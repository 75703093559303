import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import {
	Box,
	Card,
	CardContent,
	CardHeader,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	TableSortLabel,
	makeStyles,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
// import './consolidated.scss'
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog';
import { amtStr } from 'helpers/numFormatter';
import {
	useConsolidatedInvoiceListingLazyQuery,
	useDocToConsolidateViewLazyQuery,
	useGetAssignedOutletByUserQuery,
	useGetOutletQuery,
} from 'generated/graphql';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import {
	convertDate24HrsWSec,
	dateFormat,
	formatDateWDash,
} from 'helpers/hooks/formatDate';

const useStyles = makeStyles({
	tableHead: {
		fontSize: '11px',
		padding: '5px 10px',
		backgroundColor: '#ff9800',
	},
	tableContent: {
		fontSize: '11px',
		padding: '5px 10px',
	},
	tableRowOdd: {
		backgroundColor: '#faf2e8',
	},
	tableRowEven: {
		backgroundColor: '#ffffff',
	},
});

export const ConsolidatedEInvoiceView = (props: any) => {
	const dateFormat = require('dateformat');
	const { mode } = props;
	const { outletID, consolidatedEInvoiceID } = useParams<any>();

	let history = useHistory();
	const classes = useStyles();
	const { state }: any = useLocation();

	const [order, setOrder] = React.useState('asc');
	const [orderBy, setOrderBy] = React.useState('DocType');
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(5);
	const [openDialog, setOpenDialog] = useState(false);
	const [offset, setOffset] = React.useState(0);

	const {
		called: outletCalled,
		loading: outletLoading,
		error: outletError,
		data: { getOutlet } = { getOutlet: [] },
	} = useGetOutletQuery({
		fetchPolicy: 'network-only',
		variables: { ID: outletID },
	});

	const [
		loadConsolidatedInvoiceListing,
		{
			called: ConsolidatedInvoiceListingCalled,
			loading: ConsolidatedInvoiceListingLoading,
			data: { ConsolidatedInvoiceListing } = { ConsolidatedInvoiceListing: [] },
		},
	] = useConsolidatedInvoiceListingLazyQuery({
		fetchPolicy: 'network-only',
		onCompleted: ({ ConsolidatedInvoiceListing }) => {},
	});

	const {
		data: { getAssignedOutletByUser } = { getAssignedOutletByUser: [] },
		loading: getAssignedOutletByUserLoading,
	} = useGetAssignedOutletByUserQuery({
		fetchPolicy: 'network-only',
	});

	useEffect(() => {
		if (mode == 'view' && getOutlet?.length > 0) {
			loadConsolidatedInvoiceListing({
				variables: {
					outletID: outletID,
					accountID: JSON.parse(localStorage.getItem('loggedInUser'))
						?.accountID,
					companyID: getOutlet[0]?.companyID,
					ID: consolidatedEInvoiceID,
					offSet: 0,
					limit: 20,
				},
			});
		}
	}, [mode, getOutlet]);

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	const tableSort = (array, comparator) => {
		const stabilizedThis = array.map((el, index) => [el, index]);
		stabilizedThis.sort((a, b) => {
			const order = comparator(a[0], b[0]);
			if (order !== 0) return order;
			return a[1] - b[1];
		});
		return stabilizedThis.map(el => el[0]);
	};

	const descComparator = (a, b, orderBy) => {
		if (b[orderBy] < a[orderBy]) {
			return -1;
		}
		if (b[orderBy] > a[orderBy]) {
			return 1;
		}
		return 0;
	};

	const getComparator = (order, orderBy) => {
		return order === 'desc'
			? (a, b) => descComparator(a, b, orderBy)
			: (a, b) => -descComparator(a, b, orderBy);
	};

	const handlePageChange = (event, newPage) => {
		if (newPage > page) {
			setOffset(offset + rowsPerPage);
		} else if (newPage < page) {
			setOffset(offset - rowsPerPage);
		}
		setPage(newPage);

		loadDocToConsolidateView({
			variables: {
				outletID: outletID,
				accountID: JSON.parse(localStorage.getItem('loggedInUser'))?.accountID,
				companyID: getOutlet[0]?.companyID,
				consolidationID: consolidatedEInvoiceID,
				limit: rowsPerPage,
				offset: newPage > page ? offset + rowsPerPage : offset - rowsPerPage,
			},
		});
	};

	const handleChangeRowsPerPage = event => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);

		loadDocToConsolidateView({
			variables: {
				outletID: outletID,
				accountID: JSON.parse(localStorage.getItem('loggedInUser'))?.accountID,
				companyID: getOutlet[0]?.companyID,
				consolidationID: consolidatedEInvoiceID,
				limit: parseInt(event.target.value, 10),
				offset: 0,
			},
		});
	};

	const [
		loadDocToConsolidateView,
		{
			loading: DocToConsolidateViewLoading,
			data: { DocToConsolidateView } = {
				DocToConsolidateView: [],
			},
		},
	] = useDocToConsolidateViewLazyQuery({
		fetchPolicy: 'no-cache',
	});

	useEffect(() => {
		if (mode == 'view' && getOutlet?.length > 0) {
			loadDocToConsolidateView({
				variables: {
					outletID: outletID,
					accountID: JSON.parse(localStorage.getItem('loggedInUser'))
						?.accountID,
					companyID: getOutlet[0]?.companyID,
					consolidationID: consolidatedEInvoiceID,
					limit: rowsPerPage,
					offset: offset,
				},
			});
		}
	}, [mode, getOutlet]);

	const displayAmount = (docType, amount) => {
		if (docType === 'Credit Note') {
			return amtStr(Math.abs(amount));
		} else {
			return amtStr(amount);
		}
	};

	const headCells = [
		{
			ID: 'docNo',
			numeric: false,
			disablePadding: false,
			label: 'Doc No',
		},
		{
			ID: 'submenu',
			numeric: false,
			disablePadding: true,
			label: '',
		},
		{
			ID: 'docDate',
			numeric: false,
			disablePadding: true,
			label: 'Doc Date',
		},
		{
			ID: 'docType',
			numeric: false,
			disablePadding: false,
			label: 'Doc Type',
		},
		{
			ID: 'name',
			numeric: false,
			disablePadding: false,
			label: 'Name',
		},
		{
			ID: 'baseAmount',
			numeric: true,
			disablePadding: false,
			label: 'Base Amt',
		},
		{
			ID: 'serviceCharge',
			numeric: true,
			disablePadding: false,
			label: 'Service Charge',
		},
		{
			ID: 'taxAmount',
			numeric: true,
			disablePadding: false,
			label: 'Tax Amt',
		},
		{
			ID: 'docAmount',
			numeric: true,
			disablePadding: false,
			label: 'Doc Amt',
		},
	];

	const sumConsolidatedDetailOverallField = (consolidatedDetailList = []) => {
		let totalAmount = 0;
		let totalServiceCharge = 0;
		let totalTaxAmount = 0;
		let totalDocAmount = 0;
		for (const item of consolidatedDetailList) {
			totalAmount += item?.amount || 0;
			totalServiceCharge += item?.serviceCharge || 0;
			totalTaxAmount += item?.taxAmt || 0;
			totalDocAmount += item?.docAmt || 0;
		}
		return {
			amount: parseFloat(totalAmount.toFixed(2)),
			serviceCharge: parseFloat(totalServiceCharge.toFixed(2)),
			taxAmt: parseFloat(totalTaxAmount.toFixed(2)),
			docAmt: parseFloat(totalDocAmount.toFixed(2)),
		};
	};

	const totalConsolidatedDetailOverallAmount = sumConsolidatedDetailOverallField(
		ConsolidatedInvoiceListing[0]?.eIConsolidationDetail || [],
	);

	const createData = (
		docDate,
		docNo,
		docType,
		name,
		baseAmount,
		serviceCharge,
		taxAmount,
		docAmount,
	) => {
		return {
			docDate,
			docNo,
			docType,
			name,
			baseAmount,
			serviceCharge,
			taxAmount,
			docAmount,
		};
	};

	// const totalConsolidatedDetailOverallAmount = sumConsolidatedDetailOverallField(
	// 	ConsolidatedInvoiceListing[0]?.eIConsolidationDetail,
	// );

	const consolidatedEI = Array.isArray(
		ConsolidatedInvoiceListing[0]?.eIConsolidationDetail,
	)
		? ConsolidatedInvoiceListing[0].eIConsolidationDetail.map(el =>
				createData(
					dateFormat(el?.createdTs),
					el?.bill?.billNo,
					el?.refTable,
					'Agoda Company Pte. Ltd',
					amtStr(el?.amount),
					amtStr(el?.serviceCharge),
					amtStr(el?.taxAmt),
					amtStr(el?.docAmt),
				),
		  )
		: [];

	const TableHeader = props => {
		return (
			<>
				<TableHead>
					<TableRow>
						{headCells?.map(el => {
							return (
								<>
									<TableCell
										key={el?.ID}
										align={el?.numeric ? 'right' : 'left'}
										padding={el?.disablePadding ? 'none' : 'normal'}
										className={classes.tableHead}
									>
										{el.label}
									</TableCell>
								</>
							);
						})}
					</TableRow>
				</TableHead>
			</>
		);
	};

	const TableContent = () => {
		return (
			<TableBody className="table">
				{DocToConsolidateView?.map((row, index) => {
					return (
						<>
							<TableRow
								key={row.DocNo}
								className={
									index % 2 === 0 ? classes.tableRowEven : classes.tableRowOdd
								}
							>
								<TableCell
									className={classes.tableContent}
									align="left"
									colSpan={2}
								>
									{row?.docNo}
								</TableCell>
								<TableCell
									component="th"
									scope="row"
									align="left"
									className={classes.tableContent}
								>
									{dateFormat(row?.docDate, 'dd/mm/yyyy')}
								</TableCell>
								<TableCell className={classes.tableContent} align="left">
									{row?.docType}
								</TableCell>
								<TableCell className={classes.tableContent} align="left">
									{row?.name}
								</TableCell>
								<TableCell className={classes.tableContent} align="right">
									{displayAmount(row?.docType, row?.baseAmount)}
								</TableCell>
								<TableCell className={classes.tableContent} align="right">
									{displayAmount(row?.docType, row?.serviceCharge)}
								</TableCell>
								<TableCell className={classes.tableContent} align="right">
									{displayAmount(row?.docType, row?.taxAmount)}
								</TableCell>
								<TableCell className={classes.tableContent} align="right">
									{displayAmount(row?.docType, row?.docAmount)}
								</TableCell>
							</TableRow>
						</>
					);
				})}
			</TableBody>
		);
	};

	return (
		<>
			{outletLoading && <Loading />}
			{DocToConsolidateViewLoading && <Loading />}
			{getAssignedOutletByUserLoading && <Loading />}

			<MainHeader
				onClick={() =>
					history.push(`/menu/outlet-app/${outletID}/consolidate-e-inovoice`)
				}
				mainBtn="back"
				smTitle="Outlet App"
				title={
					getAssignedOutletByUser?.filter(outlet => outlet?.ID === outletID)[0]
						?.name
				}
				routeSegments={[
					{ name: 'Consolidated e-Invoice' },
					{ name: 'Consolidated e-Invoice', current: true },
				]}
			/>

			<ContentWrapper>
				{ConsolidatedInvoiceListing?.map((el, index) => (
					<Box className="card-form">
						<Card>
							<CardHeader
								disableTypography
								title={
									<span className="smTitle">
										<span
											style={{
												display: 'flex',
												justifyContent: 'space-between',
												alignItems: 'center',
												padding: '5px 0px',
											}}
										>
											<span>{el?.docNo}</span>
											<span>{formatDateWDash(el?.docDate)}</span>
										</span>
									</span>
								}
							/>
							<CardContent
								style={{ paddingTop: '20px', paddingBottom: '20px' }}
							>
								<>
									<div className="content-wrap">
										<div className="desc" style={{ fontSize: '12px' }}>
											Date
										</div>
									</div>
									<div className="content-wrap">
										<div className="desc" style={{ fontSize: '12px' }}>
											{formatDateWDash(el?.docDate)}
										</div>
									</div>
									<div className="content-wrap">
										<div className="desc" style={{ fontSize: '12px' }}>
											Submitted Date & Time
										</div>
									</div>
									<div className="content-wrap">
										<div className="desc" style={{ fontSize: '12px' }}>
											{/* {convertDate24HrsWSec(el?.createdTs)} */}
											{dateFormat(el?.createdTs, 'dd/mm/yyyy HH:mm:ss')}
										</div>
									</div>
									<div className="content-wrap">
										<div className="desc" style={{ fontSize: '12px' }}>
											Total Amount
										</div>
									</div>
									<div className="content-wrap">
										<div className="desc" style={{ fontSize: '12px' }}>
											{amtStr(el?.docAmt)}
										</div>
									</div>

									<div className="content-wrap">
										<div className="desc" style={{ fontSize: '12px' }}>
											Description
										</div>
									</div>
									<div className="content-wrap">
										<div className="desc" style={{ fontSize: '12px' }}>
											{el?.description}
										</div>
									</div>
								</>
							</CardContent>
						</Card>
					</Box>
				))}
			</ContentWrapper>

			<ContentWrapper footer style={{ marginTop: '0px' }}>
				<Box className="card-form">
					<Card>
						<CardHeader
							disableTypography
							title={
								<span className="smTitle">
									<span
										style={{
											display: 'flex',
											justifyContent: 'space-between',
											alignItems: 'center',
											padding: '5px 0px',
										}}
									>
										<span>Consolidated Detail(s)</span>
									</span>
								</span>
							}
						/>
					</Card>
				</Box>
				<div style={{ width: '100%' }}>
					<Paper style={{ width: '100%' }}>
						<TableContainer>
							<Table style={{ minWidth: 750 }}>
								<TableHeader />

								{!DocToConsolidateViewLoading && <TableContent />}

								<TableBody>
									<TableRow>
										<TableCell
											className={classes.tableHead}
										>{`Total record: ${consolidatedEI?.length ||
											0}`}</TableCell>
										<TableCell className={classes.tableHead}>{''}</TableCell>
										<TableCell className={classes.tableHead}>{''}</TableCell>
										<TableCell className={classes.tableHead}>{''}</TableCell>
										<TableCell className={classes.tableHead}>Total</TableCell>
										<TableCell className={classes.tableHead} align="right">
											{displayAmount(
												DocToConsolidateView[0]?.docType,
												totalConsolidatedDetailOverallAmount?.amount,
											)}
										</TableCell>
										<TableCell className={classes.tableHead} align="right">
											{displayAmount(
												DocToConsolidateView[0]?.DocType,
												totalConsolidatedDetailOverallAmount?.serviceCharge,
											)}
										</TableCell>
										<TableCell className={classes.tableHead} align="right">
											{displayAmount(
												DocToConsolidateView[0]?.DocType,
												totalConsolidatedDetailOverallAmount?.taxAmt,
											)}
										</TableCell>
										<TableCell className={classes.tableHead} align="right">
											{displayAmount(
												DocToConsolidateView[0]?.DocType,
												totalConsolidatedDetailOverallAmount?.docAmt,
											)}
										</TableCell>
									</TableRow>
								</TableBody>
							</Table>
						</TableContainer>

						<TablePagination
							rowsPerPageOptions={[5, 10, 25, 50, 100]}
							component="div"
							count={consolidatedEI?.length}
							rowsPerPage={rowsPerPage}
							page={page}
							onPageChange={handlePageChange}
							onRowsPerPageChange={handleChangeRowsPerPage}
						/>
					</Paper>
				</div>
			</ContentWrapper>

			<CommonDialog
				fullWidth={true}
				open={openDialog}
				onClose={() => setOpenDialog(false)}
				sections={{
					header: {
						dynamic: (
							<>
								<div className="dialog-dynamic-content">
									<div className="session">
										<div className="flex session">
											<div className="xsTitle color-primary-orange flex-space">
												Consolidated e-Invoice
											</div>
										</div>
									</div>
								</div>
							</>
						),
					},

					footer: {
						actions: [
							{
								displayText: 'Cancel',
								props: {
									onClick: () => {
										setOpenDialog(false);
									},
									variant: 'contained',
									color: 'primary',
								},
							},
							{
								displayText: 'Proceed',
								props: {
									onClick: () => {},
									variant: 'contained',
									color: 'primary',
								},
							},
						],
					},
				}}
			/>
		</>
	);
};
