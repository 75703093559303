import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type AccountJson = {
  __typename?: 'AccountJson';
  revenueDiv?: Maybe<Scalars['String']>;
  revenueDept?: Maybe<Scalars['String']>;
  revenueDebit?: Maybe<Scalars['String']>;
  revenueCredit?: Maybe<Scalars['String']>;
  reverseDiv?: Maybe<Scalars['String']>;
  reverseDept?: Maybe<Scalars['String']>;
  reverseDebit?: Maybe<Scalars['String']>;
  reverseCredit?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type AccountJsonInput = {
  revenueDiv?: Maybe<Scalars['String']>;
  revenueDept?: Maybe<Scalars['String']>;
  revenueDebit?: Maybe<Scalars['String']>;
  revenueCredit?: Maybe<Scalars['String']>;
  reverseDiv?: Maybe<Scalars['String']>;
  reverseDept?: Maybe<Scalars['String']>;
  reverseDebit?: Maybe<Scalars['String']>;
  reverseCredit?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type AccountMappingGlInput = {
  bill?: Maybe<Array<GlInput>>;
};

export type AccountMappingResponse = {
  __typename?: 'AccountMappingResponse';
  description?: Maybe<Scalars['String']>;
  glAccount?: Maybe<Scalars['JSON']>;
};

export type AccountMappingTaxGlInput = {
  TaxGL?: Maybe<Array<TaxGlInput>>;
};

export type ActionLogEntity = AuditEntity & {
  __typename?: 'ActionLogEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  outletID: Scalars['String'];
  userID: Scalars['String'];
  softwareName?: Maybe<Scalars['String']>;
  actionName: Scalars['String'];
  IPAddress?: Maybe<Scalars['String']>;
  loginDateTime: Scalars['DateTime'];
  logoutDateTime: Scalars['DateTime'];
  socketID?: Maybe<Scalars['String']>;
};

export type ActionlogInput = {
  ID?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
  userID?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['String']>;
  softwareName?: Maybe<Scalars['String']>;
  actionName?: Maybe<Scalars['String']>;
  IPAddress?: Maybe<Scalars['String']>;
  loginDateTime?: Maybe<Scalars['DateTime']>;
  logoutDateTime?: Maybe<Scalars['DateTime']>;
  socketID?: Maybe<Scalars['String']>;
};

/** JsonType */
export type Address = {
  __typename?: 'Address';
  ID?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  siteName?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  district?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  postCodeName?: Maybe<Scalars['String']>;
  AddressType?: Maybe<AddressType>;
};

export type AddressInput = {
  ID?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  siteName?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  district?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  postCodeName?: Maybe<Scalars['String']>;
  AddressType?: Maybe<AddressType>;
};

export enum AddressType {
  Primary = 'PRIMARY',
  Delivery = 'DELIVERY',
  Site = 'SITE'
}

export type AllStandardCoreBaseEntity = StandardAuditEntity & {
  __typename?: 'AllStandardCoreBaseEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
};

export type AttachmentEntity = AuditEntity & {
  __typename?: 'AttachmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  mediaType: Scalars['String'];
  refID: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  fileName: Scalars['String'];
  fileURL: Scalars['String'];
  bucketFileName: Scalars['String'];
  fileSize: Scalars['Float'];
  isPrivate: Scalars['Boolean'];
};

/** JsonType */
export type AttachmentInfo = {
  __typename?: 'AttachmentInfo';
  fileURL: Scalars['String'];
  mediaType: Scalars['String'];
};

export type AttachmentInput = {
  isPrivate?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  attachment?: Maybe<Scalars['Upload']>;
  bucketFileName?: Maybe<Scalars['String']>;
  fileURL?: Maybe<Scalars['String']>;
};

export type AuditEntity = {
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
};

export type AzureStorageOutput = {
  __typename?: 'AzureStorageOutput';
  bucketFileName: Scalars['String'];
  fileName: Scalars['String'];
  url: Scalars['String'];
  mediaType: Scalars['String'];
  isPrivate?: Maybe<Scalars['Boolean']>;
  privateUrl?: Maybe<Scalars['String']>;
};

export type BankAccountContactEntity = AuditEntity & {
  __typename?: 'BankAccountContactEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  designation?: Maybe<Scalars['String']>;
  mobileNo?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  bankAccountID: Scalars['String'];
  bankAccount?: Maybe<BankAccountEntity>;
};

export type BankAccountContactInput = {
  name?: Maybe<Scalars['String']>;
  designation?: Maybe<Scalars['String']>;
  mobileNo?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export type BankAccountEntity = AuditEntity & {
  __typename?: 'BankAccountEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  bankName: Scalars['String'];
  accountName: Scalars['String'];
  accountNo: Scalars['String'];
  SWIFTCode: Scalars['String'];
  branch: Scalars['String'];
  contactNo: Scalars['String'];
  address: Scalars['JSON'];
  isDefault?: Maybe<Scalars['Boolean']>;
  outletID?: Maybe<Scalars['String']>;
  bankCode?: Maybe<Scalars['String']>;
  bankAccountContact?: Maybe<Array<BankAccountContactEntity>>;
};

export type BankAccountInput = {
  ID?: Maybe<Scalars['String']>;
  bankName: Scalars['String'];
  accountName: Scalars['String'];
  accountNo: Scalars['String'];
  SWIFTCode: Scalars['String'];
  branch: Scalars['String'];
  contactNo: Scalars['String'];
  address: AddressInput;
  isDefault?: Maybe<Scalars['Boolean']>;
  outletID?: Maybe<Scalars['String']>;
  bankCode?: Maybe<Scalars['String']>;
};

export type BaseInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
};

export type BillDiscountEntity = AuditEntity & {
  __typename?: 'BillDiscountEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  outletID: Scalars['String'];
  billID: Scalars['String'];
  discountID: Scalars['String'];
  amount: Scalars['Float'];
  businessDate: Scalars['DateTime'];
};

export type BillDiscountInput = {
  outletID: Scalars['String'];
  billID: Scalars['String'];
  discountID: Scalars['String'];
  amount: Scalars['Float'];
  businessDate: Scalars['DateTime'];
};

export type BillEntity = AuditEntity & {
  __typename?: 'BillEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  billNo?: Maybe<Scalars['String']>;
  outletID: Scalars['String'];
  outlet?: Maybe<OutletEntity>;
  businessDate?: Maybe<Scalars['DateTime']>;
  reasonCode?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  status: BillStatus;
  orderID: Scalars['String'];
  order?: Maybe<OrderEntity>;
  payments?: Maybe<Array<PaymentEntity>>;
  orderItem?: Maybe<Array<OrderItemEntity>>;
  paymentItem?: Maybe<Array<PaymentItemEntity>>;
  isGLPosted?: Maybe<Scalars['Boolean']>;
  eIConsolidationDetail?: Maybe<EInvoiceConsolidationDetailEntity>;
  isConsolidated?: Maybe<Scalars['Boolean']>;
  einvoice_status?: Maybe<Scalars['String']>;
  is_einvoice?: Maybe<Scalars['Boolean']>;
  einvoice_buyer_name?: Maybe<Scalars['String']>;
  einvoice_irbm_uniqueID?: Maybe<Scalars['String']>;
  einvoice_buyer_tin?: Maybe<Scalars['String']>;
  einvoice_buyer_regno?: Maybe<Scalars['String']>;
  einvoice_buyer_tax_regno?: Maybe<Scalars['String']>;
  einvoice_buyer_address?: Maybe<Scalars['String']>;
  einvoice_buyer_post_code?: Maybe<Scalars['String']>;
  einvoice_buyer_city?: Maybe<Scalars['String']>;
  einvoice_buyer_state?: Maybe<Scalars['String']>;
  einvoice_buyer_country?: Maybe<Scalars['String']>;
  einvoice_buyer_contact_no?: Maybe<Scalars['String']>;
  einvoice_buyer_email?: Maybe<Scalars['String']>;
  einvoice_qr_url?: Maybe<Scalars['String']>;
  documentType?: Maybe<Scalars['String']>;
  refID?: Maybe<Scalars['String']>;
  refBillNo?: Maybe<Scalars['String']>;
  isCreditNote?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  signature?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  billDiscountType?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  billPaymentType?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  qrCodeUrl?: Maybe<Scalars['JSON']>;
};

export type BillInput = {
  billNo?: Maybe<Scalars['String']>;
  orderID?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
  businessDate?: Maybe<Scalars['DateTime']>;
  file?: Maybe<Array<Scalars['Upload']>>;
  reasonCode?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
  orderItemIDs?: Maybe<Array<Scalars['String']>>;
};

export type BillInputOffset = {
  ID?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
  billNo?: Maybe<Scalars['String']>;
};

export type BillLedgerInput = {
  ID?: Maybe<Scalars['String']>;
  FolioID?: Maybe<Scalars['String']>;
  RegistrationID?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  ReversedBillLedgerID?: Maybe<Scalars['String']>;
  IncidentalChargeID?: Maybe<Scalars['String']>;
  Reason: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  TrxDate: Scalars['DateTime'];
  Qty?: Maybe<Scalars['Float']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  TrxAmount?: Maybe<Scalars['Float']>;
  BaseAmount?: Maybe<Scalars['Float']>;
  TaxAmount?: Maybe<Scalars['Float']>;
  DiscountAmount?: Maybe<Scalars['Float']>;
  ServiceCharge?: Maybe<Scalars['Float']>;
  IsDelivered?: Maybe<Scalars['Boolean']>;
  Signature?: Maybe<Scalars['String']>;
  ReferenceNo?: Maybe<Scalars['String']>;
  PaymentID?: Maybe<Scalars['String']>;
  IsChargeToRoom?: Maybe<Scalars['Boolean']>;
  OutletCode?: Maybe<Scalars['String']>;
  ChargeType?: Maybe<Scalars['String']>;
  RefBillLedgerID?: Maybe<Scalars['String']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export enum BillStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Void = 'VOID'
}

export type CbExportEntity = AuditEntity & {
  __typename?: 'CBExportEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  batchNo?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
  sourceID?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  cbDate?: Maybe<Scalars['DateTime']>;
  trxDate?: Maybe<Scalars['DateTime']>;
  cbType?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  bankCode?: Maybe<Scalars['String']>;
  referenceNo?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['String']>;
  accountCode?: Maybe<Scalars['String']>;
  billNo?: Maybe<Scalars['String']>;
  bankAccountNo?: Maybe<Scalars['String']>;
  guestName?: Maybe<Scalars['String']>;
};

export enum CalcType {
  Amount = 'AMOUNT',
  Percentage = 'PERCENTAGE'
}

export type CalculateOrderDiscountInput = {
  outletID?: Maybe<Scalars['String']>;
  orderID?: Maybe<Scalars['String']>;
};

export type CheckOut = {
  __typename?: 'CheckOut';
  item?: Maybe<Item>;
  code?: Maybe<Scalars['String']>;
  error?: Maybe<Errors>;
  paySessionNo?: Maybe<Scalars['Float']>;
};

export enum CommonStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Block = 'BLOCK'
}

export type CompanyEntity = {
  __typename?: 'CompanyEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  parentCompanyId?: Maybe<Scalars['String']>;
  baseCurrencyId: Scalars['String'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  companyRegNo?: Maybe<Scalars['String']>;
  code: Scalars['String'];
  address?: Maybe<Address>;
  contactNo?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  sstNo?: Maybe<Scalars['String']>;
  gstNo?: Maybe<Scalars['String']>;
  ttxNo?: Maybe<Scalars['String']>;
  gstExpiryDate?: Maybe<Scalars['DateTime']>;
  image?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  imageBucketFile?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  eInvClientID?: Maybe<Scalars['String']>;
  eInvClientSecret?: Maybe<Scalars['String']>;
  eInvClientSecret2?: Maybe<Scalars['String']>;
  regionId?: Maybe<Scalars['String']>;
  imageLogoPosition: Scalars['String'];
  watermarkImplementation: Scalars['Boolean'];
  watermarkWording: Scalars['String'];
  isoImplementation: Scalars['Boolean'];
  isoLogoPosition: Scalars['String'];
  companyTax: Scalars['String'];
  recordStatus: Scalars['String'];
  groupGstRegNo?: Maybe<Scalars['String']>;
  officeTel?: Maybe<Scalars['String']>;
  tin?: Maybe<Scalars['String']>;
  eSign?: Maybe<Scalars['String']>;
  eSignExpiryDate?: Maybe<Scalars['DateTime']>;
  isEInvIntegrated: Scalars['Boolean'];
  eInvStartDate?: Maybe<Scalars['String']>;
  eInvEndDate?: Maybe<Scalars['String']>;
  outlet?: Maybe<Array<OutletEntity>>;
};

export type CompanyInput = {
  id?: Maybe<Scalars['String']>;
  parentCompanyId?: Maybe<Scalars['String']>;
  accountId: Scalars['String'];
  baseCurrencyId: Scalars['String'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  companyRegNo?: Maybe<Scalars['String']>;
  code: Scalars['String'];
  address?: Maybe<AddressInput>;
  contactNo?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  sstNo?: Maybe<Scalars['String']>;
  gstNo?: Maybe<Scalars['String']>;
  ttxNo?: Maybe<Scalars['String']>;
  gstExpiryDate?: Maybe<Scalars['DateTime']>;
  image?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  imageBucketFile?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  eInvClientID?: Maybe<Scalars['String']>;
  eInvClientSecret?: Maybe<Scalars['String']>;
  eInvClientSecret2?: Maybe<Scalars['String']>;
  regionId?: Maybe<Scalars['String']>;
  imageLogoPosition?: Maybe<Scalars['String']>;
  watermarkImplementation?: Maybe<Scalars['Boolean']>;
  watermarkWording?: Maybe<Scalars['String']>;
  isoImplementation?: Maybe<Scalars['Boolean']>;
  isoLogoPosition?: Maybe<Scalars['String']>;
  companyTax?: Maybe<Scalars['String']>;
  recordStatus?: Maybe<Scalars['String']>;
  groupGstRegNo?: Maybe<Scalars['String']>;
  officeTel?: Maybe<Scalars['String']>;
  tin?: Maybe<Scalars['String']>;
  eSign?: Maybe<Scalars['String']>;
  eSignExpiryDate?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  modBy?: Maybe<Scalars['String']>;
  isEInvIntegrated: Scalars['Boolean'];
  eInvStartDate?: Maybe<Scalars['String']>;
  eInvEndDate?: Maybe<Scalars['String']>;
  logoFiles?: Maybe<Array<Scalars['Upload']>>;
};

export enum ComputeMethod {
  TaxOnGross = 'TAX_ON_GROSS',
  TaxOnTaxedPrice = 'TAX_ON_TAXED_PRICE'
}

/** JsonType */
export type ContactPerson = {
  __typename?: 'ContactPerson';
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  associateID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  designation?: Maybe<Scalars['String']>;
  contactNo?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export type ContactPersonInput = {
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  associateID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  designation?: Maybe<Scalars['String']>;
  contactNo?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export type ConversionLog = {
  __typename?: 'ConversionLog';
  exchangeRate?: Maybe<Scalars['Float']>;
  modTs?: Maybe<Scalars['DateTime']>;
  modBy?: Maybe<Scalars['DateTime']>;
};

export type ConversionLogInput = {
  exchangeRate?: Maybe<Scalars['Float']>;
  modTs?: Maybe<Scalars['DateTime']>;
  modBy?: Maybe<Scalars['DateTime']>;
};

export type Coordinate = {
  __typename?: 'Coordinate';
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};

export type CoordinateInput = {
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};

export type CoreBaseEntity = AuditEntity & {
  __typename?: 'CoreBaseEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
};

export type CoreEntityRoleUserAssignmentEntity = AuditEntity & {
  __typename?: 'CoreEntityRoleUserAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  entityID: Scalars['String'];
  roleID: Scalars['String'];
};

export type CorePermissionEntity = {
  __typename?: 'CorePermissionEntity';
  index: Scalars['String'];
  name: Scalars['String'];
};

export type CoreRoleEntity = AuditEntity & {
  __typename?: 'CoreRoleEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  name: Scalars['String'];
};

export type CoreRolePermissionAssignmentEntity = AuditEntity & {
  __typename?: 'CoreRolePermissionAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  roleID: Scalars['String'];
  permissionID: Scalars['Float'];
};

export type CreateAuditInput = {
  createdBy?: Maybe<Scalars['String']>;
};

export type CreatePermRoleAsgInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  permId: Scalars['String'];
  roleId: Scalars['String'];
};

export enum CreditCardType {
  VisaCard = 'VISA_CARD',
  Mastercard = 'MASTERCARD',
  AmericanExpressCard = 'AMERICAN_EXPRESS_CARD',
  DinersCard = 'DINERS_CARD',
  JcbCard = 'JCB_CARD',
  UnionpayCard = 'UNIONPAY_CARD',
  DebitCard = 'DEBIT_CARD',
  Ewallet = 'EWALLET'
}

export type CurrencyEntity = {
  __typename?: 'CurrencyEntity';
  id: Scalars['String'];
  code: Scalars['String'];
  name: Scalars['String'];
  symbol: Scalars['String'];
  format: Scalars['String'];
  common_status: Scalars['String'];
};

export type CustomerAccessResponse = {
  __typename?: 'CustomerAccessResponse';
  accessToken: Scalars['String'];
  customer: CustomerEntity;
};

export type CustomerEntity = AuditEntity & {
  __typename?: 'CustomerEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  outletID?: Maybe<Scalars['String']>;
  orderID?: Maybe<Scalars['String']>;
  softwareCode: Scalars['String'];
  superUser: Scalars['Boolean'];
};

export type DailyMenuItemAvailabilityEntity = AuditEntity & {
  __typename?: 'DailyMenuItemAvailabilityEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  menuItemID: Scalars['String'];
  outletID?: Maybe<Scalars['String']>;
  quantity: Scalars['Float'];
  dayTs: Scalars['DateTime'];
  businessDate?: Maybe<Scalars['DateTime']>;
  menuItem?: Maybe<MenuItemEntity>;
  /** CustomFieldResolver */
  outstandingQty?: Maybe<Scalars['Float']>;
};

export type DailyMenuItemAvailabilityInput = {
  accountID?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
  menuItemID: Scalars['String'];
  quantity: Scalars['Float'];
  dayTs: Scalars['DateTime'];
};

export type DailyMenuPriceEntity = AuditEntity & {
  __typename?: 'DailyMenuPriceEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  promoMenuID: Scalars['String'];
  menuItemID: Scalars['String'];
  patronTypeID: Scalars['String'];
  outletID: Scalars['String'];
  promoMenu?: Maybe<PromoMenuEntity>;
  menuItem?: Maybe<MenuItemEntity>;
  patronType?: Maybe<PatronTypeEntity>;
  patronCustomType: Scalars['String'];
  unitPrice: Scalars['Float'];
  startTs: Scalars['DateTime'];
  endTs: Scalars['DateTime'];
};

export type DailyMenuPriceInput = {
  promotionMenuID: Scalars['String'];
  menuItemID: Scalars['String'];
  startTs: Scalars['DateTime'];
  endTs: Scalars['DateTime'];
};


export type DefaultPaymentEntity = AuditEntity & {
  __typename?: 'DefaultPaymentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  outletID: Scalars['String'];
  paymentTypeID: Scalars['String'];
  payment?: Maybe<Array<PaymentEntity>>;
  isDefault: Scalars['Boolean'];
  paymentClass: PaymentClass;
  outletGLAccount?: Maybe<AccountJson>;
  /** CustomFieldResolver */
  paymentTypeInfo?: Maybe<Scalars['JSON']>;
};

export type DefaultPaymentGlEntity = AuditEntity & {
  __typename?: 'DefaultPaymentGLEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  outletID: Scalars['String'];
  paymentClass?: Maybe<Scalars['String']>;
  discountType?: Maybe<Scalars['String']>;
  outletGLAccount?: Maybe<AccountJson>;
};

export type DefaultPaymentInput = {
  ID?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
  paymentTypeID: Scalars['String'];
  isDefault: Scalars['Boolean'];
  paymentClass: PaymentClass;
};

export type DigitalMenuCategoryEntity = AuditEntity & {
  __typename?: 'DigitalMenuCategoryEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  digitalMenuID: Scalars['String'];
  outletID: Scalars['String'];
  name: Scalars['String'];
  priority: Scalars['Float'];
  digitalMenu?: Maybe<DigitalMenuEntity>;
  digitalMenuItem?: Maybe<Array<DigitalMenuItemEntity>>;
};

export type DigitalMenuCategoryInput = {
  ID?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['String']>;
  outletID: Scalars['String'];
  name: Scalars['String'];
  priority: Scalars['Float'];
  digitalMenuID: Scalars['String'];
};

export type DigitalMenuEntity = AuditEntity & {
  __typename?: 'DigitalMenuEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  name: Scalars['String'];
  outletID: Scalars['String'];
  description: Scalars['String'];
  digitalMenuCategory?: Maybe<Array<DigitalMenuCategoryEntity>>;
};

export type DigitalMenuInput = {
  accountID?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
  outletID: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
};

export type DigitalMenuItemEntity = AuditEntity & {
  __typename?: 'DigitalMenuItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  outletID: Scalars['String'];
  digitalMenuCategoryID: Scalars['String'];
  digitalMenuCategory?: Maybe<DigitalMenuCategoryEntity>;
  menuItemID: Scalars['String'];
  menuItem?: Maybe<MenuItemEntity>;
  digitalMenuItemPrice?: Maybe<Array<DigitalMenuItemPriceEntity>>;
};

export type DigitalMenuItemInput = {
  accountID?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
  digitalMenuCategoryID?: Maybe<Scalars['String']>;
  menuItemID: Scalars['String'];
  digitalMenuItemPrice: Array<DigitalMenuItemPriceInput>;
};

export type DigitalMenuItemPriceEntity = AuditEntity & {
  __typename?: 'DigitalMenuItemPriceEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  digitalMenuItemID: Scalars['String'];
  outletID?: Maybe<Scalars['String']>;
  price: Scalars['Float'];
  digitalMenuItem?: Maybe<DigitalMenuItemEntity>;
  patronID: Scalars['String'];
  patron?: Maybe<PatronEntity>;
};

export type DigitalMenuItemPriceInput = {
  accountID?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
  digitalMenuItemID?: Maybe<Scalars['String']>;
  patronID: Scalars['String'];
  price: Scalars['Float'];
};

/** JsonType */
export type DiscountCheckingInUseInfo = {
  __typename?: 'DiscountCheckingInUseInfo';
  discountInUse: Scalars['String'];
};

export type DiscountEntity = AuditEntity & {
  __typename?: 'DiscountEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  outletID?: Maybe<Scalars['String']>;
  code: Scalars['String'];
  description: Scalars['String'];
  amount: Scalars['Float'];
  calcType: CalcType;
  discountType: DiscountType;
  outletGLAccount?: Maybe<AccountJson>;
  outletTaxGLAccount?: Maybe<AccountJson>;
  outletServiceChargeGLAccount?: Maybe<AccountJson>;
  /** CustomFieldResolver */
  discountCheckingInUseInfo?: Maybe<Scalars['JSON']>;
};

export type DiscountInput = {
  ID?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['String']>;
  outletID: Scalars['String'];
  code: Scalars['String'];
  description: Scalars['String'];
  amount: Scalars['Float'];
  calcType: CalcType;
  discountType: DiscountType;
};

export enum DiscountType {
  Discount = 'DISCOUNT',
  OfficerCheck = 'OFFICER_CHECK',
  Entertainment = 'ENTERTAINMENT',
  Promo = 'PROMO'
}

/** JsonType */
export type DiscountTypeInfo = {
  __typename?: 'DiscountTypeInfo';
  discountType: Scalars['String'];
};

export enum DocType {
  Bill = 'BILL',
  Order = 'ORDER',
  Table = 'TABLE',
  Payment = 'PAYMENT',
  Reservation = 'RESERVATION',
  OnlinePayment = 'ONLINE_PAYMENT',
  ConsolidatedEinvoice = 'CONSOLIDATED_EINVOICE',
  CreditNote = 'CREDIT_NOTE'
}

export type EInvoiceConsolidationDetailEntity = AuditEntity & {
  __typename?: 'EInvoiceConsolidationDetailEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  consolidationID: Scalars['String'];
  refTable: Scalars['String'];
  refRecordID: Scalars['String'];
  amount: Scalars['Float'];
  serviceCharge?: Maybe<Scalars['Float']>;
  taxAmt: Scalars['Float'];
  docAmt: Scalars['Float'];
  eIConsolidation?: Maybe<EInvoiceConsolidationEntity>;
  bill?: Maybe<BillEntity>;
};

export type EInvoiceConsolidationEntity = AuditEntity & {
  __typename?: 'EInvoiceConsolidationEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  companyID: Scalars['String'];
  accountID: Scalars['String'];
  outletID: Scalars['String'];
  amount?: Maybe<Scalars['Float']>;
  serviceCharge?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  docNo?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate: Scalars['String'];
  einvoice_status?: Maybe<Scalars['String']>;
  einvoice_trx_type?: Maybe<EInvoiceConsolidationTrxType>;
  einvoice_sent_date?: Maybe<Scalars['DateTime']>;
  einvoice_failed_send_reason?: Maybe<Scalars['String']>;
  einvoice_validation_date?: Maybe<Scalars['DateTime']>;
  einvoice_failed_validation_date?: Maybe<Scalars['DateTime']>;
  einvoice_failed_validation_reason?: Maybe<Scalars['String']>;
  einvoice_cancelled_by?: Maybe<Scalars['String']>;
  einvoice_cancelled_date?: Maybe<Scalars['DateTime']>;
  einvoice_cancelled_reason?: Maybe<Scalars['String']>;
  einvoice_rejected_request_date?: Maybe<Scalars['DateTime']>;
  einvoice_rejected_confirm_by?: Maybe<Scalars['String']>;
  einvoice_rejected_confirm_date?: Maybe<Scalars['DateTime']>;
  einvoice_rejected_reason?: Maybe<Scalars['String']>;
  einvoice_qr_url?: Maybe<Scalars['String']>;
  einvoice_irbm_uniqueID?: Maybe<Scalars['String']>;
  einvoice_directsend?: Maybe<Scalars['Boolean']>;
  eIConsolidationDetail?: Maybe<Array<EInvoiceConsolidationDetailEntity>>;
};

export enum EInvoiceConsolidationTrxType {
  Bill = 'BILL',
  CreditNote = 'CREDIT_NOTE',
  Invoice = 'INVOICE'
}

export type EInvoicePolicyEntity = AuditEntity & {
  __typename?: 'EInvoicePolicyEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  eInvoiceClassificationCodeID: Scalars['String'];
  classificationCode?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type EInvoicePolicyInput = {
  ID?: Maybe<Scalars['String']>;
  eInvoiceClassificationCodeID: Scalars['String'];
  classificationCode?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type EInvoiceSubscriptionEntity = AuditEntity & {
  __typename?: 'EInvoiceSubscriptionEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
};

export type EditTaxSchemeInput = {
  createdBy: Scalars['String'];
  modBy: Scalars['String'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  isExclusive?: Maybe<Scalars['Boolean']>;
  taxSchemeDetail?: Maybe<Array<TaxSchemeDetailInput>>;
  id?: Maybe<Scalars['String']>;
};

export type EditTaxSetupInput = {
  code: Scalars['String'];
  description: Scalars['String'];
  classType: Scalars['String'];
  isClaimable: Scalars['Boolean'];
  schemeTypeId: Scalars['String'];
  createdBy?: Maybe<Scalars['String']>;
  modBy?: Maybe<Scalars['String']>;
  taxDetail: Array<TaxDetailInput>;
  taxId: Scalars['String'];
  deletedId: Array<Scalars['String']>;
};

export type EntityRoleUserAsgInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  outletID: Scalars['String'];
  roleID: Scalars['String'];
  userID: Array<Scalars['String']>;
};

export type Errors = {
  __typename?: 'Errors';
  code?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  debug?: Maybe<Scalars['String']>;
};

export type ExportedGlTransferResponse = {
  __typename?: 'ExportedGLTransferResponse';
  AccountID?: Maybe<Scalars['String']>;
  CompanyName?: Maybe<Scalars['String']>;
  JournalTypeName?: Maybe<Scalars['String']>;
  DocNo?: Maybe<Scalars['String']>;
  JournalDate?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  AccountName?: Maybe<Scalars['String']>;
  DepartmentCode?: Maybe<Scalars['String']>;
  MasterCOACode?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  Remark?: Maybe<Scalars['String']>;
};

export type FamilyEntity = AuditEntity & {
  __typename?: 'FamilyEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  name: Scalars['String'];
  description: Scalars['String'];
  majorID: Scalars['String'];
  outletID?: Maybe<Scalars['String']>;
  major?: Maybe<MajorEntity>;
  menuItem?: Maybe<Array<MenuItemEntity>>;
};

export type FamilyInput = {
  ID?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
  majorID: Scalars['String'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
};

export type FieldError = {
  __typename?: 'FieldError';
  field: Scalars['String'];
  message: Scalars['String'];
};

export enum FrequencyType {
  Once = 'ONCE',
  Daily = 'DAILY',
  Weekly = 'WEEKLY',
  Monthly = 'MONTHLY',
  Yearly = 'YEARLY'
}

export type GlExportEntity = AuditEntity & {
  __typename?: 'GLExportEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  batchNo?: Maybe<Scalars['Float']>;
  outletID?: Maybe<Scalars['String']>;
  sourceID?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  glDate: Scalars['DateTime'];
  trxDate: Scalars['DateTime'];
  glType?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
  division?: Maybe<Scalars['String']>;
  accountCode?: Maybe<Scalars['String']>;
  debit?: Maybe<Scalars['Float']>;
  credit?: Maybe<Scalars['Float']>;
  displaySeq?: Maybe<Scalars['Float']>;
};

export type GlInput = {
  description?: Maybe<Scalars['String']>;
  descriptionType?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
  outletGLAccount?: Maybe<AccountJsonInput>;
  outletOCGLAccount?: Maybe<AccountJsonInput>;
  outletENTGLAccount?: Maybe<AccountJsonInput>;
  outletTaxGLAccount?: Maybe<AccountJsonInput>;
  outletServiceChargeGLAccount?: Maybe<AccountJsonInput>;
  outletRoundingGLAccount?: Maybe<AccountJsonInput>;
  outletDiscountGLAcct?: Maybe<AccountJsonInput>;
  outletOCDiscountGLAcct?: Maybe<AccountJsonInput>;
  outletENTDiscountGLAcct?: Maybe<AccountJsonInput>;
};

export type GlPostResponse = {
  __typename?: 'GLPostResponse';
  GLPost?: Maybe<Scalars['Boolean']>;
  ExportedGLTransfer?: Maybe<Array<ExportedGlTransferResponse>>;
};

export type GuestLedgerAcctInput = {
  div?: Maybe<Scalars['String']>;
  dept?: Maybe<Scalars['String']>;
  acct?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type HotelGuestInfo = {
  __typename?: 'HotelGuestInfo';
  BookingNo?: Maybe<Scalars['String']>;
  FullName?: Maybe<Scalars['String']>;
  RoomNo?: Maybe<Scalars['String']>;
  GuestType?: Maybe<Scalars['String']>;
  ArrivalDate?: Maybe<Scalars['DateTime']>;
  DepartureDate?: Maybe<Scalars['DateTime']>;
  DebtorAccount?: Maybe<Scalars['String']>;
  CompanyName?: Maybe<Scalars['String']>;
};

export type HotelGuestInfoInput = {
  BookingNo?: Maybe<Scalars['String']>;
  FullName?: Maybe<Scalars['String']>;
  RoomNo?: Maybe<Scalars['String']>;
  GuestType?: Maybe<Scalars['String']>;
  ArrivalDate?: Maybe<Scalars['DateTime']>;
  DepartureDate?: Maybe<Scalars['DateTime']>;
  DebtorAccount?: Maybe<Scalars['String']>;
  CompanyName?: Maybe<Scalars['String']>;
};

export type HotelRoomEntity = AuditEntity & {
  __typename?: 'HotelRoomEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  roomNo?: Maybe<Scalars['String']>;
  patronAccount?: Maybe<Array<PatronAccountEntity>>;
};

export type HotelVendorInfoInput = {
  outletID: Scalars['String'];
  vendorName?: Maybe<Scalars['String']>;
  vendorInfo?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
};

export type HuaweiStorageOutput = {
  __typename?: 'HuaweiStorageOutput';
  BucketFileName: Scalars['String'];
  FileName: Scalars['String'];
  Url: Scalars['String'];
  MediaType: Scalars['String'];
  IsPrivate?: Maybe<Scalars['Boolean']>;
  PrivateUrl?: Maybe<Scalars['String']>;
};

export type InterfaceBaseEntity = AuditEntity & {
  __typename?: 'InterfaceBaseEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
};

export type InterfaceTrxEntity = AuditEntity & {
  __typename?: 'InterfaceTrxEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
};


export type KitchenOrder = AuditEntity & {
  __typename?: 'KitchenOrder';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  outletID: Scalars['String'];
  registrationID?: Maybe<Scalars['String']>;
  docNo?: Maybe<Scalars['String']>;
  serviceMode: ServiceMode;
  openedByID: Scalars['String'];
  servedByIDs: Array<Scalars['String']>;
  paidByID?: Maybe<Scalars['String']>;
  businessDate: Scalars['DateTime'];
  pax?: Maybe<Scalars['Float']>;
  patronName?: Maybe<Scalars['String']>;
  contactNo?: Maybe<Scalars['String']>;
  pickupTime?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  status: OrderStatus;
  refOrderID?: Maybe<Scalars['String']>;
  orderItem?: Maybe<Array<OrderItemEntity>>;
  bill?: Maybe<Array<BillEntity>>;
  tableID?: Maybe<Scalars['String']>;
  table?: Maybe<TableEntity>;
  qrID: Scalars['String'];
  mealPeriodID: Scalars['String'];
  mealPeriod?: Maybe<MealPeriodEntity>;
  orderDiscount?: Maybe<Array<OrderDiscountEntity>>;
  patronID: Scalars['String'];
  patron?: Maybe<PatronEntity>;
  patronAccountID?: Maybe<Scalars['String']>;
  paymentItem?: Maybe<Array<PaymentItemEntity>>;
  /** CustomFieldResolver */
  qr?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  totalAmount?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  outstandingAmount?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  totalDiscountAmount?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  taxSchemeInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  taxSchemeFooterInfo?: Maybe<Scalars['JSON']>;
  kitchenOrderItems?: Maybe<Array<OrderItemEntity>>;
};

export type KitchenPrinterEntity = AuditEntity & {
  __typename?: 'KitchenPrinterEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  name: Scalars['String'];
  description: Scalars['String'];
  address: Scalars['String'];
  outletID: Scalars['String'];
  menuItem?: Maybe<Array<MenuItemEntity>>;
  isDefault?: Maybe<Scalars['Boolean']>;
};

export type KitchenPrinterInput = {
  ID?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  description: Scalars['String'];
  address?: Maybe<Scalars['String']>;
};

export enum KitchenStatus {
  Pending = 'PENDING',
  Preparing = 'PREPARING',
  Prepared = 'PREPARED',
  Completed = 'COMPLETED'
}

export type ListEntityRoleUserAsgInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  outletID: Scalars['String'];
  roleUserIDs: Array<RoleUserInput>;
};

export type LoginResponse = {
  __typename?: 'LoginResponse';
  accessToken: Scalars['String'];
  user: UserResponse;
};

export type MajorDiscountGlEntity = AuditEntity & {
  __typename?: 'MajorDiscountGLEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  outletID: Scalars['String'];
  majorName?: Maybe<Scalars['String']>;
  discountType?: Maybe<Scalars['String']>;
  outletGLAccount?: Maybe<AccountJson>;
};

export type MajorEntity = AuditEntity & {
  __typename?: 'MajorEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  name: Scalars['String'];
  priority?: Maybe<Scalars['Float']>;
  outletID: Scalars['String'];
  description: Scalars['String'];
  eInvClassification?: Maybe<Scalars['String']>;
  family?: Maybe<Array<FamilyEntity>>;
  menuItem?: Maybe<Array<MenuItemEntity>>;
  outletGLAccount?: Maybe<AccountJson>;
  outletOCGLAccount?: Maybe<AccountJson>;
  outletENTGLAccount?: Maybe<AccountJson>;
  msicCode?: Maybe<Scalars['String']>;
  msicDesc?: Maybe<Scalars['String']>;
};

export type MajorGlEntity = AuditEntity & {
  __typename?: 'MajorGLEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  outletID: Scalars['String'];
  majorName?: Maybe<Scalars['String']>;
  discountType?: Maybe<Scalars['String']>;
  outletGLAccount?: Maybe<AccountJson>;
};

export type MajorInput = {
  ID?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['String']>;
  outletID: Scalars['String'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['Float']>;
  eInvClassification?: Maybe<Scalars['String']>;
  msicCode?: Maybe<Scalars['String']>;
  msicDesc?: Maybe<Scalars['String']>;
};

export type MealPeriodEntity = AuditEntity & {
  __typename?: 'MealPeriodEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  name: Scalars['String'];
  outletID: Scalars['String'];
  startTime: Scalars['String'];
  endTime: Scalars['String'];
  validFrom?: Maybe<Scalars['DateTime']>;
  validTill?: Maybe<Scalars['DateTime']>;
  paymentItem?: Maybe<Array<PaymentItemEntity>>;
  orderItem?: Maybe<Array<OrderItemEntity>>;
  order?: Maybe<Array<OrderEntity>>;
};

export type MealPeriodInput = {
  ID?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
  scheduledMenuID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  startTime: Scalars['String'];
  endTime: Scalars['String'];
  validFrom?: Maybe<Scalars['DateTime']>;
  validTill?: Maybe<Scalars['DateTime']>;
};

export type MembershipEntity = AuditEntity & {
  __typename?: 'MembershipEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  memberNo?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  patronAccount?: Maybe<Array<PatronAccountEntity>>;
};

export type MenuItemEntity = AuditEntity & {
  __typename?: 'MenuItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  name: Scalars['String'];
  nativeName: Scalars['String'];
  kitchenName?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  perforatedQuantity?: Maybe<Scalars['Float']>;
  defaultQuantity?: Maybe<Scalars['Float']>;
  isOpenItem: Scalars['Boolean'];
  isOpenPrice: Scalars['Boolean'];
  isChefRecommended: Scalars['Boolean'];
  isQuantityControlled: Scalars['Boolean'];
  isTaxable: Scalars['Boolean'];
  isDiscountable: Scalars['Boolean'];
  isServiceChargeable: Scalars['Boolean'];
  isByWeight: Scalars['Boolean'];
  isSetMenu: Scalars['Boolean'];
  isPerforated: Scalars['Boolean'];
  promoMenuIDs: Array<Scalars['String']>;
  promoMenu: Array<PromoMenuEntity>;
  promoMenuItem?: Maybe<Array<PromoMenuItemEntity>>;
  dailyAvailability?: Maybe<DailyMenuItemAvailabilityEntity>;
  menuItemOptions?: Maybe<Array<MenuItemOptionEntity>>;
  paymentItem?: Maybe<Array<PaymentItemEntity>>;
  orderItem?: Maybe<Array<OrderItemEntity>>;
  orderItemSetMenuOptionItem?: Maybe<Array<OrderItemSetMenuOptionItemEntity>>;
  paymentItemSetMenuOptionItem?: Maybe<Array<PaymentItemSetMenuOptionItemEntity>>;
  outletDailyMenuItem?: Maybe<Array<OutletDailyMenuItemEntity>>;
  stopSales?: Maybe<StopSalesEntity>;
  dailyMenuPrice?: Maybe<DailyMenuPriceEntity>;
  familyID?: Maybe<Scalars['String']>;
  family?: Maybe<FamilyEntity>;
  majorID?: Maybe<Scalars['String']>;
  major?: Maybe<MajorEntity>;
  outletID: Scalars['String'];
  outlet?: Maybe<OutletEntity>;
  kitchenPrinterID?: Maybe<Scalars['String']>;
  kitchenPrinter2ID?: Maybe<Scalars['String']>;
  kitchenPrinter?: Maybe<KitchenPrinterEntity>;
  digitalMenuItem?: Maybe<Array<DigitalMenuItemEntity>>;
  setMenuOptionItem?: Maybe<Array<SetMenuOptionItemEntity>>;
  setMenuOption?: Maybe<Array<SetMenuOptionEntity>>;
  uomID?: Maybe<Scalars['String']>;
  uom?: Maybe<UomEntity>;
  taxSchemeID?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  attachments?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  setMenuInfo?: Maybe<Array<SetMenuInfo>>;
};

export type MenuItemInput = {
  isActive?: Maybe<Scalars['Boolean']>;
  ID?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  nativeName: Scalars['String'];
  kitchenName?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  familyID?: Maybe<Scalars['String']>;
  majorID?: Maybe<Scalars['String']>;
  kitchenPrinterID?: Maybe<Scalars['String']>;
  kitchenPrinter2ID?: Maybe<Scalars['String']>;
  uomID?: Maybe<Scalars['String']>;
  outletID: Scalars['String'];
  isOpenItem?: Maybe<Scalars['Boolean']>;
  isOpenPrice?: Maybe<Scalars['Boolean']>;
  isChefRecommended?: Maybe<Scalars['Boolean']>;
  isQuantityControlled?: Maybe<Scalars['Boolean']>;
  isTaxable?: Maybe<Scalars['Boolean']>;
  isServiceChargeable?: Maybe<Scalars['Boolean']>;
  isDiscountable?: Maybe<Scalars['Boolean']>;
  isSetMenu?: Maybe<Scalars['Boolean']>;
  defaultQuantity?: Maybe<Scalars['Float']>;
  isByWeight?: Maybe<Scalars['Boolean']>;
  isPerforated?: Maybe<Scalars['Boolean']>;
  perforatedQuantity?: Maybe<Scalars['Float']>;
  file?: Maybe<Array<Scalars['Upload']>>;
  taxSchemeID?: Maybe<Scalars['String']>;
};

export type MenuItemOptionEntity = AuditEntity & {
  __typename?: 'MenuItemOptionEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  menuItemID: Scalars['String'];
  outletID: Scalars['String'];
  maxSelections: Scalars['Float'];
  menuItem?: Maybe<MenuItemEntity>;
  optionID: Scalars['String'];
  option?: Maybe<OptionEntity>;
  menuItemOptionItem?: Maybe<Array<MenuItemOptionItemEntity>>;
};

export type MenuItemOptionInput = {
  accountID?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
  menuItemID?: Maybe<Scalars['String']>;
  optionID: Scalars['String'];
  maxSelections: Scalars['Float'];
  menuItemOptionItems: Array<MenuItemOptionItemInput>;
};

export type MenuItemOptionItemEntity = AuditEntity & {
  __typename?: 'MenuItemOptionItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  outletID: Scalars['String'];
  price: Scalars['Float'];
  optionItemID: Scalars['String'];
  optionItem?: Maybe<OptionItemEntity>;
  menuItemOptionID: Scalars['String'];
  menuItemOption?: Maybe<MenuItemOptionEntity>;
};

export type MenuItemOptionItemInput = {
  accountID?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
  price: Scalars['Float'];
  optionItemID: Scalars['String'];
  menuItemOptionID?: Maybe<Scalars['String']>;
};

export type MenuVendorEntity = AuditEntity & {
  __typename?: 'MenuVendorEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  outletID: Scalars['String'];
  vendorName?: Maybe<Scalars['String']>;
  vendorInfo?: Maybe<Scalars['String']>;
};

export enum MethodType {
  Up = 'UP',
  Down = 'DOWN',
  Off = 'OFF'
}

export type Mutation = {
  __typename?: 'Mutation';
  submitOutletGLInterface: Scalars['Boolean'];
  AccountMappingTaxGLInsert: Scalars['Boolean'];
  updateBankAccount: Scalars['Boolean'];
  createBankAccount: Scalars['Boolean'];
  deleteBankAccount: Scalars['Boolean'];
  createBankAccountContact: BankAccountContactEntity;
  updateBankAccountContact: BankAccountContactEntity;
  deleteBankAccountContact: Scalars['Boolean'];
  postCBExport: Scalars['JSON'];
  postGLExport: GlPostResponse;
  createAttachment: AttachmentEntity;
  updateAttachment: AttachmentEntity;
  deleteAttachment: Scalars['Boolean'];
  shareAttachment?: Maybe<ShareAttachmentEntity>;
  transactionExecMutation: Scalars['Boolean'];
  createCompany: Scalars['Boolean'];
  updateCompany: Scalars['Boolean'];
  companyUpdateStatus: Scalars['Boolean'];
  GenerateConsolidatedDoc: Scalars['Boolean'];
  CreateRolePermission: Scalars['Boolean'];
  UpdateRolePermission: Scalars['Boolean'];
  DeleteRolePermission: Scalars['Boolean'];
  CreateEntityRoleUser: Scalars['Boolean'];
  CreateListEntityRoleUser: Scalars['Boolean'];
  DuplicateRolePermission: Scalars['Boolean'];
  createEInvoicePolicy: EInvoicePolicyEntity;
  updateEInvoicePolicy: EInvoicePolicyEntity;
  deleteEInvoicePolicy: Scalars['Boolean'];
  submitEInvoicePolicy: Scalars['Boolean'];
  createOfficer: OfficerEntity;
  updateOfficer: OfficerEntity;
  deleteOfficer: Scalars['Boolean'];
  createRoundingPolicy: RoundingPolicyEntity;
  updateRoundingPolicy: RoundingPolicyEntity;
  deleteRoundingPolicy: Scalars['Boolean'];
  submitRoundingPolicy: Scalars['Boolean'];
  createStaff: StaffEntity;
  updateStaff: StaffEntity;
  deleteStaff: Scalars['Boolean'];
  taxSchemeInsert: Scalars['Boolean'];
  taxSchemeUpdate: Scalars['Boolean'];
  taxSchemeDelete: Scalars['Boolean'];
  setDefaultTaxScheme: Scalars['Boolean'];
  taxInsert: Scalars['Boolean'];
  taxUpdate: Scalars['Boolean'];
  taxDelete: Scalars['Boolean'];
  createUser: UserEntity;
  resendInvitation: Scalars['Boolean'];
  updateUser: UserEntity;
  createPassword: Scalars['Boolean'];
  createPasswordTest: Scalars['Boolean'];
  activateUser: Scalars['Boolean'];
  login: LoginResponse;
  changePassword: Scalars['Boolean'];
  logout: Scalars['Boolean'];
  resetPassword: Scalars['Boolean'];
  totpSecret: Scalars['String'];
  totpValidate: Scalars['Boolean'];
  uploadProfilePic: AttachmentEntity;
  forgotPassword: Scalars['Boolean'];
  unblockUser: Scalars['Boolean'];
  customerAccess: CustomerAccessResponse;
  createDailyMenuItemAvailability: DailyMenuItemAvailabilityEntity;
  updateDailyMenuItemAvailability: DailyMenuItemAvailabilityEntity;
  deleteDailyMenuItemAvailability: Scalars['Boolean'];
  submitDailyMenuItemAvailability: Scalars['Boolean'];
  createDailyMenuPrice: DailyMenuPriceEntity;
  updateDailyMenuPrice: DailyMenuPriceEntity;
  deleteDailyMenuPrice: Scalars['Boolean'];
  createDefaultPayment: DefaultPaymentEntity;
  updateDefaultPayment: DefaultPaymentEntity;
  deleteDefaultPayment: Scalars['Boolean'];
  submitDefaultPayment: Scalars['Boolean'];
  createDigitalMenu: DigitalMenuEntity;
  updateDigitalMenu: DigitalMenuEntity;
  deleteDigitalMenu: Scalars['Boolean'];
  duplicateDigitalMenu: Scalars['Boolean'];
  createDigitalMenuCategory: DigitalMenuCategoryEntity;
  updateDigitalMenuCategory: DigitalMenuCategoryEntity;
  deleteDigitalMenuCategory: Scalars['Boolean'];
  createDigitalMenuItem: DigitalMenuItemEntity;
  updateDigitalMenuItem: DigitalMenuItemEntity;
  deleteDigitalMenuItem: Scalars['Boolean'];
  deleteDigitalMenuItemPrice: Scalars['Boolean'];
  createDigitalMenuItemPrice: Scalars['Boolean'];
  updateDigitalMenuItemPrice: Scalars['Boolean'];
  deleteDigitalMenuItemAndPrice: Scalars['Boolean'];
  createDiscount: DiscountEntity;
  updateDiscount: DiscountEntity;
  deleteDiscount: Scalars['Boolean'];
  createFamily: FamilyEntity;
  updateFamily: FamilyEntity;
  deleteFamily: Scalars['Boolean'];
  createKitchenPrinter: KitchenPrinterEntity;
  updateKitchenPrinter: KitchenPrinterEntity;
  deleteKitchenPrinter: Scalars['Boolean'];
  createMajor: MajorEntity;
  updateMajor: MajorEntity;
  deleteMajor: Scalars['Boolean'];
  createMajorWithGL: Scalars['Boolean'];
  updateMajorWithGL: Scalars['Boolean'];
  deleteMajorWithGL: Scalars['Boolean'];
  dragMajor: Scalars['Boolean'];
  createMealPeriod: MealPeriodEntity;
  updateMealPeriod: MealPeriodEntity;
  deleteMealPeriod: Scalars['Boolean'];
  duplicateOutletMenu: Scalars['Boolean'];
  createMenuItem: MenuItemEntity;
  updateMenuItem: MenuItemEntity;
  createMenuItemWithOptions: Scalars['Boolean'];
  updateMenuItemWithOptions: Scalars['Boolean'];
  deleteMenuItem: Scalars['Boolean'];
  createMenuItemOption: MenuItemOptionEntity;
  updateMenuItemOption: MenuItemOptionEntity;
  deleteMenuItemOption: Scalars['Boolean'];
  createOperatingHours: OperatingHoursEntity;
  updateOperatingHours: OperatingHoursEntity;
  deleteOperatingHours: Scalars['Boolean'];
  createOption: OptionEntity;
  updateOption: OptionEntity;
  deleteOption: Scalars['Boolean'];
  deleteOptionItem: Scalars['Boolean'];
  createOptionItem: OptionItemEntity;
  updateOptionItem: OptionItemEntity;
  dragOptionItem: Scalars['Boolean'];
  deleteOutlet: Scalars['Boolean'];
  createOutlet: Scalars['Boolean'];
  updateOutlet: Scalars['Boolean'];
  updateOutletTaxSetupByTaxScheme: Scalars['Boolean'];
  createOutletCustomerPolicy: OutletCustomerPolicyEntity;
  updateOutletCustomerPolicy: OutletCustomerPolicyEntity;
  deleteOutletCustomerPolicy: Scalars['Boolean'];
  submitOutletCustomerPolicy: Scalars['Boolean'];
  createOutletTaxPolicy: OutletTaxPolicyEntity;
  updateOutletTaxPolicy: OutletTaxPolicyEntity;
  deleteOutletTaxPolicy: Scalars['Boolean'];
  updateOutletGovTaxPolicy: Scalars['Boolean'];
  createPatron: PatronEntity;
  updatePatron: PatronEntity;
  deletePatron: Scalars['Boolean'];
  submitOutletPatronSetup: Scalars['Boolean'];
  createPromoCode: PromoCodeEntity;
  updatePromoCode: PromoCodeEntity;
  deletePromoCode: Scalars['Boolean'];
  createPromoMenu: PromoMenuEntity;
  updatePromoMenu: PromoMenuEntity;
  deletePromoMenu: Scalars['Boolean'];
  createPromoMenuItem: PromoMenuItemEntity;
  updatePromoMenuItem: PromoMenuItemEntity;
  deletePromoMenuItem: Scalars['Boolean'];
  deletePromoMenuItemPrice: Scalars['Boolean'];
  createPromoMenuItemPrice: Scalars['Boolean'];
  updatePromoMenuItemPrice: Scalars['Boolean'];
  deletePromoMenuItemAndPrice: Scalars['Boolean'];
  createQR: QrEntity;
  updateQR: QrEntity;
  deleteQR: Scalars['Boolean'];
  createReason: ReasonEntity;
  updateReason: ReasonEntity;
  deleteReason: Scalars['Boolean'];
  deleteReceiptPrinter: Scalars['Boolean'];
  createReceiptPrinter: Scalars['Boolean'];
  updateReceiptPrinter: Scalars['Boolean'];
  createScheduledMenu: ScheduledMenuEntity;
  updateScheduledMenu: ScheduledMenuEntity;
  deleteScheduledMenu: Scalars['Boolean'];
  createScheduledMenuAndPeriods: Scalars['Boolean'];
  updateScheduledMenuMealPeriods: Scalars['Boolean'];
  deleteScheduledMenuMealPeriods: Scalars['Boolean'];
  createSetMenuOption: SetMenuOptionEntity;
  updateSetMenuOption: SetMenuOptionEntity;
  deleteSetMenuOption: Scalars['Boolean'];
  createSetMenuOptionItem: SetMenuOptionItemEntity;
  updateSetMenuOptionItem: SetMenuOptionItemEntity;
  deleteSetMenuOptionItem: Scalars['Boolean'];
  createSetMenuStandardOptionItem: Scalars['Boolean'];
  createSetMenuOptionOptionItem: Scalars['Boolean'];
  createStopSales: StopSalesEntity;
  updateStopSales: StopSalesEntity;
  deleteStopSales: Scalars['Boolean'];
  submitStopSales: Scalars['Boolean'];
  createTable: TableEntity;
  updateTable: TableEntity;
  deleteTable: Scalars['Boolean'];
  submitTable: Scalars['Boolean'];
  transferTable: Scalars['Boolean'];
  closeTableStatus: Scalars['Boolean'];
  dragTable: Scalars['Boolean'];
  createBill: BillEntity;
  updateBill: BillEntity;
  deleteBill: Scalars['Boolean'];
  createBillAndPayment: BillEntity;
  createReceiptAttachment?: Maybe<AttachmentEntity>;
  voidBill: Scalars['Boolean'];
  createBillDiscount: BillDiscountEntity;
  updateBillDiscount: BillDiscountEntity;
  deleteBillDiscount: Scalars['Boolean'];
  deleteOrder: Scalars['Boolean'];
  createOrder: OrderEntity;
  updateOrder: Scalars['Boolean'];
  pickupTakeawayOrder: Scalars['Boolean'];
  cancelTakeawayOrder: Scalars['Boolean'];
  updateOrderDiscount: OrderDiscountEntity;
  createOrderDiscount: Scalars['Boolean'];
  deleteOrderDiscount: Scalars['Boolean'];
  createOrderItem: OrderItemEntity;
  updateOrderItem: OrderItemEntity;
  createOrderItemWithOptions: Scalars['Boolean'];
  updateOrderItemWithOptions: Scalars['Boolean'];
  createOrderItemWithSetMenuOptions: Scalars['Boolean'];
  updateOrderItemWithSetMenuOptions: Scalars['Boolean'];
  confirmOrderItem: Scalars['Boolean'];
  voidOrderItemWithOptions: Scalars['Boolean'];
  voidOrderItemWithSetMenuOptions: Scalars['Boolean'];
  transferItemByQuantity: Scalars['Boolean'];
  postPrintData: Scalars['Boolean'];
  createOrderItemPatronApp: Scalars['Boolean'];
  updateOrderItemDiscount: OrderItemDiscountEntity;
  createOrderItemDiscount: Scalars['Boolean'];
  deleteOrderItemDiscount: Scalars['Boolean'];
  updateKitchenStatus: Scalars['Boolean'];
  revertKitchenStatus: Scalars['Boolean'];
  confirmKitchenStatus: Scalars['Boolean'];
  createOrderItemOption: OrderItemOptionEntity;
  updateOrderItemOption: OrderItemOptionEntity;
  deleteOrderItemOption: Scalars['Boolean'];
  createOrderItemOptionItem: OrderItemOptionItemEntity;
  updateOrderItemOptionItem: OrderItemOptionItemEntity;
  deleteOrderItemOptionItem: Scalars['Boolean'];
  createOrderItemSetMenuOption: OrderItemSetMenuOptionEntity;
  updateOrderItemSetMenuOption: OrderItemSetMenuOptionEntity;
  deleteOrderItemSetMenuOption: Scalars['Boolean'];
  createOrderItemSetMenuOptionItem: OrderItemSetMenuOptionItemEntity;
  updateOrderItemSetMenuOptionItem: OrderItemSetMenuOptionItemEntity;
  deleteOrderItemSetMenuOptionItem: Scalars['Boolean'];
  updateReservation: ReservationEntity;
  deleteReservation: Scalars['Boolean'];
  createReservation: Scalars['Boolean'];
  assignReservationTable: Scalars['Boolean'];
  cancelReservation: Scalars['Boolean'];
  reinstateReservation: Scalars['Boolean'];
  createTableSession: TableSessionEntity;
  updateTableSession: TableSessionEntity;
  deleteAddress: Scalars['Boolean'];
  createOnlinePayment?: Maybe<CheckOut>;
  refundPayment?: Maybe<CheckOut>;
  createPayment: PaymentEntity;
  updatePayment: PaymentEntity;
  deletePayment: Scalars['Boolean'];
  voidPayment: Scalars['Boolean'];
  paymentByQuantity: BillEntity;
  creditNoteBillPayment: Scalars['Boolean'];
  createPatronType: PatronTypeEntity;
  updatePatronType: PatronTypeEntity;
  deletePatronType: Scalars['Boolean'];
  createPaymentType: PaymentTypeEntity;
  updatePaymentType: PaymentTypeEntity;
  deletePaymentType: Scalars['Boolean'];
  createPlatformTax: PlatformTaxEntity;
  updatePlatformTax: PlatformTaxEntity;
  deletePlatformTax: Scalars['Boolean'];
  createUOM: UomEntity;
  updateUOM: UomEntity;
  deleteUOM: Scalars['Boolean'];
  createUOMConversion: Scalars['Boolean'];
  updateUOMConversion: Scalars['Boolean'];
  deleteUOMConversion: Scalars['Boolean'];
  createMissingPatron: Scalars['Boolean'];
  createMissingPaymentType: Scalars['Boolean'];
  createMissingNextNumber: Scalars['Boolean'];
  updateOutletBusinessDate: Scalars['Boolean'];
  setupNewSubscription: Scalars['Boolean'];
  createSuperUser: UserEntity;
  resetRolePermission: Scalars['Boolean'];
  createMajorDiscountGL: Scalars['Boolean'];
  createDefaultPaymentGL: Scalars['Boolean'];
  createOutletTaxPolicyGL: Scalars['Boolean'];
  createOutletDailyMenuCategory: OutletDailyMenuCategoryEntity;
  updateOutletDailyMenuCategory: OutletDailyMenuCategoryEntity;
  deleteOutletDailyMenuCategory: Scalars['Boolean'];
  syncOutletDailyMenu: Scalars['Boolean'];
  createOutletDailyMenuItem: OutletDailyMenuItemEntity;
  updateOutletDailyMenuItem: OutletDailyMenuItemEntity;
  deleteOutletDailyMenuItem: Scalars['Boolean'];
  createOutletDailyMenuItemOption: OutletDailyMenuItemOptionEntity;
  updateOutletDailyMenuItemOption: OutletDailyMenuItemOptionEntity;
  deleteOutletDailyMenuItemOption: Scalars['Boolean'];
  createOutletDailyMenuItemOptionItem: OutletDailyMenuItemOptionItemEntity;
  updateOutletDailyMenuItemOptionItem: OutletDailyMenuItemOptionItemEntity;
  deleteOutletDailyMenuItemOptionItem: Scalars['Boolean'];
  createOutletDailyMenuItemPatronPrice: OutletDailyMenuItemPatronPriceEntity;
  updateOutletDailyMenuItemPatronPrice: OutletDailyMenuItemPatronPriceEntity;
  deleteOutletDailyMenuItemPatronPrice: Scalars['Boolean'];
  createOutletDailyPromoMenuItem: OutletDailyPromoMenuItemEntity;
  updateOutletDailyPromoMenuItem: OutletDailyPromoMenuItemEntity;
  deleteOutletDailyPromoMenuItem: Scalars['Boolean'];
  createOutletDailyPromoMenuItemPrice: OutletDailyPromoMenuItemPriceEntity;
  updateOutletDailyPromoMenuItemPrice: OutletDailyPromoMenuItemPriceEntity;
  deleteOutletDailyPromoMenuItemPrice: Scalars['Boolean'];
  createOutletDailySetMenuOption: OutletDailySetMenuOptionEntity;
  updateOutletDailySetMenuOption: OutletDailySetMenuOptionEntity;
  deleteOutletDailySetMenuOption: Scalars['Boolean'];
  createOutletDailySetMenuOptionItem: OutletDailySetMenuOptionItemEntity;
  updateOutletDailySetMenuOptionItem: OutletDailySetMenuOptionItemEntity;
  deleteOutletDailySetMenuOptionItem: Scalars['Boolean'];
  batchDeleteOrderItem: Scalars['Boolean'];
  dailyMenuRevenueInsert: Scalars['Boolean'];
};


export type MutationSubmitOutletGlInterfaceArgs = {
  glInput: AccountMappingGlInput;
  outletID: Scalars['String'];
};


export type MutationAccountMappingTaxGlInsertArgs = {
  TaxGLInput: AccountMappingTaxGlInput;
  accountID: Scalars['String'];
  outletID: Scalars['String'];
};


export type MutationUpdateBankAccountArgs = {
  outletID: Scalars['String'];
  contactInput: Array<BankAccountContactInput>;
  bankAccountInput: BankAccountInput;
};


export type MutationCreateBankAccountArgs = {
  outletID: Scalars['String'];
  contactInput: Array<BankAccountContactInput>;
  bankAccountInput: BankAccountInput;
};


export type MutationDeleteBankAccountArgs = {
  ID: Scalars['String'];
};


export type MutationCreateBankAccountContactArgs = {
  input: BankAccountContactInput;
};


export type MutationUpdateBankAccountContactArgs = {
  input: BankAccountContactInput;
};


export type MutationDeleteBankAccountContactArgs = {
  ID: Scalars['String'];
};


export type MutationPostCbExportArgs = {
  isTransfer?: Maybe<Scalars['Boolean']>;
  cbDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  startDate: Scalars['DateTime'];
  outletID: Scalars['String'];
  accountID: Scalars['String'];
};


export type MutationPostGlExportArgs = {
  isTransfer?: Maybe<Scalars['Boolean']>;
  glDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  startDate: Scalars['DateTime'];
  outletID: Scalars['String'];
  accountID: Scalars['String'];
};


export type MutationCreateAttachmentArgs = {
  input: AttachmentInput;
};


export type MutationUpdateAttachmentArgs = {
  input: AttachmentInput;
};


export type MutationDeleteAttachmentArgs = {
  ID: Scalars['String'];
};


export type MutationShareAttachmentArgs = {
  file: Scalars['Upload'];
  outletID: Scalars['String'];
};


export type MutationCreateCompanyArgs = {
  companyInput: CompanyInput;
};


export type MutationUpdateCompanyArgs = {
  companyInput: CompanyInput;
};


export type MutationCompanyUpdateStatusArgs = {
  companyID: Scalars['String'];
  status: Scalars['String'];
};


export type MutationGenerateConsolidatedDocArgs = {
  description?: Maybe<Scalars['String']>;
  docDate: Scalars['DateTime'];
  trxDate: Scalars['DateTime'];
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  isSearch?: Maybe<Scalars['Boolean']>;
  docNo?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  month?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
  docType?: Maybe<Scalars['String']>;
  companyID: Scalars['String'];
  accountID: Scalars['String'];
  outletID: Scalars['String'];
};


export type MutationCreateRolePermissionArgs = {
  permissionArr: Array<PosPermission>;
  input: RoleInput;
};


export type MutationUpdateRolePermissionArgs = {
  permissionArr: Array<PosPermission>;
  input: RoleInput;
};


export type MutationDeleteRolePermissionArgs = {
  roleID: Scalars['String'];
};


export type MutationCreateEntityRoleUserArgs = {
  input: EntityRoleUserAsgInput;
};


export type MutationCreateListEntityRoleUserArgs = {
  roleUserIDs: Array<RoleUserInput>;
  outletID: Scalars['String'];
};


export type MutationDuplicateRolePermissionArgs = {
  name: Scalars['String'];
  roleID: Scalars['String'];
};


export type MutationCreateEInvoicePolicyArgs = {
  input: EInvoicePolicyInput;
};


export type MutationUpdateEInvoicePolicyArgs = {
  input: EInvoicePolicyInput;
};


export type MutationDeleteEInvoicePolicyArgs = {
  ID: Scalars['String'];
};


export type MutationSubmitEInvoicePolicyArgs = {
  input: EInvoicePolicyInput;
};


export type MutationCreateOfficerArgs = {
  input: OfficerInput;
};


export type MutationUpdateOfficerArgs = {
  input: OfficerInput;
};


export type MutationDeleteOfficerArgs = {
  ID: Scalars['String'];
};


export type MutationCreateRoundingPolicyArgs = {
  input: RoundingPolicyInput;
};


export type MutationUpdateRoundingPolicyArgs = {
  input: RoundingPolicyInput;
};


export type MutationDeleteRoundingPolicyArgs = {
  ID: Scalars['String'];
};


export type MutationSubmitRoundingPolicyArgs = {
  input: RoundingPolicyInput;
};


export type MutationCreateStaffArgs = {
  input: StaffInput;
};


export type MutationUpdateStaffArgs = {
  input: StaffInput;
};


export type MutationDeleteStaffArgs = {
  ID: Scalars['String'];
};


export type MutationTaxSchemeInsertArgs = {
  taxSchemeInput: TaxSchemeInput;
  accountID: Scalars['String'];
};


export type MutationTaxSchemeUpdateArgs = {
  editTaxSchemeInput: EditTaxSchemeInput;
  accountID: Scalars['String'];
};


export type MutationTaxSchemeDeleteArgs = {
  TaxSchemeID: Scalars['String'];
};


export type MutationSetDefaultTaxSchemeArgs = {
  taxSchemeID: Scalars['String'];
};


export type MutationTaxInsertArgs = {
  taxInsertInput: TaxSetupInput;
  accountID: Scalars['String'];
};


export type MutationTaxUpdateArgs = {
  taxEditInput: EditTaxSetupInput;
  accountID: Scalars['String'];
};


export type MutationTaxDeleteArgs = {
  taxDeleteInput: Array<TaxDetailInput>;
  taxID: Scalars['String'];
};


export type MutationCreateUserArgs = {
  OutletID: Scalars['String'];
  input: UserInput;
};


export type MutationResendInvitationArgs = {
  userID?: Maybe<Scalars['String']>;
};


export type MutationUpdateUserArgs = {
  input: UserInput;
};


export type MutationCreatePasswordArgs = {
  input: PasswordInfo;
};


export type MutationCreatePasswordTestArgs = {
  input: PasswordInfo;
};


export type MutationActivateUserArgs = {
  token: Scalars['String'];
};


export type MutationLoginArgs = {
  password: Scalars['String'];
  loginID: Scalars['String'];
};


export type MutationChangePasswordArgs = {
  newPassword: Scalars['String'];
  currentPassword: Scalars['String'];
};


export type MutationLogoutArgs = {
  outletID?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
};


export type MutationResetPasswordArgs = {
  accountID?: Maybe<Scalars['String']>;
  userID?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  token: Scalars['String'];
};


export type MutationTotpValidateArgs = {
  token: Scalars['String'];
};


export type MutationUploadProfilePicArgs = {
  file?: Maybe<Scalars['Upload']>;
  userID: Scalars['String'];
};


export type MutationForgotPasswordArgs = {
  email: Scalars['String'];
};


export type MutationUnblockUserArgs = {
  input: PasswordInfo;
};


export type MutationCustomerAccessArgs = {
  qrID: Scalars['String'];
  outletID: Scalars['String'];
  accountID: Scalars['String'];
};


export type MutationCreateDailyMenuItemAvailabilityArgs = {
  input: DailyMenuItemAvailabilityInput;
};


export type MutationUpdateDailyMenuItemAvailabilityArgs = {
  input: DailyMenuItemAvailabilityInput;
};


export type MutationDeleteDailyMenuItemAvailabilityArgs = {
  ID: Scalars['String'];
};


export type MutationSubmitDailyMenuItemAvailabilityArgs = {
  outletID: Scalars['String'];
  input: Array<DailyMenuItemAvailabilityInput>;
};


export type MutationCreateDailyMenuPriceArgs = {
  input: DailyMenuPriceInput;
};


export type MutationUpdateDailyMenuPriceArgs = {
  input: DailyMenuPriceInput;
};


export type MutationDeleteDailyMenuPriceArgs = {
  ID: Scalars['String'];
};


export type MutationCreateDefaultPaymentArgs = {
  input: DefaultPaymentInput;
};


export type MutationUpdateDefaultPaymentArgs = {
  input: DefaultPaymentInput;
};


export type MutationDeleteDefaultPaymentArgs = {
  ID: Scalars['String'];
};


export type MutationSubmitDefaultPaymentArgs = {
  outletID: Scalars['String'];
  defaultPaymentIDs: Array<Scalars['String']>;
};


export type MutationCreateDigitalMenuArgs = {
  input: DigitalMenuInput;
};


export type MutationUpdateDigitalMenuArgs = {
  input: DigitalMenuInput;
};


export type MutationDeleteDigitalMenuArgs = {
  ID: Scalars['String'];
};


export type MutationDuplicateDigitalMenuArgs = {
  outletID: Scalars['String'];
  digitalMenuID: Scalars['String'];
};


export type MutationCreateDigitalMenuCategoryArgs = {
  input: DigitalMenuCategoryInput;
};


export type MutationUpdateDigitalMenuCategoryArgs = {
  input: DigitalMenuCategoryInput;
};


export type MutationDeleteDigitalMenuCategoryArgs = {
  ID: Scalars['String'];
};


export type MutationCreateDigitalMenuItemArgs = {
  input: DigitalMenuItemInput;
};


export type MutationUpdateDigitalMenuItemArgs = {
  input: DigitalMenuItemInput;
};


export type MutationDeleteDigitalMenuItemArgs = {
  ID: Scalars['String'];
};


export type MutationDeleteDigitalMenuItemPriceArgs = {
  ID: Scalars['String'];
};


export type MutationCreateDigitalMenuItemPriceArgs = {
  digitalMenuItemInput: Array<DigitalMenuItemInput>;
  digitalMenuCategoryID: Scalars['String'];
  outletID: Scalars['String'];
};


export type MutationUpdateDigitalMenuItemPriceArgs = {
  digitalMenuItemPriceInput: Array<DigitalMenuItemPriceInput>;
  outletID: Scalars['String'];
  digitalMenuItemID: Scalars['String'];
};


export type MutationDeleteDigitalMenuItemAndPriceArgs = {
  digitalMenuItemID: Scalars['String'];
};


export type MutationCreateDiscountArgs = {
  input: DiscountInput;
};


export type MutationUpdateDiscountArgs = {
  input: DiscountInput;
};


export type MutationDeleteDiscountArgs = {
  ID: Scalars['String'];
};


export type MutationCreateFamilyArgs = {
  input: FamilyInput;
};


export type MutationUpdateFamilyArgs = {
  input: FamilyInput;
};


export type MutationDeleteFamilyArgs = {
  ID: Scalars['String'];
};


export type MutationCreateKitchenPrinterArgs = {
  input: KitchenPrinterInput;
};


export type MutationUpdateKitchenPrinterArgs = {
  input: KitchenPrinterInput;
};


export type MutationDeleteKitchenPrinterArgs = {
  ID: Scalars['String'];
};


export type MutationCreateMajorArgs = {
  input: MajorInput;
};


export type MutationUpdateMajorArgs = {
  input: MajorInput;
};


export type MutationDeleteMajorArgs = {
  ID: Scalars['String'];
};


export type MutationCreateMajorWithGlArgs = {
  input: MajorInput;
};


export type MutationUpdateMajorWithGlArgs = {
  input: MajorInput;
};


export type MutationDeleteMajorWithGlArgs = {
  ID: Scalars['String'];
};


export type MutationDragMajorArgs = {
  outletID: Scalars['String'];
  input: Array<MajorInput>;
};


export type MutationCreateMealPeriodArgs = {
  input: MealPeriodInput;
};


export type MutationUpdateMealPeriodArgs = {
  input: MealPeriodInput;
};


export type MutationDeleteMealPeriodArgs = {
  ID: Scalars['String'];
};


export type MutationDuplicateOutletMenuArgs = {
  targetOutletID: Scalars['String'];
  sourceOutletID: Scalars['String'];
};


export type MutationCreateMenuItemArgs = {
  input: MenuItemInput;
};


export type MutationUpdateMenuItemArgs = {
  input: MenuItemInput;
};


export type MutationCreateMenuItemWithOptionsArgs = {
  menuItemOptionInput?: Maybe<Array<MenuItemOptionInput>>;
  menuItemInput: MenuItemInput;
};


export type MutationUpdateMenuItemWithOptionsArgs = {
  menuItemOptionInput?: Maybe<Array<MenuItemOptionInput>>;
  menuItemInput: MenuItemInput;
};


export type MutationDeleteMenuItemArgs = {
  ID: Scalars['String'];
};


export type MutationCreateMenuItemOptionArgs = {
  input: MenuItemOptionInput;
};


export type MutationUpdateMenuItemOptionArgs = {
  input: MenuItemOptionInput;
};


export type MutationDeleteMenuItemOptionArgs = {
  ID: Scalars['String'];
};


export type MutationCreateOperatingHoursArgs = {
  input: OperatingHoursInput;
};


export type MutationUpdateOperatingHoursArgs = {
  input: OperatingHoursInput;
};


export type MutationDeleteOperatingHoursArgs = {
  ID: Scalars['String'];
};


export type MutationCreateOptionArgs = {
  input: OptionInput;
};


export type MutationUpdateOptionArgs = {
  input: OptionInput;
};


export type MutationDeleteOptionArgs = {
  ID: Scalars['String'];
};


export type MutationDeleteOptionItemArgs = {
  ID: Scalars['String'];
};


export type MutationCreateOptionItemArgs = {
  input: OptionItemInput;
};


export type MutationUpdateOptionItemArgs = {
  input: OptionItemInput;
};


export type MutationDragOptionItemArgs = {
  optionID: Scalars['String'];
  input: Array<OptionItemInput>;
};


export type MutationDeleteOutletArgs = {
  ID: Scalars['String'];
};


export type MutationCreateOutletArgs = {
  outletInput: OutletInput;
};


export type MutationUpdateOutletArgs = {
  opHourInput: Array<OperatingHoursInput>;
  outletInput: OutletInput;
};


export type MutationUpdateOutletTaxSetupByTaxSchemeArgs = {
  taxSchemeID: Scalars['String'];
  outletID: Scalars['String'];
};


export type MutationCreateOutletCustomerPolicyArgs = {
  input: OutletCustomerPolicyInput;
};


export type MutationUpdateOutletCustomerPolicyArgs = {
  input: OutletCustomerPolicyInput;
};


export type MutationDeleteOutletCustomerPolicyArgs = {
  ID: Scalars['String'];
};


export type MutationSubmitOutletCustomerPolicyArgs = {
  outletCustomerPolicyInput: OutletCustomerPolicyInput;
};


export type MutationCreateOutletTaxPolicyArgs = {
  input: OutletTaxPolicyInput;
};


export type MutationUpdateOutletTaxPolicyArgs = {
  input: OutletTaxPolicyInput;
};


export type MutationDeleteOutletTaxPolicyArgs = {
  ID: Scalars['String'];
};


export type MutationUpdateOutletGovTaxPolicyArgs = {
  isInclusive: Scalars['Boolean'];
  outletID: Scalars['String'];
};


export type MutationCreatePatronArgs = {
  input: PatronInput;
};


export type MutationUpdatePatronArgs = {
  input: PatronInput;
};


export type MutationDeletePatronArgs = {
  ID: Scalars['String'];
};


export type MutationSubmitOutletPatronSetupArgs = {
  outletID: Scalars['String'];
  patronIDs: Array<Scalars['String']>;
};


export type MutationCreatePromoCodeArgs = {
  input: PromoCodeInput;
};


export type MutationUpdatePromoCodeArgs = {
  input: PromoCodeInput;
};


export type MutationDeletePromoCodeArgs = {
  ID: Scalars['String'];
};


export type MutationCreatePromoMenuArgs = {
  input: PromoMenuInput;
};


export type MutationUpdatePromoMenuArgs = {
  input: PromoMenuInput;
};


export type MutationDeletePromoMenuArgs = {
  ID: Scalars['String'];
};


export type MutationCreatePromoMenuItemArgs = {
  input: PromoMenuItemInput;
};


export type MutationUpdatePromoMenuItemArgs = {
  input: PromoMenuItemInput;
};


export type MutationDeletePromoMenuItemArgs = {
  ID: Scalars['String'];
};


export type MutationDeletePromoMenuItemPriceArgs = {
  ID: Scalars['String'];
};


export type MutationCreatePromoMenuItemPriceArgs = {
  promoMenuItemInput: Array<PromoMenuItemInput>;
  promoMenuID: Scalars['String'];
  outletID: Scalars['String'];
};


export type MutationUpdatePromoMenuItemPriceArgs = {
  promoMenuItemPriceInput: Array<PromoMenuItemPriceInput>;
  outletID: Scalars['String'];
  promoMenuItemID: Scalars['String'];
};


export type MutationDeletePromoMenuItemAndPriceArgs = {
  promoMenuItemID: Scalars['String'];
};


export type MutationCreateQrArgs = {
  input: QrInput;
};


export type MutationUpdateQrArgs = {
  input: QrInput;
};


export type MutationDeleteQrArgs = {
  ID: Scalars['String'];
};


export type MutationCreateReasonArgs = {
  input: ReasonInput;
};


export type MutationUpdateReasonArgs = {
  input: ReasonInput;
};


export type MutationDeleteReasonArgs = {
  ID: Scalars['String'];
};


export type MutationDeleteReceiptPrinterArgs = {
  ID: Scalars['String'];
};


export type MutationCreateReceiptPrinterArgs = {
  input: ReceiptPrinterInput;
};


export type MutationUpdateReceiptPrinterArgs = {
  input: ReceiptPrinterInput;
};


export type MutationCreateScheduledMenuArgs = {
  input: ScheduledMenuInput;
};


export type MutationUpdateScheduledMenuArgs = {
  input: ScheduledMenuInput;
};


export type MutationDeleteScheduledMenuArgs = {
  ID: Scalars['String'];
};


export type MutationCreateScheduledMenuAndPeriodsArgs = {
  scheduledMenuMealPeriodInput: Array<ScheduledMenuMealPeriodInput>;
  scheduledMenuInput: ScheduledMenuInput;
};


export type MutationUpdateScheduledMenuMealPeriodsArgs = {
  scheduledMenuMealPeriodInput: Array<ScheduledMenuMealPeriodInput>;
  scheduledMenuInput: ScheduledMenuInput;
};


export type MutationDeleteScheduledMenuMealPeriodsArgs = {
  scheduledMenuID: Scalars['String'];
};


export type MutationCreateSetMenuOptionArgs = {
  input: SetMenuOptionInput;
};


export type MutationUpdateSetMenuOptionArgs = {
  input: SetMenuOptionInput;
};


export type MutationDeleteSetMenuOptionArgs = {
  ID: Scalars['String'];
};


export type MutationCreateSetMenuOptionItemArgs = {
  input: SetMenuOptionItemInput;
};


export type MutationUpdateSetMenuOptionItemArgs = {
  input: SetMenuOptionItemInput;
};


export type MutationDeleteSetMenuOptionItemArgs = {
  ID: Scalars['String'];
};


export type MutationCreateSetMenuStandardOptionItemArgs = {
  outletID: Scalars['String'];
  setMenuID: Scalars['String'];
  setMenuOptionItemInput: Array<SetMenuOptionItemInput>;
};


export type MutationCreateSetMenuOptionOptionItemArgs = {
  setMenuOptionItemInput: Array<SetMenuOptionItemInput>;
};


export type MutationCreateStopSalesArgs = {
  input: StopSalesInput;
};


export type MutationUpdateStopSalesArgs = {
  input: StopSalesInput;
};


export type MutationDeleteStopSalesArgs = {
  ID: Scalars['String'];
};


export type MutationSubmitStopSalesArgs = {
  outletID: Scalars['String'];
  input: Array<StopSalesInput>;
};


export type MutationCreateTableArgs = {
  input: TableInput;
};


export type MutationUpdateTableArgs = {
  input: TableInput;
};


export type MutationDeleteTableArgs = {
  ID: Scalars['String'];
};


export type MutationSubmitTableArgs = {
  outletID: Scalars['String'];
  input?: Maybe<Array<TableInput>>;
};


export type MutationTransferTableArgs = {
  orderID: Scalars['String'];
  newTableID: Scalars['String'];
  oldTableID: Scalars['String'];
};


export type MutationCloseTableStatusArgs = {
  paymentByQuantityInput: Array<PaymentByQuantityInput>;
  billInput: Array<BillInput>;
  tableID: Scalars['String'];
};


export type MutationDragTableArgs = {
  input: Array<TableInput>;
};


export type MutationCreateBillArgs = {
  input: BillInput;
};


export type MutationUpdateBillArgs = {
  input: BillInput;
};


export type MutationDeleteBillArgs = {
  ID: Scalars['String'];
};


export type MutationCreateBillAndPaymentArgs = {
  isTakeaway?: Maybe<Scalars['Boolean']>;
  paymentInput: PaymentInput;
  billInput: BillInput;
  orderItemIDs: Array<Scalars['String']>;
};


export type MutationCreateReceiptAttachmentArgs = {
  file: Scalars['Upload'];
  outletID: Scalars['String'];
  billID: Scalars['String'];
};


export type MutationVoidBillArgs = {
  scheduleBillingInput?: Maybe<ScheduleBillingInput>;
  billLedgerInput?: Maybe<BillLedgerInput>;
  billInput?: Maybe<BillInput>;
  paymentInput: PaymentInput;
};


export type MutationCreateBillDiscountArgs = {
  input: BillDiscountInput;
};


export type MutationUpdateBillDiscountArgs = {
  input: BillDiscountInput;
};


export type MutationDeleteBillDiscountArgs = {
  ID: Scalars['String'];
};


export type MutationDeleteOrderArgs = {
  ID: Scalars['String'];
};


export type MutationCreateOrderArgs = {
  input: OrderInput;
};


export type MutationUpdateOrderArgs = {
  input: OrderInput;
};


export type MutationPickupTakeawayOrderArgs = {
  orderID: Scalars['String'];
};


export type MutationCancelTakeawayOrderArgs = {
  orderID: Scalars['String'];
};


export type MutationUpdateOrderDiscountArgs = {
  input: OrderDiscountInput;
};


export type MutationCreateOrderDiscountArgs = {
  input: OrderDiscountInput;
};


export type MutationDeleteOrderDiscountArgs = {
  input: OrderDiscountInput;
};


export type MutationCreateOrderItemArgs = {
  input: OrderItemInput;
};


export type MutationUpdateOrderItemArgs = {
  input: OrderItemInput;
};


export type MutationCreateOrderItemWithOptionsArgs = {
  orderItemOptionInput?: Maybe<Array<OrderItemOptionInput>>;
  orderItemInput: OrderItemInput;
};


export type MutationUpdateOrderItemWithOptionsArgs = {
  orderItemOptionInput?: Maybe<Array<OrderItemOptionInput>>;
  orderItemInput: OrderItemInput;
};


export type MutationCreateOrderItemWithSetMenuOptionsArgs = {
  orderItemSetMenuOptionInput?: Maybe<Array<OrderItemSetMenuOptionInput>>;
  orderItemInput: OrderItemInput;
};


export type MutationUpdateOrderItemWithSetMenuOptionsArgs = {
  orderItemSetMenuOptionInput?: Maybe<Array<OrderItemSetMenuOptionInput>>;
  orderItemInput: OrderItemInput;
};


export type MutationConfirmOrderItemArgs = {
  orderID: Scalars['String'];
  orderItemIDs: Array<Scalars['String']>;
};


export type MutationVoidOrderItemWithOptionsArgs = {
  file?: Maybe<Scalars['String']>;
  voidOrderItemInput: VoidOrderItemInput;
};


export type MutationVoidOrderItemWithSetMenuOptionsArgs = {
  voidOrderItemInput: VoidOrderItemInput;
};


export type MutationTransferItemByQuantityArgs = {
  sourceOrderID?: Maybe<Scalars['String']>;
  targetOrderID: Scalars['String'];
  transferInput: Array<TransferInput>;
};


export type MutationPostPrintDataArgs = {
  printData: PrintDataPayload;
};


export type MutationCreateOrderItemPatronAppArgs = {
  optionItemInput?: Maybe<Array<OrderItemOptionInput>>;
  orderInput: OrderItemInput;
};


export type MutationUpdateOrderItemDiscountArgs = {
  input: OrderItemDiscountInput;
};


export type MutationCreateOrderItemDiscountArgs = {
  input: OrderItemDiscountInput;
  orderID: Scalars['String'];
};


export type MutationDeleteOrderItemDiscountArgs = {
  input: OrderItemDiscountInput;
  orderID: Scalars['String'];
};


export type MutationUpdateKitchenStatusArgs = {
  InputItemID: Scalars['String'];
};


export type MutationRevertKitchenStatusArgs = {
  InputItemID: Scalars['String'];
};


export type MutationConfirmKitchenStatusArgs = {
  InputItemID: Array<Scalars['String']>;
};


export type MutationCreateOrderItemOptionArgs = {
  input: OrderItemOptionInput;
};


export type MutationUpdateOrderItemOptionArgs = {
  input: OrderItemOptionInput;
};


export type MutationDeleteOrderItemOptionArgs = {
  ID: Scalars['String'];
};


export type MutationCreateOrderItemOptionItemArgs = {
  input: OrderItemOptionItemInput;
};


export type MutationUpdateOrderItemOptionItemArgs = {
  input: OrderItemOptionItemInput;
};


export type MutationDeleteOrderItemOptionItemArgs = {
  ID: Scalars['String'];
};


export type MutationCreateOrderItemSetMenuOptionArgs = {
  input: OrderItemSetMenuOptionInput;
};


export type MutationUpdateOrderItemSetMenuOptionArgs = {
  input: OrderItemSetMenuOptionInput;
};


export type MutationDeleteOrderItemSetMenuOptionArgs = {
  ID: Scalars['String'];
};


export type MutationCreateOrderItemSetMenuOptionItemArgs = {
  input: OrderItemSetMenuOptionItemInput;
};


export type MutationUpdateOrderItemSetMenuOptionItemArgs = {
  input: OrderItemSetMenuOptionItemInput;
};


export type MutationDeleteOrderItemSetMenuOptionItemArgs = {
  ID: Scalars['String'];
};


export type MutationUpdateReservationArgs = {
  input: ReservationInput;
};


export type MutationDeleteReservationArgs = {
  ID: Scalars['String'];
};


export type MutationCreateReservationArgs = {
  input: ReservationInput;
};


export type MutationAssignReservationTableArgs = {
  reservationID: Scalars['String'];
  tableID: Scalars['String'];
};


export type MutationCancelReservationArgs = {
  reservationID: Scalars['String'];
};


export type MutationReinstateReservationArgs = {
  reservationID: Scalars['String'];
};


export type MutationCreateTableSessionArgs = {
  input: TableSessionInput;
};


export type MutationUpdateTableSessionArgs = {
  input: TableSessionInput;
};


export type MutationDeleteAddressArgs = {
  ID: Scalars['String'];
};


export type MutationCreateOnlinePaymentArgs = {
  redirectUrl: Scalars['String'];
  payAmount: Scalars['Float'];
  detail: Scalars['String'];
  title: Scalars['String'];
  outletID: Scalars['String'];
};


export type MutationRefundPaymentArgs = {
  billID: Scalars['String'];
  outletID: Scalars['String'];
};


export type MutationCreatePaymentArgs = {
  input: PaymentInput;
};


export type MutationUpdatePaymentArgs = {
  input: PaymentInput;
};


export type MutationDeletePaymentArgs = {
  ID: Scalars['String'];
};


export type MutationVoidPaymentArgs = {
  scheduleBillingInput?: Maybe<ScheduleBillingInput>;
  billLedgerInput?: Maybe<BillLedgerInput>;
  voidPaymentIDs: Array<Scalars['String']>;
  billInput?: Maybe<BillInput>;
  paymentInput: Array<PaymentInput>;
};


export type MutationPaymentByQuantityArgs = {
  billLedgerInput?: Maybe<BillLedgerInput>;
  scheduleBillingInput?: Maybe<ScheduleBillingInput>;
  billInput: BillInput;
  paymentInput: Array<PaymentInput>;
  paymentByQuantityInput: Array<PaymentByQuantityInput>;
};


export type MutationCreditNoteBillPaymentArgs = {
  billInput: BillInput;
};


export type MutationCreatePatronTypeArgs = {
  input: PatronTypeInput;
};


export type MutationUpdatePatronTypeArgs = {
  input: PatronTypeInput;
};


export type MutationDeletePatronTypeArgs = {
  ID: Scalars['String'];
};


export type MutationCreatePaymentTypeArgs = {
  input: PaymentTypeInput;
};


export type MutationUpdatePaymentTypeArgs = {
  input: PaymentTypeInput;
};


export type MutationDeletePaymentTypeArgs = {
  ID: Scalars['String'];
};


export type MutationCreatePlatformTaxArgs = {
  input: PlatformTaxInput;
};


export type MutationUpdatePlatformTaxArgs = {
  input: PlatformTaxInput;
};


export type MutationDeletePlatformTaxArgs = {
  ID: Scalars['String'];
};


export type MutationCreateUomArgs = {
  input: UomInput;
};


export type MutationUpdateUomArgs = {
  input: UomInput;
};


export type MutationDeleteUomArgs = {
  ID: Scalars['String'];
};


export type MutationCreateUomConversionArgs = {
  input: UomConversionInput;
};


export type MutationUpdateUomConversionArgs = {
  input: UomConversionInput;
};


export type MutationDeleteUomConversionArgs = {
  ID: Scalars['String'];
};


export type MutationCreateMissingPatronArgs = {
  outletID: Scalars['String'];
};


export type MutationCreateMissingPaymentTypeArgs = {
  outletIDs: Array<Scalars['String']>;
};


export type MutationCreateMissingNextNumberArgs = {
  outletIDs: Array<Scalars['String']>;
};


export type MutationUpdateOutletBusinessDateArgs = {
  outletIDs: Array<Scalars['String']>;
};


export type MutationSetupNewSubscriptionArgs = {
  outletIDs: Scalars['String'];
};


export type MutationCreateSuperUserArgs = {
  accountID: Scalars['String'];
  input: UserInput;
};


export type MutationResetRolePermissionArgs = {
  accountID: Scalars['String'];
};


export type MutationCreateOutletDailyMenuCategoryArgs = {
  input: OutletDailyMenuCategoryInput;
};


export type MutationUpdateOutletDailyMenuCategoryArgs = {
  input: OutletDailyMenuCategoryInput;
};


export type MutationDeleteOutletDailyMenuCategoryArgs = {
  ID: Scalars['String'];
};


export type MutationSyncOutletDailyMenuArgs = {
  manual?: Maybe<Scalars['Boolean']>;
  outletID: Scalars['String'];
};


export type MutationCreateOutletDailyMenuItemArgs = {
  input: OutletDailyMenuItemInput;
};


export type MutationUpdateOutletDailyMenuItemArgs = {
  input: OutletDailyMenuItemInput;
};


export type MutationDeleteOutletDailyMenuItemArgs = {
  ID: Scalars['String'];
};


export type MutationCreateOutletDailyMenuItemOptionArgs = {
  input: OutletDailyMenuItemOptionInput;
};


export type MutationUpdateOutletDailyMenuItemOptionArgs = {
  input: OutletDailyMenuItemOptionInput;
};


export type MutationDeleteOutletDailyMenuItemOptionArgs = {
  ID: Scalars['String'];
};


export type MutationCreateOutletDailyMenuItemOptionItemArgs = {
  input: OutletDailyMenuItemOptionItemInput;
};


export type MutationUpdateOutletDailyMenuItemOptionItemArgs = {
  input: OutletDailyMenuItemOptionItemInput;
};


export type MutationDeleteOutletDailyMenuItemOptionItemArgs = {
  ID: Scalars['String'];
};


export type MutationCreateOutletDailyMenuItemPatronPriceArgs = {
  input: OutletDailyMenuItemPatronPriceInput;
};


export type MutationUpdateOutletDailyMenuItemPatronPriceArgs = {
  input: OutletDailyMenuItemPatronPriceInput;
};


export type MutationDeleteOutletDailyMenuItemPatronPriceArgs = {
  ID: Scalars['String'];
};


export type MutationCreateOutletDailyPromoMenuItemArgs = {
  input: OutletDailyPromoMenuItemInput;
};


export type MutationUpdateOutletDailyPromoMenuItemArgs = {
  input: OutletDailyPromoMenuItemInput;
};


export type MutationDeleteOutletDailyPromoMenuItemArgs = {
  ID: Scalars['String'];
};


export type MutationCreateOutletDailyPromoMenuItemPriceArgs = {
  input: OutletDailyPromoMenuItemPriceInput;
};


export type MutationUpdateOutletDailyPromoMenuItemPriceArgs = {
  input: OutletDailyPromoMenuItemPriceInput;
};


export type MutationDeleteOutletDailyPromoMenuItemPriceArgs = {
  ID: Scalars['String'];
};


export type MutationCreateOutletDailySetMenuOptionArgs = {
  input: OutletDailySetMenuOptionInput;
};


export type MutationUpdateOutletDailySetMenuOptionArgs = {
  input: OutletDailySetMenuOptionInput;
};


export type MutationDeleteOutletDailySetMenuOptionArgs = {
  ID: Scalars['String'];
};


export type MutationCreateOutletDailySetMenuOptionItemArgs = {
  input: OutletDailySetMenuOptionItemInput;
};


export type MutationUpdateOutletDailySetMenuOptionItemArgs = {
  input: OutletDailySetMenuOptionItemInput;
};


export type MutationDeleteOutletDailySetMenuOptionItemArgs = {
  ID: Scalars['String'];
};


export type MutationBatchDeleteOrderItemArgs = {
  orderItemID: Array<Scalars['String']>;
};


export type MutationDailyMenuRevenueInsertArgs = {
  endDate?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  accountID?: Maybe<Scalars['String']>;
  hotelID?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
};

export type NextNumberEntity = {
  __typename?: 'NextNumberEntity';
  ID: Scalars['String'];
  accountID: Scalars['String'];
  outletID: Scalars['String'];
  docType: DocType;
  prefix: Scalars['String'];
  fill: Scalars['Float'];
  startNumber: Scalars['Float'];
  nextNumber: Scalars['Float'];
};

export type OfficerEntity = AuditEntity & {
  __typename?: 'OfficerEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  name: Scalars['String'];
  designation: Scalars['String'];
  mobileNo: Scalars['String'];
  email: Scalars['String'];
};

export type OfficerInput = {
  name: Scalars['String'];
  designation: Scalars['String'];
  mobileNo: Scalars['String'];
  email: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  ID?: Maybe<Scalars['String']>;
};

export type OperatingHoursEntity = AuditEntity & {
  __typename?: 'OperatingHoursEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  outletID: Scalars['String'];
  outlet?: Maybe<OutletEntity>;
  day: RecurrenceDay;
  openingTime: Scalars['String'];
  closingTime: Scalars['String'];
};

export type OperatingHoursInput = {
  outletID?: Maybe<Scalars['String']>;
  openingTime: Scalars['String'];
  closingTime: Scalars['String'];
  day: RecurrenceDay;
};

export type OptionEntity = AuditEntity & {
  __typename?: 'OptionEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  name: Scalars['String'];
  outletID: Scalars['String'];
  optionItem?: Maybe<Array<OptionItemEntity>>;
  menuItemOption?: Maybe<Array<MenuItemOptionEntity>>;
  orderItemOption?: Maybe<Array<OrderItemOptionEntity>>;
  paymentItemOption?: Maybe<Array<PaymentItemOptionEntity>>;
};

export type OptionInput = {
  ID?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['String']>;
  outletID: Scalars['String'];
  name: Scalars['String'];
};

/** JsonType */
export type OptionItemDiscountTaxInfo = {
  __typename?: 'OptionItemDiscountTaxInfo';
  ID: Scalars['String'];
  orderItemDiscountID: Scalars['String'];
  orderItemOptionItemID: Scalars['String'];
  taxID: Scalars['String'];
  taxSchemeDetailID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  taxAmount: Scalars['Float'];
  taxRate: Scalars['Float'];
};

export type OptionItemEntity = AuditEntity & {
  __typename?: 'OptionItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  name: Scalars['String'];
  priority?: Maybe<Scalars['Float']>;
  outletID?: Maybe<Scalars['String']>;
  optionID: Scalars['String'];
  option?: Maybe<OptionEntity>;
  menuItemOptionItem?: Maybe<MenuItemOptionItemEntity>;
  orderItemOptionItem?: Maybe<OrderItemOptionItemEntity>;
  paymentItemOptionItem?: Maybe<PaymentItemOptionItemEntity>;
};

export type OptionItemInput = {
  ID?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['Float']>;
  outletID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  optionID: Scalars['String'];
};

export type OrderDiscountEntity = AuditEntity & {
  __typename?: 'OrderDiscountEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  discountID: Scalars['String'];
  outletID: Scalars['String'];
  businessDate: Scalars['DateTime'];
  status: OrderDiscountStatus;
  amount?: Maybe<Scalars['Float']>;
  discountedBy?: Maybe<Scalars['String']>;
  voidedBy?: Maybe<Scalars['String']>;
  orderID: Scalars['String'];
  order?: Maybe<OrderEntity>;
  reasonCode?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  isGLPosted?: Maybe<Scalars['Boolean']>;
  orderDiscountItem?: Maybe<Array<OrderDiscountItemEntity>>;
  /** CustomFieldResolver */
  discountTypeInfo?: Maybe<Scalars['JSON']>;
};

export type OrderDiscountInput = {
  outletID: Scalars['String'];
  orderID: Scalars['String'];
  discountID?: Maybe<Scalars['String']>;
  businessDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<OrderDiscountStatus>;
  amount?: Maybe<Scalars['Float']>;
  discountedBy?: Maybe<Scalars['String']>;
  voidedBy?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  reasonCode?: Maybe<Scalars['String']>;
};

export type OrderDiscountItemEntity = AuditEntity & {
  __typename?: 'OrderDiscountItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  orderDiscountID?: Maybe<Scalars['String']>;
  orderItemID?: Maybe<Scalars['String']>;
  outletID: Scalars['String'];
  amount?: Maybe<Scalars['Float']>;
  baseAmount?: Maybe<Scalars['Float']>;
  taxAmount?: Maybe<Scalars['Float']>;
  serviceCharge?: Maybe<Scalars['Float']>;
  orderDiscount?: Maybe<OrderDiscountEntity>;
  orderItem?: Maybe<OrderItemEntity>;
  orderDiscountItemTax?: Maybe<Array<OrderDiscountItemTaxV1Entity>>;
  orderDiscountItemOptionTax?: Maybe<Array<OrderDiscountItemOptionTaxEntity>>;
};

export type OrderDiscountItemOptionTaxEntity = AuditEntity & {
  __typename?: 'OrderDiscountItemOptionTaxEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  outletID: Scalars['String'];
  orderItemOptionItemID: Scalars['String'];
  orderDiscountItemID: Scalars['String'];
  taxSchemeID?: Maybe<Scalars['String']>;
  taxSchemeDetailID?: Maybe<Scalars['String']>;
  taxID: Scalars['String'];
  taxRate?: Maybe<Scalars['Float']>;
  taxAmount?: Maybe<Scalars['Float']>;
  orderDiscountItem?: Maybe<OrderDiscountItemEntity>;
  orderItemOptionItem?: Maybe<OrderItemOptionItemEntity>;
};

export type OrderDiscountItemTaxV1Entity = AuditEntity & {
  __typename?: 'OrderDiscountItemTaxV1Entity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  outletID: Scalars['String'];
  orderItemID: Scalars['String'];
  orderDiscountItemID: Scalars['String'];
  orderDiscountID: Scalars['String'];
  taxSchemeID?: Maybe<Scalars['String']>;
  taxSchemeDetailID?: Maybe<Scalars['String']>;
  taxID: Scalars['String'];
  taxRate?: Maybe<Scalars['Float']>;
  taxAmount?: Maybe<Scalars['Float']>;
  orderItem?: Maybe<OrderItemEntity>;
  orderDiscountItem?: Maybe<OrderDiscountItemEntity>;
};

export enum OrderDiscountStatus {
  Active = 'ACTIVE',
  Void = 'VOID'
}

export type OrderEntity = AuditEntity & {
  __typename?: 'OrderEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  outletID: Scalars['String'];
  registrationID?: Maybe<Scalars['String']>;
  docNo?: Maybe<Scalars['String']>;
  serviceMode: ServiceMode;
  openedByID: Scalars['String'];
  servedByIDs: Array<Scalars['String']>;
  paidByID?: Maybe<Scalars['String']>;
  businessDate: Scalars['DateTime'];
  pax?: Maybe<Scalars['Float']>;
  patronName?: Maybe<Scalars['String']>;
  contactNo?: Maybe<Scalars['String']>;
  pickupTime?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  status: OrderStatus;
  refOrderID?: Maybe<Scalars['String']>;
  orderItem?: Maybe<Array<OrderItemEntity>>;
  bill?: Maybe<Array<BillEntity>>;
  tableID?: Maybe<Scalars['String']>;
  table?: Maybe<TableEntity>;
  qrID: Scalars['String'];
  mealPeriodID: Scalars['String'];
  mealPeriod?: Maybe<MealPeriodEntity>;
  orderDiscount?: Maybe<Array<OrderDiscountEntity>>;
  patronID: Scalars['String'];
  patron?: Maybe<PatronEntity>;
  patronAccountID?: Maybe<Scalars['String']>;
  paymentItem?: Maybe<Array<PaymentItemEntity>>;
  /** CustomFieldResolver */
  qr?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  totalAmount?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  outstandingAmount?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  totalDiscountAmount?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  taxSchemeInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  taxSchemeFooterInfo?: Maybe<Scalars['JSON']>;
};

/** JsonType */
export type OrderInfo = {
  __typename?: 'OrderInfo';
  totalAmount: Scalars['Float'];
  totalPax: Scalars['Float'];
};

export type OrderInput = {
  ID?: Maybe<Scalars['String']>;
  outletID: Scalars['String'];
  tableID?: Maybe<Scalars['String']>;
  qrID: Scalars['String'];
  serviceMode?: Maybe<ServiceMode>;
  openedByID?: Maybe<Scalars['String']>;
  servedByIDs?: Maybe<Array<Scalars['String']>>;
  promoIDs?: Maybe<Array<Scalars['String']>>;
  paidByID?: Maybe<Scalars['String']>;
  businessDate?: Maybe<Scalars['DateTime']>;
  pax?: Maybe<Scalars['Float']>;
  mealPeriodID?: Maybe<Scalars['String']>;
  patronID: Scalars['String'];
  patronAccountID?: Maybe<Scalars['String']>;
  patronName?: Maybe<Scalars['String']>;
  contactNo?: Maybe<Scalars['String']>;
  pickupTime?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  registrationID?: Maybe<Scalars['String']>;
  status?: Maybe<OrderStatus>;
};

export type OrderItemDiscountEntity = AuditEntity & {
  __typename?: 'OrderItemDiscountEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  outletID: Scalars['String'];
  discountID: Scalars['String'];
  orderItemID: Scalars['String'];
  orderItem?: Maybe<OrderItemEntity>;
  status: OrderItemDiscountStatus;
  amount?: Maybe<Scalars['Float']>;
  baseAmount?: Maybe<Scalars['Float']>;
  taxAmount?: Maybe<Scalars['Float']>;
  serviceCharge?: Maybe<Scalars['Float']>;
  discountedBy?: Maybe<Scalars['String']>;
  voidedBy?: Maybe<Scalars['String']>;
  reasonCode?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  isGLPosted?: Maybe<Scalars['Boolean']>;
  optAmount?: Maybe<Scalars['Float']>;
  optBaseAmount?: Maybe<Scalars['Float']>;
  optTaxAmount?: Maybe<Scalars['Float']>;
  optServiceCharge?: Maybe<Scalars['Float']>;
  orderItemDiscountTax?: Maybe<Array<OrderItemDiscountTaxEntity>>;
  orderItemOptionItemDiscountTax?: Maybe<Array<OrderItemOptionItemDiscountTaxEntity>>;
};

export type OrderItemDiscountInput = {
  outletID: Scalars['String'];
  orderItemID: Scalars['String'];
  status?: Maybe<OrderItemDiscountStatus>;
  discountID?: Maybe<Scalars['String']>;
  taxAmount?: Maybe<Scalars['Float']>;
  serviceCharge?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Float']>;
  baseAmount?: Maybe<Scalars['Float']>;
  discountedBy?: Maybe<Scalars['String']>;
  voidedBy?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  reasonCode?: Maybe<Scalars['String']>;
  optAmount?: Maybe<Scalars['Float']>;
  optBaseAmount?: Maybe<Scalars['Float']>;
  optTaxAmount?: Maybe<Scalars['Float']>;
  optServiceCharge?: Maybe<Scalars['Float']>;
};

export enum OrderItemDiscountStatus {
  Active = 'ACTIVE',
  Void = 'VOID'
}

export type OrderItemDiscountTaxEntity = AuditEntity & {
  __typename?: 'OrderItemDiscountTaxEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  outletID: Scalars['String'];
  orderItemID: Scalars['String'];
  orderItemDiscountID: Scalars['String'];
  taxSchemeID?: Maybe<Scalars['String']>;
  taxSchemeDetailID?: Maybe<Scalars['String']>;
  taxID: Scalars['String'];
  taxRate?: Maybe<Scalars['Float']>;
  taxAmount?: Maybe<Scalars['Float']>;
  orderItemDiscount?: Maybe<OrderItemDiscountEntity>;
  orderItem?: Maybe<OrderItemEntity>;
};

export type OrderItemEntity = AuditEntity & {
  __typename?: 'OrderItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  openItemName?: Maybe<Scalars['String']>;
  outletID: Scalars['String'];
  refID?: Maybe<Scalars['String']>;
  specialRequest?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Float']>;
  taxAmount?: Maybe<Scalars['Float']>;
  serviceCharge?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Float']>;
  baseAmount?: Maybe<Scalars['Float']>;
  isTakeaway?: Maybe<Scalars['Boolean']>;
  isPrintToKitchen?: Maybe<Scalars['Boolean']>;
  businessDate: Scalars['DateTime'];
  status: OrderItemStatus;
  kitchenStatus?: Maybe<KitchenStatus>;
  unitPrice: Scalars['Float'];
  quantity: Scalars['Float'];
  name: Scalars['String'];
  description: Scalars['String'];
  reasonCode?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  promoID?: Maybe<Scalars['String']>;
  orderID: Scalars['String'];
  order?: Maybe<OrderEntity>;
  orderItemDiscount?: Maybe<Array<OrderItemDiscountEntity>>;
  paymentItem?: Maybe<Array<PaymentItemEntity>>;
  menuItemID: Scalars['String'];
  menuItem?: Maybe<MenuItemEntity>;
  mealPeriodID: Scalars['String'];
  mealPeriod?: Maybe<MealPeriodEntity>;
  billID?: Maybe<Scalars['String']>;
  bill?: Maybe<BillEntity>;
  orderItemOption?: Maybe<Array<OrderItemOptionEntity>>;
  orderItemSetMenuOption?: Maybe<Array<OrderItemSetMenuOptionEntity>>;
  isGLPosted?: Maybe<Scalars['Boolean']>;
  orderDiscountItem?: Maybe<OrderDiscountItemEntity>;
  orderItemTax?: Maybe<Array<OrderItemTaxEntity>>;
  orderItemDiscountTax?: Maybe<Array<OrderItemDiscountTaxEntity>>;
  orderDiscountItemTax?: Maybe<Array<OrderDiscountItemTaxV1Entity>>;
  digitalMenuCategoryID?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  totalInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  unitPriceInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  orderDiscount?: Maybe<Scalars['Float']>;
};

export type OrderItemInput = {
  ID?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['String']>;
  openItemName?: Maybe<Scalars['String']>;
  billID?: Maybe<Scalars['String']>;
  refID?: Maybe<Scalars['String']>;
  specialRequest?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Float']>;
  taxAmount?: Maybe<Scalars['Float']>;
  serviceCharge?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Float']>;
  baseAmount?: Maybe<Scalars['Float']>;
  isTakeaway?: Maybe<Scalars['Boolean']>;
  isPrintToKitchen?: Maybe<Scalars['Boolean']>;
  businessDate?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  quantity: Scalars['Float'];
  unitPrice: Scalars['Float'];
  menuItemID: Scalars['String'];
  outletID: Scalars['String'];
  orderID: Scalars['String'];
  mealPeriodID: Scalars['String'];
  status: OrderItemStatus;
  kitchenStatus?: Maybe<KitchenStatus>;
  digitalMenuCategoryID?: Maybe<Scalars['String']>;
};

export type OrderItemOptionEntity = AuditEntity & {
  __typename?: 'OrderItemOptionEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  outletID: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  orderItemID: Scalars['String'];
  orderItem?: Maybe<OrderItemEntity>;
  optionID: Scalars['String'];
  option?: Maybe<OptionEntity>;
  orderItemOptionItem?: Maybe<Array<OrderItemOptionItemEntity>>;
};

export type OrderItemOptionInput = {
  accountID?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
  orderItemID?: Maybe<Scalars['String']>;
  optionID: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  orderItemOptionItems: Array<OrderItemOptionItemInput>;
};

export type OrderItemOptionItemDiscountTaxEntity = AuditEntity & {
  __typename?: 'OrderItemOptionItemDiscountTaxEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  outletID: Scalars['String'];
  orderItemOptionItemID: Scalars['String'];
  orderItemDiscountID: Scalars['String'];
  taxSchemeID?: Maybe<Scalars['String']>;
  taxSchemeDetailID?: Maybe<Scalars['String']>;
  taxID: Scalars['String'];
  taxRate?: Maybe<Scalars['Float']>;
  taxAmount?: Maybe<Scalars['Float']>;
  orderItemDiscount?: Maybe<OrderItemDiscountEntity>;
  orderItemOptionItem?: Maybe<OrderItemOptionItemEntity>;
};

export type OrderItemOptionItemEntity = AuditEntity & {
  __typename?: 'OrderItemOptionItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  outletID: Scalars['String'];
  price?: Maybe<Scalars['Float']>;
  unitPrice?: Maybe<Scalars['Float']>;
  basePrice?: Maybe<Scalars['Float']>;
  taxAmount?: Maybe<Scalars['Float']>;
  serviceCharge?: Maybe<Scalars['Float']>;
  optionItemID: Scalars['String'];
  optionItem?: Maybe<OptionItemEntity>;
  orderItemOptionID: Scalars['String'];
  orderItemOption?: Maybe<OrderItemOptionEntity>;
  isGLPosted?: Maybe<Scalars['Boolean']>;
  discountAmount?: Maybe<Scalars['Float']>;
  discountBaseAmount?: Maybe<Scalars['Float']>;
  discountTaxAmount?: Maybe<Scalars['Float']>;
  discountServiceCharge?: Maybe<Scalars['Float']>;
  orderItemOptionItemTax?: Maybe<Array<OrderItemOptionItemTaxEntity>>;
  orderItemOptionItemDiscountTax?: Maybe<Array<OrderItemOptionItemDiscountTaxEntity>>;
  orderDiscountItemOptionTax?: Maybe<Array<OrderDiscountItemOptionTaxEntity>>;
};

export type OrderItemOptionItemInput = {
  ID?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  unitPrice?: Maybe<Scalars['Float']>;
  basePrice?: Maybe<Scalars['Float']>;
  taxAmount?: Maybe<Scalars['Float']>;
  optionItemID: Scalars['String'];
  orderItemOptionID?: Maybe<Scalars['String']>;
  discountAmount?: Maybe<Scalars['Float']>;
  discountBaseAmount?: Maybe<Scalars['Float']>;
  discountTaxAmount?: Maybe<Scalars['Float']>;
  discountServiceCharge?: Maybe<Scalars['Float']>;
};

export type OrderItemOptionItemTaxEntity = AuditEntity & {
  __typename?: 'OrderItemOptionItemTaxEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  outletID: Scalars['String'];
  orderItemID: Scalars['String'];
  orderItemOptionItemID: Scalars['String'];
  taxSchemeID?: Maybe<Scalars['String']>;
  taxSchemeDetailID?: Maybe<Scalars['String']>;
  taxID: Scalars['String'];
  taxRate?: Maybe<Scalars['Float']>;
  taxAmount?: Maybe<Scalars['Float']>;
  orderItemOptionItem?: Maybe<OrderItemOptionItemEntity>;
};

export type OrderItemSetMenuOptionEntity = AuditEntity & {
  __typename?: 'OrderItemSetMenuOptionEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  outletID: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  orderItemID: Scalars['String'];
  orderItem?: Maybe<OrderItemEntity>;
  setMenuOptionID: Scalars['String'];
  setMenuOption?: Maybe<SetMenuOptionEntity>;
  orderItemSetMenuOptionItem?: Maybe<Array<OrderItemSetMenuOptionItemEntity>>;
};

export type OrderItemSetMenuOptionInput = {
  accountID?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  setMenuOptionID: Scalars['String'];
  orderItemID?: Maybe<Scalars['String']>;
  orderItemSetMenuOptionItemInput: Array<OrderItemSetMenuOptionItemInput>;
};

export type OrderItemSetMenuOptionItemEntity = AuditEntity & {
  __typename?: 'OrderItemSetMenuOptionItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  outletID: Scalars['String'];
  quantity?: Maybe<Scalars['Float']>;
  setMenuOptionItemID: Scalars['String'];
  setMenuOptionItem?: Maybe<SetMenuOptionItemEntity>;
  orderItemSetMenuOptionID: Scalars['String'];
  orderItemSetMenuOption?: Maybe<OrderItemSetMenuOptionEntity>;
  menuItemID: Scalars['String'];
  menuItem?: Maybe<MenuItemEntity>;
};

export type OrderItemSetMenuOptionItemInput = {
  accountID?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  menuItemID: Scalars['String'];
  setMenuOptionItemID: Scalars['String'];
  orderItemSetMenuOptionID?: Maybe<Scalars['String']>;
};

export enum OrderItemStatus {
  Ordered = 'ORDERED',
  Served = 'SERVED',
  Paid = 'PAID',
  Preparing = 'PREPARING',
  Prepared = 'PREPARED',
  Pending = 'PENDING',
  Voided = 'VOIDED',
  Confirmed = 'CONFIRMED',
  PaidIn = 'PAID_IN',
  PaidOut = 'PAID_OUT',
  TransferIn = 'TRANSFER_IN',
  TransferOut = 'TRANSFER_OUT'
}

export type OrderItemTaxEntity = AuditEntity & {
  __typename?: 'OrderItemTaxEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  outletID: Scalars['String'];
  orderItemID: Scalars['String'];
  taxSchemeID?: Maybe<Scalars['String']>;
  taxSchemeDetailID?: Maybe<Scalars['String']>;
  taxID: Scalars['String'];
  taxRate?: Maybe<Scalars['Float']>;
  taxAmount?: Maybe<Scalars['Float']>;
  orderItem?: Maybe<OrderItemEntity>;
  /** CustomFieldResolver */
  taxSchemeType?: Maybe<Scalars['JSON']>;
};

export enum OrderStatus {
  Delivered = 'DELIVERED',
  InTransit = 'IN_TRANSIT',
  PaymentDue = 'PAYMENT_DUE',
  PaymentComplete = 'PAYMENT_COMPLETE',
  PickupAvailable = 'PICKUP_AVAILABLE',
  Problem = 'PROBLEM',
  Processing = 'PROCESSING',
  Returned = 'RETURNED',
  Open = 'OPEN',
  Closed = 'CLOSED',
  Cancelled = 'CANCELLED',
  PickedUp = 'PICKED_UP',
  Splitted = 'SPLITTED'
}

export type OutletChannelEntity = AuditEntity & {
  __typename?: 'OutletChannelEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  outletID: Scalars['String'];
  name: Scalars['String'];
  secret: Scalars['String'];
  user: Scalars['String'];
  url: Scalars['String'];
  hotelID: Scalars['String'];
  outlet?: Maybe<OutletEntity>;
};

export type OutletCustomerPolicyEntity = AuditEntity & {
  __typename?: 'OutletCustomerPolicyEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  outletID: Scalars['String'];
  selfOrder?: Maybe<Scalars['Boolean']>;
  selfPayment?: Maybe<Scalars['Boolean']>;
  qrCodeGenerate?: Maybe<Scalars['Boolean']>;
  outlet?: Maybe<OutletEntity>;
};

export type OutletCustomerPolicyInput = {
  outletID: Scalars['String'];
  selfOrder?: Maybe<Scalars['Boolean']>;
  selfPayment?: Maybe<Scalars['Boolean']>;
  qrCodeGenerate?: Maybe<Scalars['Boolean']>;
};

export type OutletDailyMenuCategoryEntity = AuditEntity & {
  __typename?: 'OutletDailyMenuCategoryEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  outletID: Scalars['String'];
  name: Scalars['String'];
  priority: Scalars['Float'];
  businessDate: Scalars['DateTime'];
  startTime: Scalars['String'];
  endTime: Scalars['String'];
  mealPeriodID: Scalars['String'];
  digitalMenuCategoryID: Scalars['String'];
  outletDailyMenuItem?: Maybe<Array<OutletDailyMenuItemEntity>>;
};

export type OutletDailyMenuCategoryInput = {
  ID?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  priority: Scalars['Float'];
  businessDate: Scalars['DateTime'];
  startTime: Scalars['String'];
  endTime: Scalars['String'];
  mealPeriodID: Scalars['String'];
  digitalMenuCategoryID: Scalars['String'];
  isByWeight: Scalars['Boolean'];
  outletID: Scalars['String'];
};

export type OutletDailyMenuItemEntity = AuditEntity & {
  __typename?: 'OutletDailyMenuItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  outletID: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
  defaultQuantity?: Maybe<Scalars['Float']>;
  uomID?: Maybe<Scalars['String']>;
  isOpenItem: Scalars['Boolean'];
  isOpenPrice: Scalars['Boolean'];
  isChefRecommended: Scalars['Boolean'];
  isQuantityControlled: Scalars['Boolean'];
  isTaxable: Scalars['Boolean'];
  isDiscountable: Scalars['Boolean'];
  isSetMenu: Scalars['Boolean'];
  isServiceChargeable: Scalars['Boolean'];
  isByWeight: Scalars['Boolean'];
  outletDailyMenuCategoryID: Scalars['String'];
  outletDailyMenuCategory?: Maybe<OutletDailyMenuCategoryEntity>;
  digitalMenuItemID: Scalars['String'];
  menuItemID: Scalars['String'];
  menuItem?: Maybe<MenuItemEntity>;
  outletDailyPromoMenuItem?: Maybe<Array<OutletDailyPromoMenuItemEntity>>;
  outletDailyMenuItemOption?: Maybe<Array<OutletDailyMenuItemOptionEntity>>;
  outletDailySetMenuOption?: Maybe<Array<OutletDailySetMenuOptionEntity>>;
  outletDailySetMenuOptionItem?: Maybe<Array<OutletDailySetMenuOptionItemEntity>>;
  outletDailyMenuItemPatronPrice?: Maybe<Array<OutletDailyMenuItemPatronPriceEntity>>;
  /** CustomFieldResolver */
  attachments?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  promoChecking?: Maybe<Scalars['Boolean']>;
};

export type OutletDailyMenuItemInput = {
  ID?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['String']>;
  outletDailyMenuCategoryID: Scalars['String'];
  menuItemID: Scalars['String'];
  digitalMenuItemID: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isOpenItem: Scalars['Boolean'];
  isOpenPrice: Scalars['Boolean'];
  isChefRecommended: Scalars['Boolean'];
  isQuantityControlled: Scalars['Boolean'];
  defaultQuantity: Scalars['Float'];
  isTaxable: Scalars['Boolean'];
  isDiscountable: Scalars['Boolean'];
  isSetMenu: Scalars['Boolean'];
  uomID: Scalars['String'];
  isByWeight: Scalars['Boolean'];
  isServiceChargeable: Scalars['Boolean'];
};

export type OutletDailyMenuItemOptionEntity = AuditEntity & {
  __typename?: 'OutletDailyMenuItemOptionEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  outletID: Scalars['String'];
  name: Scalars['String'];
  maxSelections: Scalars['Float'];
  outletDailyMenuItemID: Scalars['String'];
  outletDailyMenuItem?: Maybe<OutletDailyMenuItemEntity>;
  optionID: Scalars['String'];
  menuItemOptionID: Scalars['String'];
  outletDailyMenuItemOptionItem?: Maybe<Array<OutletDailyMenuItemOptionItemEntity>>;
};

export type OutletDailyMenuItemOptionInput = {
  ID: Scalars['String'];
  accountID?: Maybe<Scalars['String']>;
  outletDailyMenuItemID: Scalars['String'];
  optionID: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  maxSelections?: Maybe<Scalars['Float']>;
};

export type OutletDailyMenuItemOptionItemEntity = AuditEntity & {
  __typename?: 'OutletDailyMenuItemOptionItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  outletID: Scalars['String'];
  name: Scalars['String'];
  price: Scalars['Float'];
  optionItemID: Scalars['String'];
  outletDailyMenuItemOptionID: Scalars['String'];
  outletDailyMenuItemOption?: Maybe<OutletDailyMenuItemOptionEntity>;
};

export type OutletDailyMenuItemOptionItemInput = {
  ID?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  outletDailyMenuItemOptionID?: Maybe<Scalars['String']>;
  optionItemID?: Maybe<Scalars['String']>;
};

export type OutletDailyMenuItemPatronPriceEntity = AuditEntity & {
  __typename?: 'OutletDailyMenuItemPatronPriceEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  outletID: Scalars['String'];
  price: Scalars['Float'];
  name: Scalars['String'];
  description: Scalars['String'];
  patronClass: Scalars['String'];
  outletDailyMenuItemID: Scalars['String'];
  outletDailyMenuItem?: Maybe<OutletDailyMenuItemEntity>;
  patronID: Scalars['String'];
};

export type OutletDailyMenuItemPatronPriceInput = {
  accountID?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  patronClass: PatronClass;
  outletDailyMenuItemID?: Maybe<Scalars['String']>;
};

export type OutletDailyPromoMenuItemEntity = AuditEntity & {
  __typename?: 'OutletDailyPromoMenuItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  outletID: Scalars['String'];
  startTime: Scalars['String'];
  endTime: Scalars['String'];
  businessDate: Scalars['DateTime'];
  promoMenuItemID: Scalars['String'];
  outletDailyMenuItemID: Scalars['String'];
  outletDailyMenuItem?: Maybe<OutletDailyMenuItemEntity>;
  outletDailyPromoMenuItemPrice?: Maybe<Array<OutletDailyPromoMenuItemPriceEntity>>;
};

export type OutletDailyPromoMenuItemInput = {
  ID: Scalars['String'];
  outletDailyMenuItemID: Scalars['String'];
  accountID?: Maybe<Scalars['String']>;
  isActive: Scalars['Boolean'];
  startTime: Scalars['DateTime'];
  endTime: Scalars['DateTime'];
};

export type OutletDailyPromoMenuItemPriceEntity = AuditEntity & {
  __typename?: 'OutletDailyPromoMenuItemPriceEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  outletID: Scalars['String'];
  price: Scalars['Float'];
  name: Scalars['String'];
  description: Scalars['String'];
  patronClass: Scalars['String'];
  outletDailyPromoMenuItemID: Scalars['String'];
  outletDailyPromoMenuItem?: Maybe<OutletDailyPromoMenuItemEntity>;
  patronID: Scalars['String'];
};

export type OutletDailyPromoMenuItemPriceInput = {
  accountID?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  patronClass: PatronClass;
  outletDailyPromoMenuItemID?: Maybe<Scalars['String']>;
};

export type OutletDailySetMenuOptionEntity = AuditEntity & {
  __typename?: 'OutletDailySetMenuOptionEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  outletID: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  maxSelections: Scalars['Float'];
  setMenuOptionID: Scalars['String'];
  outletDailyMenuItemID: Scalars['String'];
  outletDailyMenuItem?: Maybe<OutletDailyMenuItemEntity>;
  outletDailySetMenuOptionItem?: Maybe<Array<OutletDailySetMenuOptionItemEntity>>;
};

export type OutletDailySetMenuOptionInput = {
  ID: Scalars['String'];
  accountID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  outletDailyMenuItemID?: Maybe<Scalars['String']>;
  setMenuOptionID?: Maybe<Scalars['String']>;
  maxSelections?: Maybe<Scalars['Float']>;
};

export type OutletDailySetMenuOptionItemEntity = AuditEntity & {
  __typename?: 'OutletDailySetMenuOptionItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  outletID: Scalars['String'];
  quantity?: Maybe<Scalars['Float']>;
  outletDailySetMenuOptionID: Scalars['String'];
  outletDailySetMenuOption?: Maybe<OutletDailySetMenuOptionEntity>;
  outletDailyMenuItemID: Scalars['String'];
  outletDailyMenuItem?: Maybe<OutletDailyMenuItemEntity>;
};

export type OutletDailySetMenuOptionItemInput = {
  createdTs: Scalars['DateTime'];
  createBy: Scalars['String'];
  modTs: Scalars['DateTime'];
  modBy: Scalars['String'];
  isActive: Scalars['Boolean'];
  ID: Scalars['String'];
  accountID: Scalars['String'];
  outletDailySetMenuOptionID: Scalars['String'];
  outletDailyMenuItemID: Scalars['String'];
  quantity: Scalars['Float'];
};

export type OutletEntity = AuditEntity & {
  __typename?: 'OutletEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  name: Scalars['String'];
  companyID?: Maybe<Scalars['String']>;
  company?: Maybe<CompanyEntity>;
  msicCode?: Maybe<Scalars['String']>;
  msicDesc?: Maybe<Scalars['String']>;
  eInvEffectiveDate?: Maybe<Scalars['DateTime']>;
  companyName?: Maybe<Scalars['String']>;
  tables?: Maybe<Array<TableEntity>>;
  outletCode?: Maybe<Scalars['String']>;
  currencyCode?: Maybe<Scalars['String']>;
  isInclusive: Scalars['Boolean'];
  integrateCityLedger: Scalars['Boolean'];
  capacity: Scalars['Float'];
  registrationNo: Scalars['String'];
  taxRegistrationNo: Scalars['String'];
  contactNo: Scalars['String'];
  email: Scalars['String'];
  address: Scalars['JSON'];
  coordinate?: Maybe<Scalars['JSON']>;
  closingTime?: Maybe<Scalars['String']>;
  openingTime?: Maybe<Scalars['String']>;
  autoDayEndTime?: Maybe<Scalars['String']>;
  operatingHours?: Maybe<Array<OperatingHoursEntity>>;
  menuItem?: Maybe<Array<MenuItemEntity>>;
  bill?: Maybe<Array<BillEntity>>;
  promoMenu?: Maybe<Array<PromoMenuEntity>>;
  businessDate: Scalars['DateTime'];
  paymentGateway?: Maybe<PaymentGatewayEntity>;
  onlinePaymentStoreID?: Maybe<Scalars['String']>;
  outletChannel?: Maybe<OutletChannelEntity>;
  customerPolicy?: Maybe<OutletCustomerPolicyEntity>;
  outletGLAccount?: Maybe<AccountJson>;
  outletRoundingGLAccount?: Maybe<AccountJson>;
  outletDiscountGLAcct?: Maybe<AccountJson>;
  outletOCDiscountGLAcct?: Maybe<AccountJson>;
  outletENTDiscountGLAcct?: Maybe<AccountJson>;
  taxSchemeID?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  tablesInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  logo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  gallery?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  govTax?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  serviceCharge?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  generatedBillNo?: Maybe<Scalars['Float']>;
};

export type OutletInput = {
  ID?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  companyName?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  hotelID?: Maybe<Scalars['String']>;
  address: AddressInput;
  coordinate: CoordinateInput;
  registrationNo?: Maybe<Scalars['String']>;
  taxRegistrationNo?: Maybe<Scalars['String']>;
  contactNo: Scalars['String'];
  email: Scalars['String'];
  closingTime: Scalars['String'];
  openingTime: Scalars['String'];
  autoDayEndTime: Scalars['String'];
  outletCode?: Maybe<Scalars['String']>;
  currencyCode?: Maybe<Scalars['String']>;
  govTax?: Maybe<Scalars['Float']>;
  serviceCharge?: Maybe<Scalars['Float']>;
  capacity?: Maybe<Scalars['Float']>;
  integrateCityLedger?: Maybe<Scalars['Boolean']>;
  galleryFiles?: Maybe<Array<Scalars['Upload']>>;
  logoFiles?: Maybe<Array<Scalars['Upload']>>;
  msicCode?: Maybe<Scalars['String']>;
  msicDesc?: Maybe<Scalars['String']>;
  eInvEffectiveDate?: Maybe<Scalars['DateTime']>;
  taxSchemeID?: Maybe<Scalars['String']>;
};

export type OutletTaxOutput = {
  __typename?: 'OutletTaxOutput';
  taxScheme?: Maybe<Scalars['String']>;
  taxCode?: Maybe<Scalars['String']>;
  effectiveDate?: Maybe<Scalars['DateTime']>;
  value?: Maybe<Scalars['Float']>;
  isTaxable?: Maybe<Scalars['Boolean']>;
};

export type OutletTaxPolicyEntity = AuditEntity & {
  __typename?: 'OutletTaxPolicyEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  outletID: Scalars['String'];
  taxType: TaxType;
  isTaxable?: Maybe<Scalars['Boolean']>;
  effectiveDate: Scalars['DateTime'];
  value: Scalars['Float'];
  outletGLAccount?: Maybe<AccountJson>;
  outletOCGLAccount?: Maybe<AccountJson>;
  outletENTGLAccount?: Maybe<AccountJson>;
  outletDiscountGLAcct?: Maybe<AccountJson>;
  outletOCDiscountGLAcct?: Maybe<AccountJson>;
  outletENTDiscountGLAcct?: Maybe<AccountJson>;
};

export type OutletTaxPolicyGlEntity = AuditEntity & {
  __typename?: 'OutletTaxPolicyGLEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  outletID: Scalars['String'];
  taxType?: Maybe<Scalars['String']>;
  discountType?: Maybe<Scalars['String']>;
  outletGLAccount?: Maybe<AccountJson>;
};

export type OutletTaxPolicyInput = {
  ID?: Maybe<Scalars['String']>;
  outletID: Scalars['String'];
  taxType: TaxType;
  isTaxable?: Maybe<Scalars['Boolean']>;
  effectiveDate: Scalars['DateTime'];
  value: Scalars['Float'];
};

export enum PosPermission {
  RestaurantIcon = 'RESTAURANT__________ICON',
  KitchenIcon = 'KITCHEN__________ICON',
  PrinterServerIcon = 'PRINTER_SERVER__________ICON',
  HomeTableLayoutView = 'HOME_TABLE_LAYOUT_______VIEW',
  HomeTableLayoutNewOrderCreate = 'HOME_TABLE_LAYOUT_NEW_ORDER____CREATE',
  HomeTableLayoutAddEditOrderItemView = 'HOME_TABLE_LAYOUT_ADD_EDIT_ORDER_ITEM____VIEW',
  HomeTableLayoutAddEditOrderItemVoidOrderItemVoid = 'HOME_TABLE_LAYOUT_ADD_EDIT_ORDER_ITEM_VOID_ORDER_ITEM_VOID',
  HomeTableLayoutEditOrderInfoView = 'HOME_TABLE_LAYOUT_EDIT_ORDER_INFO____VIEW',
  HomeTableLayoutChangeTransferTableView = 'HOME_TABLE_LAYOUT_CHANGE_TRANSFER_TABLE____VIEW',
  HomeTableLayoutItemTransferView = 'HOME_TABLE_LAYOUT_ITEM_TRANSFER____VIEW',
  HomeTableLayoutBillItemDiscountView = 'HOME_TABLE_LAYOUT_BILL_ITEM_DISCOUNT____VIEW',
  HomeTableLayoutBillItemDiscountCreateOrderDiscountCreate = 'HOME_TABLE_LAYOUT_BILL_ITEM_DISCOUNT_CREATE_ORDER_DISCOUNT_CREATE',
  HomeTableLayoutBillItemDiscountCreateOrderItemDiscountCreate = 'HOME_TABLE_LAYOUT_BILL_ITEM_DISCOUNT_CREATE_ORDER_ITEM_DISCOUNT_CREATE',
  HomeTableLayoutBillSettlementView = 'HOME_TABLE_LAYOUT_BILL_SETTLEMENT____VIEW',
  HomeTableLayoutCloseTableView = 'HOME_TABLE_LAYOUT_CLOSE_TABLE____VIEW',
  TakeawayShow = 'TAKEAWAY____________SHOW',
  TakeawayCreate = 'TAKEAWAY____________CREATE',
  TakeawayTakeawayVoidOrderItemVoid = 'TAKEAWAY_TAKEAWAY_VOID_ORDER_ITEM_________VOID',
  TakeawayPickUpUpdate = 'TAKEAWAY_PICK_UP_________UPDATE',
  TakeawayAddOrderCreate = 'TAKEAWAY_ADD_ORDER_________CREATE',
  TakeawayCancelOrderUpdate = 'TAKEAWAY_CANCEL_ORDER_________UPDATE',
  TakeawayMakePaymentCreate = 'TAKEAWAY_MAKE_PAYMENT_________CREATE',
  CommonSettingShow = 'COMMON_SETTING____________SHOW',
  CommonSettingTaxPolicyCreate = 'COMMON_SETTING_TAX_POLICY_________CREATE',
  CommonSettingTaxPolicyShow = 'COMMON_SETTING_TAX_POLICY_________SHOW',
  CommonSettingTaxPolicyUpdate = 'COMMON_SETTING_TAX_POLICY_________UPDATE',
  CommonSettingTaxPolicyDelete = 'COMMON_SETTING_TAX_POLICY_________DELETE',
  CommonSettingTaxSchemePolicyCreate = 'COMMON_SETTING_TAX_SCHEME_POLICY_________CREATE',
  CommonSettingTaxSchemePolicyShow = 'COMMON_SETTING_TAX_SCHEME_POLICY_________SHOW',
  CommonSettingTaxSchemePolicyUpdate = 'COMMON_SETTING_TAX_SCHEME_POLICY_________UPDATE',
  CommonSettingTaxSchemePolicyDelete = 'COMMON_SETTING_TAX_SCHEME_POLICY_________DELETE',
  GeneralSettingShow = 'GENERAL_SETTING____________SHOW',
  GeneralSettingAppointedOfficerCreate = 'GENERAL_SETTING_APPOINTED_OFFICER_________CREATE',
  GeneralSettingAppointedOfficerShow = 'GENERAL_SETTING_APPOINTED_OFFICER_________SHOW',
  GeneralSettingAppointedOfficerUpdate = 'GENERAL_SETTING_APPOINTED_OFFICER_________UPDATE',
  GeneralSettingAppointedOfficerDelete = 'GENERAL_SETTING_APPOINTED_OFFICER_________DELETE',
  GeneralSettingAuthorisedStaffCreate = 'GENERAL_SETTING_AUTHORISED_STAFF_________CREATE',
  GeneralSettingAuthorisedStaffShow = 'GENERAL_SETTING_AUTHORISED_STAFF_________SHOW',
  GeneralSettingAuthorisedStaffUpdate = 'GENERAL_SETTING_AUTHORISED_STAFF_________UPDATE',
  GeneralSettingAuthorisedStaffDelete = 'GENERAL_SETTING_AUTHORISED_STAFF_________DELETE',
  GeneralSettingAccessSecurityShow = 'GENERAL_SETTING_ACCESS_SECURITY_________SHOW',
  GeneralSettingAccessSecurityUsersCreate = 'GENERAL_SETTING_ACCESS_SECURITY_USERS______CREATE',
  GeneralSettingAccessSecurityUsersShow = 'GENERAL_SETTING_ACCESS_SECURITY_USERS______SHOW',
  GeneralSettingAccessSecurityUsersUpdate = 'GENERAL_SETTING_ACCESS_SECURITY_USERS______UPDATE',
  GeneralSettingAccessSecurityRolesCreate = 'GENERAL_SETTING_ACCESS_SECURITY_ROLES______CREATE',
  GeneralSettingAccessSecurityRolesShow = 'GENERAL_SETTING_ACCESS_SECURITY_ROLES______SHOW',
  GeneralSettingAccessSecurityRolesUpdate = 'GENERAL_SETTING_ACCESS_SECURITY_ROLES______UPDATE',
  GeneralSettingAccessSecurityRolesDelete = 'GENERAL_SETTING_ACCESS_SECURITY_ROLES______DELETE',
  GeneralSettingRoundingPolicyShow = 'GENERAL_SETTING_ROUNDING_POLICY_________SHOW',
  GeneralSettingRoundingPolicyUpdate = 'GENERAL_SETTING_ROUNDING_POLICY_________UPDATE',
  GeneralSettingUomShow = 'GENERAL_SETTING_UOM_________SHOW',
  GeneralSettingUomCreate = 'GENERAL_SETTING_UOM_________CREATE',
  GeneralSettingUomUpdate = 'GENERAL_SETTING_UOM_________UPDATE',
  GeneralSettingUomDelete = 'GENERAL_SETTING_UOM_________DELETE',
  GeneralSettingUomConversionShow = 'GENERAL_SETTING_UOM_CONVERSION_________SHOW',
  GeneralSettingUomConversionCreate = 'GENERAL_SETTING_UOM_CONVERSION_________CREATE',
  GeneralSettingUomConversionUpdate = 'GENERAL_SETTING_UOM_CONVERSION_________UPDATE',
  GeneralSettingUomConversionDelete = 'GENERAL_SETTING_UOM_CONVERSION_________DELETE',
  GeneralSettingEInvoicePolicyShow = 'GENERAL_SETTING_E_INVOICE_POLICY_________SHOW',
  GeneralSettingEInvoicePolicyUpdate = 'GENERAL_SETTING_E_INVOICE_POLICY_________UPDATE',
  OutletSettingShow = 'OUTLET_SETTING____________SHOW',
  OutletSettingCreate = 'OUTLET_SETTING____________CREATE',
  OutletSettingOutletProfileUpdate = 'OUTLET_SETTING_OUTLET_PROFILE_________UPDATE',
  OutletSettingOutletPolicyShow = 'OUTLET_SETTING_OUTLET_POLICY_________SHOW',
  OutletSettingOutletPolicyPatronSetupShow = 'OUTLET_SETTING_OUTLET_POLICY_PATRON_SETUP______SHOW',
  OutletSettingOutletPolicyTaxSetupShow = 'OUTLET_SETTING_OUTLET_POLICY_TAX_SETUP______SHOW',
  OutletSettingOutletPolicyTaxSetupTaxShow = 'OUTLET_SETTING_OUTLET_POLICY_TAX_SETUP___TAX_SHOW',
  OutletSettingOutletPolicyTaxSetupServiceChargeShow = 'OUTLET_SETTING_OUTLET_POLICY_TAX_SETUP___SERVICE_CHARGE_SHOW',
  OutletSettingOutletPolicyReasonSetupShow = 'OUTLET_SETTING_OUTLET_POLICY_REASON_SETUP______SHOW',
  OutletSettingTableShow = 'OUTLET_SETTING_TABLE_________SHOW',
  OutletSettingQrCodeShow = 'OUTLET_SETTING_QR_CODE_________SHOW',
  OutletSettingKitchenPrinterShow = 'OUTLET_SETTING_KITCHEN_PRINTER_________SHOW',
  OutletSettingReceiptPrinterShow = 'OUTLET_SETTING_RECEIPT_PRINTER_________SHOW',
  OutletSettingMealPeriodShow = 'OUTLET_SETTING_MEAL_PERIOD_________SHOW',
  OutletSettingMajorFamilyShow = 'OUTLET_SETTING_MAJOR_FAMILY_________SHOW',
  OutletSettingStandardMenuOptionShow = 'OUTLET_SETTING_STANDARD_MENU_OPTION_________SHOW',
  OutletSettingMenuItemsShow = 'OUTLET_SETTING_MENU_ITEMS_________SHOW',
  OutletSettingSetMenuShow = 'OUTLET_SETTING_SET_MENU_________SHOW',
  OutletSettingDigitalMenuPricingShow = 'OUTLET_SETTING_DIGITAL_MENU_PRICING_________SHOW',
  OutletSettingMenuSchedulingShow = 'OUTLET_SETTING_MENU_SCHEDULING_________SHOW',
  OutletSettingPromotionalMenuPricingShow = 'OUTLET_SETTING_PROMOTIONAL_MENU_PRICING_________SHOW',
  OutletSettingDailyMenuItemAvailabilityShow = 'OUTLET_SETTING_DAILY_MENU_ITEM_AVAILABILITY_________SHOW',
  OutletSettingPromoCodeShow = 'OUTLET_SETTING_PROMO_CODE_________SHOW',
  OutletSettingDiscountTypeShow = 'OUTLET_SETTING_DISCOUNT_TYPE_________SHOW',
  OutletSettingStopSalesItemsShow = 'OUTLET_SETTING_STOP_SALES_ITEMS_________SHOW',
  OutletSettingOutletRoleAssignmentShow = 'OUTLET_SETTING_OUTLET_ROLE_ASSIGNMENT_________SHOW',
  OutletSettingPaymentTypeShow = 'OUTLET_SETTING_PAYMENT_TYPE_________SHOW',
  OutletSettingThirdPartyInterfaceSetupShow = 'OUTLET_SETTING_THIRD_PARTY_INTERFACE_SETUP_________SHOW',
  BillListingShow = 'BILL_LISTING____________SHOW',
  BillListingVoidPayment = 'BILL_LISTING____________VOID_PAYMENT',
  BillListingVoidBill = 'BILL_LISTING____________VOID_BILL',
  BillListingPrint = 'BILL_LISTING____________PRINT',
  BillListingCreditNote = 'BILL_LISTING____________CREDIT_NOTE',
  UtilityShow = 'UTILITY____________SHOW',
  UtilityDuplicateMenuDuplicate = 'UTILITY_DUPLICATE_MENU_________DUPLICATE',
  UtilityUpdateDailyMenuItemUpdate = 'UTILITY_UPDATE_DAILY_MENU_ITEM_________UPDATE',
  BizAnalyticsShow = 'BIZ_ANALYTICS____________SHOW',
  BizAnalyticsOscAnalysisShow = 'BIZ_ANALYTICS_OSC_ANALYSIS_________SHOW',
  BizAnalyticsMpsAnalysisShow = 'BIZ_ANALYTICS_MPS_ANALYSIS_________SHOW',
  BizAnalyticsWscAnalysisShow = 'BIZ_ANALYTICS_WSC_ANALYSIS_________SHOW',
  BizAnalyticsFmviAnalysisShow = 'BIZ_ANALYTICS_FMVI_ANALYSIS_________SHOW',
  BizAnalyticsSmviAnalysisShow = 'BIZ_ANALYTICS_SMVI_ANALYSIS_________SHOW',
  BizAnalyticsPtAnalysisShow = 'BIZ_ANALYTICS_PT_ANALYSIS_________SHOW',
  BizAnalyticsDtAnalysisShow = 'BIZ_ANALYTICS_DT_ANALYSIS_________SHOW',
  BizAnalyticsAmtAnalysisShow = 'BIZ_ANALYTICS_AMT_ANALYSIS_________SHOW',
  DigitalReportingShow = 'DIGITAL_REPORTING____________SHOW',
  DigitalReportingOutletSalesRevenueShow = 'DIGITAL_REPORTING_OUTLET_SALES_REVENUE_________SHOW',
  DigitalReportingMealPeriodCollectionShow = 'DIGITAL_REPORTING_MEAL_PERIOD_COLLECTION_________SHOW',
  DigitalReportingProductionAnalysisSummaryShow = 'DIGITAL_REPORTING_PRODUCTION_ANALYSIS_SUMMARY_________SHOW',
  DigitalReportingProductionAnalysisByOutletShow = 'DIGITAL_REPORTING_PRODUCTION_ANALYSIS_BY_OUTLET_________SHOW',
  DigitalReportingDailyEarningReportShow = 'DIGITAL_REPORTING_DAILY_EARNING_REPORT_________SHOW',
  DigitalReportingCashierCollectionReportShow = 'DIGITAL_REPORTING_CASHIER_COLLECTION_REPORT_________SHOW',
  DigitalReportingDayEndReportShow = 'DIGITAL_REPORTING_DAY_END_REPORT_________SHOW',
  DigitalReportingBillTransactionListingShow = 'DIGITAL_REPORTING_BILL_TRANSACTION_LISTING_________SHOW',
  DigitalReportingDiscountListingShow = 'DIGITAL_REPORTING_DISCOUNT_LISTING_________SHOW',
  DigitalReportingAuditUserLoggingSystemShow = 'DIGITAL_REPORTING_AUDIT_USER_LOGGING_SYSTEM_________SHOW',
  DigitalReportingVoidedTransactionListingShow = 'DIGITAL_REPORTING_VOIDED_TRANSACTION_LISTING_________SHOW',
  DigitalReportingMealPeriodSalesShow = 'DIGITAL_REPORTING_MEAL_PERIOD_SALES_________SHOW',
  DigitalReportingDownloadedGlListingShow = 'DIGITAL_REPORTING_DOWNLOADED_GL_LISTING_________SHOW',
  DigitalReportingConsolidatedEInvoiceShow = 'DIGITAL_REPORTING_CONSOLIDATED_E_INVOICE_________SHOW',
  DigitalReportingEInvoiceListingShow = 'DIGITAL_REPORTING_E_INVOICE_LISTING_________SHOW',
  DigitalDocumentShow = 'DIGITAL_DOCUMENT____________SHOW',
  DigitalDocumentPrint = 'DIGITAL_DOCUMENT____________PRINT',
  DigitalDocumentShare = 'DIGITAL_DOCUMENT____________SHARE',
  AccountJournalsShow = 'ACCOUNT_JOURNALS____________SHOW',
  AccountJournalsAccountsMappingShow = 'ACCOUNT_JOURNALS_ACCOUNTS_MAPPING_________SHOW',
  AccountJournalsAccountsMappingOutletGlInterfaceShow = 'ACCOUNT_JOURNALS_ACCOUNTS_MAPPING_OUTLET_GL_INTERFACE______SHOW',
  AccountJournalsAccountsMappingOutletGlInterfaceUpdate = 'ACCOUNT_JOURNALS_ACCOUNTS_MAPPING_OUTLET_GL_INTERFACE______UPDATE',
  AccountJournalsAccountsMappingBankAccountShow = 'ACCOUNT_JOURNALS_ACCOUNTS_MAPPING_BANK_ACCOUNT______SHOW',
  AccountJournalsAccountsMappingBankAccountCreate = 'ACCOUNT_JOURNALS_ACCOUNTS_MAPPING_BANK_ACCOUNT______CREATE',
  AccountJournalsAccountsMappingBankAccountUpdate = 'ACCOUNT_JOURNALS_ACCOUNTS_MAPPING_BANK_ACCOUNT______UPDATE',
  AccountJournalsAccountsMappingBankAccountDelete = 'ACCOUNT_JOURNALS_ACCOUNTS_MAPPING_BANK_ACCOUNT______DELETE',
  AccountJournalsGlJournalShow = 'ACCOUNT_JOURNALS_GL_JOURNAL_________SHOW',
  AccountJournalsCbTransactionShow = 'ACCOUNT_JOURNALS_CB_TRANSACTION_________SHOW',
  KitchenUpdateOrderItemUpdate = 'KITCHEN_UPDATE_ORDER_ITEM_________UPDATE',
  KitchenCloseOrderUpdate = 'KITCHEN_CLOSE_ORDER_________UPDATE',
  ConsolidatedEInvoiceShow = 'CONSOLIDATED_E_INVOICE____________SHOW'
}

export type PosRoleEntity = AuditEntity & {
  __typename?: 'POSRoleEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  name: Scalars['String'];
};

export type PatronAccountEntity = AuditEntity & {
  __typename?: 'PatronAccountEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  creditBalance?: Maybe<Scalars['Float']>;
  hotelRoomID?: Maybe<Scalars['String']>;
  hotelRoom?: Maybe<HotelRoomEntity>;
  membershipID?: Maybe<Scalars['String']>;
  membership?: Maybe<MembershipEntity>;
};

export enum PatronClass {
  Member = 'MEMBER',
  Staff = 'STAFF',
  Officer = 'OFFICER',
  Visitor = 'VISITOR',
  HotelGuest = 'HOTEL_GUEST',
  Hotel = 'HOTEL',
  Guest = 'GUEST'
}

export type PatronEntity = AuditEntity & {
  __typename?: 'PatronEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  outletID: Scalars['String'];
  patronTypeID: Scalars['String'];
  patronType?: Maybe<PatronTypeEntity>;
  reservation?: Maybe<ReservationEntity>;
  promoMenuItemPrice?: Maybe<Array<PromoMenuItemPriceEntity>>;
  digitalMenuItemPrice?: Maybe<Array<DigitalMenuItemPriceEntity>>;
  order?: Maybe<Array<OrderEntity>>;
  /** CustomFieldResolver */
  patronClass?: Maybe<Scalars['JSON']>;
};

export type PatronInput = {
  ID?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
  patronTypeID: Scalars['String'];
};

export type PatronTypeEntity = AuditEntity & {
  __typename?: 'PatronTypeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  name: Scalars['String'];
  description: Scalars['String'];
  patronClass: PatronClass;
  dailyMenuPrice?: Maybe<Array<DailyMenuPriceEntity>>;
  patron?: Maybe<Array<PatronEntity>>;
};

export type PatronTypeInput = {
  name: Scalars['String'];
  description: Scalars['String'];
};

export type PaymentByQuantityInput = {
  orderItemID?: Maybe<Scalars['String']>;
  paymentQuantity?: Maybe<Scalars['Float']>;
};

export enum PaymentClass {
  Member = 'MEMBER',
  Online = 'ONLINE',
  Cash = 'CASH',
  Credit = 'CREDIT',
  Room = 'ROOM',
  NonGuest = 'NON_GUEST',
  Debtor = 'DEBTOR',
  Skipper = 'SKIPPER',
  Officer = 'OFFICER',
  Staff = 'STAFF',
  Voucher = 'VOUCHER',
  Others = 'OTHERS',
  UnsettledBill = 'UNSETTLED_BILL',
  Folio = 'FOLIO',
  SuspendFolio = 'SUSPEND_FOLIO',
  BankTt = 'BANK_TT',
  Cheque = 'CHEQUE'
}

export type PaymentEntity = AuditEntity & {
  __typename?: 'PaymentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  outletID: Scalars['String'];
  registrationID?: Maybe<Scalars['String']>;
  billID?: Maybe<Scalars['String']>;
  refBillNo?: Maybe<Scalars['String']>;
  bill?: Maybe<BillEntity>;
  creditCardType?: Maybe<CreditCardType>;
  defaultPaymentID: Scalars['String'];
  patronAccountID?: Maybe<Scalars['String']>;
  defaultPayment?: Maybe<DefaultPaymentEntity>;
  paymentStatus?: Maybe<PaymentStatus>;
  referenceNo?: Maybe<Scalars['String']>;
  debtorAccount?: Maybe<Scalars['String']>;
  paySessionNo?: Maybe<Scalars['Float']>;
  checkoutID?: Maybe<Scalars['String']>;
  cardNo?: Maybe<Scalars['String']>;
  amount: Scalars['Float'];
  receivedAmount?: Maybe<Scalars['Float']>;
  changeAmount?: Maybe<Scalars['Float']>;
  roundingAmount?: Maybe<Scalars['Float']>;
  businessDate?: Maybe<Scalars['DateTime']>;
  reasonCode?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  voidRemark?: Maybe<Scalars['String']>;
  status: PaymentStatus;
  paymentClass: PaymentClass;
  isGLPosted?: Maybe<Scalars['Boolean']>;
  isCBPosted?: Maybe<Scalars['Boolean']>;
  hotelGuestInfo?: Maybe<Scalars['JSON']>;
};

export type PaymentGatewayEntity = AuditEntity & {
  __typename?: 'PaymentGatewayEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  outletID: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  client?: Maybe<Scalars['String']>;
  secret?: Maybe<Scalars['String']>;
  store?: Maybe<Scalars['String']>;
  privateKey?: Maybe<Scalars['String']>;
  publicKey?: Maybe<Scalars['String']>;
  environment?: Maybe<Scalars['String']>;
  outlet?: Maybe<OutletEntity>;
};

export type PaymentGatewayInput = {
  ID: Scalars['String'];
  outletID: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  client?: Maybe<Scalars['String']>;
  secret?: Maybe<Scalars['String']>;
  store?: Maybe<Scalars['String']>;
  privateKey?: Maybe<Scalars['String']>;
  publicKey?: Maybe<Scalars['String']>;
  environment?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  userID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  modifiedBy?: Maybe<Scalars['String']>;
};

export type PaymentInput = {
  ID?: Maybe<Scalars['String']>;
  defaultPaymentID?: Maybe<Scalars['String']>;
  billID?: Maybe<Scalars['String']>;
  refBillNo?: Maybe<Scalars['String']>;
  outletID: Scalars['String'];
  cardNo?: Maybe<Scalars['String']>;
  registrationID?: Maybe<Scalars['String']>;
  creditCardType?: Maybe<CreditCardType>;
  paymentStatus?: Maybe<PaymentStatus>;
  amount: Scalars['Float'];
  receivedAmount?: Maybe<Scalars['Float']>;
  changeAmount?: Maybe<Scalars['Float']>;
  roundingAmount?: Maybe<Scalars['Float']>;
  paySessionNo?: Maybe<Scalars['Float']>;
  checkoutID?: Maybe<Scalars['String']>;
  businessDate?: Maybe<Scalars['DateTime']>;
  reasonCode?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  voidRemark?: Maybe<Scalars['String']>;
  paymentClass?: Maybe<PaymentClass>;
  prevPaymentClass?: Maybe<PaymentClass>;
  patronAccountID?: Maybe<Scalars['String']>;
  referenceNo?: Maybe<Scalars['String']>;
  hotelGuestInfo?: Maybe<HotelGuestInfoInput>;
  scheduleBillingInput?: Maybe<ScheduleBillingInput>;
  billLedgerInput?: Maybe<BillLedgerInput>;
};

export type PaymentItemDiscountEntity = AuditEntity & {
  __typename?: 'PaymentItemDiscountEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  outletID: Scalars['String'];
  discountID: Scalars['String'];
  paymentItemID: Scalars['String'];
  paymentItem?: Maybe<PaymentItemEntity>;
  status: OrderItemDiscountStatus;
  amount?: Maybe<Scalars['Float']>;
  discountedBy?: Maybe<Scalars['String']>;
  voidedBy?: Maybe<Scalars['String']>;
};

export type PaymentItemEntity = AuditEntity & {
  __typename?: 'PaymentItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  openItemName?: Maybe<Scalars['String']>;
  outletID: Scalars['String'];
  refID?: Maybe<Scalars['String']>;
  specialRequest?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Float']>;
  taxAmount?: Maybe<Scalars['Float']>;
  serviceCharge?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Float']>;
  baseAmount?: Maybe<Scalars['Float']>;
  isTakeaway?: Maybe<Scalars['Boolean']>;
  isPrintToKitchen?: Maybe<Scalars['Boolean']>;
  businessDate: Scalars['DateTime'];
  status: OrderItemStatus;
  kitchenStatus?: Maybe<KitchenStatus>;
  unitPrice: Scalars['Float'];
  quantity: Scalars['Float'];
  name: Scalars['String'];
  description: Scalars['String'];
  promoID?: Maybe<Scalars['String']>;
  orderID: Scalars['String'];
  order?: Maybe<OrderEntity>;
  paymentItemDiscount?: Maybe<PaymentItemDiscountEntity>;
  menuItemID: Scalars['String'];
  menuItem?: Maybe<MenuItemEntity>;
  mealPeriodID: Scalars['String'];
  mealPeriod?: Maybe<MealPeriodEntity>;
  billID?: Maybe<Scalars['String']>;
  bill?: Maybe<BillEntity>;
  orderItemID?: Maybe<Scalars['String']>;
  orderItem?: Maybe<OrderItemEntity>;
  paymentItemOption?: Maybe<Array<PaymentItemOptionEntity>>;
  paymentItemSetMenuOption?: Maybe<Array<PaymentItemSetMenuOptionEntity>>;
};

export type PaymentItemOptionEntity = AuditEntity & {
  __typename?: 'PaymentItemOptionEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  outletID: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  paymentItemID: Scalars['String'];
  paymentItem?: Maybe<PaymentItemEntity>;
  optionID: Scalars['String'];
  option?: Maybe<OptionEntity>;
  paymentItemOptionItem?: Maybe<Array<PaymentItemOptionItemEntity>>;
};

export type PaymentItemOptionItemEntity = AuditEntity & {
  __typename?: 'PaymentItemOptionItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  outletID: Scalars['String'];
  price?: Maybe<Scalars['Float']>;
  basePrice?: Maybe<Scalars['Float']>;
  taxAmount?: Maybe<Scalars['Float']>;
  serviceCharge?: Maybe<Scalars['Float']>;
  optionItemID: Scalars['String'];
  optionItem?: Maybe<OptionItemEntity>;
  paymentItemOptionID: Scalars['String'];
  paymentItemOption?: Maybe<PaymentItemOptionEntity>;
};

export type PaymentItemSetMenuOptionEntity = AuditEntity & {
  __typename?: 'PaymentItemSetMenuOptionEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  outletID: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  paymentItemID: Scalars['String'];
  paymentItem?: Maybe<PaymentItemEntity>;
  setMenuOptionID: Scalars['String'];
  setMenuOption?: Maybe<SetMenuOptionEntity>;
  paymentItemSetMenuOptionItem?: Maybe<Array<PaymentItemSetMenuOptionItemEntity>>;
};

export type PaymentItemSetMenuOptionItemEntity = AuditEntity & {
  __typename?: 'PaymentItemSetMenuOptionItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  outletID: Scalars['String'];
  quantity?: Maybe<Scalars['Float']>;
  setMenuOptionItemID: Scalars['String'];
  setMenuOptionItem?: Maybe<SetMenuOptionItemEntity>;
  paymentItemSetMenuOptionID: Scalars['String'];
  paymentItemSetMenuOption?: Maybe<PaymentItemSetMenuOptionEntity>;
  menuItemID: Scalars['String'];
  menuItem?: Maybe<MenuItemEntity>;
};

export enum PaymentStatus {
  Open = 'OPEN',
  Pending = 'PENDING',
  Void = 'VOID'
}

export type PaymentTypeEntity = AuditEntity & {
  __typename?: 'PaymentTypeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  description: Scalars['String'];
  paymentClass: PaymentClass;
};

export type PaymentTypeInput = {
  ID?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  paymentClass: PaymentClass;
};

export type PlatformTaxEntity = AuditEntity & {
  __typename?: 'PlatformTaxEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  effectiveDate: Scalars['DateTime'];
  value: Scalars['Float'];
};

export type PlatformTaxInput = {
  ID?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['String']>;
  effectiveDate?: Maybe<Scalars['DateTime']>;
  value?: Maybe<Scalars['Float']>;
};

export type PosCoreEntity = AuditEntity & {
  __typename?: 'PosCoreEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
};

export type PrintData = {
  __typename?: 'PrintData';
  printerName: Scalars['String'];
  outletName: Scalars['String'];
  orderID: Scalars['String'];
  orderItemIDs: Array<Scalars['String']>;
  orderMode: Scalars['String'];
  voidQuantity?: Maybe<Scalars['Float']>;
  reasonCode?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
};

export type PrintDataPayload = {
  outletID: Scalars['String'];
  dataURL?: Maybe<Scalars['String']>;
  printerName: Scalars['String'];
  outletName: Scalars['String'];
  orderID: Scalars['String'];
  orderItemIDs: Array<Scalars['String']>;
  orderMode: Scalars['String'];
  voidQuantity?: Maybe<Scalars['Float']>;
  reasonCode?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
};

export type PromoCodeEntity = AuditEntity & {
  __typename?: 'PromoCodeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  outletID?: Maybe<Scalars['String']>;
  code: Scalars['String'];
  description: Scalars['String'];
  validFrom: Scalars['DateTime'];
  validTill: Scalars['DateTime'];
  calcType: CalcType;
  amount: Scalars['Float'];
};

export type PromoCodeInput = {
  ID?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['String']>;
  outletID: Scalars['String'];
  code: Scalars['String'];
  description: Scalars['String'];
  validFrom: Scalars['DateTime'];
  validTill: Scalars['DateTime'];
  calcType: CalcType;
  amount: Scalars['Float'];
};

export type PromoMenuEntity = AuditEntity & {
  __typename?: 'PromoMenuEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  name: Scalars['String'];
  description: Scalars['String'];
  outletID: Scalars['String'];
  outlet?: Maybe<OutletEntity>;
  frequencyType?: Maybe<FrequencyType>;
  validityStartTs: Scalars['DateTime'];
  validityEndTs: Scalars['DateTime'];
  recurStartTime: Scalars['String'];
  recurEndTime: Scalars['String'];
  recurOnceAt?: Maybe<Scalars['DateTime']>;
  recurEveryXDays?: Maybe<Scalars['Float']>;
  recurEveryXWeeks?: Maybe<Scalars['Float']>;
  recurEveryXMonths?: Maybe<Scalars['Float']>;
  recurAtDayXOfMonth?: Maybe<Scalars['Float']>;
  recurWeekBitmap?: Maybe<Scalars['Float']>;
  recurDayBitmap?: Maybe<Scalars['Float']>;
  timezone?: Maybe<Scalars['Float']>;
  dailyMenuPrice?: Maybe<Array<DailyMenuPriceEntity>>;
  menuItem: Array<MenuItemEntity>;
  menuItemIDs: Array<Scalars['Float']>;
  promoMenuItem?: Maybe<Array<PromoMenuItemEntity>>;
};

export type PromoMenuInput = {
  ID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  description: Scalars['String'];
  outletID: Scalars['String'];
  frequencyType: FrequencyType;
  validityStartTs: Scalars['DateTime'];
  validityEndTs: Scalars['DateTime'];
  recurStartTime?: Maybe<Scalars['String']>;
  recurEndTime?: Maybe<Scalars['String']>;
  recurOnceAt?: Maybe<Scalars['DateTime']>;
  recurEveryXDays?: Maybe<Scalars['Float']>;
  recurEveryXWeeks?: Maybe<Scalars['Float']>;
  recurEveryXMonths?: Maybe<Scalars['Float']>;
  recurAtDayXOfMonth?: Maybe<Scalars['Float']>;
  recurWeekBitmap?: Maybe<Scalars['Float']>;
  recurDayBitmap?: Maybe<Scalars['Float']>;
  timezone?: Maybe<Scalars['Float']>;
};

export type PromoMenuItemEntity = AuditEntity & {
  __typename?: 'PromoMenuItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  outletID: Scalars['String'];
  promoMenuID: Scalars['String'];
  promoMenu?: Maybe<PromoMenuEntity>;
  menuItemID: Scalars['String'];
  menuItem?: Maybe<MenuItemEntity>;
  promoMenuItemPrice?: Maybe<Array<PromoMenuItemPriceEntity>>;
};

export type PromoMenuItemInput = {
  accountID?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
  promoMenuID?: Maybe<Scalars['String']>;
  menuItemID: Scalars['String'];
  promoMenuItemPriceInput: Array<PromoMenuItemPriceInput>;
};

export type PromoMenuItemPriceEntity = AuditEntity & {
  __typename?: 'PromoMenuItemPriceEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  promoMenuItemID: Scalars['String'];
  patronID: Scalars['String'];
  outletID: Scalars['String'];
  price?: Maybe<Scalars['Float']>;
  promoMenuItem?: Maybe<PromoMenuItemEntity>;
  patron?: Maybe<PatronEntity>;
  /** CustomFieldResolver */
  patronClass?: Maybe<Scalars['JSON']>;
};

export type PromoMenuItemPriceInput = {
  accountID?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
  promoMenuItemID?: Maybe<Scalars['String']>;
  patronID: Scalars['String'];
  price: Scalars['Float'];
};

export type QrEntity = AuditEntity & {
  __typename?: 'QREntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  code: Scalars['String'];
  outletID: Scalars['String'];
  /** CustomFieldResolver */
  orderInfo?: Maybe<Scalars['JSON']>;
};

export type QrInput = {
  accountID?: Maybe<Scalars['String']>;
  code: Scalars['String'];
  outletID?: Maybe<Scalars['String']>;
};

/** JsonType */
export type QrOrderInfo = {
  __typename?: 'QROrderInfo';
  orderID: Scalars['String'];
  tableID: Scalars['String'];
  orderItemCount: Scalars['Float'];
};

export type Query = {
  __typename?: 'Query';
  getOutletBillCharges: Scalars['JSON'];
  getOutletBillOCCharges: Scalars['JSON'];
  getOutletBillEntCharges: Scalars['JSON'];
  AccountMappingTaxGLList: Scalars['JSON'];
  getBankAccount: Array<BankAccountEntity>;
  getBankAccountContact: Array<BankAccountContactEntity>;
  getExportedCB: Scalars['JSON'];
  getExportedCBDetails: Scalars['JSON'];
  CheckBanknameInUse: Scalars['JSON'];
  getCBExportData: Scalars['JSON'];
  getExportedCBDetailsByTrxType: Scalars['JSON'];
  getExportedGLListing: Scalars['JSON'];
  getExportedGLDetails: Array<Scalars['JSON']>;
  ExportedGLTransferDetails: Array<ExportedGlTransferResponse>;
  getGLExport: Scalars['JSON'];
  getGLExportDetailsByTrxType: Scalars['JSON'];
  getAttachment: Array<AttachmentEntity>;
  attachmentListing?: Maybe<Array<AttachmentEntity>>;
  auditLogDetails: Scalars['JSON'];
  averageMealTimeAnalysis: Scalars['JSON'];
  discountTypeAnalysis: Scalars['JSON'];
  fastMovingMenuItemAnalysis: Scalars['JSON'];
  mealPeriodSalesAnalysis: Scalars['JSON'];
  outletSalesCollectionAnalysis: Scalars['JSON'];
  patronTypeAnalysis: Scalars['JSON'];
  customerTypeAnalysis: Scalars['JSON'];
  slowMovingMenuItemAnalysis: Scalars['JSON'];
  waiterSalesCollectionAnalysis: Scalars['JSON'];
  getCompanyListing: Array<CompanyEntity>;
  getCompanyListingByID: Array<CompanyEntity>;
  CurrencyListing: Array<CurrencyEntity>;
  ConsolidatedInvoiceListing: Array<EInvoiceConsolidationEntity>;
  DocToConsolidateListing: ConsolidatedEiResponse;
  DocToConsolidateView: Scalars['JSON'];
  getMsicCodeListing: Array<EInvoiceMsicEntity>;
  getClassificationListing: Array<EInvoiceClassificationEntity>;
  EInvoiceInformation: Scalars['JSON'];
  getEInvoiceSub: Array<EInvoiceSubscriptionEntity>;
  getEntityRoleUserAssignment: Array<RoleUserEntAssignmentEntity>;
  getRole: Array<RoleEntity>;
  userRoleIDs: Array<UserRoleId>;
  getPOSPermission: Scalars['JSON'];
  getRolePermission: Scalars['JSON'];
  getUsersRoleContract: Array<UserEntity>;
  testPermission: Scalars['String'];
  getEInvoicePolicy: Array<EInvoicePolicyEntity>;
  logoView: AttachmentEntity;
  getOfficer: Array<OfficerEntity>;
  getRoundingPolicy: Array<RoundingPolicyEntity>;
  getStaff: Array<StaffEntity>;
  getTaxSchemeListing: Array<TaxSchemeEntity>;
  isTaxSameName: Scalars['Boolean'];
  getTaxSchemeDetailItemRate: Array<TaxSchemeDetailItemRateOutput>;
  getTaxSchemeDetail: Array<TaxSchemeDetailOutput>;
  isTaxSchemeInUse: Scalars['Boolean'];
  getTaxTypeListing: Scalars['JSON'];
  getTaxListing: Scalars['JSON'];
  isTaxSameCode: Scalars['Boolean'];
  isTaxInUse: Scalars['Boolean'];
  getTaxDetails: TaxEntity;
  getTaxListingDropdown: Array<TaxListingOutput>;
  testPath: Scalars['JSON'];
  loggedInUserProfile: UserEntity;
  activeUser: Scalars['Float'];
  getUsersByAccount: Array<UserEntity>;
  getUser: UserEntity;
  getUsersbyIDs: Array<UserEntity>;
  isUserValid: Scalars['Boolean'];
  getUsersBySoftware: Array<UserEntity>;
  getUsersByAccountAndSoftware: Array<UserEntity>;
  getUsersWaiterRole: Array<UserEntity>;
  getSuperUsers: Array<UserEntity>;
  getDailyMenuItemAvailability: Array<DailyMenuItemAvailabilityEntity>;
  getDailyMenuPrice: Array<DailyMenuPriceEntity>;
  getDefaultPayment: Array<DefaultPaymentEntity>;
  getDigitalMenu: Array<DigitalMenuEntity>;
  getScheduledDigitalMenu: Array<OutletDailyMenuCategoryEntity>;
  isDigitalMenuInUse: Scalars['Boolean'];
  getDigitalMenuCategory: Array<DigitalMenuCategoryEntity>;
  getScheduledDigitalMenuCategory: Array<DigitalMenuCategoryEntity>;
  isDigitalMenuCategoryInUse: Scalars['Boolean'];
  getDigitalMenuItem: Array<DigitalMenuItemEntity>;
  getDigitalMenuItemPrice: Array<DigitalMenuItemPriceEntity>;
  getMenuItemForDigitalMenuItemPrice: Array<MenuItemEntity>;
  getDiscount: Array<DiscountEntity>;
  getFilteredDiscount: Array<DiscountEntity>;
  getSortedDiscount: Array<DiscountEntity>;
  getVoidedDiscount: Scalars['JSON'];
  getFamily: Array<FamilyEntity>;
  getKitchenPrinter: Array<KitchenPrinterEntity>;
  getMajor: Array<MajorEntity>;
  getMealPeriod: Array<MealPeriodEntity>;
  getCurrentMealPeriod: MealPeriodEntity;
  getAllMealPeriod: Scalars['JSON'];
  getMenuItem: Array<MenuItemEntity>;
  getMenuItemForSetMenuOptionItem: Array<MenuItemEntity>;
  getMenuItemOption: Array<MenuItemOptionEntity>;
  getOperatingHours: Array<OperatingHoursEntity>;
  getOption: Array<OptionEntity>;
  getOptionItem: Array<OptionItemEntity>;
  getOutlet: Array<OutletEntity>;
  getAssignedOutletByUser: Array<OutletEntity>;
  getOutletCustomerPolicy: Array<OutletCustomerPolicyEntity>;
  getOutletTaxPolicy: Array<OutletTaxPolicyEntity>;
  getPatron: Array<PatronEntity>;
  getOutletVisitorPatron: PatronEntity;
  getPromoCode: Array<PromoCodeEntity>;
  getPromoMenu: Array<PromoMenuEntity>;
  getPromoMenuItem: Array<PromoMenuItemEntity>;
  promoMenuItemChecking: Array<PromoMenuItemEntity>;
  getPromoMenuItemPrice: Array<PromoMenuItemPriceEntity>;
  getMenuItemForPromoMenuItemPrice: Array<MenuItemEntity>;
  getQR: Array<QrEntity>;
  openOrderQRChecking: QrEntity;
  getReason: Array<ReasonEntity>;
  getReceiptPrinter: Array<ReceiptPrinterEntity>;
  getScheduledMenuMealPeriod: Array<ScheduledMenuMealPeriodEntity>;
  getScheduledMenu: Array<ScheduledMenuEntity>;
  getSetMenuOption: Array<SetMenuOptionEntity>;
  getSetMenuOptionOption: Array<SetMenuOptionEntity>;
  getSetMenuOptionItem: Array<SetMenuOptionItemEntity>;
  getStopSales: Array<StopSalesEntity>;
  getMenuItemForStopSale: Array<MenuItemEntity>;
  getTable: Array<TableEntity>;
  getTableByAscOrder: Array<TableEntity>;
  MenuAccountXList?: Maybe<MenuVendorEntity>;
  getBill: Array<BillEntity>;
  getBillInfinite?: Maybe<Array<BillEntity>>;
  getBillWithDate?: Maybe<Array<BillEntity>>;
  getCNBillOrderItem?: Maybe<Array<OrderItemEntity>>;
  getBillDiscount: Array<BillDiscountEntity>;
  getMenuXInhouseList: Scalars['JSON'];
  getMenuXInhouseStatus: Scalars['JSON'];
  getMenuXDebtorList: Scalars['JSON'];
  getHotelBySubscription: Scalars['JSON'];
  getOrder: Array<OrderEntity>;
  getTakeawayOrder: Array<OrderEntity>;
  getKitchenOrder: Array<KitchenOrder>;
  getOrderDiscount: Array<OrderDiscountEntity>;
  getOrderItem: Array<OrderItemEntity>;
  getAllOrderItem: Array<OrderItemEntity>;
  getPrinterOrderItem: Array<OrderItemEntity>;
  getBillSettlementOrderItem: Array<OrderItemEntity>;
  getOrderItemDiscount: Array<OrderItemDiscountEntity>;
  getOrderItemOption: Array<OrderItemOptionEntity>;
  getOrderItemOptionItem: Array<OrderItemOptionItemEntity>;
  getOrderItemSetMenuOption: Array<OrderItemSetMenuOptionEntity>;
  getOrderItemSetMenuOptionItem: Array<OrderItemSetMenuOptionItemEntity>;
  getPatronAccount: Array<PatronAccountEntity>;
  getReservation: Array<ReservationEntity>;
  noShowCheck: Scalars['Boolean'];
  getTableSession: Array<TableSessionEntity>;
  getPayment: Array<PaymentEntity>;
  getPatronType: Array<PatronTypeEntity>;
  getPaymentType: Array<PaymentTypeEntity>;
  getPlatformTax: Array<PlatformTaxEntity>;
  computeTaxbyOutlet: TaxOutput;
  getUOM: Array<UomEntity>;
  getUOMConversion: Array<UomConversionEntity>;
  getUomConversionData: Scalars['JSON'];
  getOutletDailyMenuCategory: Array<OutletDailyMenuCategoryEntity>;
  getOutletDailyMenuItem: Array<OutletDailyMenuItemEntity>;
  getOutletDailyMenuItemOption: Array<OutletDailyMenuItemOptionEntity>;
  getOutletDailyMenuItemOptionItem: Array<OutletDailyMenuItemOptionItemEntity>;
  getOutletDailyMenuItemPatronPrice: Array<OutletDailyMenuItemPatronPriceEntity>;
  getOutletDailyPromoMenuItem: Array<OutletDailyPromoMenuItemEntity>;
  getOutletDailyPromoMenuItemPrice: Array<OutletDailyPromoMenuItemPriceEntity>;
  getOutletDailySetMenuOption: Array<OutletDailySetMenuOptionEntity>;
  getOutletDailySetMenuOptionItem: Array<OutletDailySetMenuOptionItemEntity>;
  bankAccountDate?: Maybe<Scalars['DateTime']>;
  glJournalDate?: Maybe<Scalars['DateTime']>;
  cbTransactionDate?: Maybe<Scalars['DateTime']>;
  billByDateCount?: Maybe<Scalars['JSON']>;
  outletSalesDate?: Maybe<Scalars['DateTime']>;
  mealPeriodDate?: Maybe<Scalars['DateTime']>;
  waiterSalesDate?: Maybe<Scalars['DateTime']>;
  fastMovingMenuItemDate?: Maybe<Scalars['DateTime']>;
  slowMovingMenuItemDate?: Maybe<Scalars['DateTime']>;
  patronTypeDate?: Maybe<Scalars['DateTime']>;
  discountTypeDate?: Maybe<Scalars['DateTime']>;
  averageMealTimeDate?: Maybe<Scalars['DateTime']>;
  userDate?: Maybe<Scalars['DateTime']>;
  roleDate?: Maybe<Scalars['DateTime']>;
  officerCount?: Maybe<Scalars['JSON']>;
  staffCount?: Maybe<Scalars['JSON']>;
  userCount?: Maybe<Scalars['JSON']>;
  roundingPolicyDate?: Maybe<Scalars['DateTime']>;
  uomDate?: Maybe<Scalars['DateTime']>;
  uomConversionDate?: Maybe<Scalars['DateTime']>;
  taxDate?: Maybe<Scalars['DateTime']>;
  taxSchemeDate?: Maybe<Scalars['DateTime']>;
  eInvoicePolicyDate?: Maybe<Scalars['DateTime']>;
  orderCount?: Maybe<Scalars['JSON']>;
  orderItemCount?: Maybe<Scalars['JSON']>;
  editOrderInfo?: Maybe<Scalars['JSON']>;
  newestTable?: Maybe<Scalars['JSON']>;
  itemTransferCount?: Maybe<Scalars['JSON']>;
  billItemDiscount?: Maybe<Scalars['JSON']>;
  billSettlement?: Maybe<Scalars['JSON']>;
  close?: Maybe<Scalars['JSON']>;
  patronSetupDate?: Maybe<Scalars['DateTime']>;
  taxSetupDate?: Maybe<Scalars['DateTime']>;
  reasonSetupDate?: Maybe<Scalars['DateTime']>;
  customerSetupDate?: Maybe<Scalars['DateTime']>;
  outletProfileDate?: Maybe<Scalars['DateTime']>;
  outletPolicyDate?: Maybe<Scalars['DateTime']>;
  outletPolicyDateNew?: Maybe<Scalars['JSON']>;
  tableCount?: Maybe<Scalars['JSON']>;
  qrCount?: Maybe<Scalars['JSON']>;
  kitchenPrinterCount?: Maybe<Scalars['JSON']>;
  receiptPrinterCount?: Maybe<Scalars['JSON']>;
  mealPeriodCount?: Maybe<Scalars['JSON']>;
  majorFamilyCount?: Maybe<Scalars['JSON']>;
  standardMenuOptionCount?: Maybe<Scalars['JSON']>;
  menuItemsCount?: Maybe<Scalars['JSON']>;
  setMenuCount?: Maybe<Scalars['JSON']>;
  digitalMenuPricingCount?: Maybe<Scalars['JSON']>;
  menuSchedulingCount?: Maybe<Scalars['JSON']>;
  promotionalMenuPricingCount?: Maybe<Scalars['JSON']>;
  dailyMenuItemAvailabilityCount?: Maybe<Scalars['JSON']>;
  promoCodeCount?: Maybe<Scalars['JSON']>;
  discountTypeCount?: Maybe<Scalars['JSON']>;
  stopSalesItemCount?: Maybe<Scalars['JSON']>;
  paymentTypeDate?: Maybe<Scalars['DateTime']>;
  getItemTotal: Scalars['JSON'];
};


export type QueryGetOutletBillChargesArgs = {
  outletID: Scalars['String'];
};


export type QueryGetOutletBillOcChargesArgs = {
  outletID: Scalars['String'];
};


export type QueryGetOutletBillEntChargesArgs = {
  outletID: Scalars['String'];
};


export type QueryAccountMappingTaxGlListArgs = {
  accountID: Scalars['String'];
  outletID: Scalars['String'];
};


export type QueryGetBankAccountArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
};


export type QueryGetBankAccountContactArgs = {
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};


export type QueryGetExportedCbArgs = {
  outletID: Scalars['String'];
};


export type QueryGetExportedCbDetailsArgs = {
  batchNo: Scalars['Float'];
  outletID: Scalars['String'];
};


export type QueryCheckBanknameInUseArgs = {
  bankAccountID: Scalars['String'];
};


export type QueryGetCbExportDataArgs = {
  endDate: Scalars['DateTime'];
  startDate: Scalars['DateTime'];
  outletID: Scalars['String'];
  accountID: Scalars['String'];
};


export type QueryGetExportedCbDetailsByTrxTypeArgs = {
  trxDate: Scalars['DateTime'];
  trxType: Scalars['String'];
  outletID: Scalars['String'];
  accountID: Scalars['String'];
};


export type QueryGetExportedGlListingArgs = {
  outletID: Scalars['String'];
};


export type QueryGetExportedGlDetailsArgs = {
  batchNo: Scalars['Float'];
  outletID: Scalars['String'];
};


export type QueryExportedGlTransferDetailsArgs = {
  batchNo: Scalars['Float'];
  accountID: Scalars['String'];
  outletID: Scalars['String'];
};


export type QueryGetGlExportArgs = {
  endDate: Scalars['DateTime'];
  startDate: Scalars['DateTime'];
  outletID: Scalars['String'];
  accountID: Scalars['String'];
};


export type QueryGetGlExportDetailsByTrxTypeArgs = {
  trxDate: Scalars['DateTime'];
  trxDesc: Scalars['String'];
  trxType: Scalars['String'];
  outletID: Scalars['String'];
  accountID: Scalars['String'];
};


export type QueryGetAttachmentArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['String']>;
};


export type QueryAttachmentListingArgs = {
  refID?: Maybe<Scalars['String']>;
};


export type QueryAuditLogDetailsArgs = {
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  fieldID: Scalars['String'];
  tableName: Scalars['String'];
};


export type QueryAverageMealTimeAnalysisArgs = {
  outletID: Scalars['String'];
};


export type QueryDiscountTypeAnalysisArgs = {
  endDate: Scalars['DateTime'];
  startDate: Scalars['DateTime'];
  outletID: Scalars['String'];
};


export type QueryFastMovingMenuItemAnalysisArgs = {
  endDate: Scalars['DateTime'];
  startDate: Scalars['DateTime'];
  mealPeriodID?: Maybe<Scalars['String']>;
  familyID?: Maybe<Scalars['String']>;
  majorID?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
};


export type QueryMealPeriodSalesAnalysisArgs = {
  endDate: Scalars['DateTime'];
  startDate: Scalars['DateTime'];
  outletID: Scalars['String'];
};


export type QueryOutletSalesCollectionAnalysisArgs = {
  frequencyType?: Maybe<Scalars['String']>;
  endDate: Scalars['DateTime'];
  startDate: Scalars['DateTime'];
  outletID: Scalars['String'];
};


export type QueryPatronTypeAnalysisArgs = {
  endDate: Scalars['DateTime'];
  startDate: Scalars['DateTime'];
  outletID: Scalars['String'];
};


export type QueryCustomerTypeAnalysisArgs = {
  endDate: Scalars['DateTime'];
  startDate: Scalars['DateTime'];
  outletID: Scalars['String'];
};


export type QuerySlowMovingMenuItemAnalysisArgs = {
  endDate: Scalars['DateTime'];
  startDate: Scalars['DateTime'];
  mealPeriodID?: Maybe<Scalars['String']>;
  familyID?: Maybe<Scalars['String']>;
  majorID?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
};


export type QueryWaiterSalesCollectionAnalysisArgs = {
  selectedDate?: Maybe<Scalars['DateTime']>;
  salesAmount?: Maybe<Scalars['Float']>;
  userID?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
};


export type QueryGetCompanyListingArgs = {
  recordStatus?: Maybe<Scalars['String']>;
  accountID: Scalars['String'];
};


export type QueryGetCompanyListingByIdArgs = {
  ID?: Maybe<Scalars['String']>;
  accountID: Scalars['String'];
};


export type QueryConsolidatedInvoiceListingArgs = {
  limit?: Maybe<Scalars['Float']>;
  offSet?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
  docType?: Maybe<Scalars['String']>;
  docNo?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  docDate?: Maybe<Scalars['DateTime']>;
  ID?: Maybe<Scalars['String']>;
  companyID: Scalars['String'];
  accountID: Scalars['String'];
  outletID: Scalars['String'];
};


export type QueryDocToConsolidateListingArgs = {
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  isSearch?: Maybe<Scalars['Boolean']>;
  docNo?: Maybe<Scalars['String']>;
  docType?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  month?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
  accountID: Scalars['String'];
  outletID: Scalars['String'];
};


export type QueryDocToConsolidateViewArgs = {
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  consolidationID: Scalars['String'];
  companyID: Scalars['String'];
  accountID: Scalars['String'];
  outletID: Scalars['String'];
};


export type QueryEInvoiceInformationArgs = {
  type: Scalars['String'];
  ID: Scalars['String'];
  outletID: Scalars['String'];
};


export type QueryGetEInvoiceSubArgs = {
  accountID: Scalars['String'];
};


export type QueryGetEntityRoleUserAssignmentArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  entityID?: Maybe<Scalars['String']>;
};


export type QueryGetRoleArgs = {
  ID?: Maybe<Scalars['String']>;
};


export type QueryUserRoleIDsArgs = {
  outletID: Scalars['String'];
};


export type QueryGetUsersRoleContractArgs = {
  outletID: Scalars['String'];
  roleID: Scalars['String'];
};


export type QueryTestPermissionArgs = {
  posPerm: PosPermission;
};


export type QueryGetEInvoicePolicyArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['String']>;
};


export type QueryLogoViewArgs = {
  description?: Maybe<Scalars['String']>;
  refTable?: Maybe<Scalars['String']>;
  refID?: Maybe<Scalars['String']>;
};


export type QueryGetOfficerArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
};


export type QueryGetRoundingPolicyArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['String']>;
};


export type QueryGetStaffArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
};


export type QueryGetTaxSchemeListingArgs = {
  accountID: Scalars['String'];
};


export type QueryIsTaxSameNameArgs = {
  taxSchemeName: Scalars['String'];
  accountID: Scalars['String'];
};


export type QueryGetTaxSchemeDetailItemRateArgs = {
  effectiveDate: Scalars['DateTime'];
  taxSchemeID: Scalars['String'];
  accountID: Scalars['String'];
};


export type QueryGetTaxSchemeDetailArgs = {
  orderID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  accountID: Scalars['String'];
};


export type QueryIsTaxSchemeInUseArgs = {
  outletID: Scalars['String'];
  taxSchemeID: Scalars['String'];
};


export type QueryGetTaxTypeListingArgs = {
  softwareCode: Scalars['String'];
};


export type QueryGetTaxListingArgs = {
  accountID: Scalars['String'];
};


export type QueryIsTaxSameCodeArgs = {
  taxCode: Scalars['String'];
  accountID: Scalars['String'];
};


export type QueryIsTaxInUseArgs = {
  taxID: Scalars['String'];
  accountID: Scalars['String'];
};


export type QueryGetTaxDetailsArgs = {
  taxID: Scalars['String'];
  accountID: Scalars['String'];
};


export type QueryGetTaxListingDropdownArgs = {
  softwareCode: Scalars['String'];
  accountID: Scalars['String'];
};


export type QueryGetUsersByAccountArgs = {
  accountID: Scalars['String'];
};


export type QueryGetUserArgs = {
  ID: Scalars['String'];
};


export type QueryGetUsersbyIDsArgs = {
  userIDs: Array<Scalars['String']>;
};


export type QueryIsUserValidArgs = {
  userID: Scalars['String'];
};


export type QueryGetUsersByAccountAndSoftwareArgs = {
  superUserBool?: Maybe<UserSelection>;
  status?: Maybe<CommonStatus>;
};


export type QueryGetUsersWaiterRoleArgs = {
  outletID: Scalars['String'];
};


export type QueryGetDailyMenuItemAvailabilityArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
  menuItemID?: Maybe<Scalars['String']>;
};


export type QueryGetDailyMenuPriceArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  promotionMenuID?: Maybe<Scalars['String']>;
  menuItemID?: Maybe<Scalars['String']>;
  startTs?: Maybe<Scalars['DateTime']>;
  endTs?: Maybe<Scalars['DateTime']>;
};


export type QueryGetDefaultPaymentArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
  paymentTypeID?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
};


export type QueryGetDigitalMenuArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
};


export type QueryGetScheduledDigitalMenuArgs = {
  mealPeriodID: Scalars['String'];
  outletID: Scalars['String'];
};


export type QueryIsDigitalMenuInUseArgs = {
  digitalMenuID: Scalars['String'];
  outletID: Scalars['String'];
};


export type QueryGetDigitalMenuCategoryArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
  digitalMenuID?: Maybe<Scalars['String']>;
};


export type QueryGetScheduledDigitalMenuCategoryArgs = {
  mealPeriodID: Scalars['String'];
};


export type QueryIsDigitalMenuCategoryInUseArgs = {
  digitalMenuCategoryID: Scalars['String'];
  outletID: Scalars['String'];
};


export type QueryGetDigitalMenuItemArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  digitalMenuCategoryID?: Maybe<Scalars['String']>;
  menuItemID?: Maybe<Scalars['String']>;
};


export type QueryGetDigitalMenuItemPriceArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  digitalMenuItemID?: Maybe<Scalars['String']>;
  patronTypeID?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  outletID?: Maybe<Scalars['String']>;
};


export type QueryGetMenuItemForDigitalMenuItemPriceArgs = {
  outletID: Scalars['String'];
  digitalMenuCategoryID: Scalars['String'];
};


export type QueryGetDiscountArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
};


export type QueryGetFilteredDiscountArgs = {
  isOrderItemDiscount: Scalars['Boolean'];
  amount: Scalars['Float'];
  outletID: Scalars['String'];
};


export type QueryGetSortedDiscountArgs = {
  outletID?: Maybe<Scalars['String']>;
};


export type QueryGetVoidedDiscountArgs = {
  outletID: Scalars['String'];
  orderID: Scalars['String'];
};


export type QueryGetFamilyArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
  majorID?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  name?: Maybe<Scalars['String']>;
};


export type QueryGetKitchenPrinterArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
};


export type QueryGetMajorArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  name?: Maybe<Scalars['String']>;
};


export type QueryGetMealPeriodArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  validFrom?: Maybe<Scalars['String']>;
  validTill?: Maybe<Scalars['String']>;
  menus?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
};


export type QueryGetCurrentMealPeriodArgs = {
  outletID: Scalars['String'];
};


export type QueryGetMenuItemArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  familyID?: Maybe<Scalars['String']>;
  majorID?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
  isSetMenu?: Maybe<Scalars['Boolean']>;
  isQuantityControlled?: Maybe<Scalars['Boolean']>;
};


export type QueryGetMenuItemForSetMenuOptionItemArgs = {
  isStandard?: Maybe<Scalars['Boolean']>;
  outletID: Scalars['String'];
  setMenuID: Scalars['String'];
};


export type QueryGetMenuItemOptionArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  menuItemID?: Maybe<Scalars['String']>;
  optionID?: Maybe<Scalars['String']>;
};


export type QueryGetOperatingHoursArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
  day: RecurrenceDay;
};


export type QueryGetOptionArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  name?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
};


export type QueryGetOptionItemArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  outletID?: Maybe<Scalars['String']>;
  optionID?: Maybe<Scalars['String']>;
};


export type QueryGetOutletArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
};


export type QueryGetOutletCustomerPolicyArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  outletID: Scalars['String'];
  selfOrder?: Maybe<Scalars['Boolean']>;
  selfPayment?: Maybe<Scalars['Boolean']>;
  qrCodeGenerate?: Maybe<Scalars['Boolean']>;
};


export type QueryGetOutletTaxPolicyArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
  taxType?: Maybe<TaxType>;
  isTaxable?: Maybe<Scalars['Boolean']>;
  effectiveDate?: Maybe<Scalars['DateTime']>;
};


export type QueryGetPatronArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  personID?: Maybe<Scalars['String']>;
  patronTypeID?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
};


export type QueryGetOutletVisitorPatronArgs = {
  outletID: Scalars['String'];
};


export type QueryGetPromoCodeArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  validFrom?: Maybe<Scalars['DateTime']>;
  validTill?: Maybe<Scalars['DateTime']>;
  calcType?: Maybe<CalcType>;
  amount?: Maybe<Scalars['Float']>;
  percentage?: Maybe<Scalars['Float']>;
};


export type QueryGetPromoMenuArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
};


export type QueryGetPromoMenuItemArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  promoMenuID?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
  menuItemID?: Maybe<Scalars['String']>;
};


export type QueryPromoMenuItemCheckingArgs = {
  promoMenuID: Scalars['String'];
  menuItemIDs: Array<Scalars['String']>;
};


export type QueryGetPromoMenuItemPriceArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  promoMenuItemID?: Maybe<Scalars['String']>;
  patronTypeID?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
};


export type QueryGetMenuItemForPromoMenuItemPriceArgs = {
  outletID: Scalars['String'];
  promoMenuID: Scalars['String'];
};


export type QueryGetQrArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
};


export type QueryOpenOrderQrCheckingArgs = {
  qrID: Scalars['String'];
};


export type QueryGetReasonArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};


export type QueryGetReceiptPrinterArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
};


export type QueryGetScheduledMenuMealPeriodArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
  mealPeriodID?: Maybe<Scalars['String']>;
  scheduledMenuID?: Maybe<Scalars['String']>;
  digitalMenuID?: Maybe<Scalars['String']>;
};


export type QueryGetScheduledMenuArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  effectiveTs?: Maybe<Scalars['DateTime']>;
};


export type QueryGetSetMenuOptionArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  maxSelections?: Maybe<Scalars['Float']>;
  menuItemID?: Maybe<Scalars['String']>;
};


export type QueryGetSetMenuOptionOptionArgs = {
  outletID: Scalars['String'];
  menuItemID: Scalars['String'];
};


export type QueryGetSetMenuOptionItemArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  recommendedPriceAdjustment?: Maybe<Scalars['Float']>;
};


export type QueryGetStopSalesArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
  menuItemID?: Maybe<Scalars['String']>;
};


export type QueryGetMenuItemForStopSaleArgs = {
  outletID: Scalars['String'];
};


export type QueryGetTableArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  prefix?: Maybe<Scalars['String']>;
  tableNumber?: Maybe<Scalars['String']>;
  seatCount?: Maybe<Scalars['Float']>;
  outletID?: Maybe<Scalars['String']>;
  sequence?: Maybe<Scalars['Float']>;
};


export type QueryGetTableByAscOrderArgs = {
  isActive?: Maybe<Scalars['Boolean']>;
  outletID: Scalars['String'];
};


export type QueryMenuAccountXListArgs = {
  outletID: Scalars['String'];
};


export type QueryGetBillArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
  billNo?: Maybe<Scalars['String']>;
};


export type QueryGetBillInfiniteArgs = {
  limit?: Maybe<Scalars['Float']>;
  offSet?: Maybe<Scalars['Float']>;
  tabValue?: Maybe<Scalars['Float']>;
  customerType?: Maybe<Scalars['String']>;
  paymentType?: Maybe<Scalars['String']>;
  billNo?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  input: BillInputOffset;
};


export type QueryGetBillWithDateArgs = {
  limit: Scalars['Float'];
  offSet: Scalars['Float'];
  endDate: Scalars['DateTime'];
  startDate: Scalars['DateTime'];
  billNo?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
};


export type QueryGetCnBillOrderItemArgs = {
  outletID: Scalars['String'];
  ogBillID: Scalars['String'];
};


export type QueryGetBillDiscountArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
  billID?: Maybe<Scalars['String']>;
};


export type QueryGetMenuXInhouseListArgs = {
  outletID?: Maybe<Scalars['String']>;
  hotelID: Scalars['String'];
};


export type QueryGetMenuXInhouseStatusArgs = {
  outletID?: Maybe<Scalars['String']>;
  registrationID: Scalars['String'];
};


export type QueryGetMenuXDebtorListArgs = {
  outletID?: Maybe<Scalars['String']>;
  hotelID: Scalars['String'];
};


export type QueryGetOrderArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
  tableID?: Maybe<Scalars['String']>;
  mealPeriodID?: Maybe<Scalars['String']>;
  patronAccountID?: Maybe<Scalars['String']>;
  personID?: Maybe<Scalars['String']>;
  isSupervisor?: Maybe<Scalars['String']>;
  status?: Maybe<OrderStatus>;
  serviceMode?: Maybe<ServiceMode>;
};


export type QueryGetTakeawayOrderArgs = {
  orderID?: Maybe<Scalars['String']>;
  outletID: Scalars['String'];
};


export type QueryGetKitchenOrderArgs = {
  kitchenPrinterID: Scalars['String'];
  outletID: Scalars['String'];
};


export type QueryGetOrderDiscountArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  discountID?: Maybe<Scalars['String']>;
  orderID?: Maybe<Scalars['String']>;
  status?: Maybe<OrderDiscountStatus>;
  amount?: Maybe<Scalars['String']>;
  discountedBy?: Maybe<Scalars['String']>;
  voidedBy?: Maybe<Scalars['String']>;
};


export type QueryGetOrderItemArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  menuItemID?: Maybe<Scalars['String']>;
  orderID?: Maybe<Scalars['String']>;
  billID?: Maybe<Scalars['String']>;
  status?: Maybe<OrderItemStatus>;
  kitchenStatus?: Maybe<KitchenStatus>;
};


export type QueryGetAllOrderItemArgs = {
  orderID: Scalars['String'];
};


export type QueryGetPrinterOrderItemArgs = {
  orderItemIDs: Array<Scalars['String']>;
  orderID: Scalars['String'];
};


export type QueryGetBillSettlementOrderItemArgs = {
  orderID: Scalars['String'];
};


export type QueryGetOrderItemDiscountArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  discountID?: Maybe<Scalars['String']>;
  orderItemID?: Maybe<Scalars['String']>;
  status?: Maybe<OrderItemDiscountStatus>;
  amount?: Maybe<Scalars['String']>;
  discountedBy?: Maybe<Scalars['String']>;
  voidedBy?: Maybe<Scalars['String']>;
};


export type QueryGetOrderItemOptionArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  optionID?: Maybe<Scalars['String']>;
  orderItemID?: Maybe<Scalars['String']>;
};


export type QueryGetOrderItemOptionItemArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  taxAmount?: Maybe<Scalars['Float']>;
  optionItemID?: Maybe<Scalars['String']>;
};


export type QueryGetOrderItemSetMenuOptionArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
  setMenuOptionID?: Maybe<Scalars['String']>;
  orderItemID?: Maybe<Scalars['String']>;
};


export type QueryGetOrderItemSetMenuOptionItemArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  setMenuOptionItemID?: Maybe<Scalars['String']>;
  orderItemSetMenuOptionItemID?: Maybe<Scalars['String']>;
};


export type QueryGetPatronAccountArgs = {
  isMember?: Maybe<Scalars['Boolean']>;
};


export type QueryGetReservationArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  patronID?: Maybe<Scalars['String']>;
  tableID?: Maybe<Scalars['String']>;
  pax?: Maybe<Scalars['Float']>;
  outletID?: Maybe<Scalars['String']>;
};


export type QueryNoShowCheckArgs = {
  reservationID: Scalars['String'];
};


export type QueryGetTableSessionArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  tableID?: Maybe<Scalars['String']>;
};


export type QueryGetPaymentArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
  billID?: Maybe<Scalars['String']>;
  amountPaid?: Maybe<Scalars['Float']>;
  roundingAdjustment?: Maybe<Scalars['Float']>;
  paidByID?: Maybe<Scalars['String']>;
};


export type QueryGetPatronTypeArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  name?: Maybe<Scalars['String']>;
};


export type QueryGetPaymentTypeArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  paymentClass?: Maybe<PaymentClass>;
};


export type QueryGetPlatformTaxArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  effectiveDate?: Maybe<Scalars['DateTime']>;
  value?: Maybe<Scalars['Float']>;
};


export type QueryComputeTaxbyOutletArgs = {
  input: TaxInput;
};


export type QueryGetUomArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
};


export type QueryGetUomConversionArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  fromUomID?: Maybe<Scalars['String']>;
  toUomID?: Maybe<Scalars['String']>;
};


export type QueryGetOutletDailyMenuCategoryArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  digitalMenuCategoryID?: Maybe<Scalars['String']>;
  businessDate?: Maybe<Scalars['DateTime']>;
  mealPeriodID?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
};


export type QueryGetOutletDailyMenuItemArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  outletDailyMenuCategoryID?: Maybe<Scalars['String']>;
  menuItemID?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  name?: Maybe<Scalars['String']>;
};


export type QueryGetOutletDailyMenuItemOptionArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  outletDailyMenuItemID?: Maybe<Scalars['String']>;
  optionID?: Maybe<Scalars['String']>;
};


export type QueryGetOutletDailyMenuItemOptionItemArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  outletDailyMenuItemOptionID?: Maybe<Scalars['String']>;
  optionItemID?: Maybe<Scalars['String']>;
};


export type QueryGetOutletDailyMenuItemPatronPriceArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  outletDailyMenuItemID?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
};


export type QueryGetOutletDailyPromoMenuItemArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
  outletDailyMenuItemID?: Maybe<Scalars['String']>;
};


export type QueryGetOutletDailyPromoMenuItemPriceArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  outletDailyPromoMenuItemID?: Maybe<Scalars['String']>;
};


export type QueryGetOutletDailySetMenuOptionArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<Scalars['String']>;
};


export type QueryGetOutletDailySetMenuOptionItemArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  outletDailySetMenuOptionID?: Maybe<Scalars['String']>;
  outletDailyMenuItemID?: Maybe<Scalars['String']>;
};


export type QueryBillByDateCountArgs = {
  outletID: Scalars['String'];
};


export type QueryOrderCountArgs = {
  tableID: Scalars['String'];
};


export type QueryOrderItemCountArgs = {
  tableID: Scalars['String'];
};


export type QueryEditOrderInfoArgs = {
  tableID: Scalars['String'];
};


export type QueryNewestTableArgs = {
  ID: Scalars['String'];
};


export type QueryItemTransferCountArgs = {
  tableID: Scalars['String'];
};


export type QueryBillItemDiscountArgs = {
  tableID: Scalars['String'];
};


export type QueryBillSettlementArgs = {
  tableID: Scalars['String'];
};


export type QueryCloseArgs = {
  tableID: Scalars['String'];
};


export type QueryPatronSetupDateArgs = {
  outletID: Scalars['String'];
};


export type QueryTaxSetupDateArgs = {
  outletID: Scalars['String'];
};


export type QueryReasonSetupDateArgs = {
  outletID: Scalars['String'];
};


export type QueryCustomerSetupDateArgs = {
  outletID: Scalars['String'];
};


export type QueryOutletProfileDateArgs = {
  ID: Scalars['String'];
};


export type QueryOutletPolicyDateArgs = {
  outletID: Scalars['String'];
};


export type QueryOutletPolicyDateNewArgs = {
  outletID: Scalars['String'];
};


export type QueryTableCountArgs = {
  outletID: Scalars['String'];
};


export type QueryQrCountArgs = {
  outletID: Scalars['String'];
};


export type QueryKitchenPrinterCountArgs = {
  outletID: Scalars['String'];
};


export type QueryReceiptPrinterCountArgs = {
  outletID: Scalars['String'];
};


export type QueryMealPeriodCountArgs = {
  outletID: Scalars['String'];
};


export type QueryMajorFamilyCountArgs = {
  outletID: Scalars['String'];
};


export type QueryStandardMenuOptionCountArgs = {
  outletID: Scalars['String'];
};


export type QueryMenuItemsCountArgs = {
  outletID: Scalars['String'];
};


export type QuerySetMenuCountArgs = {
  outletID: Scalars['String'];
};


export type QueryDigitalMenuPricingCountArgs = {
  outletID: Scalars['String'];
};


export type QueryMenuSchedulingCountArgs = {
  outletID: Scalars['String'];
};


export type QueryPromotionalMenuPricingCountArgs = {
  outletID: Scalars['String'];
};


export type QueryDailyMenuItemAvailabilityCountArgs = {
  outletID: Scalars['String'];
};


export type QueryPromoCodeCountArgs = {
  outletID: Scalars['String'];
};


export type QueryDiscountTypeCountArgs = {
  outletID: Scalars['String'];
};


export type QueryStopSalesItemCountArgs = {
  outletID: Scalars['String'];
};


export type QueryPaymentTypeDateArgs = {
  outletID: Scalars['String'];
};


export type QueryGetItemTotalArgs = {
  orderItemID: Scalars['String'];
};

export type ReasonEntity = AuditEntity & {
  __typename?: 'ReasonEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  name: Scalars['String'];
  description: Scalars['String'];
  outletID: Scalars['String'];
};

export type ReasonInput = {
  ID?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['String']>;
  outletID: Scalars['String'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
};

export type ReceiptPrinterEntity = AuditEntity & {
  __typename?: 'ReceiptPrinterEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  name: Scalars['String'];
  outletID: Scalars['String'];
  isDefault: Scalars['Boolean'];
};

export type ReceiptPrinterInput = {
  ID?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  isDefault?: Maybe<Scalars['Boolean']>;
};

export enum RecurrenceDay {
  Sunday = 'SUNDAY',
  Monday = 'MONDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY',
  Thursday = 'THURSDAY',
  Friday = 'FRIDAY',
  Saturday = 'SATURDAY'
}

export type ReportDevEntity = {
  __typename?: 'ReportDevEntity';
  ID: Scalars['String'];
  ParentID: Scalars['String'];
  ReportName: Scalars['String'];
  FilePath?: Maybe<Scalars['String']>;
  IsSystem?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Module?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['String']>;
};

export type ReservationEntity = AuditEntity & {
  __typename?: 'ReservationEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  outletID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  contactNo: Scalars['String'];
  reservationDate: Scalars['DateTime'];
  reservationTime: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
  noShow?: Maybe<Scalars['Boolean']>;
  pax: Scalars['Float'];
  status: ReservationStatus;
  tableID?: Maybe<Scalars['String']>;
  table?: Maybe<TableEntity>;
  patronID?: Maybe<Scalars['String']>;
  patron?: Maybe<PatronEntity>;
};

/** JsonType */
export type ReservationInfo = {
  __typename?: 'ReservationInfo';
  reserved: Scalars['Boolean'];
  pax: Scalars['Float'];
  name: Scalars['String'];
  time: Scalars['String'];
  noShow: Scalars['Boolean'];
};

export type ReservationInput = {
  noShow?: Maybe<Scalars['Boolean']>;
  patronID?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
  tableID?: Maybe<Scalars['String']>;
  outletID: Scalars['String'];
  contactNo: Scalars['String'];
  reservationDate: Scalars['DateTime'];
  reservationTime: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
  pax: Scalars['Float'];
};

export enum ReservationStatus {
  Active = 'ACTIVE',
  Closed = 'CLOSED',
  Cancelled = 'CANCELLED'
}

export type RoleEntity = AuditEntity & {
  __typename?: 'RoleEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  name: Scalars['String'];
  rolePerm: Array<RolePermissionAssignmentEntity>;
};

export type RoleInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  name: Scalars['String'];
};

export type RolePermissionAssignmentEntity = AuditEntity & {
  __typename?: 'RolePermissionAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  roleID: Scalars['String'];
  permissionID: Scalars['Float'];
  permName?: Maybe<Scalars['String']>;
};

export type RoleUserEntAssignmentEntity = AuditEntity & {
  __typename?: 'RoleUserEntAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  entityID: Scalars['String'];
  roleID: Scalars['String'];
  accountID: Scalars['String'];
  userID: Scalars['String'];
};

export type RoleUserInput = {
  roleID: Scalars['String'];
  userID: Array<Scalars['String']>;
};

export type RoundingPolicyEntity = AuditEntity & {
  __typename?: 'RoundingPolicyEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  inUse: Scalars['Boolean'];
  transactionType: TransactionType;
  methodType: MethodType;
  smallestDenomination: Scalars['Float'];
};

export type RoundingPolicyInput = {
  ID?: Maybe<Scalars['String']>;
  inUse?: Maybe<Scalars['Boolean']>;
  transactionType?: Maybe<TransactionType>;
  methodType?: Maybe<MethodType>;
  smallestDenomination?: Maybe<Scalars['Float']>;
};

export type ScheduleBillingInput = {
  ID?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  BillNo?: Maybe<Scalars['String']>;
  CardNo?: Maybe<Scalars['String']>;
  BillDate: Scalars['DateTime'];
  BillAmount?: Maybe<Scalars['Float']>;
  DebtorID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
};

/** JsonType */
export type ScheduledDigitalMenuMealPeriod = {
  __typename?: 'ScheduledDigitalMenuMealPeriod';
  mealPeriodID: Scalars['String'];
  mealPeriodName: Scalars['String'];
  digitalMenuID: Scalars['String'];
  digitalMenuName: Scalars['String'];
};

export type ScheduledMenuEntity = AuditEntity & {
  __typename?: 'ScheduledMenuEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  name: Scalars['String'];
  outletID?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  effectiveTs: Scalars['DateTime'];
  /** CustomFieldResolver */
  scheduledDigitalMenuMealPeriod?: Maybe<Scalars['JSON']>;
};

export type ScheduledMenuInput = {
  ID?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['String']>;
  outletID: Scalars['String'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  effectiveTs: Scalars['DateTime'];
};

export type ScheduledMenuMealPeriodEntity = AuditEntity & {
  __typename?: 'ScheduledMenuMealPeriodEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  outletID: Scalars['String'];
  mealPeriodID: Scalars['String'];
  scheduledMenuID: Scalars['String'];
  digitalMenuID: Scalars['String'];
};

export type ScheduledMenuMealPeriodInput = {
  ID?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['String']>;
  outletID: Scalars['String'];
  mealPeriodID: Scalars['String'];
  scheduledMenuID?: Maybe<Scalars['String']>;
  digitalMenuID: Scalars['String'];
};

export enum ServiceMode {
  DineIn = 'DINE_IN',
  Pickup = 'PICKUP',
  Delivery = 'DELIVERY'
}

/** JsonType */
export type SetMenuInfo = {
  __typename?: 'SetMenuInfo';
  menuItemID: Scalars['String'];
  setMenuID: Scalars['String'];
  setMenuName: Scalars['String'];
};

export type SetMenuOptionEntity = AuditEntity & {
  __typename?: 'SetMenuOptionEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
  nativeName?: Maybe<Scalars['String']>;
  menuItemID: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  maxSelections: Scalars['Float'];
  setMenuOptionItem?: Maybe<Array<SetMenuOptionItemEntity>>;
  menuItem?: Maybe<MenuItemEntity>;
  orderItemSetMenuOption?: Maybe<Array<OrderItemSetMenuOptionEntity>>;
  paymentItemSetMenuOption?: Maybe<Array<PaymentItemSetMenuOptionEntity>>;
};

export type SetMenuOptionInput = {
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  nativeName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  outletID: Scalars['String'];
  maxSelections: Scalars['Float'];
  menuItemID: Scalars['String'];
};

export type SetMenuOptionItemEntity = AuditEntity & {
  __typename?: 'SetMenuOptionItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  outletID: Scalars['String'];
  setMenuOptionID: Scalars['String'];
  menuItemID: Scalars['String'];
  quantity?: Maybe<Scalars['Float']>;
  setMenuOption?: Maybe<SetMenuOptionEntity>;
  menuItem?: Maybe<MenuItemEntity>;
  orderItemSetMenuOptionItem?: Maybe<Array<OrderItemSetMenuOptionItemEntity>>;
  paymentItemSetMenuOptionItem?: Maybe<Array<PaymentItemSetMenuOptionItemEntity>>;
};

export type SetMenuOptionItemInput = {
  outletID?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
  menuItemID: Scalars['String'];
  quantity?: Maybe<Scalars['Float']>;
  setMenuOptionID?: Maybe<Scalars['String']>;
};

export type ShareAttachmentEntity = AuditEntity & {
  __typename?: 'ShareAttachmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID?: Maybe<Scalars['String']>;
  isActive: Scalars['Boolean'];
  outletID?: Maybe<Scalars['String']>;
  bucketFileName?: Maybe<Scalars['String']>;
  fileURL?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
};

export type StaffEntity = AuditEntity & {
  __typename?: 'StaffEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  paymentID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  designation: Scalars['String'];
  mobileNo: Scalars['String'];
  email: Scalars['String'];
};

export type StaffInput = {
  name: Scalars['String'];
  designation: Scalars['String'];
  mobileNo: Scalars['String'];
  email: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  ID?: Maybe<Scalars['String']>;
};

export type StandardAuditEntity = {
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
};

export type StandardCoreBaseEntity = AuditEntity & {
  __typename?: 'StandardCoreBaseEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
};

export type StopSalesEntity = AuditEntity & {
  __typename?: 'StopSalesEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  menuItemID: Scalars['String'];
  outletID: Scalars['String'];
  menuItem?: Maybe<MenuItemEntity>;
  major: StopSalesEntity;
};

export type StopSalesInput = {
  menuItemID: Scalars['String'];
  outletID?: Maybe<Scalars['String']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  refreshTable: TableEntity;
  refreshOrderItem: OrderItemEntity;
  getPrintData: PrintData;
};


export type SubscriptionRefreshTableArgs = {
  outletID: Scalars['String'];
};


export type SubscriptionRefreshOrderItemArgs = {
  outletID: Scalars['String'];
};


export type SubscriptionGetPrintDataArgs = {
  outletID: Scalars['String'];
};

export type TableEntity = AuditEntity & {
  __typename?: 'TableEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  outletID: Scalars['String'];
  sequence?: Maybe<Scalars['Float']>;
  outlet?: Maybe<OutletEntity>;
  seatCount: Scalars['Float'];
  prefix: Scalars['String'];
  tableNumber: Scalars['String'];
  status: TableStatus;
  reservation?: Maybe<ReservationEntity>;
  order?: Maybe<Array<OrderEntity>>;
  /** CustomFieldResolver */
  orderInfo?: Maybe<OrderInfo>;
  /** CustomFieldResolver */
  reservationInfo?: Maybe<ReservationInfo>;
  /** CustomFieldResolver */
  tablePrfixNumberInfo?: Maybe<Scalars['JSON']>;
};

/** JsonType */
export type TableInfo = {
  __typename?: 'TableInfo';
  count: Scalars['Float'];
  capacity: Scalars['Float'];
};

export type TableInput = {
  ID?: Maybe<Scalars['String']>;
  outletID: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  seatCount?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
  prefix?: Maybe<Scalars['String']>;
  tableNumber?: Maybe<Scalars['String']>;
};

/** JsonType */
export type TablePrfixNumberInfo = {
  __typename?: 'TablePrfixNumberInfo';
  prefixNumber: Scalars['String'];
};

export type TableSessionEntity = AuditEntity & {
  __typename?: 'TableSessionEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  tableID: Scalars['String'];
  tableSessionType: TableSessionType;
  billIDs: Array<Scalars['String']>;
  closedTs: Scalars['DateTime'];
  duration: Scalars['Float'];
};

export type TableSessionInput = {
  tableID: Scalars['String'];
  tableSessionType: TableSessionType;
  billIDs: Array<Scalars['String']>;
  closedTs: Scalars['DateTime'];
  duration: Scalars['Float'];
};

export enum TableSessionType {
  Preparation = 'PREPARATION',
  Dining = 'DINING',
  Closed = 'CLOSED',
  Opened = 'OPENED'
}

export enum TableStatus {
  Available = 'AVAILABLE',
  Occupied = 'OCCUPIED',
  Reserved = 'RESERVED'
}

export type TaxDetailInput = {
  id?: Maybe<Scalars['String']>;
  taxId?: Maybe<Scalars['String']>;
  effectiveDate: Scalars['DateTime'];
  rate: Scalars['Float'];
};

/** JsonType */
export type TaxDetailtoInfo = {
  __typename?: 'TaxDetailtoInfo';
  id: Scalars['String'];
  accountId: Scalars['String'];
  taxId: Scalars['String'];
  commonStatus: Scalars['String'];
  effectiveDate: Scalars['DateTime'];
  rate: Scalars['Float'];
};

export type TaxGlEntity = AuditEntity & {
  __typename?: 'TaxGLEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  outletID: Scalars['String'];
  accountID: Scalars['String'];
  taxID: Scalars['String'];
  schemetypeID: Scalars['String'];
  outletTaxGLAcct?: Maybe<AccountJson>;
  outletTaxDiscountGLAcct?: Maybe<AccountJson>;
};

export type TaxGlInput = {
  Description?: Maybe<Scalars['String']>;
  DescriptionType?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  taxID?: Maybe<Scalars['String']>;
  GL?: Maybe<AccountJsonInput>;
  GLDisc?: Maybe<AccountJsonInput>;
};

export type TaxInput = {
  outletID?: Maybe<Scalars['String']>;
  tax?: Maybe<Scalars['Boolean']>;
  serviceCharge?: Maybe<Scalars['Boolean']>;
  trxAmount?: Maybe<Scalars['Float']>;
  discountAmount?: Maybe<Scalars['Float']>;
  unitPrice?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
};

export type TaxListingOutput = {
  __typename?: 'TaxListingOutput';
  tax_id: Scalars['String'];
  code: Scalars['String'];
  description: Scalars['String'];
  class_type: Scalars['String'];
  tax_status: Scalars['String'];
  effective_date: Scalars['DateTime'];
  rate: Scalars['String'];
  rn?: Maybe<Scalars['String']>;
  tax_type?: Maybe<Scalars['String']>;
  scheme_type_id?: Maybe<Scalars['String']>;
};

export type TaxOnSeq = {
  __typename?: 'TaxOnSeq';
  taxSchemeDetailItemId: Scalars['String'];
  seqNo: Scalars['Float'];
};

export type TaxOutput = {
  __typename?: 'TaxOutput';
  discountAmount?: Maybe<Scalars['Float']>;
  baseAmount?: Maybe<Scalars['Float']>;
  taxAmount?: Maybe<Scalars['Float']>;
  serviceCharge?: Maybe<Scalars['Float']>;
  totalAmount?: Maybe<Scalars['Float']>;
};

export type TaxSchemeDetailInput = {
  detailId?: Maybe<Scalars['String']>;
  effectiveDate: Scalars['DateTime'];
  taxSchemeDetailItem: Array<TaxSchemeDetailItemInput>;
};

export type TaxSchemeDetailItemInput = {
  detailItemId?: Maybe<Scalars['String']>;
  detailId?: Maybe<Scalars['String']>;
  taxId?: Maybe<Scalars['String']>;
  classType: Scalars['String'];
  computeMethod: ComputeMethod;
  seqNo?: Maybe<Scalars['Float']>;
  taxOnSeq?: Maybe<Array<TaxOnSeqInput>>;
};

export type TaxSchemeDetailItemRateOutput = {
  __typename?: 'TaxSchemeDetailItemRateOutput';
  isExclusive: Scalars['Boolean'];
  taxId: Scalars['String'];
  taxRate: Scalars['Float'];
  computeMethod: Scalars['String'];
  seqNo: Scalars['String'];
  taxOnSeq?: Maybe<Array<TaxOnSeqObjects>>;
  taxSchemeDetailId: Scalars['String'];
};

export type TaxSchemeDetailOutput = {
  __typename?: 'TaxSchemeDetailOutput';
  taxId: Scalars['String'];
  taxRate: Scalars['Float'];
  taxCode: Scalars['String'];
  taxType: Scalars['String'];
};

/** JsonType */
export type TaxSchemeDetailsInfo = {
  __typename?: 'TaxSchemeDetailsInfo';
  id: Scalars['String'];
  accountId: Scalars['String'];
  taxSchemeId: Scalars['String'];
  effectiveDate: Scalars['DateTime'];
  commonStatus: Scalars['String'];
};

/** JsonType */
export type TaxSchemeInfo = {
  __typename?: 'TaxSchemeInfo';
  id: Scalars['String'];
  accountId: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
  isExclusive: Scalars['Boolean'];
  commonStatus: Scalars['String'];
};

export type TaxSchemeInput = {
  createdBy: Scalars['String'];
  modBy: Scalars['String'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  isExclusive?: Maybe<Scalars['Boolean']>;
  taxSchemeDetail?: Maybe<Array<TaxSchemeDetailInput>>;
};

export type TaxSetupInput = {
  code: Scalars['String'];
  description: Scalars['String'];
  classType: Scalars['String'];
  isClaimable: Scalars['Boolean'];
  schemeTypeId: Scalars['String'];
  createdBy?: Maybe<Scalars['String']>;
  modBy?: Maybe<Scalars['String']>;
  taxDetail: Array<TaxDetailInput>;
};

export enum TaxType {
  GovTax = 'GOV_TAX',
  ServiceCharge = 'SERVICE_CHARGE'
}

export type TestOutletInput = {
  name: Scalars['String'];
};

/** JsonType */
export type TotalInfo = {
  __typename?: 'TotalInfo';
  totalAmount: Scalars['Float'];
  totalBaseAmount: Scalars['Float'];
  totalTax: Scalars['Float'];
  totalServiceCharge: Scalars['Float'];
};

/** JsonType */
export type TotalTaxSchemeInfo = {
  __typename?: 'TotalTaxSchemeInfo';
  totalSST: Scalars['Float'];
  totalSC: Scalars['Float'];
  totalGST: Scalars['Float'];
};

export type TotpSecretTyes = {
  __typename?: 'TotpSecretTyes';
  is2FA?: Maybe<Scalars['Boolean']>;
  totpSecret?: Maybe<Scalars['String']>;
  dataURL?: Maybe<Scalars['String']>;
  otpURL?: Maybe<Scalars['String']>;
};

export enum TransactionType {
  Cash = 'CASH',
  Debit = 'DEBIT',
  Credit = 'CREDIT',
  Round = 'ROUND'
}

export type TransferInput = {
  orderItemID: Scalars['String'];
  removeQty: Scalars['Float'];
};

export type UomDeleteInput = {
  ID: Scalars['String'];
};

export type UomEntity = AuditEntity & {
  __typename?: 'UOMEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  name: Scalars['String'];
  code: Scalars['String'];
  commonStatus: CommonStatus;
  menuItem?: Maybe<Array<MenuItemEntity>>;
  fromUOMConversion?: Maybe<Array<UomConversionEntity>>;
  toUOMConversion?: Maybe<Array<UomConversionEntity>>;
};

export type UomInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  name: Scalars['String'];
  code?: Maybe<Scalars['String']>;
  ExcelFileName?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

/** JsonType */
export type UnitPriceInfo = {
  __typename?: 'UnitPriceInfo';
  totalAmount: Scalars['Float'];
  totalBaseAmount: Scalars['Float'];
  totalTax: Scalars['Float'];
  totalServiceCharge: Scalars['Float'];
};

export type UomConversionEntity = AuditEntity & {
  __typename?: 'UomConversionEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  fromAmt: Scalars['Float'];
  toAmt: Scalars['Float'];
  conversionRate: Scalars['Float'];
  uomLog?: Maybe<Array<Scalars['JSON']>>;
  fromUomID: Scalars['String'];
  fromUOM?: Maybe<UomEntity>;
  toUomID: Scalars['String'];
  toUOM?: Maybe<UomEntity>;
};

export type UomConversionInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  fromUomID?: Maybe<Scalars['String']>;
  toUomID?: Maybe<Scalars['String']>;
  fromAmt?: Maybe<Scalars['Float']>;
  toAmt?: Maybe<Scalars['Float']>;
  conversionRate?: Maybe<Scalars['Float']>;
  uomLog?: Maybe<Array<ConversionLogInput>>;
};

export type UpdateAuditInput = {
  modBy?: Maybe<Scalars['String']>;
};


export type UserEntity = AuditEntity & {
  __typename?: 'UserEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  userName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  contactNo: Scalars['String'];
  is2FA?: Maybe<Scalars['Boolean']>;
  commonStatus: CommonStatus;
  confirmed: Scalars['Boolean'];
  avatar?: Maybe<Scalars['String']>;
  superUser: Scalars['Boolean'];
  accountID: Scalars['String'];
  softwareCode: Scalars['String'];
  loginAttempt: Scalars['Float'];
  blockDuration: Scalars['Float'];
  blockCount: Scalars['Float'];
  loginTs?: Maybe<Scalars['DateTime']>;
  deviceIP?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  address?: Maybe<Address>;
  department?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  lastestAccessedEntity?: Maybe<Scalars['String']>;
};

export type UserInput = {
  ID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  contactNo: Scalars['String'];
  email: Scalars['String'];
  hashed?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  userName?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  superUser?: Maybe<Scalars['Boolean']>;
  department?: Maybe<Scalars['String']>;
  address?: Maybe<AddressInput>;
  commonStatus?: Maybe<CommonStatus>;
};

export type UserOnlineEntity = AuditEntity & {
  __typename?: 'UserOnlineEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  outletID: Scalars['String'];
  userID: Scalars['String'];
  commonStatus: Scalars['String'];
  softwareCode?: Maybe<Scalars['String']>;
  IPAddress?: Maybe<Scalars['String']>;
  lastLogin: Scalars['DateTime'];
  lastLogout: Scalars['DateTime'];
  socketID?: Maybe<Scalars['String']>;
};

export type UserOnlineInput = {
  ID?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
  userID?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['String']>;
  softwareCode?: Maybe<Scalars['String']>;
  IPAddress?: Maybe<Scalars['String']>;
  lastLogin?: Maybe<Scalars['DateTime']>;
  lastLogout?: Maybe<Scalars['DateTime']>;
  socketID?: Maybe<Scalars['String']>;
};

export type UserResponse = AuditEntity & {
  __typename?: 'UserResponse';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  name: Scalars['String'];
  userName: Scalars['String'];
  email: Scalars['String'];
  contactNo: Scalars['String'];
  confirmed: Scalars['Boolean'];
  superUser: Scalars['Boolean'];
  accountID: Scalars['String'];
  commonStatus: CommonStatus;
  password?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  lastestAccessedEntity?: Maybe<Scalars['String']>;
  deviceIP?: Maybe<Scalars['String']>;
  loginTs?: Maybe<Scalars['DateTime']>;
  blockDuration?: Maybe<Scalars['Float']>;
  blockCount?: Maybe<Scalars['Float']>;
  loginAttempt?: Maybe<Scalars['Float']>;
  is2FA?: Maybe<Scalars['Boolean']>;
  address?: Maybe<Address>;
  errors?: Maybe<Array<FieldError>>;
};

export enum UserSelection {
  All = 'ALL',
  Superuser = 'SUPERUSER',
  Nonsuper = 'NONSUPER'
}

export type VoidOrderItemInput = {
  orderItemID: Scalars['String'];
  deductionQuantity: Scalars['Float'];
  reasonCode: Scalars['String'];
  remark?: Maybe<Scalars['String']>;
};

export type ConsolidatedEi = {
  __typename?: 'consolidatedEI';
  ID?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['DateTime']>;
  docNo?: Maybe<Scalars['String']>;
  docType?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  billStatus?: Maybe<Scalars['String']>;
  baseAmount?: Maybe<Scalars['Float']>;
  serviceCharge?: Maybe<Scalars['Float']>;
  taxAmount?: Maybe<Scalars['Float']>;
  docAmount?: Maybe<Scalars['Float']>;
};

export type ConsolidatedEiResponse = {
  __typename?: 'consolidatedEIResponse';
  consolidatedEIAll?: Maybe<Array<ConsolidatedEi>>;
  consolidatedEI?: Maybe<Array<ConsolidatedEi>>;
  consolidatedEICount?: Maybe<Scalars['Float']>;
  totalPages?: Maybe<Scalars['Float']>;
  currentPage?: Maybe<Scalars['Float']>;
  total?: Maybe<ConsolidatedEiTotal>;
};

export type ConsolidatedEiTotal = {
  __typename?: 'consolidatedEITotal';
  baseAmount?: Maybe<Scalars['Float']>;
  serviceCharge?: Maybe<Scalars['Float']>;
  taxAmount?: Maybe<Scalars['Float']>;
  docAmount?: Maybe<Scalars['Float']>;
};

export type EInvoiceClassificationEntity = {
  __typename?: 'eInvoiceClassificationEntity';
  id: Scalars['String'];
  classificationCode?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type EInvoiceMsicEntity = {
  __typename?: 'eInvoiceMsicEntity';
  id: Scalars['String'];
  msicCode?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  categoryReference?: Maybe<Scalars['String']>;
};

export type Item = {
  __typename?: 'item';
  checkoutId?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  qrCodeUrl?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type PasswordInfo = {
  ID?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  confirmedPassword: Scalars['String'];
};

export type RoleUser = {
  __typename?: 'roleUser';
  roleID: Scalars['String'];
  userID: Array<Scalars['String']>;
};

export type TaxDetailEntity = {
  __typename?: 'taxDetailEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  taxId: Scalars['String'];
  tax: TaxEntity;
  effectiveDate: Scalars['DateTime'];
  rate: Scalars['Float'];
  commonStatus: Scalars['String'];
};

export type TaxEntity = {
  __typename?: 'taxEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  schemeTypeId: Scalars['String'];
  schemeType: TaxSchemeTypeEntity;
  code: Scalars['String'];
  description: Scalars['String'];
  classType: Scalars['String'];
  isClaimable: Scalars['Boolean'];
  commonStatus: Scalars['String'];
  taxDetail: Array<TaxDetailEntity>;
  taxSchemeDetailItem: Array<TaxSchemeDetailItemEntity>;
};

export type TaxOnSeqInput = {
  taxSchemeDetailItemId: Scalars['String'];
  seqNo: Scalars['Float'];
};

/** JsonType */
export type TaxOnSeqObjects = {
  __typename?: 'taxOnSeqObjects';
  taxSchemeDetailItemId: Scalars['String'];
  seqNo: Scalars['Float'];
};

export type TaxSchemeDetailEntity = {
  __typename?: 'taxSchemeDetailEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  taxSchemeId: Scalars['String'];
  taxScheme: TaxSchemeEntity;
  effectiveDate: Scalars['DateTime'];
  commonStatus: Scalars['String'];
  taxSchemeDetailItems?: Maybe<Array<TaxSchemeDetailItemEntity>>;
};

export type TaxSchemeDetailItemEntity = {
  __typename?: 'taxSchemeDetailItemEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  taxSchemeDetailId: Scalars['String'];
  taxSchemeDetail: TaxSchemeDetailEntity;
  taxId: Scalars['String'];
  tax: TaxEntity;
  computeMethod: Scalars['String'];
  seqNo?: Maybe<Scalars['Float']>;
  taxOnSeq?: Maybe<Array<TaxOnSeqObjects>>;
  commonStatus: Scalars['String'];
};

/** JsonType */
export type TaxSchemeDetailItemsInfo = {
  __typename?: 'taxSchemeDetailItemsInfo';
  id: Scalars['String'];
  accountId: Scalars['String'];
  taxSchemeDetailId: Scalars['String'];
  computeMethod: Scalars['String'];
  taxId: Scalars['String'];
  seqNo: Scalars['Float'];
  commonStatus: Scalars['String'];
};

export type TaxSchemeEntity = {
  __typename?: 'taxSchemeEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  isExclusive?: Maybe<Scalars['Boolean']>;
  commonStatus: Scalars['String'];
  taxSchemeDetails?: Maybe<Array<TaxSchemeDetailEntity>>;
};

export type TaxSchemeTypeEntity = {
  __typename?: 'taxSchemeTypeEntity';
  id: Scalars['String'];
  code: Scalars['String'];
  name: Scalars['String'];
  softwareCode: Scalars['String'];
  commonStatus: Scalars['String'];
  tax?: Maybe<Array<TaxEntity>>;
};

export type TransferAxLogEntity = AuditEntity & {
  __typename?: 'transferAXLogEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  requestBody: Scalars['String'];
  responseBody: Scalars['String'];
  logData: Scalars['String'];
  actionType: Scalars['String'];
  outletID?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['String']>;
};

export type UserRoleId = {
  __typename?: 'userRoleID';
  roleID: Scalars['String'];
  userID: Scalars['String'];
};

export type LoggedInUserProfileQueryVariables = Exact<{ [key: string]: never; }>;


export type LoggedInUserProfileQuery = (
  { __typename?: 'Query' }
  & { loggedInUserProfile: (
    { __typename?: 'UserEntity' }
    & Pick<UserEntity, 'ID' | 'accountID' | 'name' | 'email' | 'contactNo' | 'department' | 'superUser' | 'jobTitle' | 'lastestAccessedEntity'>
  ) }
);

export type LoginMutationVariables = Exact<{
  loginID: Scalars['String'];
  password: Scalars['String'];
}>;


export type LoginMutation = (
  { __typename?: 'Mutation' }
  & { login: (
    { __typename?: 'LoginResponse' }
    & Pick<LoginResponse, 'accessToken'>
    & { user: (
      { __typename?: 'UserResponse' }
      & Pick<UserResponse, 'ID' | 'name' | 'userName' | 'email' | 'contactNo' | 'department' | 'jobTitle' | 'commonStatus' | 'confirmed' | 'avatar' | 'superUser' | 'accountID' | 'lastestAccessedEntity'>
    ) }
  ) }
);

export type ChangePasswordMutationVariables = Exact<{
  newPassword: Scalars['String'];
  currentPassword: Scalars['String'];
}>;


export type ChangePasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'changePassword'>
);

export type ForgotPasswordMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type ForgotPasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'forgotPassword'>
);

export type ResetPasswordMutationVariables = Exact<{
  password: Scalars['String'];
  token: Scalars['String'];
}>;


export type ResetPasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'resetPassword'>
);

export type LogoutMutationVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
}>;


export type LogoutMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'logout'>
);

export type ActivateUserMutationVariables = Exact<{
  token: Scalars['String'];
}>;


export type ActivateUserMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'activateUser'>
);

export type CreatePasswordMutationVariables = Exact<{
  input: PasswordInfo;
}>;


export type CreatePasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createPassword'>
);

export type UploadProfilePicMutationVariables = Exact<{
  file: Scalars['Upload'];
  userID: Scalars['String'];
}>;


export type UploadProfilePicMutation = (
  { __typename?: 'Mutation' }
  & { uploadProfilePic: (
    { __typename?: 'AttachmentEntity' }
    & Pick<AttachmentEntity, 'refID' | 'fileName' | 'fileURL' | 'mediaType'>
  ) }
);

export type UnblockUserMutationVariables = Exact<{
  input: PasswordInfo;
}>;


export type UnblockUserMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'unblockUser'>
);

export type ChangePasswordSuMutationVariables = Exact<{
  input: PasswordInfo;
}>;


export type ChangePasswordSuMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'unblockUser'>
);

export type GetUserLogoQueryVariables = Exact<{
  refID?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
}>;


export type GetUserLogoQuery = (
  { __typename?: 'Query' }
  & { logoView: (
    { __typename?: 'AttachmentEntity' }
    & Pick<AttachmentEntity, 'fileName' | 'fileURL'>
  ) }
);

export type GetHomeSummaryQueryVariables = Exact<{ [key: string]: never; }>;


export type GetHomeSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getRolePermission' | 'getPOSPermission'>
  & { loggedInUserProfile: (
    { __typename?: 'UserEntity' }
    & Pick<UserEntity, 'ID' | 'accountID' | 'name' | 'email' | 'contactNo' | 'userName' | 'department' | 'superUser' | 'jobTitle' | 'lastestAccessedEntity'>
  ) }
);

export type GetKitchenOrderQueryVariables = Exact<{
  outletID: Scalars['String'];
  kitchenPrinterID: Scalars['String'];
}>;


export type GetKitchenOrderQuery = (
  { __typename?: 'Query' }
  & { getKitchenOrder: Array<(
    { __typename?: 'KitchenOrder' }
    & Pick<KitchenOrder, 'ID' | 'docNo' | 'createdTs'>
    & { table?: Maybe<(
      { __typename?: 'TableEntity' }
      & Pick<TableEntity, 'prefix' | 'tableNumber'>
    )>, kitchenOrderItems?: Maybe<Array<(
      { __typename?: 'OrderItemEntity' }
      & Pick<OrderItemEntity, 'modTs' | 'ID' | 'refID' | 'kitchenStatus' | 'openItemName' | 'quantity' | 'weight' | 'specialRequest' | 'isTakeaway' | 'status'>
      & { menuItem?: Maybe<(
        { __typename?: 'MenuItemEntity' }
        & Pick<MenuItemEntity, 'name' | 'attachments'>
      )>, orderItemOption?: Maybe<Array<(
        { __typename?: 'OrderItemOptionEntity' }
        & { orderItemOptionItem?: Maybe<Array<(
          { __typename?: 'OrderItemOptionItemEntity' }
          & { optionItem?: Maybe<(
            { __typename?: 'OptionItemEntity' }
            & Pick<OptionItemEntity, 'name'>
          )> }
        )>> }
      )>>, orderItemSetMenuOption?: Maybe<Array<(
        { __typename?: 'OrderItemSetMenuOptionEntity' }
        & { orderItemSetMenuOptionItem?: Maybe<Array<(
          { __typename?: 'OrderItemSetMenuOptionItemEntity' }
          & { setMenuOptionItem?: Maybe<(
            { __typename?: 'SetMenuOptionItemEntity' }
            & { menuItem?: Maybe<(
              { __typename?: 'MenuItemEntity' }
              & Pick<MenuItemEntity, 'name'>
            )> }
          )> }
        )>> }
      )>> }
    )>> }
  )> }
);

export type GetAccountJournalSummaryQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAccountJournalSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'bankAccountDate' | 'cbTransactionDate' | 'glJournalDate'>
);

export type GetBankAccountQueryVariables = Exact<{
  outletID?: Maybe<Scalars['String']>;
}>;


export type GetBankAccountQuery = (
  { __typename?: 'Query' }
  & { getBankAccount: Array<(
    { __typename?: 'BankAccountEntity' }
    & Pick<BankAccountEntity, 'ID' | 'bankName' | 'accountName' | 'accountNo' | 'SWIFTCode' | 'branch' | 'contactNo' | 'address' | 'isDefault' | 'outletID' | 'bankCode'>
    & { bankAccountContact?: Maybe<Array<(
      { __typename?: 'BankAccountContactEntity' }
      & Pick<BankAccountContactEntity, 'ID' | 'name' | 'designation' | 'mobileNo' | 'email'>
    )>> }
  )> }
);

export type CreateBankAccountMutationVariables = Exact<{
  bankAccountInput: BankAccountInput;
  contactInput: Array<BankAccountContactInput> | BankAccountContactInput;
  outletID: Scalars['String'];
}>;


export type CreateBankAccountMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createBankAccount'>
);

export type UpdateBankAccountMutationVariables = Exact<{
  bankAccountInput: BankAccountInput;
  contactInput: Array<BankAccountContactInput> | BankAccountContactInput;
  outletID: Scalars['String'];
}>;


export type UpdateBankAccountMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateBankAccount'>
);

export type DeleteBankAccountMutationVariables = Exact<{
  ID: Scalars['String'];
}>;


export type DeleteBankAccountMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteBankAccount'>
);

export type DeleteBankAccountContactMutationVariables = Exact<{
  ID: Scalars['String'];
}>;


export type DeleteBankAccountContactMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteBankAccountContact'>
);

export type GetCbExportDataQueryVariables = Exact<{
  accountID: Scalars['String'];
  outletID: Scalars['String'];
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
}>;


export type GetCbExportDataQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getCBExportData'>
);

export type GetExportedCbQueryVariables = Exact<{
  outletID: Scalars['String'];
}>;


export type GetExportedCbQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getExportedCB'>
);

export type GetExportedCbDetailsQueryVariables = Exact<{
  batchNo: Scalars['Float'];
  outletID: Scalars['String'];
}>;


export type GetExportedCbDetailsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getExportedCBDetails'>
);

export type GetExportedCbDetailsByTrxTypeQueryVariables = Exact<{
  trxDate: Scalars['DateTime'];
  trxType: Scalars['String'];
  outletID: Scalars['String'];
  accountID: Scalars['String'];
}>;


export type GetExportedCbDetailsByTrxTypeQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getExportedCBDetailsByTrxType'>
);

export type PostCbExportMutationVariables = Exact<{
  cbDate: Scalars['DateTime'];
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  outletID: Scalars['String'];
  accountID: Scalars['String'];
  isTransfer?: Maybe<Scalars['Boolean']>;
}>;


export type PostCbExportMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'postCBExport'>
);

export type CheckBanknameInUseQueryVariables = Exact<{
  bankAccountID: Scalars['String'];
}>;


export type CheckBanknameInUseQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'CheckBanknameInUse'>
);

export type GetUsersByAccountQueryVariables = Exact<{
  accountID: Scalars['String'];
}>;


export type GetUsersByAccountQuery = (
  { __typename?: 'Query' }
  & { getUsersByAccount: Array<(
    { __typename?: 'UserEntity' }
    & Pick<UserEntity, 'ID' | 'name' | 'accountID' | 'userName' | 'email'>
  )> }
);

export type MenuAccountXListQueryVariables = Exact<{
  outletID: Scalars['String'];
}>;


export type MenuAccountXListQuery = (
  { __typename?: 'Query' }
  & { MenuAccountXList?: Maybe<(
    { __typename?: 'MenuVendorEntity' }
    & Pick<MenuVendorEntity, 'ID' | 'outletID' | 'vendorName' | 'isActive'>
  )> }
);

export type GetGlExportQueryVariables = Exact<{
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  outletID: Scalars['String'];
  accountID: Scalars['String'];
}>;


export type GetGlExportQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getGLExport'>
);

export type GetGlListingQueryVariables = Exact<{
  outletID: Scalars['String'];
}>;


export type GetGlListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getExportedGLListing'>
);

export type GetGlExportedDetailsQueryVariables = Exact<{
  batchNo: Scalars['Float'];
  outletID: Scalars['String'];
}>;


export type GetGlExportedDetailsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getExportedGLDetails'>
);

export type GetGlExportDetailsByTrxTypeQueryVariables = Exact<{
  trxDate: Scalars['DateTime'];
  trxDesc: Scalars['String'];
  trxType: Scalars['String'];
  outletID: Scalars['String'];
  accountID: Scalars['String'];
}>;


export type GetGlExportDetailsByTrxTypeQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getGLExportDetailsByTrxType'>
);

export type ExportedGlTransferDetailsQueryVariables = Exact<{
  outletID: Scalars['String'];
  accountID: Scalars['String'];
  batchNo: Scalars['Float'];
}>;


export type ExportedGlTransferDetailsQuery = (
  { __typename?: 'Query' }
  & { ExportedGLTransferDetails: Array<(
    { __typename?: 'ExportedGLTransferResponse' }
    & Pick<ExportedGlTransferResponse, 'AccountID' | 'CompanyName' | 'JournalTypeName' | 'JournalDate' | 'DocNo' | 'Description' | 'AccountName' | 'DepartmentCode' | 'MasterCOACode' | 'Amount' | 'Remark'>
  )> }
);

export type PostGlExportMutationVariables = Exact<{
  glDate: Scalars['DateTime'];
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  outletID: Scalars['String'];
  accountID: Scalars['String'];
  isTransfer?: Maybe<Scalars['Boolean']>;
}>;


export type PostGlExportMutation = (
  { __typename?: 'Mutation' }
  & { postGLExport: (
    { __typename?: 'GLPostResponse' }
    & Pick<GlPostResponse, 'GLPost'>
    & { ExportedGLTransfer?: Maybe<Array<(
      { __typename?: 'ExportedGLTransferResponse' }
      & Pick<ExportedGlTransferResponse, 'AccountID' | 'CompanyName' | 'JournalTypeName' | 'JournalDate' | 'DocNo' | 'Description' | 'AccountName' | 'DepartmentCode' | 'MasterCOACode' | 'Amount' | 'Remark'>
    )>> }
  ) }
);

export type GetOutletBillChargesQueryVariables = Exact<{
  outletID: Scalars['String'];
}>;


export type GetOutletBillChargesQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getOutletBillCharges'>
);

export type GetOutletBillOcChargesQueryVariables = Exact<{
  outletID: Scalars['String'];
}>;


export type GetOutletBillOcChargesQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getOutletBillOCCharges'>
);

export type GetOutletBillEntChargesQueryVariables = Exact<{
  outletID: Scalars['String'];
}>;


export type GetOutletBillEntChargesQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getOutletBillEntCharges'>
);

export type SubmitOutletGlInterfaceMutationVariables = Exact<{
  outletID: Scalars['String'];
  glInput: AccountMappingGlInput;
}>;


export type SubmitOutletGlInterfaceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'submitOutletGLInterface'>
);

export type AccountMappingTaxGlListQueryVariables = Exact<{
  outletID: Scalars['String'];
  accountID: Scalars['String'];
}>;


export type AccountMappingTaxGlListQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'AccountMappingTaxGLList'>
);

export type AccountMappingTaxGlInsertMutationVariables = Exact<{
  outletID: Scalars['String'];
  accountID: Scalars['String'];
  TaxGLInput: AccountMappingTaxGlInput;
}>;


export type AccountMappingTaxGlInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'AccountMappingTaxGLInsert'>
);

export type GetBillListingQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
}>;


export type GetBillListingQuery = (
  { __typename?: 'Query' }
  & { getBill: Array<(
    { __typename?: 'BillEntity' }
    & Pick<BillEntity, 'ID' | 'outletID' | 'createdTs' | 'billNo' | 'signature' | 'billPaymentType' | 'businessDate' | 'orderID' | 'status' | 'einvoice_qr_url' | 'is_einvoice' | 'einvoice_irbm_uniqueID' | 'einvoice_status' | 'qrCodeUrl' | 'documentType' | 'refID' | 'refBillNo' | 'isCreditNote'>
    & { order?: Maybe<(
      { __typename?: 'OrderEntity' }
      & Pick<OrderEntity, 'ID' | 'taxSchemeInfo' | 'taxSchemeFooterInfo' | 'docNo' | 'patronAccountID' | 'patronName' | 'pax' | 'registrationID' | 'patronID' | 'totalAmount' | 'outstandingAmount' | 'serviceMode' | 'totalDiscountAmount'>
      & { orderDiscount?: Maybe<Array<(
        { __typename?: 'OrderDiscountEntity' }
        & Pick<OrderDiscountEntity, 'ID' | 'amount' | 'status' | 'reasonCode' | 'remark'>
      )>>, table?: Maybe<(
        { __typename?: 'TableEntity' }
        & Pick<TableEntity, 'ID' | 'tableNumber' | 'prefix'>
      )>, patron?: Maybe<(
        { __typename?: 'PatronEntity' }
        & Pick<PatronEntity, 'patronClass'>
        & { patronType?: Maybe<(
          { __typename?: 'PatronTypeEntity' }
          & Pick<PatronTypeEntity, 'name'>
        )> }
      )> }
    )>, payments?: Maybe<Array<(
      { __typename?: 'PaymentEntity' }
      & Pick<PaymentEntity, 'ID' | 'amount' | 'registrationID' | 'receivedAmount' | 'changeAmount' | 'roundingAmount' | 'businessDate' | 'cardNo' | 'reasonCode' | 'creditCardType' | 'referenceNo' | 'paymentClass' | 'patronAccountID' | 'hotelGuestInfo' | 'status' | 'remark' | 'createdTs' | 'modBy'>
      & { defaultPayment?: Maybe<(
        { __typename?: 'DefaultPaymentEntity' }
        & Pick<DefaultPaymentEntity, 'ID' | 'paymentClass' | 'paymentTypeInfo'>
      )> }
    )>>, orderItem?: Maybe<Array<(
      { __typename?: 'OrderItemEntity' }
      & Pick<OrderItemEntity, 'ID' | 'billID' | 'refID' | 'unitPrice' | 'amount' | 'serviceCharge' | 'baseAmount' | 'taxAmount' | 'openItemName' | 'totalInfo' | 'unitPriceInfo' | 'quantity' | 'status'>
      & { orderItemTax?: Maybe<Array<(
        { __typename?: 'OrderItemTaxEntity' }
        & Pick<OrderItemTaxEntity, 'ID' | 'orderItemID' | 'taxSchemeID' | 'taxSchemeDetailID' | 'taxID' | 'taxRate' | 'taxAmount' | 'taxSchemeType'>
      )>>, orderItemDiscountTax?: Maybe<Array<(
        { __typename?: 'OrderItemDiscountTaxEntity' }
        & Pick<OrderItemDiscountTaxEntity, 'ID' | 'orderItemID' | 'orderItemDiscountID' | 'taxSchemeID' | 'taxSchemeDetailID' | 'taxID' | 'taxRate' | 'taxAmount'>
      )>>, orderDiscountItemTax?: Maybe<Array<(
        { __typename?: 'OrderDiscountItemTaxV1Entity' }
        & Pick<OrderDiscountItemTaxV1Entity, 'ID' | 'orderItemID' | 'orderDiscountItemID' | 'taxSchemeID' | 'taxSchemeDetailID' | 'taxID' | 'taxRate' | 'taxAmount'>
      )>>, orderItemDiscount?: Maybe<Array<(
        { __typename?: 'OrderItemDiscountEntity' }
        & Pick<OrderItemDiscountEntity, 'orderItemID' | 'amount' | 'baseAmount' | 'serviceCharge' | 'taxAmount' | 'status' | 'reasonCode' | 'remark'>
        & { orderItemDiscountTax?: Maybe<Array<(
          { __typename?: 'OrderItemDiscountTaxEntity' }
          & Pick<OrderItemDiscountTaxEntity, 'ID' | 'orderItemID' | 'orderItemDiscountID' | 'taxSchemeID' | 'taxSchemeDetailID' | 'taxID' | 'taxRate' | 'taxAmount'>
        )>> }
      )>>, orderDiscountItem?: Maybe<(
        { __typename?: 'OrderDiscountItemEntity' }
        & Pick<OrderDiscountItemEntity, 'orderItemID' | 'amount' | 'baseAmount' | 'serviceCharge' | 'taxAmount'>
        & { orderDiscountItemTax?: Maybe<Array<(
          { __typename?: 'OrderDiscountItemTaxV1Entity' }
          & Pick<OrderDiscountItemTaxV1Entity, 'ID' | 'orderItemID' | 'orderDiscountItemID' | 'taxSchemeID' | 'taxSchemeDetailID' | 'taxID' | 'taxRate' | 'taxAmount'>
        )>> }
      )>, menuItem?: Maybe<(
        { __typename?: 'MenuItemEntity' }
        & Pick<MenuItemEntity, 'ID' | 'name'>
      )>, orderItemOption?: Maybe<Array<(
        { __typename?: 'OrderItemOptionEntity' }
        & Pick<OrderItemOptionEntity, 'ID' | 'name' | 'orderItemID' | 'optionID'>
        & { orderItemOptionItem?: Maybe<Array<(
          { __typename?: 'OrderItemOptionItemEntity' }
          & Pick<OrderItemOptionItemEntity, 'ID' | 'price' | 'unitPrice' | 'basePrice' | 'taxAmount' | 'serviceCharge' | 'discountBaseAmount' | 'discountServiceCharge' | 'discountTaxAmount'>
          & { orderItemOptionItemTax?: Maybe<Array<(
            { __typename?: 'OrderItemOptionItemTaxEntity' }
            & Pick<OrderItemOptionItemTaxEntity, 'ID' | 'orderItemID' | 'orderItemOptionItemID' | 'taxSchemeID' | 'taxSchemeDetailID' | 'taxID' | 'taxRate' | 'taxAmount'>
          )>>, orderItemOptionItemDiscountTax?: Maybe<Array<(
            { __typename?: 'OrderItemOptionItemDiscountTaxEntity' }
            & Pick<OrderItemOptionItemDiscountTaxEntity, 'ID' | 'orderItemOptionItemID' | 'orderItemDiscountID' | 'taxSchemeID' | 'taxSchemeDetailID' | 'taxID' | 'taxRate' | 'taxAmount'>
          )>>, orderDiscountItemOptionTax?: Maybe<Array<(
            { __typename?: 'OrderDiscountItemOptionTaxEntity' }
            & Pick<OrderDiscountItemOptionTaxEntity, 'ID' | 'orderItemOptionItemID' | 'orderDiscountItemID' | 'taxSchemeID' | 'taxSchemeDetailID' | 'taxID' | 'taxRate' | 'taxAmount'>
          )>>, optionItem?: Maybe<(
            { __typename?: 'OptionItemEntity' }
            & Pick<OptionItemEntity, 'name'>
          )> }
        )>> }
      )>>, orderItemSetMenuOption?: Maybe<Array<(
        { __typename?: 'OrderItemSetMenuOptionEntity' }
        & Pick<OrderItemSetMenuOptionEntity, 'ID'>
        & { orderItemSetMenuOptionItem?: Maybe<Array<(
          { __typename?: 'OrderItemSetMenuOptionItemEntity' }
          & { menuItem?: Maybe<(
            { __typename?: 'MenuItemEntity' }
            & Pick<MenuItemEntity, 'name'>
          )>, setMenuOptionItem?: Maybe<(
            { __typename?: 'SetMenuOptionItemEntity' }
            & { menuItem?: Maybe<(
              { __typename?: 'MenuItemEntity' }
              & Pick<MenuItemEntity, 'name'>
            )> }
          )> }
        )>> }
      )>> }
    )>> }
  )> }
);

export type GetCnBillOrderItemQueryVariables = Exact<{
  outletID: Scalars['String'];
  ogBillID: Scalars['String'];
}>;


export type GetCnBillOrderItemQuery = (
  { __typename?: 'Query' }
  & { getCNBillOrderItem?: Maybe<Array<(
    { __typename?: 'OrderItemEntity' }
    & Pick<OrderItemEntity, 'ID' | 'amount' | 'refID' | 'serviceCharge' | 'taxAmount' | 'unitPrice' | 'isTakeaway' | 'weight' | 'unitPriceInfo' | 'totalInfo' | 'openItemName' | 'quantity' | 'baseAmount' | 'status'>
    & { orderItemTax?: Maybe<Array<(
      { __typename?: 'OrderItemTaxEntity' }
      & Pick<OrderItemTaxEntity, 'ID' | 'orderItemID' | 'taxSchemeID' | 'taxSchemeDetailID' | 'taxID' | 'taxRate' | 'taxAmount' | 'taxSchemeType'>
    )>>, orderItemDiscountTax?: Maybe<Array<(
      { __typename?: 'OrderItemDiscountTaxEntity' }
      & Pick<OrderItemDiscountTaxEntity, 'ID' | 'orderItemID' | 'orderItemDiscountID' | 'taxSchemeID' | 'taxSchemeDetailID' | 'taxID' | 'taxRate' | 'taxAmount'>
    )>>, orderDiscountItemTax?: Maybe<Array<(
      { __typename?: 'OrderDiscountItemTaxV1Entity' }
      & Pick<OrderDiscountItemTaxV1Entity, 'ID' | 'orderItemID' | 'orderDiscountItemID' | 'taxSchemeID' | 'taxSchemeDetailID' | 'taxID' | 'taxRate' | 'taxAmount'>
    )>>, orderItemDiscount?: Maybe<Array<(
      { __typename?: 'OrderItemDiscountEntity' }
      & Pick<OrderItemDiscountEntity, 'discountID' | 'orderItemID' | 'amount' | 'baseAmount' | 'serviceCharge' | 'taxAmount' | 'status' | 'reasonCode' | 'remark'>
      & { orderItemDiscountTax?: Maybe<Array<(
        { __typename?: 'OrderItemDiscountTaxEntity' }
        & Pick<OrderItemDiscountTaxEntity, 'ID' | 'orderItemID' | 'orderItemDiscountID' | 'taxSchemeID' | 'taxSchemeDetailID' | 'taxID' | 'taxRate' | 'taxAmount'>
      )>> }
    )>>, orderDiscountItem?: Maybe<(
      { __typename?: 'OrderDiscountItemEntity' }
      & Pick<OrderDiscountItemEntity, 'orderItemID' | 'amount' | 'baseAmount' | 'serviceCharge' | 'taxAmount'>
      & { orderDiscountItemTax?: Maybe<Array<(
        { __typename?: 'OrderDiscountItemTaxV1Entity' }
        & Pick<OrderDiscountItemTaxV1Entity, 'ID' | 'orderItemID' | 'orderDiscountItemID' | 'taxSchemeID' | 'taxSchemeDetailID' | 'taxID' | 'taxRate' | 'taxAmount'>
      )>> }
    )>, menuItem?: Maybe<(
      { __typename?: 'MenuItemEntity' }
      & Pick<MenuItemEntity, 'ID' | 'name'>
    )>, orderItemOption?: Maybe<Array<(
      { __typename?: 'OrderItemOptionEntity' }
      & Pick<OrderItemOptionEntity, 'ID' | 'name' | 'optionID' | 'orderItemID'>
      & { orderItemOptionItem?: Maybe<Array<(
        { __typename?: 'OrderItemOptionItemEntity' }
        & Pick<OrderItemOptionItemEntity, 'ID' | 'optionItemID' | 'price' | 'basePrice' | 'unitPrice' | 'taxAmount' | 'serviceCharge' | 'discountBaseAmount' | 'discountServiceCharge' | 'discountTaxAmount'>
        & { orderItemOptionItemTax?: Maybe<Array<(
          { __typename?: 'OrderItemOptionItemTaxEntity' }
          & Pick<OrderItemOptionItemTaxEntity, 'ID' | 'orderItemID' | 'orderItemOptionItemID' | 'taxSchemeID' | 'taxSchemeDetailID' | 'taxID' | 'taxRate' | 'taxAmount'>
        )>>, orderItemOptionItemDiscountTax?: Maybe<Array<(
          { __typename?: 'OrderItemOptionItemDiscountTaxEntity' }
          & Pick<OrderItemOptionItemDiscountTaxEntity, 'ID' | 'orderItemOptionItemID' | 'orderItemDiscountID' | 'taxSchemeID' | 'taxSchemeDetailID' | 'taxID' | 'taxRate' | 'taxAmount'>
        )>>, orderDiscountItemOptionTax?: Maybe<Array<(
          { __typename?: 'OrderDiscountItemOptionTaxEntity' }
          & Pick<OrderDiscountItemOptionTaxEntity, 'ID' | 'orderItemOptionItemID' | 'orderDiscountItemID' | 'taxSchemeID' | 'taxSchemeDetailID' | 'taxID' | 'taxRate' | 'taxAmount'>
        )>>, optionItem?: Maybe<(
          { __typename?: 'OptionItemEntity' }
          & Pick<OptionItemEntity, 'name'>
        )> }
      )>> }
    )>>, orderItemSetMenuOption?: Maybe<Array<(
      { __typename?: 'OrderItemSetMenuOptionEntity' }
      & Pick<OrderItemSetMenuOptionEntity, 'ID'>
      & { orderItemSetMenuOptionItem?: Maybe<Array<(
        { __typename?: 'OrderItemSetMenuOptionItemEntity' }
        & { menuItem?: Maybe<(
          { __typename?: 'MenuItemEntity' }
          & Pick<MenuItemEntity, 'name'>
        )>, setMenuOptionItem?: Maybe<(
          { __typename?: 'SetMenuOptionItemEntity' }
          & { menuItem?: Maybe<(
            { __typename?: 'MenuItemEntity' }
            & Pick<MenuItemEntity, 'name'>
          )> }
        )> }
      )>> }
    )>> }
  )>> }
);

export type VoidPaymentMutationVariables = Exact<{
  paymentInput: Array<PaymentInput> | PaymentInput;
  billInput?: Maybe<BillInput>;
  voidPaymentIDs: Array<Scalars['String']> | Scalars['String'];
  billLedgerInput?: Maybe<BillLedgerInput>;
  scheduleBillingInput?: Maybe<ScheduleBillingInput>;
}>;


export type VoidPaymentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'voidPayment'>
);

export type VoidBillMutationVariables = Exact<{
  paymentInput: PaymentInput;
  billInput?: Maybe<BillInput>;
  billLedgerInput?: Maybe<BillLedgerInput>;
  scheduleBillingInput?: Maybe<ScheduleBillingInput>;
}>;


export type VoidBillMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'voidBill'>
);

export type CreditNoteBillPaymentMutationVariables = Exact<{
  billInput: BillInput;
}>;


export type CreditNoteBillPaymentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'creditNoteBillPayment'>
);

export type GetBillOffsetQueryVariables = Exact<{
  input: BillInputOffset;
  offSet: Scalars['Float'];
  limit: Scalars['Float'];
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  billNo?: Maybe<Scalars['String']>;
  paymentType?: Maybe<Scalars['String']>;
  customerType?: Maybe<Scalars['String']>;
  tabValue: Scalars['Float'];
}>;


export type GetBillOffsetQuery = (
  { __typename?: 'Query' }
  & { getBillInfinite?: Maybe<Array<(
    { __typename?: 'BillEntity' }
    & Pick<BillEntity, 'ID' | 'outletID' | 'createdTs' | 'billNo' | 'signature' | 'billPaymentType' | 'orderID' | 'status' | 'qrCodeUrl' | 'is_einvoice' | 'einvoice_irbm_uniqueID' | 'einvoice_status' | 'refID' | 'refBillNo' | 'documentType' | 'isCreditNote'>
    & { order?: Maybe<(
      { __typename?: 'OrderEntity' }
      & Pick<OrderEntity, 'ID' | 'docNo' | 'patronAccountID' | 'patronName' | 'taxSchemeInfo' | 'taxSchemeFooterInfo' | 'registrationID' | 'patronID' | 'totalAmount' | 'outstandingAmount' | 'serviceMode'>
      & { orderDiscount?: Maybe<Array<(
        { __typename?: 'OrderDiscountEntity' }
        & Pick<OrderDiscountEntity, 'ID' | 'amount' | 'status' | 'reasonCode' | 'remark'>
      )>>, table?: Maybe<(
        { __typename?: 'TableEntity' }
        & Pick<TableEntity, 'ID' | 'tableNumber' | 'prefix'>
      )>, patron?: Maybe<(
        { __typename?: 'PatronEntity' }
        & Pick<PatronEntity, 'patronClass'>
        & { patronType?: Maybe<(
          { __typename?: 'PatronTypeEntity' }
          & Pick<PatronTypeEntity, 'name'>
        )> }
      )> }
    )>, payments?: Maybe<Array<(
      { __typename?: 'PaymentEntity' }
      & Pick<PaymentEntity, 'ID' | 'amount' | 'registrationID' | 'receivedAmount' | 'changeAmount' | 'roundingAmount' | 'businessDate' | 'cardNo' | 'reasonCode' | 'referenceNo' | 'creditCardType' | 'hotelGuestInfo' | 'status' | 'remark' | 'createdTs'>
      & { defaultPayment?: Maybe<(
        { __typename?: 'DefaultPaymentEntity' }
        & Pick<DefaultPaymentEntity, 'ID' | 'paymentClass' | 'paymentTypeInfo'>
      )> }
    )>>, orderItem?: Maybe<Array<(
      { __typename?: 'OrderItemEntity' }
      & Pick<OrderItemEntity, 'ID' | 'billID' | 'refID' | 'unitPrice' | 'amount' | 'serviceCharge' | 'taxAmount' | 'totalInfo' | 'quantity' | 'openItemName'>
      & { menuItem?: Maybe<(
        { __typename?: 'MenuItemEntity' }
        & Pick<MenuItemEntity, 'ID' | 'name'>
      )>, orderItemOption?: Maybe<Array<(
        { __typename?: 'OrderItemOptionEntity' }
        & Pick<OrderItemOptionEntity, 'ID' | 'name' | 'orderItemID'>
        & { orderItemOptionItem?: Maybe<Array<(
          { __typename?: 'OrderItemOptionItemEntity' }
          & Pick<OrderItemOptionItemEntity, 'ID' | 'price' | 'unitPrice'>
          & { optionItem?: Maybe<(
            { __typename?: 'OptionItemEntity' }
            & Pick<OptionItemEntity, 'name'>
          )> }
        )>> }
      )>>, orderItemSetMenuOption?: Maybe<Array<(
        { __typename?: 'OrderItemSetMenuOptionEntity' }
        & Pick<OrderItemSetMenuOptionEntity, 'ID'>
        & { orderItemSetMenuOptionItem?: Maybe<Array<(
          { __typename?: 'OrderItemSetMenuOptionItemEntity' }
          & { menuItem?: Maybe<(
            { __typename?: 'MenuItemEntity' }
            & Pick<MenuItemEntity, 'name'>
          )>, setMenuOptionItem?: Maybe<(
            { __typename?: 'SetMenuOptionItemEntity' }
            & { menuItem?: Maybe<(
              { __typename?: 'MenuItemEntity' }
              & Pick<MenuItemEntity, 'name'>
            )> }
          )> }
        )>> }
      )>> }
    )>> }
  )>> }
);

export type GetBillWithDateQueryVariables = Exact<{
  outletID?: Maybe<Scalars['String']>;
  billNo?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  offSet: Scalars['Float'];
  limit: Scalars['Float'];
}>;


export type GetBillWithDateQuery = (
  { __typename?: 'Query' }
  & { getBillWithDate?: Maybe<Array<(
    { __typename?: 'BillEntity' }
    & Pick<BillEntity, 'ID' | 'outletID' | 'createdTs' | 'businessDate' | 'billNo' | 'signature' | 'orderID' | 'qrCodeUrl' | 'status' | 'is_einvoice' | 'einvoice_irbm_uniqueID' | 'einvoice_status'>
    & { order?: Maybe<(
      { __typename?: 'OrderEntity' }
      & Pick<OrderEntity, 'ID' | 'docNo' | 'patronAccountID' | 'patronName' | 'taxSchemeInfo' | 'taxSchemeFooterInfo' | 'registrationID' | 'patronID' | 'totalAmount' | 'outstandingAmount' | 'serviceMode'>
      & { orderDiscount?: Maybe<Array<(
        { __typename?: 'OrderDiscountEntity' }
        & Pick<OrderDiscountEntity, 'ID' | 'amount' | 'status' | 'reasonCode' | 'remark'>
      )>>, table?: Maybe<(
        { __typename?: 'TableEntity' }
        & Pick<TableEntity, 'ID' | 'tableNumber' | 'prefix'>
      )>, patron?: Maybe<(
        { __typename?: 'PatronEntity' }
        & Pick<PatronEntity, 'patronClass'>
        & { patronType?: Maybe<(
          { __typename?: 'PatronTypeEntity' }
          & Pick<PatronTypeEntity, 'name'>
        )> }
      )> }
    )>, payments?: Maybe<Array<(
      { __typename?: 'PaymentEntity' }
      & Pick<PaymentEntity, 'ID' | 'amount' | 'registrationID' | 'receivedAmount' | 'changeAmount' | 'roundingAmount' | 'businessDate' | 'paymentClass' | 'cardNo' | 'reasonCode' | 'referenceNo' | 'creditCardType' | 'hotelGuestInfo' | 'status' | 'remark' | 'createdTs'>
      & { defaultPayment?: Maybe<(
        { __typename?: 'DefaultPaymentEntity' }
        & Pick<DefaultPaymentEntity, 'ID' | 'paymentClass' | 'paymentTypeInfo'>
      )> }
    )>>, orderItem?: Maybe<Array<(
      { __typename?: 'OrderItemEntity' }
      & Pick<OrderItemEntity, 'ID' | 'billID' | 'refID' | 'unitPrice' | 'amount' | 'serviceCharge' | 'taxAmount' | 'totalInfo' | 'quantity' | 'openItemName'>
      & { menuItem?: Maybe<(
        { __typename?: 'MenuItemEntity' }
        & Pick<MenuItemEntity, 'ID' | 'name'>
      )>, orderItemOption?: Maybe<Array<(
        { __typename?: 'OrderItemOptionEntity' }
        & Pick<OrderItemOptionEntity, 'ID' | 'name' | 'orderItemID'>
        & { orderItemOptionItem?: Maybe<Array<(
          { __typename?: 'OrderItemOptionItemEntity' }
          & Pick<OrderItemOptionItemEntity, 'ID' | 'price' | 'unitPrice'>
          & { optionItem?: Maybe<(
            { __typename?: 'OptionItemEntity' }
            & Pick<OptionItemEntity, 'name'>
          )> }
        )>> }
      )>>, orderItemSetMenuOption?: Maybe<Array<(
        { __typename?: 'OrderItemSetMenuOptionEntity' }
        & Pick<OrderItemSetMenuOptionEntity, 'ID'>
        & { orderItemSetMenuOptionItem?: Maybe<Array<(
          { __typename?: 'OrderItemSetMenuOptionItemEntity' }
          & { menuItem?: Maybe<(
            { __typename?: 'MenuItemEntity' }
            & Pick<MenuItemEntity, 'name'>
          )>, setMenuOptionItem?: Maybe<(
            { __typename?: 'SetMenuOptionItemEntity' }
            & { menuItem?: Maybe<(
              { __typename?: 'MenuItemEntity' }
              & Pick<MenuItemEntity, 'name'>
            )> }
          )> }
        )>> }
      )>> }
    )>> }
  )>> }
);

export type GetBillListingSummaryQueryVariables = Exact<{
  outletID: Scalars['String'];
}>;


export type GetBillListingSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'billByDateCount'>
);

export type GetAverageMealTimeAnalysisQueryVariables = Exact<{
  outletID: Scalars['String'];
}>;


export type GetAverageMealTimeAnalysisQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'averageMealTimeAnalysis'>
);

export type GetDiscountTypeAnalysisQueryVariables = Exact<{
  outletID: Scalars['String'];
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
}>;


export type GetDiscountTypeAnalysisQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'discountTypeAnalysis'>
);

export type FastMovingMenuItemAnalysisQueryVariables = Exact<{
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  outletID?: Maybe<Scalars['String']>;
  majorID?: Maybe<Scalars['String']>;
  familyID?: Maybe<Scalars['String']>;
  mealPeriodID?: Maybe<Scalars['String']>;
}>;


export type FastMovingMenuItemAnalysisQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'fastMovingMenuItemAnalysis'>
);

export type MealPeriodSalesAnalysisQueryVariables = Exact<{
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  outletID: Scalars['String'];
}>;


export type MealPeriodSalesAnalysisQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'mealPeriodSalesAnalysis'>
);

export type GetOutletSalesCollectionAnalysisQueryVariables = Exact<{
  endDate: Scalars['DateTime'];
  startDate: Scalars['DateTime'];
  outletID: Scalars['String'];
  frequencyType?: Maybe<Scalars['String']>;
}>;


export type GetOutletSalesCollectionAnalysisQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'outletSalesCollectionAnalysis'>
);

export type GetPatronTypeAnalysisQueryVariables = Exact<{
  outletID: Scalars['String'];
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
}>;


export type GetPatronTypeAnalysisQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'patronTypeAnalysis'>
);

export type GetSlowMovingMenuItemAnalysisQueryVariables = Exact<{
  endDate: Scalars['DateTime'];
  startDate: Scalars['DateTime'];
  outletID?: Maybe<Scalars['String']>;
  mealPeriodID?: Maybe<Scalars['String']>;
  familyID?: Maybe<Scalars['String']>;
  majorID?: Maybe<Scalars['String']>;
}>;


export type GetSlowMovingMenuItemAnalysisQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'slowMovingMenuItemAnalysis'>
);

export type GetWaiterQueryVariables = Exact<{
  outletID: Scalars['String'];
}>;


export type GetWaiterQuery = (
  { __typename?: 'Query' }
  & { getUsersWaiterRole: Array<(
    { __typename?: 'UserEntity' }
    & Pick<UserEntity, 'ID' | 'name'>
  )> }
);

export type GetWaiterSalesCollectionAnalysisQueryVariables = Exact<{
  outletID?: Maybe<Scalars['String']>;
  userID?: Maybe<Scalars['String']>;
  salesAmount?: Maybe<Scalars['Float']>;
  selectedDate?: Maybe<Scalars['DateTime']>;
}>;


export type GetWaiterSalesCollectionAnalysisQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'waiterSalesCollectionAnalysis'>
);

export type GetShareAttachmentMutationVariables = Exact<{
  file: Scalars['Upload'];
  outletID: Scalars['String'];
}>;


export type GetShareAttachmentMutation = (
  { __typename?: 'Mutation' }
  & { shareAttachment?: Maybe<(
    { __typename?: 'ShareAttachmentEntity' }
    & Pick<ShareAttachmentEntity, 'ID' | 'bucketFileName' | 'fileName' | 'fileURL'>
  )> }
);

export type GetBusinessAnalyticsSummaryQueryVariables = Exact<{ [key: string]: never; }>;


export type GetBusinessAnalyticsSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'outletSalesDate' | 'mealPeriodDate' | 'waiterSalesDate' | 'fastMovingMenuItemDate' | 'slowMovingMenuItemDate' | 'patronTypeDate' | 'discountTypeDate' | 'averageMealTimeDate'>
);

export type GetBizInsightOutletQueryVariables = Exact<{ [key: string]: never; }>;


export type GetBizInsightOutletQuery = (
  { __typename?: 'Query' }
  & { getOutlet: Array<(
    { __typename?: 'OutletEntity' }
    & Pick<OutletEntity, 'ID' | 'name'>
  )> }
);

export type GetCompanyListingQueryVariables = Exact<{
  accountID: Scalars['String'];
  recordStatus?: Maybe<Scalars['String']>;
}>;


export type GetCompanyListingQuery = (
  { __typename?: 'Query' }
  & { getCompanyListing: Array<(
    { __typename?: 'CompanyEntity' }
    & Pick<CompanyEntity, 'id' | 'parentCompanyId' | 'accountId' | 'baseCurrencyId' | 'name' | 'description' | 'companyRegNo' | 'code' | 'contactNo' | 'email' | 'sstNo' | 'gstNo' | 'ttxNo' | 'gstExpiryDate' | 'image' | 'imageUrl' | 'imageBucketFile' | 'websiteUrl' | 'eInvClientID' | 'eInvClientSecret' | 'eInvClientSecret2' | 'regionId' | 'imageLogoPosition' | 'watermarkImplementation' | 'watermarkWording' | 'isoImplementation' | 'isoLogoPosition' | 'companyTax' | 'recordStatus' | 'groupGstRegNo' | 'officeTel' | 'tin' | 'eSign' | 'eSignExpiryDate' | 'isEInvIntegrated' | 'eInvStartDate' | 'eInvEndDate'>
    & { address?: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'address' | 'city' | 'state' | 'country' | 'postCode'>
    )> }
  )> }
);

export type GetCompanyListingByIdQueryVariables = Exact<{
  accountID: Scalars['String'];
  ID?: Maybe<Scalars['String']>;
}>;


export type GetCompanyListingByIdQuery = (
  { __typename?: 'Query' }
  & { getCompanyListingByID: Array<(
    { __typename?: 'CompanyEntity' }
    & Pick<CompanyEntity, 'id' | 'parentCompanyId' | 'accountId' | 'baseCurrencyId' | 'name' | 'description' | 'companyRegNo' | 'code' | 'contactNo' | 'email' | 'sstNo' | 'gstNo' | 'ttxNo' | 'gstExpiryDate' | 'image' | 'imageUrl' | 'imageBucketFile' | 'websiteUrl' | 'regionId' | 'imageLogoPosition' | 'watermarkImplementation' | 'watermarkWording' | 'isoImplementation' | 'isoLogoPosition' | 'companyTax' | 'recordStatus' | 'groupGstRegNo' | 'officeTel' | 'tin' | 'eSign' | 'eSignExpiryDate'>
    & { address?: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'address' | 'city' | 'state' | 'country' | 'postCode'>
    )> }
  )> }
);

export type CreateCompanyMutationVariables = Exact<{
  companyInput: CompanyInput;
}>;


export type CreateCompanyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createCompany'>
);

export type UpdateCompanyMutationVariables = Exact<{
  companyInput: CompanyInput;
}>;


export type UpdateCompanyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateCompany'>
);

export type CompanyUpdateStatusMutationVariables = Exact<{
  status: Scalars['String'];
  companyID: Scalars['String'];
}>;


export type CompanyUpdateStatusMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'companyUpdateStatus'>
);

export type CurrencyListingQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrencyListingQuery = (
  { __typename?: 'Query' }
  & { CurrencyListing: Array<(
    { __typename?: 'CurrencyEntity' }
    & Pick<CurrencyEntity, 'id' | 'code' | 'name' | 'symbol' | 'format' | 'common_status'>
  )> }
);

export type GetEInvoiceSubQueryVariables = Exact<{
  accountID: Scalars['String'];
}>;


export type GetEInvoiceSubQuery = (
  { __typename?: 'Query' }
  & { getEInvoiceSub: Array<(
    { __typename?: 'EInvoiceSubscriptionEntity' }
    & Pick<EInvoiceSubscriptionEntity, 'ID' | 'isActive' | 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'accountID'>
  )> }
);

export type EInvoiceInformationQueryVariables = Exact<{
  outletID: Scalars['String'];
  ID: Scalars['String'];
  type: Scalars['String'];
}>;


export type EInvoiceInformationQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'EInvoiceInformation'>
);

export type GetTaxSchemeListingQueryVariables = Exact<{
  accountID: Scalars['String'];
}>;


export type GetTaxSchemeListingQuery = (
  { __typename?: 'Query' }
  & { getTaxSchemeListing: Array<(
    { __typename?: 'taxSchemeEntity' }
    & Pick<TaxSchemeEntity, 'id' | 'name' | 'description' | 'isExclusive' | 'commonStatus'>
    & { taxSchemeDetails?: Maybe<Array<(
      { __typename?: 'taxSchemeDetailEntity' }
      & Pick<TaxSchemeDetailEntity, 'id' | 'taxSchemeId' | 'effectiveDate' | 'commonStatus'>
      & { taxSchemeDetailItems?: Maybe<Array<(
        { __typename?: 'taxSchemeDetailItemEntity' }
        & Pick<TaxSchemeDetailItemEntity, 'id' | 'taxSchemeDetailId' | 'computeMethod' | 'taxId' | 'seqNo' | 'commonStatus'>
        & { taxOnSeq?: Maybe<Array<(
          { __typename?: 'taxOnSeqObjects' }
          & Pick<TaxOnSeqObjects, 'taxSchemeDetailItemId' | 'seqNo'>
        )>>, taxSchemeDetail: (
          { __typename?: 'taxSchemeDetailEntity' }
          & Pick<TaxSchemeDetailEntity, 'id' | 'taxSchemeId' | 'effectiveDate' | 'commonStatus'>
          & { taxScheme: (
            { __typename?: 'taxSchemeEntity' }
            & Pick<TaxSchemeEntity, 'id' | 'name' | 'description' | 'isExclusive' | 'commonStatus'>
          ) }
        ) }
      )>> }
    )>> }
  )> }
);

export type GetTaxListingDropdownQueryVariables = Exact<{
  accountID: Scalars['String'];
  softwareCode: Scalars['String'];
}>;


export type GetTaxListingDropdownQuery = (
  { __typename?: 'Query' }
  & { getTaxListingDropdown: Array<(
    { __typename?: 'TaxListingOutput' }
    & Pick<TaxListingOutput, 'tax_id' | 'code' | 'description' | 'class_type' | 'tax_status' | 'effective_date' | 'rate' | 'rn' | 'tax_type'>
  )> }
);

export type TaxSchemeInsertMutationVariables = Exact<{
  accountID: Scalars['String'];
  taxSchemeInput: TaxSchemeInput;
}>;


export type TaxSchemeInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'taxSchemeInsert'>
);

export type TaxSchemeUpdateMutationVariables = Exact<{
  accountID: Scalars['String'];
  editTaxSchemeInput: EditTaxSchemeInput;
}>;


export type TaxSchemeUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'taxSchemeUpdate'>
);

export type TaxSchemeDeleteMutationVariables = Exact<{
  TaxSchemeID: Scalars['String'];
}>;


export type TaxSchemeDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'taxSchemeDelete'>
);

export type IsTaxSchemeInUseQueryVariables = Exact<{
  taxSchemeID: Scalars['String'];
  outletID: Scalars['String'];
}>;


export type IsTaxSchemeInUseQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'isTaxSchemeInUse'>
);

export type GetTaxSchemeDetailQueryVariables = Exact<{
  accountID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  orderID: Scalars['String'];
}>;


export type GetTaxSchemeDetailQuery = (
  { __typename?: 'Query' }
  & { getTaxSchemeDetail: Array<(
    { __typename?: 'TaxSchemeDetailOutput' }
    & Pick<TaxSchemeDetailOutput, 'taxId' | 'taxRate' | 'taxCode' | 'taxType'>
  )> }
);

export type IsTaxSameNameQueryVariables = Exact<{
  accountID: Scalars['String'];
  taxSchemeName: Scalars['String'];
}>;


export type IsTaxSameNameQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'isTaxSameName'>
);

export type GetTaxTypeListingQueryVariables = Exact<{
  softwareCode: Scalars['String'];
}>;


export type GetTaxTypeListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getTaxTypeListing'>
);

export type GetTaxListingQueryVariables = Exact<{
  accountID: Scalars['String'];
}>;


export type GetTaxListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getTaxListing'>
);

export type IsTaxInUseQueryVariables = Exact<{
  taxID: Scalars['String'];
  accountID: Scalars['String'];
}>;


export type IsTaxInUseQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'isTaxInUse'>
);

export type GetTaxDetailsQueryVariables = Exact<{
  taxID: Scalars['String'];
  accountID: Scalars['String'];
}>;


export type GetTaxDetailsQuery = (
  { __typename?: 'Query' }
  & { getTaxDetails: (
    { __typename?: 'taxEntity' }
    & Pick<TaxEntity, 'id' | 'accountId' | 'code' | 'description' | 'classType' | 'schemeTypeId' | 'isClaimable' | 'commonStatus'>
    & { taxDetail: Array<(
      { __typename?: 'taxDetailEntity' }
      & Pick<TaxDetailEntity, 'id' | 'effectiveDate' | 'rate'>
    )> }
  ) }
);

export type TaxInsertMutationVariables = Exact<{
  taxInsertInput: TaxSetupInput;
  accountID: Scalars['String'];
}>;


export type TaxInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'taxInsert'>
);

export type TaxDeleteMutationVariables = Exact<{
  taxID: Scalars['String'];
  taxDeleteInput: Array<TaxDetailInput> | TaxDetailInput;
}>;


export type TaxDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'taxDelete'>
);

export type TaxUpdateMutationVariables = Exact<{
  taxEditInput: EditTaxSetupInput;
  accountID: Scalars['String'];
}>;


export type TaxUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'taxUpdate'>
);

export type IsTaxSameCodeQueryVariables = Exact<{
  accountID: Scalars['String'];
  taxCode: Scalars['String'];
}>;


export type IsTaxSameCodeQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'isTaxSameCode'>
);

export type ConsolidatedInvoiceListingQueryVariables = Exact<{
  outletID: Scalars['String'];
  accountID: Scalars['String'];
  companyID: Scalars['String'];
  ID?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  docNo?: Maybe<Scalars['String']>;
  docType?: Maybe<Scalars['String']>;
  offSet: Scalars['Float'];
  limit: Scalars['Float'];
}>;


export type ConsolidatedInvoiceListingQuery = (
  { __typename?: 'Query' }
  & { ConsolidatedInvoiceListing: Array<(
    { __typename?: 'EInvoiceConsolidationEntity' }
    & Pick<EInvoiceConsolidationEntity, 'ID' | 'docDate' | 'docNo' | 'description' | 'docAmt' | 'createdTs' | 'createdBy' | 'einvoice_trx_type' | 'einvoice_status'>
    & { eIConsolidationDetail?: Maybe<Array<(
      { __typename?: 'EInvoiceConsolidationDetailEntity' }
      & Pick<EInvoiceConsolidationDetailEntity, 'ID' | 'createdTs' | 'createdBy' | 'refTable' | 'refRecordID' | 'amount' | 'serviceCharge' | 'taxAmt' | 'docAmt'>
      & { bill?: Maybe<(
        { __typename?: 'BillEntity' }
        & Pick<BillEntity, 'ID' | 'billNo'>
      )> }
    )>> }
  )> }
);

export type DocToConsolidateListingQueryVariables = Exact<{
  outletID: Scalars['String'];
  accountID: Scalars['String'];
  year?: Maybe<Scalars['Float']>;
  month?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  docType?: Maybe<Scalars['String']>;
  docNo?: Maybe<Scalars['String']>;
  isSearch?: Maybe<Scalars['Boolean']>;
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
}>;


export type DocToConsolidateListingQuery = (
  { __typename?: 'Query' }
  & { DocToConsolidateListing: (
    { __typename?: 'consolidatedEIResponse' }
    & Pick<ConsolidatedEiResponse, 'consolidatedEICount' | 'totalPages' | 'currentPage'>
    & { consolidatedEIAll?: Maybe<Array<(
      { __typename?: 'consolidatedEI' }
      & Pick<ConsolidatedEi, 'ID' | 'billStatus' | 'docDate' | 'docNo' | 'docType' | 'name' | 'baseAmount' | 'serviceCharge' | 'taxAmount' | 'docAmount'>
    )>>, consolidatedEI?: Maybe<Array<(
      { __typename?: 'consolidatedEI' }
      & Pick<ConsolidatedEi, 'ID' | 'billStatus' | 'docDate' | 'docNo' | 'docType' | 'name' | 'baseAmount' | 'serviceCharge' | 'taxAmount' | 'docAmount'>
    )>>, total?: Maybe<(
      { __typename?: 'consolidatedEITotal' }
      & Pick<ConsolidatedEiTotal, 'baseAmount' | 'serviceCharge' | 'taxAmount' | 'docAmount'>
    )> }
  ) }
);

export type GenerateConsolidatedDocMutationVariables = Exact<{
  outletID: Scalars['String'];
  accountID: Scalars['String'];
  companyID: Scalars['String'];
  year?: Maybe<Scalars['Float']>;
  month?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  docNo?: Maybe<Scalars['String']>;
  docType?: Maybe<Scalars['String']>;
  isSearch?: Maybe<Scalars['Boolean']>;
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  trxDate: Scalars['DateTime'];
  docDate: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
}>;


export type GenerateConsolidatedDocMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'GenerateConsolidatedDoc'>
);

export type DocToConsolidateViewQueryVariables = Exact<{
  outletID: Scalars['String'];
  accountID: Scalars['String'];
  companyID: Scalars['String'];
  consolidationID: Scalars['String'];
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
}>;


export type DocToConsolidateViewQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'DocToConsolidateView'>
);

export type GetOfficerQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
}>;


export type GetOfficerQuery = (
  { __typename?: 'Query' }
  & { getOfficer: Array<(
    { __typename?: 'OfficerEntity' }
    & Pick<OfficerEntity, 'ID' | 'name' | 'designation' | 'mobileNo' | 'isActive' | 'email'>
  )> }
);

export type CreateOfficerMutationVariables = Exact<{
  input: OfficerInput;
}>;


export type CreateOfficerMutation = (
  { __typename?: 'Mutation' }
  & { createOfficer: (
    { __typename?: 'OfficerEntity' }
    & Pick<OfficerEntity, 'ID'>
  ) }
);

export type UpdateOfficerMutationVariables = Exact<{
  input: OfficerInput;
}>;


export type UpdateOfficerMutation = (
  { __typename?: 'Mutation' }
  & { updateOfficer: (
    { __typename?: 'OfficerEntity' }
    & Pick<OfficerEntity, 'ID'>
  ) }
);

export type DeleteOfficerMutationVariables = Exact<{
  ID: Scalars['String'];
}>;


export type DeleteOfficerMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteOfficer'>
);

export type GetStaffQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
}>;


export type GetStaffQuery = (
  { __typename?: 'Query' }
  & { getStaff: Array<(
    { __typename?: 'StaffEntity' }
    & Pick<StaffEntity, 'ID' | 'name' | 'designation' | 'mobileNo' | 'email' | 'isActive'>
  )> }
);

export type CreateStaffMutationVariables = Exact<{
  input: StaffInput;
}>;


export type CreateStaffMutation = (
  { __typename?: 'Mutation' }
  & { createStaff: (
    { __typename?: 'StaffEntity' }
    & Pick<StaffEntity, 'ID' | 'name' | 'designation' | 'mobileNo'>
  ) }
);

export type UpdateStaffMutationVariables = Exact<{
  input: StaffInput;
}>;


export type UpdateStaffMutation = (
  { __typename?: 'Mutation' }
  & { updateStaff: (
    { __typename?: 'StaffEntity' }
    & Pick<StaffEntity, 'ID' | 'name' | 'designation' | 'mobileNo' | 'email'>
  ) }
);

export type DeleteStaffMutationVariables = Exact<{
  ID: Scalars['String'];
}>;


export type DeleteStaffMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteStaff'>
);

export type GetEInvoicePolicyQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['String']>;
}>;


export type GetEInvoicePolicyQuery = (
  { __typename?: 'Query' }
  & { getEInvoicePolicy: Array<(
    { __typename?: 'EInvoicePolicyEntity' }
    & Pick<EInvoicePolicyEntity, 'ID' | 'isActive' | 'accountID' | 'eInvoiceClassificationCodeID' | 'classificationCode' | 'description'>
  )> }
);

export type SubmitEInvoicePolicyMutationVariables = Exact<{
  input: EInvoicePolicyInput;
}>;


export type SubmitEInvoicePolicyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'submitEInvoicePolicy'>
);

export type CreateEInvoicePolicyMutationVariables = Exact<{
  input: EInvoicePolicyInput;
}>;


export type CreateEInvoicePolicyMutation = (
  { __typename?: 'Mutation' }
  & { createEInvoicePolicy: (
    { __typename?: 'EInvoicePolicyEntity' }
    & Pick<EInvoicePolicyEntity, 'ID'>
  ) }
);

export type UpdateEInvoicePolicyMutationVariables = Exact<{
  input: EInvoicePolicyInput;
}>;


export type UpdateEInvoicePolicyMutation = (
  { __typename?: 'Mutation' }
  & { updateEInvoicePolicy: (
    { __typename?: 'EInvoicePolicyEntity' }
    & Pick<EInvoicePolicyEntity, 'ID'>
  ) }
);

export type GetGeneralSettingSummaryQueryVariables = Exact<{ [key: string]: never; }>;


export type GetGeneralSettingSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'officerCount' | 'staffCount' | 'userCount' | 'roundingPolicyDate' | 'uomDate' | 'uomConversionDate' | 'taxDate' | 'taxSchemeDate' | 'eInvoicePolicyDate'>
);

export type GetRoundingPolicyQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['String']>;
}>;


export type GetRoundingPolicyQuery = (
  { __typename?: 'Query' }
  & { getRoundingPolicy: Array<(
    { __typename?: 'RoundingPolicyEntity' }
    & Pick<RoundingPolicyEntity, 'ID' | 'inUse' | 'isActive' | 'transactionType' | 'methodType' | 'smallestDenomination'>
  )> }
);

export type SubmitRoundingPolicyMutationVariables = Exact<{
  input: RoundingPolicyInput;
}>;


export type SubmitRoundingPolicyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'submitRoundingPolicy'>
);

export type CreateRoundingPolicyMutationVariables = Exact<{
  input: RoundingPolicyInput;
}>;


export type CreateRoundingPolicyMutation = (
  { __typename?: 'Mutation' }
  & { createRoundingPolicy: (
    { __typename?: 'RoundingPolicyEntity' }
    & Pick<RoundingPolicyEntity, 'ID'>
  ) }
);

export type UpdateRoundingPolicyMutationVariables = Exact<{
  input: RoundingPolicyInput;
}>;


export type UpdateRoundingPolicyMutation = (
  { __typename?: 'Mutation' }
  & { updateRoundingPolicy: (
    { __typename?: 'RoundingPolicyEntity' }
    & Pick<RoundingPolicyEntity, 'ID'>
  ) }
);

export type GetUomQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
}>;


export type GetUomQuery = (
  { __typename?: 'Query' }
  & { getUOM: Array<(
    { __typename?: 'UOMEntity' }
    & Pick<UomEntity, 'ID' | 'name' | 'code' | 'commonStatus'>
  )> }
);

export type CreateUomMutationVariables = Exact<{
  input: UomInput;
}>;


export type CreateUomMutation = (
  { __typename?: 'Mutation' }
  & { createUOM: (
    { __typename?: 'UOMEntity' }
    & Pick<UomEntity, 'name' | 'code'>
  ) }
);

export type UpdateUomMutationVariables = Exact<{
  input: UomInput;
}>;


export type UpdateUomMutation = (
  { __typename?: 'Mutation' }
  & { updateUOM: (
    { __typename?: 'UOMEntity' }
    & Pick<UomEntity, 'ID' | 'name' | 'code'>
  ) }
);

export type DeleteUomMutationVariables = Exact<{
  ID: Scalars['String'];
}>;


export type DeleteUomMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteUOM'>
);

export type GetUomConversionQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  fromUomID?: Maybe<Scalars['String']>;
  toUomID?: Maybe<Scalars['String']>;
}>;


export type GetUomConversionQuery = (
  { __typename?: 'Query' }
  & { getUOMConversion: Array<(
    { __typename?: 'UomConversionEntity' }
    & Pick<UomConversionEntity, 'ID' | 'fromAmt' | 'toAmt' | 'conversionRate' | 'fromUomID' | 'toUomID'>
    & { fromUOM?: Maybe<(
      { __typename?: 'UOMEntity' }
      & Pick<UomEntity, 'ID' | 'name' | 'code'>
    )>, toUOM?: Maybe<(
      { __typename?: 'UOMEntity' }
      & Pick<UomEntity, 'ID' | 'name' | 'code'>
    )> }
  )> }
);

export type GetUomConversionDataQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUomConversionDataQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getUomConversionData'>
);

export type CreateUomConversionMutationVariables = Exact<{
  input: UomConversionInput;
}>;


export type CreateUomConversionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createUOMConversion'>
);

export type UpdateUomConversionMutationVariables = Exact<{
  input: UomConversionInput;
}>;


export type UpdateUomConversionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateUOMConversion'>
);

export type DeleteUomConversionMutationVariables = Exact<{
  ID: Scalars['String'];
}>;


export type DeleteUomConversionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteUOMConversion'>
);

export type GetUserQueryVariables = Exact<{
  ID: Scalars['String'];
}>;


export type GetUserQuery = (
  { __typename?: 'Query' }
  & { getUser: (
    { __typename?: 'UserEntity' }
    & Pick<UserEntity, 'ID' | 'name' | 'department' | 'email' | 'jobTitle' | 'contactNo' | 'superUser' | 'commonStatus' | 'userName'>
  ) }
);

export type GetUsersByAccountAndSoftwareQueryVariables = Exact<{
  superUserBool?: Maybe<UserSelection>;
  status?: Maybe<CommonStatus>;
}>;


export type GetUsersByAccountAndSoftwareQuery = (
  { __typename?: 'Query' }
  & { getUsersByAccountAndSoftware: Array<(
    { __typename?: 'UserEntity' }
    & Pick<UserEntity, 'ID' | 'name' | 'department' | 'email' | 'contactNo' | 'superUser' | 'commonStatus' | 'jobTitle' | 'userName' | 'confirmed'>
  )> }
);

export type CreateUserMutationVariables = Exact<{
  input: UserInput;
  OutletID: Scalars['String'];
}>;


export type CreateUserMutation = (
  { __typename?: 'Mutation' }
  & { createUser: (
    { __typename?: 'UserEntity' }
    & Pick<UserEntity, 'ID' | 'email' | 'name'>
  ) }
);

export type UpdateUserMutationVariables = Exact<{
  input: UserInput;
}>;


export type UpdateUserMutation = (
  { __typename?: 'Mutation' }
  & { updateUser: (
    { __typename?: 'UserEntity' }
    & Pick<UserEntity, 'ID'>
  ) }
);

export type GetRoleQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
}>;


export type GetRoleQuery = (
  { __typename?: 'Query' }
  & { getRole: Array<(
    { __typename?: 'RoleEntity' }
    & Pick<RoleEntity, 'ID' | 'name'>
    & { rolePerm: Array<(
      { __typename?: 'RolePermissionAssignmentEntity' }
      & Pick<RolePermissionAssignmentEntity, 'permissionID' | 'roleID' | 'permName'>
    )> }
  )> }
);

export type TestPermissionQueryVariables = Exact<{
  posPerm: PosPermission;
}>;


export type TestPermissionQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'testPermission'>
);

export type GetAccessSecuritySummaryQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAccessSecuritySummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'userDate' | 'roleDate'>
);

export type ResendInvitationMutationVariables = Exact<{
  userID: Scalars['String'];
}>;


export type ResendInvitationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'resendInvitation'>
);

export type GetEntityRoleUserAssignmentQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  entityID?: Maybe<Scalars['String']>;
}>;


export type GetEntityRoleUserAssignmentQuery = (
  { __typename?: 'Query' }
  & { getEntityRoleUserAssignment: Array<(
    { __typename?: 'RoleUserEntAssignmentEntity' }
    & Pick<RoleUserEntAssignmentEntity, 'userID' | 'roleID' | 'entityID'>
  )> }
);

export type GetOutletRoleAssignmentQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
}>;


export type GetOutletRoleAssignmentQuery = (
  { __typename?: 'Query' }
  & { getOutlet: Array<(
    { __typename?: 'OutletEntity' }
    & Pick<OutletEntity, 'ID' | 'name' | 'businessDate' | 'address' | 'tablesInfo'>
  )>, getEntityRoleUserAssignment: Array<(
    { __typename?: 'RoleUserEntAssignmentEntity' }
    & Pick<RoleUserEntAssignmentEntity, 'ID' | 'userID' | 'roleID' | 'entityID' | 'accountID'>
  )> }
);

export type UserRoleIDsQueryVariables = Exact<{
  outletID: Scalars['String'];
}>;


export type UserRoleIDsQuery = (
  { __typename?: 'Query' }
  & { userRoleIDs: Array<(
    { __typename?: 'userRoleID' }
    & Pick<UserRoleId, 'userID' | 'roleID'>
  )> }
);

export type CreateEntityRoleUserMutationVariables = Exact<{
  input: EntityRoleUserAsgInput;
}>;


export type CreateEntityRoleUserMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'CreateEntityRoleUser'>
);

export type CreateListEntityRoleUserMutationVariables = Exact<{
  outletID: Scalars['String'];
  roleUserIDs: Array<RoleUserInput> | RoleUserInput;
}>;


export type CreateListEntityRoleUserMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'CreateListEntityRoleUser'>
);

export type CreateRolePermissionMutationVariables = Exact<{
  permissionArr: Array<PosPermission> | PosPermission;
  input: RoleInput;
}>;


export type CreateRolePermissionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'CreateRolePermission'>
);

export type UpdateRolePermissionMutationVariables = Exact<{
  permissionArr: Array<PosPermission> | PosPermission;
  input: RoleInput;
}>;


export type UpdateRolePermissionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'UpdateRolePermission'>
);

export type DeleteRolePermissionMutationVariables = Exact<{
  roleID: Scalars['String'];
}>;


export type DeleteRolePermissionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'DeleteRolePermission'>
);

export type DuplicateRolePermissionMutationVariables = Exact<{
  roleID: Scalars['String'];
  name: Scalars['String'];
}>;


export type DuplicateRolePermissionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'DuplicateRolePermission'>
);

export type GetHomeTableSummaryQueryVariables = Exact<{
  tableID: Scalars['String'];
}>;


export type GetHomeTableSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'orderCount' | 'orderItemCount' | 'newestTable' | 'itemTransferCount' | 'billSettlement' | 'billItemDiscount' | 'close' | 'editOrderInfo'>
);

export type TransferTableMutationVariables = Exact<{
  newTableID: Scalars['String'];
  oldTableID: Scalars['String'];
  orderID: Scalars['String'];
}>;


export type TransferTableMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'transferTable'>
);

export type TransferItemByQuantityMutationVariables = Exact<{
  transferInput: Array<TransferInput> | TransferInput;
  targetOrderID: Scalars['String'];
  sourceOrderID: Scalars['String'];
}>;


export type TransferItemByQuantityMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'transferItemByQuantity'>
);

export type GetTableSubmenuTableQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
}>;


export type GetTableSubmenuTableQuery = (
  { __typename?: 'Query' }
  & { getTable: Array<(
    { __typename?: 'TableEntity' }
    & Pick<TableEntity, 'ID' | 'tableNumber' | 'createdBy' | 'seatCount' | 'prefix' | 'outletID' | 'isActive' | 'status'>
    & { orderInfo?: Maybe<(
      { __typename?: 'OrderInfo' }
      & Pick<OrderInfo, 'totalAmount' | 'totalPax'>
    )>, reservation?: Maybe<(
      { __typename?: 'ReservationEntity' }
      & Pick<ReservationEntity, 'ID'>
    )>, outlet?: Maybe<(
      { __typename?: 'OutletEntity' }
      & Pick<OutletEntity, 'ID' | 'name'>
    )>, order?: Maybe<Array<(
      { __typename?: 'OrderEntity' }
      & Pick<OrderEntity, 'ID' | 'docNo' | 'patronAccountID' | 'tableID' | 'status' | 'pax'>
      & { patron?: Maybe<(
        { __typename?: 'PatronEntity' }
        & Pick<PatronEntity, 'ID' | 'patronClass'>
        & { patronType?: Maybe<(
          { __typename?: 'PatronTypeEntity' }
          & Pick<PatronTypeEntity, 'ID' | 'patronClass'>
        )> }
      )> }
    )>> }
  )> }
);

export type GetTableGridQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
}>;


export type GetTableGridQuery = (
  { __typename?: 'Query' }
  & { getTable: Array<(
    { __typename?: 'TableEntity' }
    & Pick<TableEntity, 'ID' | 'tableNumber' | 'seatCount' | 'prefix' | 'outletID' | 'isActive' | 'status'>
    & { orderInfo?: Maybe<(
      { __typename?: 'OrderInfo' }
      & Pick<OrderInfo, 'totalAmount' | 'totalPax'>
    )>, reservationInfo?: Maybe<(
      { __typename?: 'ReservationInfo' }
      & Pick<ReservationInfo, 'reserved' | 'pax' | 'name' | 'time' | 'noShow'>
    )>, reservation?: Maybe<(
      { __typename?: 'ReservationEntity' }
      & Pick<ReservationEntity, 'ID' | 'name' | 'reservationDate' | 'reservationTime'>
    )>, outlet?: Maybe<(
      { __typename?: 'OutletEntity' }
      & Pick<OutletEntity, 'ID' | 'name'>
    )>, order?: Maybe<Array<(
      { __typename?: 'OrderEntity' }
      & Pick<OrderEntity, 'tableID' | 'status'>
    )>> }
  )> }
);

export type CloseTableStatusMutationVariables = Exact<{
  tableID: Scalars['String'];
  billInput: Array<BillInput> | BillInput;
  paymentByQuantityInput: Array<PaymentByQuantityInput> | PaymentByQuantityInput;
}>;


export type CloseTableStatusMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'closeTableStatus'>
);

export type GetTableByAscOrderQueryVariables = Exact<{
  outletID: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
}>;


export type GetTableByAscOrderQuery = (
  { __typename?: 'Query' }
  & { getTableByAscOrder: Array<(
    { __typename?: 'TableEntity' }
    & Pick<TableEntity, 'ID' | 'tableNumber' | 'seatCount' | 'prefix' | 'outletID' | 'isActive' | 'status'>
    & { orderInfo?: Maybe<(
      { __typename?: 'OrderInfo' }
      & Pick<OrderInfo, 'totalAmount' | 'totalPax'>
    )>, reservationInfo?: Maybe<(
      { __typename?: 'ReservationInfo' }
      & Pick<ReservationInfo, 'reserved' | 'pax' | 'name' | 'time' | 'noShow'>
    )>, reservation?: Maybe<(
      { __typename?: 'ReservationEntity' }
      & Pick<ReservationEntity, 'ID' | 'name' | 'reservationDate' | 'reservationTime'>
    )>, outlet?: Maybe<(
      { __typename?: 'OutletEntity' }
      & Pick<OutletEntity, 'ID' | 'name'>
    )>, order?: Maybe<Array<(
      { __typename?: 'OrderEntity' }
      & Pick<OrderEntity, 'tableID' | 'status'>
    )>> }
  )> }
);

export type CheckCurrentMealPeriodQueryVariables = Exact<{
  outletID: Scalars['String'];
}>;


export type CheckCurrentMealPeriodQuery = (
  { __typename?: 'Query' }
  & { getCurrentMealPeriod: (
    { __typename?: 'MealPeriodEntity' }
    & Pick<MealPeriodEntity, 'ID' | 'name' | 'startTime' | 'endTime' | 'outletID' | 'isActive'>
  ) }
);

export type GetOrderItemQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  orderID?: Maybe<Scalars['String']>;
  billID?: Maybe<Scalars['String']>;
  status?: Maybe<OrderItemStatus>;
}>;


export type GetOrderItemQuery = (
  { __typename?: 'Query' }
  & { getOrderItem: Array<(
    { __typename?: 'OrderItemEntity' }
    & Pick<OrderItemEntity, 'ID' | 'orderID' | 'billID' | 'openItemName' | 'quantity' | 'taxAmount' | 'serviceCharge' | 'amount' | 'baseAmount' | 'kitchenStatus' | 'status' | 'orderDiscount' | 'totalInfo'>
    & { order?: Maybe<(
      { __typename?: 'OrderEntity' }
      & Pick<OrderEntity, 'ID'>
      & { bill?: Maybe<Array<(
        { __typename?: 'BillEntity' }
        & Pick<BillEntity, 'billNo'>
      )>> }
    )>, menuItem?: Maybe<(
      { __typename?: 'MenuItemEntity' }
      & Pick<MenuItemEntity, 'ID' | 'name' | 'description' | 'isDiscountable'>
    )>, orderItemDiscount?: Maybe<Array<(
      { __typename?: 'OrderItemDiscountEntity' }
      & Pick<OrderItemDiscountEntity, 'ID' | 'amount' | 'status' | 'reasonCode' | 'remark'>
    )>>, orderItemOption?: Maybe<Array<(
      { __typename?: 'OrderItemOptionEntity' }
      & Pick<OrderItemOptionEntity, 'ID'>
      & { orderItemOptionItem?: Maybe<Array<(
        { __typename?: 'OrderItemOptionItemEntity' }
        & Pick<OrderItemOptionItemEntity, 'ID' | 'price' | 'basePrice' | 'unitPrice' | 'taxAmount' | 'serviceCharge'>
      )>> }
    )>> }
  )> }
);

export type GetBillDiscountOrderQueryVariables = Exact<{
  outletID: Scalars['String'];
  ID?: Maybe<Scalars['String']>;
  tableID?: Maybe<Scalars['String']>;
  status?: Maybe<OrderStatus>;
}>;


export type GetBillDiscountOrderQuery = (
  { __typename?: 'Query' }
  & { getOrder: Array<(
    { __typename?: 'OrderEntity' }
    & Pick<OrderEntity, 'ID' | 'docNo' | 'outletID' | 'tableID' | 'mealPeriodID' | 'patronID' | 'totalAmount' | 'patronName' | 'taxSchemeInfo' | 'taxSchemeFooterInfo' | 'outstandingAmount' | 'patronAccountID' | 'contactNo' | 'pickupTime' | 'serviceMode' | 'status' | 'qr' | 'pax'>
    & { patron?: Maybe<(
      { __typename?: 'PatronEntity' }
      & Pick<PatronEntity, 'patronClass'>
    )>, bill?: Maybe<Array<(
      { __typename?: 'BillEntity' }
      & Pick<BillEntity, 'ID' | 'billNo' | 'businessDate'>
    )>>, table?: Maybe<(
      { __typename?: 'TableEntity' }
      & Pick<TableEntity, 'ID' | 'name' | 'seatCount' | 'prefix' | 'tableNumber' | 'status'>
    )>, orderDiscount?: Maybe<Array<(
      { __typename?: 'OrderDiscountEntity' }
      & Pick<OrderDiscountEntity, 'ID' | 'discountID' | 'orderID' | 'amount' | 'status' | 'reasonCode' | 'remark'>
    )>>, orderItem?: Maybe<Array<(
      { __typename?: 'OrderItemEntity' }
      & Pick<OrderItemEntity, 'ID' | 'name' | 'orderID' | 'amount' | 'specialRequest' | 'quantity' | 'status' | 'kitchenStatus' | 'isTakeaway' | 'weight' | 'menuItemID' | 'openItemName' | 'billID' | 'refID'>
      & { menuItem?: Maybe<(
        { __typename?: 'MenuItemEntity' }
        & Pick<MenuItemEntity, 'name' | 'description' | 'attachments'>
      )>, orderItemOption?: Maybe<Array<(
        { __typename?: 'OrderItemOptionEntity' }
        & Pick<OrderItemOptionEntity, 'optionID' | 'name'>
        & { orderItemOptionItem?: Maybe<Array<(
          { __typename?: 'OrderItemOptionItemEntity' }
          & Pick<OrderItemOptionItemEntity, 'optionItemID'>
          & { optionItem?: Maybe<(
            { __typename?: 'OptionItemEntity' }
            & Pick<OptionItemEntity, 'name'>
          )> }
        )>> }
      )>>, orderItemSetMenuOption?: Maybe<Array<(
        { __typename?: 'OrderItemSetMenuOptionEntity' }
        & Pick<OrderItemSetMenuOptionEntity, 'setMenuOptionID' | 'name'>
        & { orderItemSetMenuOptionItem?: Maybe<Array<(
          { __typename?: 'OrderItemSetMenuOptionItemEntity' }
          & Pick<OrderItemSetMenuOptionItemEntity, 'setMenuOptionItemID'>
          & { setMenuOptionItem?: Maybe<(
            { __typename?: 'SetMenuOptionItemEntity' }
            & Pick<SetMenuOptionItemEntity, 'ID'>
            & { menuItem?: Maybe<(
              { __typename?: 'MenuItemEntity' }
              & Pick<MenuItemEntity, 'ID' | 'name'>
            )> }
          )> }
        )>> }
      )>> }
    )>> }
  )> }
);

export type CreateOrderItemWithOptionsMutationVariables = Exact<{
  orderItemOptionInput?: Maybe<Array<OrderItemOptionInput> | OrderItemOptionInput>;
  orderItemInput: OrderItemInput;
}>;


export type CreateOrderItemWithOptionsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createOrderItemWithOptions'>
);

export type CreateOrderItemWithSetMenuOptionsMutationVariables = Exact<{
  orderItemInput: OrderItemInput;
  orderItemSetMenuOptionInput?: Maybe<Array<OrderItemSetMenuOptionInput> | OrderItemSetMenuOptionInput>;
}>;


export type CreateOrderItemWithSetMenuOptionsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createOrderItemWithSetMenuOptions'>
);

export type UpdateOrderItemWithOptionsMutationVariables = Exact<{
  orderItemInput: OrderItemInput;
  orderItemOptionInput?: Maybe<Array<OrderItemOptionInput> | OrderItemOptionInput>;
}>;


export type UpdateOrderItemWithOptionsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateOrderItemWithOptions'>
);

export type UpdateOrderItemWithSetMenuOptionsMutationVariables = Exact<{
  orderItemInput: OrderItemInput;
  orderItemSetMenuOptionInput?: Maybe<Array<OrderItemSetMenuOptionInput> | OrderItemSetMenuOptionInput>;
}>;


export type UpdateOrderItemWithSetMenuOptionsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateOrderItemWithSetMenuOptions'>
);

export type ConfirmOrderItemMutationVariables = Exact<{
  orderItemIDs: Array<Scalars['String']> | Scalars['String'];
  orderID: Scalars['String'];
}>;


export type ConfirmOrderItemMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'confirmOrderItem'>
);

export type RefreshOrderItemSubscriptionVariables = Exact<{
  outletID: Scalars['String'];
}>;


export type RefreshOrderItemSubscription = (
  { __typename?: 'Subscription' }
  & { refreshOrderItem: (
    { __typename?: 'OrderItemEntity' }
    & Pick<OrderItemEntity, 'ID' | 'outletID' | 'name' | 'description'>
  ) }
);

export type PostPrintDataMutationVariables = Exact<{
  printData: PrintDataPayload;
}>;


export type PostPrintDataMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'postPrintData'>
);

export type GetPrintDataSubscriptionVariables = Exact<{
  outletID: Scalars['String'];
}>;


export type GetPrintDataSubscription = (
  { __typename?: 'Subscription' }
  & { getPrintData: (
    { __typename?: 'PrintData' }
    & Pick<PrintData, 'printerName' | 'outletName' | 'orderID' | 'orderItemIDs' | 'orderMode' | 'voidQuantity' | 'reasonCode' | 'remark'>
  ) }
);

export type CreateOrderMutationVariables = Exact<{
  input: OrderInput;
}>;


export type CreateOrderMutation = (
  { __typename?: 'Mutation' }
  & { createOrder: (
    { __typename?: 'OrderEntity' }
    & Pick<OrderEntity, 'ID'>
  ) }
);

export type UpdateOrderMutationVariables = Exact<{
  input: OrderInput;
}>;


export type UpdateOrderMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateOrder'>
);

export type GetPrinterOrderItemQueryVariables = Exact<{
  orderID: Scalars['String'];
  orderItemIDs: Array<Scalars['String']> | Scalars['String'];
}>;


export type GetPrinterOrderItemQuery = (
  { __typename?: 'Query' }
  & { getPrinterOrderItem: Array<(
    { __typename?: 'OrderItemEntity' }
    & Pick<OrderItemEntity, 'ID' | 'quantity' | 'specialRequest' | 'isTakeaway'>
    & { menuItem?: Maybe<(
      { __typename?: 'MenuItemEntity' }
      & Pick<MenuItemEntity, 'ID' | 'name' | 'kitchenName' | 'kitchenPrinterID' | 'isPerforated' | 'perforatedQuantity'>
      & { kitchenPrinter?: Maybe<(
        { __typename?: 'KitchenPrinterEntity' }
        & Pick<KitchenPrinterEntity, 'ID' | 'name'>
      )> }
    )>, orderItemOption?: Maybe<Array<(
      { __typename?: 'OrderItemOptionEntity' }
      & Pick<OrderItemOptionEntity, 'ID'>
      & { orderItemOptionItem?: Maybe<Array<(
        { __typename?: 'OrderItemOptionItemEntity' }
        & Pick<OrderItemOptionItemEntity, 'ID'>
        & { optionItem?: Maybe<(
          { __typename?: 'OptionItemEntity' }
          & Pick<OptionItemEntity, 'ID' | 'name'>
        )> }
      )>> }
    )>>, orderItemSetMenuOption?: Maybe<Array<(
      { __typename?: 'OrderItemSetMenuOptionEntity' }
      & Pick<OrderItemSetMenuOptionEntity, 'ID'>
      & { orderItemSetMenuOptionItem?: Maybe<Array<(
        { __typename?: 'OrderItemSetMenuOptionItemEntity' }
        & Pick<OrderItemSetMenuOptionItemEntity, 'ID'>
        & { menuItem?: Maybe<(
          { __typename?: 'MenuItemEntity' }
          & Pick<MenuItemEntity, 'ID' | 'name' | 'kitchenName' | 'isPerforated' | 'perforatedQuantity'>
        )> }
      )>> }
    )>> }
  )> }
);

export type GetOrderSummaryQueryVariables = Exact<{
  orderID?: Maybe<Scalars['String']>;
  menuItemID?: Maybe<Scalars['String']>;
  status?: Maybe<OrderItemStatus>;
  ID?: Maybe<Scalars['String']>;
}>;


export type GetOrderSummaryQuery = (
  { __typename?: 'Query' }
  & { getOrderItem: Array<(
    { __typename?: 'OrderItemEntity' }
    & Pick<OrderItemEntity, 'ID' | 'quantity' | 'orderID' | 'menuItemID' | 'billID' | 'specialRequest' | 'unitPrice' | 'taxAmount' | 'serviceCharge' | 'amount' | 'baseAmount' | 'isTakeaway' | 'status' | 'weight' | 'openItemName' | 'totalInfo'>
    & { orderItemDiscount?: Maybe<Array<(
      { __typename?: 'OrderItemDiscountEntity' }
      & Pick<OrderItemDiscountEntity, 'amount' | 'status' | 'reasonCode' | 'remark'>
    )>>, menuItem?: Maybe<(
      { __typename?: 'MenuItemEntity' }
      & Pick<MenuItemEntity, 'name' | 'isSetMenu' | 'isByWeight' | 'isOpenItem' | 'isOpenPrice' | 'description' | 'attachments' | 'uomID' | 'kitchenPrinterID' | 'kitchenPrinter2ID'>
      & { dailyAvailability?: Maybe<(
        { __typename?: 'DailyMenuItemAvailabilityEntity' }
        & Pick<DailyMenuItemAvailabilityEntity, 'menuItemID' | 'ID' | 'outstandingQty'>
      )>, kitchenPrinter?: Maybe<(
        { __typename?: 'KitchenPrinterEntity' }
        & Pick<KitchenPrinterEntity, 'ID' | 'name' | 'description'>
      )>, menuItemOptions?: Maybe<Array<(
        { __typename?: 'MenuItemOptionEntity' }
        & Pick<MenuItemOptionEntity, 'ID' | 'optionID' | 'maxSelections'>
        & { menuItemOptionItem?: Maybe<Array<(
          { __typename?: 'MenuItemOptionItemEntity' }
          & Pick<MenuItemOptionItemEntity, 'ID' | 'menuItemOptionID' | 'optionItemID' | 'price'>
        )>>, option?: Maybe<(
          { __typename?: 'OptionEntity' }
          & Pick<OptionEntity, 'ID' | 'name'>
          & { optionItem?: Maybe<Array<(
            { __typename?: 'OptionItemEntity' }
            & Pick<OptionItemEntity, 'name' | 'ID'>
          )>> }
        )> }
      )>>, setMenuOption?: Maybe<Array<(
        { __typename?: 'SetMenuOptionEntity' }
        & Pick<SetMenuOptionEntity, 'ID' | 'name' | 'maxSelections'>
        & { setMenuOptionItem?: Maybe<Array<(
          { __typename?: 'SetMenuOptionItemEntity' }
          & Pick<SetMenuOptionItemEntity, 'ID' | 'quantity' | 'menuItemID'>
          & { menuItem?: Maybe<(
            { __typename?: 'MenuItemEntity' }
            & Pick<MenuItemEntity, 'ID' | 'name'>
          )> }
        )>> }
      )>> }
    )>, orderItemOption?: Maybe<Array<(
      { __typename?: 'OrderItemOptionEntity' }
      & Pick<OrderItemOptionEntity, 'optionID'>
      & { orderItemOptionItem?: Maybe<Array<(
        { __typename?: 'OrderItemOptionItemEntity' }
        & Pick<OrderItemOptionItemEntity, 'ID' | 'optionItemID' | 'price' | 'basePrice' | 'unitPrice' | 'taxAmount' | 'serviceCharge'>
        & { optionItem?: Maybe<(
          { __typename?: 'OptionItemEntity' }
          & Pick<OptionItemEntity, 'name'>
        )> }
      )>> }
    )>>, orderItemSetMenuOption?: Maybe<Array<(
      { __typename?: 'OrderItemSetMenuOptionEntity' }
      & Pick<OrderItemSetMenuOptionEntity, 'setMenuOptionID'>
      & { orderItemSetMenuOptionItem?: Maybe<Array<(
        { __typename?: 'OrderItemSetMenuOptionItemEntity' }
        & Pick<OrderItemSetMenuOptionItemEntity, 'setMenuOptionItemID' | 'quantity'>
        & { setMenuOptionItem?: Maybe<(
          { __typename?: 'SetMenuOptionItemEntity' }
          & Pick<SetMenuOptionItemEntity, 'ID' | 'quantity'>
          & { menuItem?: Maybe<(
            { __typename?: 'MenuItemEntity' }
            & Pick<MenuItemEntity, 'ID' | 'name'>
          )> }
        )> }
      )>> }
    )>> }
  )> }
);

export type GetAllOrderItemQueryVariables = Exact<{
  orderID: Scalars['String'];
}>;


export type GetAllOrderItemQuery = (
  { __typename?: 'Query' }
  & { getAllOrderItem: Array<(
    { __typename?: 'OrderItemEntity' }
    & Pick<OrderItemEntity, 'ID' | 'modBy' | 'createdBy' | 'quantity' | 'orderID' | 'menuItemID' | 'billID' | 'specialRequest' | 'unitPrice' | 'taxAmount' | 'serviceCharge' | 'amount' | 'baseAmount' | 'isTakeaway' | 'reasonCode' | 'refID' | 'status' | 'weight' | 'openItemName' | 'orderDiscount' | 'totalInfo' | 'unitPriceInfo'>
    & { orderItemTax?: Maybe<Array<(
      { __typename?: 'OrderItemTaxEntity' }
      & Pick<OrderItemTaxEntity, 'ID' | 'orderItemID' | 'taxSchemeID' | 'taxSchemeDetailID' | 'taxID' | 'taxRate' | 'taxAmount' | 'taxSchemeType'>
    )>>, orderItemDiscountTax?: Maybe<Array<(
      { __typename?: 'OrderItemDiscountTaxEntity' }
      & Pick<OrderItemDiscountTaxEntity, 'ID' | 'orderItemID' | 'orderItemDiscountID' | 'taxSchemeID' | 'taxSchemeDetailID' | 'taxID' | 'taxRate' | 'taxAmount'>
    )>>, orderDiscountItemTax?: Maybe<Array<(
      { __typename?: 'OrderDiscountItemTaxV1Entity' }
      & Pick<OrderDiscountItemTaxV1Entity, 'ID' | 'orderItemID' | 'orderDiscountItemID' | 'taxSchemeID' | 'taxSchemeDetailID' | 'taxID' | 'taxRate' | 'taxAmount'>
    )>>, orderItemDiscount?: Maybe<Array<(
      { __typename?: 'OrderItemDiscountEntity' }
      & Pick<OrderItemDiscountEntity, 'orderItemID' | 'amount' | 'baseAmount' | 'serviceCharge' | 'taxAmount' | 'status' | 'reasonCode' | 'remark'>
      & { orderItemDiscountTax?: Maybe<Array<(
        { __typename?: 'OrderItemDiscountTaxEntity' }
        & Pick<OrderItemDiscountTaxEntity, 'ID' | 'orderItemID' | 'orderItemDiscountID' | 'taxRate' | 'taxAmount'>
      )>> }
    )>>, orderDiscountItem?: Maybe<(
      { __typename?: 'OrderDiscountItemEntity' }
      & Pick<OrderDiscountItemEntity, 'orderItemID' | 'amount' | 'baseAmount' | 'serviceCharge' | 'taxAmount'>
      & { orderDiscountItemTax?: Maybe<Array<(
        { __typename?: 'OrderDiscountItemTaxV1Entity' }
        & Pick<OrderDiscountItemTaxV1Entity, 'ID' | 'orderItemID' | 'orderDiscountItemID' | 'taxSchemeID' | 'taxSchemeDetailID' | 'taxID' | 'taxRate' | 'taxAmount'>
      )>> }
    )>, menuItem?: Maybe<(
      { __typename?: 'MenuItemEntity' }
      & Pick<MenuItemEntity, 'name' | 'isSetMenu' | 'isByWeight' | 'isOpenItem' | 'isOpenPrice' | 'isDiscountable' | 'description' | 'attachments' | 'uomID'>
      & { kitchenPrinter?: Maybe<(
        { __typename?: 'KitchenPrinterEntity' }
        & Pick<KitchenPrinterEntity, 'ID' | 'name'>
      )>, menuItemOptions?: Maybe<Array<(
        { __typename?: 'MenuItemOptionEntity' }
        & Pick<MenuItemOptionEntity, 'ID' | 'optionID' | 'maxSelections'>
        & { menuItemOptionItem?: Maybe<Array<(
          { __typename?: 'MenuItemOptionItemEntity' }
          & Pick<MenuItemOptionItemEntity, 'ID' | 'menuItemOptionID' | 'optionItemID' | 'price'>
        )>>, option?: Maybe<(
          { __typename?: 'OptionEntity' }
          & Pick<OptionEntity, 'ID' | 'name'>
          & { optionItem?: Maybe<Array<(
            { __typename?: 'OptionItemEntity' }
            & Pick<OptionItemEntity, 'name' | 'ID'>
          )>> }
        )> }
      )>>, setMenuOption?: Maybe<Array<(
        { __typename?: 'SetMenuOptionEntity' }
        & Pick<SetMenuOptionEntity, 'ID' | 'name' | 'maxSelections'>
        & { setMenuOptionItem?: Maybe<Array<(
          { __typename?: 'SetMenuOptionItemEntity' }
          & Pick<SetMenuOptionItemEntity, 'ID' | 'quantity' | 'menuItemID'>
          & { menuItem?: Maybe<(
            { __typename?: 'MenuItemEntity' }
            & Pick<MenuItemEntity, 'ID' | 'name'>
          )> }
        )>> }
      )>> }
    )>, orderItemOption?: Maybe<Array<(
      { __typename?: 'OrderItemOptionEntity' }
      & Pick<OrderItemOptionEntity, 'optionID'>
      & { orderItemOptionItem?: Maybe<Array<(
        { __typename?: 'OrderItemOptionItemEntity' }
        & Pick<OrderItemOptionItemEntity, 'optionItemID' | 'price' | 'basePrice' | 'unitPrice' | 'taxAmount' | 'serviceCharge' | 'discountAmount' | 'discountBaseAmount' | 'discountTaxAmount' | 'discountServiceCharge'>
        & { optionItem?: Maybe<(
          { __typename?: 'OptionItemEntity' }
          & Pick<OptionItemEntity, 'name'>
        )>, orderItemOptionItemTax?: Maybe<Array<(
          { __typename?: 'OrderItemOptionItemTaxEntity' }
          & Pick<OrderItemOptionItemTaxEntity, 'ID' | 'orderItemID' | 'orderItemOptionItemID' | 'taxSchemeID' | 'taxSchemeDetailID' | 'taxID' | 'taxRate' | 'taxAmount'>
        )>>, orderItemOptionItemDiscountTax?: Maybe<Array<(
          { __typename?: 'OrderItemOptionItemDiscountTaxEntity' }
          & Pick<OrderItemOptionItemDiscountTaxEntity, 'ID' | 'orderItemOptionItemID' | 'orderItemDiscountID' | 'taxSchemeID' | 'taxSchemeDetailID' | 'taxID' | 'taxRate' | 'taxAmount'>
        )>>, orderDiscountItemOptionTax?: Maybe<Array<(
          { __typename?: 'OrderDiscountItemOptionTaxEntity' }
          & Pick<OrderDiscountItemOptionTaxEntity, 'ID' | 'orderItemOptionItemID' | 'orderDiscountItemID' | 'taxSchemeID' | 'taxSchemeDetailID' | 'taxID' | 'taxRate' | 'taxAmount'>
        )>> }
      )>> }
    )>>, orderItemSetMenuOption?: Maybe<Array<(
      { __typename?: 'OrderItemSetMenuOptionEntity' }
      & Pick<OrderItemSetMenuOptionEntity, 'setMenuOptionID'>
      & { orderItemSetMenuOptionItem?: Maybe<Array<(
        { __typename?: 'OrderItemSetMenuOptionItemEntity' }
        & Pick<OrderItemSetMenuOptionItemEntity, 'setMenuOptionItemID' | 'quantity'>
        & { setMenuOptionItem?: Maybe<(
          { __typename?: 'SetMenuOptionItemEntity' }
          & Pick<SetMenuOptionItemEntity, 'ID' | 'quantity'>
          & { menuItem?: Maybe<(
            { __typename?: 'MenuItemEntity' }
            & Pick<MenuItemEntity, 'ID' | 'name'>
          )> }
        )> }
      )>> }
    )>> }
  )> }
);

export type GetBillSettlementOrderItemQueryVariables = Exact<{
  orderID: Scalars['String'];
}>;


export type GetBillSettlementOrderItemQuery = (
  { __typename?: 'Query' }
  & { getBillSettlementOrderItem: Array<(
    { __typename?: 'OrderItemEntity' }
    & Pick<OrderItemEntity, 'ID' | 'quantity' | 'orderID' | 'menuItemID' | 'billID' | 'specialRequest' | 'unitPrice' | 'taxAmount' | 'serviceCharge' | 'amount' | 'baseAmount' | 'isTakeaway' | 'refID' | 'status' | 'weight' | 'openItemName' | 'orderDiscount' | 'totalInfo'>
    & { orderItemTax?: Maybe<Array<(
      { __typename?: 'OrderItemTaxEntity' }
      & Pick<OrderItemTaxEntity, 'ID' | 'orderItemID' | 'taxSchemeID' | 'taxSchemeDetailID' | 'taxID' | 'taxRate' | 'taxAmount' | 'taxSchemeType'>
    )>>, orderItemDiscountTax?: Maybe<Array<(
      { __typename?: 'OrderItemDiscountTaxEntity' }
      & Pick<OrderItemDiscountTaxEntity, 'ID' | 'orderItemID' | 'orderItemDiscountID' | 'taxSchemeID' | 'taxSchemeDetailID' | 'taxID' | 'taxRate' | 'taxAmount'>
    )>>, orderDiscountItemTax?: Maybe<Array<(
      { __typename?: 'OrderDiscountItemTaxV1Entity' }
      & Pick<OrderDiscountItemTaxV1Entity, 'ID' | 'orderItemID' | 'orderDiscountItemID' | 'taxSchemeID' | 'taxSchemeDetailID' | 'taxID' | 'taxRate' | 'taxAmount'>
    )>>, orderItemDiscount?: Maybe<Array<(
      { __typename?: 'OrderItemDiscountEntity' }
      & Pick<OrderItemDiscountEntity, 'orderItemID' | 'amount' | 'baseAmount' | 'serviceCharge' | 'discountID' | 'taxAmount' | 'status' | 'reasonCode' | 'remark'>
      & { orderItemDiscountTax?: Maybe<Array<(
        { __typename?: 'OrderItemDiscountTaxEntity' }
        & Pick<OrderItemDiscountTaxEntity, 'ID' | 'orderItemID' | 'orderItemDiscountID' | 'taxSchemeID' | 'taxSchemeDetailID' | 'taxID' | 'taxRate' | 'taxAmount'>
      )>> }
    )>>, orderDiscountItem?: Maybe<(
      { __typename?: 'OrderDiscountItemEntity' }
      & Pick<OrderDiscountItemEntity, 'orderItemID' | 'amount' | 'baseAmount' | 'serviceCharge' | 'taxAmount'>
      & { orderDiscountItemTax?: Maybe<Array<(
        { __typename?: 'OrderDiscountItemTaxV1Entity' }
        & Pick<OrderDiscountItemTaxV1Entity, 'ID' | 'orderItemID' | 'orderDiscountItemID' | 'taxSchemeID' | 'taxSchemeDetailID' | 'taxID' | 'taxRate' | 'taxAmount'>
      )>> }
    )>, menuItem?: Maybe<(
      { __typename?: 'MenuItemEntity' }
      & Pick<MenuItemEntity, 'name' | 'isSetMenu' | 'isByWeight' | 'isOpenItem' | 'isOpenPrice' | 'isDiscountable' | 'description' | 'attachments' | 'uomID'>
      & { menuItemOptions?: Maybe<Array<(
        { __typename?: 'MenuItemOptionEntity' }
        & Pick<MenuItemOptionEntity, 'ID' | 'optionID' | 'maxSelections'>
        & { menuItemOptionItem?: Maybe<Array<(
          { __typename?: 'MenuItemOptionItemEntity' }
          & Pick<MenuItemOptionItemEntity, 'ID' | 'menuItemOptionID' | 'optionItemID' | 'price'>
        )>>, option?: Maybe<(
          { __typename?: 'OptionEntity' }
          & Pick<OptionEntity, 'ID' | 'name'>
          & { optionItem?: Maybe<Array<(
            { __typename?: 'OptionItemEntity' }
            & Pick<OptionItemEntity, 'name' | 'ID'>
          )>> }
        )> }
      )>>, setMenuOption?: Maybe<Array<(
        { __typename?: 'SetMenuOptionEntity' }
        & Pick<SetMenuOptionEntity, 'ID' | 'name' | 'maxSelections'>
        & { setMenuOptionItem?: Maybe<Array<(
          { __typename?: 'SetMenuOptionItemEntity' }
          & Pick<SetMenuOptionItemEntity, 'ID' | 'quantity' | 'menuItemID'>
          & { menuItem?: Maybe<(
            { __typename?: 'MenuItemEntity' }
            & Pick<MenuItemEntity, 'ID' | 'name'>
          )> }
        )>> }
      )>> }
    )>, orderItemOption?: Maybe<Array<(
      { __typename?: 'OrderItemOptionEntity' }
      & Pick<OrderItemOptionEntity, 'optionID'>
      & { orderItemOptionItem?: Maybe<Array<(
        { __typename?: 'OrderItemOptionItemEntity' }
        & Pick<OrderItemOptionItemEntity, 'optionItemID' | 'price' | 'basePrice' | 'unitPrice' | 'taxAmount' | 'serviceCharge' | 'discountBaseAmount' | 'discountServiceCharge' | 'discountTaxAmount'>
        & { orderItemOptionItemTax?: Maybe<Array<(
          { __typename?: 'OrderItemOptionItemTaxEntity' }
          & Pick<OrderItemOptionItemTaxEntity, 'ID' | 'orderItemID' | 'orderItemOptionItemID' | 'taxSchemeID' | 'taxSchemeDetailID' | 'taxID' | 'taxRate' | 'taxAmount'>
        )>>, orderItemOptionItemDiscountTax?: Maybe<Array<(
          { __typename?: 'OrderItemOptionItemDiscountTaxEntity' }
          & Pick<OrderItemOptionItemDiscountTaxEntity, 'ID' | 'orderItemOptionItemID' | 'orderItemDiscountID' | 'taxSchemeID' | 'taxSchemeDetailID' | 'taxID' | 'taxRate' | 'taxAmount'>
        )>>, orderDiscountItemOptionTax?: Maybe<Array<(
          { __typename?: 'OrderDiscountItemOptionTaxEntity' }
          & Pick<OrderDiscountItemOptionTaxEntity, 'ID' | 'orderItemOptionItemID' | 'orderDiscountItemID' | 'taxSchemeID' | 'taxSchemeDetailID' | 'taxID' | 'taxRate' | 'taxAmount'>
        )>>, optionItem?: Maybe<(
          { __typename?: 'OptionItemEntity' }
          & Pick<OptionItemEntity, 'name'>
        )> }
      )>> }
    )>>, orderItemSetMenuOption?: Maybe<Array<(
      { __typename?: 'OrderItemSetMenuOptionEntity' }
      & Pick<OrderItemSetMenuOptionEntity, 'setMenuOptionID'>
      & { orderItemSetMenuOptionItem?: Maybe<Array<(
        { __typename?: 'OrderItemSetMenuOptionItemEntity' }
        & Pick<OrderItemSetMenuOptionItemEntity, 'setMenuOptionItemID' | 'quantity'>
        & { setMenuOptionItem?: Maybe<(
          { __typename?: 'SetMenuOptionItemEntity' }
          & Pick<SetMenuOptionItemEntity, 'ID' | 'quantity'>
          & { menuItem?: Maybe<(
            { __typename?: 'MenuItemEntity' }
            & Pick<MenuItemEntity, 'ID' | 'name'>
          )> }
        )> }
      )>> }
    )>> }
  )> }
);

export type GetOrderTotalQueryVariables = Exact<{
  outletID: Scalars['String'];
  ID?: Maybe<Scalars['String']>;
  tableID?: Maybe<Scalars['String']>;
  status?: Maybe<OrderStatus>;
}>;


export type GetOrderTotalQuery = (
  { __typename?: 'Query' }
  & { getOrder: Array<(
    { __typename?: 'OrderEntity' }
    & Pick<OrderEntity, 'outstandingAmount'>
  )> }
);

export type GetOrderQueryVariables = Exact<{
  outletID: Scalars['String'];
  ID?: Maybe<Scalars['String']>;
  tableID?: Maybe<Scalars['String']>;
  status?: Maybe<OrderStatus>;
}>;


export type GetOrderQuery = (
  { __typename?: 'Query' }
  & { getOrder: Array<(
    { __typename?: 'OrderEntity' }
    & Pick<OrderEntity, 'ID' | 'outletID' | 'tableID' | 'createdBy' | 'modBy' | 'qrID' | 'docNo' | 'serviceMode' | 'openedByID' | 'servedByIDs' | 'paidByID' | 'businessDate' | 'pax' | 'taxSchemeInfo' | 'taxSchemeFooterInfo' | 'mealPeriodID' | 'patronID' | 'createdTs' | 'totalAmount' | 'outstandingAmount' | 'patronAccountID' | 'registrationID' | 'status' | 'patronName' | 'contactNo' | 'pickupTime' | 'qr' | 'totalDiscountAmount'>
    & { orderDiscount?: Maybe<Array<(
      { __typename?: 'OrderDiscountEntity' }
      & Pick<OrderDiscountEntity, 'ID' | 'amount' | 'status' | 'reasonCode' | 'discountID' | 'remark' | 'discountTypeInfo'>
    )>>, mealPeriod?: Maybe<(
      { __typename?: 'MealPeriodEntity' }
      & Pick<MealPeriodEntity, 'ID' | 'name' | 'startTime' | 'endTime'>
    )>, orderItem?: Maybe<Array<(
      { __typename?: 'OrderItemEntity' }
      & Pick<OrderItemEntity, 'ID' | 'name' | 'orderID' | 'amount' | 'serviceCharge' | 'taxAmount' | 'specialRequest' | 'quantity' | 'status' | 'kitchenStatus' | 'isTakeaway' | 'weight' | 'menuItemID' | 'openItemName' | 'billID' | 'refID'>
      & { menuItem?: Maybe<(
        { __typename?: 'MenuItemEntity' }
        & Pick<MenuItemEntity, 'name' | 'description' | 'attachments'>
      )>, orderItemOption?: Maybe<Array<(
        { __typename?: 'OrderItemOptionEntity' }
        & Pick<OrderItemOptionEntity, 'optionID' | 'name'>
        & { orderItemOptionItem?: Maybe<Array<(
          { __typename?: 'OrderItemOptionItemEntity' }
          & Pick<OrderItemOptionItemEntity, 'optionItemID' | 'price' | 'unitPrice' | 'serviceCharge' | 'taxAmount'>
          & { optionItem?: Maybe<(
            { __typename?: 'OptionItemEntity' }
            & Pick<OptionItemEntity, 'name'>
          )> }
        )>> }
      )>>, orderItemSetMenuOption?: Maybe<Array<(
        { __typename?: 'OrderItemSetMenuOptionEntity' }
        & Pick<OrderItemSetMenuOptionEntity, 'setMenuOptionID' | 'name'>
        & { orderItemSetMenuOptionItem?: Maybe<Array<(
          { __typename?: 'OrderItemSetMenuOptionItemEntity' }
          & Pick<OrderItemSetMenuOptionItemEntity, 'setMenuOptionItemID'>
          & { setMenuOptionItem?: Maybe<(
            { __typename?: 'SetMenuOptionItemEntity' }
            & Pick<SetMenuOptionItemEntity, 'ID'>
            & { menuItem?: Maybe<(
              { __typename?: 'MenuItemEntity' }
              & Pick<MenuItemEntity, 'ID' | 'name'>
            )> }
          )> }
        )>> }
      )>>, orderItemDiscount?: Maybe<Array<(
        { __typename?: 'OrderItemDiscountEntity' }
        & Pick<OrderItemDiscountEntity, 'ID' | 'amount' | 'status' | 'reasonCode' | 'remark'>
      )>> }
    )>>, bill?: Maybe<Array<(
      { __typename?: 'BillEntity' }
      & Pick<BillEntity, 'ID' | 'createdTs' | 'billNo' | 'businessDate'>
    )>>, table?: Maybe<(
      { __typename?: 'TableEntity' }
      & Pick<TableEntity, 'ID' | 'name' | 'seatCount' | 'prefix' | 'tableNumber' | 'status'>
      & { orderInfo?: Maybe<(
        { __typename?: 'OrderInfo' }
        & Pick<OrderInfo, 'totalAmount'>
      )> }
    )>, patron?: Maybe<(
      { __typename?: 'PatronEntity' }
      & Pick<PatronEntity, 'patronClass'>
      & { patronType?: Maybe<(
        { __typename?: 'PatronTypeEntity' }
        & Pick<PatronTypeEntity, 'name'>
      )> }
    )> }
  )> }
);

export type UpdateKitchenItemStatusMutationVariables = Exact<{
  InputItemID: Scalars['String'];
}>;


export type UpdateKitchenItemStatusMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateKitchenStatus'>
);

export type RevertKitchenItemStatusMutationVariables = Exact<{
  InputItemID: Scalars['String'];
}>;


export type RevertKitchenItemStatusMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'revertKitchenStatus'>
);

export type ConfirmKitchenItemStatusMutationVariables = Exact<{
  InputItemID: Array<Scalars['String']> | Scalars['String'];
}>;


export type ConfirmKitchenItemStatusMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'confirmKitchenStatus'>
);

export type VoidOrderItemWithOptionsMutationVariables = Exact<{
  input: VoidOrderItemInput;
  file?: Maybe<Scalars['String']>;
}>;


export type VoidOrderItemWithOptionsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'voidOrderItemWithOptions'>
);

export type VoidOrderItemWithSetMenuOptionsMutationVariables = Exact<{
  input: VoidOrderItemInput;
}>;


export type VoidOrderItemWithSetMenuOptionsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'voidOrderItemWithSetMenuOptions'>
);

export type GetScheduledDailyMenuCategoryQueryVariables = Exact<{
  outletID?: Maybe<Scalars['String']>;
  mealPeriodID?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
}>;


export type GetScheduledDailyMenuCategoryQuery = (
  { __typename?: 'Query' }
  & { getOutletDailyMenuCategory: Array<(
    { __typename?: 'OutletDailyMenuCategoryEntity' }
    & Pick<OutletDailyMenuCategoryEntity, 'ID' | 'name' | 'priority' | 'modTs' | 'createdTs' | 'outletID' | 'mealPeriodID' | 'digitalMenuCategoryID'>
    & { outletDailyMenuItem?: Maybe<Array<(
      { __typename?: 'OutletDailyMenuItemEntity' }
      & Pick<OutletDailyMenuItemEntity, 'ID' | 'name' | 'description' | 'menuItemID' | 'attachments' | 'isOpenItem' | 'isOpenPrice' | 'isByWeight' | 'isSetMenu' | 'isChefRecommended' | 'uomID' | 'promoChecking' | 'outletDailyMenuCategoryID'>
      & { outletDailyPromoMenuItem?: Maybe<Array<(
        { __typename?: 'OutletDailyPromoMenuItemEntity' }
        & Pick<OutletDailyPromoMenuItemEntity, 'ID' | 'promoMenuItemID' | 'startTime' | 'endTime'>
        & { outletDailyPromoMenuItemPrice?: Maybe<Array<(
          { __typename?: 'OutletDailyPromoMenuItemPriceEntity' }
          & Pick<OutletDailyPromoMenuItemPriceEntity, 'ID' | 'price' | 'name' | 'description' | 'patronID'>
        )>> }
      )>>, menuItem?: Maybe<(
        { __typename?: 'MenuItemEntity' }
        & Pick<MenuItemEntity, 'ID' | 'isDiscountable'>
        & { dailyAvailability?: Maybe<(
          { __typename?: 'DailyMenuItemAvailabilityEntity' }
          & Pick<DailyMenuItemAvailabilityEntity, 'menuItemID' | 'ID' | 'outstandingQty'>
        )>, stopSales?: Maybe<(
          { __typename?: 'StopSalesEntity' }
          & Pick<StopSalesEntity, 'ID' | 'isActive' | 'menuItemID'>
        )>, menuItemOptions?: Maybe<Array<(
          { __typename?: 'MenuItemOptionEntity' }
          & Pick<MenuItemOptionEntity, 'maxSelections' | 'menuItemID' | 'optionID'>
          & { menuItemOptionItem?: Maybe<Array<(
            { __typename?: 'MenuItemOptionItemEntity' }
            & Pick<MenuItemOptionItemEntity, 'optionItemID' | 'menuItemOptionID' | 'price'>
            & { optionItem?: Maybe<(
              { __typename?: 'OptionItemEntity' }
              & Pick<OptionItemEntity, 'ID' | 'optionID' | 'name'>
            )> }
          )>>, option?: Maybe<(
            { __typename?: 'OptionEntity' }
            & Pick<OptionEntity, 'ID' | 'name'>
            & { optionItem?: Maybe<Array<(
              { __typename?: 'OptionItemEntity' }
              & Pick<OptionItemEntity, 'ID' | 'optionID' | 'name'>
            )>> }
          )> }
        )>>, setMenuOption?: Maybe<Array<(
          { __typename?: 'SetMenuOptionEntity' }
          & Pick<SetMenuOptionEntity, 'ID' | 'name' | 'maxSelections'>
          & { setMenuOptionItem?: Maybe<Array<(
            { __typename?: 'SetMenuOptionItemEntity' }
            & Pick<SetMenuOptionItemEntity, 'ID' | 'quantity' | 'menuItemID'>
            & { menuItem?: Maybe<(
              { __typename?: 'MenuItemEntity' }
              & Pick<MenuItemEntity, 'ID' | 'name'>
              & { dailyAvailability?: Maybe<(
                { __typename?: 'DailyMenuItemAvailabilityEntity' }
                & Pick<DailyMenuItemAvailabilityEntity, 'outstandingQty'>
              )> }
            )> }
          )>> }
        )>> }
      )>, outletDailyMenuItemPatronPrice?: Maybe<Array<(
        { __typename?: 'OutletDailyMenuItemPatronPriceEntity' }
        & Pick<OutletDailyMenuItemPatronPriceEntity, 'ID' | 'outletID' | 'price' | 'name' | 'patronID' | 'description' | 'patronClass' | 'outletDailyMenuItemID'>
      )>> }
    )>> }
  )> }
);

export type GetDailyMenuCategoryQueryVariables = Exact<{
  outletID?: Maybe<Scalars['String']>;
  mealPeriodID?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
}>;


export type GetDailyMenuCategoryQuery = (
  { __typename?: 'Query' }
  & { getOutletDailyMenuCategory: Array<(
    { __typename?: 'OutletDailyMenuCategoryEntity' }
    & Pick<OutletDailyMenuCategoryEntity, 'ID' | 'name' | 'outletID' | 'mealPeriodID' | 'priority' | 'digitalMenuCategoryID'>
    & { outletDailyMenuItem?: Maybe<Array<(
      { __typename?: 'OutletDailyMenuItemEntity' }
      & Pick<OutletDailyMenuItemEntity, 'ID' | 'outletDailyMenuCategoryID' | 'name'>
      & { menuItem?: Maybe<(
        { __typename?: 'MenuItemEntity' }
        & Pick<MenuItemEntity, 'ID'>
        & { stopSales?: Maybe<(
          { __typename?: 'StopSalesEntity' }
          & Pick<StopSalesEntity, 'ID'>
        )> }
      )>, outletDailyMenuItemPatronPrice?: Maybe<Array<(
        { __typename?: 'OutletDailyMenuItemPatronPriceEntity' }
        & Pick<OutletDailyMenuItemPatronPriceEntity, 'patronID' | 'price'>
      )>>, outletDailyPromoMenuItem?: Maybe<Array<(
        { __typename?: 'OutletDailyPromoMenuItemEntity' }
        & Pick<OutletDailyPromoMenuItemEntity, 'ID' | 'startTime' | 'endTime'>
        & { outletDailyPromoMenuItemPrice?: Maybe<Array<(
          { __typename?: 'OutletDailyPromoMenuItemPriceEntity' }
          & Pick<OutletDailyPromoMenuItemPriceEntity, 'ID' | 'price' | 'patronID'>
        )>> }
      )>> }
    )>> }
  )> }
);

export type GetDailyMenuItemQueryVariables = Exact<{
  outletDailyMenuCategoryID?: Maybe<Scalars['String']>;
}>;


export type GetDailyMenuItemQuery = (
  { __typename?: 'Query' }
  & { getOutletDailyMenuItem: Array<(
    { __typename?: 'OutletDailyMenuItemEntity' }
    & Pick<OutletDailyMenuItemEntity, 'ID' | 'name' | 'description' | 'defaultQuantity' | 'menuItemID' | 'attachments'>
    & { outletDailyMenuItemOption?: Maybe<Array<(
      { __typename?: 'OutletDailyMenuItemOptionEntity' }
      & Pick<OutletDailyMenuItemOptionEntity, 'ID' | 'outletDailyMenuItemID' | 'maxSelections' | 'name' | 'optionID'>
      & { outletDailyMenuItemOptionItem?: Maybe<Array<(
        { __typename?: 'OutletDailyMenuItemOptionItemEntity' }
        & Pick<OutletDailyMenuItemOptionItemEntity, 'ID' | 'outletDailyMenuItemOptionID' | 'name' | 'price' | 'optionItemID'>
      )>> }
    )>> }
  )> }
);

export type GetDailyPatronPriceQueryVariables = Exact<{
  outletID?: Maybe<Scalars['String']>;
}>;


export type GetDailyPatronPriceQuery = (
  { __typename?: 'Query' }
  & { getOutletDailyMenuItemPatronPrice: Array<(
    { __typename?: 'OutletDailyMenuItemPatronPriceEntity' }
    & Pick<OutletDailyMenuItemPatronPriceEntity, 'ID' | 'outletID' | 'price' | 'name' | 'description' | 'patronClass' | 'outletDailyMenuItemID'>
  )> }
);

export type CreateOrderItemPatronAppMutationVariables = Exact<{
  orderInput: OrderItemInput;
  optionItemInput?: Maybe<Array<OrderItemOptionInput> | OrderItemOptionInput>;
}>;


export type CreateOrderItemPatronAppMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createOrderItemPatronApp'>
);

export type GetOutletVisitorPatronQueryVariables = Exact<{
  outletID: Scalars['String'];
}>;


export type GetOutletVisitorPatronQuery = (
  { __typename?: 'Query' }
  & { getOutletVisitorPatron: (
    { __typename?: 'PatronEntity' }
    & Pick<PatronEntity, 'ID'>
  ) }
);

export type AuditLogDetailsQueryVariables = Exact<{
  fieldID: Scalars['String'];
  tableName: Scalars['String'];
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
}>;


export type AuditLogDetailsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'auditLogDetails'>
);

export type GetOrderItemDiscountQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  orderItemID?: Maybe<Scalars['String']>;
}>;


export type GetOrderItemDiscountQuery = (
  { __typename?: 'Query' }
  & { getOrderItemDiscount: Array<(
    { __typename?: 'OrderItemDiscountEntity' }
    & Pick<OrderItemDiscountEntity, 'ID' | 'discountID' | 'orderItemID' | 'amount' | 'status' | 'reasonCode' | 'remark'>
  )> }
);

export type CreateOrderItemDiscountMutationVariables = Exact<{
  input: OrderItemDiscountInput;
  orderID: Scalars['String'];
}>;


export type CreateOrderItemDiscountMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createOrderItemDiscount'>
);

export type DeleteOrderItemDiscountMutationVariables = Exact<{
  input: OrderItemDiscountInput;
  orderID: Scalars['String'];
}>;


export type DeleteOrderItemDiscountMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteOrderItemDiscount'>
);

export type GetOrderDiscountQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  orderID?: Maybe<Scalars['String']>;
}>;


export type GetOrderDiscountQuery = (
  { __typename?: 'Query' }
  & { getOrderDiscount: Array<(
    { __typename?: 'OrderDiscountEntity' }
    & Pick<OrderDiscountEntity, 'ID' | 'discountedBy' | 'amount' | 'isActive' | 'discountID' | 'status' | 'orderID' | 'reasonCode' | 'remark'>
  )> }
);

export type GetBillDiscountQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
}>;


export type GetBillDiscountQuery = (
  { __typename?: 'Query' }
  & { getBillDiscount: Array<(
    { __typename?: 'BillDiscountEntity' }
    & Pick<BillDiscountEntity, 'ID' | 'discountID' | 'isActive' | 'amount' | 'businessDate'>
  )> }
);

export type CreateOrderDiscountMutationVariables = Exact<{
  input: OrderDiscountInput;
}>;


export type CreateOrderDiscountMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createOrderDiscount'>
);

export type DeleteOrderDiscountMutationVariables = Exact<{
  input: OrderDiscountInput;
}>;


export type DeleteOrderDiscountMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteOrderDiscount'>
);

export type GetBillDiscountOrderItemQueryVariables = Exact<{
  outletID: Scalars['String'];
  ID?: Maybe<Scalars['String']>;
  tableID?: Maybe<Scalars['String']>;
  status?: Maybe<OrderStatus>;
}>;


export type GetBillDiscountOrderItemQuery = (
  { __typename?: 'Query' }
  & { getOrder: Array<(
    { __typename?: 'OrderEntity' }
    & Pick<OrderEntity, 'ID' | 'docNo' | 'outletID' | 'tableID' | 'mealPeriodID' | 'patronID' | 'totalAmount' | 'outstandingAmount' | 'patronAccountID' | 'contactNo' | 'pickupTime' | 'serviceMode' | 'status'>
    & { bill?: Maybe<Array<(
      { __typename?: 'BillEntity' }
      & Pick<BillEntity, 'ID' | 'billNo' | 'businessDate'>
    )>>, table?: Maybe<(
      { __typename?: 'TableEntity' }
      & Pick<TableEntity, 'ID' | 'name' | 'seatCount' | 'prefix' | 'tableNumber' | 'status'>
    )>, orderDiscount?: Maybe<Array<(
      { __typename?: 'OrderDiscountEntity' }
      & Pick<OrderDiscountEntity, 'ID' | 'discountID' | 'orderID' | 'amount' | 'reasonCode' | 'remark'>
    )>> }
  )> }
);

export type GetFilteredDiscountQueryVariables = Exact<{
  outletID: Scalars['String'];
  amount: Scalars['Float'];
  isOrderItemDiscount: Scalars['Boolean'];
}>;


export type GetFilteredDiscountQuery = (
  { __typename?: 'Query' }
  & { getFilteredDiscount: Array<(
    { __typename?: 'DiscountEntity' }
    & Pick<DiscountEntity, 'ID' | 'code' | 'amount' | 'calcType' | 'description'>
  )> }
);

export type GetVoidedDiscountQueryVariables = Exact<{
  orderID: Scalars['String'];
  outletID: Scalars['String'];
}>;


export type GetVoidedDiscountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getVoidedDiscount'>
);

export type CreateBillAndPaymentMutationVariables = Exact<{
  orderItemIDs: Array<Scalars['String']> | Scalars['String'];
  paymentInput: PaymentInput;
  billInput: BillInput;
  isTakeaway?: Maybe<Scalars['Boolean']>;
}>;


export type CreateBillAndPaymentMutation = (
  { __typename?: 'Mutation' }
  & { createBillAndPayment: (
    { __typename?: 'BillEntity' }
    & Pick<BillEntity, 'ID'>
  ) }
);

export type GetBillQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
}>;


export type GetBillQuery = (
  { __typename?: 'Query' }
  & { getBill: Array<(
    { __typename?: 'BillEntity' }
    & Pick<BillEntity, 'ID' | 'outletID' | 'createdBy' | 'modBy' | 'createdTs' | 'billNo' | 'signature' | 'orderID' | 'status' | 'billPaymentType' | 'einvoice_qr_url' | 'is_einvoice' | 'einvoice_irbm_uniqueID' | 'einvoice_status' | 'qrCodeUrl' | 'documentType' | 'refID' | 'refBillNo' | 'isCreditNote'>
    & { outlet?: Maybe<(
      { __typename?: 'OutletEntity' }
      & Pick<OutletEntity, 'isInclusive' | 'name' | 'logo' | 'address' | 'contactNo' | 'currencyCode' | 'registrationNo' | 'taxSchemeID' | 'taxRegistrationNo'>
      & { outletChannel?: Maybe<(
        { __typename?: 'OutletChannelEntity' }
        & Pick<OutletChannelEntity, 'ID' | 'hotelID' | 'name'>
      )>, company?: Maybe<(
        { __typename?: 'CompanyEntity' }
        & Pick<CompanyEntity, 'id' | 'name' | 'description' | 'companyRegNo' | 'code' | 'contactNo' | 'email' | 'sstNo' | 'gstNo' | 'ttxNo' | 'gstExpiryDate' | 'eInvClientID' | 'eInvClientSecret' | 'eInvClientSecret2' | 'regionId' | 'companyTax' | 'recordStatus' | 'groupGstRegNo' | 'officeTel' | 'tin' | 'eSign' | 'eSignExpiryDate' | 'isEInvIntegrated' | 'eInvStartDate' | 'eInvEndDate'>
      )> }
    )>, order?: Maybe<(
      { __typename?: 'OrderEntity' }
      & Pick<OrderEntity, 'ID' | 'docNo' | 'patronAccountID' | 'pax' | 'taxSchemeInfo' | 'contactNo' | 'taxSchemeFooterInfo' | 'patronName' | 'patronID' | 'serviceMode' | 'totalDiscountAmount'>
      & { orderDiscount?: Maybe<Array<(
        { __typename?: 'OrderDiscountEntity' }
        & Pick<OrderDiscountEntity, 'ID' | 'discountID' | 'amount' | 'status' | 'reasonCode' | 'remark'>
      )>>, table?: Maybe<(
        { __typename?: 'TableEntity' }
        & Pick<TableEntity, 'ID' | 'tableNumber' | 'prefix'>
      )>, patron?: Maybe<(
        { __typename?: 'PatronEntity' }
        & Pick<PatronEntity, 'patronClass'>
      )>, orderItem?: Maybe<Array<(
        { __typename?: 'OrderItemEntity' }
        & Pick<OrderItemEntity, 'ID' | 'billID'>
      )>> }
    )>, payments?: Maybe<Array<(
      { __typename?: 'PaymentEntity' }
      & Pick<PaymentEntity, 'ID' | 'amount' | 'registrationID' | 'receivedAmount' | 'changeAmount' | 'roundingAmount' | 'businessDate' | 'cardNo' | 'reasonCode' | 'creditCardType' | 'paymentClass' | 'referenceNo' | 'patronAccountID' | 'hotelGuestInfo' | 'status' | 'remark' | 'createdTs'>
      & { defaultPayment?: Maybe<(
        { __typename?: 'DefaultPaymentEntity' }
        & Pick<DefaultPaymentEntity, 'ID' | 'paymentClass' | 'paymentTypeInfo'>
      )> }
    )>>, orderItem?: Maybe<Array<(
      { __typename?: 'OrderItemEntity' }
      & Pick<OrderItemEntity, 'ID' | 'amount' | 'refID' | 'serviceCharge' | 'taxAmount' | 'unitPrice' | 'isTakeaway' | 'weight' | 'unitPriceInfo' | 'totalInfo' | 'openItemName' | 'quantity' | 'baseAmount' | 'status'>
      & { orderItemTax?: Maybe<Array<(
        { __typename?: 'OrderItemTaxEntity' }
        & Pick<OrderItemTaxEntity, 'ID' | 'orderItemID' | 'taxSchemeID' | 'taxSchemeDetailID' | 'taxID' | 'taxRate' | 'taxAmount' | 'taxSchemeType'>
      )>>, orderItemDiscountTax?: Maybe<Array<(
        { __typename?: 'OrderItemDiscountTaxEntity' }
        & Pick<OrderItemDiscountTaxEntity, 'ID' | 'orderItemID' | 'orderItemDiscountID' | 'taxSchemeID' | 'taxSchemeDetailID' | 'taxID' | 'taxRate' | 'taxAmount'>
      )>>, orderDiscountItemTax?: Maybe<Array<(
        { __typename?: 'OrderDiscountItemTaxV1Entity' }
        & Pick<OrderDiscountItemTaxV1Entity, 'ID' | 'orderItemID' | 'orderDiscountItemID' | 'taxSchemeID' | 'taxSchemeDetailID' | 'taxID' | 'taxRate' | 'taxAmount'>
      )>>, orderItemDiscount?: Maybe<Array<(
        { __typename?: 'OrderItemDiscountEntity' }
        & Pick<OrderItemDiscountEntity, 'discountID' | 'orderItemID' | 'amount' | 'baseAmount' | 'serviceCharge' | 'taxAmount' | 'status' | 'reasonCode' | 'remark'>
        & { orderItemDiscountTax?: Maybe<Array<(
          { __typename?: 'OrderItemDiscountTaxEntity' }
          & Pick<OrderItemDiscountTaxEntity, 'ID' | 'orderItemID' | 'orderItemDiscountID' | 'taxSchemeID' | 'taxSchemeDetailID' | 'taxID' | 'taxRate' | 'taxAmount'>
        )>> }
      )>>, orderDiscountItem?: Maybe<(
        { __typename?: 'OrderDiscountItemEntity' }
        & Pick<OrderDiscountItemEntity, 'orderItemID' | 'amount' | 'baseAmount' | 'serviceCharge' | 'taxAmount'>
        & { orderDiscountItemTax?: Maybe<Array<(
          { __typename?: 'OrderDiscountItemTaxV1Entity' }
          & Pick<OrderDiscountItemTaxV1Entity, 'ID' | 'orderItemID' | 'orderDiscountItemID' | 'taxSchemeID' | 'taxSchemeDetailID' | 'taxID' | 'taxRate' | 'taxAmount'>
        )>> }
      )>, menuItem?: Maybe<(
        { __typename?: 'MenuItemEntity' }
        & Pick<MenuItemEntity, 'ID' | 'name'>
      )>, orderItemOption?: Maybe<Array<(
        { __typename?: 'OrderItemOptionEntity' }
        & Pick<OrderItemOptionEntity, 'ID' | 'name' | 'optionID' | 'orderItemID'>
        & { orderItemOptionItem?: Maybe<Array<(
          { __typename?: 'OrderItemOptionItemEntity' }
          & Pick<OrderItemOptionItemEntity, 'ID' | 'optionItemID' | 'price' | 'basePrice' | 'unitPrice' | 'taxAmount' | 'serviceCharge' | 'discountBaseAmount' | 'discountServiceCharge' | 'discountTaxAmount'>
          & { orderItemOptionItemTax?: Maybe<Array<(
            { __typename?: 'OrderItemOptionItemTaxEntity' }
            & Pick<OrderItemOptionItemTaxEntity, 'ID' | 'orderItemID' | 'orderItemOptionItemID' | 'taxSchemeID' | 'taxSchemeDetailID' | 'taxID' | 'taxRate' | 'taxAmount'>
          )>>, orderItemOptionItemDiscountTax?: Maybe<Array<(
            { __typename?: 'OrderItemOptionItemDiscountTaxEntity' }
            & Pick<OrderItemOptionItemDiscountTaxEntity, 'ID' | 'orderItemOptionItemID' | 'orderItemDiscountID' | 'taxSchemeID' | 'taxSchemeDetailID' | 'taxID' | 'taxRate' | 'taxAmount'>
          )>>, orderDiscountItemOptionTax?: Maybe<Array<(
            { __typename?: 'OrderDiscountItemOptionTaxEntity' }
            & Pick<OrderDiscountItemOptionTaxEntity, 'ID' | 'orderItemOptionItemID' | 'orderDiscountItemID' | 'taxSchemeID' | 'taxSchemeDetailID' | 'taxID' | 'taxRate' | 'taxAmount'>
          )>>, optionItem?: Maybe<(
            { __typename?: 'OptionItemEntity' }
            & Pick<OptionItemEntity, 'name'>
          )> }
        )>> }
      )>>, orderItemSetMenuOption?: Maybe<Array<(
        { __typename?: 'OrderItemSetMenuOptionEntity' }
        & Pick<OrderItemSetMenuOptionEntity, 'ID'>
        & { orderItemSetMenuOptionItem?: Maybe<Array<(
          { __typename?: 'OrderItemSetMenuOptionItemEntity' }
          & { menuItem?: Maybe<(
            { __typename?: 'MenuItemEntity' }
            & Pick<MenuItemEntity, 'name'>
          )>, setMenuOptionItem?: Maybe<(
            { __typename?: 'SetMenuOptionItemEntity' }
            & { menuItem?: Maybe<(
              { __typename?: 'MenuItemEntity' }
              & Pick<MenuItemEntity, 'name'>
            )> }
          )> }
        )>> }
      )>> }
    )>> }
  )> }
);

export type CreateReceiptAttachmentMutationVariables = Exact<{
  billID: Scalars['String'];
  outletID: Scalars['String'];
  file: Scalars['Upload'];
}>;


export type CreateReceiptAttachmentMutation = (
  { __typename?: 'Mutation' }
  & { createReceiptAttachment?: Maybe<(
    { __typename?: 'AttachmentEntity' }
    & Pick<AttachmentEntity, 'ID' | 'bucketFileName' | 'fileName' | 'fileURL'>
  )> }
);

export type CreatePaymentByQuantityMutationVariables = Exact<{
  paymentByQuantityInput: Array<PaymentByQuantityInput> | PaymentByQuantityInput;
  billInput: BillInput;
  paymentInput: Array<PaymentInput> | PaymentInput;
}>;


export type CreatePaymentByQuantityMutation = (
  { __typename?: 'Mutation' }
  & { paymentByQuantity: (
    { __typename?: 'BillEntity' }
    & Pick<BillEntity, 'ID'>
  ) }
);

export type CreateOnlinePaymentMutationVariables = Exact<{
  outletID: Scalars['String'];
  title: Scalars['String'];
  detail: Scalars['String'];
  payAmount: Scalars['Float'];
  redirectUrl: Scalars['String'];
}>;


export type CreateOnlinePaymentMutation = (
  { __typename?: 'Mutation' }
  & { createOnlinePayment?: Maybe<(
    { __typename?: 'CheckOut' }
    & Pick<CheckOut, 'code' | 'paySessionNo'>
    & { item?: Maybe<(
      { __typename?: 'item' }
      & Pick<Item, 'checkoutId' | 'url' | 'qrCodeUrl' | 'status'>
    )>, error?: Maybe<(
      { __typename?: 'Errors' }
      & Pick<Errors, 'code' | 'message' | 'debug'>
    )> }
  )> }
);

export type GetPatronAccountQueryVariables = Exact<{
  isMember?: Maybe<Scalars['Boolean']>;
}>;


export type GetPatronAccountQuery = (
  { __typename?: 'Query' }
  & { getPatronAccount: Array<(
    { __typename?: 'PatronAccountEntity' }
    & Pick<PatronAccountEntity, 'ID' | 'name' | 'status' | 'creditBalance' | 'hotelRoomID' | 'membershipID'>
    & { hotelRoom?: Maybe<(
      { __typename?: 'HotelRoomEntity' }
      & Pick<HotelRoomEntity, 'ID' | 'roomNo'>
    )>, membership?: Maybe<(
      { __typename?: 'MembershipEntity' }
      & Pick<MembershipEntity, 'ID' | 'memberNo' | 'description'>
    )> }
  )> }
);

export type GetMenuXInhouseListQueryVariables = Exact<{
  hotelID: Scalars['String'];
  outletID?: Maybe<Scalars['String']>;
}>;


export type GetMenuXInhouseListQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getMenuXInhouseList'>
);

export type GetMenuXInhouseStatusQueryVariables = Exact<{
  registrationID: Scalars['String'];
  outletID: Scalars['String'];
}>;


export type GetMenuXInhouseStatusQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getMenuXInhouseStatus'>
);

export type GetMenuXDebtorListQueryVariables = Exact<{
  hotelID: Scalars['String'];
  outletID?: Maybe<Scalars['String']>;
}>;


export type GetMenuXDebtorListQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getMenuXDebtorList'>
);

export type GetAttachmentQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
}>;


export type GetAttachmentQuery = (
  { __typename?: 'Query' }
  & { getAttachment: Array<(
    { __typename?: 'AttachmentEntity' }
    & Pick<AttachmentEntity, 'ID' | 'fileName' | 'refID' | 'description' | 'fileURL' | 'bucketFileName' | 'fileSize'>
  )> }
);

export type CreateAttachmentMutationVariables = Exact<{
  input: AttachmentInput;
}>;


export type CreateAttachmentMutation = (
  { __typename?: 'Mutation' }
  & { createAttachment: (
    { __typename?: 'AttachmentEntity' }
    & Pick<AttachmentEntity, 'ID'>
  ) }
);

export type UpdateAttachmentMutationVariables = Exact<{
  input: AttachmentInput;
}>;


export type UpdateAttachmentMutation = (
  { __typename?: 'Mutation' }
  & { updateAttachment: (
    { __typename?: 'AttachmentEntity' }
    & Pick<AttachmentEntity, 'ID'>
  ) }
);

export type AttachmentListingQueryVariables = Exact<{
  refID?: Maybe<Scalars['String']>;
}>;


export type AttachmentListingQuery = (
  { __typename?: 'Query' }
  & { attachmentListing?: Maybe<Array<(
    { __typename?: 'AttachmentEntity' }
    & Pick<AttachmentEntity, 'ID' | 'fileName' | 'fileURL' | 'mediaType' | 'createdTs' | 'description' | 'refID'>
  )>> }
);

export type GetDailyMenuItemAvailabilityQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  menuItemID?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
}>;


export type GetDailyMenuItemAvailabilityQuery = (
  { __typename?: 'Query' }
  & { getDailyMenuItemAvailability: Array<(
    { __typename?: 'DailyMenuItemAvailabilityEntity' }
    & Pick<DailyMenuItemAvailabilityEntity, 'ID' | 'menuItemID' | 'quantity' | 'outstandingQty'>
    & { menuItem?: Maybe<(
      { __typename?: 'MenuItemEntity' }
      & Pick<MenuItemEntity, 'name' | 'description' | 'defaultQuantity'>
    )> }
  )> }
);

export type CreateDailyMenuItemAvailabilityMutationVariables = Exact<{
  input: DailyMenuItemAvailabilityInput;
}>;


export type CreateDailyMenuItemAvailabilityMutation = (
  { __typename?: 'Mutation' }
  & { createDailyMenuItemAvailability: (
    { __typename?: 'DailyMenuItemAvailabilityEntity' }
    & Pick<DailyMenuItemAvailabilityEntity, 'ID'>
  ) }
);

export type UpdateDailyMenuItemAvailabilityMutationVariables = Exact<{
  input: DailyMenuItemAvailabilityInput;
}>;


export type UpdateDailyMenuItemAvailabilityMutation = (
  { __typename?: 'Mutation' }
  & { updateDailyMenuItemAvailability: (
    { __typename?: 'DailyMenuItemAvailabilityEntity' }
    & Pick<DailyMenuItemAvailabilityEntity, 'ID'>
  ) }
);

export type SubmitDailyMenuItemAvailabilityMutationVariables = Exact<{
  input: Array<DailyMenuItemAvailabilityInput> | DailyMenuItemAvailabilityInput;
  outletID: Scalars['String'];
}>;


export type SubmitDailyMenuItemAvailabilityMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'submitDailyMenuItemAvailability'>
);

export type GetDigitalMenuQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
}>;


export type GetDigitalMenuQuery = (
  { __typename?: 'Query' }
  & { getDigitalMenu: Array<(
    { __typename?: 'DigitalMenuEntity' }
    & Pick<DigitalMenuEntity, 'ID' | 'outletID' | 'name' | 'description' | 'modTs' | 'createdTs'>
  )> }
);

export type GetOutletDigitalMenuQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
}>;


export type GetOutletDigitalMenuQuery = (
  { __typename?: 'Query' }
  & { getDigitalMenu: Array<(
    { __typename?: 'DigitalMenuEntity' }
    & Pick<DigitalMenuEntity, 'ID' | 'outletID' | 'name' | 'description' | 'modTs' | 'createdTs'>
  )> }
);

export type GetScheduledDigitalMenuQueryVariables = Exact<{
  mealPeriodID: Scalars['String'];
  outletID: Scalars['String'];
}>;


export type GetScheduledDigitalMenuQuery = (
  { __typename?: 'Query' }
  & { getScheduledDigitalMenu: Array<(
    { __typename?: 'OutletDailyMenuCategoryEntity' }
    & Pick<OutletDailyMenuCategoryEntity, 'ID' | 'outletID' | 'name' | 'modTs' | 'createdTs'>
  )> }
);

export type GetScheduledDigitalMenuCategoryQueryVariables = Exact<{
  mealPeriodID: Scalars['String'];
}>;


export type GetScheduledDigitalMenuCategoryQuery = (
  { __typename?: 'Query' }
  & { getScheduledDigitalMenuCategory: Array<(
    { __typename?: 'DigitalMenuCategoryEntity' }
    & Pick<DigitalMenuCategoryEntity, 'ID' | 'outletID' | 'digitalMenuID' | 'priority' | 'name' | 'modTs' | 'createdTs'>
    & { digitalMenuItem?: Maybe<Array<(
      { __typename?: 'DigitalMenuItemEntity' }
      & Pick<DigitalMenuItemEntity, 'ID' | 'menuItemID'>
      & { menuItem?: Maybe<(
        { __typename?: 'MenuItemEntity' }
        & Pick<MenuItemEntity, 'ID' | 'name' | 'description' | 'attachments'>
      )>, digitalMenuItemPrice?: Maybe<Array<(
        { __typename?: 'DigitalMenuItemPriceEntity' }
        & Pick<DigitalMenuItemPriceEntity, 'price'>
      )>> }
    )>> }
  )> }
);

export type CreateDigitalMenuMutationVariables = Exact<{
  input: DigitalMenuInput;
}>;


export type CreateDigitalMenuMutation = (
  { __typename?: 'Mutation' }
  & { createDigitalMenu: (
    { __typename?: 'DigitalMenuEntity' }
    & Pick<DigitalMenuEntity, 'ID'>
  ) }
);

export type DeleteDigitalMenuMutationVariables = Exact<{
  ID: Scalars['String'];
}>;


export type DeleteDigitalMenuMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteDigitalMenu'>
);

export type UpdateDigitalMenuMutationVariables = Exact<{
  input: DigitalMenuInput;
}>;


export type UpdateDigitalMenuMutation = (
  { __typename?: 'Mutation' }
  & { updateDigitalMenu: (
    { __typename?: 'DigitalMenuEntity' }
    & Pick<DigitalMenuEntity, 'ID'>
  ) }
);

export type GetDigitalMenuCategoryQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  digitalMenuID?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
}>;


export type GetDigitalMenuCategoryQuery = (
  { __typename?: 'Query' }
  & { getDigitalMenuCategory: Array<(
    { __typename?: 'DigitalMenuCategoryEntity' }
    & Pick<DigitalMenuCategoryEntity, 'ID' | 'outletID' | 'digitalMenuID' | 'priority' | 'name' | 'modTs' | 'createdTs'>
    & { digitalMenuItem?: Maybe<Array<(
      { __typename?: 'DigitalMenuItemEntity' }
      & Pick<DigitalMenuItemEntity, 'ID' | 'menuItemID'>
      & { menuItem?: Maybe<(
        { __typename?: 'MenuItemEntity' }
        & Pick<MenuItemEntity, 'ID' | 'name'>
        & { stopSales?: Maybe<(
          { __typename?: 'StopSalesEntity' }
          & Pick<StopSalesEntity, 'ID' | 'isActive' | 'menuItemID'>
        )> }
      )> }
    )>> }
  )> }
);

export type GetDigitalMenuCategoryForPriceQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
}>;


export type GetDigitalMenuCategoryForPriceQuery = (
  { __typename?: 'Query' }
  & { getDigitalMenuCategory: Array<(
    { __typename?: 'DigitalMenuCategoryEntity' }
    & Pick<DigitalMenuCategoryEntity, 'ID' | 'createdTs' | 'digitalMenuID' | 'name'>
    & { digitalMenuItem?: Maybe<Array<(
      { __typename?: 'DigitalMenuItemEntity' }
      & Pick<DigitalMenuItemEntity, 'ID' | 'digitalMenuCategoryID' | 'menuItemID'>
      & { menuItem?: Maybe<(
        { __typename?: 'MenuItemEntity' }
        & Pick<MenuItemEntity, 'ID' | 'name' | 'description' | 'attachments'>
        & { stopSales?: Maybe<(
          { __typename?: 'StopSalesEntity' }
          & Pick<StopSalesEntity, 'ID' | 'isActive' | 'menuItemID'>
        )> }
      )>, digitalMenuItemPrice?: Maybe<Array<(
        { __typename?: 'DigitalMenuItemPriceEntity' }
        & Pick<DigitalMenuItemPriceEntity, 'ID' | 'digitalMenuItemID' | 'price' | 'patronID'>
        & { digitalMenuItem?: Maybe<(
          { __typename?: 'DigitalMenuItemEntity' }
          & Pick<DigitalMenuItemEntity, 'ID' | 'menuItemID'>
          & { menuItem?: Maybe<(
            { __typename?: 'MenuItemEntity' }
            & Pick<MenuItemEntity, 'name'>
          )> }
        )>, patron?: Maybe<(
          { __typename?: 'PatronEntity' }
          & Pick<PatronEntity, 'ID' | 'patronClass' | 'isActive'>
          & { patronType?: Maybe<(
            { __typename?: 'PatronTypeEntity' }
            & Pick<PatronTypeEntity, 'ID' | 'name'>
          )> }
        )> }
      )>> }
    )>> }
  )> }
);

export type GetScheduledCategoryWithItemsQueryVariables = Exact<{
  mealPeriodID: Scalars['String'];
}>;


export type GetScheduledCategoryWithItemsQuery = (
  { __typename?: 'Query' }
  & { getScheduledDigitalMenuCategory: Array<(
    { __typename?: 'DigitalMenuCategoryEntity' }
    & Pick<DigitalMenuCategoryEntity, 'ID' | 'outletID' | 'digitalMenuID' | 'priority' | 'name' | 'modTs' | 'createdTs'>
    & { digitalMenuItem?: Maybe<Array<(
      { __typename?: 'DigitalMenuItemEntity' }
      & Pick<DigitalMenuItemEntity, 'ID' | 'menuItemID'>
      & { menuItem?: Maybe<(
        { __typename?: 'MenuItemEntity' }
        & Pick<MenuItemEntity, 'ID' | 'name' | 'description' | 'attachments'>
        & { menuItemOptions?: Maybe<Array<(
          { __typename?: 'MenuItemOptionEntity' }
          & Pick<MenuItemOptionEntity, 'ID' | 'menuItemID' | 'optionID' | 'maxSelections'>
          & { menuItemOptionItem?: Maybe<Array<(
            { __typename?: 'MenuItemOptionItemEntity' }
            & Pick<MenuItemOptionItemEntity, 'optionItemID' | 'ID' | 'price'>
          )>>, option?: Maybe<(
            { __typename?: 'OptionEntity' }
            & Pick<OptionEntity, 'ID' | 'name'>
            & { optionItem?: Maybe<Array<(
              { __typename?: 'OptionItemEntity' }
              & Pick<OptionItemEntity, 'ID' | 'name'>
            )>> }
          )> }
        )>> }
      )>, digitalMenuItemPrice?: Maybe<Array<(
        { __typename?: 'DigitalMenuItemPriceEntity' }
        & Pick<DigitalMenuItemPriceEntity, 'ID' | 'digitalMenuItemID' | 'price' | 'patronID'>
        & { digitalMenuItem?: Maybe<(
          { __typename?: 'DigitalMenuItemEntity' }
          & Pick<DigitalMenuItemEntity, 'ID' | 'menuItemID'>
          & { menuItem?: Maybe<(
            { __typename?: 'MenuItemEntity' }
            & Pick<MenuItemEntity, 'name'>
          )> }
        )>, patron?: Maybe<(
          { __typename?: 'PatronEntity' }
          & Pick<PatronEntity, 'ID' | 'patronClass' | 'isActive'>
          & { patronType?: Maybe<(
            { __typename?: 'PatronTypeEntity' }
            & Pick<PatronTypeEntity, 'ID' | 'name'>
          )> }
        )> }
      )>> }
    )>> }
  )> }
);

export type GetDigitalMenuCategoryForMenuOptionItemQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  digitalMenuID?: Maybe<Scalars['String']>;
}>;


export type GetDigitalMenuCategoryForMenuOptionItemQuery = (
  { __typename?: 'Query' }
  & { getDigitalMenuCategory: Array<(
    { __typename?: 'DigitalMenuCategoryEntity' }
    & Pick<DigitalMenuCategoryEntity, 'ID' | 'createdTs' | 'digitalMenuID' | 'name'>
    & { digitalMenuItem?: Maybe<Array<(
      { __typename?: 'DigitalMenuItemEntity' }
      & Pick<DigitalMenuItemEntity, 'ID' | 'digitalMenuCategoryID' | 'menuItemID'>
      & { menuItem?: Maybe<(
        { __typename?: 'MenuItemEntity' }
        & Pick<MenuItemEntity, 'ID' | 'name' | 'description' | 'attachments'>
        & { menuItemOptions?: Maybe<Array<(
          { __typename?: 'MenuItemOptionEntity' }
          & Pick<MenuItemOptionEntity, 'ID' | 'menuItemID' | 'optionID'>
          & { option?: Maybe<(
            { __typename?: 'OptionEntity' }
            & Pick<OptionEntity, 'ID' | 'name'>
          )>, menuItemOptionItem?: Maybe<Array<(
            { __typename?: 'MenuItemOptionItemEntity' }
            & Pick<MenuItemOptionItemEntity, 'ID'>
            & { optionItem?: Maybe<(
              { __typename?: 'OptionItemEntity' }
              & Pick<OptionItemEntity, 'ID' | 'name'>
            )> }
          )>> }
        )>> }
      )>, digitalMenuItemPrice?: Maybe<Array<(
        { __typename?: 'DigitalMenuItemPriceEntity' }
        & Pick<DigitalMenuItemPriceEntity, 'ID' | 'digitalMenuItemID' | 'price' | 'patronID'>
        & { digitalMenuItem?: Maybe<(
          { __typename?: 'DigitalMenuItemEntity' }
          & Pick<DigitalMenuItemEntity, 'ID' | 'menuItemID'>
          & { menuItem?: Maybe<(
            { __typename?: 'MenuItemEntity' }
            & Pick<MenuItemEntity, 'name'>
          )> }
        )>, patron?: Maybe<(
          { __typename?: 'PatronEntity' }
          & Pick<PatronEntity, 'ID' | 'patronClass' | 'isActive'>
          & { patronType?: Maybe<(
            { __typename?: 'PatronTypeEntity' }
            & Pick<PatronTypeEntity, 'ID' | 'name'>
          )> }
        )> }
      )>> }
    )>> }
  )> }
);

export type DigitalMenuPriorityAndNameQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
}>;


export type DigitalMenuPriorityAndNameQuery = (
  { __typename?: 'Query' }
  & { getDigitalMenuCategory: Array<(
    { __typename?: 'DigitalMenuCategoryEntity' }
    & Pick<DigitalMenuCategoryEntity, 'ID' | 'priority' | 'name'>
  )> }
);

export type CreateDigitalMenuCategoryMutationVariables = Exact<{
  input: DigitalMenuCategoryInput;
}>;


export type CreateDigitalMenuCategoryMutation = (
  { __typename?: 'Mutation' }
  & { createDigitalMenuCategory: (
    { __typename?: 'DigitalMenuCategoryEntity' }
    & Pick<DigitalMenuCategoryEntity, 'ID'>
  ) }
);

export type UpdateDigitalMenuCategoryMutationVariables = Exact<{
  input: DigitalMenuCategoryInput;
}>;


export type UpdateDigitalMenuCategoryMutation = (
  { __typename?: 'Mutation' }
  & { updateDigitalMenuCategory: (
    { __typename?: 'DigitalMenuCategoryEntity' }
    & Pick<DigitalMenuCategoryEntity, 'ID'>
  ) }
);

export type DeleteDigitalMenuCategoryMutationVariables = Exact<{
  ID: Scalars['String'];
}>;


export type DeleteDigitalMenuCategoryMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteDigitalMenuCategory'>
);

export type GetDigitalMenuItemQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  digitalMenuCategoryID?: Maybe<Scalars['String']>;
}>;


export type GetDigitalMenuItemQuery = (
  { __typename?: 'Query' }
  & { getDigitalMenuItem: Array<(
    { __typename?: 'DigitalMenuItemEntity' }
    & Pick<DigitalMenuItemEntity, 'ID' | 'menuItemID' | 'digitalMenuCategoryID'>
    & { digitalMenuCategory?: Maybe<(
      { __typename?: 'DigitalMenuCategoryEntity' }
      & Pick<DigitalMenuCategoryEntity, 'ID' | 'name' | 'priority'>
    )>, menuItem?: Maybe<(
      { __typename?: 'MenuItemEntity' }
      & Pick<MenuItemEntity, 'ID' | 'name'>
    )> }
  )> }
);

export type CreateDigitalMenuItemPriceMutationVariables = Exact<{
  outletID: Scalars['String'];
  digitalMenuCategoryID: Scalars['String'];
  digitalMenuItemInput: Array<DigitalMenuItemInput> | DigitalMenuItemInput;
}>;


export type CreateDigitalMenuItemPriceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createDigitalMenuItemPrice'>
);

export type UpdateDigitalMenuItemPriceMutationVariables = Exact<{
  outletID: Scalars['String'];
  digitalMenuItemID: Scalars['String'];
  digitalMenuItemPriceInput: Array<DigitalMenuItemPriceInput> | DigitalMenuItemPriceInput;
}>;


export type UpdateDigitalMenuItemPriceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateDigitalMenuItemPrice'>
);

export type DeleteDigitalMenuItemAndPriceMutationVariables = Exact<{
  digitalMenuItemID: Scalars['String'];
}>;


export type DeleteDigitalMenuItemAndPriceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteDigitalMenuItemAndPrice'>
);

export type GetRemainingMenuItemsForDigitalQueryVariables = Exact<{
  digitalMenuCategoryID: Scalars['String'];
  outletID: Scalars['String'];
}>;


export type GetRemainingMenuItemsForDigitalQuery = (
  { __typename?: 'Query' }
  & { getMenuItemForDigitalMenuItemPrice: Array<(
    { __typename?: 'MenuItemEntity' }
    & Pick<MenuItemEntity, 'ID' | 'name' | 'description' | 'kitchenName' | 'nativeName'>
    & { stopSales?: Maybe<(
      { __typename?: 'StopSalesEntity' }
      & Pick<StopSalesEntity, 'ID' | 'isActive' | 'menuItemID'>
    )>, setMenuInfo?: Maybe<Array<(
      { __typename?: 'SetMenuInfo' }
      & Pick<SetMenuInfo, 'menuItemID' | 'setMenuID' | 'setMenuName'>
    )>> }
  )> }
);

export type DuplicateDigitalMenuMutationVariables = Exact<{
  digitalMenuID: Scalars['String'];
  outletID: Scalars['String'];
}>;


export type DuplicateDigitalMenuMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'duplicateDigitalMenu'>
);

export type IsDigitalMenuInUseQueryVariables = Exact<{
  outletID: Scalars['String'];
  digitalMenuID: Scalars['String'];
}>;


export type IsDigitalMenuInUseQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'isDigitalMenuInUse'>
);

export type IsDigitalMenuCategoryInUseQueryVariables = Exact<{
  outletID: Scalars['String'];
  digitalMenuCategoryID: Scalars['String'];
}>;


export type IsDigitalMenuCategoryInUseQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'isDigitalMenuCategoryInUse'>
);

export type GetDiscountQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
}>;


export type GetDiscountQuery = (
  { __typename?: 'Query' }
  & { getDiscount: Array<(
    { __typename?: 'DiscountEntity' }
    & Pick<DiscountEntity, 'ID' | 'code' | 'amount' | 'calcType' | 'discountType' | 'description' | 'discountCheckingInUseInfo'>
  )> }
);

export type CreateDiscountMutationVariables = Exact<{
  input: DiscountInput;
}>;


export type CreateDiscountMutation = (
  { __typename?: 'Mutation' }
  & { createDiscount: (
    { __typename?: 'DiscountEntity' }
    & Pick<DiscountEntity, 'ID'>
  ) }
);

export type UpdateDiscountMutationVariables = Exact<{
  input: DiscountInput;
}>;


export type UpdateDiscountMutation = (
  { __typename?: 'Mutation' }
  & { updateDiscount: (
    { __typename?: 'DiscountEntity' }
    & Pick<DiscountEntity, 'ID'>
  ) }
);

export type DeleteDiscountMutationVariables = Exact<{
  ID: Scalars['String'];
}>;


export type DeleteDiscountMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteDiscount'>
);

export type GetOutletSettingSummaryQueryVariables = Exact<{
  outletID: Scalars['String'];
}>;


export type GetOutletSettingSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'outletProfileDate' | 'outletPolicyDate' | 'outletPolicyDateNew' | 'tableCount' | 'qrCount' | 'kitchenPrinterCount' | 'receiptPrinterCount' | 'mealPeriodCount' | 'majorFamilyCount' | 'standardMenuOptionCount' | 'menuItemsCount' | 'setMenuCount' | 'digitalMenuPricingCount' | 'menuSchedulingCount' | 'promotionalMenuPricingCount' | 'dailyMenuItemAvailabilityCount' | 'promoCodeCount' | 'discountTypeCount' | 'stopSalesItemCount' | 'paymentTypeDate'>
);

export type SyncOutletDailyMenuMutationVariables = Exact<{
  outletID: Scalars['String'];
}>;


export type SyncOutletDailyMenuMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'syncOutletDailyMenu'>
);

export type DuplicateOutletMenuMutationVariables = Exact<{
  sourceOutletID: Scalars['String'];
  targetOutletID: Scalars['String'];
}>;


export type DuplicateOutletMenuMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'duplicateOutletMenu'>
);

export type GetKitchenPrinterQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
}>;


export type GetKitchenPrinterQuery = (
  { __typename?: 'Query' }
  & { getKitchenPrinter: Array<(
    { __typename?: 'KitchenPrinterEntity' }
    & Pick<KitchenPrinterEntity, 'ID' | 'name' | 'description' | 'address' | 'outletID'>
  )> }
);

export type CreateKitchenPrinterMutationVariables = Exact<{
  input: KitchenPrinterInput;
}>;


export type CreateKitchenPrinterMutation = (
  { __typename?: 'Mutation' }
  & { createKitchenPrinter: (
    { __typename?: 'KitchenPrinterEntity' }
    & Pick<KitchenPrinterEntity, 'ID'>
  ) }
);

export type UpdateKitchenPrinterMutationVariables = Exact<{
  input: KitchenPrinterInput;
}>;


export type UpdateKitchenPrinterMutation = (
  { __typename?: 'Mutation' }
  & { updateKitchenPrinter: (
    { __typename?: 'KitchenPrinterEntity' }
    & Pick<KitchenPrinterEntity, 'ID'>
  ) }
);

export type DeleteKitchenPrinterMutationVariables = Exact<{
  ID: Scalars['String'];
}>;


export type DeleteKitchenPrinterMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteKitchenPrinter'>
);

export type GetMajorQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
}>;


export type GetMajorQuery = (
  { __typename?: 'Query' }
  & { getMajor: Array<(
    { __typename?: 'MajorEntity' }
    & Pick<MajorEntity, 'ID' | 'name' | 'description' | 'eInvClassification' | 'outletID' | 'priority' | 'msicCode' | 'msicDesc'>
    & { family?: Maybe<Array<(
      { __typename?: 'FamilyEntity' }
      & Pick<FamilyEntity, 'ID' | 'name' | 'majorID'>
    )>> }
  )> }
);

export type CreateMajorMutationVariables = Exact<{
  input: MajorInput;
}>;


export type CreateMajorMutation = (
  { __typename?: 'Mutation' }
  & { createMajor: (
    { __typename?: 'MajorEntity' }
    & Pick<MajorEntity, 'ID'>
  ) }
);

export type CreateMajorWithGlMutationVariables = Exact<{
  input: MajorInput;
}>;


export type CreateMajorWithGlMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createMajorWithGL'>
);

export type UpdateMajorMutationVariables = Exact<{
  input: MajorInput;
}>;


export type UpdateMajorMutation = (
  { __typename?: 'Mutation' }
  & { updateMajor: (
    { __typename?: 'MajorEntity' }
    & Pick<MajorEntity, 'ID'>
  ) }
);

export type UpdateMajorWithGlMutationVariables = Exact<{
  input: MajorInput;
}>;


export type UpdateMajorWithGlMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateMajorWithGL'>
);

export type DeleteMajorWithGlMutationVariables = Exact<{
  ID: Scalars['String'];
}>;


export type DeleteMajorWithGlMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteMajorWithGL'>
);

export type DragMajorMutationVariables = Exact<{
  input: Array<MajorInput> | MajorInput;
  outletID: Scalars['String'];
}>;


export type DragMajorMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'dragMajor'>
);

export type GetFamilyQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  majorID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
}>;


export type GetFamilyQuery = (
  { __typename?: 'Query' }
  & { getFamily: Array<(
    { __typename?: 'FamilyEntity' }
    & Pick<FamilyEntity, 'ID' | 'name' | 'description' | 'majorID' | 'modTs' | 'createdTs' | 'createdBy'>
  )> }
);

export type CreateFamilyMutationVariables = Exact<{
  input: FamilyInput;
}>;


export type CreateFamilyMutation = (
  { __typename?: 'Mutation' }
  & { createFamily: (
    { __typename?: 'FamilyEntity' }
    & Pick<FamilyEntity, 'ID'>
  ) }
);

export type UpdateFamilyMutationVariables = Exact<{
  input: FamilyInput;
}>;


export type UpdateFamilyMutation = (
  { __typename?: 'Mutation' }
  & { updateFamily: (
    { __typename?: 'FamilyEntity' }
    & Pick<FamilyEntity, 'ID'>
  ) }
);

export type DeleteFamilyMutationVariables = Exact<{
  ID: Scalars['String'];
}>;


export type DeleteFamilyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteFamily'>
);

export type GetClassificationListingQueryVariables = Exact<{ [key: string]: never; }>;


export type GetClassificationListingQuery = (
  { __typename?: 'Query' }
  & { getClassificationListing: Array<(
    { __typename?: 'eInvoiceClassificationEntity' }
    & Pick<EInvoiceClassificationEntity, 'id' | 'classificationCode' | 'description'>
  )> }
);

export type GetMealPeriodQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  menus?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
}>;


export type GetMealPeriodQuery = (
  { __typename?: 'Query' }
  & { getMealPeriod: Array<(
    { __typename?: 'MealPeriodEntity' }
    & Pick<MealPeriodEntity, 'ID' | 'name' | 'startTime' | 'endTime' | 'outletID' | 'isActive'>
  )> }
);

export type GetCurrentMealPeriodQueryVariables = Exact<{
  outletID: Scalars['String'];
}>;


export type GetCurrentMealPeriodQuery = (
  { __typename?: 'Query' }
  & { getCurrentMealPeriod: (
    { __typename?: 'MealPeriodEntity' }
    & Pick<MealPeriodEntity, 'ID' | 'name' | 'startTime' | 'endTime' | 'outletID' | 'isActive'>
  ) }
);

export type CreateMealPeriodMutationVariables = Exact<{
  input: MealPeriodInput;
}>;


export type CreateMealPeriodMutation = (
  { __typename?: 'Mutation' }
  & { createMealPeriod: (
    { __typename?: 'MealPeriodEntity' }
    & Pick<MealPeriodEntity, 'ID'>
  ) }
);

export type UpdateMealPeriodMutationVariables = Exact<{
  input: MealPeriodInput;
}>;


export type UpdateMealPeriodMutation = (
  { __typename?: 'Mutation' }
  & { updateMealPeriod: (
    { __typename?: 'MealPeriodEntity' }
    & Pick<MealPeriodEntity, 'ID' | 'name' | 'startTime' | 'endTime'>
  ) }
);

export type DeleteMealPeriodMutationVariables = Exact<{
  ID: Scalars['String'];
}>;


export type DeleteMealPeriodMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteMealPeriod'>
);

export type GetAllMealPeriodQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllMealPeriodQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getAllMealPeriod'>
);

export type GetMenuItemQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  isSetMenu?: Maybe<Scalars['Boolean']>;
  outletID?: Maybe<Scalars['String']>;
  isQuantityControlled?: Maybe<Scalars['Boolean']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
}>;


export type GetMenuItemQuery = (
  { __typename?: 'Query' }
  & { getMenuItem: Array<(
    { __typename?: 'MenuItemEntity' }
    & Pick<MenuItemEntity, 'ID' | 'outletID' | 'name' | 'description' | 'kitchenName' | 'nativeName' | 'defaultQuantity' | 'isActive' | 'taxSchemeID' | 'isQuantityControlled' | 'isChefRecommended' | 'isTaxable' | 'isServiceChargeable' | 'isDiscountable' | 'isSetMenu' | 'isByWeight' | 'isOpenPrice' | 'isOpenItem' | 'isPerforated' | 'perforatedQuantity' | 'uomID' | 'majorID' | 'familyID' | 'kitchenPrinterID' | 'kitchenPrinter2ID' | 'attachments'>
    & { dailyAvailability?: Maybe<(
      { __typename?: 'DailyMenuItemAvailabilityEntity' }
      & Pick<DailyMenuItemAvailabilityEntity, 'ID' | 'quantity' | 'outstandingQty'>
    )>, menuItemOptions?: Maybe<Array<(
      { __typename?: 'MenuItemOptionEntity' }
      & Pick<MenuItemOptionEntity, 'ID' | 'maxSelections' | 'optionID' | 'menuItemID'>
      & { menuItemOptionItem?: Maybe<Array<(
        { __typename?: 'MenuItemOptionItemEntity' }
        & Pick<MenuItemOptionItemEntity, 'ID' | 'price' | 'optionItemID' | 'menuItemOptionID'>
        & { optionItem?: Maybe<(
          { __typename?: 'OptionItemEntity' }
          & Pick<OptionItemEntity, 'ID' | 'name'>
        )> }
      )>> }
    )>> }
  )> }
);

export type CreateMenuItemMutationVariables = Exact<{
  input: MenuItemInput;
}>;


export type CreateMenuItemMutation = (
  { __typename?: 'Mutation' }
  & { createMenuItem: (
    { __typename?: 'MenuItemEntity' }
    & Pick<MenuItemEntity, 'ID' | 'name' | 'nativeName' | 'kitchenName' | 'description' | 'uomID'>
  ) }
);

export type UpdateMenuItemMutationVariables = Exact<{
  input: MenuItemInput;
}>;


export type UpdateMenuItemMutation = (
  { __typename?: 'Mutation' }
  & { updateMenuItem: (
    { __typename?: 'MenuItemEntity' }
    & Pick<MenuItemEntity, 'ID' | 'outletID' | 'name' | 'description' | 'kitchenName' | 'isActive' | 'isQuantityControlled' | 'nativeName' | 'defaultQuantity' | 'isChefRecommended' | 'isTaxable' | 'isServiceChargeable' | 'isDiscountable' | 'isSetMenu' | 'isByWeight' | 'isOpenPrice' | 'isOpenItem' | 'uomID' | 'majorID'>
  ) }
);

export type CreateMenuItemWithOptionsMutationVariables = Exact<{
  menuItemInput: MenuItemInput;
  menuItemOptionInput?: Maybe<Array<MenuItemOptionInput> | MenuItemOptionInput>;
}>;


export type CreateMenuItemWithOptionsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createMenuItemWithOptions'>
);

export type UpdateMenuItemWithOptionsMutationVariables = Exact<{
  menuItemInput: MenuItemInput;
  menuItemOptionInput?: Maybe<Array<MenuItemOptionInput> | MenuItemOptionInput>;
}>;


export type UpdateMenuItemWithOptionsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateMenuItemWithOptions'>
);

export type DeleteMenuItemMutationVariables = Exact<{
  ID: Scalars['String'];
}>;


export type DeleteMenuItemMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteMenuItem'>
);

export type GetScheduledMenuQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  outletID: Scalars['String'];
}>;


export type GetScheduledMenuQuery = (
  { __typename?: 'Query' }
  & { getScheduledMenu: Array<(
    { __typename?: 'ScheduledMenuEntity' }
    & Pick<ScheduledMenuEntity, 'ID' | 'name' | 'effectiveTs' | 'scheduledDigitalMenuMealPeriod'>
  )> }
);

export type CreateScheduledMenuMutationVariables = Exact<{
  input: ScheduledMenuInput;
}>;


export type CreateScheduledMenuMutation = (
  { __typename?: 'Mutation' }
  & { createScheduledMenu: (
    { __typename?: 'ScheduledMenuEntity' }
    & Pick<ScheduledMenuEntity, 'ID'>
  ) }
);

export type UpdateScheduledMenuMutationVariables = Exact<{
  input: ScheduledMenuInput;
}>;


export type UpdateScheduledMenuMutation = (
  { __typename?: 'Mutation' }
  & { updateScheduledMenu: (
    { __typename?: 'ScheduledMenuEntity' }
    & Pick<ScheduledMenuEntity, 'ID'>
  ) }
);

export type CreateScheduledMenuAndPeriodsMutationVariables = Exact<{
  scheduledMenuInput: ScheduledMenuInput;
  scheduledMenuMealPeriodInput: Array<ScheduledMenuMealPeriodInput> | ScheduledMenuMealPeriodInput;
}>;


export type CreateScheduledMenuAndPeriodsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createScheduledMenuAndPeriods'>
);

export type UpdateScheduledMenuMealPeriodsMutationVariables = Exact<{
  scheduledMenuInput: ScheduledMenuInput;
  scheduledMenuMealPeriodInput: Array<ScheduledMenuMealPeriodInput> | ScheduledMenuMealPeriodInput;
}>;


export type UpdateScheduledMenuMealPeriodsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateScheduledMenuMealPeriods'>
);

export type DeleteScheduledMenuMealPeriodsMutationVariables = Exact<{
  scheduledMenuID: Scalars['String'];
}>;


export type DeleteScheduledMenuMealPeriodsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteScheduledMenuMealPeriods'>
);

export type SubmitOutletCustomerPolicyMutationVariables = Exact<{
  outletCustomerPolicyInput: OutletCustomerPolicyInput;
}>;


export type SubmitOutletCustomerPolicyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'submitOutletCustomerPolicy'>
);

export type GetOutletCustomerPolicyQueryVariables = Exact<{
  outletID: Scalars['String'];
}>;


export type GetOutletCustomerPolicyQuery = (
  { __typename?: 'Query' }
  & { getOutletCustomerPolicy: Array<(
    { __typename?: 'OutletCustomerPolicyEntity' }
    & Pick<OutletCustomerPolicyEntity, 'ID' | 'outletID' | 'selfOrder' | 'selfPayment' | 'qrCodeGenerate'>
  )> }
);

export type GetPatronQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
}>;


export type GetPatronQuery = (
  { __typename?: 'Query' }
  & { getPatron: Array<(
    { __typename?: 'PatronEntity' }
    & Pick<PatronEntity, 'ID' | 'isActive' | 'outletID' | 'patronTypeID' | 'patronClass'>
    & { patronType?: Maybe<(
      { __typename?: 'PatronTypeEntity' }
      & Pick<PatronTypeEntity, 'ID' | 'name' | 'description' | 'patronClass'>
    )> }
  )> }
);

export type GetPatronTypeQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
}>;


export type GetPatronTypeQuery = (
  { __typename?: 'Query' }
  & { getPatronType: Array<(
    { __typename?: 'PatronTypeEntity' }
    & Pick<PatronTypeEntity, 'ID' | 'name' | 'description' | 'patronClass'>
  )> }
);

export type CreatePatronMutationVariables = Exact<{
  input: PatronInput;
}>;


export type CreatePatronMutation = (
  { __typename?: 'Mutation' }
  & { createPatron: (
    { __typename?: 'PatronEntity' }
    & Pick<PatronEntity, 'ID'>
  ) }
);

export type SubmitOutletPatronSetupMutationVariables = Exact<{
  outletID: Scalars['String'];
  patronIDs: Array<Scalars['String']> | Scalars['String'];
}>;


export type SubmitOutletPatronSetupMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'submitOutletPatronSetup'>
);

export type UpdatePatronMutationVariables = Exact<{
  input: PatronInput;
}>;


export type UpdatePatronMutation = (
  { __typename?: 'Mutation' }
  & { updatePatron: (
    { __typename?: 'PatronEntity' }
    & Pick<PatronEntity, 'ID'>
  ) }
);

export type GetOutletPolicySummaryQueryVariables = Exact<{
  outletID: Scalars['String'];
}>;


export type GetOutletPolicySummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'patronSetupDate' | 'taxSetupDate' | 'reasonSetupDate' | 'customerSetupDate'>
);

export type GetOutletTaxPolicyQueryVariables = Exact<{
  outletID?: Maybe<Scalars['String']>;
  taxType?: Maybe<TaxType>;
}>;


export type GetOutletTaxPolicyQuery = (
  { __typename?: 'Query' }
  & { getOutletTaxPolicy: Array<(
    { __typename?: 'OutletTaxPolicyEntity' }
    & Pick<OutletTaxPolicyEntity, 'ID' | 'value' | 'effectiveDate' | 'isTaxable'>
  )> }
);

export type CreateOutletTaxPolicyMutationVariables = Exact<{
  input: OutletTaxPolicyInput;
}>;


export type CreateOutletTaxPolicyMutation = (
  { __typename?: 'Mutation' }
  & { createOutletTaxPolicy: (
    { __typename?: 'OutletTaxPolicyEntity' }
    & Pick<OutletTaxPolicyEntity, 'ID'>
  ) }
);

export type UpdateOutletTaxPolicyMutationVariables = Exact<{
  input: OutletTaxPolicyInput;
}>;


export type UpdateOutletTaxPolicyMutation = (
  { __typename?: 'Mutation' }
  & { updateOutletTaxPolicy: (
    { __typename?: 'OutletTaxPolicyEntity' }
    & Pick<OutletTaxPolicyEntity, 'ID'>
  ) }
);

export type DeleteOutletTaxPolicyMutationVariables = Exact<{
  ID: Scalars['String'];
}>;


export type DeleteOutletTaxPolicyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteOutletTaxPolicy'>
);

export type UpdateOutletGovTaxPolicyMutationVariables = Exact<{
  outletID: Scalars['String'];
  isInclusive: Scalars['Boolean'];
}>;


export type UpdateOutletGovTaxPolicyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateOutletGovTaxPolicy'>
);

export type GetReasonQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
}>;


export type GetReasonQuery = (
  { __typename?: 'Query' }
  & { getReason: Array<(
    { __typename?: 'ReasonEntity' }
    & Pick<ReasonEntity, 'ID' | 'outletID' | 'name' | 'description' | 'createdTs'>
  )> }
);

export type DeleteReasonMutationVariables = Exact<{
  ID: Scalars['String'];
}>;


export type DeleteReasonMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteReason'>
);

export type CreateReasonMutationVariables = Exact<{
  input: ReasonInput;
}>;


export type CreateReasonMutation = (
  { __typename?: 'Mutation' }
  & { createReason: (
    { __typename?: 'ReasonEntity' }
    & Pick<ReasonEntity, 'ID' | 'outletID' | 'name' | 'description'>
  ) }
);

export type UpdateReasonMutationVariables = Exact<{
  input: ReasonInput;
}>;


export type UpdateReasonMutation = (
  { __typename?: 'Mutation' }
  & { updateReason: (
    { __typename?: 'ReasonEntity' }
    & Pick<ReasonEntity, 'ID' | 'outletID' | 'name' | 'description'>
  ) }
);

export type UpdateOutletTaxSetupByTaxSchemeMutationVariables = Exact<{
  outletID: Scalars['String'];
  taxSchemeID: Scalars['String'];
}>;


export type UpdateOutletTaxSetupByTaxSchemeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateOutletTaxSetupByTaxScheme'>
);

export type GetOutletQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['String']>;
}>;


export type GetOutletQuery = (
  { __typename?: 'Query' }
  & { getOutlet: Array<(
    { __typename?: 'OutletEntity' }
    & Pick<OutletEntity, 'ID' | 'isActive' | 'name' | 'outletCode' | 'currencyCode' | 'registrationNo' | 'taxRegistrationNo' | 'businessDate' | 'contactNo' | 'email' | 'taxSchemeID' | 'logo' | 'gallery' | 'isInclusive' | 'integrateCityLedger' | 'capacity' | 'openingTime' | 'closingTime' | 'autoDayEndTime' | 'govTax' | 'serviceCharge' | 'tablesInfo' | 'address' | 'companyName' | 'companyID' | 'msicCode' | 'msicDesc' | 'eInvEffectiveDate' | 'generatedBillNo'>
    & { outletChannel?: Maybe<(
      { __typename?: 'OutletChannelEntity' }
      & Pick<OutletChannelEntity, 'ID' | 'url' | 'hotelID'>
    )>, operatingHours?: Maybe<Array<(
      { __typename?: 'OperatingHoursEntity' }
      & Pick<OperatingHoursEntity, 'openingTime' | 'closingTime'>
    )>>, customerPolicy?: Maybe<(
      { __typename?: 'OutletCustomerPolicyEntity' }
      & Pick<OutletCustomerPolicyEntity, 'selfOrder' | 'selfPayment'>
    )>, company?: Maybe<(
      { __typename?: 'CompanyEntity' }
      & Pick<CompanyEntity, 'id' | 'name' | 'description' | 'companyRegNo' | 'code' | 'contactNo' | 'email' | 'sstNo' | 'gstNo' | 'ttxNo' | 'gstExpiryDate' | 'eInvClientID' | 'eInvClientSecret' | 'eInvClientSecret2' | 'regionId' | 'companyTax' | 'recordStatus' | 'groupGstRegNo' | 'officeTel' | 'tin' | 'eSign' | 'eSignExpiryDate' | 'isEInvIntegrated' | 'eInvStartDate' | 'eInvEndDate'>
    )> }
  )> }
);

export type GetOutletNameQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
}>;


export type GetOutletNameQuery = (
  { __typename?: 'Query' }
  & { getOutlet: Array<(
    { __typename?: 'OutletEntity' }
    & Pick<OutletEntity, 'ID' | 'name' | 'businessDate' | 'outletCode' | 'currencyCode' | 'taxSchemeID' | 'taxRegistrationNo' | 'integrateCityLedger' | 'contactNo' | 'address' | 'logo'>
    & { outletChannel?: Maybe<(
      { __typename?: 'OutletChannelEntity' }
      & Pick<OutletChannelEntity, 'hotelID'>
    )>, customerPolicy?: Maybe<(
      { __typename?: 'OutletCustomerPolicyEntity' }
      & Pick<OutletCustomerPolicyEntity, 'selfOrder' | 'selfPayment'>
    )> }
  )> }
);

export type GetAssignedOutletByUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAssignedOutletByUserQuery = (
  { __typename?: 'Query' }
  & { getAssignedOutletByUser: Array<(
    { __typename?: 'OutletEntity' }
    & Pick<OutletEntity, 'ID' | 'name' | 'logo' | 'tablesInfo' | 'companyName' | 'businessDate' | 'currencyCode'>
    & { outletChannel?: Maybe<(
      { __typename?: 'OutletChannelEntity' }
      & Pick<OutletChannelEntity, 'ID' | 'url' | 'hotelID'>
    )>, customerPolicy?: Maybe<(
      { __typename?: 'OutletCustomerPolicyEntity' }
      & Pick<OutletCustomerPolicyEntity, 'selfOrder' | 'selfPayment'>
    )> }
  )> }
);

export type CreateOutletMutationVariables = Exact<{
  outletInput: OutletInput;
}>;


export type CreateOutletMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createOutlet'>
);

export type UpdateOutletMutationVariables = Exact<{
  outletInput: OutletInput;
  opHourInput: Array<OperatingHoursInput> | OperatingHoursInput;
}>;


export type UpdateOutletMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateOutlet'>
);

export type GetHotelBySubscriptionQueryVariables = Exact<{ [key: string]: never; }>;


export type GetHotelBySubscriptionQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getHotelBySubscription'>
);

export type GetMsicCodeListingQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMsicCodeListingQuery = (
  { __typename?: 'Query' }
  & { getMsicCodeListing: Array<(
    { __typename?: 'eInvoiceMsicEntity' }
    & Pick<EInvoiceMsicEntity, 'id' | 'msicCode' | 'description' | 'categoryReference'>
  )> }
);

export type SubmitDefaultPaymentMutationVariables = Exact<{
  defaultPaymentIDs: Array<Scalars['String']> | Scalars['String'];
  outletID: Scalars['String'];
}>;


export type SubmitDefaultPaymentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'submitDefaultPayment'>
);

export type GetDefaultPaymentQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
}>;


export type GetDefaultPaymentQuery = (
  { __typename?: 'Query' }
  & { getDefaultPayment: Array<(
    { __typename?: 'DefaultPaymentEntity' }
    & Pick<DefaultPaymentEntity, 'ID' | 'isActive' | 'outletID' | 'paymentTypeID' | 'isDefault' | 'paymentClass' | 'paymentTypeInfo'>
  )> }
);

export type GetPaymentTypeQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
}>;


export type GetPaymentTypeQuery = (
  { __typename?: 'Query' }
  & { getPaymentType: Array<(
    { __typename?: 'PaymentTypeEntity' }
    & Pick<PaymentTypeEntity, 'ID' | 'description' | 'paymentClass'>
  )> }
);

export type CreatePaymentTypeMutationVariables = Exact<{
  input: PaymentTypeInput;
}>;


export type CreatePaymentTypeMutation = (
  { __typename?: 'Mutation' }
  & { createPaymentType: (
    { __typename?: 'PaymentTypeEntity' }
    & Pick<PaymentTypeEntity, 'ID'>
  ) }
);

export type UpdatePaymentTypeMutationVariables = Exact<{
  input: PaymentTypeInput;
}>;


export type UpdatePaymentTypeMutation = (
  { __typename?: 'Mutation' }
  & { updatePaymentType: (
    { __typename?: 'PaymentTypeEntity' }
    & Pick<PaymentTypeEntity, 'ID'>
  ) }
);

export type DeletePaymentTypeMutationVariables = Exact<{
  ID: Scalars['String'];
}>;


export type DeletePaymentTypeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deletePaymentType'>
);

export type GetPromoCodeQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
}>;


export type GetPromoCodeQuery = (
  { __typename?: 'Query' }
  & { getPromoCode: Array<(
    { __typename?: 'PromoCodeEntity' }
    & Pick<PromoCodeEntity, 'ID' | 'code' | 'description' | 'amount' | 'validFrom' | 'validTill' | 'calcType'>
  )> }
);

export type CreatePromoCodeMutationVariables = Exact<{
  input: PromoCodeInput;
}>;


export type CreatePromoCodeMutation = (
  { __typename?: 'Mutation' }
  & { createPromoCode: (
    { __typename?: 'PromoCodeEntity' }
    & Pick<PromoCodeEntity, 'ID'>
  ) }
);

export type UpdatePromoCodeMutationVariables = Exact<{
  input: PromoCodeInput;
}>;


export type UpdatePromoCodeMutation = (
  { __typename?: 'Mutation' }
  & { updatePromoCode: (
    { __typename?: 'PromoCodeEntity' }
    & Pick<PromoCodeEntity, 'ID'>
  ) }
);

export type DeletePromoCodeMutationVariables = Exact<{
  ID: Scalars['String'];
}>;


export type DeletePromoCodeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deletePromoCode'>
);

export type GetPromoMenuQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
}>;


export type GetPromoMenuQuery = (
  { __typename?: 'Query' }
  & { getPromoMenu: Array<(
    { __typename?: 'PromoMenuEntity' }
    & Pick<PromoMenuEntity, 'ID' | 'name' | 'description' | 'frequencyType' | 'validityStartTs' | 'validityEndTs' | 'recurStartTime' | 'recurEndTime' | 'recurOnceAt' | 'recurEveryXDays' | 'recurEveryXWeeks' | 'recurEveryXMonths' | 'recurAtDayXOfMonth' | 'recurWeekBitmap' | 'recurDayBitmap'>
    & { promoMenuItem?: Maybe<Array<(
      { __typename?: 'PromoMenuItemEntity' }
      & Pick<PromoMenuItemEntity, 'ID' | 'menuItemID'>
    )>> }
  )> }
);

export type GetPromoMenuForPriceQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
}>;


export type GetPromoMenuForPriceQuery = (
  { __typename?: 'Query' }
  & { getPromoMenu: Array<(
    { __typename?: 'PromoMenuEntity' }
    & Pick<PromoMenuEntity, 'ID' | 'createdTs'>
    & { promoMenuItem?: Maybe<Array<(
      { __typename?: 'PromoMenuItemEntity' }
      & Pick<PromoMenuItemEntity, 'ID' | 'promoMenuID' | 'menuItemID'>
      & { menuItem?: Maybe<(
        { __typename?: 'MenuItemEntity' }
        & Pick<MenuItemEntity, 'ID' | 'name' | 'description'>
      )>, promoMenuItemPrice?: Maybe<Array<(
        { __typename?: 'PromoMenuItemPriceEntity' }
        & Pick<PromoMenuItemPriceEntity, 'ID' | 'promoMenuItemID' | 'price' | 'patronID'>
        & { promoMenuItem?: Maybe<(
          { __typename?: 'PromoMenuItemEntity' }
          & Pick<PromoMenuItemEntity, 'ID' | 'menuItemID'>
          & { menuItem?: Maybe<(
            { __typename?: 'MenuItemEntity' }
            & Pick<MenuItemEntity, 'name'>
          )> }
        )>, patron?: Maybe<(
          { __typename?: 'PatronEntity' }
          & Pick<PatronEntity, 'ID' | 'patronClass' | 'isActive'>
          & { patronType?: Maybe<(
            { __typename?: 'PatronTypeEntity' }
            & Pick<PatronTypeEntity, 'ID' | 'name'>
          )> }
        )> }
      )>> }
    )>> }
  )> }
);

export type GetPromoMenuItemQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
  promoMenuID?: Maybe<Scalars['String']>;
}>;


export type GetPromoMenuItemQuery = (
  { __typename?: 'Query' }
  & { getPromoMenuItem: Array<(
    { __typename?: 'PromoMenuItemEntity' }
    & Pick<PromoMenuItemEntity, 'ID' | 'promoMenuID' | 'menuItemID' | 'outletID'>
    & { menuItem?: Maybe<(
      { __typename?: 'MenuItemEntity' }
      & Pick<MenuItemEntity, 'ID' | 'name' | 'description'>
    )>, promoMenuItemPrice?: Maybe<Array<(
      { __typename?: 'PromoMenuItemPriceEntity' }
      & Pick<PromoMenuItemPriceEntity, 'ID' | 'patronID' | 'price' | 'patronClass'>
      & { patron?: Maybe<(
        { __typename?: 'PatronEntity' }
        & Pick<PatronEntity, 'ID' | 'patronClass' | 'isActive'>
        & { patronType?: Maybe<(
          { __typename?: 'PatronTypeEntity' }
          & Pick<PatronTypeEntity, 'ID' | 'name'>
        )> }
      )> }
    )>> }
  )> }
);

export type CreatePromoMenuMutationVariables = Exact<{
  input: PromoMenuInput;
}>;


export type CreatePromoMenuMutation = (
  { __typename?: 'Mutation' }
  & { createPromoMenu: (
    { __typename?: 'PromoMenuEntity' }
    & Pick<PromoMenuEntity, 'ID' | 'name' | 'description' | 'outletID' | 'frequencyType' | 'validityStartTs' | 'validityEndTs' | 'recurStartTime' | 'recurEndTime' | 'recurOnceAt' | 'recurEveryXDays' | 'recurEveryXWeeks' | 'recurEveryXMonths' | 'recurAtDayXOfMonth' | 'recurWeekBitmap' | 'recurDayBitmap'>
  ) }
);

export type UpdatePromoMenuMutationVariables = Exact<{
  input: PromoMenuInput;
}>;


export type UpdatePromoMenuMutation = (
  { __typename?: 'Mutation' }
  & { updatePromoMenu: (
    { __typename?: 'PromoMenuEntity' }
    & Pick<PromoMenuEntity, 'ID'>
  ) }
);

export type DeletePromoMenuMutationVariables = Exact<{
  ID: Scalars['String'];
}>;


export type DeletePromoMenuMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deletePromoMenu'>
);

export type CreatePromoMenuItemPriceMutationVariables = Exact<{
  outletID: Scalars['String'];
  promoMenuID: Scalars['String'];
  promoMenuItemInput: Array<PromoMenuItemInput> | PromoMenuItemInput;
}>;


export type CreatePromoMenuItemPriceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createPromoMenuItemPrice'>
);

export type PromoMenuItemCheckingQueryVariables = Exact<{
  promoMenuID: Scalars['String'];
  menuItemIDs: Array<Scalars['String']> | Scalars['String'];
}>;


export type PromoMenuItemCheckingQuery = (
  { __typename?: 'Query' }
  & { promoMenuItemChecking: Array<(
    { __typename?: 'PromoMenuItemEntity' }
    & Pick<PromoMenuItemEntity, 'ID' | 'menuItemID'>
    & { menuItem?: Maybe<(
      { __typename?: 'MenuItemEntity' }
      & Pick<MenuItemEntity, 'ID' | 'name' | 'description'>
    )>, promoMenu?: Maybe<(
      { __typename?: 'PromoMenuEntity' }
      & Pick<PromoMenuEntity, 'ID' | 'name'>
    )> }
  )> }
);

export type UpdatePromoMenuItemPriceMutationVariables = Exact<{
  outletID: Scalars['String'];
  promoMenuItemID: Scalars['String'];
  promoMenuItemPriceInput: Array<PromoMenuItemPriceInput> | PromoMenuItemPriceInput;
}>;


export type UpdatePromoMenuItemPriceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updatePromoMenuItemPrice'>
);

export type DeletePromoMenuItemAndPriceMutationVariables = Exact<{
  promoMenuItemID: Scalars['String'];
}>;


export type DeletePromoMenuItemAndPriceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deletePromoMenuItemAndPrice'>
);

export type GetRemainingMenuItemsForPromoQueryVariables = Exact<{
  promoMenuID: Scalars['String'];
  outletID: Scalars['String'];
}>;


export type GetRemainingMenuItemsForPromoQuery = (
  { __typename?: 'Query' }
  & { getMenuItemForPromoMenuItemPrice: Array<(
    { __typename?: 'MenuItemEntity' }
    & Pick<MenuItemEntity, 'ID' | 'name' | 'description' | 'kitchenName' | 'nativeName'>
  )> }
);

export type GetQrQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
}>;


export type GetQrQuery = (
  { __typename?: 'Query' }
  & { getQR: Array<(
    { __typename?: 'QREntity' }
    & Pick<QrEntity, 'ID' | 'accountID' | 'code' | 'createdTs' | 'outletID' | 'orderInfo'>
  )> }
);

export type OpenOrderQrCheckingQueryVariables = Exact<{
  qrID: Scalars['String'];
}>;


export type OpenOrderQrCheckingQuery = (
  { __typename?: 'Query' }
  & { openOrderQRChecking: (
    { __typename?: 'QREntity' }
    & Pick<QrEntity, 'ID' | 'code' | 'outletID' | 'orderInfo'>
  ) }
);

export type CreateQrMutationVariables = Exact<{
  input: QrInput;
}>;


export type CreateQrMutation = (
  { __typename?: 'Mutation' }
  & { createQR: (
    { __typename?: 'QREntity' }
    & Pick<QrEntity, 'ID' | 'accountID' | 'code' | 'createdTs' | 'outletID' | 'orderInfo'>
  ) }
);

export type UpdateQrMutationVariables = Exact<{
  input: QrInput;
}>;


export type UpdateQrMutation = (
  { __typename?: 'Mutation' }
  & { updateQR: (
    { __typename?: 'QREntity' }
    & Pick<QrEntity, 'ID'>
  ) }
);

export type DeleteQrMutationVariables = Exact<{
  ID: Scalars['String'];
}>;


export type DeleteQrMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteQR'>
);

export type GetReceiptPrinterQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
}>;


export type GetReceiptPrinterQuery = (
  { __typename?: 'Query' }
  & { getReceiptPrinter: Array<(
    { __typename?: 'ReceiptPrinterEntity' }
    & Pick<ReceiptPrinterEntity, 'ID' | 'name' | 'isActive' | 'outletID' | 'isDefault'>
  )> }
);

export type CreateReceiptPrinterMutationVariables = Exact<{
  input: ReceiptPrinterInput;
}>;


export type CreateReceiptPrinterMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createReceiptPrinter'>
);

export type UpdateReceiptPrinterMutationVariables = Exact<{
  input: ReceiptPrinterInput;
}>;


export type UpdateReceiptPrinterMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateReceiptPrinter'>
);

export type DeleteReceiptPrinterMutationVariables = Exact<{
  ID: Scalars['String'];
}>;


export type DeleteReceiptPrinterMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteReceiptPrinter'>
);

export type GetSetMenuOptionQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
  maxSelections?: Maybe<Scalars['Float']>;
  menuItemID?: Maybe<Scalars['String']>;
}>;


export type GetSetMenuOptionQuery = (
  { __typename?: 'Query' }
  & { getSetMenuOption: Array<(
    { __typename?: 'SetMenuOptionEntity' }
    & Pick<SetMenuOptionEntity, 'ID' | 'name'>
    & { setMenuOptionItem?: Maybe<Array<(
      { __typename?: 'SetMenuOptionItemEntity' }
      & Pick<SetMenuOptionItemEntity, 'ID' | 'quantity' | 'setMenuOptionID' | 'menuItemID'>
      & { menuItem?: Maybe<(
        { __typename?: 'MenuItemEntity' }
        & Pick<MenuItemEntity, 'ID' | 'name' | 'description' | 'attachments'>
      )> }
    )>> }
  )> }
);

export type GetSetMenuOptionOptionQueryVariables = Exact<{
  outletID: Scalars['String'];
  menuItemID: Scalars['String'];
}>;


export type GetSetMenuOptionOptionQuery = (
  { __typename?: 'Query' }
  & { getSetMenuOptionOption: Array<(
    { __typename?: 'SetMenuOptionEntity' }
    & Pick<SetMenuOptionEntity, 'ID' | 'name' | 'maxSelections'>
    & { setMenuOptionItem?: Maybe<Array<(
      { __typename?: 'SetMenuOptionItemEntity' }
      & Pick<SetMenuOptionItemEntity, 'ID' | 'quantity' | 'setMenuOptionID' | 'menuItemID'>
      & { menuItem?: Maybe<(
        { __typename?: 'MenuItemEntity' }
        & Pick<MenuItemEntity, 'ID' | 'name' | 'description' | 'attachments'>
      )> }
    )>> }
  )> }
);

export type GetMenuItemForSetMenuQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
  isSetMenu?: Maybe<Scalars['Boolean']>;
}>;


export type GetMenuItemForSetMenuQuery = (
  { __typename?: 'Query' }
  & { getMenuItem: Array<(
    { __typename?: 'MenuItemEntity' }
    & Pick<MenuItemEntity, 'ID' | 'name' | 'isSetMenu'>
    & { setMenuOption?: Maybe<Array<(
      { __typename?: 'SetMenuOptionEntity' }
      & Pick<SetMenuOptionEntity, 'ID' | 'outletID' | 'maxSelections' | 'menuItemID' | 'name'>
      & { setMenuOptionItem?: Maybe<Array<(
        { __typename?: 'SetMenuOptionItemEntity' }
        & Pick<SetMenuOptionItemEntity, 'ID' | 'quantity' | 'setMenuOptionID' | 'menuItemID'>
        & { menuItem?: Maybe<(
          { __typename?: 'MenuItemEntity' }
          & Pick<MenuItemEntity, 'ID' | 'name' | 'description' | 'attachments'>
        )> }
      )>> }
    )>> }
  )> }
);

export type GetMenuItemForSetMenuOptionItemQueryVariables = Exact<{
  setMenuID: Scalars['String'];
  outletID: Scalars['String'];
  isStandard?: Maybe<Scalars['Boolean']>;
}>;


export type GetMenuItemForSetMenuOptionItemQuery = (
  { __typename?: 'Query' }
  & { getMenuItemForSetMenuOptionItem: Array<(
    { __typename?: 'MenuItemEntity' }
    & Pick<MenuItemEntity, 'ID' | 'name' | 'description'>
  )> }
);

export type CreateSetMenuOptionMutationVariables = Exact<{
  input: SetMenuOptionInput;
}>;


export type CreateSetMenuOptionMutation = (
  { __typename?: 'Mutation' }
  & { createSetMenuOption: (
    { __typename?: 'SetMenuOptionEntity' }
    & Pick<SetMenuOptionEntity, 'name' | 'nativeName' | 'description' | 'outletID' | 'maxSelections' | 'menuItemID'>
  ) }
);

export type UpdateSetMenuOptionMutationVariables = Exact<{
  input: SetMenuOptionInput;
}>;


export type UpdateSetMenuOptionMutation = (
  { __typename?: 'Mutation' }
  & { updateSetMenuOption: (
    { __typename?: 'SetMenuOptionEntity' }
    & Pick<SetMenuOptionEntity, 'ID'>
  ) }
);

export type CreateSetMenuStandardOptionItemMutationVariables = Exact<{
  setMenuOptionItemInput: Array<SetMenuOptionItemInput> | SetMenuOptionItemInput;
  setMenuID: Scalars['String'];
  outletID: Scalars['String'];
}>;


export type CreateSetMenuStandardOptionItemMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createSetMenuStandardOptionItem'>
);

export type DeleteSetMenuOptionItemMutationVariables = Exact<{
  ID: Scalars['String'];
}>;


export type DeleteSetMenuOptionItemMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteSetMenuOptionItem'>
);

export type DeleteSetMenuOptionMutationVariables = Exact<{
  ID: Scalars['String'];
}>;


export type DeleteSetMenuOptionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteSetMenuOption'>
);

export type CreateSetMenuOptionOptionItemMutationVariables = Exact<{
  setMenuOptionItemInput: Array<SetMenuOptionItemInput> | SetMenuOptionItemInput;
}>;


export type CreateSetMenuOptionOptionItemMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createSetMenuOptionOptionItem'>
);

export type UpdateSetMenuOptionItemMutationVariables = Exact<{
  input: SetMenuOptionItemInput;
}>;


export type UpdateSetMenuOptionItemMutation = (
  { __typename?: 'Mutation' }
  & { updateSetMenuOptionItem: (
    { __typename?: 'SetMenuOptionItemEntity' }
    & Pick<SetMenuOptionItemEntity, 'ID'>
  ) }
);

export type GetOptionQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
}>;


export type GetOptionQuery = (
  { __typename?: 'Query' }
  & { getOption: Array<(
    { __typename?: 'OptionEntity' }
    & Pick<OptionEntity, 'ID' | 'name' | 'outletID'>
    & { optionItem?: Maybe<Array<(
      { __typename?: 'OptionItemEntity' }
      & Pick<OptionItemEntity, 'ID' | 'name' | 'optionID' | 'outletID' | 'priority'>
    )>> }
  )> }
);

export type GetAllOptionQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
}>;


export type GetAllOptionQuery = (
  { __typename?: 'Query' }
  & { getOption: Array<(
    { __typename?: 'OptionEntity' }
    & Pick<OptionEntity, 'ID' | 'name' | 'outletID'>
    & { optionItem?: Maybe<Array<(
      { __typename?: 'OptionItemEntity' }
      & Pick<OptionItemEntity, 'ID' | 'name' | 'optionID' | 'outletID' | 'priority'>
    )>> }
  )> }
);

export type CreateOptionMutationVariables = Exact<{
  input: OptionInput;
}>;


export type CreateOptionMutation = (
  { __typename?: 'Mutation' }
  & { createOption: (
    { __typename?: 'OptionEntity' }
    & Pick<OptionEntity, 'ID'>
  ) }
);

export type UpdateOptionMutationVariables = Exact<{
  input: OptionInput;
}>;


export type UpdateOptionMutation = (
  { __typename?: 'Mutation' }
  & { updateOption: (
    { __typename?: 'OptionEntity' }
    & Pick<OptionEntity, 'ID'>
  ) }
);

export type DeleteOptionMutationVariables = Exact<{
  ID: Scalars['String'];
}>;


export type DeleteOptionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteOption'>
);

export type GetOptionItemQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  optionID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
}>;


export type GetOptionItemQuery = (
  { __typename?: 'Query' }
  & { getOptionItem: Array<(
    { __typename?: 'OptionItemEntity' }
    & Pick<OptionItemEntity, 'ID' | 'name' | 'accountID' | 'optionID' | 'outletID' | 'modTs' | 'createdTs' | 'createdBy' | 'priority'>
  )> }
);

export type CreateOptionItemMutationVariables = Exact<{
  input: OptionItemInput;
}>;


export type CreateOptionItemMutation = (
  { __typename?: 'Mutation' }
  & { createOptionItem: (
    { __typename?: 'OptionItemEntity' }
    & Pick<OptionItemEntity, 'ID'>
  ) }
);

export type UpdateOptionItemMutationVariables = Exact<{
  input: OptionItemInput;
}>;


export type UpdateOptionItemMutation = (
  { __typename?: 'Mutation' }
  & { updateOptionItem: (
    { __typename?: 'OptionItemEntity' }
    & Pick<OptionItemEntity, 'ID'>
  ) }
);

export type DeleteOptionItemMutationVariables = Exact<{
  ID: Scalars['String'];
}>;


export type DeleteOptionItemMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteOptionItem'>
);

export type DragOptionItemMutationVariables = Exact<{
  input: Array<OptionItemInput> | OptionItemInput;
  optionID: Scalars['String'];
}>;


export type DragOptionItemMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'dragOptionItem'>
);

export type GetStopSalesQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  menuItemID?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
}>;


export type GetStopSalesQuery = (
  { __typename?: 'Query' }
  & { getStopSales: Array<(
    { __typename?: 'StopSalesEntity' }
    & Pick<StopSalesEntity, 'ID' | 'outletID' | 'menuItemID'>
    & { menuItem?: Maybe<(
      { __typename?: 'MenuItemEntity' }
      & Pick<MenuItemEntity, 'name' | 'isActive' | 'kitchenName' | 'nativeName' | 'description'>
    )> }
  )> }
);

export type CreateStopSalesMutationVariables = Exact<{
  input: StopSalesInput;
}>;


export type CreateStopSalesMutation = (
  { __typename?: 'Mutation' }
  & { createStopSales: (
    { __typename?: 'StopSalesEntity' }
    & Pick<StopSalesEntity, 'ID'>
  ) }
);

export type UpdateStopSalesMutationVariables = Exact<{
  input: StopSalesInput;
}>;


export type UpdateStopSalesMutation = (
  { __typename?: 'Mutation' }
  & { updateStopSales: (
    { __typename?: 'StopSalesEntity' }
    & Pick<StopSalesEntity, 'ID'>
  ) }
);

export type SubmitStopSalesMutationVariables = Exact<{
  input: Array<StopSalesInput> | StopSalesInput;
  outletID: Scalars['String'];
}>;


export type SubmitStopSalesMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'submitStopSales'>
);

export type DeleteStopSalesMutationVariables = Exact<{
  ID: Scalars['String'];
}>;


export type DeleteStopSalesMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteStopSales'>
);

export type GetMenuItemForStopSaleQueryVariables = Exact<{
  outletID: Scalars['String'];
}>;


export type GetMenuItemForStopSaleQuery = (
  { __typename?: 'Query' }
  & { getMenuItemForStopSale: Array<(
    { __typename?: 'MenuItemEntity' }
    & Pick<MenuItemEntity, 'ID' | 'outletID' | 'name' | 'description'>
    & { setMenuInfo?: Maybe<Array<(
      { __typename?: 'SetMenuInfo' }
      & Pick<SetMenuInfo, 'menuItemID' | 'setMenuID' | 'setMenuName'>
    )>> }
  )> }
);

export type GetTableQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
}>;


export type GetTableQuery = (
  { __typename?: 'Query' }
  & { getTable: Array<(
    { __typename?: 'TableEntity' }
    & Pick<TableEntity, 'ID' | 'tableNumber' | 'seatCount' | 'prefix' | 'outletID' | 'isActive' | 'status' | 'tablePrfixNumberInfo'>
    & { orderInfo?: Maybe<(
      { __typename?: 'OrderInfo' }
      & Pick<OrderInfo, 'totalAmount' | 'totalPax'>
    )>, reservationInfo?: Maybe<(
      { __typename?: 'ReservationInfo' }
      & Pick<ReservationInfo, 'reserved' | 'pax' | 'name' | 'time' | 'noShow'>
    )>, reservation?: Maybe<(
      { __typename?: 'ReservationEntity' }
      & Pick<ReservationEntity, 'ID' | 'name' | 'reservationDate' | 'reservationTime'>
    )>, outlet?: Maybe<(
      { __typename?: 'OutletEntity' }
      & Pick<OutletEntity, 'ID' | 'name'>
    )>, order?: Maybe<Array<(
      { __typename?: 'OrderEntity' }
      & Pick<OrderEntity, 'tableID' | 'status'>
    )>> }
  )> }
);

export type CreateTableMutationVariables = Exact<{
  input: TableInput;
}>;


export type CreateTableMutation = (
  { __typename?: 'Mutation' }
  & { createTable: (
    { __typename?: 'TableEntity' }
    & Pick<TableEntity, 'ID'>
  ) }
);

export type UpdateTableMutationVariables = Exact<{
  input: TableInput;
}>;


export type UpdateTableMutation = (
  { __typename?: 'Mutation' }
  & { updateTable: (
    { __typename?: 'TableEntity' }
    & Pick<TableEntity, 'ID'>
  ) }
);

export type SubmitTableMutationVariables = Exact<{
  input?: Maybe<Array<TableInput> | TableInput>;
  outletID: Scalars['String'];
}>;


export type SubmitTableMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'submitTable'>
);

export type DeleteTableMutationVariables = Exact<{
  ID: Scalars['String'];
}>;


export type DeleteTableMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteTable'>
);

export type DragTableMutationVariables = Exact<{
  input: Array<TableInput> | TableInput;
}>;


export type DragTableMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'dragTable'>
);

export type RefreshTableSubscriptionVariables = Exact<{
  outletID: Scalars['String'];
}>;


export type RefreshTableSubscription = (
  { __typename?: 'Subscription' }
  & { refreshTable: (
    { __typename?: 'TableEntity' }
    & Pick<TableEntity, 'ID' | 'outletID' | 'name' | 'prefix'>
  ) }
);

export type GetReservationQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
  tableID?: Maybe<Scalars['String']>;
}>;


export type GetReservationQuery = (
  { __typename?: 'Query' }
  & { getReservation: Array<(
    { __typename?: 'ReservationEntity' }
    & Pick<ReservationEntity, 'ID' | 'pax' | 'outletID' | 'createdTs' | 'description' | 'docNo' | 'status' | 'noShow' | 'isActive' | 'tableID' | 'patronID' | 'name' | 'contactNo' | 'reservationDate' | 'reservationTime'>
    & { table?: Maybe<(
      { __typename?: 'TableEntity' }
      & Pick<TableEntity, 'name' | 'prefix' | 'tableNumber' | 'tablePrfixNumberInfo'>
      & { order?: Maybe<Array<(
        { __typename?: 'OrderEntity' }
        & Pick<OrderEntity, 'docNo'>
      )>> }
    )> }
  )> }
);

export type NoShowCheckQueryVariables = Exact<{
  reservationID: Scalars['String'];
}>;


export type NoShowCheckQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'noShowCheck'>
);

export type CreateReservationMutationVariables = Exact<{
  input: ReservationInput;
}>;


export type CreateReservationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createReservation'>
);

export type UpdateReservationMutationVariables = Exact<{
  input: ReservationInput;
}>;


export type UpdateReservationMutation = (
  { __typename?: 'Mutation' }
  & { updateReservation: (
    { __typename?: 'ReservationEntity' }
    & Pick<ReservationEntity, 'ID'>
  ) }
);

export type AssignReservationTableMutationVariables = Exact<{
  tableID: Scalars['String'];
  reservationID: Scalars['String'];
}>;


export type AssignReservationTableMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'assignReservationTable'>
);

export type DeleteReservationMutationVariables = Exact<{
  ID: Scalars['String'];
}>;


export type DeleteReservationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteReservation'>
);

export type CancelReservationMutationVariables = Exact<{
  reservationID: Scalars['String'];
}>;


export type CancelReservationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelReservation'>
);

export type ReinstateReservationMutationVariables = Exact<{
  reservationID: Scalars['String'];
}>;


export type ReinstateReservationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'reinstateReservation'>
);

export type GetTakeawayOrderQueryVariables = Exact<{
  outletID: Scalars['String'];
  orderID?: Maybe<Scalars['String']>;
}>;


export type GetTakeawayOrderQuery = (
  { __typename?: 'Query' }
  & { getTakeawayOrder: Array<(
    { __typename?: 'OrderEntity' }
    & Pick<OrderEntity, 'ID' | 'outletID' | 'tableID' | 'qrID' | 'docNo' | 'serviceMode' | 'openedByID' | 'servedByIDs' | 'paidByID' | 'businessDate' | 'taxSchemeInfo' | 'taxSchemeFooterInfo' | 'pax' | 'mealPeriodID' | 'patronID' | 'createdTs' | 'totalAmount' | 'outstandingAmount' | 'registrationID' | 'patronAccountID' | 'status' | 'patronName' | 'contactNo' | 'pickupTime'>
    & { orderItem?: Maybe<Array<(
      { __typename?: 'OrderItemEntity' }
      & Pick<OrderItemEntity, 'ID' | 'orderID' | 'amount' | 'specialRequest' | 'taxAmount' | 'serviceCharge' | 'quantity' | 'status' | 'kitchenStatus' | 'isTakeaway' | 'weight' | 'menuItemID' | 'openItemName' | 'totalInfo' | 'billID'>
      & { menuItem?: Maybe<(
        { __typename?: 'MenuItemEntity' }
        & Pick<MenuItemEntity, 'name' | 'description' | 'attachments'>
      )>, orderItemOption?: Maybe<Array<(
        { __typename?: 'OrderItemOptionEntity' }
        & Pick<OrderItemOptionEntity, 'optionID' | 'name'>
        & { orderItemOptionItem?: Maybe<Array<(
          { __typename?: 'OrderItemOptionItemEntity' }
          & Pick<OrderItemOptionItemEntity, 'price' | 'basePrice' | 'unitPrice' | 'serviceCharge' | 'taxAmount' | 'optionItemID'>
          & { optionItem?: Maybe<(
            { __typename?: 'OptionItemEntity' }
            & Pick<OptionItemEntity, 'name'>
          )> }
        )>> }
      )>>, orderItemSetMenuOption?: Maybe<Array<(
        { __typename?: 'OrderItemSetMenuOptionEntity' }
        & Pick<OrderItemSetMenuOptionEntity, 'setMenuOptionID' | 'name'>
        & { orderItemSetMenuOptionItem?: Maybe<Array<(
          { __typename?: 'OrderItemSetMenuOptionItemEntity' }
          & Pick<OrderItemSetMenuOptionItemEntity, 'setMenuOptionItemID'>
          & { setMenuOptionItem?: Maybe<(
            { __typename?: 'SetMenuOptionItemEntity' }
            & Pick<SetMenuOptionItemEntity, 'ID'>
            & { menuItem?: Maybe<(
              { __typename?: 'MenuItemEntity' }
              & Pick<MenuItemEntity, 'ID' | 'name'>
            )> }
          )> }
        )>> }
      )>> }
    )>>, patron?: Maybe<(
      { __typename?: 'PatronEntity' }
      & Pick<PatronEntity, 'patronClass'>
    )> }
  )> }
);

export type PickupTakeawayOrderMutationVariables = Exact<{
  orderID: Scalars['String'];
}>;


export type PickupTakeawayOrderMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'pickupTakeawayOrder'>
);

export type CancelTakeawayOrderMutationVariables = Exact<{
  orderID: Scalars['String'];
}>;


export type CancelTakeawayOrderMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelTakeawayOrder'>
);


export const LoggedInUserProfileDocument = gql`
    query loggedInUserProfile {
  loggedInUserProfile {
    ID
    accountID
    name
    email
    contactNo
    department
    superUser
    jobTitle
    lastestAccessedEntity
  }
}
    `;

/**
 * __useLoggedInUserProfileQuery__
 *
 * To run a query within a React component, call `useLoggedInUserProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoggedInUserProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoggedInUserProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useLoggedInUserProfileQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LoggedInUserProfileQuery, LoggedInUserProfileQueryVariables>) {
        return ApolloReactHooks.useQuery<LoggedInUserProfileQuery, LoggedInUserProfileQueryVariables>(LoggedInUserProfileDocument, baseOptions);
      }
export function useLoggedInUserProfileLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LoggedInUserProfileQuery, LoggedInUserProfileQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LoggedInUserProfileQuery, LoggedInUserProfileQueryVariables>(LoggedInUserProfileDocument, baseOptions);
        }
export type LoggedInUserProfileQueryHookResult = ReturnType<typeof useLoggedInUserProfileQuery>;
export type LoggedInUserProfileLazyQueryHookResult = ReturnType<typeof useLoggedInUserProfileLazyQuery>;
export type LoggedInUserProfileQueryResult = ApolloReactCommon.QueryResult<LoggedInUserProfileQuery, LoggedInUserProfileQueryVariables>;
export const LoginDocument = gql`
    mutation login($loginID: String!, $password: String!) {
  login(loginID: $loginID, password: $password) {
    accessToken
    user {
      ID
      name
      userName
      email
      contactNo
      department
      jobTitle
      commonStatus
      confirmed
      avatar
      superUser
      accountID
      lastestAccessedEntity
    }
  }
}
    `;
export type LoginMutationFn = ApolloReactCommon.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      loginID: // value for 'loginID'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        return ApolloReactHooks.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, baseOptions);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = ApolloReactCommon.MutationResult<LoginMutation>;
export type LoginMutationOptions = ApolloReactCommon.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const ChangePasswordDocument = gql`
    mutation changePassword($newPassword: String!, $currentPassword: String!) {
  changePassword(newPassword: $newPassword, currentPassword: $currentPassword)
}
    `;
export type ChangePasswordMutationFn = ApolloReactCommon.MutationFunction<ChangePasswordMutation, ChangePasswordMutationVariables>;

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      newPassword: // value for 'newPassword'
 *      currentPassword: // value for 'currentPassword'
 *   },
 * });
 */
export function useChangePasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ChangePasswordMutation, ChangePasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(ChangePasswordDocument, baseOptions);
      }
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>;
export type ChangePasswordMutationResult = ApolloReactCommon.MutationResult<ChangePasswordMutation>;
export type ChangePasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<ChangePasswordMutation, ChangePasswordMutationVariables>;
export const ForgotPasswordDocument = gql`
    mutation forgotPassword($email: String!) {
  forgotPassword(email: $email)
}
    `;
export type ForgotPasswordMutationFn = ApolloReactCommon.MutationFunction<ForgotPasswordMutation, ForgotPasswordMutationVariables>;

/**
 * __useForgotPasswordMutation__
 *
 * To run a mutation, you first call `useForgotPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgotPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgotPasswordMutation, { data, loading, error }] = useForgotPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useForgotPasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<ForgotPasswordMutation, ForgotPasswordMutationVariables>(ForgotPasswordDocument, baseOptions);
      }
export type ForgotPasswordMutationHookResult = ReturnType<typeof useForgotPasswordMutation>;
export type ForgotPasswordMutationResult = ApolloReactCommon.MutationResult<ForgotPasswordMutation>;
export type ForgotPasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation resetPassword($password: String!, $token: String!) {
  resetPassword(password: $password, token: $token)
}
    `;
export type ResetPasswordMutationFn = ApolloReactCommon.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      password: // value for 'password'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, baseOptions);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = ApolloReactCommon.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const LogoutDocument = gql`
    mutation logout($ID: String, $outletID: String) {
  logout(ID: $ID, outletID: $outletID)
}
    `;
export type LogoutMutationFn = ApolloReactCommon.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *      outletID: // value for 'outletID'
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
        return ApolloReactHooks.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, baseOptions);
      }
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = ApolloReactCommon.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = ApolloReactCommon.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;
export const ActivateUserDocument = gql`
    mutation activateUser($token: String!) {
  activateUser(token: $token)
}
    `;
export type ActivateUserMutationFn = ApolloReactCommon.MutationFunction<ActivateUserMutation, ActivateUserMutationVariables>;

/**
 * __useActivateUserMutation__
 *
 * To run a mutation, you first call `useActivateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateUserMutation, { data, loading, error }] = useActivateUserMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useActivateUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ActivateUserMutation, ActivateUserMutationVariables>) {
        return ApolloReactHooks.useMutation<ActivateUserMutation, ActivateUserMutationVariables>(ActivateUserDocument, baseOptions);
      }
export type ActivateUserMutationHookResult = ReturnType<typeof useActivateUserMutation>;
export type ActivateUserMutationResult = ApolloReactCommon.MutationResult<ActivateUserMutation>;
export type ActivateUserMutationOptions = ApolloReactCommon.BaseMutationOptions<ActivateUserMutation, ActivateUserMutationVariables>;
export const CreatePasswordDocument = gql`
    mutation createPassword($input: passwordInfo!) {
  createPassword(input: $input)
}
    `;
export type CreatePasswordMutationFn = ApolloReactCommon.MutationFunction<CreatePasswordMutation, CreatePasswordMutationVariables>;

/**
 * __useCreatePasswordMutation__
 *
 * To run a mutation, you first call `useCreatePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPasswordMutation, { data, loading, error }] = useCreatePasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreatePasswordMutation, CreatePasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<CreatePasswordMutation, CreatePasswordMutationVariables>(CreatePasswordDocument, baseOptions);
      }
export type CreatePasswordMutationHookResult = ReturnType<typeof useCreatePasswordMutation>;
export type CreatePasswordMutationResult = ApolloReactCommon.MutationResult<CreatePasswordMutation>;
export type CreatePasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<CreatePasswordMutation, CreatePasswordMutationVariables>;
export const UploadProfilePicDocument = gql`
    mutation uploadProfilePic($file: Upload!, $userID: String!) {
  uploadProfilePic(file: $file, userID: $userID) {
    refID
    fileName
    fileURL
    mediaType
  }
}
    `;
export type UploadProfilePicMutationFn = ApolloReactCommon.MutationFunction<UploadProfilePicMutation, UploadProfilePicMutationVariables>;

/**
 * __useUploadProfilePicMutation__
 *
 * To run a mutation, you first call `useUploadProfilePicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadProfilePicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadProfilePicMutation, { data, loading, error }] = useUploadProfilePicMutation({
 *   variables: {
 *      file: // value for 'file'
 *      userID: // value for 'userID'
 *   },
 * });
 */
export function useUploadProfilePicMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UploadProfilePicMutation, UploadProfilePicMutationVariables>) {
        return ApolloReactHooks.useMutation<UploadProfilePicMutation, UploadProfilePicMutationVariables>(UploadProfilePicDocument, baseOptions);
      }
export type UploadProfilePicMutationHookResult = ReturnType<typeof useUploadProfilePicMutation>;
export type UploadProfilePicMutationResult = ApolloReactCommon.MutationResult<UploadProfilePicMutation>;
export type UploadProfilePicMutationOptions = ApolloReactCommon.BaseMutationOptions<UploadProfilePicMutation, UploadProfilePicMutationVariables>;
export const UnblockUserDocument = gql`
    mutation unblockUser($input: passwordInfo!) {
  unblockUser(input: $input)
}
    `;
export type UnblockUserMutationFn = ApolloReactCommon.MutationFunction<UnblockUserMutation, UnblockUserMutationVariables>;

/**
 * __useUnblockUserMutation__
 *
 * To run a mutation, you first call `useUnblockUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnblockUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unblockUserMutation, { data, loading, error }] = useUnblockUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnblockUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UnblockUserMutation, UnblockUserMutationVariables>) {
        return ApolloReactHooks.useMutation<UnblockUserMutation, UnblockUserMutationVariables>(UnblockUserDocument, baseOptions);
      }
export type UnblockUserMutationHookResult = ReturnType<typeof useUnblockUserMutation>;
export type UnblockUserMutationResult = ApolloReactCommon.MutationResult<UnblockUserMutation>;
export type UnblockUserMutationOptions = ApolloReactCommon.BaseMutationOptions<UnblockUserMutation, UnblockUserMutationVariables>;
export const ChangePasswordSuDocument = gql`
    mutation changePasswordSU($input: passwordInfo!) {
  unblockUser(input: $input)
}
    `;
export type ChangePasswordSuMutationFn = ApolloReactCommon.MutationFunction<ChangePasswordSuMutation, ChangePasswordSuMutationVariables>;

/**
 * __useChangePasswordSuMutation__
 *
 * To run a mutation, you first call `useChangePasswordSuMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordSuMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordSuMutation, { data, loading, error }] = useChangePasswordSuMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangePasswordSuMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ChangePasswordSuMutation, ChangePasswordSuMutationVariables>) {
        return ApolloReactHooks.useMutation<ChangePasswordSuMutation, ChangePasswordSuMutationVariables>(ChangePasswordSuDocument, baseOptions);
      }
export type ChangePasswordSuMutationHookResult = ReturnType<typeof useChangePasswordSuMutation>;
export type ChangePasswordSuMutationResult = ApolloReactCommon.MutationResult<ChangePasswordSuMutation>;
export type ChangePasswordSuMutationOptions = ApolloReactCommon.BaseMutationOptions<ChangePasswordSuMutation, ChangePasswordSuMutationVariables>;
export const GetUserLogoDocument = gql`
    query getUserLogo($refID: String, $description: String) {
  logoView(refID: $refID, description: $description) {
    fileName
    fileURL
  }
}
    `;

/**
 * __useGetUserLogoQuery__
 *
 * To run a query within a React component, call `useGetUserLogoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserLogoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserLogoQuery({
 *   variables: {
 *      refID: // value for 'refID'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useGetUserLogoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUserLogoQuery, GetUserLogoQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUserLogoQuery, GetUserLogoQueryVariables>(GetUserLogoDocument, baseOptions);
      }
export function useGetUserLogoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUserLogoQuery, GetUserLogoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUserLogoQuery, GetUserLogoQueryVariables>(GetUserLogoDocument, baseOptions);
        }
export type GetUserLogoQueryHookResult = ReturnType<typeof useGetUserLogoQuery>;
export type GetUserLogoLazyQueryHookResult = ReturnType<typeof useGetUserLogoLazyQuery>;
export type GetUserLogoQueryResult = ApolloReactCommon.QueryResult<GetUserLogoQuery, GetUserLogoQueryVariables>;
export const GetHomeSummaryDocument = gql`
    query getHomeSummary {
  loggedInUserProfile {
    ID
    accountID
    name
    email
    contactNo
    userName
    department
    superUser
    jobTitle
    lastestAccessedEntity
  }
  getRolePermission
  getPOSPermission
}
    `;

/**
 * __useGetHomeSummaryQuery__
 *
 * To run a query within a React component, call `useGetHomeSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHomeSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHomeSummaryQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetHomeSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetHomeSummaryQuery, GetHomeSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetHomeSummaryQuery, GetHomeSummaryQueryVariables>(GetHomeSummaryDocument, baseOptions);
      }
export function useGetHomeSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetHomeSummaryQuery, GetHomeSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetHomeSummaryQuery, GetHomeSummaryQueryVariables>(GetHomeSummaryDocument, baseOptions);
        }
export type GetHomeSummaryQueryHookResult = ReturnType<typeof useGetHomeSummaryQuery>;
export type GetHomeSummaryLazyQueryHookResult = ReturnType<typeof useGetHomeSummaryLazyQuery>;
export type GetHomeSummaryQueryResult = ApolloReactCommon.QueryResult<GetHomeSummaryQuery, GetHomeSummaryQueryVariables>;
export const GetKitchenOrderDocument = gql`
    query getKitchenOrder($outletID: String!, $kitchenPrinterID: String!) {
  getKitchenOrder(outletID: $outletID, kitchenPrinterID: $kitchenPrinterID) {
    ID
    docNo
    createdTs
    table {
      prefix
      tableNumber
    }
    kitchenOrderItems {
      modTs
      ID
      refID
      kitchenStatus
      openItemName
      quantity
      weight
      menuItem {
        name
        attachments
      }
      orderItemOption {
        orderItemOptionItem {
          optionItem {
            name
          }
        }
      }
      orderItemSetMenuOption {
        orderItemSetMenuOptionItem {
          setMenuOptionItem {
            menuItem {
              name
            }
          }
        }
      }
      specialRequest
      isTakeaway
      status
    }
  }
}
    `;

/**
 * __useGetKitchenOrderQuery__
 *
 * To run a query within a React component, call `useGetKitchenOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetKitchenOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetKitchenOrderQuery({
 *   variables: {
 *      outletID: // value for 'outletID'
 *      kitchenPrinterID: // value for 'kitchenPrinterID'
 *   },
 * });
 */
export function useGetKitchenOrderQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetKitchenOrderQuery, GetKitchenOrderQueryVariables>) {
        return ApolloReactHooks.useQuery<GetKitchenOrderQuery, GetKitchenOrderQueryVariables>(GetKitchenOrderDocument, baseOptions);
      }
export function useGetKitchenOrderLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetKitchenOrderQuery, GetKitchenOrderQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetKitchenOrderQuery, GetKitchenOrderQueryVariables>(GetKitchenOrderDocument, baseOptions);
        }
export type GetKitchenOrderQueryHookResult = ReturnType<typeof useGetKitchenOrderQuery>;
export type GetKitchenOrderLazyQueryHookResult = ReturnType<typeof useGetKitchenOrderLazyQuery>;
export type GetKitchenOrderQueryResult = ApolloReactCommon.QueryResult<GetKitchenOrderQuery, GetKitchenOrderQueryVariables>;
export const GetAccountJournalSummaryDocument = gql`
    query getAccountJournalSummary {
  bankAccountDate
  cbTransactionDate
  glJournalDate
}
    `;

/**
 * __useGetAccountJournalSummaryQuery__
 *
 * To run a query within a React component, call `useGetAccountJournalSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountJournalSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountJournalSummaryQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAccountJournalSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAccountJournalSummaryQuery, GetAccountJournalSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAccountJournalSummaryQuery, GetAccountJournalSummaryQueryVariables>(GetAccountJournalSummaryDocument, baseOptions);
      }
export function useGetAccountJournalSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAccountJournalSummaryQuery, GetAccountJournalSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAccountJournalSummaryQuery, GetAccountJournalSummaryQueryVariables>(GetAccountJournalSummaryDocument, baseOptions);
        }
export type GetAccountJournalSummaryQueryHookResult = ReturnType<typeof useGetAccountJournalSummaryQuery>;
export type GetAccountJournalSummaryLazyQueryHookResult = ReturnType<typeof useGetAccountJournalSummaryLazyQuery>;
export type GetAccountJournalSummaryQueryResult = ApolloReactCommon.QueryResult<GetAccountJournalSummaryQuery, GetAccountJournalSummaryQueryVariables>;
export const GetBankAccountDocument = gql`
    query getBankAccount($outletID: String) {
  getBankAccount(outletID: $outletID) {
    ID
    bankName
    accountName
    accountNo
    SWIFTCode
    branch
    contactNo
    address
    bankAccountContact {
      ID
      name
      designation
      mobileNo
      email
    }
    isDefault
    outletID
    bankCode
  }
}
    `;

/**
 * __useGetBankAccountQuery__
 *
 * To run a query within a React component, call `useGetBankAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBankAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBankAccountQuery({
 *   variables: {
 *      outletID: // value for 'outletID'
 *   },
 * });
 */
export function useGetBankAccountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBankAccountQuery, GetBankAccountQueryVariables>) {
        return ApolloReactHooks.useQuery<GetBankAccountQuery, GetBankAccountQueryVariables>(GetBankAccountDocument, baseOptions);
      }
export function useGetBankAccountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBankAccountQuery, GetBankAccountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetBankAccountQuery, GetBankAccountQueryVariables>(GetBankAccountDocument, baseOptions);
        }
export type GetBankAccountQueryHookResult = ReturnType<typeof useGetBankAccountQuery>;
export type GetBankAccountLazyQueryHookResult = ReturnType<typeof useGetBankAccountLazyQuery>;
export type GetBankAccountQueryResult = ApolloReactCommon.QueryResult<GetBankAccountQuery, GetBankAccountQueryVariables>;
export const CreateBankAccountDocument = gql`
    mutation createBankAccount($bankAccountInput: BankAccountInput!, $contactInput: [BankAccountContactInput!]!, $outletID: String!) {
  createBankAccount(bankAccountInput: $bankAccountInput, contactInput: $contactInput, outletID: $outletID)
}
    `;
export type CreateBankAccountMutationFn = ApolloReactCommon.MutationFunction<CreateBankAccountMutation, CreateBankAccountMutationVariables>;

/**
 * __useCreateBankAccountMutation__
 *
 * To run a mutation, you first call `useCreateBankAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBankAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBankAccountMutation, { data, loading, error }] = useCreateBankAccountMutation({
 *   variables: {
 *      bankAccountInput: // value for 'bankAccountInput'
 *      contactInput: // value for 'contactInput'
 *      outletID: // value for 'outletID'
 *   },
 * });
 */
export function useCreateBankAccountMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateBankAccountMutation, CreateBankAccountMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateBankAccountMutation, CreateBankAccountMutationVariables>(CreateBankAccountDocument, baseOptions);
      }
export type CreateBankAccountMutationHookResult = ReturnType<typeof useCreateBankAccountMutation>;
export type CreateBankAccountMutationResult = ApolloReactCommon.MutationResult<CreateBankAccountMutation>;
export type CreateBankAccountMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateBankAccountMutation, CreateBankAccountMutationVariables>;
export const UpdateBankAccountDocument = gql`
    mutation updateBankAccount($bankAccountInput: BankAccountInput!, $contactInput: [BankAccountContactInput!]!, $outletID: String!) {
  updateBankAccount(bankAccountInput: $bankAccountInput, contactInput: $contactInput, outletID: $outletID)
}
    `;
export type UpdateBankAccountMutationFn = ApolloReactCommon.MutationFunction<UpdateBankAccountMutation, UpdateBankAccountMutationVariables>;

/**
 * __useUpdateBankAccountMutation__
 *
 * To run a mutation, you first call `useUpdateBankAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBankAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBankAccountMutation, { data, loading, error }] = useUpdateBankAccountMutation({
 *   variables: {
 *      bankAccountInput: // value for 'bankAccountInput'
 *      contactInput: // value for 'contactInput'
 *      outletID: // value for 'outletID'
 *   },
 * });
 */
export function useUpdateBankAccountMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateBankAccountMutation, UpdateBankAccountMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateBankAccountMutation, UpdateBankAccountMutationVariables>(UpdateBankAccountDocument, baseOptions);
      }
export type UpdateBankAccountMutationHookResult = ReturnType<typeof useUpdateBankAccountMutation>;
export type UpdateBankAccountMutationResult = ApolloReactCommon.MutationResult<UpdateBankAccountMutation>;
export type UpdateBankAccountMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateBankAccountMutation, UpdateBankAccountMutationVariables>;
export const DeleteBankAccountDocument = gql`
    mutation deleteBankAccount($ID: String!) {
  deleteBankAccount(ID: $ID)
}
    `;
export type DeleteBankAccountMutationFn = ApolloReactCommon.MutationFunction<DeleteBankAccountMutation, DeleteBankAccountMutationVariables>;

/**
 * __useDeleteBankAccountMutation__
 *
 * To run a mutation, you first call `useDeleteBankAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBankAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBankAccountMutation, { data, loading, error }] = useDeleteBankAccountMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDeleteBankAccountMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteBankAccountMutation, DeleteBankAccountMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteBankAccountMutation, DeleteBankAccountMutationVariables>(DeleteBankAccountDocument, baseOptions);
      }
export type DeleteBankAccountMutationHookResult = ReturnType<typeof useDeleteBankAccountMutation>;
export type DeleteBankAccountMutationResult = ApolloReactCommon.MutationResult<DeleteBankAccountMutation>;
export type DeleteBankAccountMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteBankAccountMutation, DeleteBankAccountMutationVariables>;
export const DeleteBankAccountContactDocument = gql`
    mutation deleteBankAccountContact($ID: String!) {
  deleteBankAccountContact(ID: $ID)
}
    `;
export type DeleteBankAccountContactMutationFn = ApolloReactCommon.MutationFunction<DeleteBankAccountContactMutation, DeleteBankAccountContactMutationVariables>;

/**
 * __useDeleteBankAccountContactMutation__
 *
 * To run a mutation, you first call `useDeleteBankAccountContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBankAccountContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBankAccountContactMutation, { data, loading, error }] = useDeleteBankAccountContactMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDeleteBankAccountContactMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteBankAccountContactMutation, DeleteBankAccountContactMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteBankAccountContactMutation, DeleteBankAccountContactMutationVariables>(DeleteBankAccountContactDocument, baseOptions);
      }
export type DeleteBankAccountContactMutationHookResult = ReturnType<typeof useDeleteBankAccountContactMutation>;
export type DeleteBankAccountContactMutationResult = ApolloReactCommon.MutationResult<DeleteBankAccountContactMutation>;
export type DeleteBankAccountContactMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteBankAccountContactMutation, DeleteBankAccountContactMutationVariables>;
export const GetCbExportDataDocument = gql`
    query getCBExportData($accountID: String!, $outletID: String!, $startDate: DateTime!, $endDate: DateTime!) {
  getCBExportData(accountID: $accountID, outletID: $outletID, startDate: $startDate, endDate: $endDate)
}
    `;

/**
 * __useGetCbExportDataQuery__
 *
 * To run a query within a React component, call `useGetCbExportDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCbExportDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCbExportDataQuery({
 *   variables: {
 *      accountID: // value for 'accountID'
 *      outletID: // value for 'outletID'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useGetCbExportDataQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCbExportDataQuery, GetCbExportDataQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCbExportDataQuery, GetCbExportDataQueryVariables>(GetCbExportDataDocument, baseOptions);
      }
export function useGetCbExportDataLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCbExportDataQuery, GetCbExportDataQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCbExportDataQuery, GetCbExportDataQueryVariables>(GetCbExportDataDocument, baseOptions);
        }
export type GetCbExportDataQueryHookResult = ReturnType<typeof useGetCbExportDataQuery>;
export type GetCbExportDataLazyQueryHookResult = ReturnType<typeof useGetCbExportDataLazyQuery>;
export type GetCbExportDataQueryResult = ApolloReactCommon.QueryResult<GetCbExportDataQuery, GetCbExportDataQueryVariables>;
export const GetExportedCbDocument = gql`
    query getExportedCB($outletID: String!) {
  getExportedCB(outletID: $outletID)
}
    `;

/**
 * __useGetExportedCbQuery__
 *
 * To run a query within a React component, call `useGetExportedCbQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExportedCbQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExportedCbQuery({
 *   variables: {
 *      outletID: // value for 'outletID'
 *   },
 * });
 */
export function useGetExportedCbQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetExportedCbQuery, GetExportedCbQueryVariables>) {
        return ApolloReactHooks.useQuery<GetExportedCbQuery, GetExportedCbQueryVariables>(GetExportedCbDocument, baseOptions);
      }
export function useGetExportedCbLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetExportedCbQuery, GetExportedCbQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetExportedCbQuery, GetExportedCbQueryVariables>(GetExportedCbDocument, baseOptions);
        }
export type GetExportedCbQueryHookResult = ReturnType<typeof useGetExportedCbQuery>;
export type GetExportedCbLazyQueryHookResult = ReturnType<typeof useGetExportedCbLazyQuery>;
export type GetExportedCbQueryResult = ApolloReactCommon.QueryResult<GetExportedCbQuery, GetExportedCbQueryVariables>;
export const GetExportedCbDetailsDocument = gql`
    query getExportedCBDetails($batchNo: Float!, $outletID: String!) {
  getExportedCBDetails(batchNo: $batchNo, outletID: $outletID)
}
    `;

/**
 * __useGetExportedCbDetailsQuery__
 *
 * To run a query within a React component, call `useGetExportedCbDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExportedCbDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExportedCbDetailsQuery({
 *   variables: {
 *      batchNo: // value for 'batchNo'
 *      outletID: // value for 'outletID'
 *   },
 * });
 */
export function useGetExportedCbDetailsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetExportedCbDetailsQuery, GetExportedCbDetailsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetExportedCbDetailsQuery, GetExportedCbDetailsQueryVariables>(GetExportedCbDetailsDocument, baseOptions);
      }
export function useGetExportedCbDetailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetExportedCbDetailsQuery, GetExportedCbDetailsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetExportedCbDetailsQuery, GetExportedCbDetailsQueryVariables>(GetExportedCbDetailsDocument, baseOptions);
        }
export type GetExportedCbDetailsQueryHookResult = ReturnType<typeof useGetExportedCbDetailsQuery>;
export type GetExportedCbDetailsLazyQueryHookResult = ReturnType<typeof useGetExportedCbDetailsLazyQuery>;
export type GetExportedCbDetailsQueryResult = ApolloReactCommon.QueryResult<GetExportedCbDetailsQuery, GetExportedCbDetailsQueryVariables>;
export const GetExportedCbDetailsByTrxTypeDocument = gql`
    query getExportedCBDetailsByTrxType($trxDate: DateTime!, $trxType: String!, $outletID: String!, $accountID: String!) {
  getExportedCBDetailsByTrxType(trxDate: $trxDate, trxType: $trxType, outletID: $outletID, accountID: $accountID)
}
    `;

/**
 * __useGetExportedCbDetailsByTrxTypeQuery__
 *
 * To run a query within a React component, call `useGetExportedCbDetailsByTrxTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExportedCbDetailsByTrxTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExportedCbDetailsByTrxTypeQuery({
 *   variables: {
 *      trxDate: // value for 'trxDate'
 *      trxType: // value for 'trxType'
 *      outletID: // value for 'outletID'
 *      accountID: // value for 'accountID'
 *   },
 * });
 */
export function useGetExportedCbDetailsByTrxTypeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetExportedCbDetailsByTrxTypeQuery, GetExportedCbDetailsByTrxTypeQueryVariables>) {
        return ApolloReactHooks.useQuery<GetExportedCbDetailsByTrxTypeQuery, GetExportedCbDetailsByTrxTypeQueryVariables>(GetExportedCbDetailsByTrxTypeDocument, baseOptions);
      }
export function useGetExportedCbDetailsByTrxTypeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetExportedCbDetailsByTrxTypeQuery, GetExportedCbDetailsByTrxTypeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetExportedCbDetailsByTrxTypeQuery, GetExportedCbDetailsByTrxTypeQueryVariables>(GetExportedCbDetailsByTrxTypeDocument, baseOptions);
        }
export type GetExportedCbDetailsByTrxTypeQueryHookResult = ReturnType<typeof useGetExportedCbDetailsByTrxTypeQuery>;
export type GetExportedCbDetailsByTrxTypeLazyQueryHookResult = ReturnType<typeof useGetExportedCbDetailsByTrxTypeLazyQuery>;
export type GetExportedCbDetailsByTrxTypeQueryResult = ApolloReactCommon.QueryResult<GetExportedCbDetailsByTrxTypeQuery, GetExportedCbDetailsByTrxTypeQueryVariables>;
export const PostCbExportDocument = gql`
    mutation postCBExport($cbDate: DateTime!, $startDate: DateTime!, $endDate: DateTime!, $outletID: String!, $accountID: String!, $isTransfer: Boolean) {
  postCBExport(cbDate: $cbDate, startDate: $startDate, endDate: $endDate, outletID: $outletID, accountID: $accountID, isTransfer: $isTransfer)
}
    `;
export type PostCbExportMutationFn = ApolloReactCommon.MutationFunction<PostCbExportMutation, PostCbExportMutationVariables>;

/**
 * __usePostCbExportMutation__
 *
 * To run a mutation, you first call `usePostCbExportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePostCbExportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [postCbExportMutation, { data, loading, error }] = usePostCbExportMutation({
 *   variables: {
 *      cbDate: // value for 'cbDate'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      outletID: // value for 'outletID'
 *      accountID: // value for 'accountID'
 *      isTransfer: // value for 'isTransfer'
 *   },
 * });
 */
export function usePostCbExportMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PostCbExportMutation, PostCbExportMutationVariables>) {
        return ApolloReactHooks.useMutation<PostCbExportMutation, PostCbExportMutationVariables>(PostCbExportDocument, baseOptions);
      }
export type PostCbExportMutationHookResult = ReturnType<typeof usePostCbExportMutation>;
export type PostCbExportMutationResult = ApolloReactCommon.MutationResult<PostCbExportMutation>;
export type PostCbExportMutationOptions = ApolloReactCommon.BaseMutationOptions<PostCbExportMutation, PostCbExportMutationVariables>;
export const CheckBanknameInUseDocument = gql`
    query CheckBanknameInUse($bankAccountID: String!) {
  CheckBanknameInUse(bankAccountID: $bankAccountID)
}
    `;

/**
 * __useCheckBanknameInUseQuery__
 *
 * To run a query within a React component, call `useCheckBanknameInUseQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckBanknameInUseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckBanknameInUseQuery({
 *   variables: {
 *      bankAccountID: // value for 'bankAccountID'
 *   },
 * });
 */
export function useCheckBanknameInUseQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CheckBanknameInUseQuery, CheckBanknameInUseQueryVariables>) {
        return ApolloReactHooks.useQuery<CheckBanknameInUseQuery, CheckBanknameInUseQueryVariables>(CheckBanknameInUseDocument, baseOptions);
      }
export function useCheckBanknameInUseLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CheckBanknameInUseQuery, CheckBanknameInUseQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CheckBanknameInUseQuery, CheckBanknameInUseQueryVariables>(CheckBanknameInUseDocument, baseOptions);
        }
export type CheckBanknameInUseQueryHookResult = ReturnType<typeof useCheckBanknameInUseQuery>;
export type CheckBanknameInUseLazyQueryHookResult = ReturnType<typeof useCheckBanknameInUseLazyQuery>;
export type CheckBanknameInUseQueryResult = ApolloReactCommon.QueryResult<CheckBanknameInUseQuery, CheckBanknameInUseQueryVariables>;
export const GetUsersByAccountDocument = gql`
    query getUsersByAccount($accountID: String!) {
  getUsersByAccount(accountID: $accountID) {
    ID
    name
    accountID
    userName
    email
  }
}
    `;

/**
 * __useGetUsersByAccountQuery__
 *
 * To run a query within a React component, call `useGetUsersByAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersByAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersByAccountQuery({
 *   variables: {
 *      accountID: // value for 'accountID'
 *   },
 * });
 */
export function useGetUsersByAccountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUsersByAccountQuery, GetUsersByAccountQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUsersByAccountQuery, GetUsersByAccountQueryVariables>(GetUsersByAccountDocument, baseOptions);
      }
export function useGetUsersByAccountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUsersByAccountQuery, GetUsersByAccountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUsersByAccountQuery, GetUsersByAccountQueryVariables>(GetUsersByAccountDocument, baseOptions);
        }
export type GetUsersByAccountQueryHookResult = ReturnType<typeof useGetUsersByAccountQuery>;
export type GetUsersByAccountLazyQueryHookResult = ReturnType<typeof useGetUsersByAccountLazyQuery>;
export type GetUsersByAccountQueryResult = ApolloReactCommon.QueryResult<GetUsersByAccountQuery, GetUsersByAccountQueryVariables>;
export const MenuAccountXListDocument = gql`
    query MenuAccountXList($outletID: String!) {
  MenuAccountXList(outletID: $outletID) {
    ID
    outletID
    vendorName
    isActive
  }
}
    `;

/**
 * __useMenuAccountXListQuery__
 *
 * To run a query within a React component, call `useMenuAccountXListQuery` and pass it any options that fit your needs.
 * When your component renders, `useMenuAccountXListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMenuAccountXListQuery({
 *   variables: {
 *      outletID: // value for 'outletID'
 *   },
 * });
 */
export function useMenuAccountXListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MenuAccountXListQuery, MenuAccountXListQueryVariables>) {
        return ApolloReactHooks.useQuery<MenuAccountXListQuery, MenuAccountXListQueryVariables>(MenuAccountXListDocument, baseOptions);
      }
export function useMenuAccountXListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MenuAccountXListQuery, MenuAccountXListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MenuAccountXListQuery, MenuAccountXListQueryVariables>(MenuAccountXListDocument, baseOptions);
        }
export type MenuAccountXListQueryHookResult = ReturnType<typeof useMenuAccountXListQuery>;
export type MenuAccountXListLazyQueryHookResult = ReturnType<typeof useMenuAccountXListLazyQuery>;
export type MenuAccountXListQueryResult = ApolloReactCommon.QueryResult<MenuAccountXListQuery, MenuAccountXListQueryVariables>;
export const GetGlExportDocument = gql`
    query getGLExport($startDate: DateTime!, $endDate: DateTime!, $outletID: String!, $accountID: String!) {
  getGLExport(startDate: $startDate, endDate: $endDate, outletID: $outletID, accountID: $accountID)
}
    `;

/**
 * __useGetGlExportQuery__
 *
 * To run a query within a React component, call `useGetGlExportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGlExportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGlExportQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      outletID: // value for 'outletID'
 *      accountID: // value for 'accountID'
 *   },
 * });
 */
export function useGetGlExportQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetGlExportQuery, GetGlExportQueryVariables>) {
        return ApolloReactHooks.useQuery<GetGlExportQuery, GetGlExportQueryVariables>(GetGlExportDocument, baseOptions);
      }
export function useGetGlExportLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetGlExportQuery, GetGlExportQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetGlExportQuery, GetGlExportQueryVariables>(GetGlExportDocument, baseOptions);
        }
export type GetGlExportQueryHookResult = ReturnType<typeof useGetGlExportQuery>;
export type GetGlExportLazyQueryHookResult = ReturnType<typeof useGetGlExportLazyQuery>;
export type GetGlExportQueryResult = ApolloReactCommon.QueryResult<GetGlExportQuery, GetGlExportQueryVariables>;
export const GetGlListingDocument = gql`
    query getGLListing($outletID: String!) {
  getExportedGLListing(outletID: $outletID)
}
    `;

/**
 * __useGetGlListingQuery__
 *
 * To run a query within a React component, call `useGetGlListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGlListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGlListingQuery({
 *   variables: {
 *      outletID: // value for 'outletID'
 *   },
 * });
 */
export function useGetGlListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetGlListingQuery, GetGlListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetGlListingQuery, GetGlListingQueryVariables>(GetGlListingDocument, baseOptions);
      }
export function useGetGlListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetGlListingQuery, GetGlListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetGlListingQuery, GetGlListingQueryVariables>(GetGlListingDocument, baseOptions);
        }
export type GetGlListingQueryHookResult = ReturnType<typeof useGetGlListingQuery>;
export type GetGlListingLazyQueryHookResult = ReturnType<typeof useGetGlListingLazyQuery>;
export type GetGlListingQueryResult = ApolloReactCommon.QueryResult<GetGlListingQuery, GetGlListingQueryVariables>;
export const GetGlExportedDetailsDocument = gql`
    query getGLExportedDetails($batchNo: Float!, $outletID: String!) {
  getExportedGLDetails(batchNo: $batchNo, outletID: $outletID)
}
    `;

/**
 * __useGetGlExportedDetailsQuery__
 *
 * To run a query within a React component, call `useGetGlExportedDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGlExportedDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGlExportedDetailsQuery({
 *   variables: {
 *      batchNo: // value for 'batchNo'
 *      outletID: // value for 'outletID'
 *   },
 * });
 */
export function useGetGlExportedDetailsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetGlExportedDetailsQuery, GetGlExportedDetailsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetGlExportedDetailsQuery, GetGlExportedDetailsQueryVariables>(GetGlExportedDetailsDocument, baseOptions);
      }
export function useGetGlExportedDetailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetGlExportedDetailsQuery, GetGlExportedDetailsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetGlExportedDetailsQuery, GetGlExportedDetailsQueryVariables>(GetGlExportedDetailsDocument, baseOptions);
        }
export type GetGlExportedDetailsQueryHookResult = ReturnType<typeof useGetGlExportedDetailsQuery>;
export type GetGlExportedDetailsLazyQueryHookResult = ReturnType<typeof useGetGlExportedDetailsLazyQuery>;
export type GetGlExportedDetailsQueryResult = ApolloReactCommon.QueryResult<GetGlExportedDetailsQuery, GetGlExportedDetailsQueryVariables>;
export const GetGlExportDetailsByTrxTypeDocument = gql`
    query getGLExportDetailsByTrxType($trxDate: DateTime!, $trxDesc: String!, $trxType: String!, $outletID: String!, $accountID: String!) {
  getGLExportDetailsByTrxType(trxDate: $trxDate, trxDesc: $trxDesc, trxType: $trxType, outletID: $outletID, accountID: $accountID)
}
    `;

/**
 * __useGetGlExportDetailsByTrxTypeQuery__
 *
 * To run a query within a React component, call `useGetGlExportDetailsByTrxTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGlExportDetailsByTrxTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGlExportDetailsByTrxTypeQuery({
 *   variables: {
 *      trxDate: // value for 'trxDate'
 *      trxDesc: // value for 'trxDesc'
 *      trxType: // value for 'trxType'
 *      outletID: // value for 'outletID'
 *      accountID: // value for 'accountID'
 *   },
 * });
 */
export function useGetGlExportDetailsByTrxTypeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetGlExportDetailsByTrxTypeQuery, GetGlExportDetailsByTrxTypeQueryVariables>) {
        return ApolloReactHooks.useQuery<GetGlExportDetailsByTrxTypeQuery, GetGlExportDetailsByTrxTypeQueryVariables>(GetGlExportDetailsByTrxTypeDocument, baseOptions);
      }
export function useGetGlExportDetailsByTrxTypeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetGlExportDetailsByTrxTypeQuery, GetGlExportDetailsByTrxTypeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetGlExportDetailsByTrxTypeQuery, GetGlExportDetailsByTrxTypeQueryVariables>(GetGlExportDetailsByTrxTypeDocument, baseOptions);
        }
export type GetGlExportDetailsByTrxTypeQueryHookResult = ReturnType<typeof useGetGlExportDetailsByTrxTypeQuery>;
export type GetGlExportDetailsByTrxTypeLazyQueryHookResult = ReturnType<typeof useGetGlExportDetailsByTrxTypeLazyQuery>;
export type GetGlExportDetailsByTrxTypeQueryResult = ApolloReactCommon.QueryResult<GetGlExportDetailsByTrxTypeQuery, GetGlExportDetailsByTrxTypeQueryVariables>;
export const ExportedGlTransferDetailsDocument = gql`
    query ExportedGLTransferDetails($outletID: String!, $accountID: String!, $batchNo: Float!) {
  ExportedGLTransferDetails(outletID: $outletID, accountID: $accountID, batchNo: $batchNo) {
    AccountID
    CompanyName
    JournalTypeName
    JournalDate
    DocNo
    Description
    AccountName
    DepartmentCode
    MasterCOACode
    Amount
    Remark
  }
}
    `;

/**
 * __useExportedGlTransferDetailsQuery__
 *
 * To run a query within a React component, call `useExportedGlTransferDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportedGlTransferDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportedGlTransferDetailsQuery({
 *   variables: {
 *      outletID: // value for 'outletID'
 *      accountID: // value for 'accountID'
 *      batchNo: // value for 'batchNo'
 *   },
 * });
 */
export function useExportedGlTransferDetailsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ExportedGlTransferDetailsQuery, ExportedGlTransferDetailsQueryVariables>) {
        return ApolloReactHooks.useQuery<ExportedGlTransferDetailsQuery, ExportedGlTransferDetailsQueryVariables>(ExportedGlTransferDetailsDocument, baseOptions);
      }
export function useExportedGlTransferDetailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ExportedGlTransferDetailsQuery, ExportedGlTransferDetailsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ExportedGlTransferDetailsQuery, ExportedGlTransferDetailsQueryVariables>(ExportedGlTransferDetailsDocument, baseOptions);
        }
export type ExportedGlTransferDetailsQueryHookResult = ReturnType<typeof useExportedGlTransferDetailsQuery>;
export type ExportedGlTransferDetailsLazyQueryHookResult = ReturnType<typeof useExportedGlTransferDetailsLazyQuery>;
export type ExportedGlTransferDetailsQueryResult = ApolloReactCommon.QueryResult<ExportedGlTransferDetailsQuery, ExportedGlTransferDetailsQueryVariables>;
export const PostGlExportDocument = gql`
    mutation postGLExport($glDate: DateTime!, $startDate: DateTime!, $endDate: DateTime!, $outletID: String!, $accountID: String!, $isTransfer: Boolean) {
  postGLExport(glDate: $glDate, startDate: $startDate, endDate: $endDate, outletID: $outletID, accountID: $accountID, isTransfer: $isTransfer) {
    GLPost
    ExportedGLTransfer {
      AccountID
      CompanyName
      JournalTypeName
      JournalDate
      DocNo
      Description
      AccountName
      DepartmentCode
      MasterCOACode
      Amount
      Remark
    }
  }
}
    `;
export type PostGlExportMutationFn = ApolloReactCommon.MutationFunction<PostGlExportMutation, PostGlExportMutationVariables>;

/**
 * __usePostGlExportMutation__
 *
 * To run a mutation, you first call `usePostGlExportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePostGlExportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [postGlExportMutation, { data, loading, error }] = usePostGlExportMutation({
 *   variables: {
 *      glDate: // value for 'glDate'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      outletID: // value for 'outletID'
 *      accountID: // value for 'accountID'
 *      isTransfer: // value for 'isTransfer'
 *   },
 * });
 */
export function usePostGlExportMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PostGlExportMutation, PostGlExportMutationVariables>) {
        return ApolloReactHooks.useMutation<PostGlExportMutation, PostGlExportMutationVariables>(PostGlExportDocument, baseOptions);
      }
export type PostGlExportMutationHookResult = ReturnType<typeof usePostGlExportMutation>;
export type PostGlExportMutationResult = ApolloReactCommon.MutationResult<PostGlExportMutation>;
export type PostGlExportMutationOptions = ApolloReactCommon.BaseMutationOptions<PostGlExportMutation, PostGlExportMutationVariables>;
export const GetOutletBillChargesDocument = gql`
    query getOutletBillCharges($outletID: String!) {
  getOutletBillCharges(outletID: $outletID)
}
    `;

/**
 * __useGetOutletBillChargesQuery__
 *
 * To run a query within a React component, call `useGetOutletBillChargesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOutletBillChargesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOutletBillChargesQuery({
 *   variables: {
 *      outletID: // value for 'outletID'
 *   },
 * });
 */
export function useGetOutletBillChargesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetOutletBillChargesQuery, GetOutletBillChargesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetOutletBillChargesQuery, GetOutletBillChargesQueryVariables>(GetOutletBillChargesDocument, baseOptions);
      }
export function useGetOutletBillChargesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetOutletBillChargesQuery, GetOutletBillChargesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetOutletBillChargesQuery, GetOutletBillChargesQueryVariables>(GetOutletBillChargesDocument, baseOptions);
        }
export type GetOutletBillChargesQueryHookResult = ReturnType<typeof useGetOutletBillChargesQuery>;
export type GetOutletBillChargesLazyQueryHookResult = ReturnType<typeof useGetOutletBillChargesLazyQuery>;
export type GetOutletBillChargesQueryResult = ApolloReactCommon.QueryResult<GetOutletBillChargesQuery, GetOutletBillChargesQueryVariables>;
export const GetOutletBillOcChargesDocument = gql`
    query getOutletBillOCCharges($outletID: String!) {
  getOutletBillOCCharges(outletID: $outletID)
}
    `;

/**
 * __useGetOutletBillOcChargesQuery__
 *
 * To run a query within a React component, call `useGetOutletBillOcChargesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOutletBillOcChargesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOutletBillOcChargesQuery({
 *   variables: {
 *      outletID: // value for 'outletID'
 *   },
 * });
 */
export function useGetOutletBillOcChargesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetOutletBillOcChargesQuery, GetOutletBillOcChargesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetOutletBillOcChargesQuery, GetOutletBillOcChargesQueryVariables>(GetOutletBillOcChargesDocument, baseOptions);
      }
export function useGetOutletBillOcChargesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetOutletBillOcChargesQuery, GetOutletBillOcChargesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetOutletBillOcChargesQuery, GetOutletBillOcChargesQueryVariables>(GetOutletBillOcChargesDocument, baseOptions);
        }
export type GetOutletBillOcChargesQueryHookResult = ReturnType<typeof useGetOutletBillOcChargesQuery>;
export type GetOutletBillOcChargesLazyQueryHookResult = ReturnType<typeof useGetOutletBillOcChargesLazyQuery>;
export type GetOutletBillOcChargesQueryResult = ApolloReactCommon.QueryResult<GetOutletBillOcChargesQuery, GetOutletBillOcChargesQueryVariables>;
export const GetOutletBillEntChargesDocument = gql`
    query getOutletBillEntCharges($outletID: String!) {
  getOutletBillEntCharges(outletID: $outletID)
}
    `;

/**
 * __useGetOutletBillEntChargesQuery__
 *
 * To run a query within a React component, call `useGetOutletBillEntChargesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOutletBillEntChargesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOutletBillEntChargesQuery({
 *   variables: {
 *      outletID: // value for 'outletID'
 *   },
 * });
 */
export function useGetOutletBillEntChargesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetOutletBillEntChargesQuery, GetOutletBillEntChargesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetOutletBillEntChargesQuery, GetOutletBillEntChargesQueryVariables>(GetOutletBillEntChargesDocument, baseOptions);
      }
export function useGetOutletBillEntChargesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetOutletBillEntChargesQuery, GetOutletBillEntChargesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetOutletBillEntChargesQuery, GetOutletBillEntChargesQueryVariables>(GetOutletBillEntChargesDocument, baseOptions);
        }
export type GetOutletBillEntChargesQueryHookResult = ReturnType<typeof useGetOutletBillEntChargesQuery>;
export type GetOutletBillEntChargesLazyQueryHookResult = ReturnType<typeof useGetOutletBillEntChargesLazyQuery>;
export type GetOutletBillEntChargesQueryResult = ApolloReactCommon.QueryResult<GetOutletBillEntChargesQuery, GetOutletBillEntChargesQueryVariables>;
export const SubmitOutletGlInterfaceDocument = gql`
    mutation submitOutletGLInterface($outletID: String!, $glInput: AccountMappingGLInput!) {
  submitOutletGLInterface(outletID: $outletID, glInput: $glInput)
}
    `;
export type SubmitOutletGlInterfaceMutationFn = ApolloReactCommon.MutationFunction<SubmitOutletGlInterfaceMutation, SubmitOutletGlInterfaceMutationVariables>;

/**
 * __useSubmitOutletGlInterfaceMutation__
 *
 * To run a mutation, you first call `useSubmitOutletGlInterfaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitOutletGlInterfaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitOutletGlInterfaceMutation, { data, loading, error }] = useSubmitOutletGlInterfaceMutation({
 *   variables: {
 *      outletID: // value for 'outletID'
 *      glInput: // value for 'glInput'
 *   },
 * });
 */
export function useSubmitOutletGlInterfaceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SubmitOutletGlInterfaceMutation, SubmitOutletGlInterfaceMutationVariables>) {
        return ApolloReactHooks.useMutation<SubmitOutletGlInterfaceMutation, SubmitOutletGlInterfaceMutationVariables>(SubmitOutletGlInterfaceDocument, baseOptions);
      }
export type SubmitOutletGlInterfaceMutationHookResult = ReturnType<typeof useSubmitOutletGlInterfaceMutation>;
export type SubmitOutletGlInterfaceMutationResult = ApolloReactCommon.MutationResult<SubmitOutletGlInterfaceMutation>;
export type SubmitOutletGlInterfaceMutationOptions = ApolloReactCommon.BaseMutationOptions<SubmitOutletGlInterfaceMutation, SubmitOutletGlInterfaceMutationVariables>;
export const AccountMappingTaxGlListDocument = gql`
    query AccountMappingTaxGLList($outletID: String!, $accountID: String!) {
  AccountMappingTaxGLList(outletID: $outletID, accountID: $accountID)
}
    `;

/**
 * __useAccountMappingTaxGlListQuery__
 *
 * To run a query within a React component, call `useAccountMappingTaxGlListQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountMappingTaxGlListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountMappingTaxGlListQuery({
 *   variables: {
 *      outletID: // value for 'outletID'
 *      accountID: // value for 'accountID'
 *   },
 * });
 */
export function useAccountMappingTaxGlListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AccountMappingTaxGlListQuery, AccountMappingTaxGlListQueryVariables>) {
        return ApolloReactHooks.useQuery<AccountMappingTaxGlListQuery, AccountMappingTaxGlListQueryVariables>(AccountMappingTaxGlListDocument, baseOptions);
      }
export function useAccountMappingTaxGlListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AccountMappingTaxGlListQuery, AccountMappingTaxGlListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AccountMappingTaxGlListQuery, AccountMappingTaxGlListQueryVariables>(AccountMappingTaxGlListDocument, baseOptions);
        }
export type AccountMappingTaxGlListQueryHookResult = ReturnType<typeof useAccountMappingTaxGlListQuery>;
export type AccountMappingTaxGlListLazyQueryHookResult = ReturnType<typeof useAccountMappingTaxGlListLazyQuery>;
export type AccountMappingTaxGlListQueryResult = ApolloReactCommon.QueryResult<AccountMappingTaxGlListQuery, AccountMappingTaxGlListQueryVariables>;
export const AccountMappingTaxGlInsertDocument = gql`
    mutation AccountMappingTaxGLInsert($outletID: String!, $accountID: String!, $TaxGLInput: AccountMappingTaxGLInput!) {
  AccountMappingTaxGLInsert(outletID: $outletID, accountID: $accountID, TaxGLInput: $TaxGLInput)
}
    `;
export type AccountMappingTaxGlInsertMutationFn = ApolloReactCommon.MutationFunction<AccountMappingTaxGlInsertMutation, AccountMappingTaxGlInsertMutationVariables>;

/**
 * __useAccountMappingTaxGlInsertMutation__
 *
 * To run a mutation, you first call `useAccountMappingTaxGlInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAccountMappingTaxGlInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [accountMappingTaxGlInsertMutation, { data, loading, error }] = useAccountMappingTaxGlInsertMutation({
 *   variables: {
 *      outletID: // value for 'outletID'
 *      accountID: // value for 'accountID'
 *      TaxGLInput: // value for 'TaxGLInput'
 *   },
 * });
 */
export function useAccountMappingTaxGlInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AccountMappingTaxGlInsertMutation, AccountMappingTaxGlInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<AccountMappingTaxGlInsertMutation, AccountMappingTaxGlInsertMutationVariables>(AccountMappingTaxGlInsertDocument, baseOptions);
      }
export type AccountMappingTaxGlInsertMutationHookResult = ReturnType<typeof useAccountMappingTaxGlInsertMutation>;
export type AccountMappingTaxGlInsertMutationResult = ApolloReactCommon.MutationResult<AccountMappingTaxGlInsertMutation>;
export type AccountMappingTaxGlInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<AccountMappingTaxGlInsertMutation, AccountMappingTaxGlInsertMutationVariables>;
export const GetBillListingDocument = gql`
    query getBillListing($ID: String, $outletID: String, $orderByDesc: String) {
  getBill(ID: $ID, outletID: $outletID, orderByDesc: $orderByDesc) {
    ID
    outletID
    createdTs
    billNo
    signature
    billPaymentType
    businessDate
    orderID
    order {
      ID
      taxSchemeInfo
      taxSchemeFooterInfo
      docNo
      patronAccountID
      patronName
      pax
      registrationID
      patronID
      orderDiscount {
        ID
        amount
        status
        reasonCode
        remark
      }
      table {
        ID
        tableNumber
        prefix
      }
      patron {
        patronClass
        patronType {
          name
        }
      }
      totalAmount
      outstandingAmount
      serviceMode
      totalDiscountAmount
    }
    payments {
      ID
      amount
      registrationID
      receivedAmount
      changeAmount
      roundingAmount
      businessDate
      cardNo
      reasonCode
      creditCardType
      referenceNo
      paymentClass
      patronAccountID
      hotelGuestInfo
      defaultPayment {
        ID
        paymentClass
        paymentTypeInfo
      }
      status
      remark
      createdTs
      modBy
    }
    orderItem {
      ID
      billID
      refID
      unitPrice
      amount
      serviceCharge
      baseAmount
      taxAmount
      openItemName
      totalInfo
      unitPriceInfo
      quantity
      status
      orderItemTax {
        ID
        orderItemID
        taxSchemeID
        taxSchemeDetailID
        taxID
        taxRate
        taxAmount
        taxSchemeType
      }
      orderItemDiscountTax {
        ID
        orderItemID
        orderItemDiscountID
        taxSchemeID
        taxSchemeDetailID
        taxID
        taxRate
        taxAmount
      }
      orderDiscountItemTax {
        ID
        orderItemID
        orderDiscountItemID
        taxSchemeID
        taxSchemeDetailID
        taxID
        taxRate
        taxAmount
      }
      orderItemDiscount {
        orderItemID
        amount
        baseAmount
        serviceCharge
        taxAmount
        status
        reasonCode
        remark
        orderItemDiscountTax {
          ID
          orderItemID
          orderItemDiscountID
          taxSchemeID
          taxSchemeDetailID
          taxID
          taxRate
          taxAmount
        }
      }
      orderDiscountItem {
        orderItemID
        amount
        baseAmount
        serviceCharge
        taxAmount
        orderDiscountItemTax {
          ID
          orderItemID
          orderDiscountItemID
          taxSchemeID
          taxSchemeDetailID
          taxID
          taxRate
          taxAmount
        }
      }
      menuItem {
        ID
        name
      }
      orderItemOption {
        ID
        name
        orderItemID
        optionID
        orderItemOptionItem {
          ID
          price
          unitPrice
          basePrice
          taxAmount
          serviceCharge
          discountBaseAmount
          discountServiceCharge
          discountTaxAmount
          orderItemOptionItemTax {
            ID
            orderItemID
            orderItemOptionItemID
            taxSchemeID
            taxSchemeDetailID
            taxID
            taxRate
            taxAmount
          }
          orderItemOptionItemDiscountTax {
            ID
            orderItemOptionItemID
            orderItemDiscountID
            taxSchemeID
            taxSchemeDetailID
            taxID
            taxRate
            taxAmount
          }
          orderDiscountItemOptionTax {
            ID
            orderItemOptionItemID
            orderDiscountItemID
            taxSchemeID
            taxSchemeDetailID
            taxID
            taxRate
            taxAmount
          }
          optionItem {
            name
          }
        }
      }
      orderItemSetMenuOption {
        ID
        orderItemSetMenuOptionItem {
          menuItem {
            name
          }
          setMenuOptionItem {
            menuItem {
              name
            }
          }
        }
      }
    }
    status
    einvoice_qr_url
    is_einvoice
    einvoice_irbm_uniqueID
    einvoice_status
    qrCodeUrl
    documentType
    refID
    documentType
    refBillNo
    isCreditNote
  }
}
    `;

/**
 * __useGetBillListingQuery__
 *
 * To run a query within a React component, call `useGetBillListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBillListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBillListingQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      outletID: // value for 'outletID'
 *      orderByDesc: // value for 'orderByDesc'
 *   },
 * });
 */
export function useGetBillListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBillListingQuery, GetBillListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetBillListingQuery, GetBillListingQueryVariables>(GetBillListingDocument, baseOptions);
      }
export function useGetBillListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBillListingQuery, GetBillListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetBillListingQuery, GetBillListingQueryVariables>(GetBillListingDocument, baseOptions);
        }
export type GetBillListingQueryHookResult = ReturnType<typeof useGetBillListingQuery>;
export type GetBillListingLazyQueryHookResult = ReturnType<typeof useGetBillListingLazyQuery>;
export type GetBillListingQueryResult = ApolloReactCommon.QueryResult<GetBillListingQuery, GetBillListingQueryVariables>;
export const GetCnBillOrderItemDocument = gql`
    query getCNBillOrderItem($outletID: String!, $ogBillID: String!) {
  getCNBillOrderItem(outletID: $outletID, ogBillID: $ogBillID) {
    ID
    amount
    refID
    serviceCharge
    taxAmount
    unitPrice
    isTakeaway
    weight
    orderItemTax {
      ID
      orderItemID
      taxSchemeID
      taxSchemeDetailID
      taxID
      taxRate
      taxAmount
      taxSchemeType
    }
    orderItemDiscountTax {
      ID
      orderItemID
      orderItemDiscountID
      taxSchemeID
      taxSchemeDetailID
      taxID
      taxRate
      taxAmount
    }
    orderDiscountItemTax {
      ID
      orderItemID
      orderDiscountItemID
      taxSchemeID
      taxSchemeDetailID
      taxID
      taxRate
      taxAmount
    }
    unitPriceInfo
    totalInfo
    openItemName
    quantity
    baseAmount
    status
    orderItemDiscount {
      discountID
      orderItemID
      amount
      baseAmount
      serviceCharge
      taxAmount
      status
      reasonCode
      remark
      orderItemDiscountTax {
        ID
        orderItemID
        orderItemDiscountID
        taxSchemeID
        taxSchemeDetailID
        taxID
        taxRate
        taxAmount
      }
    }
    orderDiscountItem {
      orderItemID
      amount
      baseAmount
      serviceCharge
      taxAmount
      orderDiscountItemTax {
        ID
        orderItemID
        orderDiscountItemID
        taxSchemeID
        taxSchemeDetailID
        taxID
        taxRate
        taxAmount
      }
    }
    menuItem {
      ID
      name
    }
    orderItemOption {
      ID
      name
      optionID
      orderItemID
      orderItemOptionItem {
        ID
        optionItemID
        orderItemOptionItemTax {
          ID
          orderItemID
          orderItemOptionItemID
          taxSchemeID
          taxSchemeDetailID
          taxID
          taxRate
          taxAmount
        }
        orderItemOptionItemDiscountTax {
          ID
          orderItemOptionItemID
          orderItemDiscountID
          taxSchemeID
          taxSchemeDetailID
          taxID
          taxRate
          taxAmount
        }
        orderDiscountItemOptionTax {
          ID
          orderItemOptionItemID
          orderDiscountItemID
          taxSchemeID
          taxSchemeDetailID
          taxID
          taxRate
          taxAmount
        }
        price
        basePrice
        unitPrice
        taxAmount
        serviceCharge
        discountBaseAmount
        discountServiceCharge
        discountTaxAmount
        optionItem {
          name
        }
      }
    }
    orderItemSetMenuOption {
      ID
      orderItemSetMenuOptionItem {
        menuItem {
          name
        }
        setMenuOptionItem {
          menuItem {
            name
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetCnBillOrderItemQuery__
 *
 * To run a query within a React component, call `useGetCnBillOrderItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCnBillOrderItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCnBillOrderItemQuery({
 *   variables: {
 *      outletID: // value for 'outletID'
 *      ogBillID: // value for 'ogBillID'
 *   },
 * });
 */
export function useGetCnBillOrderItemQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCnBillOrderItemQuery, GetCnBillOrderItemQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCnBillOrderItemQuery, GetCnBillOrderItemQueryVariables>(GetCnBillOrderItemDocument, baseOptions);
      }
export function useGetCnBillOrderItemLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCnBillOrderItemQuery, GetCnBillOrderItemQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCnBillOrderItemQuery, GetCnBillOrderItemQueryVariables>(GetCnBillOrderItemDocument, baseOptions);
        }
export type GetCnBillOrderItemQueryHookResult = ReturnType<typeof useGetCnBillOrderItemQuery>;
export type GetCnBillOrderItemLazyQueryHookResult = ReturnType<typeof useGetCnBillOrderItemLazyQuery>;
export type GetCnBillOrderItemQueryResult = ApolloReactCommon.QueryResult<GetCnBillOrderItemQuery, GetCnBillOrderItemQueryVariables>;
export const VoidPaymentDocument = gql`
    mutation voidPayment($paymentInput: [PaymentInput!]!, $billInput: BillInput, $voidPaymentIDs: [String!]!, $billLedgerInput: BillLedgerInput, $scheduleBillingInput: ScheduleBillingInput) {
  voidPayment(paymentInput: $paymentInput, billInput: $billInput, voidPaymentIDs: $voidPaymentIDs, billLedgerInput: $billLedgerInput, scheduleBillingInput: $scheduleBillingInput)
}
    `;
export type VoidPaymentMutationFn = ApolloReactCommon.MutationFunction<VoidPaymentMutation, VoidPaymentMutationVariables>;

/**
 * __useVoidPaymentMutation__
 *
 * To run a mutation, you first call `useVoidPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVoidPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [voidPaymentMutation, { data, loading, error }] = useVoidPaymentMutation({
 *   variables: {
 *      paymentInput: // value for 'paymentInput'
 *      billInput: // value for 'billInput'
 *      voidPaymentIDs: // value for 'voidPaymentIDs'
 *      billLedgerInput: // value for 'billLedgerInput'
 *      scheduleBillingInput: // value for 'scheduleBillingInput'
 *   },
 * });
 */
export function useVoidPaymentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<VoidPaymentMutation, VoidPaymentMutationVariables>) {
        return ApolloReactHooks.useMutation<VoidPaymentMutation, VoidPaymentMutationVariables>(VoidPaymentDocument, baseOptions);
      }
export type VoidPaymentMutationHookResult = ReturnType<typeof useVoidPaymentMutation>;
export type VoidPaymentMutationResult = ApolloReactCommon.MutationResult<VoidPaymentMutation>;
export type VoidPaymentMutationOptions = ApolloReactCommon.BaseMutationOptions<VoidPaymentMutation, VoidPaymentMutationVariables>;
export const VoidBillDocument = gql`
    mutation voidBill($paymentInput: PaymentInput!, $billInput: BillInput, $billLedgerInput: BillLedgerInput, $scheduleBillingInput: ScheduleBillingInput) {
  voidBill(paymentInput: $paymentInput, billInput: $billInput, billLedgerInput: $billLedgerInput, scheduleBillingInput: $scheduleBillingInput)
}
    `;
export type VoidBillMutationFn = ApolloReactCommon.MutationFunction<VoidBillMutation, VoidBillMutationVariables>;

/**
 * __useVoidBillMutation__
 *
 * To run a mutation, you first call `useVoidBillMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVoidBillMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [voidBillMutation, { data, loading, error }] = useVoidBillMutation({
 *   variables: {
 *      paymentInput: // value for 'paymentInput'
 *      billInput: // value for 'billInput'
 *      billLedgerInput: // value for 'billLedgerInput'
 *      scheduleBillingInput: // value for 'scheduleBillingInput'
 *   },
 * });
 */
export function useVoidBillMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<VoidBillMutation, VoidBillMutationVariables>) {
        return ApolloReactHooks.useMutation<VoidBillMutation, VoidBillMutationVariables>(VoidBillDocument, baseOptions);
      }
export type VoidBillMutationHookResult = ReturnType<typeof useVoidBillMutation>;
export type VoidBillMutationResult = ApolloReactCommon.MutationResult<VoidBillMutation>;
export type VoidBillMutationOptions = ApolloReactCommon.BaseMutationOptions<VoidBillMutation, VoidBillMutationVariables>;
export const CreditNoteBillPaymentDocument = gql`
    mutation creditNoteBillPayment($billInput: BillInput!) {
  creditNoteBillPayment(billInput: $billInput)
}
    `;
export type CreditNoteBillPaymentMutationFn = ApolloReactCommon.MutationFunction<CreditNoteBillPaymentMutation, CreditNoteBillPaymentMutationVariables>;

/**
 * __useCreditNoteBillPaymentMutation__
 *
 * To run a mutation, you first call `useCreditNoteBillPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreditNoteBillPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [creditNoteBillPaymentMutation, { data, loading, error }] = useCreditNoteBillPaymentMutation({
 *   variables: {
 *      billInput: // value for 'billInput'
 *   },
 * });
 */
export function useCreditNoteBillPaymentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreditNoteBillPaymentMutation, CreditNoteBillPaymentMutationVariables>) {
        return ApolloReactHooks.useMutation<CreditNoteBillPaymentMutation, CreditNoteBillPaymentMutationVariables>(CreditNoteBillPaymentDocument, baseOptions);
      }
export type CreditNoteBillPaymentMutationHookResult = ReturnType<typeof useCreditNoteBillPaymentMutation>;
export type CreditNoteBillPaymentMutationResult = ApolloReactCommon.MutationResult<CreditNoteBillPaymentMutation>;
export type CreditNoteBillPaymentMutationOptions = ApolloReactCommon.BaseMutationOptions<CreditNoteBillPaymentMutation, CreditNoteBillPaymentMutationVariables>;
export const GetBillOffsetDocument = gql`
    query getBillOffset($input: BillInputOffset!, $offSet: Float!, $limit: Float!, $startDate: DateTime, $endDate: DateTime, $billNo: String, $paymentType: String, $customerType: String, $tabValue: Float!) {
  getBillInfinite(input: $input, offSet: $offSet, limit: $limit, startDate: $startDate, endDate: $endDate, billNo: $billNo, paymentType: $paymentType, customerType: $customerType, tabValue: $tabValue) {
    ID
    outletID
    createdTs
    billNo
    signature
    billPaymentType
    orderID
    order {
      ID
      docNo
      patronAccountID
      patronName
      taxSchemeInfo
      taxSchemeFooterInfo
      registrationID
      patronID
      orderDiscount {
        ID
        amount
        status
        reasonCode
        remark
      }
      table {
        ID
        tableNumber
        prefix
      }
      patron {
        patronClass
        patronType {
          name
        }
      }
      totalAmount
      outstandingAmount
      serviceMode
    }
    payments {
      ID
      amount
      registrationID
      receivedAmount
      changeAmount
      roundingAmount
      businessDate
      cardNo
      reasonCode
      referenceNo
      creditCardType
      hotelGuestInfo
      defaultPayment {
        ID
        paymentClass
        paymentTypeInfo
      }
      status
      remark
      createdTs
    }
    orderItem {
      ID
      billID
      refID
      unitPrice
      amount
      serviceCharge
      taxAmount
      totalInfo
      quantity
      openItemName
      menuItem {
        ID
        name
      }
      orderItemOption {
        ID
        name
        orderItemID
        orderItemOptionItem {
          ID
          price
          unitPrice
          optionItem {
            name
          }
        }
      }
      orderItemSetMenuOption {
        ID
        orderItemSetMenuOptionItem {
          menuItem {
            name
          }
          setMenuOptionItem {
            menuItem {
              name
            }
          }
        }
      }
    }
    status
    qrCodeUrl
    is_einvoice
    einvoice_irbm_uniqueID
    einvoice_status
    refID
    refBillNo
    documentType
    isCreditNote
  }
}
    `;

/**
 * __useGetBillOffsetQuery__
 *
 * To run a query within a React component, call `useGetBillOffsetQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBillOffsetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBillOffsetQuery({
 *   variables: {
 *      input: // value for 'input'
 *      offSet: // value for 'offSet'
 *      limit: // value for 'limit'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      billNo: // value for 'billNo'
 *      paymentType: // value for 'paymentType'
 *      customerType: // value for 'customerType'
 *      tabValue: // value for 'tabValue'
 *   },
 * });
 */
export function useGetBillOffsetQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBillOffsetQuery, GetBillOffsetQueryVariables>) {
        return ApolloReactHooks.useQuery<GetBillOffsetQuery, GetBillOffsetQueryVariables>(GetBillOffsetDocument, baseOptions);
      }
export function useGetBillOffsetLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBillOffsetQuery, GetBillOffsetQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetBillOffsetQuery, GetBillOffsetQueryVariables>(GetBillOffsetDocument, baseOptions);
        }
export type GetBillOffsetQueryHookResult = ReturnType<typeof useGetBillOffsetQuery>;
export type GetBillOffsetLazyQueryHookResult = ReturnType<typeof useGetBillOffsetLazyQuery>;
export type GetBillOffsetQueryResult = ApolloReactCommon.QueryResult<GetBillOffsetQuery, GetBillOffsetQueryVariables>;
export const GetBillWithDateDocument = gql`
    query getBillWithDate($outletID: String, $billNo: String, $startDate: DateTime!, $endDate: DateTime!, $offSet: Float!, $limit: Float!) {
  getBillWithDate(outletID: $outletID, billNo: $billNo, startDate: $startDate, endDate: $endDate, offSet: $offSet, limit: $limit) {
    ID
    outletID
    createdTs
    businessDate
    billNo
    signature
    orderID
    qrCodeUrl
    order {
      ID
      docNo
      patronAccountID
      patronName
      taxSchemeInfo
      taxSchemeFooterInfo
      registrationID
      patronID
      orderDiscount {
        ID
        amount
        status
        reasonCode
        remark
      }
      table {
        ID
        tableNumber
        prefix
      }
      patron {
        patronClass
        patronType {
          name
        }
      }
      totalAmount
      outstandingAmount
      serviceMode
    }
    payments {
      ID
      amount
      registrationID
      receivedAmount
      changeAmount
      roundingAmount
      businessDate
      paymentClass
      cardNo
      reasonCode
      referenceNo
      creditCardType
      hotelGuestInfo
      defaultPayment {
        ID
        paymentClass
        paymentTypeInfo
      }
      status
      remark
      createdTs
    }
    orderItem {
      ID
      billID
      refID
      unitPrice
      amount
      serviceCharge
      taxAmount
      totalInfo
      quantity
      openItemName
      menuItem {
        ID
        name
      }
      orderItemOption {
        ID
        name
        orderItemID
        orderItemOptionItem {
          ID
          price
          unitPrice
          optionItem {
            name
          }
        }
      }
      orderItemSetMenuOption {
        ID
        orderItemSetMenuOptionItem {
          menuItem {
            name
          }
          setMenuOptionItem {
            menuItem {
              name
            }
          }
        }
      }
    }
    status
    is_einvoice
    einvoice_irbm_uniqueID
    einvoice_status
  }
}
    `;

/**
 * __useGetBillWithDateQuery__
 *
 * To run a query within a React component, call `useGetBillWithDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBillWithDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBillWithDateQuery({
 *   variables: {
 *      outletID: // value for 'outletID'
 *      billNo: // value for 'billNo'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      offSet: // value for 'offSet'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetBillWithDateQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBillWithDateQuery, GetBillWithDateQueryVariables>) {
        return ApolloReactHooks.useQuery<GetBillWithDateQuery, GetBillWithDateQueryVariables>(GetBillWithDateDocument, baseOptions);
      }
export function useGetBillWithDateLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBillWithDateQuery, GetBillWithDateQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetBillWithDateQuery, GetBillWithDateQueryVariables>(GetBillWithDateDocument, baseOptions);
        }
export type GetBillWithDateQueryHookResult = ReturnType<typeof useGetBillWithDateQuery>;
export type GetBillWithDateLazyQueryHookResult = ReturnType<typeof useGetBillWithDateLazyQuery>;
export type GetBillWithDateQueryResult = ApolloReactCommon.QueryResult<GetBillWithDateQuery, GetBillWithDateQueryVariables>;
export const GetBillListingSummaryDocument = gql`
    query getBillListingSummary($outletID: String!) {
  billByDateCount(outletID: $outletID)
}
    `;

/**
 * __useGetBillListingSummaryQuery__
 *
 * To run a query within a React component, call `useGetBillListingSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBillListingSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBillListingSummaryQuery({
 *   variables: {
 *      outletID: // value for 'outletID'
 *   },
 * });
 */
export function useGetBillListingSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBillListingSummaryQuery, GetBillListingSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetBillListingSummaryQuery, GetBillListingSummaryQueryVariables>(GetBillListingSummaryDocument, baseOptions);
      }
export function useGetBillListingSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBillListingSummaryQuery, GetBillListingSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetBillListingSummaryQuery, GetBillListingSummaryQueryVariables>(GetBillListingSummaryDocument, baseOptions);
        }
export type GetBillListingSummaryQueryHookResult = ReturnType<typeof useGetBillListingSummaryQuery>;
export type GetBillListingSummaryLazyQueryHookResult = ReturnType<typeof useGetBillListingSummaryLazyQuery>;
export type GetBillListingSummaryQueryResult = ApolloReactCommon.QueryResult<GetBillListingSummaryQuery, GetBillListingSummaryQueryVariables>;
export const GetAverageMealTimeAnalysisDocument = gql`
    query getAverageMealTimeAnalysis($outletID: String!) {
  averageMealTimeAnalysis(outletID: $outletID)
}
    `;

/**
 * __useGetAverageMealTimeAnalysisQuery__
 *
 * To run a query within a React component, call `useGetAverageMealTimeAnalysisQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAverageMealTimeAnalysisQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAverageMealTimeAnalysisQuery({
 *   variables: {
 *      outletID: // value for 'outletID'
 *   },
 * });
 */
export function useGetAverageMealTimeAnalysisQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAverageMealTimeAnalysisQuery, GetAverageMealTimeAnalysisQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAverageMealTimeAnalysisQuery, GetAverageMealTimeAnalysisQueryVariables>(GetAverageMealTimeAnalysisDocument, baseOptions);
      }
export function useGetAverageMealTimeAnalysisLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAverageMealTimeAnalysisQuery, GetAverageMealTimeAnalysisQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAverageMealTimeAnalysisQuery, GetAverageMealTimeAnalysisQueryVariables>(GetAverageMealTimeAnalysisDocument, baseOptions);
        }
export type GetAverageMealTimeAnalysisQueryHookResult = ReturnType<typeof useGetAverageMealTimeAnalysisQuery>;
export type GetAverageMealTimeAnalysisLazyQueryHookResult = ReturnType<typeof useGetAverageMealTimeAnalysisLazyQuery>;
export type GetAverageMealTimeAnalysisQueryResult = ApolloReactCommon.QueryResult<GetAverageMealTimeAnalysisQuery, GetAverageMealTimeAnalysisQueryVariables>;
export const GetDiscountTypeAnalysisDocument = gql`
    query getDiscountTypeAnalysis($outletID: String!, $startDate: DateTime!, $endDate: DateTime!) {
  discountTypeAnalysis(outletID: $outletID, startDate: $startDate, endDate: $endDate)
}
    `;

/**
 * __useGetDiscountTypeAnalysisQuery__
 *
 * To run a query within a React component, call `useGetDiscountTypeAnalysisQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDiscountTypeAnalysisQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDiscountTypeAnalysisQuery({
 *   variables: {
 *      outletID: // value for 'outletID'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useGetDiscountTypeAnalysisQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDiscountTypeAnalysisQuery, GetDiscountTypeAnalysisQueryVariables>) {
        return ApolloReactHooks.useQuery<GetDiscountTypeAnalysisQuery, GetDiscountTypeAnalysisQueryVariables>(GetDiscountTypeAnalysisDocument, baseOptions);
      }
export function useGetDiscountTypeAnalysisLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDiscountTypeAnalysisQuery, GetDiscountTypeAnalysisQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetDiscountTypeAnalysisQuery, GetDiscountTypeAnalysisQueryVariables>(GetDiscountTypeAnalysisDocument, baseOptions);
        }
export type GetDiscountTypeAnalysisQueryHookResult = ReturnType<typeof useGetDiscountTypeAnalysisQuery>;
export type GetDiscountTypeAnalysisLazyQueryHookResult = ReturnType<typeof useGetDiscountTypeAnalysisLazyQuery>;
export type GetDiscountTypeAnalysisQueryResult = ApolloReactCommon.QueryResult<GetDiscountTypeAnalysisQuery, GetDiscountTypeAnalysisQueryVariables>;
export const FastMovingMenuItemAnalysisDocument = gql`
    query fastMovingMenuItemAnalysis($startDate: DateTime!, $endDate: DateTime!, $outletID: String, $majorID: String, $familyID: String, $mealPeriodID: String) {
  fastMovingMenuItemAnalysis(startDate: $startDate, endDate: $endDate, outletID: $outletID, majorID: $majorID, familyID: $familyID, mealPeriodID: $mealPeriodID)
}
    `;

/**
 * __useFastMovingMenuItemAnalysisQuery__
 *
 * To run a query within a React component, call `useFastMovingMenuItemAnalysisQuery` and pass it any options that fit your needs.
 * When your component renders, `useFastMovingMenuItemAnalysisQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFastMovingMenuItemAnalysisQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      outletID: // value for 'outletID'
 *      majorID: // value for 'majorID'
 *      familyID: // value for 'familyID'
 *      mealPeriodID: // value for 'mealPeriodID'
 *   },
 * });
 */
export function useFastMovingMenuItemAnalysisQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FastMovingMenuItemAnalysisQuery, FastMovingMenuItemAnalysisQueryVariables>) {
        return ApolloReactHooks.useQuery<FastMovingMenuItemAnalysisQuery, FastMovingMenuItemAnalysisQueryVariables>(FastMovingMenuItemAnalysisDocument, baseOptions);
      }
export function useFastMovingMenuItemAnalysisLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FastMovingMenuItemAnalysisQuery, FastMovingMenuItemAnalysisQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FastMovingMenuItemAnalysisQuery, FastMovingMenuItemAnalysisQueryVariables>(FastMovingMenuItemAnalysisDocument, baseOptions);
        }
export type FastMovingMenuItemAnalysisQueryHookResult = ReturnType<typeof useFastMovingMenuItemAnalysisQuery>;
export type FastMovingMenuItemAnalysisLazyQueryHookResult = ReturnType<typeof useFastMovingMenuItemAnalysisLazyQuery>;
export type FastMovingMenuItemAnalysisQueryResult = ApolloReactCommon.QueryResult<FastMovingMenuItemAnalysisQuery, FastMovingMenuItemAnalysisQueryVariables>;
export const MealPeriodSalesAnalysisDocument = gql`
    query mealPeriodSalesAnalysis($startDate: DateTime!, $endDate: DateTime!, $outletID: String!) {
  mealPeriodSalesAnalysis(startDate: $startDate, endDate: $endDate, outletID: $outletID)
}
    `;

/**
 * __useMealPeriodSalesAnalysisQuery__
 *
 * To run a query within a React component, call `useMealPeriodSalesAnalysisQuery` and pass it any options that fit your needs.
 * When your component renders, `useMealPeriodSalesAnalysisQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMealPeriodSalesAnalysisQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      outletID: // value for 'outletID'
 *   },
 * });
 */
export function useMealPeriodSalesAnalysisQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MealPeriodSalesAnalysisQuery, MealPeriodSalesAnalysisQueryVariables>) {
        return ApolloReactHooks.useQuery<MealPeriodSalesAnalysisQuery, MealPeriodSalesAnalysisQueryVariables>(MealPeriodSalesAnalysisDocument, baseOptions);
      }
export function useMealPeriodSalesAnalysisLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MealPeriodSalesAnalysisQuery, MealPeriodSalesAnalysisQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MealPeriodSalesAnalysisQuery, MealPeriodSalesAnalysisQueryVariables>(MealPeriodSalesAnalysisDocument, baseOptions);
        }
export type MealPeriodSalesAnalysisQueryHookResult = ReturnType<typeof useMealPeriodSalesAnalysisQuery>;
export type MealPeriodSalesAnalysisLazyQueryHookResult = ReturnType<typeof useMealPeriodSalesAnalysisLazyQuery>;
export type MealPeriodSalesAnalysisQueryResult = ApolloReactCommon.QueryResult<MealPeriodSalesAnalysisQuery, MealPeriodSalesAnalysisQueryVariables>;
export const GetOutletSalesCollectionAnalysisDocument = gql`
    query getOutletSalesCollectionAnalysis($endDate: DateTime!, $startDate: DateTime!, $outletID: String!, $frequencyType: String) {
  outletSalesCollectionAnalysis(endDate: $endDate, startDate: $startDate, outletID: $outletID, frequencyType: $frequencyType)
}
    `;

/**
 * __useGetOutletSalesCollectionAnalysisQuery__
 *
 * To run a query within a React component, call `useGetOutletSalesCollectionAnalysisQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOutletSalesCollectionAnalysisQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOutletSalesCollectionAnalysisQuery({
 *   variables: {
 *      endDate: // value for 'endDate'
 *      startDate: // value for 'startDate'
 *      outletID: // value for 'outletID'
 *      frequencyType: // value for 'frequencyType'
 *   },
 * });
 */
export function useGetOutletSalesCollectionAnalysisQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetOutletSalesCollectionAnalysisQuery, GetOutletSalesCollectionAnalysisQueryVariables>) {
        return ApolloReactHooks.useQuery<GetOutletSalesCollectionAnalysisQuery, GetOutletSalesCollectionAnalysisQueryVariables>(GetOutletSalesCollectionAnalysisDocument, baseOptions);
      }
export function useGetOutletSalesCollectionAnalysisLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetOutletSalesCollectionAnalysisQuery, GetOutletSalesCollectionAnalysisQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetOutletSalesCollectionAnalysisQuery, GetOutletSalesCollectionAnalysisQueryVariables>(GetOutletSalesCollectionAnalysisDocument, baseOptions);
        }
export type GetOutletSalesCollectionAnalysisQueryHookResult = ReturnType<typeof useGetOutletSalesCollectionAnalysisQuery>;
export type GetOutletSalesCollectionAnalysisLazyQueryHookResult = ReturnType<typeof useGetOutletSalesCollectionAnalysisLazyQuery>;
export type GetOutletSalesCollectionAnalysisQueryResult = ApolloReactCommon.QueryResult<GetOutletSalesCollectionAnalysisQuery, GetOutletSalesCollectionAnalysisQueryVariables>;
export const GetPatronTypeAnalysisDocument = gql`
    query getPatronTypeAnalysis($outletID: String!, $startDate: DateTime!, $endDate: DateTime!) {
  patronTypeAnalysis(outletID: $outletID, startDate: $startDate, endDate: $endDate)
}
    `;

/**
 * __useGetPatronTypeAnalysisQuery__
 *
 * To run a query within a React component, call `useGetPatronTypeAnalysisQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPatronTypeAnalysisQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPatronTypeAnalysisQuery({
 *   variables: {
 *      outletID: // value for 'outletID'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useGetPatronTypeAnalysisQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPatronTypeAnalysisQuery, GetPatronTypeAnalysisQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPatronTypeAnalysisQuery, GetPatronTypeAnalysisQueryVariables>(GetPatronTypeAnalysisDocument, baseOptions);
      }
export function useGetPatronTypeAnalysisLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPatronTypeAnalysisQuery, GetPatronTypeAnalysisQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPatronTypeAnalysisQuery, GetPatronTypeAnalysisQueryVariables>(GetPatronTypeAnalysisDocument, baseOptions);
        }
export type GetPatronTypeAnalysisQueryHookResult = ReturnType<typeof useGetPatronTypeAnalysisQuery>;
export type GetPatronTypeAnalysisLazyQueryHookResult = ReturnType<typeof useGetPatronTypeAnalysisLazyQuery>;
export type GetPatronTypeAnalysisQueryResult = ApolloReactCommon.QueryResult<GetPatronTypeAnalysisQuery, GetPatronTypeAnalysisQueryVariables>;
export const GetSlowMovingMenuItemAnalysisDocument = gql`
    query getSlowMovingMenuItemAnalysis($endDate: DateTime!, $startDate: DateTime!, $outletID: String, $mealPeriodID: String, $familyID: String, $majorID: String) {
  slowMovingMenuItemAnalysis(endDate: $endDate, startDate: $startDate, outletID: $outletID, mealPeriodID: $mealPeriodID, familyID: $familyID, majorID: $majorID)
}
    `;

/**
 * __useGetSlowMovingMenuItemAnalysisQuery__
 *
 * To run a query within a React component, call `useGetSlowMovingMenuItemAnalysisQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSlowMovingMenuItemAnalysisQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSlowMovingMenuItemAnalysisQuery({
 *   variables: {
 *      endDate: // value for 'endDate'
 *      startDate: // value for 'startDate'
 *      outletID: // value for 'outletID'
 *      mealPeriodID: // value for 'mealPeriodID'
 *      familyID: // value for 'familyID'
 *      majorID: // value for 'majorID'
 *   },
 * });
 */
export function useGetSlowMovingMenuItemAnalysisQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSlowMovingMenuItemAnalysisQuery, GetSlowMovingMenuItemAnalysisQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSlowMovingMenuItemAnalysisQuery, GetSlowMovingMenuItemAnalysisQueryVariables>(GetSlowMovingMenuItemAnalysisDocument, baseOptions);
      }
export function useGetSlowMovingMenuItemAnalysisLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSlowMovingMenuItemAnalysisQuery, GetSlowMovingMenuItemAnalysisQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSlowMovingMenuItemAnalysisQuery, GetSlowMovingMenuItemAnalysisQueryVariables>(GetSlowMovingMenuItemAnalysisDocument, baseOptions);
        }
export type GetSlowMovingMenuItemAnalysisQueryHookResult = ReturnType<typeof useGetSlowMovingMenuItemAnalysisQuery>;
export type GetSlowMovingMenuItemAnalysisLazyQueryHookResult = ReturnType<typeof useGetSlowMovingMenuItemAnalysisLazyQuery>;
export type GetSlowMovingMenuItemAnalysisQueryResult = ApolloReactCommon.QueryResult<GetSlowMovingMenuItemAnalysisQuery, GetSlowMovingMenuItemAnalysisQueryVariables>;
export const GetWaiterDocument = gql`
    query getWaiter($outletID: String!) {
  getUsersWaiterRole(outletID: $outletID) {
    ID
    name
  }
}
    `;

/**
 * __useGetWaiterQuery__
 *
 * To run a query within a React component, call `useGetWaiterQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWaiterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWaiterQuery({
 *   variables: {
 *      outletID: // value for 'outletID'
 *   },
 * });
 */
export function useGetWaiterQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetWaiterQuery, GetWaiterQueryVariables>) {
        return ApolloReactHooks.useQuery<GetWaiterQuery, GetWaiterQueryVariables>(GetWaiterDocument, baseOptions);
      }
export function useGetWaiterLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetWaiterQuery, GetWaiterQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetWaiterQuery, GetWaiterQueryVariables>(GetWaiterDocument, baseOptions);
        }
export type GetWaiterQueryHookResult = ReturnType<typeof useGetWaiterQuery>;
export type GetWaiterLazyQueryHookResult = ReturnType<typeof useGetWaiterLazyQuery>;
export type GetWaiterQueryResult = ApolloReactCommon.QueryResult<GetWaiterQuery, GetWaiterQueryVariables>;
export const GetWaiterSalesCollectionAnalysisDocument = gql`
    query getWaiterSalesCollectionAnalysis($outletID: String, $userID: String, $salesAmount: Float, $selectedDate: DateTime) {
  waiterSalesCollectionAnalysis(outletID: $outletID, userID: $userID, salesAmount: $salesAmount, selectedDate: $selectedDate)
}
    `;

/**
 * __useGetWaiterSalesCollectionAnalysisQuery__
 *
 * To run a query within a React component, call `useGetWaiterSalesCollectionAnalysisQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWaiterSalesCollectionAnalysisQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWaiterSalesCollectionAnalysisQuery({
 *   variables: {
 *      outletID: // value for 'outletID'
 *      userID: // value for 'userID'
 *      salesAmount: // value for 'salesAmount'
 *      selectedDate: // value for 'selectedDate'
 *   },
 * });
 */
export function useGetWaiterSalesCollectionAnalysisQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetWaiterSalesCollectionAnalysisQuery, GetWaiterSalesCollectionAnalysisQueryVariables>) {
        return ApolloReactHooks.useQuery<GetWaiterSalesCollectionAnalysisQuery, GetWaiterSalesCollectionAnalysisQueryVariables>(GetWaiterSalesCollectionAnalysisDocument, baseOptions);
      }
export function useGetWaiterSalesCollectionAnalysisLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetWaiterSalesCollectionAnalysisQuery, GetWaiterSalesCollectionAnalysisQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetWaiterSalesCollectionAnalysisQuery, GetWaiterSalesCollectionAnalysisQueryVariables>(GetWaiterSalesCollectionAnalysisDocument, baseOptions);
        }
export type GetWaiterSalesCollectionAnalysisQueryHookResult = ReturnType<typeof useGetWaiterSalesCollectionAnalysisQuery>;
export type GetWaiterSalesCollectionAnalysisLazyQueryHookResult = ReturnType<typeof useGetWaiterSalesCollectionAnalysisLazyQuery>;
export type GetWaiterSalesCollectionAnalysisQueryResult = ApolloReactCommon.QueryResult<GetWaiterSalesCollectionAnalysisQuery, GetWaiterSalesCollectionAnalysisQueryVariables>;
export const GetShareAttachmentDocument = gql`
    mutation getShareAttachment($file: Upload!, $outletID: String!) {
  shareAttachment(file: $file, outletID: $outletID) {
    ID
    bucketFileName
    fileName
    fileURL
  }
}
    `;
export type GetShareAttachmentMutationFn = ApolloReactCommon.MutationFunction<GetShareAttachmentMutation, GetShareAttachmentMutationVariables>;

/**
 * __useGetShareAttachmentMutation__
 *
 * To run a mutation, you first call `useGetShareAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetShareAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getShareAttachmentMutation, { data, loading, error }] = useGetShareAttachmentMutation({
 *   variables: {
 *      file: // value for 'file'
 *      outletID: // value for 'outletID'
 *   },
 * });
 */
export function useGetShareAttachmentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<GetShareAttachmentMutation, GetShareAttachmentMutationVariables>) {
        return ApolloReactHooks.useMutation<GetShareAttachmentMutation, GetShareAttachmentMutationVariables>(GetShareAttachmentDocument, baseOptions);
      }
export type GetShareAttachmentMutationHookResult = ReturnType<typeof useGetShareAttachmentMutation>;
export type GetShareAttachmentMutationResult = ApolloReactCommon.MutationResult<GetShareAttachmentMutation>;
export type GetShareAttachmentMutationOptions = ApolloReactCommon.BaseMutationOptions<GetShareAttachmentMutation, GetShareAttachmentMutationVariables>;
export const GetBusinessAnalyticsSummaryDocument = gql`
    query getBusinessAnalyticsSummary {
  outletSalesDate
  mealPeriodDate
  waiterSalesDate
  fastMovingMenuItemDate
  slowMovingMenuItemDate
  patronTypeDate
  discountTypeDate
  averageMealTimeDate
}
    `;

/**
 * __useGetBusinessAnalyticsSummaryQuery__
 *
 * To run a query within a React component, call `useGetBusinessAnalyticsSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBusinessAnalyticsSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBusinessAnalyticsSummaryQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBusinessAnalyticsSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBusinessAnalyticsSummaryQuery, GetBusinessAnalyticsSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetBusinessAnalyticsSummaryQuery, GetBusinessAnalyticsSummaryQueryVariables>(GetBusinessAnalyticsSummaryDocument, baseOptions);
      }
export function useGetBusinessAnalyticsSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBusinessAnalyticsSummaryQuery, GetBusinessAnalyticsSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetBusinessAnalyticsSummaryQuery, GetBusinessAnalyticsSummaryQueryVariables>(GetBusinessAnalyticsSummaryDocument, baseOptions);
        }
export type GetBusinessAnalyticsSummaryQueryHookResult = ReturnType<typeof useGetBusinessAnalyticsSummaryQuery>;
export type GetBusinessAnalyticsSummaryLazyQueryHookResult = ReturnType<typeof useGetBusinessAnalyticsSummaryLazyQuery>;
export type GetBusinessAnalyticsSummaryQueryResult = ApolloReactCommon.QueryResult<GetBusinessAnalyticsSummaryQuery, GetBusinessAnalyticsSummaryQueryVariables>;
export const GetBizInsightOutletDocument = gql`
    query getBizInsightOutlet {
  getOutlet {
    ID
    name
  }
}
    `;

/**
 * __useGetBizInsightOutletQuery__
 *
 * To run a query within a React component, call `useGetBizInsightOutletQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBizInsightOutletQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBizInsightOutletQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBizInsightOutletQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBizInsightOutletQuery, GetBizInsightOutletQueryVariables>) {
        return ApolloReactHooks.useQuery<GetBizInsightOutletQuery, GetBizInsightOutletQueryVariables>(GetBizInsightOutletDocument, baseOptions);
      }
export function useGetBizInsightOutletLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBizInsightOutletQuery, GetBizInsightOutletQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetBizInsightOutletQuery, GetBizInsightOutletQueryVariables>(GetBizInsightOutletDocument, baseOptions);
        }
export type GetBizInsightOutletQueryHookResult = ReturnType<typeof useGetBizInsightOutletQuery>;
export type GetBizInsightOutletLazyQueryHookResult = ReturnType<typeof useGetBizInsightOutletLazyQuery>;
export type GetBizInsightOutletQueryResult = ApolloReactCommon.QueryResult<GetBizInsightOutletQuery, GetBizInsightOutletQueryVariables>;
export const GetCompanyListingDocument = gql`
    query getCompanyListing($accountID: String!, $recordStatus: String) {
  getCompanyListing(accountID: $accountID, recordStatus: $recordStatus) {
    id
    parentCompanyId
    accountId
    baseCurrencyId
    name
    description
    companyRegNo
    code
    address {
      address
      city
      state
      country
      postCode
    }
    contactNo
    email
    sstNo
    gstNo
    ttxNo
    gstExpiryDate
    image
    imageUrl
    imageBucketFile
    websiteUrl
    eInvClientID
    eInvClientSecret
    eInvClientSecret2
    regionId
    imageLogoPosition
    watermarkImplementation
    watermarkWording
    isoImplementation
    isoLogoPosition
    companyTax
    recordStatus
    groupGstRegNo
    officeTel
    tin
    eSign
    eSignExpiryDate
    isEInvIntegrated
    eInvStartDate
    eInvEndDate
  }
}
    `;

/**
 * __useGetCompanyListingQuery__
 *
 * To run a query within a React component, call `useGetCompanyListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyListingQuery({
 *   variables: {
 *      accountID: // value for 'accountID'
 *      recordStatus: // value for 'recordStatus'
 *   },
 * });
 */
export function useGetCompanyListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCompanyListingQuery, GetCompanyListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCompanyListingQuery, GetCompanyListingQueryVariables>(GetCompanyListingDocument, baseOptions);
      }
export function useGetCompanyListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCompanyListingQuery, GetCompanyListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCompanyListingQuery, GetCompanyListingQueryVariables>(GetCompanyListingDocument, baseOptions);
        }
export type GetCompanyListingQueryHookResult = ReturnType<typeof useGetCompanyListingQuery>;
export type GetCompanyListingLazyQueryHookResult = ReturnType<typeof useGetCompanyListingLazyQuery>;
export type GetCompanyListingQueryResult = ApolloReactCommon.QueryResult<GetCompanyListingQuery, GetCompanyListingQueryVariables>;
export const GetCompanyListingByIdDocument = gql`
    query getCompanyListingByID($accountID: String!, $ID: String) {
  getCompanyListingByID(accountID: $accountID, ID: $ID) {
    id
    parentCompanyId
    accountId
    baseCurrencyId
    name
    description
    companyRegNo
    code
    address {
      address
      city
      state
      country
      postCode
    }
    contactNo
    email
    sstNo
    gstNo
    ttxNo
    gstExpiryDate
    image
    imageUrl
    imageBucketFile
    websiteUrl
    regionId
    imageLogoPosition
    watermarkImplementation
    watermarkWording
    isoImplementation
    isoLogoPosition
    companyTax
    recordStatus
    groupGstRegNo
    officeTel
    tin
    eSign
    eSignExpiryDate
  }
}
    `;

/**
 * __useGetCompanyListingByIdQuery__
 *
 * To run a query within a React component, call `useGetCompanyListingByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyListingByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyListingByIdQuery({
 *   variables: {
 *      accountID: // value for 'accountID'
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useGetCompanyListingByIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCompanyListingByIdQuery, GetCompanyListingByIdQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCompanyListingByIdQuery, GetCompanyListingByIdQueryVariables>(GetCompanyListingByIdDocument, baseOptions);
      }
export function useGetCompanyListingByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCompanyListingByIdQuery, GetCompanyListingByIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCompanyListingByIdQuery, GetCompanyListingByIdQueryVariables>(GetCompanyListingByIdDocument, baseOptions);
        }
export type GetCompanyListingByIdQueryHookResult = ReturnType<typeof useGetCompanyListingByIdQuery>;
export type GetCompanyListingByIdLazyQueryHookResult = ReturnType<typeof useGetCompanyListingByIdLazyQuery>;
export type GetCompanyListingByIdQueryResult = ApolloReactCommon.QueryResult<GetCompanyListingByIdQuery, GetCompanyListingByIdQueryVariables>;
export const CreateCompanyDocument = gql`
    mutation createCompany($companyInput: CompanyInput!) {
  createCompany(companyInput: $companyInput)
}
    `;
export type CreateCompanyMutationFn = ApolloReactCommon.MutationFunction<CreateCompanyMutation, CreateCompanyMutationVariables>;

/**
 * __useCreateCompanyMutation__
 *
 * To run a mutation, you first call `useCreateCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCompanyMutation, { data, loading, error }] = useCreateCompanyMutation({
 *   variables: {
 *      companyInput: // value for 'companyInput'
 *   },
 * });
 */
export function useCreateCompanyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCompanyMutation, CreateCompanyMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateCompanyMutation, CreateCompanyMutationVariables>(CreateCompanyDocument, baseOptions);
      }
export type CreateCompanyMutationHookResult = ReturnType<typeof useCreateCompanyMutation>;
export type CreateCompanyMutationResult = ApolloReactCommon.MutationResult<CreateCompanyMutation>;
export type CreateCompanyMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateCompanyMutation, CreateCompanyMutationVariables>;
export const UpdateCompanyDocument = gql`
    mutation updateCompany($companyInput: CompanyInput!) {
  updateCompany(companyInput: $companyInput)
}
    `;
export type UpdateCompanyMutationFn = ApolloReactCommon.MutationFunction<UpdateCompanyMutation, UpdateCompanyMutationVariables>;

/**
 * __useUpdateCompanyMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyMutation, { data, loading, error }] = useUpdateCompanyMutation({
 *   variables: {
 *      companyInput: // value for 'companyInput'
 *   },
 * });
 */
export function useUpdateCompanyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCompanyMutation, UpdateCompanyMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateCompanyMutation, UpdateCompanyMutationVariables>(UpdateCompanyDocument, baseOptions);
      }
export type UpdateCompanyMutationHookResult = ReturnType<typeof useUpdateCompanyMutation>;
export type UpdateCompanyMutationResult = ApolloReactCommon.MutationResult<UpdateCompanyMutation>;
export type UpdateCompanyMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateCompanyMutation, UpdateCompanyMutationVariables>;
export const CompanyUpdateStatusDocument = gql`
    mutation companyUpdateStatus($status: String!, $companyID: String!) {
  companyUpdateStatus(status: $status, companyID: $companyID)
}
    `;
export type CompanyUpdateStatusMutationFn = ApolloReactCommon.MutationFunction<CompanyUpdateStatusMutation, CompanyUpdateStatusMutationVariables>;

/**
 * __useCompanyUpdateStatusMutation__
 *
 * To run a mutation, you first call `useCompanyUpdateStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyUpdateStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyUpdateStatusMutation, { data, loading, error }] = useCompanyUpdateStatusMutation({
 *   variables: {
 *      status: // value for 'status'
 *      companyID: // value for 'companyID'
 *   },
 * });
 */
export function useCompanyUpdateStatusMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CompanyUpdateStatusMutation, CompanyUpdateStatusMutationVariables>) {
        return ApolloReactHooks.useMutation<CompanyUpdateStatusMutation, CompanyUpdateStatusMutationVariables>(CompanyUpdateStatusDocument, baseOptions);
      }
export type CompanyUpdateStatusMutationHookResult = ReturnType<typeof useCompanyUpdateStatusMutation>;
export type CompanyUpdateStatusMutationResult = ApolloReactCommon.MutationResult<CompanyUpdateStatusMutation>;
export type CompanyUpdateStatusMutationOptions = ApolloReactCommon.BaseMutationOptions<CompanyUpdateStatusMutation, CompanyUpdateStatusMutationVariables>;
export const CurrencyListingDocument = gql`
    query CurrencyListing {
  CurrencyListing {
    id
    code
    name
    symbol
    format
    common_status
  }
}
    `;

/**
 * __useCurrencyListingQuery__
 *
 * To run a query within a React component, call `useCurrencyListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrencyListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrencyListingQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrencyListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CurrencyListingQuery, CurrencyListingQueryVariables>) {
        return ApolloReactHooks.useQuery<CurrencyListingQuery, CurrencyListingQueryVariables>(CurrencyListingDocument, baseOptions);
      }
export function useCurrencyListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CurrencyListingQuery, CurrencyListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CurrencyListingQuery, CurrencyListingQueryVariables>(CurrencyListingDocument, baseOptions);
        }
export type CurrencyListingQueryHookResult = ReturnType<typeof useCurrencyListingQuery>;
export type CurrencyListingLazyQueryHookResult = ReturnType<typeof useCurrencyListingLazyQuery>;
export type CurrencyListingQueryResult = ApolloReactCommon.QueryResult<CurrencyListingQuery, CurrencyListingQueryVariables>;
export const GetEInvoiceSubDocument = gql`
    query getEInvoiceSub($accountID: String!) {
  getEInvoiceSub(accountID: $accountID) {
    ID
    isActive
    createdTs
    createdBy
    modTs
    modBy
    accountID
  }
}
    `;

/**
 * __useGetEInvoiceSubQuery__
 *
 * To run a query within a React component, call `useGetEInvoiceSubQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEInvoiceSubQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEInvoiceSubQuery({
 *   variables: {
 *      accountID: // value for 'accountID'
 *   },
 * });
 */
export function useGetEInvoiceSubQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetEInvoiceSubQuery, GetEInvoiceSubQueryVariables>) {
        return ApolloReactHooks.useQuery<GetEInvoiceSubQuery, GetEInvoiceSubQueryVariables>(GetEInvoiceSubDocument, baseOptions);
      }
export function useGetEInvoiceSubLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetEInvoiceSubQuery, GetEInvoiceSubQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetEInvoiceSubQuery, GetEInvoiceSubQueryVariables>(GetEInvoiceSubDocument, baseOptions);
        }
export type GetEInvoiceSubQueryHookResult = ReturnType<typeof useGetEInvoiceSubQuery>;
export type GetEInvoiceSubLazyQueryHookResult = ReturnType<typeof useGetEInvoiceSubLazyQuery>;
export type GetEInvoiceSubQueryResult = ApolloReactCommon.QueryResult<GetEInvoiceSubQuery, GetEInvoiceSubQueryVariables>;
export const EInvoiceInformationDocument = gql`
    query EInvoiceInformation($outletID: String!, $ID: String!, $type: String!) {
  EInvoiceInformation(outletID: $outletID, ID: $ID, type: $type)
}
    `;

/**
 * __useEInvoiceInformationQuery__
 *
 * To run a query within a React component, call `useEInvoiceInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useEInvoiceInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEInvoiceInformationQuery({
 *   variables: {
 *      outletID: // value for 'outletID'
 *      ID: // value for 'ID'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useEInvoiceInformationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EInvoiceInformationQuery, EInvoiceInformationQueryVariables>) {
        return ApolloReactHooks.useQuery<EInvoiceInformationQuery, EInvoiceInformationQueryVariables>(EInvoiceInformationDocument, baseOptions);
      }
export function useEInvoiceInformationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EInvoiceInformationQuery, EInvoiceInformationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EInvoiceInformationQuery, EInvoiceInformationQueryVariables>(EInvoiceInformationDocument, baseOptions);
        }
export type EInvoiceInformationQueryHookResult = ReturnType<typeof useEInvoiceInformationQuery>;
export type EInvoiceInformationLazyQueryHookResult = ReturnType<typeof useEInvoiceInformationLazyQuery>;
export type EInvoiceInformationQueryResult = ApolloReactCommon.QueryResult<EInvoiceInformationQuery, EInvoiceInformationQueryVariables>;
export const GetTaxSchemeListingDocument = gql`
    query getTaxSchemeListing($accountID: String!) {
  getTaxSchemeListing(accountID: $accountID) {
    id
    name
    description
    isExclusive
    commonStatus
    taxSchemeDetails {
      id
      taxSchemeId
      effectiveDate
      commonStatus
      taxSchemeDetailItems {
        id
        taxSchemeDetailId
        computeMethod
        taxId
        seqNo
        taxOnSeq {
          taxSchemeDetailItemId
          seqNo
        }
        commonStatus
        taxSchemeDetail {
          id
          taxSchemeId
          effectiveDate
          commonStatus
          taxScheme {
            id
            name
            description
            isExclusive
            commonStatus
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetTaxSchemeListingQuery__
 *
 * To run a query within a React component, call `useGetTaxSchemeListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaxSchemeListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaxSchemeListingQuery({
 *   variables: {
 *      accountID: // value for 'accountID'
 *   },
 * });
 */
export function useGetTaxSchemeListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetTaxSchemeListingQuery, GetTaxSchemeListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetTaxSchemeListingQuery, GetTaxSchemeListingQueryVariables>(GetTaxSchemeListingDocument, baseOptions);
      }
export function useGetTaxSchemeListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTaxSchemeListingQuery, GetTaxSchemeListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetTaxSchemeListingQuery, GetTaxSchemeListingQueryVariables>(GetTaxSchemeListingDocument, baseOptions);
        }
export type GetTaxSchemeListingQueryHookResult = ReturnType<typeof useGetTaxSchemeListingQuery>;
export type GetTaxSchemeListingLazyQueryHookResult = ReturnType<typeof useGetTaxSchemeListingLazyQuery>;
export type GetTaxSchemeListingQueryResult = ApolloReactCommon.QueryResult<GetTaxSchemeListingQuery, GetTaxSchemeListingQueryVariables>;
export const GetTaxListingDropdownDocument = gql`
    query getTaxListingDropdown($accountID: String!, $softwareCode: String!) {
  getTaxListingDropdown(accountID: $accountID, softwareCode: $softwareCode) {
    tax_id
    code
    description
    class_type
    tax_status
    effective_date
    rate
    rn
    tax_type
  }
}
    `;

/**
 * __useGetTaxListingDropdownQuery__
 *
 * To run a query within a React component, call `useGetTaxListingDropdownQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaxListingDropdownQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaxListingDropdownQuery({
 *   variables: {
 *      accountID: // value for 'accountID'
 *      softwareCode: // value for 'softwareCode'
 *   },
 * });
 */
export function useGetTaxListingDropdownQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetTaxListingDropdownQuery, GetTaxListingDropdownQueryVariables>) {
        return ApolloReactHooks.useQuery<GetTaxListingDropdownQuery, GetTaxListingDropdownQueryVariables>(GetTaxListingDropdownDocument, baseOptions);
      }
export function useGetTaxListingDropdownLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTaxListingDropdownQuery, GetTaxListingDropdownQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetTaxListingDropdownQuery, GetTaxListingDropdownQueryVariables>(GetTaxListingDropdownDocument, baseOptions);
        }
export type GetTaxListingDropdownQueryHookResult = ReturnType<typeof useGetTaxListingDropdownQuery>;
export type GetTaxListingDropdownLazyQueryHookResult = ReturnType<typeof useGetTaxListingDropdownLazyQuery>;
export type GetTaxListingDropdownQueryResult = ApolloReactCommon.QueryResult<GetTaxListingDropdownQuery, GetTaxListingDropdownQueryVariables>;
export const TaxSchemeInsertDocument = gql`
    mutation taxSchemeInsert($accountID: String!, $taxSchemeInput: TaxSchemeInput!) {
  taxSchemeInsert(accountID: $accountID, taxSchemeInput: $taxSchemeInput)
}
    `;
export type TaxSchemeInsertMutationFn = ApolloReactCommon.MutationFunction<TaxSchemeInsertMutation, TaxSchemeInsertMutationVariables>;

/**
 * __useTaxSchemeInsertMutation__
 *
 * To run a mutation, you first call `useTaxSchemeInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTaxSchemeInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [taxSchemeInsertMutation, { data, loading, error }] = useTaxSchemeInsertMutation({
 *   variables: {
 *      accountID: // value for 'accountID'
 *      taxSchemeInput: // value for 'taxSchemeInput'
 *   },
 * });
 */
export function useTaxSchemeInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TaxSchemeInsertMutation, TaxSchemeInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<TaxSchemeInsertMutation, TaxSchemeInsertMutationVariables>(TaxSchemeInsertDocument, baseOptions);
      }
export type TaxSchemeInsertMutationHookResult = ReturnType<typeof useTaxSchemeInsertMutation>;
export type TaxSchemeInsertMutationResult = ApolloReactCommon.MutationResult<TaxSchemeInsertMutation>;
export type TaxSchemeInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<TaxSchemeInsertMutation, TaxSchemeInsertMutationVariables>;
export const TaxSchemeUpdateDocument = gql`
    mutation taxSchemeUpdate($accountID: String!, $editTaxSchemeInput: EditTaxSchemeInput!) {
  taxSchemeUpdate(accountID: $accountID, editTaxSchemeInput: $editTaxSchemeInput)
}
    `;
export type TaxSchemeUpdateMutationFn = ApolloReactCommon.MutationFunction<TaxSchemeUpdateMutation, TaxSchemeUpdateMutationVariables>;

/**
 * __useTaxSchemeUpdateMutation__
 *
 * To run a mutation, you first call `useTaxSchemeUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTaxSchemeUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [taxSchemeUpdateMutation, { data, loading, error }] = useTaxSchemeUpdateMutation({
 *   variables: {
 *      accountID: // value for 'accountID'
 *      editTaxSchemeInput: // value for 'editTaxSchemeInput'
 *   },
 * });
 */
export function useTaxSchemeUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TaxSchemeUpdateMutation, TaxSchemeUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<TaxSchemeUpdateMutation, TaxSchemeUpdateMutationVariables>(TaxSchemeUpdateDocument, baseOptions);
      }
export type TaxSchemeUpdateMutationHookResult = ReturnType<typeof useTaxSchemeUpdateMutation>;
export type TaxSchemeUpdateMutationResult = ApolloReactCommon.MutationResult<TaxSchemeUpdateMutation>;
export type TaxSchemeUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<TaxSchemeUpdateMutation, TaxSchemeUpdateMutationVariables>;
export const TaxSchemeDeleteDocument = gql`
    mutation taxSchemeDelete($TaxSchemeID: String!) {
  taxSchemeDelete(TaxSchemeID: $TaxSchemeID)
}
    `;
export type TaxSchemeDeleteMutationFn = ApolloReactCommon.MutationFunction<TaxSchemeDeleteMutation, TaxSchemeDeleteMutationVariables>;

/**
 * __useTaxSchemeDeleteMutation__
 *
 * To run a mutation, you first call `useTaxSchemeDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTaxSchemeDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [taxSchemeDeleteMutation, { data, loading, error }] = useTaxSchemeDeleteMutation({
 *   variables: {
 *      TaxSchemeID: // value for 'TaxSchemeID'
 *   },
 * });
 */
export function useTaxSchemeDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TaxSchemeDeleteMutation, TaxSchemeDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<TaxSchemeDeleteMutation, TaxSchemeDeleteMutationVariables>(TaxSchemeDeleteDocument, baseOptions);
      }
export type TaxSchemeDeleteMutationHookResult = ReturnType<typeof useTaxSchemeDeleteMutation>;
export type TaxSchemeDeleteMutationResult = ApolloReactCommon.MutationResult<TaxSchemeDeleteMutation>;
export type TaxSchemeDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<TaxSchemeDeleteMutation, TaxSchemeDeleteMutationVariables>;
export const IsTaxSchemeInUseDocument = gql`
    query isTaxSchemeInUse($taxSchemeID: String!, $outletID: String!) {
  isTaxSchemeInUse(taxSchemeID: $taxSchemeID, outletID: $outletID)
}
    `;

/**
 * __useIsTaxSchemeInUseQuery__
 *
 * To run a query within a React component, call `useIsTaxSchemeInUseQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsTaxSchemeInUseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsTaxSchemeInUseQuery({
 *   variables: {
 *      taxSchemeID: // value for 'taxSchemeID'
 *      outletID: // value for 'outletID'
 *   },
 * });
 */
export function useIsTaxSchemeInUseQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IsTaxSchemeInUseQuery, IsTaxSchemeInUseQueryVariables>) {
        return ApolloReactHooks.useQuery<IsTaxSchemeInUseQuery, IsTaxSchemeInUseQueryVariables>(IsTaxSchemeInUseDocument, baseOptions);
      }
export function useIsTaxSchemeInUseLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IsTaxSchemeInUseQuery, IsTaxSchemeInUseQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IsTaxSchemeInUseQuery, IsTaxSchemeInUseQueryVariables>(IsTaxSchemeInUseDocument, baseOptions);
        }
export type IsTaxSchemeInUseQueryHookResult = ReturnType<typeof useIsTaxSchemeInUseQuery>;
export type IsTaxSchemeInUseLazyQueryHookResult = ReturnType<typeof useIsTaxSchemeInUseLazyQuery>;
export type IsTaxSchemeInUseQueryResult = ApolloReactCommon.QueryResult<IsTaxSchemeInUseQuery, IsTaxSchemeInUseQueryVariables>;
export const GetTaxSchemeDetailDocument = gql`
    query getTaxSchemeDetail($accountID: String!, $taxSchemeID: String!, $orderID: String!) {
  getTaxSchemeDetail(accountID: $accountID, taxSchemeID: $taxSchemeID, orderID: $orderID) {
    taxId
    taxRate
    taxCode
    taxType
  }
}
    `;

/**
 * __useGetTaxSchemeDetailQuery__
 *
 * To run a query within a React component, call `useGetTaxSchemeDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaxSchemeDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaxSchemeDetailQuery({
 *   variables: {
 *      accountID: // value for 'accountID'
 *      taxSchemeID: // value for 'taxSchemeID'
 *      orderID: // value for 'orderID'
 *   },
 * });
 */
export function useGetTaxSchemeDetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetTaxSchemeDetailQuery, GetTaxSchemeDetailQueryVariables>) {
        return ApolloReactHooks.useQuery<GetTaxSchemeDetailQuery, GetTaxSchemeDetailQueryVariables>(GetTaxSchemeDetailDocument, baseOptions);
      }
export function useGetTaxSchemeDetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTaxSchemeDetailQuery, GetTaxSchemeDetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetTaxSchemeDetailQuery, GetTaxSchemeDetailQueryVariables>(GetTaxSchemeDetailDocument, baseOptions);
        }
export type GetTaxSchemeDetailQueryHookResult = ReturnType<typeof useGetTaxSchemeDetailQuery>;
export type GetTaxSchemeDetailLazyQueryHookResult = ReturnType<typeof useGetTaxSchemeDetailLazyQuery>;
export type GetTaxSchemeDetailQueryResult = ApolloReactCommon.QueryResult<GetTaxSchemeDetailQuery, GetTaxSchemeDetailQueryVariables>;
export const IsTaxSameNameDocument = gql`
    query isTaxSameName($accountID: String!, $taxSchemeName: String!) {
  isTaxSameName(accountID: $accountID, taxSchemeName: $taxSchemeName)
}
    `;

/**
 * __useIsTaxSameNameQuery__
 *
 * To run a query within a React component, call `useIsTaxSameNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsTaxSameNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsTaxSameNameQuery({
 *   variables: {
 *      accountID: // value for 'accountID'
 *      taxSchemeName: // value for 'taxSchemeName'
 *   },
 * });
 */
export function useIsTaxSameNameQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IsTaxSameNameQuery, IsTaxSameNameQueryVariables>) {
        return ApolloReactHooks.useQuery<IsTaxSameNameQuery, IsTaxSameNameQueryVariables>(IsTaxSameNameDocument, baseOptions);
      }
export function useIsTaxSameNameLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IsTaxSameNameQuery, IsTaxSameNameQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IsTaxSameNameQuery, IsTaxSameNameQueryVariables>(IsTaxSameNameDocument, baseOptions);
        }
export type IsTaxSameNameQueryHookResult = ReturnType<typeof useIsTaxSameNameQuery>;
export type IsTaxSameNameLazyQueryHookResult = ReturnType<typeof useIsTaxSameNameLazyQuery>;
export type IsTaxSameNameQueryResult = ApolloReactCommon.QueryResult<IsTaxSameNameQuery, IsTaxSameNameQueryVariables>;
export const GetTaxTypeListingDocument = gql`
    query getTaxTypeListing($softwareCode: String!) {
  getTaxTypeListing(softwareCode: $softwareCode)
}
    `;

/**
 * __useGetTaxTypeListingQuery__
 *
 * To run a query within a React component, call `useGetTaxTypeListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaxTypeListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaxTypeListingQuery({
 *   variables: {
 *      softwareCode: // value for 'softwareCode'
 *   },
 * });
 */
export function useGetTaxTypeListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetTaxTypeListingQuery, GetTaxTypeListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetTaxTypeListingQuery, GetTaxTypeListingQueryVariables>(GetTaxTypeListingDocument, baseOptions);
      }
export function useGetTaxTypeListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTaxTypeListingQuery, GetTaxTypeListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetTaxTypeListingQuery, GetTaxTypeListingQueryVariables>(GetTaxTypeListingDocument, baseOptions);
        }
export type GetTaxTypeListingQueryHookResult = ReturnType<typeof useGetTaxTypeListingQuery>;
export type GetTaxTypeListingLazyQueryHookResult = ReturnType<typeof useGetTaxTypeListingLazyQuery>;
export type GetTaxTypeListingQueryResult = ApolloReactCommon.QueryResult<GetTaxTypeListingQuery, GetTaxTypeListingQueryVariables>;
export const GetTaxListingDocument = gql`
    query getTaxListing($accountID: String!) {
  getTaxListing(accountID: $accountID)
}
    `;

/**
 * __useGetTaxListingQuery__
 *
 * To run a query within a React component, call `useGetTaxListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaxListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaxListingQuery({
 *   variables: {
 *      accountID: // value for 'accountID'
 *   },
 * });
 */
export function useGetTaxListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetTaxListingQuery, GetTaxListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetTaxListingQuery, GetTaxListingQueryVariables>(GetTaxListingDocument, baseOptions);
      }
export function useGetTaxListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTaxListingQuery, GetTaxListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetTaxListingQuery, GetTaxListingQueryVariables>(GetTaxListingDocument, baseOptions);
        }
export type GetTaxListingQueryHookResult = ReturnType<typeof useGetTaxListingQuery>;
export type GetTaxListingLazyQueryHookResult = ReturnType<typeof useGetTaxListingLazyQuery>;
export type GetTaxListingQueryResult = ApolloReactCommon.QueryResult<GetTaxListingQuery, GetTaxListingQueryVariables>;
export const IsTaxInUseDocument = gql`
    query isTaxInUse($taxID: String!, $accountID: String!) {
  isTaxInUse(taxID: $taxID, accountID: $accountID)
}
    `;

/**
 * __useIsTaxInUseQuery__
 *
 * To run a query within a React component, call `useIsTaxInUseQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsTaxInUseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsTaxInUseQuery({
 *   variables: {
 *      taxID: // value for 'taxID'
 *      accountID: // value for 'accountID'
 *   },
 * });
 */
export function useIsTaxInUseQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IsTaxInUseQuery, IsTaxInUseQueryVariables>) {
        return ApolloReactHooks.useQuery<IsTaxInUseQuery, IsTaxInUseQueryVariables>(IsTaxInUseDocument, baseOptions);
      }
export function useIsTaxInUseLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IsTaxInUseQuery, IsTaxInUseQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IsTaxInUseQuery, IsTaxInUseQueryVariables>(IsTaxInUseDocument, baseOptions);
        }
export type IsTaxInUseQueryHookResult = ReturnType<typeof useIsTaxInUseQuery>;
export type IsTaxInUseLazyQueryHookResult = ReturnType<typeof useIsTaxInUseLazyQuery>;
export type IsTaxInUseQueryResult = ApolloReactCommon.QueryResult<IsTaxInUseQuery, IsTaxInUseQueryVariables>;
export const GetTaxDetailsDocument = gql`
    query getTaxDetails($taxID: String!, $accountID: String!) {
  getTaxDetails(taxID: $taxID, accountID: $accountID) {
    id
    accountId
    code
    description
    classType
    schemeTypeId
    isClaimable
    commonStatus
    taxDetail {
      id
      effectiveDate
      rate
    }
  }
}
    `;

/**
 * __useGetTaxDetailsQuery__
 *
 * To run a query within a React component, call `useGetTaxDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaxDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaxDetailsQuery({
 *   variables: {
 *      taxID: // value for 'taxID'
 *      accountID: // value for 'accountID'
 *   },
 * });
 */
export function useGetTaxDetailsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetTaxDetailsQuery, GetTaxDetailsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetTaxDetailsQuery, GetTaxDetailsQueryVariables>(GetTaxDetailsDocument, baseOptions);
      }
export function useGetTaxDetailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTaxDetailsQuery, GetTaxDetailsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetTaxDetailsQuery, GetTaxDetailsQueryVariables>(GetTaxDetailsDocument, baseOptions);
        }
export type GetTaxDetailsQueryHookResult = ReturnType<typeof useGetTaxDetailsQuery>;
export type GetTaxDetailsLazyQueryHookResult = ReturnType<typeof useGetTaxDetailsLazyQuery>;
export type GetTaxDetailsQueryResult = ApolloReactCommon.QueryResult<GetTaxDetailsQuery, GetTaxDetailsQueryVariables>;
export const TaxInsertDocument = gql`
    mutation taxInsert($taxInsertInput: TaxSetupInput!, $accountID: String!) {
  taxInsert(taxInsertInput: $taxInsertInput, accountID: $accountID)
}
    `;
export type TaxInsertMutationFn = ApolloReactCommon.MutationFunction<TaxInsertMutation, TaxInsertMutationVariables>;

/**
 * __useTaxInsertMutation__
 *
 * To run a mutation, you first call `useTaxInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTaxInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [taxInsertMutation, { data, loading, error }] = useTaxInsertMutation({
 *   variables: {
 *      taxInsertInput: // value for 'taxInsertInput'
 *      accountID: // value for 'accountID'
 *   },
 * });
 */
export function useTaxInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TaxInsertMutation, TaxInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<TaxInsertMutation, TaxInsertMutationVariables>(TaxInsertDocument, baseOptions);
      }
export type TaxInsertMutationHookResult = ReturnType<typeof useTaxInsertMutation>;
export type TaxInsertMutationResult = ApolloReactCommon.MutationResult<TaxInsertMutation>;
export type TaxInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<TaxInsertMutation, TaxInsertMutationVariables>;
export const TaxDeleteDocument = gql`
    mutation taxDelete($taxID: String!, $taxDeleteInput: [TaxDetailInput!]!) {
  taxDelete(taxID: $taxID, taxDeleteInput: $taxDeleteInput)
}
    `;
export type TaxDeleteMutationFn = ApolloReactCommon.MutationFunction<TaxDeleteMutation, TaxDeleteMutationVariables>;

/**
 * __useTaxDeleteMutation__
 *
 * To run a mutation, you first call `useTaxDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTaxDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [taxDeleteMutation, { data, loading, error }] = useTaxDeleteMutation({
 *   variables: {
 *      taxID: // value for 'taxID'
 *      taxDeleteInput: // value for 'taxDeleteInput'
 *   },
 * });
 */
export function useTaxDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TaxDeleteMutation, TaxDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<TaxDeleteMutation, TaxDeleteMutationVariables>(TaxDeleteDocument, baseOptions);
      }
export type TaxDeleteMutationHookResult = ReturnType<typeof useTaxDeleteMutation>;
export type TaxDeleteMutationResult = ApolloReactCommon.MutationResult<TaxDeleteMutation>;
export type TaxDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<TaxDeleteMutation, TaxDeleteMutationVariables>;
export const TaxUpdateDocument = gql`
    mutation taxUpdate($taxEditInput: EditTaxSetupInput!, $accountID: String!) {
  taxUpdate(taxEditInput: $taxEditInput, accountID: $accountID)
}
    `;
export type TaxUpdateMutationFn = ApolloReactCommon.MutationFunction<TaxUpdateMutation, TaxUpdateMutationVariables>;

/**
 * __useTaxUpdateMutation__
 *
 * To run a mutation, you first call `useTaxUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTaxUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [taxUpdateMutation, { data, loading, error }] = useTaxUpdateMutation({
 *   variables: {
 *      taxEditInput: // value for 'taxEditInput'
 *      accountID: // value for 'accountID'
 *   },
 * });
 */
export function useTaxUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TaxUpdateMutation, TaxUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<TaxUpdateMutation, TaxUpdateMutationVariables>(TaxUpdateDocument, baseOptions);
      }
export type TaxUpdateMutationHookResult = ReturnType<typeof useTaxUpdateMutation>;
export type TaxUpdateMutationResult = ApolloReactCommon.MutationResult<TaxUpdateMutation>;
export type TaxUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<TaxUpdateMutation, TaxUpdateMutationVariables>;
export const IsTaxSameCodeDocument = gql`
    query isTaxSameCode($accountID: String!, $taxCode: String!) {
  isTaxSameCode(accountID: $accountID, taxCode: $taxCode)
}
    `;

/**
 * __useIsTaxSameCodeQuery__
 *
 * To run a query within a React component, call `useIsTaxSameCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsTaxSameCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsTaxSameCodeQuery({
 *   variables: {
 *      accountID: // value for 'accountID'
 *      taxCode: // value for 'taxCode'
 *   },
 * });
 */
export function useIsTaxSameCodeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IsTaxSameCodeQuery, IsTaxSameCodeQueryVariables>) {
        return ApolloReactHooks.useQuery<IsTaxSameCodeQuery, IsTaxSameCodeQueryVariables>(IsTaxSameCodeDocument, baseOptions);
      }
export function useIsTaxSameCodeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IsTaxSameCodeQuery, IsTaxSameCodeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IsTaxSameCodeQuery, IsTaxSameCodeQueryVariables>(IsTaxSameCodeDocument, baseOptions);
        }
export type IsTaxSameCodeQueryHookResult = ReturnType<typeof useIsTaxSameCodeQuery>;
export type IsTaxSameCodeLazyQueryHookResult = ReturnType<typeof useIsTaxSameCodeLazyQuery>;
export type IsTaxSameCodeQueryResult = ApolloReactCommon.QueryResult<IsTaxSameCodeQuery, IsTaxSameCodeQueryVariables>;
export const ConsolidatedInvoiceListingDocument = gql`
    query ConsolidatedInvoiceListing($outletID: String!, $accountID: String!, $companyID: String!, $ID: String, $startDate: DateTime, $endDate: DateTime, $docNo: String, $docType: String, $offSet: Float!, $limit: Float!) {
  ConsolidatedInvoiceListing(outletID: $outletID, accountID: $accountID, companyID: $companyID, ID: $ID, startDate: $startDate, endDate: $endDate, docNo: $docNo, docType: $docType, offSet: $offSet, limit: $limit) {
    ID
    docDate
    docNo
    description
    docAmt
    createdTs
    createdBy
    einvoice_trx_type
    einvoice_status
    eIConsolidationDetail {
      ID
      createdTs
      createdBy
      refTable
      refRecordID
      amount
      serviceCharge
      taxAmt
      docAmt
      bill {
        ID
        billNo
      }
    }
  }
}
    `;

/**
 * __useConsolidatedInvoiceListingQuery__
 *
 * To run a query within a React component, call `useConsolidatedInvoiceListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useConsolidatedInvoiceListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConsolidatedInvoiceListingQuery({
 *   variables: {
 *      outletID: // value for 'outletID'
 *      accountID: // value for 'accountID'
 *      companyID: // value for 'companyID'
 *      ID: // value for 'ID'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      docNo: // value for 'docNo'
 *      docType: // value for 'docType'
 *      offSet: // value for 'offSet'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useConsolidatedInvoiceListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ConsolidatedInvoiceListingQuery, ConsolidatedInvoiceListingQueryVariables>) {
        return ApolloReactHooks.useQuery<ConsolidatedInvoiceListingQuery, ConsolidatedInvoiceListingQueryVariables>(ConsolidatedInvoiceListingDocument, baseOptions);
      }
export function useConsolidatedInvoiceListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ConsolidatedInvoiceListingQuery, ConsolidatedInvoiceListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ConsolidatedInvoiceListingQuery, ConsolidatedInvoiceListingQueryVariables>(ConsolidatedInvoiceListingDocument, baseOptions);
        }
export type ConsolidatedInvoiceListingQueryHookResult = ReturnType<typeof useConsolidatedInvoiceListingQuery>;
export type ConsolidatedInvoiceListingLazyQueryHookResult = ReturnType<typeof useConsolidatedInvoiceListingLazyQuery>;
export type ConsolidatedInvoiceListingQueryResult = ApolloReactCommon.QueryResult<ConsolidatedInvoiceListingQuery, ConsolidatedInvoiceListingQueryVariables>;
export const DocToConsolidateListingDocument = gql`
    query DocToConsolidateListing($outletID: String!, $accountID: String!, $year: Float, $month: Float, $name: String, $docType: String, $docNo: String, $isSearch: Boolean, $offset: Float, $limit: Float) {
  DocToConsolidateListing(outletID: $outletID, accountID: $accountID, year: $year, month: $month, name: $name, docType: $docType, docNo: $docNo, isSearch: $isSearch, offset: $offset, limit: $limit) {
    consolidatedEIAll {
      ID
      billStatus
      docDate
      docNo
      docType
      name
      baseAmount
      serviceCharge
      taxAmount
      docAmount
    }
    consolidatedEI {
      ID
      billStatus
      docDate
      docNo
      docType
      name
      baseAmount
      serviceCharge
      taxAmount
      docAmount
    }
    consolidatedEICount
    totalPages
    currentPage
    total {
      baseAmount
      serviceCharge
      taxAmount
      docAmount
    }
  }
}
    `;

/**
 * __useDocToConsolidateListingQuery__
 *
 * To run a query within a React component, call `useDocToConsolidateListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocToConsolidateListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocToConsolidateListingQuery({
 *   variables: {
 *      outletID: // value for 'outletID'
 *      accountID: // value for 'accountID'
 *      year: // value for 'year'
 *      month: // value for 'month'
 *      name: // value for 'name'
 *      docType: // value for 'docType'
 *      docNo: // value for 'docNo'
 *      isSearch: // value for 'isSearch'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useDocToConsolidateListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DocToConsolidateListingQuery, DocToConsolidateListingQueryVariables>) {
        return ApolloReactHooks.useQuery<DocToConsolidateListingQuery, DocToConsolidateListingQueryVariables>(DocToConsolidateListingDocument, baseOptions);
      }
export function useDocToConsolidateListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DocToConsolidateListingQuery, DocToConsolidateListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DocToConsolidateListingQuery, DocToConsolidateListingQueryVariables>(DocToConsolidateListingDocument, baseOptions);
        }
export type DocToConsolidateListingQueryHookResult = ReturnType<typeof useDocToConsolidateListingQuery>;
export type DocToConsolidateListingLazyQueryHookResult = ReturnType<typeof useDocToConsolidateListingLazyQuery>;
export type DocToConsolidateListingQueryResult = ApolloReactCommon.QueryResult<DocToConsolidateListingQuery, DocToConsolidateListingQueryVariables>;
export const GenerateConsolidatedDocDocument = gql`
    mutation GenerateConsolidatedDoc($outletID: String!, $accountID: String!, $companyID: String!, $year: Float, $month: Float, $name: String, $docNo: String, $docType: String, $isSearch: Boolean, $offset: Float, $limit: Float, $trxDate: DateTime!, $docDate: DateTime!, $description: String) {
  GenerateConsolidatedDoc(outletID: $outletID, accountID: $accountID, companyID: $companyID, year: $year, month: $month, name: $name, docNo: $docNo, docType: $docType, isSearch: $isSearch, offset: $offset, limit: $limit, trxDate: $trxDate, docDate: $docDate, description: $description)
}
    `;
export type GenerateConsolidatedDocMutationFn = ApolloReactCommon.MutationFunction<GenerateConsolidatedDocMutation, GenerateConsolidatedDocMutationVariables>;

/**
 * __useGenerateConsolidatedDocMutation__
 *
 * To run a mutation, you first call `useGenerateConsolidatedDocMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateConsolidatedDocMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateConsolidatedDocMutation, { data, loading, error }] = useGenerateConsolidatedDocMutation({
 *   variables: {
 *      outletID: // value for 'outletID'
 *      accountID: // value for 'accountID'
 *      companyID: // value for 'companyID'
 *      year: // value for 'year'
 *      month: // value for 'month'
 *      name: // value for 'name'
 *      docNo: // value for 'docNo'
 *      docType: // value for 'docType'
 *      isSearch: // value for 'isSearch'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      trxDate: // value for 'trxDate'
 *      docDate: // value for 'docDate'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useGenerateConsolidatedDocMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<GenerateConsolidatedDocMutation, GenerateConsolidatedDocMutationVariables>) {
        return ApolloReactHooks.useMutation<GenerateConsolidatedDocMutation, GenerateConsolidatedDocMutationVariables>(GenerateConsolidatedDocDocument, baseOptions);
      }
export type GenerateConsolidatedDocMutationHookResult = ReturnType<typeof useGenerateConsolidatedDocMutation>;
export type GenerateConsolidatedDocMutationResult = ApolloReactCommon.MutationResult<GenerateConsolidatedDocMutation>;
export type GenerateConsolidatedDocMutationOptions = ApolloReactCommon.BaseMutationOptions<GenerateConsolidatedDocMutation, GenerateConsolidatedDocMutationVariables>;
export const DocToConsolidateViewDocument = gql`
    query DocToConsolidateView($outletID: String!, $accountID: String!, $companyID: String!, $consolidationID: String!, $limit: Float, $offset: Float) {
  DocToConsolidateView(outletID: $outletID, accountID: $accountID, companyID: $companyID, consolidationID: $consolidationID, limit: $limit, offset: $offset)
}
    `;

/**
 * __useDocToConsolidateViewQuery__
 *
 * To run a query within a React component, call `useDocToConsolidateViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocToConsolidateViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocToConsolidateViewQuery({
 *   variables: {
 *      outletID: // value for 'outletID'
 *      accountID: // value for 'accountID'
 *      companyID: // value for 'companyID'
 *      consolidationID: // value for 'consolidationID'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useDocToConsolidateViewQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DocToConsolidateViewQuery, DocToConsolidateViewQueryVariables>) {
        return ApolloReactHooks.useQuery<DocToConsolidateViewQuery, DocToConsolidateViewQueryVariables>(DocToConsolidateViewDocument, baseOptions);
      }
export function useDocToConsolidateViewLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DocToConsolidateViewQuery, DocToConsolidateViewQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DocToConsolidateViewQuery, DocToConsolidateViewQueryVariables>(DocToConsolidateViewDocument, baseOptions);
        }
export type DocToConsolidateViewQueryHookResult = ReturnType<typeof useDocToConsolidateViewQuery>;
export type DocToConsolidateViewLazyQueryHookResult = ReturnType<typeof useDocToConsolidateViewLazyQuery>;
export type DocToConsolidateViewQueryResult = ApolloReactCommon.QueryResult<DocToConsolidateViewQuery, DocToConsolidateViewQueryVariables>;
export const GetOfficerDocument = gql`
    query getOfficer($ID: String, $orderByDesc: String, $isActive: Boolean) {
  getOfficer(ID: $ID, orderByDesc: $orderByDesc, isActive: $isActive) {
    ID
    name
    designation
    mobileNo
    isActive
    email
  }
}
    `;

/**
 * __useGetOfficerQuery__
 *
 * To run a query within a React component, call `useGetOfficerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOfficerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOfficerQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      orderByDesc: // value for 'orderByDesc'
 *      isActive: // value for 'isActive'
 *   },
 * });
 */
export function useGetOfficerQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetOfficerQuery, GetOfficerQueryVariables>) {
        return ApolloReactHooks.useQuery<GetOfficerQuery, GetOfficerQueryVariables>(GetOfficerDocument, baseOptions);
      }
export function useGetOfficerLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetOfficerQuery, GetOfficerQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetOfficerQuery, GetOfficerQueryVariables>(GetOfficerDocument, baseOptions);
        }
export type GetOfficerQueryHookResult = ReturnType<typeof useGetOfficerQuery>;
export type GetOfficerLazyQueryHookResult = ReturnType<typeof useGetOfficerLazyQuery>;
export type GetOfficerQueryResult = ApolloReactCommon.QueryResult<GetOfficerQuery, GetOfficerQueryVariables>;
export const CreateOfficerDocument = gql`
    mutation createOfficer($input: OfficerInput!) {
  createOfficer(input: $input) {
    ID
  }
}
    `;
export type CreateOfficerMutationFn = ApolloReactCommon.MutationFunction<CreateOfficerMutation, CreateOfficerMutationVariables>;

/**
 * __useCreateOfficerMutation__
 *
 * To run a mutation, you first call `useCreateOfficerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOfficerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOfficerMutation, { data, loading, error }] = useCreateOfficerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOfficerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateOfficerMutation, CreateOfficerMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateOfficerMutation, CreateOfficerMutationVariables>(CreateOfficerDocument, baseOptions);
      }
export type CreateOfficerMutationHookResult = ReturnType<typeof useCreateOfficerMutation>;
export type CreateOfficerMutationResult = ApolloReactCommon.MutationResult<CreateOfficerMutation>;
export type CreateOfficerMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateOfficerMutation, CreateOfficerMutationVariables>;
export const UpdateOfficerDocument = gql`
    mutation updateOfficer($input: OfficerInput!) {
  updateOfficer(input: $input) {
    ID
  }
}
    `;
export type UpdateOfficerMutationFn = ApolloReactCommon.MutationFunction<UpdateOfficerMutation, UpdateOfficerMutationVariables>;

/**
 * __useUpdateOfficerMutation__
 *
 * To run a mutation, you first call `useUpdateOfficerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOfficerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOfficerMutation, { data, loading, error }] = useUpdateOfficerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOfficerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateOfficerMutation, UpdateOfficerMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateOfficerMutation, UpdateOfficerMutationVariables>(UpdateOfficerDocument, baseOptions);
      }
export type UpdateOfficerMutationHookResult = ReturnType<typeof useUpdateOfficerMutation>;
export type UpdateOfficerMutationResult = ApolloReactCommon.MutationResult<UpdateOfficerMutation>;
export type UpdateOfficerMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateOfficerMutation, UpdateOfficerMutationVariables>;
export const DeleteOfficerDocument = gql`
    mutation deleteOfficer($ID: String!) {
  deleteOfficer(ID: $ID)
}
    `;
export type DeleteOfficerMutationFn = ApolloReactCommon.MutationFunction<DeleteOfficerMutation, DeleteOfficerMutationVariables>;

/**
 * __useDeleteOfficerMutation__
 *
 * To run a mutation, you first call `useDeleteOfficerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOfficerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOfficerMutation, { data, loading, error }] = useDeleteOfficerMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDeleteOfficerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteOfficerMutation, DeleteOfficerMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteOfficerMutation, DeleteOfficerMutationVariables>(DeleteOfficerDocument, baseOptions);
      }
export type DeleteOfficerMutationHookResult = ReturnType<typeof useDeleteOfficerMutation>;
export type DeleteOfficerMutationResult = ApolloReactCommon.MutationResult<DeleteOfficerMutation>;
export type DeleteOfficerMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteOfficerMutation, DeleteOfficerMutationVariables>;
export const GetStaffDocument = gql`
    query getStaff($ID: String, $orderByDesc: String, $isActive: Boolean) {
  getStaff(ID: $ID, orderByDesc: $orderByDesc, isActive: $isActive) {
    ID
    name
    designation
    mobileNo
    email
    isActive
  }
}
    `;

/**
 * __useGetStaffQuery__
 *
 * To run a query within a React component, call `useGetStaffQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStaffQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStaffQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      orderByDesc: // value for 'orderByDesc'
 *      isActive: // value for 'isActive'
 *   },
 * });
 */
export function useGetStaffQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetStaffQuery, GetStaffQueryVariables>) {
        return ApolloReactHooks.useQuery<GetStaffQuery, GetStaffQueryVariables>(GetStaffDocument, baseOptions);
      }
export function useGetStaffLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetStaffQuery, GetStaffQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetStaffQuery, GetStaffQueryVariables>(GetStaffDocument, baseOptions);
        }
export type GetStaffQueryHookResult = ReturnType<typeof useGetStaffQuery>;
export type GetStaffLazyQueryHookResult = ReturnType<typeof useGetStaffLazyQuery>;
export type GetStaffQueryResult = ApolloReactCommon.QueryResult<GetStaffQuery, GetStaffQueryVariables>;
export const CreateStaffDocument = gql`
    mutation createStaff($input: StaffInput!) {
  createStaff(input: $input) {
    ID
    name
    designation
    mobileNo
  }
}
    `;
export type CreateStaffMutationFn = ApolloReactCommon.MutationFunction<CreateStaffMutation, CreateStaffMutationVariables>;

/**
 * __useCreateStaffMutation__
 *
 * To run a mutation, you first call `useCreateStaffMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStaffMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStaffMutation, { data, loading, error }] = useCreateStaffMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateStaffMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateStaffMutation, CreateStaffMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateStaffMutation, CreateStaffMutationVariables>(CreateStaffDocument, baseOptions);
      }
export type CreateStaffMutationHookResult = ReturnType<typeof useCreateStaffMutation>;
export type CreateStaffMutationResult = ApolloReactCommon.MutationResult<CreateStaffMutation>;
export type CreateStaffMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateStaffMutation, CreateStaffMutationVariables>;
export const UpdateStaffDocument = gql`
    mutation updateStaff($input: StaffInput!) {
  updateStaff(input: $input) {
    ID
    name
    designation
    mobileNo
    email
  }
}
    `;
export type UpdateStaffMutationFn = ApolloReactCommon.MutationFunction<UpdateStaffMutation, UpdateStaffMutationVariables>;

/**
 * __useUpdateStaffMutation__
 *
 * To run a mutation, you first call `useUpdateStaffMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStaffMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStaffMutation, { data, loading, error }] = useUpdateStaffMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateStaffMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateStaffMutation, UpdateStaffMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateStaffMutation, UpdateStaffMutationVariables>(UpdateStaffDocument, baseOptions);
      }
export type UpdateStaffMutationHookResult = ReturnType<typeof useUpdateStaffMutation>;
export type UpdateStaffMutationResult = ApolloReactCommon.MutationResult<UpdateStaffMutation>;
export type UpdateStaffMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateStaffMutation, UpdateStaffMutationVariables>;
export const DeleteStaffDocument = gql`
    mutation deleteStaff($ID: String!) {
  deleteStaff(ID: $ID)
}
    `;
export type DeleteStaffMutationFn = ApolloReactCommon.MutationFunction<DeleteStaffMutation, DeleteStaffMutationVariables>;

/**
 * __useDeleteStaffMutation__
 *
 * To run a mutation, you first call `useDeleteStaffMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteStaffMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteStaffMutation, { data, loading, error }] = useDeleteStaffMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDeleteStaffMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteStaffMutation, DeleteStaffMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteStaffMutation, DeleteStaffMutationVariables>(DeleteStaffDocument, baseOptions);
      }
export type DeleteStaffMutationHookResult = ReturnType<typeof useDeleteStaffMutation>;
export type DeleteStaffMutationResult = ApolloReactCommon.MutationResult<DeleteStaffMutation>;
export type DeleteStaffMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteStaffMutation, DeleteStaffMutationVariables>;
export const GetEInvoicePolicyDocument = gql`
    query getEInvoicePolicy($ID: String, $accountID: String) {
  getEInvoicePolicy(ID: $ID, accountID: $accountID) {
    ID
    isActive
    accountID
    eInvoiceClassificationCodeID
    classificationCode
    description
  }
}
    `;

/**
 * __useGetEInvoicePolicyQuery__
 *
 * To run a query within a React component, call `useGetEInvoicePolicyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEInvoicePolicyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEInvoicePolicyQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      accountID: // value for 'accountID'
 *   },
 * });
 */
export function useGetEInvoicePolicyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetEInvoicePolicyQuery, GetEInvoicePolicyQueryVariables>) {
        return ApolloReactHooks.useQuery<GetEInvoicePolicyQuery, GetEInvoicePolicyQueryVariables>(GetEInvoicePolicyDocument, baseOptions);
      }
export function useGetEInvoicePolicyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetEInvoicePolicyQuery, GetEInvoicePolicyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetEInvoicePolicyQuery, GetEInvoicePolicyQueryVariables>(GetEInvoicePolicyDocument, baseOptions);
        }
export type GetEInvoicePolicyQueryHookResult = ReturnType<typeof useGetEInvoicePolicyQuery>;
export type GetEInvoicePolicyLazyQueryHookResult = ReturnType<typeof useGetEInvoicePolicyLazyQuery>;
export type GetEInvoicePolicyQueryResult = ApolloReactCommon.QueryResult<GetEInvoicePolicyQuery, GetEInvoicePolicyQueryVariables>;
export const SubmitEInvoicePolicyDocument = gql`
    mutation submitEInvoicePolicy($input: EInvoicePolicyInput!) {
  submitEInvoicePolicy(input: $input)
}
    `;
export type SubmitEInvoicePolicyMutationFn = ApolloReactCommon.MutationFunction<SubmitEInvoicePolicyMutation, SubmitEInvoicePolicyMutationVariables>;

/**
 * __useSubmitEInvoicePolicyMutation__
 *
 * To run a mutation, you first call `useSubmitEInvoicePolicyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitEInvoicePolicyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitEInvoicePolicyMutation, { data, loading, error }] = useSubmitEInvoicePolicyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubmitEInvoicePolicyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SubmitEInvoicePolicyMutation, SubmitEInvoicePolicyMutationVariables>) {
        return ApolloReactHooks.useMutation<SubmitEInvoicePolicyMutation, SubmitEInvoicePolicyMutationVariables>(SubmitEInvoicePolicyDocument, baseOptions);
      }
export type SubmitEInvoicePolicyMutationHookResult = ReturnType<typeof useSubmitEInvoicePolicyMutation>;
export type SubmitEInvoicePolicyMutationResult = ApolloReactCommon.MutationResult<SubmitEInvoicePolicyMutation>;
export type SubmitEInvoicePolicyMutationOptions = ApolloReactCommon.BaseMutationOptions<SubmitEInvoicePolicyMutation, SubmitEInvoicePolicyMutationVariables>;
export const CreateEInvoicePolicyDocument = gql`
    mutation createEInvoicePolicy($input: EInvoicePolicyInput!) {
  createEInvoicePolicy(input: $input) {
    ID
  }
}
    `;
export type CreateEInvoicePolicyMutationFn = ApolloReactCommon.MutationFunction<CreateEInvoicePolicyMutation, CreateEInvoicePolicyMutationVariables>;

/**
 * __useCreateEInvoicePolicyMutation__
 *
 * To run a mutation, you first call `useCreateEInvoicePolicyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEInvoicePolicyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEInvoicePolicyMutation, { data, loading, error }] = useCreateEInvoicePolicyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEInvoicePolicyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateEInvoicePolicyMutation, CreateEInvoicePolicyMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateEInvoicePolicyMutation, CreateEInvoicePolicyMutationVariables>(CreateEInvoicePolicyDocument, baseOptions);
      }
export type CreateEInvoicePolicyMutationHookResult = ReturnType<typeof useCreateEInvoicePolicyMutation>;
export type CreateEInvoicePolicyMutationResult = ApolloReactCommon.MutationResult<CreateEInvoicePolicyMutation>;
export type CreateEInvoicePolicyMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateEInvoicePolicyMutation, CreateEInvoicePolicyMutationVariables>;
export const UpdateEInvoicePolicyDocument = gql`
    mutation updateEInvoicePolicy($input: EInvoicePolicyInput!) {
  updateEInvoicePolicy(input: $input) {
    ID
  }
}
    `;
export type UpdateEInvoicePolicyMutationFn = ApolloReactCommon.MutationFunction<UpdateEInvoicePolicyMutation, UpdateEInvoicePolicyMutationVariables>;

/**
 * __useUpdateEInvoicePolicyMutation__
 *
 * To run a mutation, you first call `useUpdateEInvoicePolicyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEInvoicePolicyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEInvoicePolicyMutation, { data, loading, error }] = useUpdateEInvoicePolicyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEInvoicePolicyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateEInvoicePolicyMutation, UpdateEInvoicePolicyMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateEInvoicePolicyMutation, UpdateEInvoicePolicyMutationVariables>(UpdateEInvoicePolicyDocument, baseOptions);
      }
export type UpdateEInvoicePolicyMutationHookResult = ReturnType<typeof useUpdateEInvoicePolicyMutation>;
export type UpdateEInvoicePolicyMutationResult = ApolloReactCommon.MutationResult<UpdateEInvoicePolicyMutation>;
export type UpdateEInvoicePolicyMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateEInvoicePolicyMutation, UpdateEInvoicePolicyMutationVariables>;
export const GetGeneralSettingSummaryDocument = gql`
    query getGeneralSettingSummary {
  officerCount
  staffCount
  userCount
  roundingPolicyDate
  uomDate
  uomConversionDate
  taxDate
  taxSchemeDate
  eInvoicePolicyDate
}
    `;

/**
 * __useGetGeneralSettingSummaryQuery__
 *
 * To run a query within a React component, call `useGetGeneralSettingSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGeneralSettingSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGeneralSettingSummaryQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetGeneralSettingSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetGeneralSettingSummaryQuery, GetGeneralSettingSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetGeneralSettingSummaryQuery, GetGeneralSettingSummaryQueryVariables>(GetGeneralSettingSummaryDocument, baseOptions);
      }
export function useGetGeneralSettingSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetGeneralSettingSummaryQuery, GetGeneralSettingSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetGeneralSettingSummaryQuery, GetGeneralSettingSummaryQueryVariables>(GetGeneralSettingSummaryDocument, baseOptions);
        }
export type GetGeneralSettingSummaryQueryHookResult = ReturnType<typeof useGetGeneralSettingSummaryQuery>;
export type GetGeneralSettingSummaryLazyQueryHookResult = ReturnType<typeof useGetGeneralSettingSummaryLazyQuery>;
export type GetGeneralSettingSummaryQueryResult = ApolloReactCommon.QueryResult<GetGeneralSettingSummaryQuery, GetGeneralSettingSummaryQueryVariables>;
export const GetRoundingPolicyDocument = gql`
    query getRoundingPolicy($ID: String, $accountID: String) {
  getRoundingPolicy(ID: $ID, accountID: $accountID) {
    ID
    inUse
    isActive
    transactionType
    methodType
    smallestDenomination
  }
}
    `;

/**
 * __useGetRoundingPolicyQuery__
 *
 * To run a query within a React component, call `useGetRoundingPolicyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoundingPolicyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoundingPolicyQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      accountID: // value for 'accountID'
 *   },
 * });
 */
export function useGetRoundingPolicyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetRoundingPolicyQuery, GetRoundingPolicyQueryVariables>) {
        return ApolloReactHooks.useQuery<GetRoundingPolicyQuery, GetRoundingPolicyQueryVariables>(GetRoundingPolicyDocument, baseOptions);
      }
export function useGetRoundingPolicyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetRoundingPolicyQuery, GetRoundingPolicyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetRoundingPolicyQuery, GetRoundingPolicyQueryVariables>(GetRoundingPolicyDocument, baseOptions);
        }
export type GetRoundingPolicyQueryHookResult = ReturnType<typeof useGetRoundingPolicyQuery>;
export type GetRoundingPolicyLazyQueryHookResult = ReturnType<typeof useGetRoundingPolicyLazyQuery>;
export type GetRoundingPolicyQueryResult = ApolloReactCommon.QueryResult<GetRoundingPolicyQuery, GetRoundingPolicyQueryVariables>;
export const SubmitRoundingPolicyDocument = gql`
    mutation submitRoundingPolicy($input: RoundingPolicyInput!) {
  submitRoundingPolicy(input: $input)
}
    `;
export type SubmitRoundingPolicyMutationFn = ApolloReactCommon.MutationFunction<SubmitRoundingPolicyMutation, SubmitRoundingPolicyMutationVariables>;

/**
 * __useSubmitRoundingPolicyMutation__
 *
 * To run a mutation, you first call `useSubmitRoundingPolicyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitRoundingPolicyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitRoundingPolicyMutation, { data, loading, error }] = useSubmitRoundingPolicyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubmitRoundingPolicyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SubmitRoundingPolicyMutation, SubmitRoundingPolicyMutationVariables>) {
        return ApolloReactHooks.useMutation<SubmitRoundingPolicyMutation, SubmitRoundingPolicyMutationVariables>(SubmitRoundingPolicyDocument, baseOptions);
      }
export type SubmitRoundingPolicyMutationHookResult = ReturnType<typeof useSubmitRoundingPolicyMutation>;
export type SubmitRoundingPolicyMutationResult = ApolloReactCommon.MutationResult<SubmitRoundingPolicyMutation>;
export type SubmitRoundingPolicyMutationOptions = ApolloReactCommon.BaseMutationOptions<SubmitRoundingPolicyMutation, SubmitRoundingPolicyMutationVariables>;
export const CreateRoundingPolicyDocument = gql`
    mutation createRoundingPolicy($input: RoundingPolicyInput!) {
  createRoundingPolicy(input: $input) {
    ID
  }
}
    `;
export type CreateRoundingPolicyMutationFn = ApolloReactCommon.MutationFunction<CreateRoundingPolicyMutation, CreateRoundingPolicyMutationVariables>;

/**
 * __useCreateRoundingPolicyMutation__
 *
 * To run a mutation, you first call `useCreateRoundingPolicyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRoundingPolicyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRoundingPolicyMutation, { data, loading, error }] = useCreateRoundingPolicyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRoundingPolicyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateRoundingPolicyMutation, CreateRoundingPolicyMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateRoundingPolicyMutation, CreateRoundingPolicyMutationVariables>(CreateRoundingPolicyDocument, baseOptions);
      }
export type CreateRoundingPolicyMutationHookResult = ReturnType<typeof useCreateRoundingPolicyMutation>;
export type CreateRoundingPolicyMutationResult = ApolloReactCommon.MutationResult<CreateRoundingPolicyMutation>;
export type CreateRoundingPolicyMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateRoundingPolicyMutation, CreateRoundingPolicyMutationVariables>;
export const UpdateRoundingPolicyDocument = gql`
    mutation updateRoundingPolicy($input: RoundingPolicyInput!) {
  updateRoundingPolicy(input: $input) {
    ID
  }
}
    `;
export type UpdateRoundingPolicyMutationFn = ApolloReactCommon.MutationFunction<UpdateRoundingPolicyMutation, UpdateRoundingPolicyMutationVariables>;

/**
 * __useUpdateRoundingPolicyMutation__
 *
 * To run a mutation, you first call `useUpdateRoundingPolicyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRoundingPolicyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRoundingPolicyMutation, { data, loading, error }] = useUpdateRoundingPolicyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRoundingPolicyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateRoundingPolicyMutation, UpdateRoundingPolicyMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateRoundingPolicyMutation, UpdateRoundingPolicyMutationVariables>(UpdateRoundingPolicyDocument, baseOptions);
      }
export type UpdateRoundingPolicyMutationHookResult = ReturnType<typeof useUpdateRoundingPolicyMutation>;
export type UpdateRoundingPolicyMutationResult = ApolloReactCommon.MutationResult<UpdateRoundingPolicyMutation>;
export type UpdateRoundingPolicyMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateRoundingPolicyMutation, UpdateRoundingPolicyMutationVariables>;
export const GetUomDocument = gql`
    query getUOM($ID: String, $orderByAsc: String, $orderByDesc: String, $commonStatus: CommonStatus, $name: String, $code: String) {
  getUOM(ID: $ID, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc, commonStatus: $commonStatus, name: $name, code: $code) {
    ID
    name
    code
    commonStatus
  }
}
    `;

/**
 * __useGetUomQuery__
 *
 * To run a query within a React component, call `useGetUomQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUomQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUomQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      orderByAsc: // value for 'orderByAsc'
 *      orderByDesc: // value for 'orderByDesc'
 *      commonStatus: // value for 'commonStatus'
 *      name: // value for 'name'
 *      code: // value for 'code'
 *   },
 * });
 */
export function useGetUomQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUomQuery, GetUomQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUomQuery, GetUomQueryVariables>(GetUomDocument, baseOptions);
      }
export function useGetUomLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUomQuery, GetUomQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUomQuery, GetUomQueryVariables>(GetUomDocument, baseOptions);
        }
export type GetUomQueryHookResult = ReturnType<typeof useGetUomQuery>;
export type GetUomLazyQueryHookResult = ReturnType<typeof useGetUomLazyQuery>;
export type GetUomQueryResult = ApolloReactCommon.QueryResult<GetUomQuery, GetUomQueryVariables>;
export const CreateUomDocument = gql`
    mutation createUOM($input: UOMInput!) {
  createUOM(input: $input) {
    name
    code
  }
}
    `;
export type CreateUomMutationFn = ApolloReactCommon.MutationFunction<CreateUomMutation, CreateUomMutationVariables>;

/**
 * __useCreateUomMutation__
 *
 * To run a mutation, you first call `useCreateUomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUomMutation, { data, loading, error }] = useCreateUomMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUomMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateUomMutation, CreateUomMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateUomMutation, CreateUomMutationVariables>(CreateUomDocument, baseOptions);
      }
export type CreateUomMutationHookResult = ReturnType<typeof useCreateUomMutation>;
export type CreateUomMutationResult = ApolloReactCommon.MutationResult<CreateUomMutation>;
export type CreateUomMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateUomMutation, CreateUomMutationVariables>;
export const UpdateUomDocument = gql`
    mutation updateUOM($input: UOMInput!) {
  updateUOM(input: $input) {
    ID
    name
    code
  }
}
    `;
export type UpdateUomMutationFn = ApolloReactCommon.MutationFunction<UpdateUomMutation, UpdateUomMutationVariables>;

/**
 * __useUpdateUomMutation__
 *
 * To run a mutation, you first call `useUpdateUomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUomMutation, { data, loading, error }] = useUpdateUomMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUomMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateUomMutation, UpdateUomMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateUomMutation, UpdateUomMutationVariables>(UpdateUomDocument, baseOptions);
      }
export type UpdateUomMutationHookResult = ReturnType<typeof useUpdateUomMutation>;
export type UpdateUomMutationResult = ApolloReactCommon.MutationResult<UpdateUomMutation>;
export type UpdateUomMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateUomMutation, UpdateUomMutationVariables>;
export const DeleteUomDocument = gql`
    mutation deleteUOM($ID: String!) {
  deleteUOM(ID: $ID)
}
    `;
export type DeleteUomMutationFn = ApolloReactCommon.MutationFunction<DeleteUomMutation, DeleteUomMutationVariables>;

/**
 * __useDeleteUomMutation__
 *
 * To run a mutation, you first call `useDeleteUomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUomMutation, { data, loading, error }] = useDeleteUomMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDeleteUomMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteUomMutation, DeleteUomMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteUomMutation, DeleteUomMutationVariables>(DeleteUomDocument, baseOptions);
      }
export type DeleteUomMutationHookResult = ReturnType<typeof useDeleteUomMutation>;
export type DeleteUomMutationResult = ApolloReactCommon.MutationResult<DeleteUomMutation>;
export type DeleteUomMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteUomMutation, DeleteUomMutationVariables>;
export const GetUomConversionDocument = gql`
    query getUOMConversion($ID: String, $orderByAsc: String, $orderByDesc: String, $fromUomID: String, $toUomID: String) {
  getUOMConversion(ID: $ID, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc, fromUomID: $fromUomID, toUomID: $toUomID) {
    ID
    fromAmt
    toAmt
    conversionRate
    fromUomID
    toUomID
    fromUOM {
      ID
      name
      code
    }
    toUOM {
      ID
      name
      code
    }
  }
}
    `;

/**
 * __useGetUomConversionQuery__
 *
 * To run a query within a React component, call `useGetUomConversionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUomConversionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUomConversionQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      orderByAsc: // value for 'orderByAsc'
 *      orderByDesc: // value for 'orderByDesc'
 *      fromUomID: // value for 'fromUomID'
 *      toUomID: // value for 'toUomID'
 *   },
 * });
 */
export function useGetUomConversionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUomConversionQuery, GetUomConversionQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUomConversionQuery, GetUomConversionQueryVariables>(GetUomConversionDocument, baseOptions);
      }
export function useGetUomConversionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUomConversionQuery, GetUomConversionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUomConversionQuery, GetUomConversionQueryVariables>(GetUomConversionDocument, baseOptions);
        }
export type GetUomConversionQueryHookResult = ReturnType<typeof useGetUomConversionQuery>;
export type GetUomConversionLazyQueryHookResult = ReturnType<typeof useGetUomConversionLazyQuery>;
export type GetUomConversionQueryResult = ApolloReactCommon.QueryResult<GetUomConversionQuery, GetUomConversionQueryVariables>;
export const GetUomConversionDataDocument = gql`
    query getUomConversionData {
  getUomConversionData
}
    `;

/**
 * __useGetUomConversionDataQuery__
 *
 * To run a query within a React component, call `useGetUomConversionDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUomConversionDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUomConversionDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUomConversionDataQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUomConversionDataQuery, GetUomConversionDataQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUomConversionDataQuery, GetUomConversionDataQueryVariables>(GetUomConversionDataDocument, baseOptions);
      }
export function useGetUomConversionDataLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUomConversionDataQuery, GetUomConversionDataQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUomConversionDataQuery, GetUomConversionDataQueryVariables>(GetUomConversionDataDocument, baseOptions);
        }
export type GetUomConversionDataQueryHookResult = ReturnType<typeof useGetUomConversionDataQuery>;
export type GetUomConversionDataLazyQueryHookResult = ReturnType<typeof useGetUomConversionDataLazyQuery>;
export type GetUomConversionDataQueryResult = ApolloReactCommon.QueryResult<GetUomConversionDataQuery, GetUomConversionDataQueryVariables>;
export const CreateUomConversionDocument = gql`
    mutation createUOMConversion($input: UomConversionInput!) {
  createUOMConversion(input: $input)
}
    `;
export type CreateUomConversionMutationFn = ApolloReactCommon.MutationFunction<CreateUomConversionMutation, CreateUomConversionMutationVariables>;

/**
 * __useCreateUomConversionMutation__
 *
 * To run a mutation, you first call `useCreateUomConversionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUomConversionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUomConversionMutation, { data, loading, error }] = useCreateUomConversionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUomConversionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateUomConversionMutation, CreateUomConversionMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateUomConversionMutation, CreateUomConversionMutationVariables>(CreateUomConversionDocument, baseOptions);
      }
export type CreateUomConversionMutationHookResult = ReturnType<typeof useCreateUomConversionMutation>;
export type CreateUomConversionMutationResult = ApolloReactCommon.MutationResult<CreateUomConversionMutation>;
export type CreateUomConversionMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateUomConversionMutation, CreateUomConversionMutationVariables>;
export const UpdateUomConversionDocument = gql`
    mutation updateUOMConversion($input: UomConversionInput!) {
  updateUOMConversion(input: $input)
}
    `;
export type UpdateUomConversionMutationFn = ApolloReactCommon.MutationFunction<UpdateUomConversionMutation, UpdateUomConversionMutationVariables>;

/**
 * __useUpdateUomConversionMutation__
 *
 * To run a mutation, you first call `useUpdateUomConversionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUomConversionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUomConversionMutation, { data, loading, error }] = useUpdateUomConversionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUomConversionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateUomConversionMutation, UpdateUomConversionMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateUomConversionMutation, UpdateUomConversionMutationVariables>(UpdateUomConversionDocument, baseOptions);
      }
export type UpdateUomConversionMutationHookResult = ReturnType<typeof useUpdateUomConversionMutation>;
export type UpdateUomConversionMutationResult = ApolloReactCommon.MutationResult<UpdateUomConversionMutation>;
export type UpdateUomConversionMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateUomConversionMutation, UpdateUomConversionMutationVariables>;
export const DeleteUomConversionDocument = gql`
    mutation deleteUOMConversion($ID: String!) {
  deleteUOMConversion(ID: $ID)
}
    `;
export type DeleteUomConversionMutationFn = ApolloReactCommon.MutationFunction<DeleteUomConversionMutation, DeleteUomConversionMutationVariables>;

/**
 * __useDeleteUomConversionMutation__
 *
 * To run a mutation, you first call `useDeleteUomConversionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUomConversionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUomConversionMutation, { data, loading, error }] = useDeleteUomConversionMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDeleteUomConversionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteUomConversionMutation, DeleteUomConversionMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteUomConversionMutation, DeleteUomConversionMutationVariables>(DeleteUomConversionDocument, baseOptions);
      }
export type DeleteUomConversionMutationHookResult = ReturnType<typeof useDeleteUomConversionMutation>;
export type DeleteUomConversionMutationResult = ApolloReactCommon.MutationResult<DeleteUomConversionMutation>;
export type DeleteUomConversionMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteUomConversionMutation, DeleteUomConversionMutationVariables>;
export const GetUserDocument = gql`
    query getUser($ID: String!) {
  getUser(ID: $ID) {
    ID
    name
    department
    email
    jobTitle
    contactNo
    superUser
    commonStatus
    userName
  }
}
    `;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useGetUserQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, baseOptions);
      }
export function useGetUserLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, baseOptions);
        }
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = ApolloReactCommon.QueryResult<GetUserQuery, GetUserQueryVariables>;
export const GetUsersByAccountAndSoftwareDocument = gql`
    query getUsersByAccountAndSoftware($superUserBool: UserSelection, $status: CommonStatus) {
  getUsersByAccountAndSoftware(superUserBool: $superUserBool, status: $status) {
    ID
    name
    department
    email
    contactNo
    superUser
    commonStatus
    jobTitle
    userName
    confirmed
  }
}
    `;

/**
 * __useGetUsersByAccountAndSoftwareQuery__
 *
 * To run a query within a React component, call `useGetUsersByAccountAndSoftwareQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersByAccountAndSoftwareQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersByAccountAndSoftwareQuery({
 *   variables: {
 *      superUserBool: // value for 'superUserBool'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useGetUsersByAccountAndSoftwareQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUsersByAccountAndSoftwareQuery, GetUsersByAccountAndSoftwareQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUsersByAccountAndSoftwareQuery, GetUsersByAccountAndSoftwareQueryVariables>(GetUsersByAccountAndSoftwareDocument, baseOptions);
      }
export function useGetUsersByAccountAndSoftwareLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUsersByAccountAndSoftwareQuery, GetUsersByAccountAndSoftwareQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUsersByAccountAndSoftwareQuery, GetUsersByAccountAndSoftwareQueryVariables>(GetUsersByAccountAndSoftwareDocument, baseOptions);
        }
export type GetUsersByAccountAndSoftwareQueryHookResult = ReturnType<typeof useGetUsersByAccountAndSoftwareQuery>;
export type GetUsersByAccountAndSoftwareLazyQueryHookResult = ReturnType<typeof useGetUsersByAccountAndSoftwareLazyQuery>;
export type GetUsersByAccountAndSoftwareQueryResult = ApolloReactCommon.QueryResult<GetUsersByAccountAndSoftwareQuery, GetUsersByAccountAndSoftwareQueryVariables>;
export const CreateUserDocument = gql`
    mutation createUser($input: UserInput!, $OutletID: String!) {
  createUser(input: $input, OutletID: $OutletID) {
    ID
    email
    name
  }
}
    `;
export type CreateUserMutationFn = ApolloReactCommon.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *      OutletID: // value for 'OutletID'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, baseOptions);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = ApolloReactCommon.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const UpdateUserDocument = gql`
    mutation updateUser($input: UserInput!) {
  updateUser(input: $input) {
    ID
  }
}
    `;
export type UpdateUserMutationFn = ApolloReactCommon.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, baseOptions);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = ApolloReactCommon.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const GetRoleDocument = gql`
    query getRole($ID: String) {
  getRole(ID: $ID) {
    ID
    name
    rolePerm {
      permissionID
      roleID
      permName
    }
  }
}
    `;

/**
 * __useGetRoleQuery__
 *
 * To run a query within a React component, call `useGetRoleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoleQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useGetRoleQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetRoleQuery, GetRoleQueryVariables>) {
        return ApolloReactHooks.useQuery<GetRoleQuery, GetRoleQueryVariables>(GetRoleDocument, baseOptions);
      }
export function useGetRoleLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetRoleQuery, GetRoleQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetRoleQuery, GetRoleQueryVariables>(GetRoleDocument, baseOptions);
        }
export type GetRoleQueryHookResult = ReturnType<typeof useGetRoleQuery>;
export type GetRoleLazyQueryHookResult = ReturnType<typeof useGetRoleLazyQuery>;
export type GetRoleQueryResult = ApolloReactCommon.QueryResult<GetRoleQuery, GetRoleQueryVariables>;
export const TestPermissionDocument = gql`
    query testPermission($posPerm: POSPermission!) {
  testPermission(posPerm: $posPerm)
}
    `;

/**
 * __useTestPermissionQuery__
 *
 * To run a query within a React component, call `useTestPermissionQuery` and pass it any options that fit your needs.
 * When your component renders, `useTestPermissionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTestPermissionQuery({
 *   variables: {
 *      posPerm: // value for 'posPerm'
 *   },
 * });
 */
export function useTestPermissionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TestPermissionQuery, TestPermissionQueryVariables>) {
        return ApolloReactHooks.useQuery<TestPermissionQuery, TestPermissionQueryVariables>(TestPermissionDocument, baseOptions);
      }
export function useTestPermissionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TestPermissionQuery, TestPermissionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TestPermissionQuery, TestPermissionQueryVariables>(TestPermissionDocument, baseOptions);
        }
export type TestPermissionQueryHookResult = ReturnType<typeof useTestPermissionQuery>;
export type TestPermissionLazyQueryHookResult = ReturnType<typeof useTestPermissionLazyQuery>;
export type TestPermissionQueryResult = ApolloReactCommon.QueryResult<TestPermissionQuery, TestPermissionQueryVariables>;
export const GetAccessSecuritySummaryDocument = gql`
    query getAccessSecuritySummary {
  userDate
  roleDate
}
    `;

/**
 * __useGetAccessSecuritySummaryQuery__
 *
 * To run a query within a React component, call `useGetAccessSecuritySummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccessSecuritySummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccessSecuritySummaryQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAccessSecuritySummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAccessSecuritySummaryQuery, GetAccessSecuritySummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAccessSecuritySummaryQuery, GetAccessSecuritySummaryQueryVariables>(GetAccessSecuritySummaryDocument, baseOptions);
      }
export function useGetAccessSecuritySummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAccessSecuritySummaryQuery, GetAccessSecuritySummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAccessSecuritySummaryQuery, GetAccessSecuritySummaryQueryVariables>(GetAccessSecuritySummaryDocument, baseOptions);
        }
export type GetAccessSecuritySummaryQueryHookResult = ReturnType<typeof useGetAccessSecuritySummaryQuery>;
export type GetAccessSecuritySummaryLazyQueryHookResult = ReturnType<typeof useGetAccessSecuritySummaryLazyQuery>;
export type GetAccessSecuritySummaryQueryResult = ApolloReactCommon.QueryResult<GetAccessSecuritySummaryQuery, GetAccessSecuritySummaryQueryVariables>;
export const ResendInvitationDocument = gql`
    mutation resendInvitation($userID: String!) {
  resendInvitation(userID: $userID)
}
    `;
export type ResendInvitationMutationFn = ApolloReactCommon.MutationFunction<ResendInvitationMutation, ResendInvitationMutationVariables>;

/**
 * __useResendInvitationMutation__
 *
 * To run a mutation, you first call `useResendInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendInvitationMutation, { data, loading, error }] = useResendInvitationMutation({
 *   variables: {
 *      userID: // value for 'userID'
 *   },
 * });
 */
export function useResendInvitationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ResendInvitationMutation, ResendInvitationMutationVariables>) {
        return ApolloReactHooks.useMutation<ResendInvitationMutation, ResendInvitationMutationVariables>(ResendInvitationDocument, baseOptions);
      }
export type ResendInvitationMutationHookResult = ReturnType<typeof useResendInvitationMutation>;
export type ResendInvitationMutationResult = ApolloReactCommon.MutationResult<ResendInvitationMutation>;
export type ResendInvitationMutationOptions = ApolloReactCommon.BaseMutationOptions<ResendInvitationMutation, ResendInvitationMutationVariables>;
export const GetEntityRoleUserAssignmentDocument = gql`
    query getEntityRoleUserAssignment($ID: String, $entityID: String) {
  getEntityRoleUserAssignment(ID: $ID, entityID: $entityID) {
    userID
    roleID
    entityID
  }
}
    `;

/**
 * __useGetEntityRoleUserAssignmentQuery__
 *
 * To run a query within a React component, call `useGetEntityRoleUserAssignmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEntityRoleUserAssignmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEntityRoleUserAssignmentQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      entityID: // value for 'entityID'
 *   },
 * });
 */
export function useGetEntityRoleUserAssignmentQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetEntityRoleUserAssignmentQuery, GetEntityRoleUserAssignmentQueryVariables>) {
        return ApolloReactHooks.useQuery<GetEntityRoleUserAssignmentQuery, GetEntityRoleUserAssignmentQueryVariables>(GetEntityRoleUserAssignmentDocument, baseOptions);
      }
export function useGetEntityRoleUserAssignmentLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetEntityRoleUserAssignmentQuery, GetEntityRoleUserAssignmentQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetEntityRoleUserAssignmentQuery, GetEntityRoleUserAssignmentQueryVariables>(GetEntityRoleUserAssignmentDocument, baseOptions);
        }
export type GetEntityRoleUserAssignmentQueryHookResult = ReturnType<typeof useGetEntityRoleUserAssignmentQuery>;
export type GetEntityRoleUserAssignmentLazyQueryHookResult = ReturnType<typeof useGetEntityRoleUserAssignmentLazyQuery>;
export type GetEntityRoleUserAssignmentQueryResult = ApolloReactCommon.QueryResult<GetEntityRoleUserAssignmentQuery, GetEntityRoleUserAssignmentQueryVariables>;
export const GetOutletRoleAssignmentDocument = gql`
    query getOutletRoleAssignment($ID: String) {
  getOutlet(ID: $ID) {
    ID
    name
    businessDate
    address
    tablesInfo
  }
  getEntityRoleUserAssignment(ID: $ID) {
    ID
    userID
    roleID
    entityID
    accountID
  }
}
    `;

/**
 * __useGetOutletRoleAssignmentQuery__
 *
 * To run a query within a React component, call `useGetOutletRoleAssignmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOutletRoleAssignmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOutletRoleAssignmentQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useGetOutletRoleAssignmentQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetOutletRoleAssignmentQuery, GetOutletRoleAssignmentQueryVariables>) {
        return ApolloReactHooks.useQuery<GetOutletRoleAssignmentQuery, GetOutletRoleAssignmentQueryVariables>(GetOutletRoleAssignmentDocument, baseOptions);
      }
export function useGetOutletRoleAssignmentLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetOutletRoleAssignmentQuery, GetOutletRoleAssignmentQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetOutletRoleAssignmentQuery, GetOutletRoleAssignmentQueryVariables>(GetOutletRoleAssignmentDocument, baseOptions);
        }
export type GetOutletRoleAssignmentQueryHookResult = ReturnType<typeof useGetOutletRoleAssignmentQuery>;
export type GetOutletRoleAssignmentLazyQueryHookResult = ReturnType<typeof useGetOutletRoleAssignmentLazyQuery>;
export type GetOutletRoleAssignmentQueryResult = ApolloReactCommon.QueryResult<GetOutletRoleAssignmentQuery, GetOutletRoleAssignmentQueryVariables>;
export const UserRoleIDsDocument = gql`
    query userRoleIDs($outletID: String!) {
  userRoleIDs(outletID: $outletID) {
    userID
    roleID
  }
}
    `;

/**
 * __useUserRoleIDsQuery__
 *
 * To run a query within a React component, call `useUserRoleIDsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserRoleIDsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserRoleIDsQuery({
 *   variables: {
 *      outletID: // value for 'outletID'
 *   },
 * });
 */
export function useUserRoleIDsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserRoleIDsQuery, UserRoleIDsQueryVariables>) {
        return ApolloReactHooks.useQuery<UserRoleIDsQuery, UserRoleIDsQueryVariables>(UserRoleIDsDocument, baseOptions);
      }
export function useUserRoleIDsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserRoleIDsQuery, UserRoleIDsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UserRoleIDsQuery, UserRoleIDsQueryVariables>(UserRoleIDsDocument, baseOptions);
        }
export type UserRoleIDsQueryHookResult = ReturnType<typeof useUserRoleIDsQuery>;
export type UserRoleIDsLazyQueryHookResult = ReturnType<typeof useUserRoleIDsLazyQuery>;
export type UserRoleIDsQueryResult = ApolloReactCommon.QueryResult<UserRoleIDsQuery, UserRoleIDsQueryVariables>;
export const CreateEntityRoleUserDocument = gql`
    mutation CreateEntityRoleUser($input: EntityRoleUserAsgInput!) {
  CreateEntityRoleUser(input: $input)
}
    `;
export type CreateEntityRoleUserMutationFn = ApolloReactCommon.MutationFunction<CreateEntityRoleUserMutation, CreateEntityRoleUserMutationVariables>;

/**
 * __useCreateEntityRoleUserMutation__
 *
 * To run a mutation, you first call `useCreateEntityRoleUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEntityRoleUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEntityRoleUserMutation, { data, loading, error }] = useCreateEntityRoleUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEntityRoleUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateEntityRoleUserMutation, CreateEntityRoleUserMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateEntityRoleUserMutation, CreateEntityRoleUserMutationVariables>(CreateEntityRoleUserDocument, baseOptions);
      }
export type CreateEntityRoleUserMutationHookResult = ReturnType<typeof useCreateEntityRoleUserMutation>;
export type CreateEntityRoleUserMutationResult = ApolloReactCommon.MutationResult<CreateEntityRoleUserMutation>;
export type CreateEntityRoleUserMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateEntityRoleUserMutation, CreateEntityRoleUserMutationVariables>;
export const CreateListEntityRoleUserDocument = gql`
    mutation CreateListEntityRoleUser($outletID: String!, $roleUserIDs: [RoleUserInput!]!) {
  CreateListEntityRoleUser(outletID: $outletID, roleUserIDs: $roleUserIDs)
}
    `;
export type CreateListEntityRoleUserMutationFn = ApolloReactCommon.MutationFunction<CreateListEntityRoleUserMutation, CreateListEntityRoleUserMutationVariables>;

/**
 * __useCreateListEntityRoleUserMutation__
 *
 * To run a mutation, you first call `useCreateListEntityRoleUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateListEntityRoleUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createListEntityRoleUserMutation, { data, loading, error }] = useCreateListEntityRoleUserMutation({
 *   variables: {
 *      outletID: // value for 'outletID'
 *      roleUserIDs: // value for 'roleUserIDs'
 *   },
 * });
 */
export function useCreateListEntityRoleUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateListEntityRoleUserMutation, CreateListEntityRoleUserMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateListEntityRoleUserMutation, CreateListEntityRoleUserMutationVariables>(CreateListEntityRoleUserDocument, baseOptions);
      }
export type CreateListEntityRoleUserMutationHookResult = ReturnType<typeof useCreateListEntityRoleUserMutation>;
export type CreateListEntityRoleUserMutationResult = ApolloReactCommon.MutationResult<CreateListEntityRoleUserMutation>;
export type CreateListEntityRoleUserMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateListEntityRoleUserMutation, CreateListEntityRoleUserMutationVariables>;
export const CreateRolePermissionDocument = gql`
    mutation CreateRolePermission($permissionArr: [POSPermission!]!, $input: RoleInput!) {
  CreateRolePermission(permissionArr: $permissionArr, input: $input)
}
    `;
export type CreateRolePermissionMutationFn = ApolloReactCommon.MutationFunction<CreateRolePermissionMutation, CreateRolePermissionMutationVariables>;

/**
 * __useCreateRolePermissionMutation__
 *
 * To run a mutation, you first call `useCreateRolePermissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRolePermissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRolePermissionMutation, { data, loading, error }] = useCreateRolePermissionMutation({
 *   variables: {
 *      permissionArr: // value for 'permissionArr'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRolePermissionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateRolePermissionMutation, CreateRolePermissionMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateRolePermissionMutation, CreateRolePermissionMutationVariables>(CreateRolePermissionDocument, baseOptions);
      }
export type CreateRolePermissionMutationHookResult = ReturnType<typeof useCreateRolePermissionMutation>;
export type CreateRolePermissionMutationResult = ApolloReactCommon.MutationResult<CreateRolePermissionMutation>;
export type CreateRolePermissionMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateRolePermissionMutation, CreateRolePermissionMutationVariables>;
export const UpdateRolePermissionDocument = gql`
    mutation UpdateRolePermission($permissionArr: [POSPermission!]!, $input: RoleInput!) {
  UpdateRolePermission(permissionArr: $permissionArr, input: $input)
}
    `;
export type UpdateRolePermissionMutationFn = ApolloReactCommon.MutationFunction<UpdateRolePermissionMutation, UpdateRolePermissionMutationVariables>;

/**
 * __useUpdateRolePermissionMutation__
 *
 * To run a mutation, you first call `useUpdateRolePermissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRolePermissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRolePermissionMutation, { data, loading, error }] = useUpdateRolePermissionMutation({
 *   variables: {
 *      permissionArr: // value for 'permissionArr'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRolePermissionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateRolePermissionMutation, UpdateRolePermissionMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateRolePermissionMutation, UpdateRolePermissionMutationVariables>(UpdateRolePermissionDocument, baseOptions);
      }
export type UpdateRolePermissionMutationHookResult = ReturnType<typeof useUpdateRolePermissionMutation>;
export type UpdateRolePermissionMutationResult = ApolloReactCommon.MutationResult<UpdateRolePermissionMutation>;
export type UpdateRolePermissionMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateRolePermissionMutation, UpdateRolePermissionMutationVariables>;
export const DeleteRolePermissionDocument = gql`
    mutation DeleteRolePermission($roleID: String!) {
  DeleteRolePermission(roleID: $roleID)
}
    `;
export type DeleteRolePermissionMutationFn = ApolloReactCommon.MutationFunction<DeleteRolePermissionMutation, DeleteRolePermissionMutationVariables>;

/**
 * __useDeleteRolePermissionMutation__
 *
 * To run a mutation, you first call `useDeleteRolePermissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRolePermissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRolePermissionMutation, { data, loading, error }] = useDeleteRolePermissionMutation({
 *   variables: {
 *      roleID: // value for 'roleID'
 *   },
 * });
 */
export function useDeleteRolePermissionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteRolePermissionMutation, DeleteRolePermissionMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteRolePermissionMutation, DeleteRolePermissionMutationVariables>(DeleteRolePermissionDocument, baseOptions);
      }
export type DeleteRolePermissionMutationHookResult = ReturnType<typeof useDeleteRolePermissionMutation>;
export type DeleteRolePermissionMutationResult = ApolloReactCommon.MutationResult<DeleteRolePermissionMutation>;
export type DeleteRolePermissionMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteRolePermissionMutation, DeleteRolePermissionMutationVariables>;
export const DuplicateRolePermissionDocument = gql`
    mutation DuplicateRolePermission($roleID: String!, $name: String!) {
  DuplicateRolePermission(roleID: $roleID, name: $name)
}
    `;
export type DuplicateRolePermissionMutationFn = ApolloReactCommon.MutationFunction<DuplicateRolePermissionMutation, DuplicateRolePermissionMutationVariables>;

/**
 * __useDuplicateRolePermissionMutation__
 *
 * To run a mutation, you first call `useDuplicateRolePermissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateRolePermissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateRolePermissionMutation, { data, loading, error }] = useDuplicateRolePermissionMutation({
 *   variables: {
 *      roleID: // value for 'roleID'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useDuplicateRolePermissionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DuplicateRolePermissionMutation, DuplicateRolePermissionMutationVariables>) {
        return ApolloReactHooks.useMutation<DuplicateRolePermissionMutation, DuplicateRolePermissionMutationVariables>(DuplicateRolePermissionDocument, baseOptions);
      }
export type DuplicateRolePermissionMutationHookResult = ReturnType<typeof useDuplicateRolePermissionMutation>;
export type DuplicateRolePermissionMutationResult = ApolloReactCommon.MutationResult<DuplicateRolePermissionMutation>;
export type DuplicateRolePermissionMutationOptions = ApolloReactCommon.BaseMutationOptions<DuplicateRolePermissionMutation, DuplicateRolePermissionMutationVariables>;
export const GetHomeTableSummaryDocument = gql`
    query getHomeTableSummary($tableID: String!) {
  orderCount(tableID: $tableID)
  orderItemCount(tableID: $tableID)
  newestTable(ID: $tableID)
  itemTransferCount(tableID: $tableID)
  billSettlement(tableID: $tableID)
  billItemDiscount(tableID: $tableID)
  close(tableID: $tableID)
  editOrderInfo(tableID: $tableID)
}
    `;

/**
 * __useGetHomeTableSummaryQuery__
 *
 * To run a query within a React component, call `useGetHomeTableSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHomeTableSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHomeTableSummaryQuery({
 *   variables: {
 *      tableID: // value for 'tableID'
 *   },
 * });
 */
export function useGetHomeTableSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetHomeTableSummaryQuery, GetHomeTableSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetHomeTableSummaryQuery, GetHomeTableSummaryQueryVariables>(GetHomeTableSummaryDocument, baseOptions);
      }
export function useGetHomeTableSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetHomeTableSummaryQuery, GetHomeTableSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetHomeTableSummaryQuery, GetHomeTableSummaryQueryVariables>(GetHomeTableSummaryDocument, baseOptions);
        }
export type GetHomeTableSummaryQueryHookResult = ReturnType<typeof useGetHomeTableSummaryQuery>;
export type GetHomeTableSummaryLazyQueryHookResult = ReturnType<typeof useGetHomeTableSummaryLazyQuery>;
export type GetHomeTableSummaryQueryResult = ApolloReactCommon.QueryResult<GetHomeTableSummaryQuery, GetHomeTableSummaryQueryVariables>;
export const TransferTableDocument = gql`
    mutation transferTable($newTableID: String!, $oldTableID: String!, $orderID: String!) {
  transferTable(newTableID: $newTableID, oldTableID: $oldTableID, orderID: $orderID)
}
    `;
export type TransferTableMutationFn = ApolloReactCommon.MutationFunction<TransferTableMutation, TransferTableMutationVariables>;

/**
 * __useTransferTableMutation__
 *
 * To run a mutation, you first call `useTransferTableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTransferTableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [transferTableMutation, { data, loading, error }] = useTransferTableMutation({
 *   variables: {
 *      newTableID: // value for 'newTableID'
 *      oldTableID: // value for 'oldTableID'
 *      orderID: // value for 'orderID'
 *   },
 * });
 */
export function useTransferTableMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TransferTableMutation, TransferTableMutationVariables>) {
        return ApolloReactHooks.useMutation<TransferTableMutation, TransferTableMutationVariables>(TransferTableDocument, baseOptions);
      }
export type TransferTableMutationHookResult = ReturnType<typeof useTransferTableMutation>;
export type TransferTableMutationResult = ApolloReactCommon.MutationResult<TransferTableMutation>;
export type TransferTableMutationOptions = ApolloReactCommon.BaseMutationOptions<TransferTableMutation, TransferTableMutationVariables>;
export const TransferItemByQuantityDocument = gql`
    mutation transferItemByQuantity($transferInput: [TransferInput!]!, $targetOrderID: String!, $sourceOrderID: String!) {
  transferItemByQuantity(transferInput: $transferInput, targetOrderID: $targetOrderID, sourceOrderID: $sourceOrderID)
}
    `;
export type TransferItemByQuantityMutationFn = ApolloReactCommon.MutationFunction<TransferItemByQuantityMutation, TransferItemByQuantityMutationVariables>;

/**
 * __useTransferItemByQuantityMutation__
 *
 * To run a mutation, you first call `useTransferItemByQuantityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTransferItemByQuantityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [transferItemByQuantityMutation, { data, loading, error }] = useTransferItemByQuantityMutation({
 *   variables: {
 *      transferInput: // value for 'transferInput'
 *      targetOrderID: // value for 'targetOrderID'
 *      sourceOrderID: // value for 'sourceOrderID'
 *   },
 * });
 */
export function useTransferItemByQuantityMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TransferItemByQuantityMutation, TransferItemByQuantityMutationVariables>) {
        return ApolloReactHooks.useMutation<TransferItemByQuantityMutation, TransferItemByQuantityMutationVariables>(TransferItemByQuantityDocument, baseOptions);
      }
export type TransferItemByQuantityMutationHookResult = ReturnType<typeof useTransferItemByQuantityMutation>;
export type TransferItemByQuantityMutationResult = ApolloReactCommon.MutationResult<TransferItemByQuantityMutation>;
export type TransferItemByQuantityMutationOptions = ApolloReactCommon.BaseMutationOptions<TransferItemByQuantityMutation, TransferItemByQuantityMutationVariables>;
export const GetTableSubmenuTableDocument = gql`
    query getTableSubmenuTable($ID: String, $isActive: Boolean, $name: String, $outletID: String, $orderByAsc: String) {
  getTable(ID: $ID, isActive: $isActive, name: $name, outletID: $outletID, orderByAsc: $orderByAsc) {
    ID
    tableNumber
    createdBy
    seatCount
    prefix
    outletID
    isActive
    status
    orderInfo {
      totalAmount
      totalPax
    }
    reservation {
      ID
    }
    outlet {
      ID
      name
    }
    order {
      ID
      docNo
      patronAccountID
      tableID
      status
      pax
      patron {
        ID
        patronClass
        patronType {
          ID
          patronClass
        }
      }
    }
  }
}
    `;

/**
 * __useGetTableSubmenuTableQuery__
 *
 * To run a query within a React component, call `useGetTableSubmenuTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTableSubmenuTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTableSubmenuTableQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      isActive: // value for 'isActive'
 *      name: // value for 'name'
 *      outletID: // value for 'outletID'
 *      orderByAsc: // value for 'orderByAsc'
 *   },
 * });
 */
export function useGetTableSubmenuTableQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetTableSubmenuTableQuery, GetTableSubmenuTableQueryVariables>) {
        return ApolloReactHooks.useQuery<GetTableSubmenuTableQuery, GetTableSubmenuTableQueryVariables>(GetTableSubmenuTableDocument, baseOptions);
      }
export function useGetTableSubmenuTableLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTableSubmenuTableQuery, GetTableSubmenuTableQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetTableSubmenuTableQuery, GetTableSubmenuTableQueryVariables>(GetTableSubmenuTableDocument, baseOptions);
        }
export type GetTableSubmenuTableQueryHookResult = ReturnType<typeof useGetTableSubmenuTableQuery>;
export type GetTableSubmenuTableLazyQueryHookResult = ReturnType<typeof useGetTableSubmenuTableLazyQuery>;
export type GetTableSubmenuTableQueryResult = ApolloReactCommon.QueryResult<GetTableSubmenuTableQuery, GetTableSubmenuTableQueryVariables>;
export const GetTableGridDocument = gql`
    query getTableGrid($ID: String, $isActive: Boolean, $name: String, $outletID: String) {
  getTable(ID: $ID, isActive: $isActive, name: $name, outletID: $outletID) {
    ID
    tableNumber
    seatCount
    prefix
    outletID
    isActive
    status
    orderInfo {
      totalAmount
      totalPax
    }
    reservationInfo {
      reserved
      pax
      name
      time
      noShow
    }
    reservation {
      ID
      name
      reservationDate
      reservationTime
    }
    outlet {
      ID
      name
    }
    order {
      tableID
      status
    }
  }
}
    `;

/**
 * __useGetTableGridQuery__
 *
 * To run a query within a React component, call `useGetTableGridQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTableGridQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTableGridQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      isActive: // value for 'isActive'
 *      name: // value for 'name'
 *      outletID: // value for 'outletID'
 *   },
 * });
 */
export function useGetTableGridQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetTableGridQuery, GetTableGridQueryVariables>) {
        return ApolloReactHooks.useQuery<GetTableGridQuery, GetTableGridQueryVariables>(GetTableGridDocument, baseOptions);
      }
export function useGetTableGridLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTableGridQuery, GetTableGridQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetTableGridQuery, GetTableGridQueryVariables>(GetTableGridDocument, baseOptions);
        }
export type GetTableGridQueryHookResult = ReturnType<typeof useGetTableGridQuery>;
export type GetTableGridLazyQueryHookResult = ReturnType<typeof useGetTableGridLazyQuery>;
export type GetTableGridQueryResult = ApolloReactCommon.QueryResult<GetTableGridQuery, GetTableGridQueryVariables>;
export const CloseTableStatusDocument = gql`
    mutation closeTableStatus($tableID: String!, $billInput: [BillInput!]!, $paymentByQuantityInput: [PaymentByQuantityInput!]!) {
  closeTableStatus(tableID: $tableID, billInput: $billInput, paymentByQuantityInput: $paymentByQuantityInput)
}
    `;
export type CloseTableStatusMutationFn = ApolloReactCommon.MutationFunction<CloseTableStatusMutation, CloseTableStatusMutationVariables>;

/**
 * __useCloseTableStatusMutation__
 *
 * To run a mutation, you first call `useCloseTableStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCloseTableStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [closeTableStatusMutation, { data, loading, error }] = useCloseTableStatusMutation({
 *   variables: {
 *      tableID: // value for 'tableID'
 *      billInput: // value for 'billInput'
 *      paymentByQuantityInput: // value for 'paymentByQuantityInput'
 *   },
 * });
 */
export function useCloseTableStatusMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CloseTableStatusMutation, CloseTableStatusMutationVariables>) {
        return ApolloReactHooks.useMutation<CloseTableStatusMutation, CloseTableStatusMutationVariables>(CloseTableStatusDocument, baseOptions);
      }
export type CloseTableStatusMutationHookResult = ReturnType<typeof useCloseTableStatusMutation>;
export type CloseTableStatusMutationResult = ApolloReactCommon.MutationResult<CloseTableStatusMutation>;
export type CloseTableStatusMutationOptions = ApolloReactCommon.BaseMutationOptions<CloseTableStatusMutation, CloseTableStatusMutationVariables>;
export const GetTableByAscOrderDocument = gql`
    query getTableByAscOrder($outletID: String!, $isActive: Boolean) {
  getTableByAscOrder(outletID: $outletID, isActive: $isActive) {
    ID
    tableNumber
    seatCount
    prefix
    outletID
    isActive
    status
    orderInfo {
      totalAmount
      totalPax
    }
    reservationInfo {
      reserved
      pax
      name
      time
      noShow
    }
    reservation {
      ID
      name
      reservationDate
      reservationTime
    }
    outlet {
      ID
      name
    }
    order {
      tableID
      status
    }
  }
}
    `;

/**
 * __useGetTableByAscOrderQuery__
 *
 * To run a query within a React component, call `useGetTableByAscOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTableByAscOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTableByAscOrderQuery({
 *   variables: {
 *      outletID: // value for 'outletID'
 *      isActive: // value for 'isActive'
 *   },
 * });
 */
export function useGetTableByAscOrderQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetTableByAscOrderQuery, GetTableByAscOrderQueryVariables>) {
        return ApolloReactHooks.useQuery<GetTableByAscOrderQuery, GetTableByAscOrderQueryVariables>(GetTableByAscOrderDocument, baseOptions);
      }
export function useGetTableByAscOrderLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTableByAscOrderQuery, GetTableByAscOrderQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetTableByAscOrderQuery, GetTableByAscOrderQueryVariables>(GetTableByAscOrderDocument, baseOptions);
        }
export type GetTableByAscOrderQueryHookResult = ReturnType<typeof useGetTableByAscOrderQuery>;
export type GetTableByAscOrderLazyQueryHookResult = ReturnType<typeof useGetTableByAscOrderLazyQuery>;
export type GetTableByAscOrderQueryResult = ApolloReactCommon.QueryResult<GetTableByAscOrderQuery, GetTableByAscOrderQueryVariables>;
export const CheckCurrentMealPeriodDocument = gql`
    query checkCurrentMealPeriod($outletID: String!) {
  getCurrentMealPeriod(outletID: $outletID) {
    ID
    name
    startTime
    endTime
    outletID
    isActive
  }
}
    `;

/**
 * __useCheckCurrentMealPeriodQuery__
 *
 * To run a query within a React component, call `useCheckCurrentMealPeriodQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckCurrentMealPeriodQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckCurrentMealPeriodQuery({
 *   variables: {
 *      outletID: // value for 'outletID'
 *   },
 * });
 */
export function useCheckCurrentMealPeriodQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CheckCurrentMealPeriodQuery, CheckCurrentMealPeriodQueryVariables>) {
        return ApolloReactHooks.useQuery<CheckCurrentMealPeriodQuery, CheckCurrentMealPeriodQueryVariables>(CheckCurrentMealPeriodDocument, baseOptions);
      }
export function useCheckCurrentMealPeriodLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CheckCurrentMealPeriodQuery, CheckCurrentMealPeriodQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CheckCurrentMealPeriodQuery, CheckCurrentMealPeriodQueryVariables>(CheckCurrentMealPeriodDocument, baseOptions);
        }
export type CheckCurrentMealPeriodQueryHookResult = ReturnType<typeof useCheckCurrentMealPeriodQuery>;
export type CheckCurrentMealPeriodLazyQueryHookResult = ReturnType<typeof useCheckCurrentMealPeriodLazyQuery>;
export type CheckCurrentMealPeriodQueryResult = ApolloReactCommon.QueryResult<CheckCurrentMealPeriodQuery, CheckCurrentMealPeriodQueryVariables>;
export const GetOrderItemDocument = gql`
    query getOrderItem($ID: String, $orderID: String, $billID: String, $status: OrderItemStatus) {
  getOrderItem(ID: $ID, orderID: $orderID, billID: $billID, status: $status) {
    ID
    orderID
    billID
    openItemName
    quantity
    taxAmount
    serviceCharge
    amount
    baseAmount
    kitchenStatus
    status
    order {
      ID
      bill {
        billNo
      }
    }
    menuItem {
      ID
      name
      description
      isDiscountable
    }
    orderItemDiscount {
      ID
      amount
      status
      reasonCode
      remark
    }
    orderDiscount
    orderItemOption {
      ID
      orderItemOptionItem {
        ID
        price
        basePrice
        unitPrice
        taxAmount
        serviceCharge
      }
    }
    totalInfo
  }
}
    `;

/**
 * __useGetOrderItemQuery__
 *
 * To run a query within a React component, call `useGetOrderItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrderItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrderItemQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      orderID: // value for 'orderID'
 *      billID: // value for 'billID'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useGetOrderItemQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetOrderItemQuery, GetOrderItemQueryVariables>) {
        return ApolloReactHooks.useQuery<GetOrderItemQuery, GetOrderItemQueryVariables>(GetOrderItemDocument, baseOptions);
      }
export function useGetOrderItemLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetOrderItemQuery, GetOrderItemQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetOrderItemQuery, GetOrderItemQueryVariables>(GetOrderItemDocument, baseOptions);
        }
export type GetOrderItemQueryHookResult = ReturnType<typeof useGetOrderItemQuery>;
export type GetOrderItemLazyQueryHookResult = ReturnType<typeof useGetOrderItemLazyQuery>;
export type GetOrderItemQueryResult = ApolloReactCommon.QueryResult<GetOrderItemQuery, GetOrderItemQueryVariables>;
export const GetBillDiscountOrderDocument = gql`
    query getBillDiscountOrder($outletID: String!, $ID: String, $tableID: String, $status: OrderStatus) {
  getOrder(outletID: $outletID, ID: $ID, tableID: $tableID, status: $status) {
    ID
    docNo
    outletID
    tableID
    mealPeriodID
    patronID
    totalAmount
    patronName
    taxSchemeInfo
    taxSchemeFooterInfo
    outstandingAmount
    patronAccountID
    contactNo
    pickupTime
    serviceMode
    status
    qr
    pax
    patron {
      patronClass
    }
    bill {
      ID
      billNo
      businessDate
    }
    table {
      ID
      name
      seatCount
      prefix
      tableNumber
      status
    }
    orderDiscount {
      ID
      discountID
      orderID
      amount
      status
      reasonCode
      remark
    }
    orderItem {
      ID
      name
      orderID
      amount
      specialRequest
      quantity
      status
      kitchenStatus
      isTakeaway
      weight
      menuItemID
      openItemName
      menuItem {
        name
        description
        attachments
      }
      orderItemOption {
        optionID
        name
        orderItemOptionItem {
          optionItemID
          optionItem {
            name
          }
        }
      }
      orderItemSetMenuOption {
        setMenuOptionID
        name
        orderItemSetMenuOptionItem {
          setMenuOptionItemID
          setMenuOptionItem {
            ID
            menuItem {
              ID
              name
            }
          }
        }
      }
      billID
      refID
    }
  }
}
    `;

/**
 * __useGetBillDiscountOrderQuery__
 *
 * To run a query within a React component, call `useGetBillDiscountOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBillDiscountOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBillDiscountOrderQuery({
 *   variables: {
 *      outletID: // value for 'outletID'
 *      ID: // value for 'ID'
 *      tableID: // value for 'tableID'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useGetBillDiscountOrderQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBillDiscountOrderQuery, GetBillDiscountOrderQueryVariables>) {
        return ApolloReactHooks.useQuery<GetBillDiscountOrderQuery, GetBillDiscountOrderQueryVariables>(GetBillDiscountOrderDocument, baseOptions);
      }
export function useGetBillDiscountOrderLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBillDiscountOrderQuery, GetBillDiscountOrderQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetBillDiscountOrderQuery, GetBillDiscountOrderQueryVariables>(GetBillDiscountOrderDocument, baseOptions);
        }
export type GetBillDiscountOrderQueryHookResult = ReturnType<typeof useGetBillDiscountOrderQuery>;
export type GetBillDiscountOrderLazyQueryHookResult = ReturnType<typeof useGetBillDiscountOrderLazyQuery>;
export type GetBillDiscountOrderQueryResult = ApolloReactCommon.QueryResult<GetBillDiscountOrderQuery, GetBillDiscountOrderQueryVariables>;
export const CreateOrderItemWithOptionsDocument = gql`
    mutation createOrderItemWithOptions($orderItemOptionInput: [OrderItemOptionInput!], $orderItemInput: OrderItemInput!) {
  createOrderItemWithOptions(orderItemOptionInput: $orderItemOptionInput, orderItemInput: $orderItemInput)
}
    `;
export type CreateOrderItemWithOptionsMutationFn = ApolloReactCommon.MutationFunction<CreateOrderItemWithOptionsMutation, CreateOrderItemWithOptionsMutationVariables>;

/**
 * __useCreateOrderItemWithOptionsMutation__
 *
 * To run a mutation, you first call `useCreateOrderItemWithOptionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrderItemWithOptionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrderItemWithOptionsMutation, { data, loading, error }] = useCreateOrderItemWithOptionsMutation({
 *   variables: {
 *      orderItemOptionInput: // value for 'orderItemOptionInput'
 *      orderItemInput: // value for 'orderItemInput'
 *   },
 * });
 */
export function useCreateOrderItemWithOptionsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateOrderItemWithOptionsMutation, CreateOrderItemWithOptionsMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateOrderItemWithOptionsMutation, CreateOrderItemWithOptionsMutationVariables>(CreateOrderItemWithOptionsDocument, baseOptions);
      }
export type CreateOrderItemWithOptionsMutationHookResult = ReturnType<typeof useCreateOrderItemWithOptionsMutation>;
export type CreateOrderItemWithOptionsMutationResult = ApolloReactCommon.MutationResult<CreateOrderItemWithOptionsMutation>;
export type CreateOrderItemWithOptionsMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateOrderItemWithOptionsMutation, CreateOrderItemWithOptionsMutationVariables>;
export const CreateOrderItemWithSetMenuOptionsDocument = gql`
    mutation createOrderItemWithSetMenuOptions($orderItemInput: OrderItemInput!, $orderItemSetMenuOptionInput: [OrderItemSetMenuOptionInput!]) {
  createOrderItemWithSetMenuOptions(orderItemInput: $orderItemInput, orderItemSetMenuOptionInput: $orderItemSetMenuOptionInput)
}
    `;
export type CreateOrderItemWithSetMenuOptionsMutationFn = ApolloReactCommon.MutationFunction<CreateOrderItemWithSetMenuOptionsMutation, CreateOrderItemWithSetMenuOptionsMutationVariables>;

/**
 * __useCreateOrderItemWithSetMenuOptionsMutation__
 *
 * To run a mutation, you first call `useCreateOrderItemWithSetMenuOptionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrderItemWithSetMenuOptionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrderItemWithSetMenuOptionsMutation, { data, loading, error }] = useCreateOrderItemWithSetMenuOptionsMutation({
 *   variables: {
 *      orderItemInput: // value for 'orderItemInput'
 *      orderItemSetMenuOptionInput: // value for 'orderItemSetMenuOptionInput'
 *   },
 * });
 */
export function useCreateOrderItemWithSetMenuOptionsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateOrderItemWithSetMenuOptionsMutation, CreateOrderItemWithSetMenuOptionsMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateOrderItemWithSetMenuOptionsMutation, CreateOrderItemWithSetMenuOptionsMutationVariables>(CreateOrderItemWithSetMenuOptionsDocument, baseOptions);
      }
export type CreateOrderItemWithSetMenuOptionsMutationHookResult = ReturnType<typeof useCreateOrderItemWithSetMenuOptionsMutation>;
export type CreateOrderItemWithSetMenuOptionsMutationResult = ApolloReactCommon.MutationResult<CreateOrderItemWithSetMenuOptionsMutation>;
export type CreateOrderItemWithSetMenuOptionsMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateOrderItemWithSetMenuOptionsMutation, CreateOrderItemWithSetMenuOptionsMutationVariables>;
export const UpdateOrderItemWithOptionsDocument = gql`
    mutation updateOrderItemWithOptions($orderItemInput: OrderItemInput!, $orderItemOptionInput: [OrderItemOptionInput!]) {
  updateOrderItemWithOptions(orderItemInput: $orderItemInput, orderItemOptionInput: $orderItemOptionInput)
}
    `;
export type UpdateOrderItemWithOptionsMutationFn = ApolloReactCommon.MutationFunction<UpdateOrderItemWithOptionsMutation, UpdateOrderItemWithOptionsMutationVariables>;

/**
 * __useUpdateOrderItemWithOptionsMutation__
 *
 * To run a mutation, you first call `useUpdateOrderItemWithOptionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrderItemWithOptionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrderItemWithOptionsMutation, { data, loading, error }] = useUpdateOrderItemWithOptionsMutation({
 *   variables: {
 *      orderItemInput: // value for 'orderItemInput'
 *      orderItemOptionInput: // value for 'orderItemOptionInput'
 *   },
 * });
 */
export function useUpdateOrderItemWithOptionsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateOrderItemWithOptionsMutation, UpdateOrderItemWithOptionsMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateOrderItemWithOptionsMutation, UpdateOrderItemWithOptionsMutationVariables>(UpdateOrderItemWithOptionsDocument, baseOptions);
      }
export type UpdateOrderItemWithOptionsMutationHookResult = ReturnType<typeof useUpdateOrderItemWithOptionsMutation>;
export type UpdateOrderItemWithOptionsMutationResult = ApolloReactCommon.MutationResult<UpdateOrderItemWithOptionsMutation>;
export type UpdateOrderItemWithOptionsMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateOrderItemWithOptionsMutation, UpdateOrderItemWithOptionsMutationVariables>;
export const UpdateOrderItemWithSetMenuOptionsDocument = gql`
    mutation updateOrderItemWithSetMenuOptions($orderItemInput: OrderItemInput!, $orderItemSetMenuOptionInput: [OrderItemSetMenuOptionInput!]) {
  updateOrderItemWithSetMenuOptions(orderItemInput: $orderItemInput, orderItemSetMenuOptionInput: $orderItemSetMenuOptionInput)
}
    `;
export type UpdateOrderItemWithSetMenuOptionsMutationFn = ApolloReactCommon.MutationFunction<UpdateOrderItemWithSetMenuOptionsMutation, UpdateOrderItemWithSetMenuOptionsMutationVariables>;

/**
 * __useUpdateOrderItemWithSetMenuOptionsMutation__
 *
 * To run a mutation, you first call `useUpdateOrderItemWithSetMenuOptionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrderItemWithSetMenuOptionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrderItemWithSetMenuOptionsMutation, { data, loading, error }] = useUpdateOrderItemWithSetMenuOptionsMutation({
 *   variables: {
 *      orderItemInput: // value for 'orderItemInput'
 *      orderItemSetMenuOptionInput: // value for 'orderItemSetMenuOptionInput'
 *   },
 * });
 */
export function useUpdateOrderItemWithSetMenuOptionsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateOrderItemWithSetMenuOptionsMutation, UpdateOrderItemWithSetMenuOptionsMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateOrderItemWithSetMenuOptionsMutation, UpdateOrderItemWithSetMenuOptionsMutationVariables>(UpdateOrderItemWithSetMenuOptionsDocument, baseOptions);
      }
export type UpdateOrderItemWithSetMenuOptionsMutationHookResult = ReturnType<typeof useUpdateOrderItemWithSetMenuOptionsMutation>;
export type UpdateOrderItemWithSetMenuOptionsMutationResult = ApolloReactCommon.MutationResult<UpdateOrderItemWithSetMenuOptionsMutation>;
export type UpdateOrderItemWithSetMenuOptionsMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateOrderItemWithSetMenuOptionsMutation, UpdateOrderItemWithSetMenuOptionsMutationVariables>;
export const ConfirmOrderItemDocument = gql`
    mutation confirmOrderItem($orderItemIDs: [String!]!, $orderID: String!) {
  confirmOrderItem(orderItemIDs: $orderItemIDs, orderID: $orderID)
}
    `;
export type ConfirmOrderItemMutationFn = ApolloReactCommon.MutationFunction<ConfirmOrderItemMutation, ConfirmOrderItemMutationVariables>;

/**
 * __useConfirmOrderItemMutation__
 *
 * To run a mutation, you first call `useConfirmOrderItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmOrderItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmOrderItemMutation, { data, loading, error }] = useConfirmOrderItemMutation({
 *   variables: {
 *      orderItemIDs: // value for 'orderItemIDs'
 *      orderID: // value for 'orderID'
 *   },
 * });
 */
export function useConfirmOrderItemMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ConfirmOrderItemMutation, ConfirmOrderItemMutationVariables>) {
        return ApolloReactHooks.useMutation<ConfirmOrderItemMutation, ConfirmOrderItemMutationVariables>(ConfirmOrderItemDocument, baseOptions);
      }
export type ConfirmOrderItemMutationHookResult = ReturnType<typeof useConfirmOrderItemMutation>;
export type ConfirmOrderItemMutationResult = ApolloReactCommon.MutationResult<ConfirmOrderItemMutation>;
export type ConfirmOrderItemMutationOptions = ApolloReactCommon.BaseMutationOptions<ConfirmOrderItemMutation, ConfirmOrderItemMutationVariables>;
export const RefreshOrderItemDocument = gql`
    subscription refreshOrderItem($outletID: String!) {
  refreshOrderItem(outletID: $outletID) {
    ID
    outletID
    name
    description
  }
}
    `;

/**
 * __useRefreshOrderItemSubscription__
 *
 * To run a query within a React component, call `useRefreshOrderItemSubscription` and pass it any options that fit your needs.
 * When your component renders, `useRefreshOrderItemSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRefreshOrderItemSubscription({
 *   variables: {
 *      outletID: // value for 'outletID'
 *   },
 * });
 */
export function useRefreshOrderItemSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<RefreshOrderItemSubscription, RefreshOrderItemSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<RefreshOrderItemSubscription, RefreshOrderItemSubscriptionVariables>(RefreshOrderItemDocument, baseOptions);
      }
export type RefreshOrderItemSubscriptionHookResult = ReturnType<typeof useRefreshOrderItemSubscription>;
export type RefreshOrderItemSubscriptionResult = ApolloReactCommon.SubscriptionResult<RefreshOrderItemSubscription>;
export const PostPrintDataDocument = gql`
    mutation postPrintData($printData: PrintDataPayload!) {
  postPrintData(printData: $printData)
}
    `;
export type PostPrintDataMutationFn = ApolloReactCommon.MutationFunction<PostPrintDataMutation, PostPrintDataMutationVariables>;

/**
 * __usePostPrintDataMutation__
 *
 * To run a mutation, you first call `usePostPrintDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePostPrintDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [postPrintDataMutation, { data, loading, error }] = usePostPrintDataMutation({
 *   variables: {
 *      printData: // value for 'printData'
 *   },
 * });
 */
export function usePostPrintDataMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PostPrintDataMutation, PostPrintDataMutationVariables>) {
        return ApolloReactHooks.useMutation<PostPrintDataMutation, PostPrintDataMutationVariables>(PostPrintDataDocument, baseOptions);
      }
export type PostPrintDataMutationHookResult = ReturnType<typeof usePostPrintDataMutation>;
export type PostPrintDataMutationResult = ApolloReactCommon.MutationResult<PostPrintDataMutation>;
export type PostPrintDataMutationOptions = ApolloReactCommon.BaseMutationOptions<PostPrintDataMutation, PostPrintDataMutationVariables>;
export const GetPrintDataDocument = gql`
    subscription getPrintData($outletID: String!) {
  getPrintData(outletID: $outletID) {
    printerName
    outletName
    orderID
    orderItemIDs
    orderMode
    voidQuantity
    reasonCode
    remark
  }
}
    `;

/**
 * __useGetPrintDataSubscription__
 *
 * To run a query within a React component, call `useGetPrintDataSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetPrintDataSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPrintDataSubscription({
 *   variables: {
 *      outletID: // value for 'outletID'
 *   },
 * });
 */
export function useGetPrintDataSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<GetPrintDataSubscription, GetPrintDataSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<GetPrintDataSubscription, GetPrintDataSubscriptionVariables>(GetPrintDataDocument, baseOptions);
      }
export type GetPrintDataSubscriptionHookResult = ReturnType<typeof useGetPrintDataSubscription>;
export type GetPrintDataSubscriptionResult = ApolloReactCommon.SubscriptionResult<GetPrintDataSubscription>;
export const CreateOrderDocument = gql`
    mutation createOrder($input: OrderInput!) {
  createOrder(input: $input) {
    ID
  }
}
    `;
export type CreateOrderMutationFn = ApolloReactCommon.MutationFunction<CreateOrderMutation, CreateOrderMutationVariables>;

/**
 * __useCreateOrderMutation__
 *
 * To run a mutation, you first call `useCreateOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrderMutation, { data, loading, error }] = useCreateOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOrderMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateOrderMutation, CreateOrderMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateOrderMutation, CreateOrderMutationVariables>(CreateOrderDocument, baseOptions);
      }
export type CreateOrderMutationHookResult = ReturnType<typeof useCreateOrderMutation>;
export type CreateOrderMutationResult = ApolloReactCommon.MutationResult<CreateOrderMutation>;
export type CreateOrderMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateOrderMutation, CreateOrderMutationVariables>;
export const UpdateOrderDocument = gql`
    mutation updateOrder($input: OrderInput!) {
  updateOrder(input: $input)
}
    `;
export type UpdateOrderMutationFn = ApolloReactCommon.MutationFunction<UpdateOrderMutation, UpdateOrderMutationVariables>;

/**
 * __useUpdateOrderMutation__
 *
 * To run a mutation, you first call `useUpdateOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrderMutation, { data, loading, error }] = useUpdateOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOrderMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateOrderMutation, UpdateOrderMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateOrderMutation, UpdateOrderMutationVariables>(UpdateOrderDocument, baseOptions);
      }
export type UpdateOrderMutationHookResult = ReturnType<typeof useUpdateOrderMutation>;
export type UpdateOrderMutationResult = ApolloReactCommon.MutationResult<UpdateOrderMutation>;
export type UpdateOrderMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateOrderMutation, UpdateOrderMutationVariables>;
export const GetPrinterOrderItemDocument = gql`
    query getPrinterOrderItem($orderID: String!, $orderItemIDs: [String!]!) {
  getPrinterOrderItem(orderID: $orderID, orderItemIDs: $orderItemIDs) {
    ID
    quantity
    specialRequest
    isTakeaway
    menuItem {
      ID
      name
      kitchenName
      kitchenPrinterID
      isPerforated
      perforatedQuantity
      kitchenPrinter {
        ID
        name
      }
    }
    orderItemOption {
      ID
      orderItemOptionItem {
        ID
        optionItem {
          ID
          name
        }
      }
    }
    orderItemSetMenuOption {
      ID
      orderItemSetMenuOptionItem {
        ID
        menuItem {
          ID
          name
          kitchenName
          isPerforated
          perforatedQuantity
        }
      }
    }
  }
}
    `;

/**
 * __useGetPrinterOrderItemQuery__
 *
 * To run a query within a React component, call `useGetPrinterOrderItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPrinterOrderItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPrinterOrderItemQuery({
 *   variables: {
 *      orderID: // value for 'orderID'
 *      orderItemIDs: // value for 'orderItemIDs'
 *   },
 * });
 */
export function useGetPrinterOrderItemQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPrinterOrderItemQuery, GetPrinterOrderItemQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPrinterOrderItemQuery, GetPrinterOrderItemQueryVariables>(GetPrinterOrderItemDocument, baseOptions);
      }
export function useGetPrinterOrderItemLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPrinterOrderItemQuery, GetPrinterOrderItemQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPrinterOrderItemQuery, GetPrinterOrderItemQueryVariables>(GetPrinterOrderItemDocument, baseOptions);
        }
export type GetPrinterOrderItemQueryHookResult = ReturnType<typeof useGetPrinterOrderItemQuery>;
export type GetPrinterOrderItemLazyQueryHookResult = ReturnType<typeof useGetPrinterOrderItemLazyQuery>;
export type GetPrinterOrderItemQueryResult = ApolloReactCommon.QueryResult<GetPrinterOrderItemQuery, GetPrinterOrderItemQueryVariables>;
export const GetOrderSummaryDocument = gql`
    query getOrderSummary($orderID: String, $menuItemID: String, $status: OrderItemStatus, $ID: String) {
  getOrderItem(orderID: $orderID, menuItemID: $menuItemID, status: $status, ID: $ID) {
    ID
    quantity
    orderID
    menuItemID
    billID
    specialRequest
    unitPrice
    taxAmount
    serviceCharge
    amount
    baseAmount
    isTakeaway
    status
    weight
    openItemName
    totalInfo
    orderItemDiscount {
      amount
      status
      reasonCode
      remark
    }
    menuItem {
      name
      isSetMenu
      isByWeight
      isOpenItem
      isOpenPrice
      description
      attachments
      uomID
      kitchenPrinterID
      kitchenPrinter2ID
      dailyAvailability {
        menuItemID
        ID
        outstandingQty
      }
      kitchenPrinter {
        ID
        name
        description
      }
      menuItemOptions {
        ID
        optionID
        maxSelections
        optionID
        menuItemOptionItem {
          ID
          menuItemOptionID
          optionItemID
          price
        }
        option {
          ID
          name
          optionItem {
            name
            ID
          }
        }
      }
      setMenuOption {
        ID
        name
        maxSelections
        setMenuOptionItem {
          ID
          quantity
          menuItemID
          menuItem {
            ID
            name
          }
        }
      }
    }
    orderItemOption {
      optionID
      orderItemOptionItem {
        ID
        optionItemID
        price
        basePrice
        unitPrice
        taxAmount
        serviceCharge
        optionItem {
          name
        }
      }
    }
    orderItemSetMenuOption {
      setMenuOptionID
      orderItemSetMenuOptionItem {
        setMenuOptionItemID
        quantity
        setMenuOptionItem {
          ID
          quantity
          menuItem {
            ID
            name
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetOrderSummaryQuery__
 *
 * To run a query within a React component, call `useGetOrderSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrderSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrderSummaryQuery({
 *   variables: {
 *      orderID: // value for 'orderID'
 *      menuItemID: // value for 'menuItemID'
 *      status: // value for 'status'
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useGetOrderSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetOrderSummaryQuery, GetOrderSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetOrderSummaryQuery, GetOrderSummaryQueryVariables>(GetOrderSummaryDocument, baseOptions);
      }
export function useGetOrderSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetOrderSummaryQuery, GetOrderSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetOrderSummaryQuery, GetOrderSummaryQueryVariables>(GetOrderSummaryDocument, baseOptions);
        }
export type GetOrderSummaryQueryHookResult = ReturnType<typeof useGetOrderSummaryQuery>;
export type GetOrderSummaryLazyQueryHookResult = ReturnType<typeof useGetOrderSummaryLazyQuery>;
export type GetOrderSummaryQueryResult = ApolloReactCommon.QueryResult<GetOrderSummaryQuery, GetOrderSummaryQueryVariables>;
export const GetAllOrderItemDocument = gql`
    query getAllOrderItem($orderID: String!) {
  getAllOrderItem(orderID: $orderID) {
    ID
    modBy
    createdBy
    quantity
    orderID
    menuItemID
    billID
    specialRequest
    unitPrice
    taxAmount
    serviceCharge
    amount
    baseAmount
    isTakeaway
    reasonCode
    refID
    status
    weight
    openItemName
    orderDiscount
    totalInfo
    unitPriceInfo
    orderItemTax {
      ID
      orderItemID
      taxSchemeID
      taxSchemeDetailID
      taxID
      taxRate
      taxAmount
      taxSchemeType
    }
    orderItemDiscountTax {
      ID
      orderItemID
      orderItemDiscountID
      taxSchemeID
      taxSchemeDetailID
      taxID
      taxRate
      taxAmount
    }
    orderDiscountItemTax {
      ID
      orderItemID
      orderDiscountItemID
      taxSchemeID
      taxSchemeDetailID
      taxID
      taxRate
      taxAmount
    }
    orderItemDiscount {
      orderItemID
      amount
      baseAmount
      serviceCharge
      taxAmount
      status
      reasonCode
      remark
      orderItemDiscountTax {
        ID
        orderItemID
        orderItemDiscountID
        taxRate
        taxAmount
      }
    }
    orderDiscountItem {
      orderItemID
      amount
      baseAmount
      serviceCharge
      taxAmount
      orderDiscountItemTax {
        ID
        orderItemID
        orderDiscountItemID
        taxSchemeID
        taxSchemeDetailID
        taxID
        taxRate
        taxAmount
      }
    }
    menuItem {
      name
      isSetMenu
      isByWeight
      isOpenItem
      isOpenPrice
      isDiscountable
      description
      attachments
      uomID
      kitchenPrinter {
        ID
        name
      }
      menuItemOptions {
        ID
        optionID
        maxSelections
        optionID
        menuItemOptionItem {
          ID
          menuItemOptionID
          optionItemID
          price
        }
        option {
          ID
          name
          optionItem {
            name
            ID
          }
        }
      }
      setMenuOption {
        ID
        name
        maxSelections
        setMenuOptionItem {
          ID
          quantity
          menuItemID
          menuItem {
            ID
            name
          }
        }
      }
    }
    orderItemOption {
      optionID
      orderItemOptionItem {
        optionItemID
        price
        basePrice
        unitPrice
        taxAmount
        serviceCharge
        optionItem {
          name
        }
        orderItemOptionItemTax {
          ID
          orderItemID
          orderItemOptionItemID
          taxSchemeID
          taxSchemeDetailID
          taxID
          taxRate
          taxAmount
        }
        orderItemOptionItemDiscountTax {
          ID
          orderItemOptionItemID
          orderItemDiscountID
          taxSchemeID
          taxSchemeDetailID
          taxID
          taxRate
          taxAmount
        }
        orderDiscountItemOptionTax {
          ID
          orderItemOptionItemID
          orderDiscountItemID
          taxSchemeID
          taxSchemeDetailID
          taxID
          taxRate
          taxAmount
        }
        discountAmount
        discountBaseAmount
        discountTaxAmount
        discountServiceCharge
      }
    }
    orderItemSetMenuOption {
      setMenuOptionID
      orderItemSetMenuOptionItem {
        setMenuOptionItemID
        quantity
        setMenuOptionItem {
          ID
          quantity
          menuItem {
            ID
            name
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetAllOrderItemQuery__
 *
 * To run a query within a React component, call `useGetAllOrderItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllOrderItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllOrderItemQuery({
 *   variables: {
 *      orderID: // value for 'orderID'
 *   },
 * });
 */
export function useGetAllOrderItemQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAllOrderItemQuery, GetAllOrderItemQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAllOrderItemQuery, GetAllOrderItemQueryVariables>(GetAllOrderItemDocument, baseOptions);
      }
export function useGetAllOrderItemLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAllOrderItemQuery, GetAllOrderItemQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAllOrderItemQuery, GetAllOrderItemQueryVariables>(GetAllOrderItemDocument, baseOptions);
        }
export type GetAllOrderItemQueryHookResult = ReturnType<typeof useGetAllOrderItemQuery>;
export type GetAllOrderItemLazyQueryHookResult = ReturnType<typeof useGetAllOrderItemLazyQuery>;
export type GetAllOrderItemQueryResult = ApolloReactCommon.QueryResult<GetAllOrderItemQuery, GetAllOrderItemQueryVariables>;
export const GetBillSettlementOrderItemDocument = gql`
    query getBillSettlementOrderItem($orderID: String!) {
  getBillSettlementOrderItem(orderID: $orderID) {
    ID
    quantity
    orderID
    menuItemID
    billID
    specialRequest
    unitPrice
    taxAmount
    serviceCharge
    amount
    baseAmount
    isTakeaway
    refID
    status
    weight
    openItemName
    orderDiscount
    totalInfo
    orderItemTax {
      ID
      orderItemID
      taxSchemeID
      taxSchemeDetailID
      taxID
      taxRate
      taxAmount
      taxSchemeType
    }
    orderItemDiscountTax {
      ID
      orderItemID
      orderItemDiscountID
      taxSchemeID
      taxSchemeDetailID
      taxID
      taxRate
      taxAmount
    }
    orderDiscountItemTax {
      ID
      orderItemID
      orderDiscountItemID
      taxSchemeID
      taxSchemeDetailID
      taxID
      taxRate
      taxAmount
    }
    orderItemDiscount {
      orderItemID
      amount
      baseAmount
      serviceCharge
      discountID
      taxAmount
      status
      reasonCode
      remark
      orderItemDiscountTax {
        ID
        orderItemID
        orderItemDiscountID
        taxSchemeID
        taxSchemeDetailID
        taxID
        taxRate
        taxAmount
      }
    }
    orderDiscountItem {
      orderItemID
      amount
      baseAmount
      serviceCharge
      taxAmount
      orderDiscountItemTax {
        ID
        orderItemID
        orderDiscountItemID
        taxSchemeID
        taxSchemeDetailID
        taxID
        taxRate
        taxAmount
      }
    }
    menuItem {
      name
      isSetMenu
      isByWeight
      isOpenItem
      isOpenPrice
      isDiscountable
      description
      attachments
      uomID
      menuItemOptions {
        ID
        optionID
        maxSelections
        optionID
        menuItemOptionItem {
          ID
          menuItemOptionID
          optionItemID
          price
        }
        option {
          ID
          name
          optionItem {
            name
            ID
          }
        }
      }
      setMenuOption {
        ID
        name
        maxSelections
        setMenuOptionItem {
          ID
          quantity
          menuItemID
          menuItem {
            ID
            name
          }
        }
      }
    }
    orderItemOption {
      optionID
      orderItemOptionItem {
        optionItemID
        price
        basePrice
        unitPrice
        taxAmount
        serviceCharge
        discountBaseAmount
        discountServiceCharge
        discountTaxAmount
        orderItemOptionItemTax {
          ID
          orderItemID
          orderItemOptionItemID
          taxSchemeID
          taxSchemeDetailID
          taxID
          taxRate
          taxAmount
        }
        orderItemOptionItemDiscountTax {
          ID
          orderItemOptionItemID
          orderItemDiscountID
          taxSchemeID
          taxSchemeDetailID
          taxID
          taxRate
          taxAmount
        }
        orderDiscountItemOptionTax {
          ID
          orderItemOptionItemID
          orderDiscountItemID
          taxSchemeID
          taxSchemeDetailID
          taxID
          taxRate
          taxAmount
        }
        optionItem {
          name
        }
      }
    }
    orderItemSetMenuOption {
      setMenuOptionID
      orderItemSetMenuOptionItem {
        setMenuOptionItemID
        quantity
        setMenuOptionItem {
          ID
          quantity
          menuItem {
            ID
            name
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetBillSettlementOrderItemQuery__
 *
 * To run a query within a React component, call `useGetBillSettlementOrderItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBillSettlementOrderItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBillSettlementOrderItemQuery({
 *   variables: {
 *      orderID: // value for 'orderID'
 *   },
 * });
 */
export function useGetBillSettlementOrderItemQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBillSettlementOrderItemQuery, GetBillSettlementOrderItemQueryVariables>) {
        return ApolloReactHooks.useQuery<GetBillSettlementOrderItemQuery, GetBillSettlementOrderItemQueryVariables>(GetBillSettlementOrderItemDocument, baseOptions);
      }
export function useGetBillSettlementOrderItemLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBillSettlementOrderItemQuery, GetBillSettlementOrderItemQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetBillSettlementOrderItemQuery, GetBillSettlementOrderItemQueryVariables>(GetBillSettlementOrderItemDocument, baseOptions);
        }
export type GetBillSettlementOrderItemQueryHookResult = ReturnType<typeof useGetBillSettlementOrderItemQuery>;
export type GetBillSettlementOrderItemLazyQueryHookResult = ReturnType<typeof useGetBillSettlementOrderItemLazyQuery>;
export type GetBillSettlementOrderItemQueryResult = ApolloReactCommon.QueryResult<GetBillSettlementOrderItemQuery, GetBillSettlementOrderItemQueryVariables>;
export const GetOrderTotalDocument = gql`
    query getOrderTotal($outletID: String!, $ID: String, $tableID: String, $status: OrderStatus) {
  getOrder(outletID: $outletID, ID: $ID, tableID: $tableID, status: $status) {
    outstandingAmount
  }
}
    `;

/**
 * __useGetOrderTotalQuery__
 *
 * To run a query within a React component, call `useGetOrderTotalQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrderTotalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrderTotalQuery({
 *   variables: {
 *      outletID: // value for 'outletID'
 *      ID: // value for 'ID'
 *      tableID: // value for 'tableID'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useGetOrderTotalQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetOrderTotalQuery, GetOrderTotalQueryVariables>) {
        return ApolloReactHooks.useQuery<GetOrderTotalQuery, GetOrderTotalQueryVariables>(GetOrderTotalDocument, baseOptions);
      }
export function useGetOrderTotalLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetOrderTotalQuery, GetOrderTotalQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetOrderTotalQuery, GetOrderTotalQueryVariables>(GetOrderTotalDocument, baseOptions);
        }
export type GetOrderTotalQueryHookResult = ReturnType<typeof useGetOrderTotalQuery>;
export type GetOrderTotalLazyQueryHookResult = ReturnType<typeof useGetOrderTotalLazyQuery>;
export type GetOrderTotalQueryResult = ApolloReactCommon.QueryResult<GetOrderTotalQuery, GetOrderTotalQueryVariables>;
export const GetOrderDocument = gql`
    query getOrder($outletID: String!, $ID: String, $tableID: String, $status: OrderStatus) {
  getOrder(outletID: $outletID, ID: $ID, tableID: $tableID, status: $status) {
    ID
    outletID
    tableID
    createdBy
    modBy
    qrID
    docNo
    serviceMode
    openedByID
    servedByIDs
    paidByID
    serviceMode
    businessDate
    pax
    taxSchemeInfo
    taxSchemeFooterInfo
    mealPeriodID
    patronID
    createdTs
    totalAmount
    outstandingAmount
    patronAccountID
    registrationID
    status
    patronName
    contactNo
    pickupTime
    orderDiscount {
      ID
      amount
      status
      reasonCode
      discountID
      remark
      discountID
      discountTypeInfo
    }
    mealPeriod {
      ID
      name
      startTime
      endTime
    }
    orderItem {
      ID
      name
      orderID
      amount
      serviceCharge
      taxAmount
      specialRequest
      quantity
      status
      kitchenStatus
      isTakeaway
      weight
      menuItemID
      openItemName
      menuItem {
        name
        description
        attachments
      }
      orderItemOption {
        optionID
        name
        orderItemOptionItem {
          optionItemID
          price
          unitPrice
          serviceCharge
          taxAmount
          optionItem {
            name
          }
        }
      }
      orderItemSetMenuOption {
        setMenuOptionID
        name
        orderItemSetMenuOptionItem {
          setMenuOptionItemID
          setMenuOptionItem {
            ID
            menuItem {
              ID
              name
            }
          }
        }
      }
      orderItemDiscount {
        ID
        amount
        status
        reasonCode
        remark
      }
      billID
      refID
    }
    bill {
      ID
      createdTs
      billNo
      businessDate
    }
    docNo
    table {
      ID
      name
      seatCount
      prefix
      tableNumber
      status
      orderInfo {
        totalAmount
      }
    }
    patron {
      patronClass
      patronType {
        name
      }
    }
    qr
    totalDiscountAmount
  }
}
    `;

/**
 * __useGetOrderQuery__
 *
 * To run a query within a React component, call `useGetOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrderQuery({
 *   variables: {
 *      outletID: // value for 'outletID'
 *      ID: // value for 'ID'
 *      tableID: // value for 'tableID'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useGetOrderQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetOrderQuery, GetOrderQueryVariables>) {
        return ApolloReactHooks.useQuery<GetOrderQuery, GetOrderQueryVariables>(GetOrderDocument, baseOptions);
      }
export function useGetOrderLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetOrderQuery, GetOrderQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetOrderQuery, GetOrderQueryVariables>(GetOrderDocument, baseOptions);
        }
export type GetOrderQueryHookResult = ReturnType<typeof useGetOrderQuery>;
export type GetOrderLazyQueryHookResult = ReturnType<typeof useGetOrderLazyQuery>;
export type GetOrderQueryResult = ApolloReactCommon.QueryResult<GetOrderQuery, GetOrderQueryVariables>;
export const UpdateKitchenItemStatusDocument = gql`
    mutation updateKitchenItemStatus($InputItemID: String!) {
  updateKitchenStatus(InputItemID: $InputItemID)
}
    `;
export type UpdateKitchenItemStatusMutationFn = ApolloReactCommon.MutationFunction<UpdateKitchenItemStatusMutation, UpdateKitchenItemStatusMutationVariables>;

/**
 * __useUpdateKitchenItemStatusMutation__
 *
 * To run a mutation, you first call `useUpdateKitchenItemStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateKitchenItemStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateKitchenItemStatusMutation, { data, loading, error }] = useUpdateKitchenItemStatusMutation({
 *   variables: {
 *      InputItemID: // value for 'InputItemID'
 *   },
 * });
 */
export function useUpdateKitchenItemStatusMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateKitchenItemStatusMutation, UpdateKitchenItemStatusMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateKitchenItemStatusMutation, UpdateKitchenItemStatusMutationVariables>(UpdateKitchenItemStatusDocument, baseOptions);
      }
export type UpdateKitchenItemStatusMutationHookResult = ReturnType<typeof useUpdateKitchenItemStatusMutation>;
export type UpdateKitchenItemStatusMutationResult = ApolloReactCommon.MutationResult<UpdateKitchenItemStatusMutation>;
export type UpdateKitchenItemStatusMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateKitchenItemStatusMutation, UpdateKitchenItemStatusMutationVariables>;
export const RevertKitchenItemStatusDocument = gql`
    mutation revertKitchenItemStatus($InputItemID: String!) {
  revertKitchenStatus(InputItemID: $InputItemID)
}
    `;
export type RevertKitchenItemStatusMutationFn = ApolloReactCommon.MutationFunction<RevertKitchenItemStatusMutation, RevertKitchenItemStatusMutationVariables>;

/**
 * __useRevertKitchenItemStatusMutation__
 *
 * To run a mutation, you first call `useRevertKitchenItemStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevertKitchenItemStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revertKitchenItemStatusMutation, { data, loading, error }] = useRevertKitchenItemStatusMutation({
 *   variables: {
 *      InputItemID: // value for 'InputItemID'
 *   },
 * });
 */
export function useRevertKitchenItemStatusMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RevertKitchenItemStatusMutation, RevertKitchenItemStatusMutationVariables>) {
        return ApolloReactHooks.useMutation<RevertKitchenItemStatusMutation, RevertKitchenItemStatusMutationVariables>(RevertKitchenItemStatusDocument, baseOptions);
      }
export type RevertKitchenItemStatusMutationHookResult = ReturnType<typeof useRevertKitchenItemStatusMutation>;
export type RevertKitchenItemStatusMutationResult = ApolloReactCommon.MutationResult<RevertKitchenItemStatusMutation>;
export type RevertKitchenItemStatusMutationOptions = ApolloReactCommon.BaseMutationOptions<RevertKitchenItemStatusMutation, RevertKitchenItemStatusMutationVariables>;
export const ConfirmKitchenItemStatusDocument = gql`
    mutation confirmKitchenItemStatus($InputItemID: [String!]!) {
  confirmKitchenStatus(InputItemID: $InputItemID)
}
    `;
export type ConfirmKitchenItemStatusMutationFn = ApolloReactCommon.MutationFunction<ConfirmKitchenItemStatusMutation, ConfirmKitchenItemStatusMutationVariables>;

/**
 * __useConfirmKitchenItemStatusMutation__
 *
 * To run a mutation, you first call `useConfirmKitchenItemStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmKitchenItemStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmKitchenItemStatusMutation, { data, loading, error }] = useConfirmKitchenItemStatusMutation({
 *   variables: {
 *      InputItemID: // value for 'InputItemID'
 *   },
 * });
 */
export function useConfirmKitchenItemStatusMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ConfirmKitchenItemStatusMutation, ConfirmKitchenItemStatusMutationVariables>) {
        return ApolloReactHooks.useMutation<ConfirmKitchenItemStatusMutation, ConfirmKitchenItemStatusMutationVariables>(ConfirmKitchenItemStatusDocument, baseOptions);
      }
export type ConfirmKitchenItemStatusMutationHookResult = ReturnType<typeof useConfirmKitchenItemStatusMutation>;
export type ConfirmKitchenItemStatusMutationResult = ApolloReactCommon.MutationResult<ConfirmKitchenItemStatusMutation>;
export type ConfirmKitchenItemStatusMutationOptions = ApolloReactCommon.BaseMutationOptions<ConfirmKitchenItemStatusMutation, ConfirmKitchenItemStatusMutationVariables>;
export const VoidOrderItemWithOptionsDocument = gql`
    mutation voidOrderItemWithOptions($input: VoidOrderItemInput!, $file: String) {
  voidOrderItemWithOptions(voidOrderItemInput: $input, file: $file)
}
    `;
export type VoidOrderItemWithOptionsMutationFn = ApolloReactCommon.MutationFunction<VoidOrderItemWithOptionsMutation, VoidOrderItemWithOptionsMutationVariables>;

/**
 * __useVoidOrderItemWithOptionsMutation__
 *
 * To run a mutation, you first call `useVoidOrderItemWithOptionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVoidOrderItemWithOptionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [voidOrderItemWithOptionsMutation, { data, loading, error }] = useVoidOrderItemWithOptionsMutation({
 *   variables: {
 *      input: // value for 'input'
 *      file: // value for 'file'
 *   },
 * });
 */
export function useVoidOrderItemWithOptionsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<VoidOrderItemWithOptionsMutation, VoidOrderItemWithOptionsMutationVariables>) {
        return ApolloReactHooks.useMutation<VoidOrderItemWithOptionsMutation, VoidOrderItemWithOptionsMutationVariables>(VoidOrderItemWithOptionsDocument, baseOptions);
      }
export type VoidOrderItemWithOptionsMutationHookResult = ReturnType<typeof useVoidOrderItemWithOptionsMutation>;
export type VoidOrderItemWithOptionsMutationResult = ApolloReactCommon.MutationResult<VoidOrderItemWithOptionsMutation>;
export type VoidOrderItemWithOptionsMutationOptions = ApolloReactCommon.BaseMutationOptions<VoidOrderItemWithOptionsMutation, VoidOrderItemWithOptionsMutationVariables>;
export const VoidOrderItemWithSetMenuOptionsDocument = gql`
    mutation voidOrderItemWithSetMenuOptions($input: VoidOrderItemInput!) {
  voidOrderItemWithSetMenuOptions(voidOrderItemInput: $input)
}
    `;
export type VoidOrderItemWithSetMenuOptionsMutationFn = ApolloReactCommon.MutationFunction<VoidOrderItemWithSetMenuOptionsMutation, VoidOrderItemWithSetMenuOptionsMutationVariables>;

/**
 * __useVoidOrderItemWithSetMenuOptionsMutation__
 *
 * To run a mutation, you first call `useVoidOrderItemWithSetMenuOptionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVoidOrderItemWithSetMenuOptionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [voidOrderItemWithSetMenuOptionsMutation, { data, loading, error }] = useVoidOrderItemWithSetMenuOptionsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVoidOrderItemWithSetMenuOptionsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<VoidOrderItemWithSetMenuOptionsMutation, VoidOrderItemWithSetMenuOptionsMutationVariables>) {
        return ApolloReactHooks.useMutation<VoidOrderItemWithSetMenuOptionsMutation, VoidOrderItemWithSetMenuOptionsMutationVariables>(VoidOrderItemWithSetMenuOptionsDocument, baseOptions);
      }
export type VoidOrderItemWithSetMenuOptionsMutationHookResult = ReturnType<typeof useVoidOrderItemWithSetMenuOptionsMutation>;
export type VoidOrderItemWithSetMenuOptionsMutationResult = ApolloReactCommon.MutationResult<VoidOrderItemWithSetMenuOptionsMutation>;
export type VoidOrderItemWithSetMenuOptionsMutationOptions = ApolloReactCommon.BaseMutationOptions<VoidOrderItemWithSetMenuOptionsMutation, VoidOrderItemWithSetMenuOptionsMutationVariables>;
export const GetScheduledDailyMenuCategoryDocument = gql`
    query getScheduledDailyMenuCategory($outletID: String, $mealPeriodID: String, $ID: String) {
  getOutletDailyMenuCategory(outletID: $outletID, mealPeriodID: $mealPeriodID, ID: $ID) {
    ID
    name
    priority
    modTs
    createdTs
    outletID
    mealPeriodID
    mealPeriodID
    digitalMenuCategoryID
    outletDailyMenuItem {
      ID
      name
      description
      menuItemID
      attachments
      isOpenItem
      isOpenPrice
      isByWeight
      isSetMenu
      isChefRecommended
      uomID
      promoChecking
      outletDailyMenuCategoryID
      outletDailyPromoMenuItem {
        ID
        promoMenuItemID
        startTime
        endTime
        outletDailyPromoMenuItemPrice {
          ID
          price
          name
          description
          patronID
        }
      }
      menuItem {
        ID
        isDiscountable
        dailyAvailability {
          menuItemID
          ID
          outstandingQty
        }
        stopSales {
          ID
          isActive
          menuItemID
        }
        menuItemOptions {
          maxSelections
          menuItemID
          optionID
          menuItemOptionItem {
            optionItemID
            menuItemOptionID
            price
            optionItem {
              ID
              optionID
              name
            }
          }
          option {
            ID
            name
            optionItem {
              ID
              optionID
              name
            }
          }
        }
        setMenuOption {
          ID
          name
          maxSelections
          setMenuOptionItem {
            ID
            quantity
            menuItemID
            menuItem {
              ID
              name
              dailyAvailability {
                outstandingQty
              }
            }
          }
        }
        dailyAvailability {
          ID
          outstandingQty
        }
      }
      outletDailyMenuItemPatronPrice {
        ID
        outletID
        price
        name
        patronID
        description
        patronClass
        outletDailyMenuItemID
      }
    }
  }
}
    `;

/**
 * __useGetScheduledDailyMenuCategoryQuery__
 *
 * To run a query within a React component, call `useGetScheduledDailyMenuCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetScheduledDailyMenuCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetScheduledDailyMenuCategoryQuery({
 *   variables: {
 *      outletID: // value for 'outletID'
 *      mealPeriodID: // value for 'mealPeriodID'
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useGetScheduledDailyMenuCategoryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetScheduledDailyMenuCategoryQuery, GetScheduledDailyMenuCategoryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetScheduledDailyMenuCategoryQuery, GetScheduledDailyMenuCategoryQueryVariables>(GetScheduledDailyMenuCategoryDocument, baseOptions);
      }
export function useGetScheduledDailyMenuCategoryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetScheduledDailyMenuCategoryQuery, GetScheduledDailyMenuCategoryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetScheduledDailyMenuCategoryQuery, GetScheduledDailyMenuCategoryQueryVariables>(GetScheduledDailyMenuCategoryDocument, baseOptions);
        }
export type GetScheduledDailyMenuCategoryQueryHookResult = ReturnType<typeof useGetScheduledDailyMenuCategoryQuery>;
export type GetScheduledDailyMenuCategoryLazyQueryHookResult = ReturnType<typeof useGetScheduledDailyMenuCategoryLazyQuery>;
export type GetScheduledDailyMenuCategoryQueryResult = ApolloReactCommon.QueryResult<GetScheduledDailyMenuCategoryQuery, GetScheduledDailyMenuCategoryQueryVariables>;
export const GetDailyMenuCategoryDocument = gql`
    query getDailyMenuCategory($outletID: String, $mealPeriodID: String, $ID: String) {
  getOutletDailyMenuCategory(outletID: $outletID, mealPeriodID: $mealPeriodID, ID: $ID) {
    ID
    name
    outletID
    mealPeriodID
    mealPeriodID
    priority
    digitalMenuCategoryID
    outletDailyMenuItem {
      ID
      outletDailyMenuCategoryID
      name
      menuItem {
        ID
        stopSales {
          ID
        }
      }
      outletDailyMenuItemPatronPrice {
        patronID
        price
      }
      outletDailyPromoMenuItem {
        ID
        startTime
        endTime
        outletDailyPromoMenuItemPrice {
          ID
          price
          patronID
        }
      }
    }
  }
}
    `;

/**
 * __useGetDailyMenuCategoryQuery__
 *
 * To run a query within a React component, call `useGetDailyMenuCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDailyMenuCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDailyMenuCategoryQuery({
 *   variables: {
 *      outletID: // value for 'outletID'
 *      mealPeriodID: // value for 'mealPeriodID'
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useGetDailyMenuCategoryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDailyMenuCategoryQuery, GetDailyMenuCategoryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetDailyMenuCategoryQuery, GetDailyMenuCategoryQueryVariables>(GetDailyMenuCategoryDocument, baseOptions);
      }
export function useGetDailyMenuCategoryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDailyMenuCategoryQuery, GetDailyMenuCategoryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetDailyMenuCategoryQuery, GetDailyMenuCategoryQueryVariables>(GetDailyMenuCategoryDocument, baseOptions);
        }
export type GetDailyMenuCategoryQueryHookResult = ReturnType<typeof useGetDailyMenuCategoryQuery>;
export type GetDailyMenuCategoryLazyQueryHookResult = ReturnType<typeof useGetDailyMenuCategoryLazyQuery>;
export type GetDailyMenuCategoryQueryResult = ApolloReactCommon.QueryResult<GetDailyMenuCategoryQuery, GetDailyMenuCategoryQueryVariables>;
export const GetDailyMenuItemDocument = gql`
    query getDailyMenuItem($outletDailyMenuCategoryID: String) {
  getOutletDailyMenuItem(outletDailyMenuCategoryID: $outletDailyMenuCategoryID) {
    ID
    name
    description
    defaultQuantity
    menuItemID
    attachments
    outletDailyMenuItemOption {
      ID
      outletDailyMenuItemID
      maxSelections
      name
      optionID
      outletDailyMenuItemOptionItem {
        ID
        outletDailyMenuItemOptionID
        name
        price
        optionItemID
      }
    }
  }
}
    `;

/**
 * __useGetDailyMenuItemQuery__
 *
 * To run a query within a React component, call `useGetDailyMenuItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDailyMenuItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDailyMenuItemQuery({
 *   variables: {
 *      outletDailyMenuCategoryID: // value for 'outletDailyMenuCategoryID'
 *   },
 * });
 */
export function useGetDailyMenuItemQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDailyMenuItemQuery, GetDailyMenuItemQueryVariables>) {
        return ApolloReactHooks.useQuery<GetDailyMenuItemQuery, GetDailyMenuItemQueryVariables>(GetDailyMenuItemDocument, baseOptions);
      }
export function useGetDailyMenuItemLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDailyMenuItemQuery, GetDailyMenuItemQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetDailyMenuItemQuery, GetDailyMenuItemQueryVariables>(GetDailyMenuItemDocument, baseOptions);
        }
export type GetDailyMenuItemQueryHookResult = ReturnType<typeof useGetDailyMenuItemQuery>;
export type GetDailyMenuItemLazyQueryHookResult = ReturnType<typeof useGetDailyMenuItemLazyQuery>;
export type GetDailyMenuItemQueryResult = ApolloReactCommon.QueryResult<GetDailyMenuItemQuery, GetDailyMenuItemQueryVariables>;
export const GetDailyPatronPriceDocument = gql`
    query getDailyPatronPrice($outletID: String) {
  getOutletDailyMenuItemPatronPrice(outletID: $outletID) {
    ID
    outletID
    price
    name
    description
    patronClass
    outletDailyMenuItemID
  }
}
    `;

/**
 * __useGetDailyPatronPriceQuery__
 *
 * To run a query within a React component, call `useGetDailyPatronPriceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDailyPatronPriceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDailyPatronPriceQuery({
 *   variables: {
 *      outletID: // value for 'outletID'
 *   },
 * });
 */
export function useGetDailyPatronPriceQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDailyPatronPriceQuery, GetDailyPatronPriceQueryVariables>) {
        return ApolloReactHooks.useQuery<GetDailyPatronPriceQuery, GetDailyPatronPriceQueryVariables>(GetDailyPatronPriceDocument, baseOptions);
      }
export function useGetDailyPatronPriceLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDailyPatronPriceQuery, GetDailyPatronPriceQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetDailyPatronPriceQuery, GetDailyPatronPriceQueryVariables>(GetDailyPatronPriceDocument, baseOptions);
        }
export type GetDailyPatronPriceQueryHookResult = ReturnType<typeof useGetDailyPatronPriceQuery>;
export type GetDailyPatronPriceLazyQueryHookResult = ReturnType<typeof useGetDailyPatronPriceLazyQuery>;
export type GetDailyPatronPriceQueryResult = ApolloReactCommon.QueryResult<GetDailyPatronPriceQuery, GetDailyPatronPriceQueryVariables>;
export const CreateOrderItemPatronAppDocument = gql`
    mutation createOrderItemPatronApp($orderInput: OrderItemInput!, $optionItemInput: [OrderItemOptionInput!]) {
  createOrderItemPatronApp(orderInput: $orderInput, optionItemInput: $optionItemInput)
}
    `;
export type CreateOrderItemPatronAppMutationFn = ApolloReactCommon.MutationFunction<CreateOrderItemPatronAppMutation, CreateOrderItemPatronAppMutationVariables>;

/**
 * __useCreateOrderItemPatronAppMutation__
 *
 * To run a mutation, you first call `useCreateOrderItemPatronAppMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrderItemPatronAppMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrderItemPatronAppMutation, { data, loading, error }] = useCreateOrderItemPatronAppMutation({
 *   variables: {
 *      orderInput: // value for 'orderInput'
 *      optionItemInput: // value for 'optionItemInput'
 *   },
 * });
 */
export function useCreateOrderItemPatronAppMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateOrderItemPatronAppMutation, CreateOrderItemPatronAppMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateOrderItemPatronAppMutation, CreateOrderItemPatronAppMutationVariables>(CreateOrderItemPatronAppDocument, baseOptions);
      }
export type CreateOrderItemPatronAppMutationHookResult = ReturnType<typeof useCreateOrderItemPatronAppMutation>;
export type CreateOrderItemPatronAppMutationResult = ApolloReactCommon.MutationResult<CreateOrderItemPatronAppMutation>;
export type CreateOrderItemPatronAppMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateOrderItemPatronAppMutation, CreateOrderItemPatronAppMutationVariables>;
export const GetOutletVisitorPatronDocument = gql`
    query getOutletVisitorPatron($outletID: String!) {
  getOutletVisitorPatron(outletID: $outletID) {
    ID
  }
}
    `;

/**
 * __useGetOutletVisitorPatronQuery__
 *
 * To run a query within a React component, call `useGetOutletVisitorPatronQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOutletVisitorPatronQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOutletVisitorPatronQuery({
 *   variables: {
 *      outletID: // value for 'outletID'
 *   },
 * });
 */
export function useGetOutletVisitorPatronQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetOutletVisitorPatronQuery, GetOutletVisitorPatronQueryVariables>) {
        return ApolloReactHooks.useQuery<GetOutletVisitorPatronQuery, GetOutletVisitorPatronQueryVariables>(GetOutletVisitorPatronDocument, baseOptions);
      }
export function useGetOutletVisitorPatronLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetOutletVisitorPatronQuery, GetOutletVisitorPatronQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetOutletVisitorPatronQuery, GetOutletVisitorPatronQueryVariables>(GetOutletVisitorPatronDocument, baseOptions);
        }
export type GetOutletVisitorPatronQueryHookResult = ReturnType<typeof useGetOutletVisitorPatronQuery>;
export type GetOutletVisitorPatronLazyQueryHookResult = ReturnType<typeof useGetOutletVisitorPatronLazyQuery>;
export type GetOutletVisitorPatronQueryResult = ApolloReactCommon.QueryResult<GetOutletVisitorPatronQuery, GetOutletVisitorPatronQueryVariables>;
export const AuditLogDetailsDocument = gql`
    query auditLogDetails($fieldID: String!, $tableName: String!, $offset: Float, $limit: Float) {
  auditLogDetails(fieldID: $fieldID, tableName: $tableName, offset: $offset, limit: $limit)
}
    `;

/**
 * __useAuditLogDetailsQuery__
 *
 * To run a query within a React component, call `useAuditLogDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuditLogDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuditLogDetailsQuery({
 *   variables: {
 *      fieldID: // value for 'fieldID'
 *      tableName: // value for 'tableName'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useAuditLogDetailsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AuditLogDetailsQuery, AuditLogDetailsQueryVariables>) {
        return ApolloReactHooks.useQuery<AuditLogDetailsQuery, AuditLogDetailsQueryVariables>(AuditLogDetailsDocument, baseOptions);
      }
export function useAuditLogDetailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AuditLogDetailsQuery, AuditLogDetailsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AuditLogDetailsQuery, AuditLogDetailsQueryVariables>(AuditLogDetailsDocument, baseOptions);
        }
export type AuditLogDetailsQueryHookResult = ReturnType<typeof useAuditLogDetailsQuery>;
export type AuditLogDetailsLazyQueryHookResult = ReturnType<typeof useAuditLogDetailsLazyQuery>;
export type AuditLogDetailsQueryResult = ApolloReactCommon.QueryResult<AuditLogDetailsQuery, AuditLogDetailsQueryVariables>;
export const GetOrderItemDiscountDocument = gql`
    query getOrderItemDiscount($ID: String, $orderItemID: String) {
  getOrderItemDiscount(ID: $ID, orderItemID: $orderItemID) {
    ID
    discountID
    orderItemID
    amount
    status
    reasonCode
    remark
  }
}
    `;

/**
 * __useGetOrderItemDiscountQuery__
 *
 * To run a query within a React component, call `useGetOrderItemDiscountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrderItemDiscountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrderItemDiscountQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      orderItemID: // value for 'orderItemID'
 *   },
 * });
 */
export function useGetOrderItemDiscountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetOrderItemDiscountQuery, GetOrderItemDiscountQueryVariables>) {
        return ApolloReactHooks.useQuery<GetOrderItemDiscountQuery, GetOrderItemDiscountQueryVariables>(GetOrderItemDiscountDocument, baseOptions);
      }
export function useGetOrderItemDiscountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetOrderItemDiscountQuery, GetOrderItemDiscountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetOrderItemDiscountQuery, GetOrderItemDiscountQueryVariables>(GetOrderItemDiscountDocument, baseOptions);
        }
export type GetOrderItemDiscountQueryHookResult = ReturnType<typeof useGetOrderItemDiscountQuery>;
export type GetOrderItemDiscountLazyQueryHookResult = ReturnType<typeof useGetOrderItemDiscountLazyQuery>;
export type GetOrderItemDiscountQueryResult = ApolloReactCommon.QueryResult<GetOrderItemDiscountQuery, GetOrderItemDiscountQueryVariables>;
export const CreateOrderItemDiscountDocument = gql`
    mutation createOrderItemDiscount($input: OrderItemDiscountInput!, $orderID: String!) {
  createOrderItemDiscount(input: $input, orderID: $orderID)
}
    `;
export type CreateOrderItemDiscountMutationFn = ApolloReactCommon.MutationFunction<CreateOrderItemDiscountMutation, CreateOrderItemDiscountMutationVariables>;

/**
 * __useCreateOrderItemDiscountMutation__
 *
 * To run a mutation, you first call `useCreateOrderItemDiscountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrderItemDiscountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrderItemDiscountMutation, { data, loading, error }] = useCreateOrderItemDiscountMutation({
 *   variables: {
 *      input: // value for 'input'
 *      orderID: // value for 'orderID'
 *   },
 * });
 */
export function useCreateOrderItemDiscountMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateOrderItemDiscountMutation, CreateOrderItemDiscountMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateOrderItemDiscountMutation, CreateOrderItemDiscountMutationVariables>(CreateOrderItemDiscountDocument, baseOptions);
      }
export type CreateOrderItemDiscountMutationHookResult = ReturnType<typeof useCreateOrderItemDiscountMutation>;
export type CreateOrderItemDiscountMutationResult = ApolloReactCommon.MutationResult<CreateOrderItemDiscountMutation>;
export type CreateOrderItemDiscountMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateOrderItemDiscountMutation, CreateOrderItemDiscountMutationVariables>;
export const DeleteOrderItemDiscountDocument = gql`
    mutation deleteOrderItemDiscount($input: OrderItemDiscountInput!, $orderID: String!) {
  deleteOrderItemDiscount(input: $input, orderID: $orderID)
}
    `;
export type DeleteOrderItemDiscountMutationFn = ApolloReactCommon.MutationFunction<DeleteOrderItemDiscountMutation, DeleteOrderItemDiscountMutationVariables>;

/**
 * __useDeleteOrderItemDiscountMutation__
 *
 * To run a mutation, you first call `useDeleteOrderItemDiscountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOrderItemDiscountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOrderItemDiscountMutation, { data, loading, error }] = useDeleteOrderItemDiscountMutation({
 *   variables: {
 *      input: // value for 'input'
 *      orderID: // value for 'orderID'
 *   },
 * });
 */
export function useDeleteOrderItemDiscountMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteOrderItemDiscountMutation, DeleteOrderItemDiscountMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteOrderItemDiscountMutation, DeleteOrderItemDiscountMutationVariables>(DeleteOrderItemDiscountDocument, baseOptions);
      }
export type DeleteOrderItemDiscountMutationHookResult = ReturnType<typeof useDeleteOrderItemDiscountMutation>;
export type DeleteOrderItemDiscountMutationResult = ApolloReactCommon.MutationResult<DeleteOrderItemDiscountMutation>;
export type DeleteOrderItemDiscountMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteOrderItemDiscountMutation, DeleteOrderItemDiscountMutationVariables>;
export const GetOrderDiscountDocument = gql`
    query getOrderDiscount($ID: String, $orderID: String) {
  getOrderDiscount(ID: $ID, orderID: $orderID) {
    ID
    discountedBy
    amount
    isActive
    discountID
    status
    orderID
    reasonCode
    remark
  }
}
    `;

/**
 * __useGetOrderDiscountQuery__
 *
 * To run a query within a React component, call `useGetOrderDiscountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrderDiscountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrderDiscountQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      orderID: // value for 'orderID'
 *   },
 * });
 */
export function useGetOrderDiscountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetOrderDiscountQuery, GetOrderDiscountQueryVariables>) {
        return ApolloReactHooks.useQuery<GetOrderDiscountQuery, GetOrderDiscountQueryVariables>(GetOrderDiscountDocument, baseOptions);
      }
export function useGetOrderDiscountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetOrderDiscountQuery, GetOrderDiscountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetOrderDiscountQuery, GetOrderDiscountQueryVariables>(GetOrderDiscountDocument, baseOptions);
        }
export type GetOrderDiscountQueryHookResult = ReturnType<typeof useGetOrderDiscountQuery>;
export type GetOrderDiscountLazyQueryHookResult = ReturnType<typeof useGetOrderDiscountLazyQuery>;
export type GetOrderDiscountQueryResult = ApolloReactCommon.QueryResult<GetOrderDiscountQuery, GetOrderDiscountQueryVariables>;
export const GetBillDiscountDocument = gql`
    query getBillDiscount($ID: String) {
  getBillDiscount(ID: $ID) {
    ID
    discountID
    isActive
    amount
    businessDate
  }
}
    `;

/**
 * __useGetBillDiscountQuery__
 *
 * To run a query within a React component, call `useGetBillDiscountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBillDiscountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBillDiscountQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useGetBillDiscountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBillDiscountQuery, GetBillDiscountQueryVariables>) {
        return ApolloReactHooks.useQuery<GetBillDiscountQuery, GetBillDiscountQueryVariables>(GetBillDiscountDocument, baseOptions);
      }
export function useGetBillDiscountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBillDiscountQuery, GetBillDiscountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetBillDiscountQuery, GetBillDiscountQueryVariables>(GetBillDiscountDocument, baseOptions);
        }
export type GetBillDiscountQueryHookResult = ReturnType<typeof useGetBillDiscountQuery>;
export type GetBillDiscountLazyQueryHookResult = ReturnType<typeof useGetBillDiscountLazyQuery>;
export type GetBillDiscountQueryResult = ApolloReactCommon.QueryResult<GetBillDiscountQuery, GetBillDiscountQueryVariables>;
export const CreateOrderDiscountDocument = gql`
    mutation createOrderDiscount($input: OrderDiscountInput!) {
  createOrderDiscount(input: $input)
}
    `;
export type CreateOrderDiscountMutationFn = ApolloReactCommon.MutationFunction<CreateOrderDiscountMutation, CreateOrderDiscountMutationVariables>;

/**
 * __useCreateOrderDiscountMutation__
 *
 * To run a mutation, you first call `useCreateOrderDiscountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrderDiscountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrderDiscountMutation, { data, loading, error }] = useCreateOrderDiscountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOrderDiscountMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateOrderDiscountMutation, CreateOrderDiscountMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateOrderDiscountMutation, CreateOrderDiscountMutationVariables>(CreateOrderDiscountDocument, baseOptions);
      }
export type CreateOrderDiscountMutationHookResult = ReturnType<typeof useCreateOrderDiscountMutation>;
export type CreateOrderDiscountMutationResult = ApolloReactCommon.MutationResult<CreateOrderDiscountMutation>;
export type CreateOrderDiscountMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateOrderDiscountMutation, CreateOrderDiscountMutationVariables>;
export const DeleteOrderDiscountDocument = gql`
    mutation deleteOrderDiscount($input: OrderDiscountInput!) {
  deleteOrderDiscount(input: $input)
}
    `;
export type DeleteOrderDiscountMutationFn = ApolloReactCommon.MutationFunction<DeleteOrderDiscountMutation, DeleteOrderDiscountMutationVariables>;

/**
 * __useDeleteOrderDiscountMutation__
 *
 * To run a mutation, you first call `useDeleteOrderDiscountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOrderDiscountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOrderDiscountMutation, { data, loading, error }] = useDeleteOrderDiscountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteOrderDiscountMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteOrderDiscountMutation, DeleteOrderDiscountMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteOrderDiscountMutation, DeleteOrderDiscountMutationVariables>(DeleteOrderDiscountDocument, baseOptions);
      }
export type DeleteOrderDiscountMutationHookResult = ReturnType<typeof useDeleteOrderDiscountMutation>;
export type DeleteOrderDiscountMutationResult = ApolloReactCommon.MutationResult<DeleteOrderDiscountMutation>;
export type DeleteOrderDiscountMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteOrderDiscountMutation, DeleteOrderDiscountMutationVariables>;
export const GetBillDiscountOrderItemDocument = gql`
    query getBillDiscountOrderItem($outletID: String!, $ID: String, $tableID: String, $status: OrderStatus) {
  getOrder(outletID: $outletID, ID: $ID, tableID: $tableID, status: $status) {
    ID
    docNo
    outletID
    tableID
    mealPeriodID
    patronID
    totalAmount
    outstandingAmount
    patronAccountID
    contactNo
    pickupTime
    serviceMode
    status
    bill {
      ID
      billNo
      businessDate
    }
    table {
      ID
      name
      seatCount
      prefix
      tableNumber
      status
    }
    orderDiscount {
      ID
      discountID
      orderID
      amount
      reasonCode
      remark
    }
  }
}
    `;

/**
 * __useGetBillDiscountOrderItemQuery__
 *
 * To run a query within a React component, call `useGetBillDiscountOrderItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBillDiscountOrderItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBillDiscountOrderItemQuery({
 *   variables: {
 *      outletID: // value for 'outletID'
 *      ID: // value for 'ID'
 *      tableID: // value for 'tableID'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useGetBillDiscountOrderItemQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBillDiscountOrderItemQuery, GetBillDiscountOrderItemQueryVariables>) {
        return ApolloReactHooks.useQuery<GetBillDiscountOrderItemQuery, GetBillDiscountOrderItemQueryVariables>(GetBillDiscountOrderItemDocument, baseOptions);
      }
export function useGetBillDiscountOrderItemLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBillDiscountOrderItemQuery, GetBillDiscountOrderItemQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetBillDiscountOrderItemQuery, GetBillDiscountOrderItemQueryVariables>(GetBillDiscountOrderItemDocument, baseOptions);
        }
export type GetBillDiscountOrderItemQueryHookResult = ReturnType<typeof useGetBillDiscountOrderItemQuery>;
export type GetBillDiscountOrderItemLazyQueryHookResult = ReturnType<typeof useGetBillDiscountOrderItemLazyQuery>;
export type GetBillDiscountOrderItemQueryResult = ApolloReactCommon.QueryResult<GetBillDiscountOrderItemQuery, GetBillDiscountOrderItemQueryVariables>;
export const GetFilteredDiscountDocument = gql`
    query getFilteredDiscount($outletID: String!, $amount: Float!, $isOrderItemDiscount: Boolean!) {
  getFilteredDiscount(outletID: $outletID, amount: $amount, isOrderItemDiscount: $isOrderItemDiscount) {
    ID
    code
    amount
    calcType
    description
  }
}
    `;

/**
 * __useGetFilteredDiscountQuery__
 *
 * To run a query within a React component, call `useGetFilteredDiscountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFilteredDiscountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFilteredDiscountQuery({
 *   variables: {
 *      outletID: // value for 'outletID'
 *      amount: // value for 'amount'
 *      isOrderItemDiscount: // value for 'isOrderItemDiscount'
 *   },
 * });
 */
export function useGetFilteredDiscountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetFilteredDiscountQuery, GetFilteredDiscountQueryVariables>) {
        return ApolloReactHooks.useQuery<GetFilteredDiscountQuery, GetFilteredDiscountQueryVariables>(GetFilteredDiscountDocument, baseOptions);
      }
export function useGetFilteredDiscountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetFilteredDiscountQuery, GetFilteredDiscountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetFilteredDiscountQuery, GetFilteredDiscountQueryVariables>(GetFilteredDiscountDocument, baseOptions);
        }
export type GetFilteredDiscountQueryHookResult = ReturnType<typeof useGetFilteredDiscountQuery>;
export type GetFilteredDiscountLazyQueryHookResult = ReturnType<typeof useGetFilteredDiscountLazyQuery>;
export type GetFilteredDiscountQueryResult = ApolloReactCommon.QueryResult<GetFilteredDiscountQuery, GetFilteredDiscountQueryVariables>;
export const GetVoidedDiscountDocument = gql`
    query getVoidedDiscount($orderID: String!, $outletID: String!) {
  getVoidedDiscount(orderID: $orderID, outletID: $outletID)
}
    `;

/**
 * __useGetVoidedDiscountQuery__
 *
 * To run a query within a React component, call `useGetVoidedDiscountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVoidedDiscountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVoidedDiscountQuery({
 *   variables: {
 *      orderID: // value for 'orderID'
 *      outletID: // value for 'outletID'
 *   },
 * });
 */
export function useGetVoidedDiscountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetVoidedDiscountQuery, GetVoidedDiscountQueryVariables>) {
        return ApolloReactHooks.useQuery<GetVoidedDiscountQuery, GetVoidedDiscountQueryVariables>(GetVoidedDiscountDocument, baseOptions);
      }
export function useGetVoidedDiscountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetVoidedDiscountQuery, GetVoidedDiscountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetVoidedDiscountQuery, GetVoidedDiscountQueryVariables>(GetVoidedDiscountDocument, baseOptions);
        }
export type GetVoidedDiscountQueryHookResult = ReturnType<typeof useGetVoidedDiscountQuery>;
export type GetVoidedDiscountLazyQueryHookResult = ReturnType<typeof useGetVoidedDiscountLazyQuery>;
export type GetVoidedDiscountQueryResult = ApolloReactCommon.QueryResult<GetVoidedDiscountQuery, GetVoidedDiscountQueryVariables>;
export const CreateBillAndPaymentDocument = gql`
    mutation createBillAndPayment($orderItemIDs: [String!]!, $paymentInput: PaymentInput!, $billInput: BillInput!, $isTakeaway: Boolean) {
  createBillAndPayment(orderItemIDs: $orderItemIDs, paymentInput: $paymentInput, billInput: $billInput, isTakeaway: $isTakeaway) {
    ID
  }
}
    `;
export type CreateBillAndPaymentMutationFn = ApolloReactCommon.MutationFunction<CreateBillAndPaymentMutation, CreateBillAndPaymentMutationVariables>;

/**
 * __useCreateBillAndPaymentMutation__
 *
 * To run a mutation, you first call `useCreateBillAndPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBillAndPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBillAndPaymentMutation, { data, loading, error }] = useCreateBillAndPaymentMutation({
 *   variables: {
 *      orderItemIDs: // value for 'orderItemIDs'
 *      paymentInput: // value for 'paymentInput'
 *      billInput: // value for 'billInput'
 *      isTakeaway: // value for 'isTakeaway'
 *   },
 * });
 */
export function useCreateBillAndPaymentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateBillAndPaymentMutation, CreateBillAndPaymentMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateBillAndPaymentMutation, CreateBillAndPaymentMutationVariables>(CreateBillAndPaymentDocument, baseOptions);
      }
export type CreateBillAndPaymentMutationHookResult = ReturnType<typeof useCreateBillAndPaymentMutation>;
export type CreateBillAndPaymentMutationResult = ApolloReactCommon.MutationResult<CreateBillAndPaymentMutation>;
export type CreateBillAndPaymentMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateBillAndPaymentMutation, CreateBillAndPaymentMutationVariables>;
export const GetBillDocument = gql`
    query getBill($ID: String, $outletID: String, $orderByDesc: String) {
  getBill(ID: $ID, outletID: $outletID, orderByDesc: $orderByDesc) {
    ID
    outletID
    createdBy
    modBy
    outlet {
      isInclusive
      name
      logo
      address
      contactNo
      currencyCode
      registrationNo
      taxSchemeID
      taxRegistrationNo
      outletChannel {
        ID
        hotelID
        name
      }
      company {
        id
        name
        description
        companyRegNo
        code
        contactNo
        email
        sstNo
        gstNo
        ttxNo
        gstExpiryDate
        eInvClientID
        eInvClientSecret
        eInvClientSecret2
        regionId
        companyTax
        recordStatus
        groupGstRegNo
        officeTel
        tin
        eSign
        eSignExpiryDate
        isEInvIntegrated
        eInvStartDate
        eInvEndDate
      }
    }
    createdTs
    billNo
    signature
    orderID
    status
    order {
      ID
      docNo
      patronAccountID
      pax
      taxSchemeInfo
      contactNo
      taxSchemeFooterInfo
      pax
      patronName
      patronID
      orderDiscount {
        ID
        discountID
        amount
        status
        reasonCode
        remark
      }
      table {
        ID
        tableNumber
        prefix
      }
      patron {
        patronClass
      }
      serviceMode
      orderItem {
        ID
        billID
      }
      totalDiscountAmount
    }
    payments {
      ID
      amount
      registrationID
      receivedAmount
      changeAmount
      roundingAmount
      businessDate
      cardNo
      reasonCode
      creditCardType
      paymentClass
      referenceNo
      patronAccountID
      hotelGuestInfo
      defaultPayment {
        ID
        paymentClass
        paymentTypeInfo
      }
      status
      remark
      createdTs
    }
    orderItem {
      ID
      amount
      refID
      serviceCharge
      taxAmount
      unitPrice
      isTakeaway
      weight
      orderItemTax {
        ID
        orderItemID
        taxSchemeID
        taxSchemeDetailID
        taxID
        taxRate
        taxAmount
        taxSchemeType
      }
      orderItemDiscountTax {
        ID
        orderItemID
        orderItemDiscountID
        taxSchemeID
        taxSchemeDetailID
        taxID
        taxRate
        taxAmount
      }
      orderDiscountItemTax {
        ID
        orderItemID
        orderDiscountItemID
        taxSchemeID
        taxSchemeDetailID
        taxID
        taxRate
        taxAmount
      }
      unitPriceInfo
      totalInfo
      openItemName
      quantity
      baseAmount
      status
      orderItemDiscount {
        discountID
        orderItemID
        amount
        baseAmount
        serviceCharge
        taxAmount
        status
        reasonCode
        remark
        orderItemDiscountTax {
          ID
          orderItemID
          orderItemDiscountID
          taxSchemeID
          taxSchemeDetailID
          taxID
          taxRate
          taxAmount
        }
      }
      orderDiscountItem {
        orderItemID
        amount
        baseAmount
        serviceCharge
        taxAmount
        orderDiscountItemTax {
          ID
          orderItemID
          orderDiscountItemID
          taxSchemeID
          taxSchemeDetailID
          taxID
          taxRate
          taxAmount
        }
      }
      menuItem {
        ID
        name
      }
      orderItemOption {
        ID
        name
        optionID
        orderItemID
        orderItemOptionItem {
          ID
          optionItemID
          orderItemOptionItemTax {
            ID
            orderItemID
            orderItemOptionItemID
            taxSchemeID
            taxSchemeDetailID
            taxID
            taxRate
            taxAmount
          }
          orderItemOptionItemDiscountTax {
            ID
            orderItemOptionItemID
            orderItemDiscountID
            taxSchemeID
            taxSchemeDetailID
            taxID
            taxRate
            taxAmount
          }
          orderDiscountItemOptionTax {
            ID
            orderItemOptionItemID
            orderDiscountItemID
            taxSchemeID
            taxSchemeDetailID
            taxID
            taxRate
            taxAmount
          }
          price
          basePrice
          unitPrice
          taxAmount
          serviceCharge
          discountBaseAmount
          discountServiceCharge
          discountTaxAmount
          optionItem {
            name
          }
        }
      }
      orderItemSetMenuOption {
        ID
        orderItemSetMenuOptionItem {
          menuItem {
            name
          }
          setMenuOptionItem {
            menuItem {
              name
            }
          }
        }
      }
    }
    billPaymentType
    einvoice_qr_url
    is_einvoice
    einvoice_irbm_uniqueID
    einvoice_status
    qrCodeUrl
    documentType
    refID
    documentType
    refBillNo
    isCreditNote
  }
}
    `;

/**
 * __useGetBillQuery__
 *
 * To run a query within a React component, call `useGetBillQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBillQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBillQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      outletID: // value for 'outletID'
 *      orderByDesc: // value for 'orderByDesc'
 *   },
 * });
 */
export function useGetBillQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBillQuery, GetBillQueryVariables>) {
        return ApolloReactHooks.useQuery<GetBillQuery, GetBillQueryVariables>(GetBillDocument, baseOptions);
      }
export function useGetBillLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBillQuery, GetBillQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetBillQuery, GetBillQueryVariables>(GetBillDocument, baseOptions);
        }
export type GetBillQueryHookResult = ReturnType<typeof useGetBillQuery>;
export type GetBillLazyQueryHookResult = ReturnType<typeof useGetBillLazyQuery>;
export type GetBillQueryResult = ApolloReactCommon.QueryResult<GetBillQuery, GetBillQueryVariables>;
export const CreateReceiptAttachmentDocument = gql`
    mutation createReceiptAttachment($billID: String!, $outletID: String!, $file: Upload!) {
  createReceiptAttachment(billID: $billID, outletID: $outletID, file: $file) {
    ID
    bucketFileName
    fileName
    fileURL
  }
}
    `;
export type CreateReceiptAttachmentMutationFn = ApolloReactCommon.MutationFunction<CreateReceiptAttachmentMutation, CreateReceiptAttachmentMutationVariables>;

/**
 * __useCreateReceiptAttachmentMutation__
 *
 * To run a mutation, you first call `useCreateReceiptAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReceiptAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReceiptAttachmentMutation, { data, loading, error }] = useCreateReceiptAttachmentMutation({
 *   variables: {
 *      billID: // value for 'billID'
 *      outletID: // value for 'outletID'
 *      file: // value for 'file'
 *   },
 * });
 */
export function useCreateReceiptAttachmentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateReceiptAttachmentMutation, CreateReceiptAttachmentMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateReceiptAttachmentMutation, CreateReceiptAttachmentMutationVariables>(CreateReceiptAttachmentDocument, baseOptions);
      }
export type CreateReceiptAttachmentMutationHookResult = ReturnType<typeof useCreateReceiptAttachmentMutation>;
export type CreateReceiptAttachmentMutationResult = ApolloReactCommon.MutationResult<CreateReceiptAttachmentMutation>;
export type CreateReceiptAttachmentMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateReceiptAttachmentMutation, CreateReceiptAttachmentMutationVariables>;
export const CreatePaymentByQuantityDocument = gql`
    mutation createPaymentByQuantity($paymentByQuantityInput: [PaymentByQuantityInput!]!, $billInput: BillInput!, $paymentInput: [PaymentInput!]!) {
  paymentByQuantity(paymentByQuantityInput: $paymentByQuantityInput, billInput: $billInput, paymentInput: $paymentInput) {
    ID
  }
}
    `;
export type CreatePaymentByQuantityMutationFn = ApolloReactCommon.MutationFunction<CreatePaymentByQuantityMutation, CreatePaymentByQuantityMutationVariables>;

/**
 * __useCreatePaymentByQuantityMutation__
 *
 * To run a mutation, you first call `useCreatePaymentByQuantityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePaymentByQuantityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPaymentByQuantityMutation, { data, loading, error }] = useCreatePaymentByQuantityMutation({
 *   variables: {
 *      paymentByQuantityInput: // value for 'paymentByQuantityInput'
 *      billInput: // value for 'billInput'
 *      paymentInput: // value for 'paymentInput'
 *   },
 * });
 */
export function useCreatePaymentByQuantityMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreatePaymentByQuantityMutation, CreatePaymentByQuantityMutationVariables>) {
        return ApolloReactHooks.useMutation<CreatePaymentByQuantityMutation, CreatePaymentByQuantityMutationVariables>(CreatePaymentByQuantityDocument, baseOptions);
      }
export type CreatePaymentByQuantityMutationHookResult = ReturnType<typeof useCreatePaymentByQuantityMutation>;
export type CreatePaymentByQuantityMutationResult = ApolloReactCommon.MutationResult<CreatePaymentByQuantityMutation>;
export type CreatePaymentByQuantityMutationOptions = ApolloReactCommon.BaseMutationOptions<CreatePaymentByQuantityMutation, CreatePaymentByQuantityMutationVariables>;
export const CreateOnlinePaymentDocument = gql`
    mutation createOnlinePayment($outletID: String!, $title: String!, $detail: String!, $payAmount: Float!, $redirectUrl: String!) {
  createOnlinePayment(outletID: $outletID, title: $title, detail: $detail, payAmount: $payAmount, redirectUrl: $redirectUrl) {
    item {
      checkoutId
      url
      qrCodeUrl
      status
    }
    code
    error {
      code
      message
      debug
    }
    paySessionNo
  }
}
    `;
export type CreateOnlinePaymentMutationFn = ApolloReactCommon.MutationFunction<CreateOnlinePaymentMutation, CreateOnlinePaymentMutationVariables>;

/**
 * __useCreateOnlinePaymentMutation__
 *
 * To run a mutation, you first call `useCreateOnlinePaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOnlinePaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOnlinePaymentMutation, { data, loading, error }] = useCreateOnlinePaymentMutation({
 *   variables: {
 *      outletID: // value for 'outletID'
 *      title: // value for 'title'
 *      detail: // value for 'detail'
 *      payAmount: // value for 'payAmount'
 *      redirectUrl: // value for 'redirectUrl'
 *   },
 * });
 */
export function useCreateOnlinePaymentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateOnlinePaymentMutation, CreateOnlinePaymentMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateOnlinePaymentMutation, CreateOnlinePaymentMutationVariables>(CreateOnlinePaymentDocument, baseOptions);
      }
export type CreateOnlinePaymentMutationHookResult = ReturnType<typeof useCreateOnlinePaymentMutation>;
export type CreateOnlinePaymentMutationResult = ApolloReactCommon.MutationResult<CreateOnlinePaymentMutation>;
export type CreateOnlinePaymentMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateOnlinePaymentMutation, CreateOnlinePaymentMutationVariables>;
export const GetPatronAccountDocument = gql`
    query getPatronAccount($isMember: Boolean) {
  getPatronAccount(isMember: $isMember) {
    ID
    name
    status
    creditBalance
    hotelRoomID
    hotelRoom {
      ID
      roomNo
    }
    membershipID
    membership {
      ID
      memberNo
      description
    }
  }
}
    `;

/**
 * __useGetPatronAccountQuery__
 *
 * To run a query within a React component, call `useGetPatronAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPatronAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPatronAccountQuery({
 *   variables: {
 *      isMember: // value for 'isMember'
 *   },
 * });
 */
export function useGetPatronAccountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPatronAccountQuery, GetPatronAccountQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPatronAccountQuery, GetPatronAccountQueryVariables>(GetPatronAccountDocument, baseOptions);
      }
export function useGetPatronAccountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPatronAccountQuery, GetPatronAccountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPatronAccountQuery, GetPatronAccountQueryVariables>(GetPatronAccountDocument, baseOptions);
        }
export type GetPatronAccountQueryHookResult = ReturnType<typeof useGetPatronAccountQuery>;
export type GetPatronAccountLazyQueryHookResult = ReturnType<typeof useGetPatronAccountLazyQuery>;
export type GetPatronAccountQueryResult = ApolloReactCommon.QueryResult<GetPatronAccountQuery, GetPatronAccountQueryVariables>;
export const GetMenuXInhouseListDocument = gql`
    query getMenuXInhouseList($hotelID: String!, $outletID: String) {
  getMenuXInhouseList(hotelID: $hotelID, outletID: $outletID)
}
    `;

/**
 * __useGetMenuXInhouseListQuery__
 *
 * To run a query within a React component, call `useGetMenuXInhouseListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMenuXInhouseListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMenuXInhouseListQuery({
 *   variables: {
 *      hotelID: // value for 'hotelID'
 *      outletID: // value for 'outletID'
 *   },
 * });
 */
export function useGetMenuXInhouseListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMenuXInhouseListQuery, GetMenuXInhouseListQueryVariables>) {
        return ApolloReactHooks.useQuery<GetMenuXInhouseListQuery, GetMenuXInhouseListQueryVariables>(GetMenuXInhouseListDocument, baseOptions);
      }
export function useGetMenuXInhouseListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMenuXInhouseListQuery, GetMenuXInhouseListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetMenuXInhouseListQuery, GetMenuXInhouseListQueryVariables>(GetMenuXInhouseListDocument, baseOptions);
        }
export type GetMenuXInhouseListQueryHookResult = ReturnType<typeof useGetMenuXInhouseListQuery>;
export type GetMenuXInhouseListLazyQueryHookResult = ReturnType<typeof useGetMenuXInhouseListLazyQuery>;
export type GetMenuXInhouseListQueryResult = ApolloReactCommon.QueryResult<GetMenuXInhouseListQuery, GetMenuXInhouseListQueryVariables>;
export const GetMenuXInhouseStatusDocument = gql`
    query getMenuXInhouseStatus($registrationID: String!, $outletID: String!) {
  getMenuXInhouseStatus(registrationID: $registrationID, outletID: $outletID)
}
    `;

/**
 * __useGetMenuXInhouseStatusQuery__
 *
 * To run a query within a React component, call `useGetMenuXInhouseStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMenuXInhouseStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMenuXInhouseStatusQuery({
 *   variables: {
 *      registrationID: // value for 'registrationID'
 *      outletID: // value for 'outletID'
 *   },
 * });
 */
export function useGetMenuXInhouseStatusQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMenuXInhouseStatusQuery, GetMenuXInhouseStatusQueryVariables>) {
        return ApolloReactHooks.useQuery<GetMenuXInhouseStatusQuery, GetMenuXInhouseStatusQueryVariables>(GetMenuXInhouseStatusDocument, baseOptions);
      }
export function useGetMenuXInhouseStatusLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMenuXInhouseStatusQuery, GetMenuXInhouseStatusQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetMenuXInhouseStatusQuery, GetMenuXInhouseStatusQueryVariables>(GetMenuXInhouseStatusDocument, baseOptions);
        }
export type GetMenuXInhouseStatusQueryHookResult = ReturnType<typeof useGetMenuXInhouseStatusQuery>;
export type GetMenuXInhouseStatusLazyQueryHookResult = ReturnType<typeof useGetMenuXInhouseStatusLazyQuery>;
export type GetMenuXInhouseStatusQueryResult = ApolloReactCommon.QueryResult<GetMenuXInhouseStatusQuery, GetMenuXInhouseStatusQueryVariables>;
export const GetMenuXDebtorListDocument = gql`
    query getMenuXDebtorList($hotelID: String!, $outletID: String) {
  getMenuXDebtorList(hotelID: $hotelID, outletID: $outletID)
}
    `;

/**
 * __useGetMenuXDebtorListQuery__
 *
 * To run a query within a React component, call `useGetMenuXDebtorListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMenuXDebtorListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMenuXDebtorListQuery({
 *   variables: {
 *      hotelID: // value for 'hotelID'
 *      outletID: // value for 'outletID'
 *   },
 * });
 */
export function useGetMenuXDebtorListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMenuXDebtorListQuery, GetMenuXDebtorListQueryVariables>) {
        return ApolloReactHooks.useQuery<GetMenuXDebtorListQuery, GetMenuXDebtorListQueryVariables>(GetMenuXDebtorListDocument, baseOptions);
      }
export function useGetMenuXDebtorListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMenuXDebtorListQuery, GetMenuXDebtorListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetMenuXDebtorListQuery, GetMenuXDebtorListQueryVariables>(GetMenuXDebtorListDocument, baseOptions);
        }
export type GetMenuXDebtorListQueryHookResult = ReturnType<typeof useGetMenuXDebtorListQuery>;
export type GetMenuXDebtorListLazyQueryHookResult = ReturnType<typeof useGetMenuXDebtorListLazyQuery>;
export type GetMenuXDebtorListQueryResult = ApolloReactCommon.QueryResult<GetMenuXDebtorListQuery, GetMenuXDebtorListQueryVariables>;
export const GetAttachmentDocument = gql`
    query getAttachment($ID: String, $name: String) {
  getAttachment(ID: $ID, name: $name) {
    ID
    fileName
    refID
    description
    fileURL
    bucketFileName
    fileSize
  }
}
    `;

/**
 * __useGetAttachmentQuery__
 *
 * To run a query within a React component, call `useGetAttachmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAttachmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAttachmentQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useGetAttachmentQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAttachmentQuery, GetAttachmentQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAttachmentQuery, GetAttachmentQueryVariables>(GetAttachmentDocument, baseOptions);
      }
export function useGetAttachmentLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAttachmentQuery, GetAttachmentQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAttachmentQuery, GetAttachmentQueryVariables>(GetAttachmentDocument, baseOptions);
        }
export type GetAttachmentQueryHookResult = ReturnType<typeof useGetAttachmentQuery>;
export type GetAttachmentLazyQueryHookResult = ReturnType<typeof useGetAttachmentLazyQuery>;
export type GetAttachmentQueryResult = ApolloReactCommon.QueryResult<GetAttachmentQuery, GetAttachmentQueryVariables>;
export const CreateAttachmentDocument = gql`
    mutation createAttachment($input: AttachmentInput!) {
  createAttachment(input: $input) {
    ID
  }
}
    `;
export type CreateAttachmentMutationFn = ApolloReactCommon.MutationFunction<CreateAttachmentMutation, CreateAttachmentMutationVariables>;

/**
 * __useCreateAttachmentMutation__
 *
 * To run a mutation, you first call `useCreateAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAttachmentMutation, { data, loading, error }] = useCreateAttachmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAttachmentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateAttachmentMutation, CreateAttachmentMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateAttachmentMutation, CreateAttachmentMutationVariables>(CreateAttachmentDocument, baseOptions);
      }
export type CreateAttachmentMutationHookResult = ReturnType<typeof useCreateAttachmentMutation>;
export type CreateAttachmentMutationResult = ApolloReactCommon.MutationResult<CreateAttachmentMutation>;
export type CreateAttachmentMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateAttachmentMutation, CreateAttachmentMutationVariables>;
export const UpdateAttachmentDocument = gql`
    mutation updateAttachment($input: AttachmentInput!) {
  updateAttachment(input: $input) {
    ID
  }
}
    `;
export type UpdateAttachmentMutationFn = ApolloReactCommon.MutationFunction<UpdateAttachmentMutation, UpdateAttachmentMutationVariables>;

/**
 * __useUpdateAttachmentMutation__
 *
 * To run a mutation, you first call `useUpdateAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAttachmentMutation, { data, loading, error }] = useUpdateAttachmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAttachmentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateAttachmentMutation, UpdateAttachmentMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateAttachmentMutation, UpdateAttachmentMutationVariables>(UpdateAttachmentDocument, baseOptions);
      }
export type UpdateAttachmentMutationHookResult = ReturnType<typeof useUpdateAttachmentMutation>;
export type UpdateAttachmentMutationResult = ApolloReactCommon.MutationResult<UpdateAttachmentMutation>;
export type UpdateAttachmentMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateAttachmentMutation, UpdateAttachmentMutationVariables>;
export const AttachmentListingDocument = gql`
    query attachmentListing($refID: String) {
  attachmentListing(refID: $refID) {
    ID
    fileName
    fileURL
    mediaType
    createdTs
    description
    refID
  }
}
    `;

/**
 * __useAttachmentListingQuery__
 *
 * To run a query within a React component, call `useAttachmentListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useAttachmentListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAttachmentListingQuery({
 *   variables: {
 *      refID: // value for 'refID'
 *   },
 * });
 */
export function useAttachmentListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AttachmentListingQuery, AttachmentListingQueryVariables>) {
        return ApolloReactHooks.useQuery<AttachmentListingQuery, AttachmentListingQueryVariables>(AttachmentListingDocument, baseOptions);
      }
export function useAttachmentListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AttachmentListingQuery, AttachmentListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AttachmentListingQuery, AttachmentListingQueryVariables>(AttachmentListingDocument, baseOptions);
        }
export type AttachmentListingQueryHookResult = ReturnType<typeof useAttachmentListingQuery>;
export type AttachmentListingLazyQueryHookResult = ReturnType<typeof useAttachmentListingLazyQuery>;
export type AttachmentListingQueryResult = ApolloReactCommon.QueryResult<AttachmentListingQuery, AttachmentListingQueryVariables>;
export const GetDailyMenuItemAvailabilityDocument = gql`
    query getDailyMenuItemAvailability($ID: String, $menuItemID: String, $outletID: String) {
  getDailyMenuItemAvailability(ID: $ID, menuItemID: $menuItemID, outletID: $outletID) {
    ID
    menuItemID
    quantity
    outstandingQty
    menuItem {
      name
      description
      defaultQuantity
    }
  }
}
    `;

/**
 * __useGetDailyMenuItemAvailabilityQuery__
 *
 * To run a query within a React component, call `useGetDailyMenuItemAvailabilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDailyMenuItemAvailabilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDailyMenuItemAvailabilityQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      menuItemID: // value for 'menuItemID'
 *      outletID: // value for 'outletID'
 *   },
 * });
 */
export function useGetDailyMenuItemAvailabilityQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDailyMenuItemAvailabilityQuery, GetDailyMenuItemAvailabilityQueryVariables>) {
        return ApolloReactHooks.useQuery<GetDailyMenuItemAvailabilityQuery, GetDailyMenuItemAvailabilityQueryVariables>(GetDailyMenuItemAvailabilityDocument, baseOptions);
      }
export function useGetDailyMenuItemAvailabilityLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDailyMenuItemAvailabilityQuery, GetDailyMenuItemAvailabilityQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetDailyMenuItemAvailabilityQuery, GetDailyMenuItemAvailabilityQueryVariables>(GetDailyMenuItemAvailabilityDocument, baseOptions);
        }
export type GetDailyMenuItemAvailabilityQueryHookResult = ReturnType<typeof useGetDailyMenuItemAvailabilityQuery>;
export type GetDailyMenuItemAvailabilityLazyQueryHookResult = ReturnType<typeof useGetDailyMenuItemAvailabilityLazyQuery>;
export type GetDailyMenuItemAvailabilityQueryResult = ApolloReactCommon.QueryResult<GetDailyMenuItemAvailabilityQuery, GetDailyMenuItemAvailabilityQueryVariables>;
export const CreateDailyMenuItemAvailabilityDocument = gql`
    mutation createDailyMenuItemAvailability($input: DailyMenuItemAvailabilityInput!) {
  createDailyMenuItemAvailability(input: $input) {
    ID
  }
}
    `;
export type CreateDailyMenuItemAvailabilityMutationFn = ApolloReactCommon.MutationFunction<CreateDailyMenuItemAvailabilityMutation, CreateDailyMenuItemAvailabilityMutationVariables>;

/**
 * __useCreateDailyMenuItemAvailabilityMutation__
 *
 * To run a mutation, you first call `useCreateDailyMenuItemAvailabilityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDailyMenuItemAvailabilityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDailyMenuItemAvailabilityMutation, { data, loading, error }] = useCreateDailyMenuItemAvailabilityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDailyMenuItemAvailabilityMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateDailyMenuItemAvailabilityMutation, CreateDailyMenuItemAvailabilityMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateDailyMenuItemAvailabilityMutation, CreateDailyMenuItemAvailabilityMutationVariables>(CreateDailyMenuItemAvailabilityDocument, baseOptions);
      }
export type CreateDailyMenuItemAvailabilityMutationHookResult = ReturnType<typeof useCreateDailyMenuItemAvailabilityMutation>;
export type CreateDailyMenuItemAvailabilityMutationResult = ApolloReactCommon.MutationResult<CreateDailyMenuItemAvailabilityMutation>;
export type CreateDailyMenuItemAvailabilityMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateDailyMenuItemAvailabilityMutation, CreateDailyMenuItemAvailabilityMutationVariables>;
export const UpdateDailyMenuItemAvailabilityDocument = gql`
    mutation updateDailyMenuItemAvailability($input: DailyMenuItemAvailabilityInput!) {
  updateDailyMenuItemAvailability(input: $input) {
    ID
  }
}
    `;
export type UpdateDailyMenuItemAvailabilityMutationFn = ApolloReactCommon.MutationFunction<UpdateDailyMenuItemAvailabilityMutation, UpdateDailyMenuItemAvailabilityMutationVariables>;

/**
 * __useUpdateDailyMenuItemAvailabilityMutation__
 *
 * To run a mutation, you first call `useUpdateDailyMenuItemAvailabilityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDailyMenuItemAvailabilityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDailyMenuItemAvailabilityMutation, { data, loading, error }] = useUpdateDailyMenuItemAvailabilityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDailyMenuItemAvailabilityMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateDailyMenuItemAvailabilityMutation, UpdateDailyMenuItemAvailabilityMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateDailyMenuItemAvailabilityMutation, UpdateDailyMenuItemAvailabilityMutationVariables>(UpdateDailyMenuItemAvailabilityDocument, baseOptions);
      }
export type UpdateDailyMenuItemAvailabilityMutationHookResult = ReturnType<typeof useUpdateDailyMenuItemAvailabilityMutation>;
export type UpdateDailyMenuItemAvailabilityMutationResult = ApolloReactCommon.MutationResult<UpdateDailyMenuItemAvailabilityMutation>;
export type UpdateDailyMenuItemAvailabilityMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateDailyMenuItemAvailabilityMutation, UpdateDailyMenuItemAvailabilityMutationVariables>;
export const SubmitDailyMenuItemAvailabilityDocument = gql`
    mutation submitDailyMenuItemAvailability($input: [DailyMenuItemAvailabilityInput!]!, $outletID: String!) {
  submitDailyMenuItemAvailability(input: $input, outletID: $outletID)
}
    `;
export type SubmitDailyMenuItemAvailabilityMutationFn = ApolloReactCommon.MutationFunction<SubmitDailyMenuItemAvailabilityMutation, SubmitDailyMenuItemAvailabilityMutationVariables>;

/**
 * __useSubmitDailyMenuItemAvailabilityMutation__
 *
 * To run a mutation, you first call `useSubmitDailyMenuItemAvailabilityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitDailyMenuItemAvailabilityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitDailyMenuItemAvailabilityMutation, { data, loading, error }] = useSubmitDailyMenuItemAvailabilityMutation({
 *   variables: {
 *      input: // value for 'input'
 *      outletID: // value for 'outletID'
 *   },
 * });
 */
export function useSubmitDailyMenuItemAvailabilityMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SubmitDailyMenuItemAvailabilityMutation, SubmitDailyMenuItemAvailabilityMutationVariables>) {
        return ApolloReactHooks.useMutation<SubmitDailyMenuItemAvailabilityMutation, SubmitDailyMenuItemAvailabilityMutationVariables>(SubmitDailyMenuItemAvailabilityDocument, baseOptions);
      }
export type SubmitDailyMenuItemAvailabilityMutationHookResult = ReturnType<typeof useSubmitDailyMenuItemAvailabilityMutation>;
export type SubmitDailyMenuItemAvailabilityMutationResult = ApolloReactCommon.MutationResult<SubmitDailyMenuItemAvailabilityMutation>;
export type SubmitDailyMenuItemAvailabilityMutationOptions = ApolloReactCommon.BaseMutationOptions<SubmitDailyMenuItemAvailabilityMutation, SubmitDailyMenuItemAvailabilityMutationVariables>;
export const GetDigitalMenuDocument = gql`
    query getDigitalMenu($ID: String, $name: String, $outletID: String) {
  getDigitalMenu(ID: $ID, name: $name, outletID: $outletID) {
    ID
    outletID
    name
    description
    modTs
    createdTs
  }
}
    `;

/**
 * __useGetDigitalMenuQuery__
 *
 * To run a query within a React component, call `useGetDigitalMenuQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDigitalMenuQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDigitalMenuQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      name: // value for 'name'
 *      outletID: // value for 'outletID'
 *   },
 * });
 */
export function useGetDigitalMenuQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDigitalMenuQuery, GetDigitalMenuQueryVariables>) {
        return ApolloReactHooks.useQuery<GetDigitalMenuQuery, GetDigitalMenuQueryVariables>(GetDigitalMenuDocument, baseOptions);
      }
export function useGetDigitalMenuLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDigitalMenuQuery, GetDigitalMenuQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetDigitalMenuQuery, GetDigitalMenuQueryVariables>(GetDigitalMenuDocument, baseOptions);
        }
export type GetDigitalMenuQueryHookResult = ReturnType<typeof useGetDigitalMenuQuery>;
export type GetDigitalMenuLazyQueryHookResult = ReturnType<typeof useGetDigitalMenuLazyQuery>;
export type GetDigitalMenuQueryResult = ApolloReactCommon.QueryResult<GetDigitalMenuQuery, GetDigitalMenuQueryVariables>;
export const GetOutletDigitalMenuDocument = gql`
    query getOutletDigitalMenu($ID: String, $name: String, $outletID: String) {
  getDigitalMenu(ID: $ID, name: $name, outletID: $outletID) {
    ID
    outletID
    name
    description
    modTs
    createdTs
  }
}
    `;

/**
 * __useGetOutletDigitalMenuQuery__
 *
 * To run a query within a React component, call `useGetOutletDigitalMenuQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOutletDigitalMenuQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOutletDigitalMenuQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      name: // value for 'name'
 *      outletID: // value for 'outletID'
 *   },
 * });
 */
export function useGetOutletDigitalMenuQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetOutletDigitalMenuQuery, GetOutletDigitalMenuQueryVariables>) {
        return ApolloReactHooks.useQuery<GetOutletDigitalMenuQuery, GetOutletDigitalMenuQueryVariables>(GetOutletDigitalMenuDocument, baseOptions);
      }
export function useGetOutletDigitalMenuLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetOutletDigitalMenuQuery, GetOutletDigitalMenuQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetOutletDigitalMenuQuery, GetOutletDigitalMenuQueryVariables>(GetOutletDigitalMenuDocument, baseOptions);
        }
export type GetOutletDigitalMenuQueryHookResult = ReturnType<typeof useGetOutletDigitalMenuQuery>;
export type GetOutletDigitalMenuLazyQueryHookResult = ReturnType<typeof useGetOutletDigitalMenuLazyQuery>;
export type GetOutletDigitalMenuQueryResult = ApolloReactCommon.QueryResult<GetOutletDigitalMenuQuery, GetOutletDigitalMenuQueryVariables>;
export const GetScheduledDigitalMenuDocument = gql`
    query getScheduledDigitalMenu($mealPeriodID: String!, $outletID: String!) {
  getScheduledDigitalMenu(mealPeriodID: $mealPeriodID, outletID: $outletID) {
    ID
    outletID
    name
    modTs
    createdTs
  }
}
    `;

/**
 * __useGetScheduledDigitalMenuQuery__
 *
 * To run a query within a React component, call `useGetScheduledDigitalMenuQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetScheduledDigitalMenuQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetScheduledDigitalMenuQuery({
 *   variables: {
 *      mealPeriodID: // value for 'mealPeriodID'
 *      outletID: // value for 'outletID'
 *   },
 * });
 */
export function useGetScheduledDigitalMenuQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetScheduledDigitalMenuQuery, GetScheduledDigitalMenuQueryVariables>) {
        return ApolloReactHooks.useQuery<GetScheduledDigitalMenuQuery, GetScheduledDigitalMenuQueryVariables>(GetScheduledDigitalMenuDocument, baseOptions);
      }
export function useGetScheduledDigitalMenuLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetScheduledDigitalMenuQuery, GetScheduledDigitalMenuQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetScheduledDigitalMenuQuery, GetScheduledDigitalMenuQueryVariables>(GetScheduledDigitalMenuDocument, baseOptions);
        }
export type GetScheduledDigitalMenuQueryHookResult = ReturnType<typeof useGetScheduledDigitalMenuQuery>;
export type GetScheduledDigitalMenuLazyQueryHookResult = ReturnType<typeof useGetScheduledDigitalMenuLazyQuery>;
export type GetScheduledDigitalMenuQueryResult = ApolloReactCommon.QueryResult<GetScheduledDigitalMenuQuery, GetScheduledDigitalMenuQueryVariables>;
export const GetScheduledDigitalMenuCategoryDocument = gql`
    query getScheduledDigitalMenuCategory($mealPeriodID: String!) {
  getScheduledDigitalMenuCategory(mealPeriodID: $mealPeriodID) {
    ID
    outletID
    digitalMenuID
    priority
    name
    modTs
    createdTs
    digitalMenuItem {
      ID
      menuItemID
      menuItem {
        ID
        name
        description
        attachments
      }
      digitalMenuItemPrice {
        price
      }
    }
  }
}
    `;

/**
 * __useGetScheduledDigitalMenuCategoryQuery__
 *
 * To run a query within a React component, call `useGetScheduledDigitalMenuCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetScheduledDigitalMenuCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetScheduledDigitalMenuCategoryQuery({
 *   variables: {
 *      mealPeriodID: // value for 'mealPeriodID'
 *   },
 * });
 */
export function useGetScheduledDigitalMenuCategoryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetScheduledDigitalMenuCategoryQuery, GetScheduledDigitalMenuCategoryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetScheduledDigitalMenuCategoryQuery, GetScheduledDigitalMenuCategoryQueryVariables>(GetScheduledDigitalMenuCategoryDocument, baseOptions);
      }
export function useGetScheduledDigitalMenuCategoryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetScheduledDigitalMenuCategoryQuery, GetScheduledDigitalMenuCategoryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetScheduledDigitalMenuCategoryQuery, GetScheduledDigitalMenuCategoryQueryVariables>(GetScheduledDigitalMenuCategoryDocument, baseOptions);
        }
export type GetScheduledDigitalMenuCategoryQueryHookResult = ReturnType<typeof useGetScheduledDigitalMenuCategoryQuery>;
export type GetScheduledDigitalMenuCategoryLazyQueryHookResult = ReturnType<typeof useGetScheduledDigitalMenuCategoryLazyQuery>;
export type GetScheduledDigitalMenuCategoryQueryResult = ApolloReactCommon.QueryResult<GetScheduledDigitalMenuCategoryQuery, GetScheduledDigitalMenuCategoryQueryVariables>;
export const CreateDigitalMenuDocument = gql`
    mutation createDigitalMenu($input: DigitalMenuInput!) {
  createDigitalMenu(input: $input) {
    ID
  }
}
    `;
export type CreateDigitalMenuMutationFn = ApolloReactCommon.MutationFunction<CreateDigitalMenuMutation, CreateDigitalMenuMutationVariables>;

/**
 * __useCreateDigitalMenuMutation__
 *
 * To run a mutation, you first call `useCreateDigitalMenuMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDigitalMenuMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDigitalMenuMutation, { data, loading, error }] = useCreateDigitalMenuMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDigitalMenuMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateDigitalMenuMutation, CreateDigitalMenuMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateDigitalMenuMutation, CreateDigitalMenuMutationVariables>(CreateDigitalMenuDocument, baseOptions);
      }
export type CreateDigitalMenuMutationHookResult = ReturnType<typeof useCreateDigitalMenuMutation>;
export type CreateDigitalMenuMutationResult = ApolloReactCommon.MutationResult<CreateDigitalMenuMutation>;
export type CreateDigitalMenuMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateDigitalMenuMutation, CreateDigitalMenuMutationVariables>;
export const DeleteDigitalMenuDocument = gql`
    mutation deleteDigitalMenu($ID: String!) {
  deleteDigitalMenu(ID: $ID)
}
    `;
export type DeleteDigitalMenuMutationFn = ApolloReactCommon.MutationFunction<DeleteDigitalMenuMutation, DeleteDigitalMenuMutationVariables>;

/**
 * __useDeleteDigitalMenuMutation__
 *
 * To run a mutation, you first call `useDeleteDigitalMenuMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDigitalMenuMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDigitalMenuMutation, { data, loading, error }] = useDeleteDigitalMenuMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDeleteDigitalMenuMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteDigitalMenuMutation, DeleteDigitalMenuMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteDigitalMenuMutation, DeleteDigitalMenuMutationVariables>(DeleteDigitalMenuDocument, baseOptions);
      }
export type DeleteDigitalMenuMutationHookResult = ReturnType<typeof useDeleteDigitalMenuMutation>;
export type DeleteDigitalMenuMutationResult = ApolloReactCommon.MutationResult<DeleteDigitalMenuMutation>;
export type DeleteDigitalMenuMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteDigitalMenuMutation, DeleteDigitalMenuMutationVariables>;
export const UpdateDigitalMenuDocument = gql`
    mutation updateDigitalMenu($input: DigitalMenuInput!) {
  updateDigitalMenu(input: $input) {
    ID
  }
}
    `;
export type UpdateDigitalMenuMutationFn = ApolloReactCommon.MutationFunction<UpdateDigitalMenuMutation, UpdateDigitalMenuMutationVariables>;

/**
 * __useUpdateDigitalMenuMutation__
 *
 * To run a mutation, you first call `useUpdateDigitalMenuMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDigitalMenuMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDigitalMenuMutation, { data, loading, error }] = useUpdateDigitalMenuMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDigitalMenuMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateDigitalMenuMutation, UpdateDigitalMenuMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateDigitalMenuMutation, UpdateDigitalMenuMutationVariables>(UpdateDigitalMenuDocument, baseOptions);
      }
export type UpdateDigitalMenuMutationHookResult = ReturnType<typeof useUpdateDigitalMenuMutation>;
export type UpdateDigitalMenuMutationResult = ApolloReactCommon.MutationResult<UpdateDigitalMenuMutation>;
export type UpdateDigitalMenuMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateDigitalMenuMutation, UpdateDigitalMenuMutationVariables>;
export const GetDigitalMenuCategoryDocument = gql`
    query getDigitalMenuCategory($ID: String, $name: String, $digitalMenuID: String, $outletID: String) {
  getDigitalMenuCategory(ID: $ID, name: $name, digitalMenuID: $digitalMenuID, outletID: $outletID) {
    ID
    outletID
    digitalMenuID
    priority
    name
    modTs
    createdTs
    digitalMenuItem {
      ID
      menuItemID
      menuItem {
        ID
        name
        stopSales {
          ID
          isActive
          menuItemID
        }
      }
    }
  }
}
    `;

/**
 * __useGetDigitalMenuCategoryQuery__
 *
 * To run a query within a React component, call `useGetDigitalMenuCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDigitalMenuCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDigitalMenuCategoryQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      name: // value for 'name'
 *      digitalMenuID: // value for 'digitalMenuID'
 *      outletID: // value for 'outletID'
 *   },
 * });
 */
export function useGetDigitalMenuCategoryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDigitalMenuCategoryQuery, GetDigitalMenuCategoryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetDigitalMenuCategoryQuery, GetDigitalMenuCategoryQueryVariables>(GetDigitalMenuCategoryDocument, baseOptions);
      }
export function useGetDigitalMenuCategoryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDigitalMenuCategoryQuery, GetDigitalMenuCategoryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetDigitalMenuCategoryQuery, GetDigitalMenuCategoryQueryVariables>(GetDigitalMenuCategoryDocument, baseOptions);
        }
export type GetDigitalMenuCategoryQueryHookResult = ReturnType<typeof useGetDigitalMenuCategoryQuery>;
export type GetDigitalMenuCategoryLazyQueryHookResult = ReturnType<typeof useGetDigitalMenuCategoryLazyQuery>;
export type GetDigitalMenuCategoryQueryResult = ApolloReactCommon.QueryResult<GetDigitalMenuCategoryQuery, GetDigitalMenuCategoryQueryVariables>;
export const GetDigitalMenuCategoryForPriceDocument = gql`
    query getDigitalMenuCategoryForPrice($ID: String, $name: String) {
  getDigitalMenuCategory(ID: $ID, name: $name) {
    ID
    createdTs
    digitalMenuID
    name
    digitalMenuItem {
      ID
      digitalMenuCategoryID
      menuItemID
      menuItem {
        ID
        name
        description
        attachments
        stopSales {
          ID
          isActive
          menuItemID
        }
      }
      digitalMenuItemPrice {
        ID
        digitalMenuItemID
        price
        digitalMenuItem {
          ID
          menuItemID
          menuItem {
            name
          }
        }
        patronID
        patron {
          ID
          patronClass
          isActive
          patronType {
            ID
            name
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetDigitalMenuCategoryForPriceQuery__
 *
 * To run a query within a React component, call `useGetDigitalMenuCategoryForPriceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDigitalMenuCategoryForPriceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDigitalMenuCategoryForPriceQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useGetDigitalMenuCategoryForPriceQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDigitalMenuCategoryForPriceQuery, GetDigitalMenuCategoryForPriceQueryVariables>) {
        return ApolloReactHooks.useQuery<GetDigitalMenuCategoryForPriceQuery, GetDigitalMenuCategoryForPriceQueryVariables>(GetDigitalMenuCategoryForPriceDocument, baseOptions);
      }
export function useGetDigitalMenuCategoryForPriceLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDigitalMenuCategoryForPriceQuery, GetDigitalMenuCategoryForPriceQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetDigitalMenuCategoryForPriceQuery, GetDigitalMenuCategoryForPriceQueryVariables>(GetDigitalMenuCategoryForPriceDocument, baseOptions);
        }
export type GetDigitalMenuCategoryForPriceQueryHookResult = ReturnType<typeof useGetDigitalMenuCategoryForPriceQuery>;
export type GetDigitalMenuCategoryForPriceLazyQueryHookResult = ReturnType<typeof useGetDigitalMenuCategoryForPriceLazyQuery>;
export type GetDigitalMenuCategoryForPriceQueryResult = ApolloReactCommon.QueryResult<GetDigitalMenuCategoryForPriceQuery, GetDigitalMenuCategoryForPriceQueryVariables>;
export const GetScheduledCategoryWithItemsDocument = gql`
    query getScheduledCategoryWithItems($mealPeriodID: String!) {
  getScheduledDigitalMenuCategory(mealPeriodID: $mealPeriodID) {
    ID
    outletID
    digitalMenuID
    priority
    name
    modTs
    createdTs
    digitalMenuItem {
      ID
      menuItemID
      menuItem {
        ID
        name
        description
        attachments
        menuItemOptions {
          ID
          menuItemID
          optionID
          maxSelections
          menuItemOptionItem {
            optionItemID
            ID
            price
          }
          option {
            ID
            name
            optionItem {
              ID
              name
            }
          }
        }
      }
      digitalMenuItemPrice {
        ID
        digitalMenuItemID
        price
        digitalMenuItem {
          ID
          menuItemID
          menuItem {
            name
          }
        }
        patronID
        patron {
          ID
          patronClass
          isActive
          patronType {
            ID
            name
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetScheduledCategoryWithItemsQuery__
 *
 * To run a query within a React component, call `useGetScheduledCategoryWithItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetScheduledCategoryWithItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetScheduledCategoryWithItemsQuery({
 *   variables: {
 *      mealPeriodID: // value for 'mealPeriodID'
 *   },
 * });
 */
export function useGetScheduledCategoryWithItemsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetScheduledCategoryWithItemsQuery, GetScheduledCategoryWithItemsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetScheduledCategoryWithItemsQuery, GetScheduledCategoryWithItemsQueryVariables>(GetScheduledCategoryWithItemsDocument, baseOptions);
      }
export function useGetScheduledCategoryWithItemsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetScheduledCategoryWithItemsQuery, GetScheduledCategoryWithItemsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetScheduledCategoryWithItemsQuery, GetScheduledCategoryWithItemsQueryVariables>(GetScheduledCategoryWithItemsDocument, baseOptions);
        }
export type GetScheduledCategoryWithItemsQueryHookResult = ReturnType<typeof useGetScheduledCategoryWithItemsQuery>;
export type GetScheduledCategoryWithItemsLazyQueryHookResult = ReturnType<typeof useGetScheduledCategoryWithItemsLazyQuery>;
export type GetScheduledCategoryWithItemsQueryResult = ApolloReactCommon.QueryResult<GetScheduledCategoryWithItemsQuery, GetScheduledCategoryWithItemsQueryVariables>;
export const GetDigitalMenuCategoryForMenuOptionItemDocument = gql`
    query getDigitalMenuCategoryForMenuOptionItem($ID: String, $name: String, $digitalMenuID: String) {
  getDigitalMenuCategory(ID: $ID, name: $name, digitalMenuID: $digitalMenuID) {
    ID
    createdTs
    digitalMenuID
    name
    digitalMenuItem {
      ID
      digitalMenuCategoryID
      menuItemID
      menuItem {
        ID
        name
        description
        attachments
        menuItemOptions {
          ID
          menuItemID
          optionID
          option {
            ID
            name
          }
          menuItemOptionItem {
            ID
            optionItem {
              ID
              name
            }
          }
        }
      }
      digitalMenuItemPrice {
        ID
        digitalMenuItemID
        price
        digitalMenuItem {
          ID
          menuItemID
          menuItem {
            name
          }
        }
        patronID
        patron {
          ID
          patronClass
          isActive
          patronType {
            ID
            name
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetDigitalMenuCategoryForMenuOptionItemQuery__
 *
 * To run a query within a React component, call `useGetDigitalMenuCategoryForMenuOptionItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDigitalMenuCategoryForMenuOptionItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDigitalMenuCategoryForMenuOptionItemQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      name: // value for 'name'
 *      digitalMenuID: // value for 'digitalMenuID'
 *   },
 * });
 */
export function useGetDigitalMenuCategoryForMenuOptionItemQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDigitalMenuCategoryForMenuOptionItemQuery, GetDigitalMenuCategoryForMenuOptionItemQueryVariables>) {
        return ApolloReactHooks.useQuery<GetDigitalMenuCategoryForMenuOptionItemQuery, GetDigitalMenuCategoryForMenuOptionItemQueryVariables>(GetDigitalMenuCategoryForMenuOptionItemDocument, baseOptions);
      }
export function useGetDigitalMenuCategoryForMenuOptionItemLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDigitalMenuCategoryForMenuOptionItemQuery, GetDigitalMenuCategoryForMenuOptionItemQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetDigitalMenuCategoryForMenuOptionItemQuery, GetDigitalMenuCategoryForMenuOptionItemQueryVariables>(GetDigitalMenuCategoryForMenuOptionItemDocument, baseOptions);
        }
export type GetDigitalMenuCategoryForMenuOptionItemQueryHookResult = ReturnType<typeof useGetDigitalMenuCategoryForMenuOptionItemQuery>;
export type GetDigitalMenuCategoryForMenuOptionItemLazyQueryHookResult = ReturnType<typeof useGetDigitalMenuCategoryForMenuOptionItemLazyQuery>;
export type GetDigitalMenuCategoryForMenuOptionItemQueryResult = ApolloReactCommon.QueryResult<GetDigitalMenuCategoryForMenuOptionItemQuery, GetDigitalMenuCategoryForMenuOptionItemQueryVariables>;
export const DigitalMenuPriorityAndNameDocument = gql`
    query digitalMenuPriorityAndName($ID: String) {
  getDigitalMenuCategory(ID: $ID) {
    ID
    priority
    name
  }
}
    `;

/**
 * __useDigitalMenuPriorityAndNameQuery__
 *
 * To run a query within a React component, call `useDigitalMenuPriorityAndNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useDigitalMenuPriorityAndNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDigitalMenuPriorityAndNameQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDigitalMenuPriorityAndNameQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DigitalMenuPriorityAndNameQuery, DigitalMenuPriorityAndNameQueryVariables>) {
        return ApolloReactHooks.useQuery<DigitalMenuPriorityAndNameQuery, DigitalMenuPriorityAndNameQueryVariables>(DigitalMenuPriorityAndNameDocument, baseOptions);
      }
export function useDigitalMenuPriorityAndNameLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DigitalMenuPriorityAndNameQuery, DigitalMenuPriorityAndNameQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DigitalMenuPriorityAndNameQuery, DigitalMenuPriorityAndNameQueryVariables>(DigitalMenuPriorityAndNameDocument, baseOptions);
        }
export type DigitalMenuPriorityAndNameQueryHookResult = ReturnType<typeof useDigitalMenuPriorityAndNameQuery>;
export type DigitalMenuPriorityAndNameLazyQueryHookResult = ReturnType<typeof useDigitalMenuPriorityAndNameLazyQuery>;
export type DigitalMenuPriorityAndNameQueryResult = ApolloReactCommon.QueryResult<DigitalMenuPriorityAndNameQuery, DigitalMenuPriorityAndNameQueryVariables>;
export const CreateDigitalMenuCategoryDocument = gql`
    mutation createDigitalMenuCategory($input: DigitalMenuCategoryInput!) {
  createDigitalMenuCategory(input: $input) {
    ID
  }
}
    `;
export type CreateDigitalMenuCategoryMutationFn = ApolloReactCommon.MutationFunction<CreateDigitalMenuCategoryMutation, CreateDigitalMenuCategoryMutationVariables>;

/**
 * __useCreateDigitalMenuCategoryMutation__
 *
 * To run a mutation, you first call `useCreateDigitalMenuCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDigitalMenuCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDigitalMenuCategoryMutation, { data, loading, error }] = useCreateDigitalMenuCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDigitalMenuCategoryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateDigitalMenuCategoryMutation, CreateDigitalMenuCategoryMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateDigitalMenuCategoryMutation, CreateDigitalMenuCategoryMutationVariables>(CreateDigitalMenuCategoryDocument, baseOptions);
      }
export type CreateDigitalMenuCategoryMutationHookResult = ReturnType<typeof useCreateDigitalMenuCategoryMutation>;
export type CreateDigitalMenuCategoryMutationResult = ApolloReactCommon.MutationResult<CreateDigitalMenuCategoryMutation>;
export type CreateDigitalMenuCategoryMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateDigitalMenuCategoryMutation, CreateDigitalMenuCategoryMutationVariables>;
export const UpdateDigitalMenuCategoryDocument = gql`
    mutation updateDigitalMenuCategory($input: DigitalMenuCategoryInput!) {
  updateDigitalMenuCategory(input: $input) {
    ID
  }
}
    `;
export type UpdateDigitalMenuCategoryMutationFn = ApolloReactCommon.MutationFunction<UpdateDigitalMenuCategoryMutation, UpdateDigitalMenuCategoryMutationVariables>;

/**
 * __useUpdateDigitalMenuCategoryMutation__
 *
 * To run a mutation, you first call `useUpdateDigitalMenuCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDigitalMenuCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDigitalMenuCategoryMutation, { data, loading, error }] = useUpdateDigitalMenuCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDigitalMenuCategoryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateDigitalMenuCategoryMutation, UpdateDigitalMenuCategoryMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateDigitalMenuCategoryMutation, UpdateDigitalMenuCategoryMutationVariables>(UpdateDigitalMenuCategoryDocument, baseOptions);
      }
export type UpdateDigitalMenuCategoryMutationHookResult = ReturnType<typeof useUpdateDigitalMenuCategoryMutation>;
export type UpdateDigitalMenuCategoryMutationResult = ApolloReactCommon.MutationResult<UpdateDigitalMenuCategoryMutation>;
export type UpdateDigitalMenuCategoryMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateDigitalMenuCategoryMutation, UpdateDigitalMenuCategoryMutationVariables>;
export const DeleteDigitalMenuCategoryDocument = gql`
    mutation deleteDigitalMenuCategory($ID: String!) {
  deleteDigitalMenuCategory(ID: $ID)
}
    `;
export type DeleteDigitalMenuCategoryMutationFn = ApolloReactCommon.MutationFunction<DeleteDigitalMenuCategoryMutation, DeleteDigitalMenuCategoryMutationVariables>;

/**
 * __useDeleteDigitalMenuCategoryMutation__
 *
 * To run a mutation, you first call `useDeleteDigitalMenuCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDigitalMenuCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDigitalMenuCategoryMutation, { data, loading, error }] = useDeleteDigitalMenuCategoryMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDeleteDigitalMenuCategoryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteDigitalMenuCategoryMutation, DeleteDigitalMenuCategoryMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteDigitalMenuCategoryMutation, DeleteDigitalMenuCategoryMutationVariables>(DeleteDigitalMenuCategoryDocument, baseOptions);
      }
export type DeleteDigitalMenuCategoryMutationHookResult = ReturnType<typeof useDeleteDigitalMenuCategoryMutation>;
export type DeleteDigitalMenuCategoryMutationResult = ApolloReactCommon.MutationResult<DeleteDigitalMenuCategoryMutation>;
export type DeleteDigitalMenuCategoryMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteDigitalMenuCategoryMutation, DeleteDigitalMenuCategoryMutationVariables>;
export const GetDigitalMenuItemDocument = gql`
    query getDigitalMenuItem($ID: String, $digitalMenuCategoryID: String) {
  getDigitalMenuItem(ID: $ID, digitalMenuCategoryID: $digitalMenuCategoryID) {
    ID
    menuItemID
    digitalMenuCategoryID
    digitalMenuCategory {
      ID
      name
      priority
    }
    menuItem {
      ID
      name
    }
  }
}
    `;

/**
 * __useGetDigitalMenuItemQuery__
 *
 * To run a query within a React component, call `useGetDigitalMenuItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDigitalMenuItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDigitalMenuItemQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      digitalMenuCategoryID: // value for 'digitalMenuCategoryID'
 *   },
 * });
 */
export function useGetDigitalMenuItemQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDigitalMenuItemQuery, GetDigitalMenuItemQueryVariables>) {
        return ApolloReactHooks.useQuery<GetDigitalMenuItemQuery, GetDigitalMenuItemQueryVariables>(GetDigitalMenuItemDocument, baseOptions);
      }
export function useGetDigitalMenuItemLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDigitalMenuItemQuery, GetDigitalMenuItemQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetDigitalMenuItemQuery, GetDigitalMenuItemQueryVariables>(GetDigitalMenuItemDocument, baseOptions);
        }
export type GetDigitalMenuItemQueryHookResult = ReturnType<typeof useGetDigitalMenuItemQuery>;
export type GetDigitalMenuItemLazyQueryHookResult = ReturnType<typeof useGetDigitalMenuItemLazyQuery>;
export type GetDigitalMenuItemQueryResult = ApolloReactCommon.QueryResult<GetDigitalMenuItemQuery, GetDigitalMenuItemQueryVariables>;
export const CreateDigitalMenuItemPriceDocument = gql`
    mutation createDigitalMenuItemPrice($outletID: String!, $digitalMenuCategoryID: String!, $digitalMenuItemInput: [DigitalMenuItemInput!]!) {
  createDigitalMenuItemPrice(outletID: $outletID, digitalMenuCategoryID: $digitalMenuCategoryID, digitalMenuItemInput: $digitalMenuItemInput)
}
    `;
export type CreateDigitalMenuItemPriceMutationFn = ApolloReactCommon.MutationFunction<CreateDigitalMenuItemPriceMutation, CreateDigitalMenuItemPriceMutationVariables>;

/**
 * __useCreateDigitalMenuItemPriceMutation__
 *
 * To run a mutation, you first call `useCreateDigitalMenuItemPriceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDigitalMenuItemPriceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDigitalMenuItemPriceMutation, { data, loading, error }] = useCreateDigitalMenuItemPriceMutation({
 *   variables: {
 *      outletID: // value for 'outletID'
 *      digitalMenuCategoryID: // value for 'digitalMenuCategoryID'
 *      digitalMenuItemInput: // value for 'digitalMenuItemInput'
 *   },
 * });
 */
export function useCreateDigitalMenuItemPriceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateDigitalMenuItemPriceMutation, CreateDigitalMenuItemPriceMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateDigitalMenuItemPriceMutation, CreateDigitalMenuItemPriceMutationVariables>(CreateDigitalMenuItemPriceDocument, baseOptions);
      }
export type CreateDigitalMenuItemPriceMutationHookResult = ReturnType<typeof useCreateDigitalMenuItemPriceMutation>;
export type CreateDigitalMenuItemPriceMutationResult = ApolloReactCommon.MutationResult<CreateDigitalMenuItemPriceMutation>;
export type CreateDigitalMenuItemPriceMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateDigitalMenuItemPriceMutation, CreateDigitalMenuItemPriceMutationVariables>;
export const UpdateDigitalMenuItemPriceDocument = gql`
    mutation updateDigitalMenuItemPrice($outletID: String!, $digitalMenuItemID: String!, $digitalMenuItemPriceInput: [DigitalMenuItemPriceInput!]!) {
  updateDigitalMenuItemPrice(outletID: $outletID, digitalMenuItemID: $digitalMenuItemID, digitalMenuItemPriceInput: $digitalMenuItemPriceInput)
}
    `;
export type UpdateDigitalMenuItemPriceMutationFn = ApolloReactCommon.MutationFunction<UpdateDigitalMenuItemPriceMutation, UpdateDigitalMenuItemPriceMutationVariables>;

/**
 * __useUpdateDigitalMenuItemPriceMutation__
 *
 * To run a mutation, you first call `useUpdateDigitalMenuItemPriceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDigitalMenuItemPriceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDigitalMenuItemPriceMutation, { data, loading, error }] = useUpdateDigitalMenuItemPriceMutation({
 *   variables: {
 *      outletID: // value for 'outletID'
 *      digitalMenuItemID: // value for 'digitalMenuItemID'
 *      digitalMenuItemPriceInput: // value for 'digitalMenuItemPriceInput'
 *   },
 * });
 */
export function useUpdateDigitalMenuItemPriceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateDigitalMenuItemPriceMutation, UpdateDigitalMenuItemPriceMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateDigitalMenuItemPriceMutation, UpdateDigitalMenuItemPriceMutationVariables>(UpdateDigitalMenuItemPriceDocument, baseOptions);
      }
export type UpdateDigitalMenuItemPriceMutationHookResult = ReturnType<typeof useUpdateDigitalMenuItemPriceMutation>;
export type UpdateDigitalMenuItemPriceMutationResult = ApolloReactCommon.MutationResult<UpdateDigitalMenuItemPriceMutation>;
export type UpdateDigitalMenuItemPriceMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateDigitalMenuItemPriceMutation, UpdateDigitalMenuItemPriceMutationVariables>;
export const DeleteDigitalMenuItemAndPriceDocument = gql`
    mutation deleteDigitalMenuItemAndPrice($digitalMenuItemID: String!) {
  deleteDigitalMenuItemAndPrice(digitalMenuItemID: $digitalMenuItemID)
}
    `;
export type DeleteDigitalMenuItemAndPriceMutationFn = ApolloReactCommon.MutationFunction<DeleteDigitalMenuItemAndPriceMutation, DeleteDigitalMenuItemAndPriceMutationVariables>;

/**
 * __useDeleteDigitalMenuItemAndPriceMutation__
 *
 * To run a mutation, you first call `useDeleteDigitalMenuItemAndPriceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDigitalMenuItemAndPriceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDigitalMenuItemAndPriceMutation, { data, loading, error }] = useDeleteDigitalMenuItemAndPriceMutation({
 *   variables: {
 *      digitalMenuItemID: // value for 'digitalMenuItemID'
 *   },
 * });
 */
export function useDeleteDigitalMenuItemAndPriceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteDigitalMenuItemAndPriceMutation, DeleteDigitalMenuItemAndPriceMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteDigitalMenuItemAndPriceMutation, DeleteDigitalMenuItemAndPriceMutationVariables>(DeleteDigitalMenuItemAndPriceDocument, baseOptions);
      }
export type DeleteDigitalMenuItemAndPriceMutationHookResult = ReturnType<typeof useDeleteDigitalMenuItemAndPriceMutation>;
export type DeleteDigitalMenuItemAndPriceMutationResult = ApolloReactCommon.MutationResult<DeleteDigitalMenuItemAndPriceMutation>;
export type DeleteDigitalMenuItemAndPriceMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteDigitalMenuItemAndPriceMutation, DeleteDigitalMenuItemAndPriceMutationVariables>;
export const GetRemainingMenuItemsForDigitalDocument = gql`
    query getRemainingMenuItemsForDigital($digitalMenuCategoryID: String!, $outletID: String!) {
  getMenuItemForDigitalMenuItemPrice(digitalMenuCategoryID: $digitalMenuCategoryID, outletID: $outletID) {
    ID
    name
    description
    kitchenName
    nativeName
    stopSales {
      ID
      isActive
      menuItemID
    }
    setMenuInfo {
      menuItemID
      setMenuID
      setMenuName
    }
  }
}
    `;

/**
 * __useGetRemainingMenuItemsForDigitalQuery__
 *
 * To run a query within a React component, call `useGetRemainingMenuItemsForDigitalQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRemainingMenuItemsForDigitalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRemainingMenuItemsForDigitalQuery({
 *   variables: {
 *      digitalMenuCategoryID: // value for 'digitalMenuCategoryID'
 *      outletID: // value for 'outletID'
 *   },
 * });
 */
export function useGetRemainingMenuItemsForDigitalQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetRemainingMenuItemsForDigitalQuery, GetRemainingMenuItemsForDigitalQueryVariables>) {
        return ApolloReactHooks.useQuery<GetRemainingMenuItemsForDigitalQuery, GetRemainingMenuItemsForDigitalQueryVariables>(GetRemainingMenuItemsForDigitalDocument, baseOptions);
      }
export function useGetRemainingMenuItemsForDigitalLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetRemainingMenuItemsForDigitalQuery, GetRemainingMenuItemsForDigitalQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetRemainingMenuItemsForDigitalQuery, GetRemainingMenuItemsForDigitalQueryVariables>(GetRemainingMenuItemsForDigitalDocument, baseOptions);
        }
export type GetRemainingMenuItemsForDigitalQueryHookResult = ReturnType<typeof useGetRemainingMenuItemsForDigitalQuery>;
export type GetRemainingMenuItemsForDigitalLazyQueryHookResult = ReturnType<typeof useGetRemainingMenuItemsForDigitalLazyQuery>;
export type GetRemainingMenuItemsForDigitalQueryResult = ApolloReactCommon.QueryResult<GetRemainingMenuItemsForDigitalQuery, GetRemainingMenuItemsForDigitalQueryVariables>;
export const DuplicateDigitalMenuDocument = gql`
    mutation duplicateDigitalMenu($digitalMenuID: String!, $outletID: String!) {
  duplicateDigitalMenu(digitalMenuID: $digitalMenuID, outletID: $outletID)
}
    `;
export type DuplicateDigitalMenuMutationFn = ApolloReactCommon.MutationFunction<DuplicateDigitalMenuMutation, DuplicateDigitalMenuMutationVariables>;

/**
 * __useDuplicateDigitalMenuMutation__
 *
 * To run a mutation, you first call `useDuplicateDigitalMenuMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateDigitalMenuMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateDigitalMenuMutation, { data, loading, error }] = useDuplicateDigitalMenuMutation({
 *   variables: {
 *      digitalMenuID: // value for 'digitalMenuID'
 *      outletID: // value for 'outletID'
 *   },
 * });
 */
export function useDuplicateDigitalMenuMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DuplicateDigitalMenuMutation, DuplicateDigitalMenuMutationVariables>) {
        return ApolloReactHooks.useMutation<DuplicateDigitalMenuMutation, DuplicateDigitalMenuMutationVariables>(DuplicateDigitalMenuDocument, baseOptions);
      }
export type DuplicateDigitalMenuMutationHookResult = ReturnType<typeof useDuplicateDigitalMenuMutation>;
export type DuplicateDigitalMenuMutationResult = ApolloReactCommon.MutationResult<DuplicateDigitalMenuMutation>;
export type DuplicateDigitalMenuMutationOptions = ApolloReactCommon.BaseMutationOptions<DuplicateDigitalMenuMutation, DuplicateDigitalMenuMutationVariables>;
export const IsDigitalMenuInUseDocument = gql`
    query isDigitalMenuInUse($outletID: String!, $digitalMenuID: String!) {
  isDigitalMenuInUse(outletID: $outletID, digitalMenuID: $digitalMenuID)
}
    `;

/**
 * __useIsDigitalMenuInUseQuery__
 *
 * To run a query within a React component, call `useIsDigitalMenuInUseQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsDigitalMenuInUseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsDigitalMenuInUseQuery({
 *   variables: {
 *      outletID: // value for 'outletID'
 *      digitalMenuID: // value for 'digitalMenuID'
 *   },
 * });
 */
export function useIsDigitalMenuInUseQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IsDigitalMenuInUseQuery, IsDigitalMenuInUseQueryVariables>) {
        return ApolloReactHooks.useQuery<IsDigitalMenuInUseQuery, IsDigitalMenuInUseQueryVariables>(IsDigitalMenuInUseDocument, baseOptions);
      }
export function useIsDigitalMenuInUseLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IsDigitalMenuInUseQuery, IsDigitalMenuInUseQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IsDigitalMenuInUseQuery, IsDigitalMenuInUseQueryVariables>(IsDigitalMenuInUseDocument, baseOptions);
        }
export type IsDigitalMenuInUseQueryHookResult = ReturnType<typeof useIsDigitalMenuInUseQuery>;
export type IsDigitalMenuInUseLazyQueryHookResult = ReturnType<typeof useIsDigitalMenuInUseLazyQuery>;
export type IsDigitalMenuInUseQueryResult = ApolloReactCommon.QueryResult<IsDigitalMenuInUseQuery, IsDigitalMenuInUseQueryVariables>;
export const IsDigitalMenuCategoryInUseDocument = gql`
    query isDigitalMenuCategoryInUse($outletID: String!, $digitalMenuCategoryID: String!) {
  isDigitalMenuCategoryInUse(outletID: $outletID, digitalMenuCategoryID: $digitalMenuCategoryID)
}
    `;

/**
 * __useIsDigitalMenuCategoryInUseQuery__
 *
 * To run a query within a React component, call `useIsDigitalMenuCategoryInUseQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsDigitalMenuCategoryInUseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsDigitalMenuCategoryInUseQuery({
 *   variables: {
 *      outletID: // value for 'outletID'
 *      digitalMenuCategoryID: // value for 'digitalMenuCategoryID'
 *   },
 * });
 */
export function useIsDigitalMenuCategoryInUseQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IsDigitalMenuCategoryInUseQuery, IsDigitalMenuCategoryInUseQueryVariables>) {
        return ApolloReactHooks.useQuery<IsDigitalMenuCategoryInUseQuery, IsDigitalMenuCategoryInUseQueryVariables>(IsDigitalMenuCategoryInUseDocument, baseOptions);
      }
export function useIsDigitalMenuCategoryInUseLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IsDigitalMenuCategoryInUseQuery, IsDigitalMenuCategoryInUseQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IsDigitalMenuCategoryInUseQuery, IsDigitalMenuCategoryInUseQueryVariables>(IsDigitalMenuCategoryInUseDocument, baseOptions);
        }
export type IsDigitalMenuCategoryInUseQueryHookResult = ReturnType<typeof useIsDigitalMenuCategoryInUseQuery>;
export type IsDigitalMenuCategoryInUseLazyQueryHookResult = ReturnType<typeof useIsDigitalMenuCategoryInUseLazyQuery>;
export type IsDigitalMenuCategoryInUseQueryResult = ApolloReactCommon.QueryResult<IsDigitalMenuCategoryInUseQuery, IsDigitalMenuCategoryInUseQueryVariables>;
export const GetDiscountDocument = gql`
    query getDiscount($ID: String, $outletID: String) {
  getDiscount(ID: $ID, outletID: $outletID) {
    ID
    code
    amount
    calcType
    discountType
    description
    discountCheckingInUseInfo
  }
}
    `;

/**
 * __useGetDiscountQuery__
 *
 * To run a query within a React component, call `useGetDiscountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDiscountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDiscountQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      outletID: // value for 'outletID'
 *   },
 * });
 */
export function useGetDiscountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDiscountQuery, GetDiscountQueryVariables>) {
        return ApolloReactHooks.useQuery<GetDiscountQuery, GetDiscountQueryVariables>(GetDiscountDocument, baseOptions);
      }
export function useGetDiscountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDiscountQuery, GetDiscountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetDiscountQuery, GetDiscountQueryVariables>(GetDiscountDocument, baseOptions);
        }
export type GetDiscountQueryHookResult = ReturnType<typeof useGetDiscountQuery>;
export type GetDiscountLazyQueryHookResult = ReturnType<typeof useGetDiscountLazyQuery>;
export type GetDiscountQueryResult = ApolloReactCommon.QueryResult<GetDiscountQuery, GetDiscountQueryVariables>;
export const CreateDiscountDocument = gql`
    mutation createDiscount($input: DiscountInput!) {
  createDiscount(input: $input) {
    ID
  }
}
    `;
export type CreateDiscountMutationFn = ApolloReactCommon.MutationFunction<CreateDiscountMutation, CreateDiscountMutationVariables>;

/**
 * __useCreateDiscountMutation__
 *
 * To run a mutation, you first call `useCreateDiscountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDiscountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDiscountMutation, { data, loading, error }] = useCreateDiscountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDiscountMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateDiscountMutation, CreateDiscountMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateDiscountMutation, CreateDiscountMutationVariables>(CreateDiscountDocument, baseOptions);
      }
export type CreateDiscountMutationHookResult = ReturnType<typeof useCreateDiscountMutation>;
export type CreateDiscountMutationResult = ApolloReactCommon.MutationResult<CreateDiscountMutation>;
export type CreateDiscountMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateDiscountMutation, CreateDiscountMutationVariables>;
export const UpdateDiscountDocument = gql`
    mutation updateDiscount($input: DiscountInput!) {
  updateDiscount(input: $input) {
    ID
  }
}
    `;
export type UpdateDiscountMutationFn = ApolloReactCommon.MutationFunction<UpdateDiscountMutation, UpdateDiscountMutationVariables>;

/**
 * __useUpdateDiscountMutation__
 *
 * To run a mutation, you first call `useUpdateDiscountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDiscountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDiscountMutation, { data, loading, error }] = useUpdateDiscountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDiscountMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateDiscountMutation, UpdateDiscountMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateDiscountMutation, UpdateDiscountMutationVariables>(UpdateDiscountDocument, baseOptions);
      }
export type UpdateDiscountMutationHookResult = ReturnType<typeof useUpdateDiscountMutation>;
export type UpdateDiscountMutationResult = ApolloReactCommon.MutationResult<UpdateDiscountMutation>;
export type UpdateDiscountMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateDiscountMutation, UpdateDiscountMutationVariables>;
export const DeleteDiscountDocument = gql`
    mutation deleteDiscount($ID: String!) {
  deleteDiscount(ID: $ID)
}
    `;
export type DeleteDiscountMutationFn = ApolloReactCommon.MutationFunction<DeleteDiscountMutation, DeleteDiscountMutationVariables>;

/**
 * __useDeleteDiscountMutation__
 *
 * To run a mutation, you first call `useDeleteDiscountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDiscountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDiscountMutation, { data, loading, error }] = useDeleteDiscountMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDeleteDiscountMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteDiscountMutation, DeleteDiscountMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteDiscountMutation, DeleteDiscountMutationVariables>(DeleteDiscountDocument, baseOptions);
      }
export type DeleteDiscountMutationHookResult = ReturnType<typeof useDeleteDiscountMutation>;
export type DeleteDiscountMutationResult = ApolloReactCommon.MutationResult<DeleteDiscountMutation>;
export type DeleteDiscountMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteDiscountMutation, DeleteDiscountMutationVariables>;
export const GetOutletSettingSummaryDocument = gql`
    query getOutletSettingSummary($outletID: String!) {
  outletProfileDate(ID: $outletID)
  outletPolicyDate(outletID: $outletID)
  outletPolicyDateNew(outletID: $outletID)
  tableCount(outletID: $outletID)
  qrCount(outletID: $outletID)
  kitchenPrinterCount(outletID: $outletID)
  receiptPrinterCount(outletID: $outletID)
  mealPeriodCount(outletID: $outletID)
  majorFamilyCount(outletID: $outletID)
  standardMenuOptionCount(outletID: $outletID)
  menuItemsCount(outletID: $outletID)
  setMenuCount(outletID: $outletID)
  digitalMenuPricingCount(outletID: $outletID)
  menuSchedulingCount(outletID: $outletID)
  promotionalMenuPricingCount(outletID: $outletID)
  dailyMenuItemAvailabilityCount(outletID: $outletID)
  promoCodeCount(outletID: $outletID)
  discountTypeCount(outletID: $outletID)
  stopSalesItemCount(outletID: $outletID)
  paymentTypeDate(outletID: $outletID)
}
    `;

/**
 * __useGetOutletSettingSummaryQuery__
 *
 * To run a query within a React component, call `useGetOutletSettingSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOutletSettingSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOutletSettingSummaryQuery({
 *   variables: {
 *      outletID: // value for 'outletID'
 *   },
 * });
 */
export function useGetOutletSettingSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetOutletSettingSummaryQuery, GetOutletSettingSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetOutletSettingSummaryQuery, GetOutletSettingSummaryQueryVariables>(GetOutletSettingSummaryDocument, baseOptions);
      }
export function useGetOutletSettingSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetOutletSettingSummaryQuery, GetOutletSettingSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetOutletSettingSummaryQuery, GetOutletSettingSummaryQueryVariables>(GetOutletSettingSummaryDocument, baseOptions);
        }
export type GetOutletSettingSummaryQueryHookResult = ReturnType<typeof useGetOutletSettingSummaryQuery>;
export type GetOutletSettingSummaryLazyQueryHookResult = ReturnType<typeof useGetOutletSettingSummaryLazyQuery>;
export type GetOutletSettingSummaryQueryResult = ApolloReactCommon.QueryResult<GetOutletSettingSummaryQuery, GetOutletSettingSummaryQueryVariables>;
export const SyncOutletDailyMenuDocument = gql`
    mutation syncOutletDailyMenu($outletID: String!) {
  syncOutletDailyMenu(outletID: $outletID)
}
    `;
export type SyncOutletDailyMenuMutationFn = ApolloReactCommon.MutationFunction<SyncOutletDailyMenuMutation, SyncOutletDailyMenuMutationVariables>;

/**
 * __useSyncOutletDailyMenuMutation__
 *
 * To run a mutation, you first call `useSyncOutletDailyMenuMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyncOutletDailyMenuMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syncOutletDailyMenuMutation, { data, loading, error }] = useSyncOutletDailyMenuMutation({
 *   variables: {
 *      outletID: // value for 'outletID'
 *   },
 * });
 */
export function useSyncOutletDailyMenuMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SyncOutletDailyMenuMutation, SyncOutletDailyMenuMutationVariables>) {
        return ApolloReactHooks.useMutation<SyncOutletDailyMenuMutation, SyncOutletDailyMenuMutationVariables>(SyncOutletDailyMenuDocument, baseOptions);
      }
export type SyncOutletDailyMenuMutationHookResult = ReturnType<typeof useSyncOutletDailyMenuMutation>;
export type SyncOutletDailyMenuMutationResult = ApolloReactCommon.MutationResult<SyncOutletDailyMenuMutation>;
export type SyncOutletDailyMenuMutationOptions = ApolloReactCommon.BaseMutationOptions<SyncOutletDailyMenuMutation, SyncOutletDailyMenuMutationVariables>;
export const DuplicateOutletMenuDocument = gql`
    mutation duplicateOutletMenu($sourceOutletID: String!, $targetOutletID: String!) {
  duplicateOutletMenu(sourceOutletID: $sourceOutletID, targetOutletID: $targetOutletID)
}
    `;
export type DuplicateOutletMenuMutationFn = ApolloReactCommon.MutationFunction<DuplicateOutletMenuMutation, DuplicateOutletMenuMutationVariables>;

/**
 * __useDuplicateOutletMenuMutation__
 *
 * To run a mutation, you first call `useDuplicateOutletMenuMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateOutletMenuMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateOutletMenuMutation, { data, loading, error }] = useDuplicateOutletMenuMutation({
 *   variables: {
 *      sourceOutletID: // value for 'sourceOutletID'
 *      targetOutletID: // value for 'targetOutletID'
 *   },
 * });
 */
export function useDuplicateOutletMenuMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DuplicateOutletMenuMutation, DuplicateOutletMenuMutationVariables>) {
        return ApolloReactHooks.useMutation<DuplicateOutletMenuMutation, DuplicateOutletMenuMutationVariables>(DuplicateOutletMenuDocument, baseOptions);
      }
export type DuplicateOutletMenuMutationHookResult = ReturnType<typeof useDuplicateOutletMenuMutation>;
export type DuplicateOutletMenuMutationResult = ApolloReactCommon.MutationResult<DuplicateOutletMenuMutation>;
export type DuplicateOutletMenuMutationOptions = ApolloReactCommon.BaseMutationOptions<DuplicateOutletMenuMutation, DuplicateOutletMenuMutationVariables>;
export const GetKitchenPrinterDocument = gql`
    query getKitchenPrinter($ID: String, $name: String, $outletID: String, $orderByDesc: String, $orderByAsc: String) {
  getKitchenPrinter(ID: $ID, name: $name, outletID: $outletID, orderByDesc: $orderByDesc, orderByAsc: $orderByAsc) {
    ID
    name
    description
    address
    outletID
  }
}
    `;

/**
 * __useGetKitchenPrinterQuery__
 *
 * To run a query within a React component, call `useGetKitchenPrinterQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetKitchenPrinterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetKitchenPrinterQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      name: // value for 'name'
 *      outletID: // value for 'outletID'
 *      orderByDesc: // value for 'orderByDesc'
 *      orderByAsc: // value for 'orderByAsc'
 *   },
 * });
 */
export function useGetKitchenPrinterQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetKitchenPrinterQuery, GetKitchenPrinterQueryVariables>) {
        return ApolloReactHooks.useQuery<GetKitchenPrinterQuery, GetKitchenPrinterQueryVariables>(GetKitchenPrinterDocument, baseOptions);
      }
export function useGetKitchenPrinterLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetKitchenPrinterQuery, GetKitchenPrinterQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetKitchenPrinterQuery, GetKitchenPrinterQueryVariables>(GetKitchenPrinterDocument, baseOptions);
        }
export type GetKitchenPrinterQueryHookResult = ReturnType<typeof useGetKitchenPrinterQuery>;
export type GetKitchenPrinterLazyQueryHookResult = ReturnType<typeof useGetKitchenPrinterLazyQuery>;
export type GetKitchenPrinterQueryResult = ApolloReactCommon.QueryResult<GetKitchenPrinterQuery, GetKitchenPrinterQueryVariables>;
export const CreateKitchenPrinterDocument = gql`
    mutation createKitchenPrinter($input: KitchenPrinterInput!) {
  createKitchenPrinter(input: $input) {
    ID
  }
}
    `;
export type CreateKitchenPrinterMutationFn = ApolloReactCommon.MutationFunction<CreateKitchenPrinterMutation, CreateKitchenPrinterMutationVariables>;

/**
 * __useCreateKitchenPrinterMutation__
 *
 * To run a mutation, you first call `useCreateKitchenPrinterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateKitchenPrinterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createKitchenPrinterMutation, { data, loading, error }] = useCreateKitchenPrinterMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateKitchenPrinterMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateKitchenPrinterMutation, CreateKitchenPrinterMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateKitchenPrinterMutation, CreateKitchenPrinterMutationVariables>(CreateKitchenPrinterDocument, baseOptions);
      }
export type CreateKitchenPrinterMutationHookResult = ReturnType<typeof useCreateKitchenPrinterMutation>;
export type CreateKitchenPrinterMutationResult = ApolloReactCommon.MutationResult<CreateKitchenPrinterMutation>;
export type CreateKitchenPrinterMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateKitchenPrinterMutation, CreateKitchenPrinterMutationVariables>;
export const UpdateKitchenPrinterDocument = gql`
    mutation updateKitchenPrinter($input: KitchenPrinterInput!) {
  updateKitchenPrinter(input: $input) {
    ID
  }
}
    `;
export type UpdateKitchenPrinterMutationFn = ApolloReactCommon.MutationFunction<UpdateKitchenPrinterMutation, UpdateKitchenPrinterMutationVariables>;

/**
 * __useUpdateKitchenPrinterMutation__
 *
 * To run a mutation, you first call `useUpdateKitchenPrinterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateKitchenPrinterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateKitchenPrinterMutation, { data, loading, error }] = useUpdateKitchenPrinterMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateKitchenPrinterMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateKitchenPrinterMutation, UpdateKitchenPrinterMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateKitchenPrinterMutation, UpdateKitchenPrinterMutationVariables>(UpdateKitchenPrinterDocument, baseOptions);
      }
export type UpdateKitchenPrinterMutationHookResult = ReturnType<typeof useUpdateKitchenPrinterMutation>;
export type UpdateKitchenPrinterMutationResult = ApolloReactCommon.MutationResult<UpdateKitchenPrinterMutation>;
export type UpdateKitchenPrinterMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateKitchenPrinterMutation, UpdateKitchenPrinterMutationVariables>;
export const DeleteKitchenPrinterDocument = gql`
    mutation deleteKitchenPrinter($ID: String!) {
  deleteKitchenPrinter(ID: $ID)
}
    `;
export type DeleteKitchenPrinterMutationFn = ApolloReactCommon.MutationFunction<DeleteKitchenPrinterMutation, DeleteKitchenPrinterMutationVariables>;

/**
 * __useDeleteKitchenPrinterMutation__
 *
 * To run a mutation, you first call `useDeleteKitchenPrinterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteKitchenPrinterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteKitchenPrinterMutation, { data, loading, error }] = useDeleteKitchenPrinterMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDeleteKitchenPrinterMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteKitchenPrinterMutation, DeleteKitchenPrinterMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteKitchenPrinterMutation, DeleteKitchenPrinterMutationVariables>(DeleteKitchenPrinterDocument, baseOptions);
      }
export type DeleteKitchenPrinterMutationHookResult = ReturnType<typeof useDeleteKitchenPrinterMutation>;
export type DeleteKitchenPrinterMutationResult = ApolloReactCommon.MutationResult<DeleteKitchenPrinterMutation>;
export type DeleteKitchenPrinterMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteKitchenPrinterMutation, DeleteKitchenPrinterMutationVariables>;
export const GetMajorDocument = gql`
    query getMajor($ID: String, $name: String, $outletID: String, $orderByDesc: String, $orderByAsc: String) {
  getMajor(ID: $ID, name: $name, outletID: $outletID, orderByDesc: $orderByDesc, orderByAsc: $orderByAsc) {
    ID
    name
    description
    eInvClassification
    outletID
    family {
      ID
      name
      majorID
    }
    priority
    msicCode
    msicDesc
  }
}
    `;

/**
 * __useGetMajorQuery__
 *
 * To run a query within a React component, call `useGetMajorQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMajorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMajorQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      name: // value for 'name'
 *      outletID: // value for 'outletID'
 *      orderByDesc: // value for 'orderByDesc'
 *      orderByAsc: // value for 'orderByAsc'
 *   },
 * });
 */
export function useGetMajorQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMajorQuery, GetMajorQueryVariables>) {
        return ApolloReactHooks.useQuery<GetMajorQuery, GetMajorQueryVariables>(GetMajorDocument, baseOptions);
      }
export function useGetMajorLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMajorQuery, GetMajorQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetMajorQuery, GetMajorQueryVariables>(GetMajorDocument, baseOptions);
        }
export type GetMajorQueryHookResult = ReturnType<typeof useGetMajorQuery>;
export type GetMajorLazyQueryHookResult = ReturnType<typeof useGetMajorLazyQuery>;
export type GetMajorQueryResult = ApolloReactCommon.QueryResult<GetMajorQuery, GetMajorQueryVariables>;
export const CreateMajorDocument = gql`
    mutation createMajor($input: MajorInput!) {
  createMajor(input: $input) {
    ID
  }
}
    `;
export type CreateMajorMutationFn = ApolloReactCommon.MutationFunction<CreateMajorMutation, CreateMajorMutationVariables>;

/**
 * __useCreateMajorMutation__
 *
 * To run a mutation, you first call `useCreateMajorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMajorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMajorMutation, { data, loading, error }] = useCreateMajorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMajorMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateMajorMutation, CreateMajorMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateMajorMutation, CreateMajorMutationVariables>(CreateMajorDocument, baseOptions);
      }
export type CreateMajorMutationHookResult = ReturnType<typeof useCreateMajorMutation>;
export type CreateMajorMutationResult = ApolloReactCommon.MutationResult<CreateMajorMutation>;
export type CreateMajorMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateMajorMutation, CreateMajorMutationVariables>;
export const CreateMajorWithGlDocument = gql`
    mutation createMajorWithGL($input: MajorInput!) {
  createMajorWithGL(input: $input)
}
    `;
export type CreateMajorWithGlMutationFn = ApolloReactCommon.MutationFunction<CreateMajorWithGlMutation, CreateMajorWithGlMutationVariables>;

/**
 * __useCreateMajorWithGlMutation__
 *
 * To run a mutation, you first call `useCreateMajorWithGlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMajorWithGlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMajorWithGlMutation, { data, loading, error }] = useCreateMajorWithGlMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMajorWithGlMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateMajorWithGlMutation, CreateMajorWithGlMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateMajorWithGlMutation, CreateMajorWithGlMutationVariables>(CreateMajorWithGlDocument, baseOptions);
      }
export type CreateMajorWithGlMutationHookResult = ReturnType<typeof useCreateMajorWithGlMutation>;
export type CreateMajorWithGlMutationResult = ApolloReactCommon.MutationResult<CreateMajorWithGlMutation>;
export type CreateMajorWithGlMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateMajorWithGlMutation, CreateMajorWithGlMutationVariables>;
export const UpdateMajorDocument = gql`
    mutation updateMajor($input: MajorInput!) {
  updateMajor(input: $input) {
    ID
  }
}
    `;
export type UpdateMajorMutationFn = ApolloReactCommon.MutationFunction<UpdateMajorMutation, UpdateMajorMutationVariables>;

/**
 * __useUpdateMajorMutation__
 *
 * To run a mutation, you first call `useUpdateMajorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMajorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMajorMutation, { data, loading, error }] = useUpdateMajorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMajorMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateMajorMutation, UpdateMajorMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateMajorMutation, UpdateMajorMutationVariables>(UpdateMajorDocument, baseOptions);
      }
export type UpdateMajorMutationHookResult = ReturnType<typeof useUpdateMajorMutation>;
export type UpdateMajorMutationResult = ApolloReactCommon.MutationResult<UpdateMajorMutation>;
export type UpdateMajorMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateMajorMutation, UpdateMajorMutationVariables>;
export const UpdateMajorWithGlDocument = gql`
    mutation updateMajorWithGL($input: MajorInput!) {
  updateMajorWithGL(input: $input)
}
    `;
export type UpdateMajorWithGlMutationFn = ApolloReactCommon.MutationFunction<UpdateMajorWithGlMutation, UpdateMajorWithGlMutationVariables>;

/**
 * __useUpdateMajorWithGlMutation__
 *
 * To run a mutation, you first call `useUpdateMajorWithGlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMajorWithGlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMajorWithGlMutation, { data, loading, error }] = useUpdateMajorWithGlMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMajorWithGlMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateMajorWithGlMutation, UpdateMajorWithGlMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateMajorWithGlMutation, UpdateMajorWithGlMutationVariables>(UpdateMajorWithGlDocument, baseOptions);
      }
export type UpdateMajorWithGlMutationHookResult = ReturnType<typeof useUpdateMajorWithGlMutation>;
export type UpdateMajorWithGlMutationResult = ApolloReactCommon.MutationResult<UpdateMajorWithGlMutation>;
export type UpdateMajorWithGlMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateMajorWithGlMutation, UpdateMajorWithGlMutationVariables>;
export const DeleteMajorWithGlDocument = gql`
    mutation deleteMajorWithGL($ID: String!) {
  deleteMajorWithGL(ID: $ID)
}
    `;
export type DeleteMajorWithGlMutationFn = ApolloReactCommon.MutationFunction<DeleteMajorWithGlMutation, DeleteMajorWithGlMutationVariables>;

/**
 * __useDeleteMajorWithGlMutation__
 *
 * To run a mutation, you first call `useDeleteMajorWithGlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMajorWithGlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMajorWithGlMutation, { data, loading, error }] = useDeleteMajorWithGlMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDeleteMajorWithGlMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteMajorWithGlMutation, DeleteMajorWithGlMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteMajorWithGlMutation, DeleteMajorWithGlMutationVariables>(DeleteMajorWithGlDocument, baseOptions);
      }
export type DeleteMajorWithGlMutationHookResult = ReturnType<typeof useDeleteMajorWithGlMutation>;
export type DeleteMajorWithGlMutationResult = ApolloReactCommon.MutationResult<DeleteMajorWithGlMutation>;
export type DeleteMajorWithGlMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteMajorWithGlMutation, DeleteMajorWithGlMutationVariables>;
export const DragMajorDocument = gql`
    mutation dragMajor($input: [MajorInput!]!, $outletID: String!) {
  dragMajor(input: $input, outletID: $outletID)
}
    `;
export type DragMajorMutationFn = ApolloReactCommon.MutationFunction<DragMajorMutation, DragMajorMutationVariables>;

/**
 * __useDragMajorMutation__
 *
 * To run a mutation, you first call `useDragMajorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDragMajorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [dragMajorMutation, { data, loading, error }] = useDragMajorMutation({
 *   variables: {
 *      input: // value for 'input'
 *      outletID: // value for 'outletID'
 *   },
 * });
 */
export function useDragMajorMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DragMajorMutation, DragMajorMutationVariables>) {
        return ApolloReactHooks.useMutation<DragMajorMutation, DragMajorMutationVariables>(DragMajorDocument, baseOptions);
      }
export type DragMajorMutationHookResult = ReturnType<typeof useDragMajorMutation>;
export type DragMajorMutationResult = ApolloReactCommon.MutationResult<DragMajorMutation>;
export type DragMajorMutationOptions = ApolloReactCommon.BaseMutationOptions<DragMajorMutation, DragMajorMutationVariables>;
export const GetFamilyDocument = gql`
    query getFamily($ID: String, $name: String, $majorID: String, $orderByDesc: String, $orderByAsc: String) {
  getFamily(ID: $ID, name: $name, majorID: $majorID, orderByDesc: $orderByDesc, orderByAsc: $orderByAsc) {
    ID
    name
    description
    majorID
    modTs
    createdTs
    createdBy
  }
}
    `;

/**
 * __useGetFamilyQuery__
 *
 * To run a query within a React component, call `useGetFamilyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFamilyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFamilyQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      name: // value for 'name'
 *      majorID: // value for 'majorID'
 *      orderByDesc: // value for 'orderByDesc'
 *      orderByAsc: // value for 'orderByAsc'
 *   },
 * });
 */
export function useGetFamilyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetFamilyQuery, GetFamilyQueryVariables>) {
        return ApolloReactHooks.useQuery<GetFamilyQuery, GetFamilyQueryVariables>(GetFamilyDocument, baseOptions);
      }
export function useGetFamilyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetFamilyQuery, GetFamilyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetFamilyQuery, GetFamilyQueryVariables>(GetFamilyDocument, baseOptions);
        }
export type GetFamilyQueryHookResult = ReturnType<typeof useGetFamilyQuery>;
export type GetFamilyLazyQueryHookResult = ReturnType<typeof useGetFamilyLazyQuery>;
export type GetFamilyQueryResult = ApolloReactCommon.QueryResult<GetFamilyQuery, GetFamilyQueryVariables>;
export const CreateFamilyDocument = gql`
    mutation createFamily($input: FamilyInput!) {
  createFamily(input: $input) {
    ID
  }
}
    `;
export type CreateFamilyMutationFn = ApolloReactCommon.MutationFunction<CreateFamilyMutation, CreateFamilyMutationVariables>;

/**
 * __useCreateFamilyMutation__
 *
 * To run a mutation, you first call `useCreateFamilyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFamilyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFamilyMutation, { data, loading, error }] = useCreateFamilyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateFamilyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateFamilyMutation, CreateFamilyMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateFamilyMutation, CreateFamilyMutationVariables>(CreateFamilyDocument, baseOptions);
      }
export type CreateFamilyMutationHookResult = ReturnType<typeof useCreateFamilyMutation>;
export type CreateFamilyMutationResult = ApolloReactCommon.MutationResult<CreateFamilyMutation>;
export type CreateFamilyMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateFamilyMutation, CreateFamilyMutationVariables>;
export const UpdateFamilyDocument = gql`
    mutation updateFamily($input: FamilyInput!) {
  updateFamily(input: $input) {
    ID
  }
}
    `;
export type UpdateFamilyMutationFn = ApolloReactCommon.MutationFunction<UpdateFamilyMutation, UpdateFamilyMutationVariables>;

/**
 * __useUpdateFamilyMutation__
 *
 * To run a mutation, you first call `useUpdateFamilyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFamilyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFamilyMutation, { data, loading, error }] = useUpdateFamilyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateFamilyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateFamilyMutation, UpdateFamilyMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateFamilyMutation, UpdateFamilyMutationVariables>(UpdateFamilyDocument, baseOptions);
      }
export type UpdateFamilyMutationHookResult = ReturnType<typeof useUpdateFamilyMutation>;
export type UpdateFamilyMutationResult = ApolloReactCommon.MutationResult<UpdateFamilyMutation>;
export type UpdateFamilyMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateFamilyMutation, UpdateFamilyMutationVariables>;
export const DeleteFamilyDocument = gql`
    mutation deleteFamily($ID: String!) {
  deleteFamily(ID: $ID)
}
    `;
export type DeleteFamilyMutationFn = ApolloReactCommon.MutationFunction<DeleteFamilyMutation, DeleteFamilyMutationVariables>;

/**
 * __useDeleteFamilyMutation__
 *
 * To run a mutation, you first call `useDeleteFamilyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFamilyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFamilyMutation, { data, loading, error }] = useDeleteFamilyMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDeleteFamilyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteFamilyMutation, DeleteFamilyMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteFamilyMutation, DeleteFamilyMutationVariables>(DeleteFamilyDocument, baseOptions);
      }
export type DeleteFamilyMutationHookResult = ReturnType<typeof useDeleteFamilyMutation>;
export type DeleteFamilyMutationResult = ApolloReactCommon.MutationResult<DeleteFamilyMutation>;
export type DeleteFamilyMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteFamilyMutation, DeleteFamilyMutationVariables>;
export const GetClassificationListingDocument = gql`
    query getClassificationListing {
  getClassificationListing {
    id
    classificationCode
    description
  }
}
    `;

/**
 * __useGetClassificationListingQuery__
 *
 * To run a query within a React component, call `useGetClassificationListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClassificationListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClassificationListingQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetClassificationListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetClassificationListingQuery, GetClassificationListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetClassificationListingQuery, GetClassificationListingQueryVariables>(GetClassificationListingDocument, baseOptions);
      }
export function useGetClassificationListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetClassificationListingQuery, GetClassificationListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetClassificationListingQuery, GetClassificationListingQueryVariables>(GetClassificationListingDocument, baseOptions);
        }
export type GetClassificationListingQueryHookResult = ReturnType<typeof useGetClassificationListingQuery>;
export type GetClassificationListingLazyQueryHookResult = ReturnType<typeof useGetClassificationListingLazyQuery>;
export type GetClassificationListingQueryResult = ApolloReactCommon.QueryResult<GetClassificationListingQuery, GetClassificationListingQueryVariables>;
export const GetMealPeriodDocument = gql`
    query getMealPeriod($ID: String, $menus: String, $outletID: String) {
  getMealPeriod(ID: $ID, menus: $menus, outletID: $outletID) {
    ID
    name
    startTime
    endTime
    outletID
    isActive
  }
}
    `;

/**
 * __useGetMealPeriodQuery__
 *
 * To run a query within a React component, call `useGetMealPeriodQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMealPeriodQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMealPeriodQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      menus: // value for 'menus'
 *      outletID: // value for 'outletID'
 *   },
 * });
 */
export function useGetMealPeriodQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMealPeriodQuery, GetMealPeriodQueryVariables>) {
        return ApolloReactHooks.useQuery<GetMealPeriodQuery, GetMealPeriodQueryVariables>(GetMealPeriodDocument, baseOptions);
      }
export function useGetMealPeriodLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMealPeriodQuery, GetMealPeriodQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetMealPeriodQuery, GetMealPeriodQueryVariables>(GetMealPeriodDocument, baseOptions);
        }
export type GetMealPeriodQueryHookResult = ReturnType<typeof useGetMealPeriodQuery>;
export type GetMealPeriodLazyQueryHookResult = ReturnType<typeof useGetMealPeriodLazyQuery>;
export type GetMealPeriodQueryResult = ApolloReactCommon.QueryResult<GetMealPeriodQuery, GetMealPeriodQueryVariables>;
export const GetCurrentMealPeriodDocument = gql`
    query getCurrentMealPeriod($outletID: String!) {
  getCurrentMealPeriod(outletID: $outletID) {
    ID
    name
    startTime
    endTime
    outletID
    isActive
  }
}
    `;

/**
 * __useGetCurrentMealPeriodQuery__
 *
 * To run a query within a React component, call `useGetCurrentMealPeriodQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentMealPeriodQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentMealPeriodQuery({
 *   variables: {
 *      outletID: // value for 'outletID'
 *   },
 * });
 */
export function useGetCurrentMealPeriodQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCurrentMealPeriodQuery, GetCurrentMealPeriodQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCurrentMealPeriodQuery, GetCurrentMealPeriodQueryVariables>(GetCurrentMealPeriodDocument, baseOptions);
      }
export function useGetCurrentMealPeriodLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCurrentMealPeriodQuery, GetCurrentMealPeriodQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCurrentMealPeriodQuery, GetCurrentMealPeriodQueryVariables>(GetCurrentMealPeriodDocument, baseOptions);
        }
export type GetCurrentMealPeriodQueryHookResult = ReturnType<typeof useGetCurrentMealPeriodQuery>;
export type GetCurrentMealPeriodLazyQueryHookResult = ReturnType<typeof useGetCurrentMealPeriodLazyQuery>;
export type GetCurrentMealPeriodQueryResult = ApolloReactCommon.QueryResult<GetCurrentMealPeriodQuery, GetCurrentMealPeriodQueryVariables>;
export const CreateMealPeriodDocument = gql`
    mutation createMealPeriod($input: MealPeriodInput!) {
  createMealPeriod(input: $input) {
    ID
  }
}
    `;
export type CreateMealPeriodMutationFn = ApolloReactCommon.MutationFunction<CreateMealPeriodMutation, CreateMealPeriodMutationVariables>;

/**
 * __useCreateMealPeriodMutation__
 *
 * To run a mutation, you first call `useCreateMealPeriodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMealPeriodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMealPeriodMutation, { data, loading, error }] = useCreateMealPeriodMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMealPeriodMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateMealPeriodMutation, CreateMealPeriodMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateMealPeriodMutation, CreateMealPeriodMutationVariables>(CreateMealPeriodDocument, baseOptions);
      }
export type CreateMealPeriodMutationHookResult = ReturnType<typeof useCreateMealPeriodMutation>;
export type CreateMealPeriodMutationResult = ApolloReactCommon.MutationResult<CreateMealPeriodMutation>;
export type CreateMealPeriodMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateMealPeriodMutation, CreateMealPeriodMutationVariables>;
export const UpdateMealPeriodDocument = gql`
    mutation updateMealPeriod($input: MealPeriodInput!) {
  updateMealPeriod(input: $input) {
    ID
    name
    startTime
    endTime
  }
}
    `;
export type UpdateMealPeriodMutationFn = ApolloReactCommon.MutationFunction<UpdateMealPeriodMutation, UpdateMealPeriodMutationVariables>;

/**
 * __useUpdateMealPeriodMutation__
 *
 * To run a mutation, you first call `useUpdateMealPeriodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMealPeriodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMealPeriodMutation, { data, loading, error }] = useUpdateMealPeriodMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMealPeriodMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateMealPeriodMutation, UpdateMealPeriodMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateMealPeriodMutation, UpdateMealPeriodMutationVariables>(UpdateMealPeriodDocument, baseOptions);
      }
export type UpdateMealPeriodMutationHookResult = ReturnType<typeof useUpdateMealPeriodMutation>;
export type UpdateMealPeriodMutationResult = ApolloReactCommon.MutationResult<UpdateMealPeriodMutation>;
export type UpdateMealPeriodMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateMealPeriodMutation, UpdateMealPeriodMutationVariables>;
export const DeleteMealPeriodDocument = gql`
    mutation deleteMealPeriod($ID: String!) {
  deleteMealPeriod(ID: $ID)
}
    `;
export type DeleteMealPeriodMutationFn = ApolloReactCommon.MutationFunction<DeleteMealPeriodMutation, DeleteMealPeriodMutationVariables>;

/**
 * __useDeleteMealPeriodMutation__
 *
 * To run a mutation, you first call `useDeleteMealPeriodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMealPeriodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMealPeriodMutation, { data, loading, error }] = useDeleteMealPeriodMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDeleteMealPeriodMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteMealPeriodMutation, DeleteMealPeriodMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteMealPeriodMutation, DeleteMealPeriodMutationVariables>(DeleteMealPeriodDocument, baseOptions);
      }
export type DeleteMealPeriodMutationHookResult = ReturnType<typeof useDeleteMealPeriodMutation>;
export type DeleteMealPeriodMutationResult = ApolloReactCommon.MutationResult<DeleteMealPeriodMutation>;
export type DeleteMealPeriodMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteMealPeriodMutation, DeleteMealPeriodMutationVariables>;
export const GetAllMealPeriodDocument = gql`
    query getAllMealPeriod {
  getAllMealPeriod
}
    `;

/**
 * __useGetAllMealPeriodQuery__
 *
 * To run a query within a React component, call `useGetAllMealPeriodQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllMealPeriodQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllMealPeriodQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllMealPeriodQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAllMealPeriodQuery, GetAllMealPeriodQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAllMealPeriodQuery, GetAllMealPeriodQueryVariables>(GetAllMealPeriodDocument, baseOptions);
      }
export function useGetAllMealPeriodLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAllMealPeriodQuery, GetAllMealPeriodQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAllMealPeriodQuery, GetAllMealPeriodQueryVariables>(GetAllMealPeriodDocument, baseOptions);
        }
export type GetAllMealPeriodQueryHookResult = ReturnType<typeof useGetAllMealPeriodQuery>;
export type GetAllMealPeriodLazyQueryHookResult = ReturnType<typeof useGetAllMealPeriodLazyQuery>;
export type GetAllMealPeriodQueryResult = ApolloReactCommon.QueryResult<GetAllMealPeriodQuery, GetAllMealPeriodQueryVariables>;
export const GetMenuItemDocument = gql`
    query getMenuItem($ID: String, $isSetMenu: Boolean, $outletID: String, $isQuantityControlled: Boolean, $orderByDesc: String, $orderByAsc: String) {
  getMenuItem(ID: $ID, isSetMenu: $isSetMenu, outletID: $outletID, isQuantityControlled: $isQuantityControlled, orderByDesc: $orderByDesc, orderByAsc: $orderByAsc) {
    ID
    outletID
    name
    description
    kitchenName
    nativeName
    defaultQuantity
    isActive
    taxSchemeID
    isQuantityControlled
    isChefRecommended
    isTaxable
    isServiceChargeable
    isDiscountable
    isSetMenu
    isByWeight
    isOpenPrice
    isOpenItem
    isPerforated
    perforatedQuantity
    uomID
    majorID
    familyID
    kitchenPrinterID
    kitchenPrinter2ID
    attachments
    dailyAvailability {
      ID
      quantity
      outstandingQty
    }
    menuItemOptions {
      ID
      maxSelections
      optionID
      menuItemID
      menuItemOptionItem {
        ID
        price
        optionItemID
        menuItemOptionID
        optionItem {
          ID
          name
        }
      }
    }
  }
}
    `;

/**
 * __useGetMenuItemQuery__
 *
 * To run a query within a React component, call `useGetMenuItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMenuItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMenuItemQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      isSetMenu: // value for 'isSetMenu'
 *      outletID: // value for 'outletID'
 *      isQuantityControlled: // value for 'isQuantityControlled'
 *      orderByDesc: // value for 'orderByDesc'
 *      orderByAsc: // value for 'orderByAsc'
 *   },
 * });
 */
export function useGetMenuItemQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMenuItemQuery, GetMenuItemQueryVariables>) {
        return ApolloReactHooks.useQuery<GetMenuItemQuery, GetMenuItemQueryVariables>(GetMenuItemDocument, baseOptions);
      }
export function useGetMenuItemLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMenuItemQuery, GetMenuItemQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetMenuItemQuery, GetMenuItemQueryVariables>(GetMenuItemDocument, baseOptions);
        }
export type GetMenuItemQueryHookResult = ReturnType<typeof useGetMenuItemQuery>;
export type GetMenuItemLazyQueryHookResult = ReturnType<typeof useGetMenuItemLazyQuery>;
export type GetMenuItemQueryResult = ApolloReactCommon.QueryResult<GetMenuItemQuery, GetMenuItemQueryVariables>;
export const CreateMenuItemDocument = gql`
    mutation createMenuItem($input: MenuItemInput!) {
  createMenuItem(input: $input) {
    ID
    name
    nativeName
    kitchenName
    description
    uomID
  }
}
    `;
export type CreateMenuItemMutationFn = ApolloReactCommon.MutationFunction<CreateMenuItemMutation, CreateMenuItemMutationVariables>;

/**
 * __useCreateMenuItemMutation__
 *
 * To run a mutation, you first call `useCreateMenuItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMenuItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMenuItemMutation, { data, loading, error }] = useCreateMenuItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMenuItemMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateMenuItemMutation, CreateMenuItemMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateMenuItemMutation, CreateMenuItemMutationVariables>(CreateMenuItemDocument, baseOptions);
      }
export type CreateMenuItemMutationHookResult = ReturnType<typeof useCreateMenuItemMutation>;
export type CreateMenuItemMutationResult = ApolloReactCommon.MutationResult<CreateMenuItemMutation>;
export type CreateMenuItemMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateMenuItemMutation, CreateMenuItemMutationVariables>;
export const UpdateMenuItemDocument = gql`
    mutation updateMenuItem($input: MenuItemInput!) {
  updateMenuItem(input: $input) {
    ID
    outletID
    name
    description
    kitchenName
    isActive
    isQuantityControlled
    nativeName
    defaultQuantity
    isChefRecommended
    isTaxable
    isServiceChargeable
    isDiscountable
    isSetMenu
    isByWeight
    isOpenPrice
    isOpenItem
    uomID
    majorID
  }
}
    `;
export type UpdateMenuItemMutationFn = ApolloReactCommon.MutationFunction<UpdateMenuItemMutation, UpdateMenuItemMutationVariables>;

/**
 * __useUpdateMenuItemMutation__
 *
 * To run a mutation, you first call `useUpdateMenuItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMenuItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMenuItemMutation, { data, loading, error }] = useUpdateMenuItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMenuItemMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateMenuItemMutation, UpdateMenuItemMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateMenuItemMutation, UpdateMenuItemMutationVariables>(UpdateMenuItemDocument, baseOptions);
      }
export type UpdateMenuItemMutationHookResult = ReturnType<typeof useUpdateMenuItemMutation>;
export type UpdateMenuItemMutationResult = ApolloReactCommon.MutationResult<UpdateMenuItemMutation>;
export type UpdateMenuItemMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateMenuItemMutation, UpdateMenuItemMutationVariables>;
export const CreateMenuItemWithOptionsDocument = gql`
    mutation createMenuItemWithOptions($menuItemInput: MenuItemInput!, $menuItemOptionInput: [MenuItemOptionInput!]) {
  createMenuItemWithOptions(menuItemInput: $menuItemInput, menuItemOptionInput: $menuItemOptionInput)
}
    `;
export type CreateMenuItemWithOptionsMutationFn = ApolloReactCommon.MutationFunction<CreateMenuItemWithOptionsMutation, CreateMenuItemWithOptionsMutationVariables>;

/**
 * __useCreateMenuItemWithOptionsMutation__
 *
 * To run a mutation, you first call `useCreateMenuItemWithOptionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMenuItemWithOptionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMenuItemWithOptionsMutation, { data, loading, error }] = useCreateMenuItemWithOptionsMutation({
 *   variables: {
 *      menuItemInput: // value for 'menuItemInput'
 *      menuItemOptionInput: // value for 'menuItemOptionInput'
 *   },
 * });
 */
export function useCreateMenuItemWithOptionsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateMenuItemWithOptionsMutation, CreateMenuItemWithOptionsMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateMenuItemWithOptionsMutation, CreateMenuItemWithOptionsMutationVariables>(CreateMenuItemWithOptionsDocument, baseOptions);
      }
export type CreateMenuItemWithOptionsMutationHookResult = ReturnType<typeof useCreateMenuItemWithOptionsMutation>;
export type CreateMenuItemWithOptionsMutationResult = ApolloReactCommon.MutationResult<CreateMenuItemWithOptionsMutation>;
export type CreateMenuItemWithOptionsMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateMenuItemWithOptionsMutation, CreateMenuItemWithOptionsMutationVariables>;
export const UpdateMenuItemWithOptionsDocument = gql`
    mutation updateMenuItemWithOptions($menuItemInput: MenuItemInput!, $menuItemOptionInput: [MenuItemOptionInput!]) {
  updateMenuItemWithOptions(menuItemInput: $menuItemInput, menuItemOptionInput: $menuItemOptionInput)
}
    `;
export type UpdateMenuItemWithOptionsMutationFn = ApolloReactCommon.MutationFunction<UpdateMenuItemWithOptionsMutation, UpdateMenuItemWithOptionsMutationVariables>;

/**
 * __useUpdateMenuItemWithOptionsMutation__
 *
 * To run a mutation, you first call `useUpdateMenuItemWithOptionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMenuItemWithOptionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMenuItemWithOptionsMutation, { data, loading, error }] = useUpdateMenuItemWithOptionsMutation({
 *   variables: {
 *      menuItemInput: // value for 'menuItemInput'
 *      menuItemOptionInput: // value for 'menuItemOptionInput'
 *   },
 * });
 */
export function useUpdateMenuItemWithOptionsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateMenuItemWithOptionsMutation, UpdateMenuItemWithOptionsMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateMenuItemWithOptionsMutation, UpdateMenuItemWithOptionsMutationVariables>(UpdateMenuItemWithOptionsDocument, baseOptions);
      }
export type UpdateMenuItemWithOptionsMutationHookResult = ReturnType<typeof useUpdateMenuItemWithOptionsMutation>;
export type UpdateMenuItemWithOptionsMutationResult = ApolloReactCommon.MutationResult<UpdateMenuItemWithOptionsMutation>;
export type UpdateMenuItemWithOptionsMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateMenuItemWithOptionsMutation, UpdateMenuItemWithOptionsMutationVariables>;
export const DeleteMenuItemDocument = gql`
    mutation deleteMenuItem($ID: String!) {
  deleteMenuItem(ID: $ID)
}
    `;
export type DeleteMenuItemMutationFn = ApolloReactCommon.MutationFunction<DeleteMenuItemMutation, DeleteMenuItemMutationVariables>;

/**
 * __useDeleteMenuItemMutation__
 *
 * To run a mutation, you first call `useDeleteMenuItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMenuItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMenuItemMutation, { data, loading, error }] = useDeleteMenuItemMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDeleteMenuItemMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteMenuItemMutation, DeleteMenuItemMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteMenuItemMutation, DeleteMenuItemMutationVariables>(DeleteMenuItemDocument, baseOptions);
      }
export type DeleteMenuItemMutationHookResult = ReturnType<typeof useDeleteMenuItemMutation>;
export type DeleteMenuItemMutationResult = ApolloReactCommon.MutationResult<DeleteMenuItemMutation>;
export type DeleteMenuItemMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteMenuItemMutation, DeleteMenuItemMutationVariables>;
export const GetScheduledMenuDocument = gql`
    query getScheduledMenu($ID: String, $outletID: String!) {
  getScheduledMenu(ID: $ID, outletID: $outletID) {
    ID
    name
    effectiveTs
    scheduledDigitalMenuMealPeriod
  }
}
    `;

/**
 * __useGetScheduledMenuQuery__
 *
 * To run a query within a React component, call `useGetScheduledMenuQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetScheduledMenuQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetScheduledMenuQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      outletID: // value for 'outletID'
 *   },
 * });
 */
export function useGetScheduledMenuQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetScheduledMenuQuery, GetScheduledMenuQueryVariables>) {
        return ApolloReactHooks.useQuery<GetScheduledMenuQuery, GetScheduledMenuQueryVariables>(GetScheduledMenuDocument, baseOptions);
      }
export function useGetScheduledMenuLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetScheduledMenuQuery, GetScheduledMenuQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetScheduledMenuQuery, GetScheduledMenuQueryVariables>(GetScheduledMenuDocument, baseOptions);
        }
export type GetScheduledMenuQueryHookResult = ReturnType<typeof useGetScheduledMenuQuery>;
export type GetScheduledMenuLazyQueryHookResult = ReturnType<typeof useGetScheduledMenuLazyQuery>;
export type GetScheduledMenuQueryResult = ApolloReactCommon.QueryResult<GetScheduledMenuQuery, GetScheduledMenuQueryVariables>;
export const CreateScheduledMenuDocument = gql`
    mutation createScheduledMenu($input: ScheduledMenuInput!) {
  createScheduledMenu(input: $input) {
    ID
  }
}
    `;
export type CreateScheduledMenuMutationFn = ApolloReactCommon.MutationFunction<CreateScheduledMenuMutation, CreateScheduledMenuMutationVariables>;

/**
 * __useCreateScheduledMenuMutation__
 *
 * To run a mutation, you first call `useCreateScheduledMenuMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateScheduledMenuMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createScheduledMenuMutation, { data, loading, error }] = useCreateScheduledMenuMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateScheduledMenuMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateScheduledMenuMutation, CreateScheduledMenuMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateScheduledMenuMutation, CreateScheduledMenuMutationVariables>(CreateScheduledMenuDocument, baseOptions);
      }
export type CreateScheduledMenuMutationHookResult = ReturnType<typeof useCreateScheduledMenuMutation>;
export type CreateScheduledMenuMutationResult = ApolloReactCommon.MutationResult<CreateScheduledMenuMutation>;
export type CreateScheduledMenuMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateScheduledMenuMutation, CreateScheduledMenuMutationVariables>;
export const UpdateScheduledMenuDocument = gql`
    mutation updateScheduledMenu($input: ScheduledMenuInput!) {
  updateScheduledMenu(input: $input) {
    ID
  }
}
    `;
export type UpdateScheduledMenuMutationFn = ApolloReactCommon.MutationFunction<UpdateScheduledMenuMutation, UpdateScheduledMenuMutationVariables>;

/**
 * __useUpdateScheduledMenuMutation__
 *
 * To run a mutation, you first call `useUpdateScheduledMenuMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateScheduledMenuMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateScheduledMenuMutation, { data, loading, error }] = useUpdateScheduledMenuMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateScheduledMenuMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateScheduledMenuMutation, UpdateScheduledMenuMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateScheduledMenuMutation, UpdateScheduledMenuMutationVariables>(UpdateScheduledMenuDocument, baseOptions);
      }
export type UpdateScheduledMenuMutationHookResult = ReturnType<typeof useUpdateScheduledMenuMutation>;
export type UpdateScheduledMenuMutationResult = ApolloReactCommon.MutationResult<UpdateScheduledMenuMutation>;
export type UpdateScheduledMenuMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateScheduledMenuMutation, UpdateScheduledMenuMutationVariables>;
export const CreateScheduledMenuAndPeriodsDocument = gql`
    mutation createScheduledMenuAndPeriods($scheduledMenuInput: ScheduledMenuInput!, $scheduledMenuMealPeriodInput: [ScheduledMenuMealPeriodInput!]!) {
  createScheduledMenuAndPeriods(scheduledMenuInput: $scheduledMenuInput, scheduledMenuMealPeriodInput: $scheduledMenuMealPeriodInput)
}
    `;
export type CreateScheduledMenuAndPeriodsMutationFn = ApolloReactCommon.MutationFunction<CreateScheduledMenuAndPeriodsMutation, CreateScheduledMenuAndPeriodsMutationVariables>;

/**
 * __useCreateScheduledMenuAndPeriodsMutation__
 *
 * To run a mutation, you first call `useCreateScheduledMenuAndPeriodsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateScheduledMenuAndPeriodsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createScheduledMenuAndPeriodsMutation, { data, loading, error }] = useCreateScheduledMenuAndPeriodsMutation({
 *   variables: {
 *      scheduledMenuInput: // value for 'scheduledMenuInput'
 *      scheduledMenuMealPeriodInput: // value for 'scheduledMenuMealPeriodInput'
 *   },
 * });
 */
export function useCreateScheduledMenuAndPeriodsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateScheduledMenuAndPeriodsMutation, CreateScheduledMenuAndPeriodsMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateScheduledMenuAndPeriodsMutation, CreateScheduledMenuAndPeriodsMutationVariables>(CreateScheduledMenuAndPeriodsDocument, baseOptions);
      }
export type CreateScheduledMenuAndPeriodsMutationHookResult = ReturnType<typeof useCreateScheduledMenuAndPeriodsMutation>;
export type CreateScheduledMenuAndPeriodsMutationResult = ApolloReactCommon.MutationResult<CreateScheduledMenuAndPeriodsMutation>;
export type CreateScheduledMenuAndPeriodsMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateScheduledMenuAndPeriodsMutation, CreateScheduledMenuAndPeriodsMutationVariables>;
export const UpdateScheduledMenuMealPeriodsDocument = gql`
    mutation updateScheduledMenuMealPeriods($scheduledMenuInput: ScheduledMenuInput!, $scheduledMenuMealPeriodInput: [ScheduledMenuMealPeriodInput!]!) {
  updateScheduledMenuMealPeriods(scheduledMenuInput: $scheduledMenuInput, scheduledMenuMealPeriodInput: $scheduledMenuMealPeriodInput)
}
    `;
export type UpdateScheduledMenuMealPeriodsMutationFn = ApolloReactCommon.MutationFunction<UpdateScheduledMenuMealPeriodsMutation, UpdateScheduledMenuMealPeriodsMutationVariables>;

/**
 * __useUpdateScheduledMenuMealPeriodsMutation__
 *
 * To run a mutation, you first call `useUpdateScheduledMenuMealPeriodsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateScheduledMenuMealPeriodsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateScheduledMenuMealPeriodsMutation, { data, loading, error }] = useUpdateScheduledMenuMealPeriodsMutation({
 *   variables: {
 *      scheduledMenuInput: // value for 'scheduledMenuInput'
 *      scheduledMenuMealPeriodInput: // value for 'scheduledMenuMealPeriodInput'
 *   },
 * });
 */
export function useUpdateScheduledMenuMealPeriodsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateScheduledMenuMealPeriodsMutation, UpdateScheduledMenuMealPeriodsMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateScheduledMenuMealPeriodsMutation, UpdateScheduledMenuMealPeriodsMutationVariables>(UpdateScheduledMenuMealPeriodsDocument, baseOptions);
      }
export type UpdateScheduledMenuMealPeriodsMutationHookResult = ReturnType<typeof useUpdateScheduledMenuMealPeriodsMutation>;
export type UpdateScheduledMenuMealPeriodsMutationResult = ApolloReactCommon.MutationResult<UpdateScheduledMenuMealPeriodsMutation>;
export type UpdateScheduledMenuMealPeriodsMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateScheduledMenuMealPeriodsMutation, UpdateScheduledMenuMealPeriodsMutationVariables>;
export const DeleteScheduledMenuMealPeriodsDocument = gql`
    mutation deleteScheduledMenuMealPeriods($scheduledMenuID: String!) {
  deleteScheduledMenuMealPeriods(scheduledMenuID: $scheduledMenuID)
}
    `;
export type DeleteScheduledMenuMealPeriodsMutationFn = ApolloReactCommon.MutationFunction<DeleteScheduledMenuMealPeriodsMutation, DeleteScheduledMenuMealPeriodsMutationVariables>;

/**
 * __useDeleteScheduledMenuMealPeriodsMutation__
 *
 * To run a mutation, you first call `useDeleteScheduledMenuMealPeriodsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteScheduledMenuMealPeriodsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteScheduledMenuMealPeriodsMutation, { data, loading, error }] = useDeleteScheduledMenuMealPeriodsMutation({
 *   variables: {
 *      scheduledMenuID: // value for 'scheduledMenuID'
 *   },
 * });
 */
export function useDeleteScheduledMenuMealPeriodsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteScheduledMenuMealPeriodsMutation, DeleteScheduledMenuMealPeriodsMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteScheduledMenuMealPeriodsMutation, DeleteScheduledMenuMealPeriodsMutationVariables>(DeleteScheduledMenuMealPeriodsDocument, baseOptions);
      }
export type DeleteScheduledMenuMealPeriodsMutationHookResult = ReturnType<typeof useDeleteScheduledMenuMealPeriodsMutation>;
export type DeleteScheduledMenuMealPeriodsMutationResult = ApolloReactCommon.MutationResult<DeleteScheduledMenuMealPeriodsMutation>;
export type DeleteScheduledMenuMealPeriodsMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteScheduledMenuMealPeriodsMutation, DeleteScheduledMenuMealPeriodsMutationVariables>;
export const SubmitOutletCustomerPolicyDocument = gql`
    mutation submitOutletCustomerPolicy($outletCustomerPolicyInput: OutletCustomerPolicyInput!) {
  submitOutletCustomerPolicy(outletCustomerPolicyInput: $outletCustomerPolicyInput)
}
    `;
export type SubmitOutletCustomerPolicyMutationFn = ApolloReactCommon.MutationFunction<SubmitOutletCustomerPolicyMutation, SubmitOutletCustomerPolicyMutationVariables>;

/**
 * __useSubmitOutletCustomerPolicyMutation__
 *
 * To run a mutation, you first call `useSubmitOutletCustomerPolicyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitOutletCustomerPolicyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitOutletCustomerPolicyMutation, { data, loading, error }] = useSubmitOutletCustomerPolicyMutation({
 *   variables: {
 *      outletCustomerPolicyInput: // value for 'outletCustomerPolicyInput'
 *   },
 * });
 */
export function useSubmitOutletCustomerPolicyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SubmitOutletCustomerPolicyMutation, SubmitOutletCustomerPolicyMutationVariables>) {
        return ApolloReactHooks.useMutation<SubmitOutletCustomerPolicyMutation, SubmitOutletCustomerPolicyMutationVariables>(SubmitOutletCustomerPolicyDocument, baseOptions);
      }
export type SubmitOutletCustomerPolicyMutationHookResult = ReturnType<typeof useSubmitOutletCustomerPolicyMutation>;
export type SubmitOutletCustomerPolicyMutationResult = ApolloReactCommon.MutationResult<SubmitOutletCustomerPolicyMutation>;
export type SubmitOutletCustomerPolicyMutationOptions = ApolloReactCommon.BaseMutationOptions<SubmitOutletCustomerPolicyMutation, SubmitOutletCustomerPolicyMutationVariables>;
export const GetOutletCustomerPolicyDocument = gql`
    query getOutletCustomerPolicy($outletID: String!) {
  getOutletCustomerPolicy(outletID: $outletID) {
    ID
    outletID
    selfOrder
    selfPayment
    qrCodeGenerate
  }
}
    `;

/**
 * __useGetOutletCustomerPolicyQuery__
 *
 * To run a query within a React component, call `useGetOutletCustomerPolicyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOutletCustomerPolicyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOutletCustomerPolicyQuery({
 *   variables: {
 *      outletID: // value for 'outletID'
 *   },
 * });
 */
export function useGetOutletCustomerPolicyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetOutletCustomerPolicyQuery, GetOutletCustomerPolicyQueryVariables>) {
        return ApolloReactHooks.useQuery<GetOutletCustomerPolicyQuery, GetOutletCustomerPolicyQueryVariables>(GetOutletCustomerPolicyDocument, baseOptions);
      }
export function useGetOutletCustomerPolicyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetOutletCustomerPolicyQuery, GetOutletCustomerPolicyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetOutletCustomerPolicyQuery, GetOutletCustomerPolicyQueryVariables>(GetOutletCustomerPolicyDocument, baseOptions);
        }
export type GetOutletCustomerPolicyQueryHookResult = ReturnType<typeof useGetOutletCustomerPolicyQuery>;
export type GetOutletCustomerPolicyLazyQueryHookResult = ReturnType<typeof useGetOutletCustomerPolicyLazyQuery>;
export type GetOutletCustomerPolicyQueryResult = ApolloReactCommon.QueryResult<GetOutletCustomerPolicyQuery, GetOutletCustomerPolicyQueryVariables>;
export const GetPatronDocument = gql`
    query getPatron($ID: String, $outletID: String, $isActive: Boolean, $orderByDesc: String, $orderByAsc: String) {
  getPatron(ID: $ID, outletID: $outletID, isActive: $isActive, orderByDesc: $orderByDesc, orderByAsc: $orderByAsc) {
    ID
    isActive
    outletID
    patronTypeID
    patronClass
    patronType {
      ID
      name
      description
      patronClass
    }
  }
}
    `;

/**
 * __useGetPatronQuery__
 *
 * To run a query within a React component, call `useGetPatronQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPatronQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPatronQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      outletID: // value for 'outletID'
 *      isActive: // value for 'isActive'
 *      orderByDesc: // value for 'orderByDesc'
 *      orderByAsc: // value for 'orderByAsc'
 *   },
 * });
 */
export function useGetPatronQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPatronQuery, GetPatronQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPatronQuery, GetPatronQueryVariables>(GetPatronDocument, baseOptions);
      }
export function useGetPatronLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPatronQuery, GetPatronQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPatronQuery, GetPatronQueryVariables>(GetPatronDocument, baseOptions);
        }
export type GetPatronQueryHookResult = ReturnType<typeof useGetPatronQuery>;
export type GetPatronLazyQueryHookResult = ReturnType<typeof useGetPatronLazyQuery>;
export type GetPatronQueryResult = ApolloReactCommon.QueryResult<GetPatronQuery, GetPatronQueryVariables>;
export const GetPatronTypeDocument = gql`
    query getPatronType($ID: String) {
  getPatronType(ID: $ID) {
    ID
    name
    description
    patronClass
  }
}
    `;

/**
 * __useGetPatronTypeQuery__
 *
 * To run a query within a React component, call `useGetPatronTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPatronTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPatronTypeQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useGetPatronTypeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPatronTypeQuery, GetPatronTypeQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPatronTypeQuery, GetPatronTypeQueryVariables>(GetPatronTypeDocument, baseOptions);
      }
export function useGetPatronTypeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPatronTypeQuery, GetPatronTypeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPatronTypeQuery, GetPatronTypeQueryVariables>(GetPatronTypeDocument, baseOptions);
        }
export type GetPatronTypeQueryHookResult = ReturnType<typeof useGetPatronTypeQuery>;
export type GetPatronTypeLazyQueryHookResult = ReturnType<typeof useGetPatronTypeLazyQuery>;
export type GetPatronTypeQueryResult = ApolloReactCommon.QueryResult<GetPatronTypeQuery, GetPatronTypeQueryVariables>;
export const CreatePatronDocument = gql`
    mutation createPatron($input: PatronInput!) {
  createPatron(input: $input) {
    ID
  }
}
    `;
export type CreatePatronMutationFn = ApolloReactCommon.MutationFunction<CreatePatronMutation, CreatePatronMutationVariables>;

/**
 * __useCreatePatronMutation__
 *
 * To run a mutation, you first call `useCreatePatronMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePatronMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPatronMutation, { data, loading, error }] = useCreatePatronMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePatronMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreatePatronMutation, CreatePatronMutationVariables>) {
        return ApolloReactHooks.useMutation<CreatePatronMutation, CreatePatronMutationVariables>(CreatePatronDocument, baseOptions);
      }
export type CreatePatronMutationHookResult = ReturnType<typeof useCreatePatronMutation>;
export type CreatePatronMutationResult = ApolloReactCommon.MutationResult<CreatePatronMutation>;
export type CreatePatronMutationOptions = ApolloReactCommon.BaseMutationOptions<CreatePatronMutation, CreatePatronMutationVariables>;
export const SubmitOutletPatronSetupDocument = gql`
    mutation submitOutletPatronSetup($outletID: String!, $patronIDs: [String!]!) {
  submitOutletPatronSetup(patronIDs: $patronIDs, outletID: $outletID)
}
    `;
export type SubmitOutletPatronSetupMutationFn = ApolloReactCommon.MutationFunction<SubmitOutletPatronSetupMutation, SubmitOutletPatronSetupMutationVariables>;

/**
 * __useSubmitOutletPatronSetupMutation__
 *
 * To run a mutation, you first call `useSubmitOutletPatronSetupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitOutletPatronSetupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitOutletPatronSetupMutation, { data, loading, error }] = useSubmitOutletPatronSetupMutation({
 *   variables: {
 *      outletID: // value for 'outletID'
 *      patronIDs: // value for 'patronIDs'
 *   },
 * });
 */
export function useSubmitOutletPatronSetupMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SubmitOutletPatronSetupMutation, SubmitOutletPatronSetupMutationVariables>) {
        return ApolloReactHooks.useMutation<SubmitOutletPatronSetupMutation, SubmitOutletPatronSetupMutationVariables>(SubmitOutletPatronSetupDocument, baseOptions);
      }
export type SubmitOutletPatronSetupMutationHookResult = ReturnType<typeof useSubmitOutletPatronSetupMutation>;
export type SubmitOutletPatronSetupMutationResult = ApolloReactCommon.MutationResult<SubmitOutletPatronSetupMutation>;
export type SubmitOutletPatronSetupMutationOptions = ApolloReactCommon.BaseMutationOptions<SubmitOutletPatronSetupMutation, SubmitOutletPatronSetupMutationVariables>;
export const UpdatePatronDocument = gql`
    mutation updatePatron($input: PatronInput!) {
  updatePatron(input: $input) {
    ID
  }
}
    `;
export type UpdatePatronMutationFn = ApolloReactCommon.MutationFunction<UpdatePatronMutation, UpdatePatronMutationVariables>;

/**
 * __useUpdatePatronMutation__
 *
 * To run a mutation, you first call `useUpdatePatronMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePatronMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePatronMutation, { data, loading, error }] = useUpdatePatronMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePatronMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdatePatronMutation, UpdatePatronMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdatePatronMutation, UpdatePatronMutationVariables>(UpdatePatronDocument, baseOptions);
      }
export type UpdatePatronMutationHookResult = ReturnType<typeof useUpdatePatronMutation>;
export type UpdatePatronMutationResult = ApolloReactCommon.MutationResult<UpdatePatronMutation>;
export type UpdatePatronMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdatePatronMutation, UpdatePatronMutationVariables>;
export const GetOutletPolicySummaryDocument = gql`
    query getOutletPolicySummary($outletID: String!) {
  patronSetupDate(outletID: $outletID)
  taxSetupDate(outletID: $outletID)
  reasonSetupDate(outletID: $outletID)
  customerSetupDate(outletID: $outletID)
}
    `;

/**
 * __useGetOutletPolicySummaryQuery__
 *
 * To run a query within a React component, call `useGetOutletPolicySummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOutletPolicySummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOutletPolicySummaryQuery({
 *   variables: {
 *      outletID: // value for 'outletID'
 *   },
 * });
 */
export function useGetOutletPolicySummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetOutletPolicySummaryQuery, GetOutletPolicySummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetOutletPolicySummaryQuery, GetOutletPolicySummaryQueryVariables>(GetOutletPolicySummaryDocument, baseOptions);
      }
export function useGetOutletPolicySummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetOutletPolicySummaryQuery, GetOutletPolicySummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetOutletPolicySummaryQuery, GetOutletPolicySummaryQueryVariables>(GetOutletPolicySummaryDocument, baseOptions);
        }
export type GetOutletPolicySummaryQueryHookResult = ReturnType<typeof useGetOutletPolicySummaryQuery>;
export type GetOutletPolicySummaryLazyQueryHookResult = ReturnType<typeof useGetOutletPolicySummaryLazyQuery>;
export type GetOutletPolicySummaryQueryResult = ApolloReactCommon.QueryResult<GetOutletPolicySummaryQuery, GetOutletPolicySummaryQueryVariables>;
export const GetOutletTaxPolicyDocument = gql`
    query getOutletTaxPolicy($outletID: String, $taxType: TaxType) {
  getOutletTaxPolicy(outletID: $outletID, taxType: $taxType) {
    ID
    value
    effectiveDate
    isTaxable
  }
}
    `;

/**
 * __useGetOutletTaxPolicyQuery__
 *
 * To run a query within a React component, call `useGetOutletTaxPolicyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOutletTaxPolicyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOutletTaxPolicyQuery({
 *   variables: {
 *      outletID: // value for 'outletID'
 *      taxType: // value for 'taxType'
 *   },
 * });
 */
export function useGetOutletTaxPolicyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetOutletTaxPolicyQuery, GetOutletTaxPolicyQueryVariables>) {
        return ApolloReactHooks.useQuery<GetOutletTaxPolicyQuery, GetOutletTaxPolicyQueryVariables>(GetOutletTaxPolicyDocument, baseOptions);
      }
export function useGetOutletTaxPolicyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetOutletTaxPolicyQuery, GetOutletTaxPolicyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetOutletTaxPolicyQuery, GetOutletTaxPolicyQueryVariables>(GetOutletTaxPolicyDocument, baseOptions);
        }
export type GetOutletTaxPolicyQueryHookResult = ReturnType<typeof useGetOutletTaxPolicyQuery>;
export type GetOutletTaxPolicyLazyQueryHookResult = ReturnType<typeof useGetOutletTaxPolicyLazyQuery>;
export type GetOutletTaxPolicyQueryResult = ApolloReactCommon.QueryResult<GetOutletTaxPolicyQuery, GetOutletTaxPolicyQueryVariables>;
export const CreateOutletTaxPolicyDocument = gql`
    mutation createOutletTaxPolicy($input: OutletTaxPolicyInput!) {
  createOutletTaxPolicy(input: $input) {
    ID
  }
}
    `;
export type CreateOutletTaxPolicyMutationFn = ApolloReactCommon.MutationFunction<CreateOutletTaxPolicyMutation, CreateOutletTaxPolicyMutationVariables>;

/**
 * __useCreateOutletTaxPolicyMutation__
 *
 * To run a mutation, you first call `useCreateOutletTaxPolicyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOutletTaxPolicyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOutletTaxPolicyMutation, { data, loading, error }] = useCreateOutletTaxPolicyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOutletTaxPolicyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateOutletTaxPolicyMutation, CreateOutletTaxPolicyMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateOutletTaxPolicyMutation, CreateOutletTaxPolicyMutationVariables>(CreateOutletTaxPolicyDocument, baseOptions);
      }
export type CreateOutletTaxPolicyMutationHookResult = ReturnType<typeof useCreateOutletTaxPolicyMutation>;
export type CreateOutletTaxPolicyMutationResult = ApolloReactCommon.MutationResult<CreateOutletTaxPolicyMutation>;
export type CreateOutletTaxPolicyMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateOutletTaxPolicyMutation, CreateOutletTaxPolicyMutationVariables>;
export const UpdateOutletTaxPolicyDocument = gql`
    mutation updateOutletTaxPolicy($input: OutletTaxPolicyInput!) {
  updateOutletTaxPolicy(input: $input) {
    ID
  }
}
    `;
export type UpdateOutletTaxPolicyMutationFn = ApolloReactCommon.MutationFunction<UpdateOutletTaxPolicyMutation, UpdateOutletTaxPolicyMutationVariables>;

/**
 * __useUpdateOutletTaxPolicyMutation__
 *
 * To run a mutation, you first call `useUpdateOutletTaxPolicyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOutletTaxPolicyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOutletTaxPolicyMutation, { data, loading, error }] = useUpdateOutletTaxPolicyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOutletTaxPolicyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateOutletTaxPolicyMutation, UpdateOutletTaxPolicyMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateOutletTaxPolicyMutation, UpdateOutletTaxPolicyMutationVariables>(UpdateOutletTaxPolicyDocument, baseOptions);
      }
export type UpdateOutletTaxPolicyMutationHookResult = ReturnType<typeof useUpdateOutletTaxPolicyMutation>;
export type UpdateOutletTaxPolicyMutationResult = ApolloReactCommon.MutationResult<UpdateOutletTaxPolicyMutation>;
export type UpdateOutletTaxPolicyMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateOutletTaxPolicyMutation, UpdateOutletTaxPolicyMutationVariables>;
export const DeleteOutletTaxPolicyDocument = gql`
    mutation deleteOutletTaxPolicy($ID: String!) {
  deleteOutletTaxPolicy(ID: $ID)
}
    `;
export type DeleteOutletTaxPolicyMutationFn = ApolloReactCommon.MutationFunction<DeleteOutletTaxPolicyMutation, DeleteOutletTaxPolicyMutationVariables>;

/**
 * __useDeleteOutletTaxPolicyMutation__
 *
 * To run a mutation, you first call `useDeleteOutletTaxPolicyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOutletTaxPolicyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOutletTaxPolicyMutation, { data, loading, error }] = useDeleteOutletTaxPolicyMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDeleteOutletTaxPolicyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteOutletTaxPolicyMutation, DeleteOutletTaxPolicyMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteOutletTaxPolicyMutation, DeleteOutletTaxPolicyMutationVariables>(DeleteOutletTaxPolicyDocument, baseOptions);
      }
export type DeleteOutletTaxPolicyMutationHookResult = ReturnType<typeof useDeleteOutletTaxPolicyMutation>;
export type DeleteOutletTaxPolicyMutationResult = ApolloReactCommon.MutationResult<DeleteOutletTaxPolicyMutation>;
export type DeleteOutletTaxPolicyMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteOutletTaxPolicyMutation, DeleteOutletTaxPolicyMutationVariables>;
export const UpdateOutletGovTaxPolicyDocument = gql`
    mutation updateOutletGovTaxPolicy($outletID: String!, $isInclusive: Boolean!) {
  updateOutletGovTaxPolicy(outletID: $outletID, isInclusive: $isInclusive)
}
    `;
export type UpdateOutletGovTaxPolicyMutationFn = ApolloReactCommon.MutationFunction<UpdateOutletGovTaxPolicyMutation, UpdateOutletGovTaxPolicyMutationVariables>;

/**
 * __useUpdateOutletGovTaxPolicyMutation__
 *
 * To run a mutation, you first call `useUpdateOutletGovTaxPolicyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOutletGovTaxPolicyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOutletGovTaxPolicyMutation, { data, loading, error }] = useUpdateOutletGovTaxPolicyMutation({
 *   variables: {
 *      outletID: // value for 'outletID'
 *      isInclusive: // value for 'isInclusive'
 *   },
 * });
 */
export function useUpdateOutletGovTaxPolicyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateOutletGovTaxPolicyMutation, UpdateOutletGovTaxPolicyMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateOutletGovTaxPolicyMutation, UpdateOutletGovTaxPolicyMutationVariables>(UpdateOutletGovTaxPolicyDocument, baseOptions);
      }
export type UpdateOutletGovTaxPolicyMutationHookResult = ReturnType<typeof useUpdateOutletGovTaxPolicyMutation>;
export type UpdateOutletGovTaxPolicyMutationResult = ApolloReactCommon.MutationResult<UpdateOutletGovTaxPolicyMutation>;
export type UpdateOutletGovTaxPolicyMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateOutletGovTaxPolicyMutation, UpdateOutletGovTaxPolicyMutationVariables>;
export const GetReasonDocument = gql`
    query getReason($ID: String, $outletID: String, $orderByAsc: String, $orderByDesc: String) {
  getReason(ID: $ID, outletID: $outletID, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc) {
    ID
    outletID
    name
    description
    createdTs
  }
}
    `;

/**
 * __useGetReasonQuery__
 *
 * To run a query within a React component, call `useGetReasonQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReasonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReasonQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      outletID: // value for 'outletID'
 *      orderByAsc: // value for 'orderByAsc'
 *      orderByDesc: // value for 'orderByDesc'
 *   },
 * });
 */
export function useGetReasonQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetReasonQuery, GetReasonQueryVariables>) {
        return ApolloReactHooks.useQuery<GetReasonQuery, GetReasonQueryVariables>(GetReasonDocument, baseOptions);
      }
export function useGetReasonLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetReasonQuery, GetReasonQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetReasonQuery, GetReasonQueryVariables>(GetReasonDocument, baseOptions);
        }
export type GetReasonQueryHookResult = ReturnType<typeof useGetReasonQuery>;
export type GetReasonLazyQueryHookResult = ReturnType<typeof useGetReasonLazyQuery>;
export type GetReasonQueryResult = ApolloReactCommon.QueryResult<GetReasonQuery, GetReasonQueryVariables>;
export const DeleteReasonDocument = gql`
    mutation deleteReason($ID: String!) {
  deleteReason(ID: $ID)
}
    `;
export type DeleteReasonMutationFn = ApolloReactCommon.MutationFunction<DeleteReasonMutation, DeleteReasonMutationVariables>;

/**
 * __useDeleteReasonMutation__
 *
 * To run a mutation, you first call `useDeleteReasonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteReasonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteReasonMutation, { data, loading, error }] = useDeleteReasonMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDeleteReasonMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteReasonMutation, DeleteReasonMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteReasonMutation, DeleteReasonMutationVariables>(DeleteReasonDocument, baseOptions);
      }
export type DeleteReasonMutationHookResult = ReturnType<typeof useDeleteReasonMutation>;
export type DeleteReasonMutationResult = ApolloReactCommon.MutationResult<DeleteReasonMutation>;
export type DeleteReasonMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteReasonMutation, DeleteReasonMutationVariables>;
export const CreateReasonDocument = gql`
    mutation createReason($input: ReasonInput!) {
  createReason(input: $input) {
    ID
    outletID
    name
    description
  }
}
    `;
export type CreateReasonMutationFn = ApolloReactCommon.MutationFunction<CreateReasonMutation, CreateReasonMutationVariables>;

/**
 * __useCreateReasonMutation__
 *
 * To run a mutation, you first call `useCreateReasonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReasonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReasonMutation, { data, loading, error }] = useCreateReasonMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateReasonMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateReasonMutation, CreateReasonMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateReasonMutation, CreateReasonMutationVariables>(CreateReasonDocument, baseOptions);
      }
export type CreateReasonMutationHookResult = ReturnType<typeof useCreateReasonMutation>;
export type CreateReasonMutationResult = ApolloReactCommon.MutationResult<CreateReasonMutation>;
export type CreateReasonMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateReasonMutation, CreateReasonMutationVariables>;
export const UpdateReasonDocument = gql`
    mutation updateReason($input: ReasonInput!) {
  updateReason(input: $input) {
    ID
    outletID
    name
    description
  }
}
    `;
export type UpdateReasonMutationFn = ApolloReactCommon.MutationFunction<UpdateReasonMutation, UpdateReasonMutationVariables>;

/**
 * __useUpdateReasonMutation__
 *
 * To run a mutation, you first call `useUpdateReasonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReasonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReasonMutation, { data, loading, error }] = useUpdateReasonMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateReasonMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateReasonMutation, UpdateReasonMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateReasonMutation, UpdateReasonMutationVariables>(UpdateReasonDocument, baseOptions);
      }
export type UpdateReasonMutationHookResult = ReturnType<typeof useUpdateReasonMutation>;
export type UpdateReasonMutationResult = ApolloReactCommon.MutationResult<UpdateReasonMutation>;
export type UpdateReasonMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateReasonMutation, UpdateReasonMutationVariables>;
export const UpdateOutletTaxSetupByTaxSchemeDocument = gql`
    mutation updateOutletTaxSetupByTaxScheme($outletID: String!, $taxSchemeID: String!) {
  updateOutletTaxSetupByTaxScheme(outletID: $outletID, taxSchemeID: $taxSchemeID)
}
    `;
export type UpdateOutletTaxSetupByTaxSchemeMutationFn = ApolloReactCommon.MutationFunction<UpdateOutletTaxSetupByTaxSchemeMutation, UpdateOutletTaxSetupByTaxSchemeMutationVariables>;

/**
 * __useUpdateOutletTaxSetupByTaxSchemeMutation__
 *
 * To run a mutation, you first call `useUpdateOutletTaxSetupByTaxSchemeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOutletTaxSetupByTaxSchemeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOutletTaxSetupByTaxSchemeMutation, { data, loading, error }] = useUpdateOutletTaxSetupByTaxSchemeMutation({
 *   variables: {
 *      outletID: // value for 'outletID'
 *      taxSchemeID: // value for 'taxSchemeID'
 *   },
 * });
 */
export function useUpdateOutletTaxSetupByTaxSchemeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateOutletTaxSetupByTaxSchemeMutation, UpdateOutletTaxSetupByTaxSchemeMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateOutletTaxSetupByTaxSchemeMutation, UpdateOutletTaxSetupByTaxSchemeMutationVariables>(UpdateOutletTaxSetupByTaxSchemeDocument, baseOptions);
      }
export type UpdateOutletTaxSetupByTaxSchemeMutationHookResult = ReturnType<typeof useUpdateOutletTaxSetupByTaxSchemeMutation>;
export type UpdateOutletTaxSetupByTaxSchemeMutationResult = ApolloReactCommon.MutationResult<UpdateOutletTaxSetupByTaxSchemeMutation>;
export type UpdateOutletTaxSetupByTaxSchemeMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateOutletTaxSetupByTaxSchemeMutation, UpdateOutletTaxSetupByTaxSchemeMutationVariables>;
export const GetOutletDocument = gql`
    query getOutlet($ID: String, $name: String, $accountID: String) {
  getOutlet(ID: $ID, name: $name, accountID: $accountID) {
    ID
    isActive
    name
    outletCode
    currencyCode
    registrationNo
    taxRegistrationNo
    businessDate
    contactNo
    email
    taxSchemeID
    logo
    gallery
    isInclusive
    integrateCityLedger
    capacity
    businessDate
    openingTime
    closingTime
    autoDayEndTime
    taxSchemeID
    outletChannel {
      ID
      url
      hotelID
    }
    operatingHours {
      openingTime
      closingTime
    }
    govTax
    serviceCharge
    tablesInfo
    address
    companyName
    companyID
    customerPolicy {
      selfOrder
      selfPayment
    }
    msicCode
    msicDesc
    eInvEffectiveDate
    generatedBillNo
    company {
      id
      name
      description
      companyRegNo
      code
      contactNo
      email
      sstNo
      gstNo
      ttxNo
      gstExpiryDate
      eInvClientID
      eInvClientSecret
      eInvClientSecret2
      regionId
      companyTax
      recordStatus
      groupGstRegNo
      officeTel
      tin
      eSign
      eSignExpiryDate
      isEInvIntegrated
      eInvStartDate
      eInvEndDate
    }
  }
}
    `;

/**
 * __useGetOutletQuery__
 *
 * To run a query within a React component, call `useGetOutletQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOutletQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOutletQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      name: // value for 'name'
 *      accountID: // value for 'accountID'
 *   },
 * });
 */
export function useGetOutletQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetOutletQuery, GetOutletQueryVariables>) {
        return ApolloReactHooks.useQuery<GetOutletQuery, GetOutletQueryVariables>(GetOutletDocument, baseOptions);
      }
export function useGetOutletLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetOutletQuery, GetOutletQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetOutletQuery, GetOutletQueryVariables>(GetOutletDocument, baseOptions);
        }
export type GetOutletQueryHookResult = ReturnType<typeof useGetOutletQuery>;
export type GetOutletLazyQueryHookResult = ReturnType<typeof useGetOutletLazyQuery>;
export type GetOutletQueryResult = ApolloReactCommon.QueryResult<GetOutletQuery, GetOutletQueryVariables>;
export const GetOutletNameDocument = gql`
    query getOutletName($ID: String) {
  getOutlet(ID: $ID) {
    ID
    name
    businessDate
    outletCode
    currencyCode
    taxSchemeID
    taxRegistrationNo
    outletChannel {
      hotelID
    }
    integrateCityLedger
    contactNo
    address
    logo
    taxRegistrationNo
    customerPolicy {
      selfOrder
      selfPayment
    }
  }
}
    `;

/**
 * __useGetOutletNameQuery__
 *
 * To run a query within a React component, call `useGetOutletNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOutletNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOutletNameQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useGetOutletNameQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetOutletNameQuery, GetOutletNameQueryVariables>) {
        return ApolloReactHooks.useQuery<GetOutletNameQuery, GetOutletNameQueryVariables>(GetOutletNameDocument, baseOptions);
      }
export function useGetOutletNameLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetOutletNameQuery, GetOutletNameQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetOutletNameQuery, GetOutletNameQueryVariables>(GetOutletNameDocument, baseOptions);
        }
export type GetOutletNameQueryHookResult = ReturnType<typeof useGetOutletNameQuery>;
export type GetOutletNameLazyQueryHookResult = ReturnType<typeof useGetOutletNameLazyQuery>;
export type GetOutletNameQueryResult = ApolloReactCommon.QueryResult<GetOutletNameQuery, GetOutletNameQueryVariables>;
export const GetAssignedOutletByUserDocument = gql`
    query getAssignedOutletByUser {
  getAssignedOutletByUser {
    ID
    name
    logo
    tablesInfo
    companyName
    businessDate
    currencyCode
    outletChannel {
      ID
      url
      hotelID
    }
    customerPolicy {
      selfOrder
      selfPayment
    }
  }
}
    `;

/**
 * __useGetAssignedOutletByUserQuery__
 *
 * To run a query within a React component, call `useGetAssignedOutletByUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssignedOutletByUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssignedOutletByUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAssignedOutletByUserQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAssignedOutletByUserQuery, GetAssignedOutletByUserQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAssignedOutletByUserQuery, GetAssignedOutletByUserQueryVariables>(GetAssignedOutletByUserDocument, baseOptions);
      }
export function useGetAssignedOutletByUserLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAssignedOutletByUserQuery, GetAssignedOutletByUserQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAssignedOutletByUserQuery, GetAssignedOutletByUserQueryVariables>(GetAssignedOutletByUserDocument, baseOptions);
        }
export type GetAssignedOutletByUserQueryHookResult = ReturnType<typeof useGetAssignedOutletByUserQuery>;
export type GetAssignedOutletByUserLazyQueryHookResult = ReturnType<typeof useGetAssignedOutletByUserLazyQuery>;
export type GetAssignedOutletByUserQueryResult = ApolloReactCommon.QueryResult<GetAssignedOutletByUserQuery, GetAssignedOutletByUserQueryVariables>;
export const CreateOutletDocument = gql`
    mutation createOutlet($outletInput: OutletInput!) {
  createOutlet(outletInput: $outletInput)
}
    `;
export type CreateOutletMutationFn = ApolloReactCommon.MutationFunction<CreateOutletMutation, CreateOutletMutationVariables>;

/**
 * __useCreateOutletMutation__
 *
 * To run a mutation, you first call `useCreateOutletMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOutletMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOutletMutation, { data, loading, error }] = useCreateOutletMutation({
 *   variables: {
 *      outletInput: // value for 'outletInput'
 *   },
 * });
 */
export function useCreateOutletMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateOutletMutation, CreateOutletMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateOutletMutation, CreateOutletMutationVariables>(CreateOutletDocument, baseOptions);
      }
export type CreateOutletMutationHookResult = ReturnType<typeof useCreateOutletMutation>;
export type CreateOutletMutationResult = ApolloReactCommon.MutationResult<CreateOutletMutation>;
export type CreateOutletMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateOutletMutation, CreateOutletMutationVariables>;
export const UpdateOutletDocument = gql`
    mutation updateOutlet($outletInput: OutletInput!, $opHourInput: [OperatingHoursInput!]!) {
  updateOutlet(outletInput: $outletInput, opHourInput: $opHourInput)
}
    `;
export type UpdateOutletMutationFn = ApolloReactCommon.MutationFunction<UpdateOutletMutation, UpdateOutletMutationVariables>;

/**
 * __useUpdateOutletMutation__
 *
 * To run a mutation, you first call `useUpdateOutletMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOutletMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOutletMutation, { data, loading, error }] = useUpdateOutletMutation({
 *   variables: {
 *      outletInput: // value for 'outletInput'
 *      opHourInput: // value for 'opHourInput'
 *   },
 * });
 */
export function useUpdateOutletMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateOutletMutation, UpdateOutletMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateOutletMutation, UpdateOutletMutationVariables>(UpdateOutletDocument, baseOptions);
      }
export type UpdateOutletMutationHookResult = ReturnType<typeof useUpdateOutletMutation>;
export type UpdateOutletMutationResult = ApolloReactCommon.MutationResult<UpdateOutletMutation>;
export type UpdateOutletMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateOutletMutation, UpdateOutletMutationVariables>;
export const GetHotelBySubscriptionDocument = gql`
    query getHotelBySubscription {
  getHotelBySubscription
}
    `;

/**
 * __useGetHotelBySubscriptionQuery__
 *
 * To run a query within a React component, call `useGetHotelBySubscriptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHotelBySubscriptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHotelBySubscriptionQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetHotelBySubscriptionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetHotelBySubscriptionQuery, GetHotelBySubscriptionQueryVariables>) {
        return ApolloReactHooks.useQuery<GetHotelBySubscriptionQuery, GetHotelBySubscriptionQueryVariables>(GetHotelBySubscriptionDocument, baseOptions);
      }
export function useGetHotelBySubscriptionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetHotelBySubscriptionQuery, GetHotelBySubscriptionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetHotelBySubscriptionQuery, GetHotelBySubscriptionQueryVariables>(GetHotelBySubscriptionDocument, baseOptions);
        }
export type GetHotelBySubscriptionQueryHookResult = ReturnType<typeof useGetHotelBySubscriptionQuery>;
export type GetHotelBySubscriptionLazyQueryHookResult = ReturnType<typeof useGetHotelBySubscriptionLazyQuery>;
export type GetHotelBySubscriptionQueryResult = ApolloReactCommon.QueryResult<GetHotelBySubscriptionQuery, GetHotelBySubscriptionQueryVariables>;
export const GetMsicCodeListingDocument = gql`
    query getMsicCodeListing {
  getMsicCodeListing {
    id
    msicCode
    description
    categoryReference
  }
}
    `;

/**
 * __useGetMsicCodeListingQuery__
 *
 * To run a query within a React component, call `useGetMsicCodeListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMsicCodeListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMsicCodeListingQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMsicCodeListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMsicCodeListingQuery, GetMsicCodeListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetMsicCodeListingQuery, GetMsicCodeListingQueryVariables>(GetMsicCodeListingDocument, baseOptions);
      }
export function useGetMsicCodeListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMsicCodeListingQuery, GetMsicCodeListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetMsicCodeListingQuery, GetMsicCodeListingQueryVariables>(GetMsicCodeListingDocument, baseOptions);
        }
export type GetMsicCodeListingQueryHookResult = ReturnType<typeof useGetMsicCodeListingQuery>;
export type GetMsicCodeListingLazyQueryHookResult = ReturnType<typeof useGetMsicCodeListingLazyQuery>;
export type GetMsicCodeListingQueryResult = ApolloReactCommon.QueryResult<GetMsicCodeListingQuery, GetMsicCodeListingQueryVariables>;
export const SubmitDefaultPaymentDocument = gql`
    mutation submitDefaultPayment($defaultPaymentIDs: [String!]!, $outletID: String!) {
  submitDefaultPayment(defaultPaymentIDs: $defaultPaymentIDs, outletID: $outletID)
}
    `;
export type SubmitDefaultPaymentMutationFn = ApolloReactCommon.MutationFunction<SubmitDefaultPaymentMutation, SubmitDefaultPaymentMutationVariables>;

/**
 * __useSubmitDefaultPaymentMutation__
 *
 * To run a mutation, you first call `useSubmitDefaultPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitDefaultPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitDefaultPaymentMutation, { data, loading, error }] = useSubmitDefaultPaymentMutation({
 *   variables: {
 *      defaultPaymentIDs: // value for 'defaultPaymentIDs'
 *      outletID: // value for 'outletID'
 *   },
 * });
 */
export function useSubmitDefaultPaymentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SubmitDefaultPaymentMutation, SubmitDefaultPaymentMutationVariables>) {
        return ApolloReactHooks.useMutation<SubmitDefaultPaymentMutation, SubmitDefaultPaymentMutationVariables>(SubmitDefaultPaymentDocument, baseOptions);
      }
export type SubmitDefaultPaymentMutationHookResult = ReturnType<typeof useSubmitDefaultPaymentMutation>;
export type SubmitDefaultPaymentMutationResult = ApolloReactCommon.MutationResult<SubmitDefaultPaymentMutation>;
export type SubmitDefaultPaymentMutationOptions = ApolloReactCommon.BaseMutationOptions<SubmitDefaultPaymentMutation, SubmitDefaultPaymentMutationVariables>;
export const GetDefaultPaymentDocument = gql`
    query getDefaultPayment($ID: String, $outletID: String, $orderByDesc: String, $orderByAsc: String) {
  getDefaultPayment(ID: $ID, outletID: $outletID, orderByDesc: $orderByDesc, orderByAsc: $orderByAsc) {
    ID
    isActive
    outletID
    paymentTypeID
    isDefault
    paymentClass
    paymentTypeInfo
  }
}
    `;

/**
 * __useGetDefaultPaymentQuery__
 *
 * To run a query within a React component, call `useGetDefaultPaymentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDefaultPaymentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDefaultPaymentQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      outletID: // value for 'outletID'
 *      orderByDesc: // value for 'orderByDesc'
 *      orderByAsc: // value for 'orderByAsc'
 *   },
 * });
 */
export function useGetDefaultPaymentQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDefaultPaymentQuery, GetDefaultPaymentQueryVariables>) {
        return ApolloReactHooks.useQuery<GetDefaultPaymentQuery, GetDefaultPaymentQueryVariables>(GetDefaultPaymentDocument, baseOptions);
      }
export function useGetDefaultPaymentLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDefaultPaymentQuery, GetDefaultPaymentQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetDefaultPaymentQuery, GetDefaultPaymentQueryVariables>(GetDefaultPaymentDocument, baseOptions);
        }
export type GetDefaultPaymentQueryHookResult = ReturnType<typeof useGetDefaultPaymentQuery>;
export type GetDefaultPaymentLazyQueryHookResult = ReturnType<typeof useGetDefaultPaymentLazyQuery>;
export type GetDefaultPaymentQueryResult = ApolloReactCommon.QueryResult<GetDefaultPaymentQuery, GetDefaultPaymentQueryVariables>;
export const GetPaymentTypeDocument = gql`
    query getPaymentType($ID: String) {
  getPaymentType(ID: $ID) {
    ID
    description
    paymentClass
  }
}
    `;

/**
 * __useGetPaymentTypeQuery__
 *
 * To run a query within a React component, call `useGetPaymentTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaymentTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaymentTypeQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useGetPaymentTypeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPaymentTypeQuery, GetPaymentTypeQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPaymentTypeQuery, GetPaymentTypeQueryVariables>(GetPaymentTypeDocument, baseOptions);
      }
export function useGetPaymentTypeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPaymentTypeQuery, GetPaymentTypeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPaymentTypeQuery, GetPaymentTypeQueryVariables>(GetPaymentTypeDocument, baseOptions);
        }
export type GetPaymentTypeQueryHookResult = ReturnType<typeof useGetPaymentTypeQuery>;
export type GetPaymentTypeLazyQueryHookResult = ReturnType<typeof useGetPaymentTypeLazyQuery>;
export type GetPaymentTypeQueryResult = ApolloReactCommon.QueryResult<GetPaymentTypeQuery, GetPaymentTypeQueryVariables>;
export const CreatePaymentTypeDocument = gql`
    mutation createPaymentType($input: PaymentTypeInput!) {
  createPaymentType(input: $input) {
    ID
  }
}
    `;
export type CreatePaymentTypeMutationFn = ApolloReactCommon.MutationFunction<CreatePaymentTypeMutation, CreatePaymentTypeMutationVariables>;

/**
 * __useCreatePaymentTypeMutation__
 *
 * To run a mutation, you first call `useCreatePaymentTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePaymentTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPaymentTypeMutation, { data, loading, error }] = useCreatePaymentTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePaymentTypeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreatePaymentTypeMutation, CreatePaymentTypeMutationVariables>) {
        return ApolloReactHooks.useMutation<CreatePaymentTypeMutation, CreatePaymentTypeMutationVariables>(CreatePaymentTypeDocument, baseOptions);
      }
export type CreatePaymentTypeMutationHookResult = ReturnType<typeof useCreatePaymentTypeMutation>;
export type CreatePaymentTypeMutationResult = ApolloReactCommon.MutationResult<CreatePaymentTypeMutation>;
export type CreatePaymentTypeMutationOptions = ApolloReactCommon.BaseMutationOptions<CreatePaymentTypeMutation, CreatePaymentTypeMutationVariables>;
export const UpdatePaymentTypeDocument = gql`
    mutation updatePaymentType($input: PaymentTypeInput!) {
  updatePaymentType(input: $input) {
    ID
  }
}
    `;
export type UpdatePaymentTypeMutationFn = ApolloReactCommon.MutationFunction<UpdatePaymentTypeMutation, UpdatePaymentTypeMutationVariables>;

/**
 * __useUpdatePaymentTypeMutation__
 *
 * To run a mutation, you first call `useUpdatePaymentTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePaymentTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePaymentTypeMutation, { data, loading, error }] = useUpdatePaymentTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePaymentTypeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdatePaymentTypeMutation, UpdatePaymentTypeMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdatePaymentTypeMutation, UpdatePaymentTypeMutationVariables>(UpdatePaymentTypeDocument, baseOptions);
      }
export type UpdatePaymentTypeMutationHookResult = ReturnType<typeof useUpdatePaymentTypeMutation>;
export type UpdatePaymentTypeMutationResult = ApolloReactCommon.MutationResult<UpdatePaymentTypeMutation>;
export type UpdatePaymentTypeMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdatePaymentTypeMutation, UpdatePaymentTypeMutationVariables>;
export const DeletePaymentTypeDocument = gql`
    mutation deletePaymentType($ID: String!) {
  deletePaymentType(ID: $ID)
}
    `;
export type DeletePaymentTypeMutationFn = ApolloReactCommon.MutationFunction<DeletePaymentTypeMutation, DeletePaymentTypeMutationVariables>;

/**
 * __useDeletePaymentTypeMutation__
 *
 * To run a mutation, you first call `useDeletePaymentTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePaymentTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePaymentTypeMutation, { data, loading, error }] = useDeletePaymentTypeMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDeletePaymentTypeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeletePaymentTypeMutation, DeletePaymentTypeMutationVariables>) {
        return ApolloReactHooks.useMutation<DeletePaymentTypeMutation, DeletePaymentTypeMutationVariables>(DeletePaymentTypeDocument, baseOptions);
      }
export type DeletePaymentTypeMutationHookResult = ReturnType<typeof useDeletePaymentTypeMutation>;
export type DeletePaymentTypeMutationResult = ApolloReactCommon.MutationResult<DeletePaymentTypeMutation>;
export type DeletePaymentTypeMutationOptions = ApolloReactCommon.BaseMutationOptions<DeletePaymentTypeMutation, DeletePaymentTypeMutationVariables>;
export const GetPromoCodeDocument = gql`
    query getPromoCode($ID: String, $outletID: String) {
  getPromoCode(ID: $ID, outletID: $outletID) {
    ID
    code
    description
    amount
    validFrom
    validTill
    calcType
  }
}
    `;

/**
 * __useGetPromoCodeQuery__
 *
 * To run a query within a React component, call `useGetPromoCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPromoCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPromoCodeQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      outletID: // value for 'outletID'
 *   },
 * });
 */
export function useGetPromoCodeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPromoCodeQuery, GetPromoCodeQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPromoCodeQuery, GetPromoCodeQueryVariables>(GetPromoCodeDocument, baseOptions);
      }
export function useGetPromoCodeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPromoCodeQuery, GetPromoCodeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPromoCodeQuery, GetPromoCodeQueryVariables>(GetPromoCodeDocument, baseOptions);
        }
export type GetPromoCodeQueryHookResult = ReturnType<typeof useGetPromoCodeQuery>;
export type GetPromoCodeLazyQueryHookResult = ReturnType<typeof useGetPromoCodeLazyQuery>;
export type GetPromoCodeQueryResult = ApolloReactCommon.QueryResult<GetPromoCodeQuery, GetPromoCodeQueryVariables>;
export const CreatePromoCodeDocument = gql`
    mutation createPromoCode($input: PromoCodeInput!) {
  createPromoCode(input: $input) {
    ID
  }
}
    `;
export type CreatePromoCodeMutationFn = ApolloReactCommon.MutationFunction<CreatePromoCodeMutation, CreatePromoCodeMutationVariables>;

/**
 * __useCreatePromoCodeMutation__
 *
 * To run a mutation, you first call `useCreatePromoCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePromoCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPromoCodeMutation, { data, loading, error }] = useCreatePromoCodeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePromoCodeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreatePromoCodeMutation, CreatePromoCodeMutationVariables>) {
        return ApolloReactHooks.useMutation<CreatePromoCodeMutation, CreatePromoCodeMutationVariables>(CreatePromoCodeDocument, baseOptions);
      }
export type CreatePromoCodeMutationHookResult = ReturnType<typeof useCreatePromoCodeMutation>;
export type CreatePromoCodeMutationResult = ApolloReactCommon.MutationResult<CreatePromoCodeMutation>;
export type CreatePromoCodeMutationOptions = ApolloReactCommon.BaseMutationOptions<CreatePromoCodeMutation, CreatePromoCodeMutationVariables>;
export const UpdatePromoCodeDocument = gql`
    mutation updatePromoCode($input: PromoCodeInput!) {
  updatePromoCode(input: $input) {
    ID
  }
}
    `;
export type UpdatePromoCodeMutationFn = ApolloReactCommon.MutationFunction<UpdatePromoCodeMutation, UpdatePromoCodeMutationVariables>;

/**
 * __useUpdatePromoCodeMutation__
 *
 * To run a mutation, you first call `useUpdatePromoCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePromoCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePromoCodeMutation, { data, loading, error }] = useUpdatePromoCodeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePromoCodeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdatePromoCodeMutation, UpdatePromoCodeMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdatePromoCodeMutation, UpdatePromoCodeMutationVariables>(UpdatePromoCodeDocument, baseOptions);
      }
export type UpdatePromoCodeMutationHookResult = ReturnType<typeof useUpdatePromoCodeMutation>;
export type UpdatePromoCodeMutationResult = ApolloReactCommon.MutationResult<UpdatePromoCodeMutation>;
export type UpdatePromoCodeMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdatePromoCodeMutation, UpdatePromoCodeMutationVariables>;
export const DeletePromoCodeDocument = gql`
    mutation deletePromoCode($ID: String!) {
  deletePromoCode(ID: $ID)
}
    `;
export type DeletePromoCodeMutationFn = ApolloReactCommon.MutationFunction<DeletePromoCodeMutation, DeletePromoCodeMutationVariables>;

/**
 * __useDeletePromoCodeMutation__
 *
 * To run a mutation, you first call `useDeletePromoCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePromoCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePromoCodeMutation, { data, loading, error }] = useDeletePromoCodeMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDeletePromoCodeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeletePromoCodeMutation, DeletePromoCodeMutationVariables>) {
        return ApolloReactHooks.useMutation<DeletePromoCodeMutation, DeletePromoCodeMutationVariables>(DeletePromoCodeDocument, baseOptions);
      }
export type DeletePromoCodeMutationHookResult = ReturnType<typeof useDeletePromoCodeMutation>;
export type DeletePromoCodeMutationResult = ApolloReactCommon.MutationResult<DeletePromoCodeMutation>;
export type DeletePromoCodeMutationOptions = ApolloReactCommon.BaseMutationOptions<DeletePromoCodeMutation, DeletePromoCodeMutationVariables>;
export const GetPromoMenuDocument = gql`
    query getPromoMenu($ID: String, $name: String, $outletID: String) {
  getPromoMenu(ID: $ID, name: $name, outletID: $outletID) {
    ID
    name
    description
    frequencyType
    validityStartTs
    validityEndTs
    recurStartTime
    recurEndTime
    recurOnceAt
    recurEveryXDays
    recurEveryXWeeks
    recurEveryXMonths
    recurAtDayXOfMonth
    recurWeekBitmap
    recurDayBitmap
    promoMenuItem {
      ID
      menuItemID
    }
  }
}
    `;

/**
 * __useGetPromoMenuQuery__
 *
 * To run a query within a React component, call `useGetPromoMenuQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPromoMenuQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPromoMenuQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      name: // value for 'name'
 *      outletID: // value for 'outletID'
 *   },
 * });
 */
export function useGetPromoMenuQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPromoMenuQuery, GetPromoMenuQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPromoMenuQuery, GetPromoMenuQueryVariables>(GetPromoMenuDocument, baseOptions);
      }
export function useGetPromoMenuLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPromoMenuQuery, GetPromoMenuQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPromoMenuQuery, GetPromoMenuQueryVariables>(GetPromoMenuDocument, baseOptions);
        }
export type GetPromoMenuQueryHookResult = ReturnType<typeof useGetPromoMenuQuery>;
export type GetPromoMenuLazyQueryHookResult = ReturnType<typeof useGetPromoMenuLazyQuery>;
export type GetPromoMenuQueryResult = ApolloReactCommon.QueryResult<GetPromoMenuQuery, GetPromoMenuQueryVariables>;
export const GetPromoMenuForPriceDocument = gql`
    query getPromoMenuForPrice($ID: String) {
  getPromoMenu(ID: $ID) {
    ID
    createdTs
    promoMenuItem {
      ID
      promoMenuID
      menuItemID
      menuItem {
        ID
        name
        description
      }
      promoMenuItemPrice {
        ID
        promoMenuItemID
        price
        promoMenuItem {
          ID
          menuItemID
          menuItem {
            name
          }
        }
        patronID
        patron {
          ID
          patronClass
          isActive
          patronType {
            ID
            name
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetPromoMenuForPriceQuery__
 *
 * To run a query within a React component, call `useGetPromoMenuForPriceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPromoMenuForPriceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPromoMenuForPriceQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useGetPromoMenuForPriceQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPromoMenuForPriceQuery, GetPromoMenuForPriceQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPromoMenuForPriceQuery, GetPromoMenuForPriceQueryVariables>(GetPromoMenuForPriceDocument, baseOptions);
      }
export function useGetPromoMenuForPriceLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPromoMenuForPriceQuery, GetPromoMenuForPriceQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPromoMenuForPriceQuery, GetPromoMenuForPriceQueryVariables>(GetPromoMenuForPriceDocument, baseOptions);
        }
export type GetPromoMenuForPriceQueryHookResult = ReturnType<typeof useGetPromoMenuForPriceQuery>;
export type GetPromoMenuForPriceLazyQueryHookResult = ReturnType<typeof useGetPromoMenuForPriceLazyQuery>;
export type GetPromoMenuForPriceQueryResult = ApolloReactCommon.QueryResult<GetPromoMenuForPriceQuery, GetPromoMenuForPriceQueryVariables>;
export const GetPromoMenuItemDocument = gql`
    query getPromoMenuItem($ID: String, $outletID: String, $promoMenuID: String) {
  getPromoMenuItem(ID: $ID, outletID: $outletID, promoMenuID: $promoMenuID) {
    ID
    promoMenuID
    menuItemID
    outletID
    menuItem {
      ID
      name
      description
    }
    promoMenuItemPrice {
      ID
      patronID
      price
      patronClass
      patron {
        ID
        patronClass
        isActive
        patronType {
          ID
          name
        }
      }
    }
  }
}
    `;

/**
 * __useGetPromoMenuItemQuery__
 *
 * To run a query within a React component, call `useGetPromoMenuItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPromoMenuItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPromoMenuItemQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      outletID: // value for 'outletID'
 *      promoMenuID: // value for 'promoMenuID'
 *   },
 * });
 */
export function useGetPromoMenuItemQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPromoMenuItemQuery, GetPromoMenuItemQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPromoMenuItemQuery, GetPromoMenuItemQueryVariables>(GetPromoMenuItemDocument, baseOptions);
      }
export function useGetPromoMenuItemLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPromoMenuItemQuery, GetPromoMenuItemQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPromoMenuItemQuery, GetPromoMenuItemQueryVariables>(GetPromoMenuItemDocument, baseOptions);
        }
export type GetPromoMenuItemQueryHookResult = ReturnType<typeof useGetPromoMenuItemQuery>;
export type GetPromoMenuItemLazyQueryHookResult = ReturnType<typeof useGetPromoMenuItemLazyQuery>;
export type GetPromoMenuItemQueryResult = ApolloReactCommon.QueryResult<GetPromoMenuItemQuery, GetPromoMenuItemQueryVariables>;
export const CreatePromoMenuDocument = gql`
    mutation createPromoMenu($input: PromoMenuInput!) {
  createPromoMenu(input: $input) {
    ID
    name
    description
    outletID
    frequencyType
    validityStartTs
    validityEndTs
    recurStartTime
    recurEndTime
    recurOnceAt
    recurEveryXDays
    recurEveryXWeeks
    recurEveryXMonths
    recurAtDayXOfMonth
    recurWeekBitmap
    recurDayBitmap
  }
}
    `;
export type CreatePromoMenuMutationFn = ApolloReactCommon.MutationFunction<CreatePromoMenuMutation, CreatePromoMenuMutationVariables>;

/**
 * __useCreatePromoMenuMutation__
 *
 * To run a mutation, you first call `useCreatePromoMenuMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePromoMenuMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPromoMenuMutation, { data, loading, error }] = useCreatePromoMenuMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePromoMenuMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreatePromoMenuMutation, CreatePromoMenuMutationVariables>) {
        return ApolloReactHooks.useMutation<CreatePromoMenuMutation, CreatePromoMenuMutationVariables>(CreatePromoMenuDocument, baseOptions);
      }
export type CreatePromoMenuMutationHookResult = ReturnType<typeof useCreatePromoMenuMutation>;
export type CreatePromoMenuMutationResult = ApolloReactCommon.MutationResult<CreatePromoMenuMutation>;
export type CreatePromoMenuMutationOptions = ApolloReactCommon.BaseMutationOptions<CreatePromoMenuMutation, CreatePromoMenuMutationVariables>;
export const UpdatePromoMenuDocument = gql`
    mutation updatePromoMenu($input: PromoMenuInput!) {
  updatePromoMenu(input: $input) {
    ID
  }
}
    `;
export type UpdatePromoMenuMutationFn = ApolloReactCommon.MutationFunction<UpdatePromoMenuMutation, UpdatePromoMenuMutationVariables>;

/**
 * __useUpdatePromoMenuMutation__
 *
 * To run a mutation, you first call `useUpdatePromoMenuMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePromoMenuMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePromoMenuMutation, { data, loading, error }] = useUpdatePromoMenuMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePromoMenuMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdatePromoMenuMutation, UpdatePromoMenuMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdatePromoMenuMutation, UpdatePromoMenuMutationVariables>(UpdatePromoMenuDocument, baseOptions);
      }
export type UpdatePromoMenuMutationHookResult = ReturnType<typeof useUpdatePromoMenuMutation>;
export type UpdatePromoMenuMutationResult = ApolloReactCommon.MutationResult<UpdatePromoMenuMutation>;
export type UpdatePromoMenuMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdatePromoMenuMutation, UpdatePromoMenuMutationVariables>;
export const DeletePromoMenuDocument = gql`
    mutation deletePromoMenu($ID: String!) {
  deletePromoMenu(ID: $ID)
}
    `;
export type DeletePromoMenuMutationFn = ApolloReactCommon.MutationFunction<DeletePromoMenuMutation, DeletePromoMenuMutationVariables>;

/**
 * __useDeletePromoMenuMutation__
 *
 * To run a mutation, you first call `useDeletePromoMenuMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePromoMenuMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePromoMenuMutation, { data, loading, error }] = useDeletePromoMenuMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDeletePromoMenuMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeletePromoMenuMutation, DeletePromoMenuMutationVariables>) {
        return ApolloReactHooks.useMutation<DeletePromoMenuMutation, DeletePromoMenuMutationVariables>(DeletePromoMenuDocument, baseOptions);
      }
export type DeletePromoMenuMutationHookResult = ReturnType<typeof useDeletePromoMenuMutation>;
export type DeletePromoMenuMutationResult = ApolloReactCommon.MutationResult<DeletePromoMenuMutation>;
export type DeletePromoMenuMutationOptions = ApolloReactCommon.BaseMutationOptions<DeletePromoMenuMutation, DeletePromoMenuMutationVariables>;
export const CreatePromoMenuItemPriceDocument = gql`
    mutation createPromoMenuItemPrice($outletID: String!, $promoMenuID: String!, $promoMenuItemInput: [PromoMenuItemInput!]!) {
  createPromoMenuItemPrice(outletID: $outletID, promoMenuID: $promoMenuID, promoMenuItemInput: $promoMenuItemInput)
}
    `;
export type CreatePromoMenuItemPriceMutationFn = ApolloReactCommon.MutationFunction<CreatePromoMenuItemPriceMutation, CreatePromoMenuItemPriceMutationVariables>;

/**
 * __useCreatePromoMenuItemPriceMutation__
 *
 * To run a mutation, you first call `useCreatePromoMenuItemPriceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePromoMenuItemPriceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPromoMenuItemPriceMutation, { data, loading, error }] = useCreatePromoMenuItemPriceMutation({
 *   variables: {
 *      outletID: // value for 'outletID'
 *      promoMenuID: // value for 'promoMenuID'
 *      promoMenuItemInput: // value for 'promoMenuItemInput'
 *   },
 * });
 */
export function useCreatePromoMenuItemPriceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreatePromoMenuItemPriceMutation, CreatePromoMenuItemPriceMutationVariables>) {
        return ApolloReactHooks.useMutation<CreatePromoMenuItemPriceMutation, CreatePromoMenuItemPriceMutationVariables>(CreatePromoMenuItemPriceDocument, baseOptions);
      }
export type CreatePromoMenuItemPriceMutationHookResult = ReturnType<typeof useCreatePromoMenuItemPriceMutation>;
export type CreatePromoMenuItemPriceMutationResult = ApolloReactCommon.MutationResult<CreatePromoMenuItemPriceMutation>;
export type CreatePromoMenuItemPriceMutationOptions = ApolloReactCommon.BaseMutationOptions<CreatePromoMenuItemPriceMutation, CreatePromoMenuItemPriceMutationVariables>;
export const PromoMenuItemCheckingDocument = gql`
    query promoMenuItemChecking($promoMenuID: String!, $menuItemIDs: [String!]!) {
  promoMenuItemChecking(promoMenuID: $promoMenuID, menuItemIDs: $menuItemIDs) {
    ID
    menuItemID
    menuItem {
      ID
      name
      description
    }
    promoMenu {
      ID
      name
    }
  }
}
    `;

/**
 * __usePromoMenuItemCheckingQuery__
 *
 * To run a query within a React component, call `usePromoMenuItemCheckingQuery` and pass it any options that fit your needs.
 * When your component renders, `usePromoMenuItemCheckingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePromoMenuItemCheckingQuery({
 *   variables: {
 *      promoMenuID: // value for 'promoMenuID'
 *      menuItemIDs: // value for 'menuItemIDs'
 *   },
 * });
 */
export function usePromoMenuItemCheckingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PromoMenuItemCheckingQuery, PromoMenuItemCheckingQueryVariables>) {
        return ApolloReactHooks.useQuery<PromoMenuItemCheckingQuery, PromoMenuItemCheckingQueryVariables>(PromoMenuItemCheckingDocument, baseOptions);
      }
export function usePromoMenuItemCheckingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PromoMenuItemCheckingQuery, PromoMenuItemCheckingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PromoMenuItemCheckingQuery, PromoMenuItemCheckingQueryVariables>(PromoMenuItemCheckingDocument, baseOptions);
        }
export type PromoMenuItemCheckingQueryHookResult = ReturnType<typeof usePromoMenuItemCheckingQuery>;
export type PromoMenuItemCheckingLazyQueryHookResult = ReturnType<typeof usePromoMenuItemCheckingLazyQuery>;
export type PromoMenuItemCheckingQueryResult = ApolloReactCommon.QueryResult<PromoMenuItemCheckingQuery, PromoMenuItemCheckingQueryVariables>;
export const UpdatePromoMenuItemPriceDocument = gql`
    mutation updatePromoMenuItemPrice($outletID: String!, $promoMenuItemID: String!, $promoMenuItemPriceInput: [PromoMenuItemPriceInput!]!) {
  updatePromoMenuItemPrice(outletID: $outletID, promoMenuItemID: $promoMenuItemID, promoMenuItemPriceInput: $promoMenuItemPriceInput)
}
    `;
export type UpdatePromoMenuItemPriceMutationFn = ApolloReactCommon.MutationFunction<UpdatePromoMenuItemPriceMutation, UpdatePromoMenuItemPriceMutationVariables>;

/**
 * __useUpdatePromoMenuItemPriceMutation__
 *
 * To run a mutation, you first call `useUpdatePromoMenuItemPriceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePromoMenuItemPriceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePromoMenuItemPriceMutation, { data, loading, error }] = useUpdatePromoMenuItemPriceMutation({
 *   variables: {
 *      outletID: // value for 'outletID'
 *      promoMenuItemID: // value for 'promoMenuItemID'
 *      promoMenuItemPriceInput: // value for 'promoMenuItemPriceInput'
 *   },
 * });
 */
export function useUpdatePromoMenuItemPriceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdatePromoMenuItemPriceMutation, UpdatePromoMenuItemPriceMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdatePromoMenuItemPriceMutation, UpdatePromoMenuItemPriceMutationVariables>(UpdatePromoMenuItemPriceDocument, baseOptions);
      }
export type UpdatePromoMenuItemPriceMutationHookResult = ReturnType<typeof useUpdatePromoMenuItemPriceMutation>;
export type UpdatePromoMenuItemPriceMutationResult = ApolloReactCommon.MutationResult<UpdatePromoMenuItemPriceMutation>;
export type UpdatePromoMenuItemPriceMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdatePromoMenuItemPriceMutation, UpdatePromoMenuItemPriceMutationVariables>;
export const DeletePromoMenuItemAndPriceDocument = gql`
    mutation deletePromoMenuItemAndPrice($promoMenuItemID: String!) {
  deletePromoMenuItemAndPrice(promoMenuItemID: $promoMenuItemID)
}
    `;
export type DeletePromoMenuItemAndPriceMutationFn = ApolloReactCommon.MutationFunction<DeletePromoMenuItemAndPriceMutation, DeletePromoMenuItemAndPriceMutationVariables>;

/**
 * __useDeletePromoMenuItemAndPriceMutation__
 *
 * To run a mutation, you first call `useDeletePromoMenuItemAndPriceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePromoMenuItemAndPriceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePromoMenuItemAndPriceMutation, { data, loading, error }] = useDeletePromoMenuItemAndPriceMutation({
 *   variables: {
 *      promoMenuItemID: // value for 'promoMenuItemID'
 *   },
 * });
 */
export function useDeletePromoMenuItemAndPriceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeletePromoMenuItemAndPriceMutation, DeletePromoMenuItemAndPriceMutationVariables>) {
        return ApolloReactHooks.useMutation<DeletePromoMenuItemAndPriceMutation, DeletePromoMenuItemAndPriceMutationVariables>(DeletePromoMenuItemAndPriceDocument, baseOptions);
      }
export type DeletePromoMenuItemAndPriceMutationHookResult = ReturnType<typeof useDeletePromoMenuItemAndPriceMutation>;
export type DeletePromoMenuItemAndPriceMutationResult = ApolloReactCommon.MutationResult<DeletePromoMenuItemAndPriceMutation>;
export type DeletePromoMenuItemAndPriceMutationOptions = ApolloReactCommon.BaseMutationOptions<DeletePromoMenuItemAndPriceMutation, DeletePromoMenuItemAndPriceMutationVariables>;
export const GetRemainingMenuItemsForPromoDocument = gql`
    query getRemainingMenuItemsForPromo($promoMenuID: String!, $outletID: String!) {
  getMenuItemForPromoMenuItemPrice(promoMenuID: $promoMenuID, outletID: $outletID) {
    ID
    name
    description
    kitchenName
    nativeName
  }
}
    `;

/**
 * __useGetRemainingMenuItemsForPromoQuery__
 *
 * To run a query within a React component, call `useGetRemainingMenuItemsForPromoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRemainingMenuItemsForPromoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRemainingMenuItemsForPromoQuery({
 *   variables: {
 *      promoMenuID: // value for 'promoMenuID'
 *      outletID: // value for 'outletID'
 *   },
 * });
 */
export function useGetRemainingMenuItemsForPromoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetRemainingMenuItemsForPromoQuery, GetRemainingMenuItemsForPromoQueryVariables>) {
        return ApolloReactHooks.useQuery<GetRemainingMenuItemsForPromoQuery, GetRemainingMenuItemsForPromoQueryVariables>(GetRemainingMenuItemsForPromoDocument, baseOptions);
      }
export function useGetRemainingMenuItemsForPromoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetRemainingMenuItemsForPromoQuery, GetRemainingMenuItemsForPromoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetRemainingMenuItemsForPromoQuery, GetRemainingMenuItemsForPromoQueryVariables>(GetRemainingMenuItemsForPromoDocument, baseOptions);
        }
export type GetRemainingMenuItemsForPromoQueryHookResult = ReturnType<typeof useGetRemainingMenuItemsForPromoQuery>;
export type GetRemainingMenuItemsForPromoLazyQueryHookResult = ReturnType<typeof useGetRemainingMenuItemsForPromoLazyQuery>;
export type GetRemainingMenuItemsForPromoQueryResult = ApolloReactCommon.QueryResult<GetRemainingMenuItemsForPromoQuery, GetRemainingMenuItemsForPromoQueryVariables>;
export const GetQrDocument = gql`
    query getQR($ID: String, $code: String, $outletID: String, $orderByAsc: String) {
  getQR(ID: $ID, code: $code, outletID: $outletID, orderByAsc: $orderByAsc) {
    ID
    accountID
    code
    createdTs
    outletID
    orderInfo
  }
}
    `;

/**
 * __useGetQrQuery__
 *
 * To run a query within a React component, call `useGetQrQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQrQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQrQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      code: // value for 'code'
 *      outletID: // value for 'outletID'
 *      orderByAsc: // value for 'orderByAsc'
 *   },
 * });
 */
export function useGetQrQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetQrQuery, GetQrQueryVariables>) {
        return ApolloReactHooks.useQuery<GetQrQuery, GetQrQueryVariables>(GetQrDocument, baseOptions);
      }
export function useGetQrLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetQrQuery, GetQrQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetQrQuery, GetQrQueryVariables>(GetQrDocument, baseOptions);
        }
export type GetQrQueryHookResult = ReturnType<typeof useGetQrQuery>;
export type GetQrLazyQueryHookResult = ReturnType<typeof useGetQrLazyQuery>;
export type GetQrQueryResult = ApolloReactCommon.QueryResult<GetQrQuery, GetQrQueryVariables>;
export const OpenOrderQrCheckingDocument = gql`
    query openOrderQRChecking($qrID: String!) {
  openOrderQRChecking(qrID: $qrID) {
    ID
    code
    outletID
    orderInfo
  }
}
    `;

/**
 * __useOpenOrderQrCheckingQuery__
 *
 * To run a query within a React component, call `useOpenOrderQrCheckingQuery` and pass it any options that fit your needs.
 * When your component renders, `useOpenOrderQrCheckingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOpenOrderQrCheckingQuery({
 *   variables: {
 *      qrID: // value for 'qrID'
 *   },
 * });
 */
export function useOpenOrderQrCheckingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OpenOrderQrCheckingQuery, OpenOrderQrCheckingQueryVariables>) {
        return ApolloReactHooks.useQuery<OpenOrderQrCheckingQuery, OpenOrderQrCheckingQueryVariables>(OpenOrderQrCheckingDocument, baseOptions);
      }
export function useOpenOrderQrCheckingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OpenOrderQrCheckingQuery, OpenOrderQrCheckingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OpenOrderQrCheckingQuery, OpenOrderQrCheckingQueryVariables>(OpenOrderQrCheckingDocument, baseOptions);
        }
export type OpenOrderQrCheckingQueryHookResult = ReturnType<typeof useOpenOrderQrCheckingQuery>;
export type OpenOrderQrCheckingLazyQueryHookResult = ReturnType<typeof useOpenOrderQrCheckingLazyQuery>;
export type OpenOrderQrCheckingQueryResult = ApolloReactCommon.QueryResult<OpenOrderQrCheckingQuery, OpenOrderQrCheckingQueryVariables>;
export const CreateQrDocument = gql`
    mutation createQR($input: QRInput!) {
  createQR(input: $input) {
    ID
    accountID
    code
    createdTs
    outletID
    orderInfo
  }
}
    `;
export type CreateQrMutationFn = ApolloReactCommon.MutationFunction<CreateQrMutation, CreateQrMutationVariables>;

/**
 * __useCreateQrMutation__
 *
 * To run a mutation, you first call `useCreateQrMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateQrMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createQrMutation, { data, loading, error }] = useCreateQrMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateQrMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateQrMutation, CreateQrMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateQrMutation, CreateQrMutationVariables>(CreateQrDocument, baseOptions);
      }
export type CreateQrMutationHookResult = ReturnType<typeof useCreateQrMutation>;
export type CreateQrMutationResult = ApolloReactCommon.MutationResult<CreateQrMutation>;
export type CreateQrMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateQrMutation, CreateQrMutationVariables>;
export const UpdateQrDocument = gql`
    mutation updateQR($input: QRInput!) {
  updateQR(input: $input) {
    ID
  }
}
    `;
export type UpdateQrMutationFn = ApolloReactCommon.MutationFunction<UpdateQrMutation, UpdateQrMutationVariables>;

/**
 * __useUpdateQrMutation__
 *
 * To run a mutation, you first call `useUpdateQrMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateQrMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateQrMutation, { data, loading, error }] = useUpdateQrMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateQrMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateQrMutation, UpdateQrMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateQrMutation, UpdateQrMutationVariables>(UpdateQrDocument, baseOptions);
      }
export type UpdateQrMutationHookResult = ReturnType<typeof useUpdateQrMutation>;
export type UpdateQrMutationResult = ApolloReactCommon.MutationResult<UpdateQrMutation>;
export type UpdateQrMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateQrMutation, UpdateQrMutationVariables>;
export const DeleteQrDocument = gql`
    mutation deleteQR($ID: String!) {
  deleteQR(ID: $ID)
}
    `;
export type DeleteQrMutationFn = ApolloReactCommon.MutationFunction<DeleteQrMutation, DeleteQrMutationVariables>;

/**
 * __useDeleteQrMutation__
 *
 * To run a mutation, you first call `useDeleteQrMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteQrMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteQrMutation, { data, loading, error }] = useDeleteQrMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDeleteQrMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteQrMutation, DeleteQrMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteQrMutation, DeleteQrMutationVariables>(DeleteQrDocument, baseOptions);
      }
export type DeleteQrMutationHookResult = ReturnType<typeof useDeleteQrMutation>;
export type DeleteQrMutationResult = ApolloReactCommon.MutationResult<DeleteQrMutation>;
export type DeleteQrMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteQrMutation, DeleteQrMutationVariables>;
export const GetReceiptPrinterDocument = gql`
    query getReceiptPrinter($ID: String, $name: String, $outletID: String, $orderByDesc: String, $orderByAsc: String, $isDefault: Boolean) {
  getReceiptPrinter(ID: $ID, name: $name, outletID: $outletID, orderByDesc: $orderByDesc, orderByAsc: $orderByAsc, isDefault: $isDefault) {
    ID
    name
    isActive
    outletID
    isDefault
  }
}
    `;

/**
 * __useGetReceiptPrinterQuery__
 *
 * To run a query within a React component, call `useGetReceiptPrinterQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReceiptPrinterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReceiptPrinterQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      name: // value for 'name'
 *      outletID: // value for 'outletID'
 *      orderByDesc: // value for 'orderByDesc'
 *      orderByAsc: // value for 'orderByAsc'
 *      isDefault: // value for 'isDefault'
 *   },
 * });
 */
export function useGetReceiptPrinterQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetReceiptPrinterQuery, GetReceiptPrinterQueryVariables>) {
        return ApolloReactHooks.useQuery<GetReceiptPrinterQuery, GetReceiptPrinterQueryVariables>(GetReceiptPrinterDocument, baseOptions);
      }
export function useGetReceiptPrinterLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetReceiptPrinterQuery, GetReceiptPrinterQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetReceiptPrinterQuery, GetReceiptPrinterQueryVariables>(GetReceiptPrinterDocument, baseOptions);
        }
export type GetReceiptPrinterQueryHookResult = ReturnType<typeof useGetReceiptPrinterQuery>;
export type GetReceiptPrinterLazyQueryHookResult = ReturnType<typeof useGetReceiptPrinterLazyQuery>;
export type GetReceiptPrinterQueryResult = ApolloReactCommon.QueryResult<GetReceiptPrinterQuery, GetReceiptPrinterQueryVariables>;
export const CreateReceiptPrinterDocument = gql`
    mutation createReceiptPrinter($input: ReceiptPrinterInput!) {
  createReceiptPrinter(input: $input)
}
    `;
export type CreateReceiptPrinterMutationFn = ApolloReactCommon.MutationFunction<CreateReceiptPrinterMutation, CreateReceiptPrinterMutationVariables>;

/**
 * __useCreateReceiptPrinterMutation__
 *
 * To run a mutation, you first call `useCreateReceiptPrinterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReceiptPrinterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReceiptPrinterMutation, { data, loading, error }] = useCreateReceiptPrinterMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateReceiptPrinterMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateReceiptPrinterMutation, CreateReceiptPrinterMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateReceiptPrinterMutation, CreateReceiptPrinterMutationVariables>(CreateReceiptPrinterDocument, baseOptions);
      }
export type CreateReceiptPrinterMutationHookResult = ReturnType<typeof useCreateReceiptPrinterMutation>;
export type CreateReceiptPrinterMutationResult = ApolloReactCommon.MutationResult<CreateReceiptPrinterMutation>;
export type CreateReceiptPrinterMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateReceiptPrinterMutation, CreateReceiptPrinterMutationVariables>;
export const UpdateReceiptPrinterDocument = gql`
    mutation updateReceiptPrinter($input: ReceiptPrinterInput!) {
  updateReceiptPrinter(input: $input)
}
    `;
export type UpdateReceiptPrinterMutationFn = ApolloReactCommon.MutationFunction<UpdateReceiptPrinterMutation, UpdateReceiptPrinterMutationVariables>;

/**
 * __useUpdateReceiptPrinterMutation__
 *
 * To run a mutation, you first call `useUpdateReceiptPrinterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReceiptPrinterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReceiptPrinterMutation, { data, loading, error }] = useUpdateReceiptPrinterMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateReceiptPrinterMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateReceiptPrinterMutation, UpdateReceiptPrinterMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateReceiptPrinterMutation, UpdateReceiptPrinterMutationVariables>(UpdateReceiptPrinterDocument, baseOptions);
      }
export type UpdateReceiptPrinterMutationHookResult = ReturnType<typeof useUpdateReceiptPrinterMutation>;
export type UpdateReceiptPrinterMutationResult = ApolloReactCommon.MutationResult<UpdateReceiptPrinterMutation>;
export type UpdateReceiptPrinterMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateReceiptPrinterMutation, UpdateReceiptPrinterMutationVariables>;
export const DeleteReceiptPrinterDocument = gql`
    mutation deleteReceiptPrinter($ID: String!) {
  deleteReceiptPrinter(ID: $ID)
}
    `;
export type DeleteReceiptPrinterMutationFn = ApolloReactCommon.MutationFunction<DeleteReceiptPrinterMutation, DeleteReceiptPrinterMutationVariables>;

/**
 * __useDeleteReceiptPrinterMutation__
 *
 * To run a mutation, you first call `useDeleteReceiptPrinterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteReceiptPrinterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteReceiptPrinterMutation, { data, loading, error }] = useDeleteReceiptPrinterMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDeleteReceiptPrinterMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteReceiptPrinterMutation, DeleteReceiptPrinterMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteReceiptPrinterMutation, DeleteReceiptPrinterMutationVariables>(DeleteReceiptPrinterDocument, baseOptions);
      }
export type DeleteReceiptPrinterMutationHookResult = ReturnType<typeof useDeleteReceiptPrinterMutation>;
export type DeleteReceiptPrinterMutationResult = ApolloReactCommon.MutationResult<DeleteReceiptPrinterMutation>;
export type DeleteReceiptPrinterMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteReceiptPrinterMutation, DeleteReceiptPrinterMutationVariables>;
export const GetSetMenuOptionDocument = gql`
    query getSetMenuOption($ID: String, $outletID: String, $maxSelections: Float, $menuItemID: String) {
  getSetMenuOption(ID: $ID, outletID: $outletID, maxSelections: $maxSelections, menuItemID: $menuItemID) {
    ID
    name
    setMenuOptionItem {
      ID
      quantity
      setMenuOptionID
      menuItemID
      menuItem {
        ID
        name
        description
        attachments
      }
    }
  }
}
    `;

/**
 * __useGetSetMenuOptionQuery__
 *
 * To run a query within a React component, call `useGetSetMenuOptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSetMenuOptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSetMenuOptionQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      outletID: // value for 'outletID'
 *      maxSelections: // value for 'maxSelections'
 *      menuItemID: // value for 'menuItemID'
 *   },
 * });
 */
export function useGetSetMenuOptionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSetMenuOptionQuery, GetSetMenuOptionQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSetMenuOptionQuery, GetSetMenuOptionQueryVariables>(GetSetMenuOptionDocument, baseOptions);
      }
export function useGetSetMenuOptionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSetMenuOptionQuery, GetSetMenuOptionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSetMenuOptionQuery, GetSetMenuOptionQueryVariables>(GetSetMenuOptionDocument, baseOptions);
        }
export type GetSetMenuOptionQueryHookResult = ReturnType<typeof useGetSetMenuOptionQuery>;
export type GetSetMenuOptionLazyQueryHookResult = ReturnType<typeof useGetSetMenuOptionLazyQuery>;
export type GetSetMenuOptionQueryResult = ApolloReactCommon.QueryResult<GetSetMenuOptionQuery, GetSetMenuOptionQueryVariables>;
export const GetSetMenuOptionOptionDocument = gql`
    query getSetMenuOptionOption($outletID: String!, $menuItemID: String!) {
  getSetMenuOptionOption(outletID: $outletID, menuItemID: $menuItemID) {
    ID
    name
    maxSelections
    setMenuOptionItem {
      ID
      quantity
      setMenuOptionID
      menuItemID
      menuItem {
        ID
        name
        description
        attachments
      }
    }
  }
}
    `;

/**
 * __useGetSetMenuOptionOptionQuery__
 *
 * To run a query within a React component, call `useGetSetMenuOptionOptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSetMenuOptionOptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSetMenuOptionOptionQuery({
 *   variables: {
 *      outletID: // value for 'outletID'
 *      menuItemID: // value for 'menuItemID'
 *   },
 * });
 */
export function useGetSetMenuOptionOptionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSetMenuOptionOptionQuery, GetSetMenuOptionOptionQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSetMenuOptionOptionQuery, GetSetMenuOptionOptionQueryVariables>(GetSetMenuOptionOptionDocument, baseOptions);
      }
export function useGetSetMenuOptionOptionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSetMenuOptionOptionQuery, GetSetMenuOptionOptionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSetMenuOptionOptionQuery, GetSetMenuOptionOptionQueryVariables>(GetSetMenuOptionOptionDocument, baseOptions);
        }
export type GetSetMenuOptionOptionQueryHookResult = ReturnType<typeof useGetSetMenuOptionOptionQuery>;
export type GetSetMenuOptionOptionLazyQueryHookResult = ReturnType<typeof useGetSetMenuOptionOptionLazyQuery>;
export type GetSetMenuOptionOptionQueryResult = ApolloReactCommon.QueryResult<GetSetMenuOptionOptionQuery, GetSetMenuOptionOptionQueryVariables>;
export const GetMenuItemForSetMenuDocument = gql`
    query getMenuItemForSetMenu($ID: String, $outletID: String, $isSetMenu: Boolean) {
  getMenuItem(ID: $ID, outletID: $outletID, isSetMenu: $isSetMenu) {
    ID
    name
    isSetMenu
    setMenuOption {
      ID
      outletID
      maxSelections
      menuItemID
      ID
      name
      setMenuOptionItem {
        ID
        quantity
        setMenuOptionID
        menuItemID
        menuItem {
          ID
          name
          description
          attachments
        }
      }
    }
  }
}
    `;

/**
 * __useGetMenuItemForSetMenuQuery__
 *
 * To run a query within a React component, call `useGetMenuItemForSetMenuQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMenuItemForSetMenuQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMenuItemForSetMenuQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      outletID: // value for 'outletID'
 *      isSetMenu: // value for 'isSetMenu'
 *   },
 * });
 */
export function useGetMenuItemForSetMenuQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMenuItemForSetMenuQuery, GetMenuItemForSetMenuQueryVariables>) {
        return ApolloReactHooks.useQuery<GetMenuItemForSetMenuQuery, GetMenuItemForSetMenuQueryVariables>(GetMenuItemForSetMenuDocument, baseOptions);
      }
export function useGetMenuItemForSetMenuLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMenuItemForSetMenuQuery, GetMenuItemForSetMenuQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetMenuItemForSetMenuQuery, GetMenuItemForSetMenuQueryVariables>(GetMenuItemForSetMenuDocument, baseOptions);
        }
export type GetMenuItemForSetMenuQueryHookResult = ReturnType<typeof useGetMenuItemForSetMenuQuery>;
export type GetMenuItemForSetMenuLazyQueryHookResult = ReturnType<typeof useGetMenuItemForSetMenuLazyQuery>;
export type GetMenuItemForSetMenuQueryResult = ApolloReactCommon.QueryResult<GetMenuItemForSetMenuQuery, GetMenuItemForSetMenuQueryVariables>;
export const GetMenuItemForSetMenuOptionItemDocument = gql`
    query getMenuItemForSetMenuOptionItem($setMenuID: String!, $outletID: String!, $isStandard: Boolean) {
  getMenuItemForSetMenuOptionItem(setMenuID: $setMenuID, outletID: $outletID, isStandard: $isStandard) {
    ID
    name
    description
  }
}
    `;

/**
 * __useGetMenuItemForSetMenuOptionItemQuery__
 *
 * To run a query within a React component, call `useGetMenuItemForSetMenuOptionItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMenuItemForSetMenuOptionItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMenuItemForSetMenuOptionItemQuery({
 *   variables: {
 *      setMenuID: // value for 'setMenuID'
 *      outletID: // value for 'outletID'
 *      isStandard: // value for 'isStandard'
 *   },
 * });
 */
export function useGetMenuItemForSetMenuOptionItemQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMenuItemForSetMenuOptionItemQuery, GetMenuItemForSetMenuOptionItemQueryVariables>) {
        return ApolloReactHooks.useQuery<GetMenuItemForSetMenuOptionItemQuery, GetMenuItemForSetMenuOptionItemQueryVariables>(GetMenuItemForSetMenuOptionItemDocument, baseOptions);
      }
export function useGetMenuItemForSetMenuOptionItemLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMenuItemForSetMenuOptionItemQuery, GetMenuItemForSetMenuOptionItemQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetMenuItemForSetMenuOptionItemQuery, GetMenuItemForSetMenuOptionItemQueryVariables>(GetMenuItemForSetMenuOptionItemDocument, baseOptions);
        }
export type GetMenuItemForSetMenuOptionItemQueryHookResult = ReturnType<typeof useGetMenuItemForSetMenuOptionItemQuery>;
export type GetMenuItemForSetMenuOptionItemLazyQueryHookResult = ReturnType<typeof useGetMenuItemForSetMenuOptionItemLazyQuery>;
export type GetMenuItemForSetMenuOptionItemQueryResult = ApolloReactCommon.QueryResult<GetMenuItemForSetMenuOptionItemQuery, GetMenuItemForSetMenuOptionItemQueryVariables>;
export const CreateSetMenuOptionDocument = gql`
    mutation createSetMenuOption($input: SetMenuOptionInput!) {
  createSetMenuOption(input: $input) {
    name
    nativeName
    description
    outletID
    maxSelections
    menuItemID
  }
}
    `;
export type CreateSetMenuOptionMutationFn = ApolloReactCommon.MutationFunction<CreateSetMenuOptionMutation, CreateSetMenuOptionMutationVariables>;

/**
 * __useCreateSetMenuOptionMutation__
 *
 * To run a mutation, you first call `useCreateSetMenuOptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSetMenuOptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSetMenuOptionMutation, { data, loading, error }] = useCreateSetMenuOptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSetMenuOptionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSetMenuOptionMutation, CreateSetMenuOptionMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateSetMenuOptionMutation, CreateSetMenuOptionMutationVariables>(CreateSetMenuOptionDocument, baseOptions);
      }
export type CreateSetMenuOptionMutationHookResult = ReturnType<typeof useCreateSetMenuOptionMutation>;
export type CreateSetMenuOptionMutationResult = ApolloReactCommon.MutationResult<CreateSetMenuOptionMutation>;
export type CreateSetMenuOptionMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateSetMenuOptionMutation, CreateSetMenuOptionMutationVariables>;
export const UpdateSetMenuOptionDocument = gql`
    mutation updateSetMenuOption($input: SetMenuOptionInput!) {
  updateSetMenuOption(input: $input) {
    ID
  }
}
    `;
export type UpdateSetMenuOptionMutationFn = ApolloReactCommon.MutationFunction<UpdateSetMenuOptionMutation, UpdateSetMenuOptionMutationVariables>;

/**
 * __useUpdateSetMenuOptionMutation__
 *
 * To run a mutation, you first call `useUpdateSetMenuOptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSetMenuOptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSetMenuOptionMutation, { data, loading, error }] = useUpdateSetMenuOptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSetMenuOptionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateSetMenuOptionMutation, UpdateSetMenuOptionMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateSetMenuOptionMutation, UpdateSetMenuOptionMutationVariables>(UpdateSetMenuOptionDocument, baseOptions);
      }
export type UpdateSetMenuOptionMutationHookResult = ReturnType<typeof useUpdateSetMenuOptionMutation>;
export type UpdateSetMenuOptionMutationResult = ApolloReactCommon.MutationResult<UpdateSetMenuOptionMutation>;
export type UpdateSetMenuOptionMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateSetMenuOptionMutation, UpdateSetMenuOptionMutationVariables>;
export const CreateSetMenuStandardOptionItemDocument = gql`
    mutation createSetMenuStandardOptionItem($setMenuOptionItemInput: [SetMenuOptionItemInput!]!, $setMenuID: String!, $outletID: String!) {
  createSetMenuStandardOptionItem(setMenuOptionItemInput: $setMenuOptionItemInput, setMenuID: $setMenuID, outletID: $outletID)
}
    `;
export type CreateSetMenuStandardOptionItemMutationFn = ApolloReactCommon.MutationFunction<CreateSetMenuStandardOptionItemMutation, CreateSetMenuStandardOptionItemMutationVariables>;

/**
 * __useCreateSetMenuStandardOptionItemMutation__
 *
 * To run a mutation, you first call `useCreateSetMenuStandardOptionItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSetMenuStandardOptionItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSetMenuStandardOptionItemMutation, { data, loading, error }] = useCreateSetMenuStandardOptionItemMutation({
 *   variables: {
 *      setMenuOptionItemInput: // value for 'setMenuOptionItemInput'
 *      setMenuID: // value for 'setMenuID'
 *      outletID: // value for 'outletID'
 *   },
 * });
 */
export function useCreateSetMenuStandardOptionItemMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSetMenuStandardOptionItemMutation, CreateSetMenuStandardOptionItemMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateSetMenuStandardOptionItemMutation, CreateSetMenuStandardOptionItemMutationVariables>(CreateSetMenuStandardOptionItemDocument, baseOptions);
      }
export type CreateSetMenuStandardOptionItemMutationHookResult = ReturnType<typeof useCreateSetMenuStandardOptionItemMutation>;
export type CreateSetMenuStandardOptionItemMutationResult = ApolloReactCommon.MutationResult<CreateSetMenuStandardOptionItemMutation>;
export type CreateSetMenuStandardOptionItemMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateSetMenuStandardOptionItemMutation, CreateSetMenuStandardOptionItemMutationVariables>;
export const DeleteSetMenuOptionItemDocument = gql`
    mutation deleteSetMenuOptionItem($ID: String!) {
  deleteSetMenuOptionItem(ID: $ID)
}
    `;
export type DeleteSetMenuOptionItemMutationFn = ApolloReactCommon.MutationFunction<DeleteSetMenuOptionItemMutation, DeleteSetMenuOptionItemMutationVariables>;

/**
 * __useDeleteSetMenuOptionItemMutation__
 *
 * To run a mutation, you first call `useDeleteSetMenuOptionItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSetMenuOptionItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSetMenuOptionItemMutation, { data, loading, error }] = useDeleteSetMenuOptionItemMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDeleteSetMenuOptionItemMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteSetMenuOptionItemMutation, DeleteSetMenuOptionItemMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteSetMenuOptionItemMutation, DeleteSetMenuOptionItemMutationVariables>(DeleteSetMenuOptionItemDocument, baseOptions);
      }
export type DeleteSetMenuOptionItemMutationHookResult = ReturnType<typeof useDeleteSetMenuOptionItemMutation>;
export type DeleteSetMenuOptionItemMutationResult = ApolloReactCommon.MutationResult<DeleteSetMenuOptionItemMutation>;
export type DeleteSetMenuOptionItemMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteSetMenuOptionItemMutation, DeleteSetMenuOptionItemMutationVariables>;
export const DeleteSetMenuOptionDocument = gql`
    mutation deleteSetMenuOption($ID: String!) {
  deleteSetMenuOption(ID: $ID)
}
    `;
export type DeleteSetMenuOptionMutationFn = ApolloReactCommon.MutationFunction<DeleteSetMenuOptionMutation, DeleteSetMenuOptionMutationVariables>;

/**
 * __useDeleteSetMenuOptionMutation__
 *
 * To run a mutation, you first call `useDeleteSetMenuOptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSetMenuOptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSetMenuOptionMutation, { data, loading, error }] = useDeleteSetMenuOptionMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDeleteSetMenuOptionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteSetMenuOptionMutation, DeleteSetMenuOptionMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteSetMenuOptionMutation, DeleteSetMenuOptionMutationVariables>(DeleteSetMenuOptionDocument, baseOptions);
      }
export type DeleteSetMenuOptionMutationHookResult = ReturnType<typeof useDeleteSetMenuOptionMutation>;
export type DeleteSetMenuOptionMutationResult = ApolloReactCommon.MutationResult<DeleteSetMenuOptionMutation>;
export type DeleteSetMenuOptionMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteSetMenuOptionMutation, DeleteSetMenuOptionMutationVariables>;
export const CreateSetMenuOptionOptionItemDocument = gql`
    mutation createSetMenuOptionOptionItem($setMenuOptionItemInput: [SetMenuOptionItemInput!]!) {
  createSetMenuOptionOptionItem(setMenuOptionItemInput: $setMenuOptionItemInput)
}
    `;
export type CreateSetMenuOptionOptionItemMutationFn = ApolloReactCommon.MutationFunction<CreateSetMenuOptionOptionItemMutation, CreateSetMenuOptionOptionItemMutationVariables>;

/**
 * __useCreateSetMenuOptionOptionItemMutation__
 *
 * To run a mutation, you first call `useCreateSetMenuOptionOptionItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSetMenuOptionOptionItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSetMenuOptionOptionItemMutation, { data, loading, error }] = useCreateSetMenuOptionOptionItemMutation({
 *   variables: {
 *      setMenuOptionItemInput: // value for 'setMenuOptionItemInput'
 *   },
 * });
 */
export function useCreateSetMenuOptionOptionItemMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSetMenuOptionOptionItemMutation, CreateSetMenuOptionOptionItemMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateSetMenuOptionOptionItemMutation, CreateSetMenuOptionOptionItemMutationVariables>(CreateSetMenuOptionOptionItemDocument, baseOptions);
      }
export type CreateSetMenuOptionOptionItemMutationHookResult = ReturnType<typeof useCreateSetMenuOptionOptionItemMutation>;
export type CreateSetMenuOptionOptionItemMutationResult = ApolloReactCommon.MutationResult<CreateSetMenuOptionOptionItemMutation>;
export type CreateSetMenuOptionOptionItemMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateSetMenuOptionOptionItemMutation, CreateSetMenuOptionOptionItemMutationVariables>;
export const UpdateSetMenuOptionItemDocument = gql`
    mutation updateSetMenuOptionItem($input: SetMenuOptionItemInput!) {
  updateSetMenuOptionItem(input: $input) {
    ID
  }
}
    `;
export type UpdateSetMenuOptionItemMutationFn = ApolloReactCommon.MutationFunction<UpdateSetMenuOptionItemMutation, UpdateSetMenuOptionItemMutationVariables>;

/**
 * __useUpdateSetMenuOptionItemMutation__
 *
 * To run a mutation, you first call `useUpdateSetMenuOptionItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSetMenuOptionItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSetMenuOptionItemMutation, { data, loading, error }] = useUpdateSetMenuOptionItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSetMenuOptionItemMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateSetMenuOptionItemMutation, UpdateSetMenuOptionItemMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateSetMenuOptionItemMutation, UpdateSetMenuOptionItemMutationVariables>(UpdateSetMenuOptionItemDocument, baseOptions);
      }
export type UpdateSetMenuOptionItemMutationHookResult = ReturnType<typeof useUpdateSetMenuOptionItemMutation>;
export type UpdateSetMenuOptionItemMutationResult = ApolloReactCommon.MutationResult<UpdateSetMenuOptionItemMutation>;
export type UpdateSetMenuOptionItemMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateSetMenuOptionItemMutation, UpdateSetMenuOptionItemMutationVariables>;
export const GetOptionDocument = gql`
    query getOption($ID: String, $name: String, $outletID: String, $orderByDesc: String, $orderByAsc: String) {
  getOption(ID: $ID, name: $name, outletID: $outletID, orderByDesc: $orderByDesc, orderByAsc: $orderByAsc) {
    ID
    name
    outletID
    optionItem {
      ID
      name
      optionID
      outletID
      priority
    }
  }
}
    `;

/**
 * __useGetOptionQuery__
 *
 * To run a query within a React component, call `useGetOptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOptionQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      name: // value for 'name'
 *      outletID: // value for 'outletID'
 *      orderByDesc: // value for 'orderByDesc'
 *      orderByAsc: // value for 'orderByAsc'
 *   },
 * });
 */
export function useGetOptionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetOptionQuery, GetOptionQueryVariables>) {
        return ApolloReactHooks.useQuery<GetOptionQuery, GetOptionQueryVariables>(GetOptionDocument, baseOptions);
      }
export function useGetOptionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetOptionQuery, GetOptionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetOptionQuery, GetOptionQueryVariables>(GetOptionDocument, baseOptions);
        }
export type GetOptionQueryHookResult = ReturnType<typeof useGetOptionQuery>;
export type GetOptionLazyQueryHookResult = ReturnType<typeof useGetOptionLazyQuery>;
export type GetOptionQueryResult = ApolloReactCommon.QueryResult<GetOptionQuery, GetOptionQueryVariables>;
export const GetAllOptionDocument = gql`
    query getAllOption($ID: String, $name: String, $outletID: String, $orderByDesc: String, $orderByAsc: String) {
  getOption(ID: $ID, name: $name, outletID: $outletID, orderByDesc: $orderByDesc, orderByAsc: $orderByAsc) {
    ID
    name
    outletID
    optionItem {
      ID
      name
      optionID
      outletID
      priority
    }
  }
}
    `;

/**
 * __useGetAllOptionQuery__
 *
 * To run a query within a React component, call `useGetAllOptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllOptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllOptionQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      name: // value for 'name'
 *      outletID: // value for 'outletID'
 *      orderByDesc: // value for 'orderByDesc'
 *      orderByAsc: // value for 'orderByAsc'
 *   },
 * });
 */
export function useGetAllOptionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAllOptionQuery, GetAllOptionQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAllOptionQuery, GetAllOptionQueryVariables>(GetAllOptionDocument, baseOptions);
      }
export function useGetAllOptionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAllOptionQuery, GetAllOptionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAllOptionQuery, GetAllOptionQueryVariables>(GetAllOptionDocument, baseOptions);
        }
export type GetAllOptionQueryHookResult = ReturnType<typeof useGetAllOptionQuery>;
export type GetAllOptionLazyQueryHookResult = ReturnType<typeof useGetAllOptionLazyQuery>;
export type GetAllOptionQueryResult = ApolloReactCommon.QueryResult<GetAllOptionQuery, GetAllOptionQueryVariables>;
export const CreateOptionDocument = gql`
    mutation createOption($input: OptionInput!) {
  createOption(input: $input) {
    ID
  }
}
    `;
export type CreateOptionMutationFn = ApolloReactCommon.MutationFunction<CreateOptionMutation, CreateOptionMutationVariables>;

/**
 * __useCreateOptionMutation__
 *
 * To run a mutation, you first call `useCreateOptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOptionMutation, { data, loading, error }] = useCreateOptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOptionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateOptionMutation, CreateOptionMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateOptionMutation, CreateOptionMutationVariables>(CreateOptionDocument, baseOptions);
      }
export type CreateOptionMutationHookResult = ReturnType<typeof useCreateOptionMutation>;
export type CreateOptionMutationResult = ApolloReactCommon.MutationResult<CreateOptionMutation>;
export type CreateOptionMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateOptionMutation, CreateOptionMutationVariables>;
export const UpdateOptionDocument = gql`
    mutation updateOption($input: OptionInput!) {
  updateOption(input: $input) {
    ID
  }
}
    `;
export type UpdateOptionMutationFn = ApolloReactCommon.MutationFunction<UpdateOptionMutation, UpdateOptionMutationVariables>;

/**
 * __useUpdateOptionMutation__
 *
 * To run a mutation, you first call `useUpdateOptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOptionMutation, { data, loading, error }] = useUpdateOptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOptionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateOptionMutation, UpdateOptionMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateOptionMutation, UpdateOptionMutationVariables>(UpdateOptionDocument, baseOptions);
      }
export type UpdateOptionMutationHookResult = ReturnType<typeof useUpdateOptionMutation>;
export type UpdateOptionMutationResult = ApolloReactCommon.MutationResult<UpdateOptionMutation>;
export type UpdateOptionMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateOptionMutation, UpdateOptionMutationVariables>;
export const DeleteOptionDocument = gql`
    mutation deleteOption($ID: String!) {
  deleteOption(ID: $ID)
}
    `;
export type DeleteOptionMutationFn = ApolloReactCommon.MutationFunction<DeleteOptionMutation, DeleteOptionMutationVariables>;

/**
 * __useDeleteOptionMutation__
 *
 * To run a mutation, you first call `useDeleteOptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOptionMutation, { data, loading, error }] = useDeleteOptionMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDeleteOptionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteOptionMutation, DeleteOptionMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteOptionMutation, DeleteOptionMutationVariables>(DeleteOptionDocument, baseOptions);
      }
export type DeleteOptionMutationHookResult = ReturnType<typeof useDeleteOptionMutation>;
export type DeleteOptionMutationResult = ApolloReactCommon.MutationResult<DeleteOptionMutation>;
export type DeleteOptionMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteOptionMutation, DeleteOptionMutationVariables>;
export const GetOptionItemDocument = gql`
    query getOptionItem($ID: String, $name: String, $optionID: String, $orderByDesc: String, $orderByAsc: String) {
  getOptionItem(ID: $ID, name: $name, optionID: $optionID, orderByDesc: $orderByDesc, orderByAsc: $orderByAsc) {
    ID
    name
    accountID
    optionID
    outletID
    modTs
    createdTs
    createdBy
    priority
  }
}
    `;

/**
 * __useGetOptionItemQuery__
 *
 * To run a query within a React component, call `useGetOptionItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOptionItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOptionItemQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      name: // value for 'name'
 *      optionID: // value for 'optionID'
 *      orderByDesc: // value for 'orderByDesc'
 *      orderByAsc: // value for 'orderByAsc'
 *   },
 * });
 */
export function useGetOptionItemQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetOptionItemQuery, GetOptionItemQueryVariables>) {
        return ApolloReactHooks.useQuery<GetOptionItemQuery, GetOptionItemQueryVariables>(GetOptionItemDocument, baseOptions);
      }
export function useGetOptionItemLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetOptionItemQuery, GetOptionItemQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetOptionItemQuery, GetOptionItemQueryVariables>(GetOptionItemDocument, baseOptions);
        }
export type GetOptionItemQueryHookResult = ReturnType<typeof useGetOptionItemQuery>;
export type GetOptionItemLazyQueryHookResult = ReturnType<typeof useGetOptionItemLazyQuery>;
export type GetOptionItemQueryResult = ApolloReactCommon.QueryResult<GetOptionItemQuery, GetOptionItemQueryVariables>;
export const CreateOptionItemDocument = gql`
    mutation createOptionItem($input: OptionItemInput!) {
  createOptionItem(input: $input) {
    ID
  }
}
    `;
export type CreateOptionItemMutationFn = ApolloReactCommon.MutationFunction<CreateOptionItemMutation, CreateOptionItemMutationVariables>;

/**
 * __useCreateOptionItemMutation__
 *
 * To run a mutation, you first call `useCreateOptionItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOptionItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOptionItemMutation, { data, loading, error }] = useCreateOptionItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOptionItemMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateOptionItemMutation, CreateOptionItemMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateOptionItemMutation, CreateOptionItemMutationVariables>(CreateOptionItemDocument, baseOptions);
      }
export type CreateOptionItemMutationHookResult = ReturnType<typeof useCreateOptionItemMutation>;
export type CreateOptionItemMutationResult = ApolloReactCommon.MutationResult<CreateOptionItemMutation>;
export type CreateOptionItemMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateOptionItemMutation, CreateOptionItemMutationVariables>;
export const UpdateOptionItemDocument = gql`
    mutation updateOptionItem($input: OptionItemInput!) {
  updateOptionItem(input: $input) {
    ID
  }
}
    `;
export type UpdateOptionItemMutationFn = ApolloReactCommon.MutationFunction<UpdateOptionItemMutation, UpdateOptionItemMutationVariables>;

/**
 * __useUpdateOptionItemMutation__
 *
 * To run a mutation, you first call `useUpdateOptionItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOptionItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOptionItemMutation, { data, loading, error }] = useUpdateOptionItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOptionItemMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateOptionItemMutation, UpdateOptionItemMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateOptionItemMutation, UpdateOptionItemMutationVariables>(UpdateOptionItemDocument, baseOptions);
      }
export type UpdateOptionItemMutationHookResult = ReturnType<typeof useUpdateOptionItemMutation>;
export type UpdateOptionItemMutationResult = ApolloReactCommon.MutationResult<UpdateOptionItemMutation>;
export type UpdateOptionItemMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateOptionItemMutation, UpdateOptionItemMutationVariables>;
export const DeleteOptionItemDocument = gql`
    mutation deleteOptionItem($ID: String!) {
  deleteOptionItem(ID: $ID)
}
    `;
export type DeleteOptionItemMutationFn = ApolloReactCommon.MutationFunction<DeleteOptionItemMutation, DeleteOptionItemMutationVariables>;

/**
 * __useDeleteOptionItemMutation__
 *
 * To run a mutation, you first call `useDeleteOptionItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOptionItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOptionItemMutation, { data, loading, error }] = useDeleteOptionItemMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDeleteOptionItemMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteOptionItemMutation, DeleteOptionItemMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteOptionItemMutation, DeleteOptionItemMutationVariables>(DeleteOptionItemDocument, baseOptions);
      }
export type DeleteOptionItemMutationHookResult = ReturnType<typeof useDeleteOptionItemMutation>;
export type DeleteOptionItemMutationResult = ApolloReactCommon.MutationResult<DeleteOptionItemMutation>;
export type DeleteOptionItemMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteOptionItemMutation, DeleteOptionItemMutationVariables>;
export const DragOptionItemDocument = gql`
    mutation dragOptionItem($input: [OptionItemInput!]!, $optionID: String!) {
  dragOptionItem(input: $input, optionID: $optionID)
}
    `;
export type DragOptionItemMutationFn = ApolloReactCommon.MutationFunction<DragOptionItemMutation, DragOptionItemMutationVariables>;

/**
 * __useDragOptionItemMutation__
 *
 * To run a mutation, you first call `useDragOptionItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDragOptionItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [dragOptionItemMutation, { data, loading, error }] = useDragOptionItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *      optionID: // value for 'optionID'
 *   },
 * });
 */
export function useDragOptionItemMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DragOptionItemMutation, DragOptionItemMutationVariables>) {
        return ApolloReactHooks.useMutation<DragOptionItemMutation, DragOptionItemMutationVariables>(DragOptionItemDocument, baseOptions);
      }
export type DragOptionItemMutationHookResult = ReturnType<typeof useDragOptionItemMutation>;
export type DragOptionItemMutationResult = ApolloReactCommon.MutationResult<DragOptionItemMutation>;
export type DragOptionItemMutationOptions = ApolloReactCommon.BaseMutationOptions<DragOptionItemMutation, DragOptionItemMutationVariables>;
export const GetStopSalesDocument = gql`
    query getStopSales($ID: String, $menuItemID: String, $outletID: String) {
  getStopSales(ID: $ID, menuItemID: $menuItemID, outletID: $outletID) {
    ID
    outletID
    menuItemID
    menuItem {
      name
      isActive
      kitchenName
      nativeName
      description
    }
  }
}
    `;

/**
 * __useGetStopSalesQuery__
 *
 * To run a query within a React component, call `useGetStopSalesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStopSalesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStopSalesQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      menuItemID: // value for 'menuItemID'
 *      outletID: // value for 'outletID'
 *   },
 * });
 */
export function useGetStopSalesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetStopSalesQuery, GetStopSalesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetStopSalesQuery, GetStopSalesQueryVariables>(GetStopSalesDocument, baseOptions);
      }
export function useGetStopSalesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetStopSalesQuery, GetStopSalesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetStopSalesQuery, GetStopSalesQueryVariables>(GetStopSalesDocument, baseOptions);
        }
export type GetStopSalesQueryHookResult = ReturnType<typeof useGetStopSalesQuery>;
export type GetStopSalesLazyQueryHookResult = ReturnType<typeof useGetStopSalesLazyQuery>;
export type GetStopSalesQueryResult = ApolloReactCommon.QueryResult<GetStopSalesQuery, GetStopSalesQueryVariables>;
export const CreateStopSalesDocument = gql`
    mutation createStopSales($input: StopSalesInput!) {
  createStopSales(input: $input) {
    ID
  }
}
    `;
export type CreateStopSalesMutationFn = ApolloReactCommon.MutationFunction<CreateStopSalesMutation, CreateStopSalesMutationVariables>;

/**
 * __useCreateStopSalesMutation__
 *
 * To run a mutation, you first call `useCreateStopSalesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStopSalesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStopSalesMutation, { data, loading, error }] = useCreateStopSalesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateStopSalesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateStopSalesMutation, CreateStopSalesMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateStopSalesMutation, CreateStopSalesMutationVariables>(CreateStopSalesDocument, baseOptions);
      }
export type CreateStopSalesMutationHookResult = ReturnType<typeof useCreateStopSalesMutation>;
export type CreateStopSalesMutationResult = ApolloReactCommon.MutationResult<CreateStopSalesMutation>;
export type CreateStopSalesMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateStopSalesMutation, CreateStopSalesMutationVariables>;
export const UpdateStopSalesDocument = gql`
    mutation updateStopSales($input: StopSalesInput!) {
  updateStopSales(input: $input) {
    ID
  }
}
    `;
export type UpdateStopSalesMutationFn = ApolloReactCommon.MutationFunction<UpdateStopSalesMutation, UpdateStopSalesMutationVariables>;

/**
 * __useUpdateStopSalesMutation__
 *
 * To run a mutation, you first call `useUpdateStopSalesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStopSalesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStopSalesMutation, { data, loading, error }] = useUpdateStopSalesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateStopSalesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateStopSalesMutation, UpdateStopSalesMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateStopSalesMutation, UpdateStopSalesMutationVariables>(UpdateStopSalesDocument, baseOptions);
      }
export type UpdateStopSalesMutationHookResult = ReturnType<typeof useUpdateStopSalesMutation>;
export type UpdateStopSalesMutationResult = ApolloReactCommon.MutationResult<UpdateStopSalesMutation>;
export type UpdateStopSalesMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateStopSalesMutation, UpdateStopSalesMutationVariables>;
export const SubmitStopSalesDocument = gql`
    mutation submitStopSales($input: [StopSalesInput!]!, $outletID: String!) {
  submitStopSales(input: $input, outletID: $outletID)
}
    `;
export type SubmitStopSalesMutationFn = ApolloReactCommon.MutationFunction<SubmitStopSalesMutation, SubmitStopSalesMutationVariables>;

/**
 * __useSubmitStopSalesMutation__
 *
 * To run a mutation, you first call `useSubmitStopSalesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitStopSalesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitStopSalesMutation, { data, loading, error }] = useSubmitStopSalesMutation({
 *   variables: {
 *      input: // value for 'input'
 *      outletID: // value for 'outletID'
 *   },
 * });
 */
export function useSubmitStopSalesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SubmitStopSalesMutation, SubmitStopSalesMutationVariables>) {
        return ApolloReactHooks.useMutation<SubmitStopSalesMutation, SubmitStopSalesMutationVariables>(SubmitStopSalesDocument, baseOptions);
      }
export type SubmitStopSalesMutationHookResult = ReturnType<typeof useSubmitStopSalesMutation>;
export type SubmitStopSalesMutationResult = ApolloReactCommon.MutationResult<SubmitStopSalesMutation>;
export type SubmitStopSalesMutationOptions = ApolloReactCommon.BaseMutationOptions<SubmitStopSalesMutation, SubmitStopSalesMutationVariables>;
export const DeleteStopSalesDocument = gql`
    mutation deleteStopSales($ID: String!) {
  deleteStopSales(ID: $ID)
}
    `;
export type DeleteStopSalesMutationFn = ApolloReactCommon.MutationFunction<DeleteStopSalesMutation, DeleteStopSalesMutationVariables>;

/**
 * __useDeleteStopSalesMutation__
 *
 * To run a mutation, you first call `useDeleteStopSalesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteStopSalesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteStopSalesMutation, { data, loading, error }] = useDeleteStopSalesMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDeleteStopSalesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteStopSalesMutation, DeleteStopSalesMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteStopSalesMutation, DeleteStopSalesMutationVariables>(DeleteStopSalesDocument, baseOptions);
      }
export type DeleteStopSalesMutationHookResult = ReturnType<typeof useDeleteStopSalesMutation>;
export type DeleteStopSalesMutationResult = ApolloReactCommon.MutationResult<DeleteStopSalesMutation>;
export type DeleteStopSalesMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteStopSalesMutation, DeleteStopSalesMutationVariables>;
export const GetMenuItemForStopSaleDocument = gql`
    query getMenuItemForStopSale($outletID: String!) {
  getMenuItemForStopSale(outletID: $outletID) {
    ID
    outletID
    name
    description
    setMenuInfo {
      menuItemID
      setMenuID
      setMenuName
    }
  }
}
    `;

/**
 * __useGetMenuItemForStopSaleQuery__
 *
 * To run a query within a React component, call `useGetMenuItemForStopSaleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMenuItemForStopSaleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMenuItemForStopSaleQuery({
 *   variables: {
 *      outletID: // value for 'outletID'
 *   },
 * });
 */
export function useGetMenuItemForStopSaleQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMenuItemForStopSaleQuery, GetMenuItemForStopSaleQueryVariables>) {
        return ApolloReactHooks.useQuery<GetMenuItemForStopSaleQuery, GetMenuItemForStopSaleQueryVariables>(GetMenuItemForStopSaleDocument, baseOptions);
      }
export function useGetMenuItemForStopSaleLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMenuItemForStopSaleQuery, GetMenuItemForStopSaleQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetMenuItemForStopSaleQuery, GetMenuItemForStopSaleQueryVariables>(GetMenuItemForStopSaleDocument, baseOptions);
        }
export type GetMenuItemForStopSaleQueryHookResult = ReturnType<typeof useGetMenuItemForStopSaleQuery>;
export type GetMenuItemForStopSaleLazyQueryHookResult = ReturnType<typeof useGetMenuItemForStopSaleLazyQuery>;
export type GetMenuItemForStopSaleQueryResult = ApolloReactCommon.QueryResult<GetMenuItemForStopSaleQuery, GetMenuItemForStopSaleQueryVariables>;
export const GetTableDocument = gql`
    query getTable($ID: String, $isActive: Boolean, $name: String, $outletID: String, $orderByAsc: String) {
  getTable(ID: $ID, isActive: $isActive, name: $name, outletID: $outletID, orderByAsc: $orderByAsc) {
    ID
    tableNumber
    seatCount
    prefix
    outletID
    isActive
    status
    orderInfo {
      totalAmount
      totalPax
    }
    tablePrfixNumberInfo
    reservationInfo {
      reserved
      pax
      name
      time
      noShow
    }
    reservation {
      ID
      name
      reservationDate
      reservationTime
    }
    outlet {
      ID
      name
    }
    order {
      tableID
      status
    }
  }
}
    `;

/**
 * __useGetTableQuery__
 *
 * To run a query within a React component, call `useGetTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTableQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      isActive: // value for 'isActive'
 *      name: // value for 'name'
 *      outletID: // value for 'outletID'
 *      orderByAsc: // value for 'orderByAsc'
 *   },
 * });
 */
export function useGetTableQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetTableQuery, GetTableQueryVariables>) {
        return ApolloReactHooks.useQuery<GetTableQuery, GetTableQueryVariables>(GetTableDocument, baseOptions);
      }
export function useGetTableLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTableQuery, GetTableQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetTableQuery, GetTableQueryVariables>(GetTableDocument, baseOptions);
        }
export type GetTableQueryHookResult = ReturnType<typeof useGetTableQuery>;
export type GetTableLazyQueryHookResult = ReturnType<typeof useGetTableLazyQuery>;
export type GetTableQueryResult = ApolloReactCommon.QueryResult<GetTableQuery, GetTableQueryVariables>;
export const CreateTableDocument = gql`
    mutation createTable($input: TableInput!) {
  createTable(input: $input) {
    ID
  }
}
    `;
export type CreateTableMutationFn = ApolloReactCommon.MutationFunction<CreateTableMutation, CreateTableMutationVariables>;

/**
 * __useCreateTableMutation__
 *
 * To run a mutation, you first call `useCreateTableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTableMutation, { data, loading, error }] = useCreateTableMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTableMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateTableMutation, CreateTableMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateTableMutation, CreateTableMutationVariables>(CreateTableDocument, baseOptions);
      }
export type CreateTableMutationHookResult = ReturnType<typeof useCreateTableMutation>;
export type CreateTableMutationResult = ApolloReactCommon.MutationResult<CreateTableMutation>;
export type CreateTableMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateTableMutation, CreateTableMutationVariables>;
export const UpdateTableDocument = gql`
    mutation updateTable($input: TableInput!) {
  updateTable(input: $input) {
    ID
  }
}
    `;
export type UpdateTableMutationFn = ApolloReactCommon.MutationFunction<UpdateTableMutation, UpdateTableMutationVariables>;

/**
 * __useUpdateTableMutation__
 *
 * To run a mutation, you first call `useUpdateTableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTableMutation, { data, loading, error }] = useUpdateTableMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTableMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateTableMutation, UpdateTableMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateTableMutation, UpdateTableMutationVariables>(UpdateTableDocument, baseOptions);
      }
export type UpdateTableMutationHookResult = ReturnType<typeof useUpdateTableMutation>;
export type UpdateTableMutationResult = ApolloReactCommon.MutationResult<UpdateTableMutation>;
export type UpdateTableMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateTableMutation, UpdateTableMutationVariables>;
export const SubmitTableDocument = gql`
    mutation submitTable($input: [TableInput!], $outletID: String!) {
  submitTable(input: $input, outletID: $outletID)
}
    `;
export type SubmitTableMutationFn = ApolloReactCommon.MutationFunction<SubmitTableMutation, SubmitTableMutationVariables>;

/**
 * __useSubmitTableMutation__
 *
 * To run a mutation, you first call `useSubmitTableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitTableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitTableMutation, { data, loading, error }] = useSubmitTableMutation({
 *   variables: {
 *      input: // value for 'input'
 *      outletID: // value for 'outletID'
 *   },
 * });
 */
export function useSubmitTableMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SubmitTableMutation, SubmitTableMutationVariables>) {
        return ApolloReactHooks.useMutation<SubmitTableMutation, SubmitTableMutationVariables>(SubmitTableDocument, baseOptions);
      }
export type SubmitTableMutationHookResult = ReturnType<typeof useSubmitTableMutation>;
export type SubmitTableMutationResult = ApolloReactCommon.MutationResult<SubmitTableMutation>;
export type SubmitTableMutationOptions = ApolloReactCommon.BaseMutationOptions<SubmitTableMutation, SubmitTableMutationVariables>;
export const DeleteTableDocument = gql`
    mutation deleteTable($ID: String!) {
  deleteTable(ID: $ID)
}
    `;
export type DeleteTableMutationFn = ApolloReactCommon.MutationFunction<DeleteTableMutation, DeleteTableMutationVariables>;

/**
 * __useDeleteTableMutation__
 *
 * To run a mutation, you first call `useDeleteTableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTableMutation, { data, loading, error }] = useDeleteTableMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDeleteTableMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteTableMutation, DeleteTableMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteTableMutation, DeleteTableMutationVariables>(DeleteTableDocument, baseOptions);
      }
export type DeleteTableMutationHookResult = ReturnType<typeof useDeleteTableMutation>;
export type DeleteTableMutationResult = ApolloReactCommon.MutationResult<DeleteTableMutation>;
export type DeleteTableMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteTableMutation, DeleteTableMutationVariables>;
export const DragTableDocument = gql`
    mutation dragTable($input: [TableInput!]!) {
  dragTable(input: $input)
}
    `;
export type DragTableMutationFn = ApolloReactCommon.MutationFunction<DragTableMutation, DragTableMutationVariables>;

/**
 * __useDragTableMutation__
 *
 * To run a mutation, you first call `useDragTableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDragTableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [dragTableMutation, { data, loading, error }] = useDragTableMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDragTableMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DragTableMutation, DragTableMutationVariables>) {
        return ApolloReactHooks.useMutation<DragTableMutation, DragTableMutationVariables>(DragTableDocument, baseOptions);
      }
export type DragTableMutationHookResult = ReturnType<typeof useDragTableMutation>;
export type DragTableMutationResult = ApolloReactCommon.MutationResult<DragTableMutation>;
export type DragTableMutationOptions = ApolloReactCommon.BaseMutationOptions<DragTableMutation, DragTableMutationVariables>;
export const RefreshTableDocument = gql`
    subscription refreshTable($outletID: String!) {
  refreshTable(outletID: $outletID) {
    ID
    outletID
    name
    prefix
  }
}
    `;

/**
 * __useRefreshTableSubscription__
 *
 * To run a query within a React component, call `useRefreshTableSubscription` and pass it any options that fit your needs.
 * When your component renders, `useRefreshTableSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRefreshTableSubscription({
 *   variables: {
 *      outletID: // value for 'outletID'
 *   },
 * });
 */
export function useRefreshTableSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<RefreshTableSubscription, RefreshTableSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<RefreshTableSubscription, RefreshTableSubscriptionVariables>(RefreshTableDocument, baseOptions);
      }
export type RefreshTableSubscriptionHookResult = ReturnType<typeof useRefreshTableSubscription>;
export type RefreshTableSubscriptionResult = ApolloReactCommon.SubscriptionResult<RefreshTableSubscription>;
export const GetReservationDocument = gql`
    query getReservation($ID: String, $outletID: String, $tableID: String) {
  getReservation(ID: $ID, outletID: $outletID, tableID: $tableID) {
    ID
    pax
    outletID
    createdTs
    description
    docNo
    status
    noShow
    outletID
    isActive
    tableID
    patronID
    table {
      name
      prefix
      tableNumber
      order {
        docNo
      }
      tablePrfixNumberInfo
    }
    name
    contactNo
    reservationDate
    reservationTime
  }
}
    `;

/**
 * __useGetReservationQuery__
 *
 * To run a query within a React component, call `useGetReservationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReservationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReservationQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      outletID: // value for 'outletID'
 *      tableID: // value for 'tableID'
 *   },
 * });
 */
export function useGetReservationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetReservationQuery, GetReservationQueryVariables>) {
        return ApolloReactHooks.useQuery<GetReservationQuery, GetReservationQueryVariables>(GetReservationDocument, baseOptions);
      }
export function useGetReservationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetReservationQuery, GetReservationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetReservationQuery, GetReservationQueryVariables>(GetReservationDocument, baseOptions);
        }
export type GetReservationQueryHookResult = ReturnType<typeof useGetReservationQuery>;
export type GetReservationLazyQueryHookResult = ReturnType<typeof useGetReservationLazyQuery>;
export type GetReservationQueryResult = ApolloReactCommon.QueryResult<GetReservationQuery, GetReservationQueryVariables>;
export const NoShowCheckDocument = gql`
    query noShowCheck($reservationID: String!) {
  noShowCheck(reservationID: $reservationID)
}
    `;

/**
 * __useNoShowCheckQuery__
 *
 * To run a query within a React component, call `useNoShowCheckQuery` and pass it any options that fit your needs.
 * When your component renders, `useNoShowCheckQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNoShowCheckQuery({
 *   variables: {
 *      reservationID: // value for 'reservationID'
 *   },
 * });
 */
export function useNoShowCheckQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<NoShowCheckQuery, NoShowCheckQueryVariables>) {
        return ApolloReactHooks.useQuery<NoShowCheckQuery, NoShowCheckQueryVariables>(NoShowCheckDocument, baseOptions);
      }
export function useNoShowCheckLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<NoShowCheckQuery, NoShowCheckQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<NoShowCheckQuery, NoShowCheckQueryVariables>(NoShowCheckDocument, baseOptions);
        }
export type NoShowCheckQueryHookResult = ReturnType<typeof useNoShowCheckQuery>;
export type NoShowCheckLazyQueryHookResult = ReturnType<typeof useNoShowCheckLazyQuery>;
export type NoShowCheckQueryResult = ApolloReactCommon.QueryResult<NoShowCheckQuery, NoShowCheckQueryVariables>;
export const CreateReservationDocument = gql`
    mutation createReservation($input: ReservationInput!) {
  createReservation(input: $input)
}
    `;
export type CreateReservationMutationFn = ApolloReactCommon.MutationFunction<CreateReservationMutation, CreateReservationMutationVariables>;

/**
 * __useCreateReservationMutation__
 *
 * To run a mutation, you first call `useCreateReservationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReservationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReservationMutation, { data, loading, error }] = useCreateReservationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateReservationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateReservationMutation, CreateReservationMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateReservationMutation, CreateReservationMutationVariables>(CreateReservationDocument, baseOptions);
      }
export type CreateReservationMutationHookResult = ReturnType<typeof useCreateReservationMutation>;
export type CreateReservationMutationResult = ApolloReactCommon.MutationResult<CreateReservationMutation>;
export type CreateReservationMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateReservationMutation, CreateReservationMutationVariables>;
export const UpdateReservationDocument = gql`
    mutation updateReservation($input: ReservationInput!) {
  updateReservation(input: $input) {
    ID
  }
}
    `;
export type UpdateReservationMutationFn = ApolloReactCommon.MutationFunction<UpdateReservationMutation, UpdateReservationMutationVariables>;

/**
 * __useUpdateReservationMutation__
 *
 * To run a mutation, you first call `useUpdateReservationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReservationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReservationMutation, { data, loading, error }] = useUpdateReservationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateReservationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateReservationMutation, UpdateReservationMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateReservationMutation, UpdateReservationMutationVariables>(UpdateReservationDocument, baseOptions);
      }
export type UpdateReservationMutationHookResult = ReturnType<typeof useUpdateReservationMutation>;
export type UpdateReservationMutationResult = ApolloReactCommon.MutationResult<UpdateReservationMutation>;
export type UpdateReservationMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateReservationMutation, UpdateReservationMutationVariables>;
export const AssignReservationTableDocument = gql`
    mutation assignReservationTable($tableID: String!, $reservationID: String!) {
  assignReservationTable(tableID: $tableID, reservationID: $reservationID)
}
    `;
export type AssignReservationTableMutationFn = ApolloReactCommon.MutationFunction<AssignReservationTableMutation, AssignReservationTableMutationVariables>;

/**
 * __useAssignReservationTableMutation__
 *
 * To run a mutation, you first call `useAssignReservationTableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignReservationTableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignReservationTableMutation, { data, loading, error }] = useAssignReservationTableMutation({
 *   variables: {
 *      tableID: // value for 'tableID'
 *      reservationID: // value for 'reservationID'
 *   },
 * });
 */
export function useAssignReservationTableMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AssignReservationTableMutation, AssignReservationTableMutationVariables>) {
        return ApolloReactHooks.useMutation<AssignReservationTableMutation, AssignReservationTableMutationVariables>(AssignReservationTableDocument, baseOptions);
      }
export type AssignReservationTableMutationHookResult = ReturnType<typeof useAssignReservationTableMutation>;
export type AssignReservationTableMutationResult = ApolloReactCommon.MutationResult<AssignReservationTableMutation>;
export type AssignReservationTableMutationOptions = ApolloReactCommon.BaseMutationOptions<AssignReservationTableMutation, AssignReservationTableMutationVariables>;
export const DeleteReservationDocument = gql`
    mutation deleteReservation($ID: String!) {
  deleteReservation(ID: $ID)
}
    `;
export type DeleteReservationMutationFn = ApolloReactCommon.MutationFunction<DeleteReservationMutation, DeleteReservationMutationVariables>;

/**
 * __useDeleteReservationMutation__
 *
 * To run a mutation, you first call `useDeleteReservationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteReservationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteReservationMutation, { data, loading, error }] = useDeleteReservationMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDeleteReservationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteReservationMutation, DeleteReservationMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteReservationMutation, DeleteReservationMutationVariables>(DeleteReservationDocument, baseOptions);
      }
export type DeleteReservationMutationHookResult = ReturnType<typeof useDeleteReservationMutation>;
export type DeleteReservationMutationResult = ApolloReactCommon.MutationResult<DeleteReservationMutation>;
export type DeleteReservationMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteReservationMutation, DeleteReservationMutationVariables>;
export const CancelReservationDocument = gql`
    mutation cancelReservation($reservationID: String!) {
  cancelReservation(reservationID: $reservationID)
}
    `;
export type CancelReservationMutationFn = ApolloReactCommon.MutationFunction<CancelReservationMutation, CancelReservationMutationVariables>;

/**
 * __useCancelReservationMutation__
 *
 * To run a mutation, you first call `useCancelReservationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelReservationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelReservationMutation, { data, loading, error }] = useCancelReservationMutation({
 *   variables: {
 *      reservationID: // value for 'reservationID'
 *   },
 * });
 */
export function useCancelReservationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelReservationMutation, CancelReservationMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelReservationMutation, CancelReservationMutationVariables>(CancelReservationDocument, baseOptions);
      }
export type CancelReservationMutationHookResult = ReturnType<typeof useCancelReservationMutation>;
export type CancelReservationMutationResult = ApolloReactCommon.MutationResult<CancelReservationMutation>;
export type CancelReservationMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelReservationMutation, CancelReservationMutationVariables>;
export const ReinstateReservationDocument = gql`
    mutation reinstateReservation($reservationID: String!) {
  reinstateReservation(reservationID: $reservationID)
}
    `;
export type ReinstateReservationMutationFn = ApolloReactCommon.MutationFunction<ReinstateReservationMutation, ReinstateReservationMutationVariables>;

/**
 * __useReinstateReservationMutation__
 *
 * To run a mutation, you first call `useReinstateReservationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReinstateReservationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reinstateReservationMutation, { data, loading, error }] = useReinstateReservationMutation({
 *   variables: {
 *      reservationID: // value for 'reservationID'
 *   },
 * });
 */
export function useReinstateReservationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ReinstateReservationMutation, ReinstateReservationMutationVariables>) {
        return ApolloReactHooks.useMutation<ReinstateReservationMutation, ReinstateReservationMutationVariables>(ReinstateReservationDocument, baseOptions);
      }
export type ReinstateReservationMutationHookResult = ReturnType<typeof useReinstateReservationMutation>;
export type ReinstateReservationMutationResult = ApolloReactCommon.MutationResult<ReinstateReservationMutation>;
export type ReinstateReservationMutationOptions = ApolloReactCommon.BaseMutationOptions<ReinstateReservationMutation, ReinstateReservationMutationVariables>;
export const GetTakeawayOrderDocument = gql`
    query getTakeawayOrder($outletID: String!, $orderID: String) {
  getTakeawayOrder(outletID: $outletID, orderID: $orderID) {
    ID
    outletID
    tableID
    qrID
    docNo
    serviceMode
    openedByID
    servedByIDs
    paidByID
    serviceMode
    businessDate
    taxSchemeInfo
    taxSchemeFooterInfo
    pax
    mealPeriodID
    patronID
    createdTs
    totalAmount
    outstandingAmount
    registrationID
    patronAccountID
    status
    patronName
    contactNo
    pickupTime
    orderItem {
      ID
      orderID
      amount
      specialRequest
      taxAmount
      serviceCharge
      quantity
      status
      kitchenStatus
      isTakeaway
      weight
      menuItemID
      openItemName
      totalInfo
      menuItem {
        name
        description
        attachments
      }
      orderItemOption {
        optionID
        name
        orderItemOptionItem {
          price
          basePrice
          unitPrice
          serviceCharge
          taxAmount
          optionItemID
          optionItem {
            name
          }
        }
      }
      orderItemSetMenuOption {
        setMenuOptionID
        name
        orderItemSetMenuOptionItem {
          setMenuOptionItemID
          setMenuOptionItem {
            ID
            menuItem {
              ID
              name
            }
          }
        }
      }
      billID
    }
    patron {
      patronClass
    }
  }
}
    `;

/**
 * __useGetTakeawayOrderQuery__
 *
 * To run a query within a React component, call `useGetTakeawayOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTakeawayOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTakeawayOrderQuery({
 *   variables: {
 *      outletID: // value for 'outletID'
 *      orderID: // value for 'orderID'
 *   },
 * });
 */
export function useGetTakeawayOrderQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetTakeawayOrderQuery, GetTakeawayOrderQueryVariables>) {
        return ApolloReactHooks.useQuery<GetTakeawayOrderQuery, GetTakeawayOrderQueryVariables>(GetTakeawayOrderDocument, baseOptions);
      }
export function useGetTakeawayOrderLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTakeawayOrderQuery, GetTakeawayOrderQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetTakeawayOrderQuery, GetTakeawayOrderQueryVariables>(GetTakeawayOrderDocument, baseOptions);
        }
export type GetTakeawayOrderQueryHookResult = ReturnType<typeof useGetTakeawayOrderQuery>;
export type GetTakeawayOrderLazyQueryHookResult = ReturnType<typeof useGetTakeawayOrderLazyQuery>;
export type GetTakeawayOrderQueryResult = ApolloReactCommon.QueryResult<GetTakeawayOrderQuery, GetTakeawayOrderQueryVariables>;
export const PickupTakeawayOrderDocument = gql`
    mutation pickupTakeawayOrder($orderID: String!) {
  pickupTakeawayOrder(orderID: $orderID)
}
    `;
export type PickupTakeawayOrderMutationFn = ApolloReactCommon.MutationFunction<PickupTakeawayOrderMutation, PickupTakeawayOrderMutationVariables>;

/**
 * __usePickupTakeawayOrderMutation__
 *
 * To run a mutation, you first call `usePickupTakeawayOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePickupTakeawayOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pickupTakeawayOrderMutation, { data, loading, error }] = usePickupTakeawayOrderMutation({
 *   variables: {
 *      orderID: // value for 'orderID'
 *   },
 * });
 */
export function usePickupTakeawayOrderMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PickupTakeawayOrderMutation, PickupTakeawayOrderMutationVariables>) {
        return ApolloReactHooks.useMutation<PickupTakeawayOrderMutation, PickupTakeawayOrderMutationVariables>(PickupTakeawayOrderDocument, baseOptions);
      }
export type PickupTakeawayOrderMutationHookResult = ReturnType<typeof usePickupTakeawayOrderMutation>;
export type PickupTakeawayOrderMutationResult = ApolloReactCommon.MutationResult<PickupTakeawayOrderMutation>;
export type PickupTakeawayOrderMutationOptions = ApolloReactCommon.BaseMutationOptions<PickupTakeawayOrderMutation, PickupTakeawayOrderMutationVariables>;
export const CancelTakeawayOrderDocument = gql`
    mutation cancelTakeawayOrder($orderID: String!) {
  cancelTakeawayOrder(orderID: $orderID)
}
    `;
export type CancelTakeawayOrderMutationFn = ApolloReactCommon.MutationFunction<CancelTakeawayOrderMutation, CancelTakeawayOrderMutationVariables>;

/**
 * __useCancelTakeawayOrderMutation__
 *
 * To run a mutation, you first call `useCancelTakeawayOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelTakeawayOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelTakeawayOrderMutation, { data, loading, error }] = useCancelTakeawayOrderMutation({
 *   variables: {
 *      orderID: // value for 'orderID'
 *   },
 * });
 */
export function useCancelTakeawayOrderMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelTakeawayOrderMutation, CancelTakeawayOrderMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelTakeawayOrderMutation, CancelTakeawayOrderMutationVariables>(CancelTakeawayOrderDocument, baseOptions);
      }
export type CancelTakeawayOrderMutationHookResult = ReturnType<typeof useCancelTakeawayOrderMutation>;
export type CancelTakeawayOrderMutationResult = ApolloReactCommon.MutationResult<CancelTakeawayOrderMutation>;
export type CancelTakeawayOrderMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelTakeawayOrderMutation, CancelTakeawayOrderMutationVariables>;